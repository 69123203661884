export const DONATION_CLEAR_ITEMS = 'DONATION_CLEAR_ITEMS';
export const DONATION_CREATE_ONE_BEGIN = 'DONATION_CREATE_ONE_BEGIN';
export const DONATION_CREATE_ONE_SUCCESS = 'DONATION_CREATE_ONE_SUCCESS';
export const DONATION_CREATE_ONE_FAILURE = 'DONATION_CREATE_ONE_FAILURE';
export const DONATION_CREATE_ONE_DISMISS_ERROR = 'DONATION_CREATE_ONE_DISMISS_ERROR';
export const DONATION_DEL_ONE_BEGIN = 'DONATION_DEL_ONE_BEGIN';
export const DONATION_DEL_ONE_SUCCESS = 'DONATION_DEL_ONE_SUCCESS';
export const DONATION_DEL_ONE_FAILURE = 'DONATION_DEL_ONE_FAILURE';
export const DONATION_DEL_ONE_DISMISS_ERROR = 'DONATION_DEL_ONE_DISMISS_ERROR';
export const DONATION_EXPORT_AS_TAB_BEGIN = 'DONATION_EXPORT_AS_TAB_BEGIN';
export const DONATION_EXPORT_AS_TAB_SUCCESS = 'DONATION_EXPORT_AS_TAB_SUCCESS';
export const DONATION_EXPORT_AS_TAB_FAILURE = 'DONATION_EXPORT_AS_TAB_FAILURE';
export const DONATION_EXPORT_AS_TAB_DISMISS_ERROR = 'DONATION_EXPORT_AS_TAB_DISMISS_ERROR';
export const DONATION_EXPORT_BEGIN = 'DONATION_EXPORT_BEGIN';
export const DONATION_EXPORT_SUCCESS = 'DONATION_EXPORT_SUCCESS';
export const DONATION_EXPORT_FAILURE = 'DONATION_EXPORT_FAILURE';
export const DONATION_EXPORT_DISMISS_ERROR = 'DONATION_EXPORT_DISMISS_ERROR';
export const DONATION_INIT_FILTERS = 'DONATION_INIT_FILTERS';
export const DONATION_INIT_SORT = 'DONATION_INIT_SORT';
export const DONATION_LOAD_DONATIONS_INIT = 'DONATION_LOAD_DONATIONS_INIT';
export const DONATION_LOAD_DONATIONS_BEGIN = 'DONATION_LOAD_DONATIONS_BEGIN';
export const DONATION_LOAD_DONATIONS_SUCCESS = 'DONATION_LOAD_DONATIONS_SUCCESS';
export const DONATION_LOAD_DONATIONS_FAILURE = 'DONATION_LOAD_DONATIONS_FAILURE';
export const DONATION_LOAD_DONATIONS_DISMISS_ERROR = 'DONATION_LOAD_DONATIONS_DISMISS_ERROR';
export const DONATION_LOAD_MORE_INIT = 'DONATION_LOAD_MORE_INIT';
export const DONATION_LOAD_MORE_BEGIN = 'DONATION_LOAD_MORE_BEGIN';
export const DONATION_LOAD_MORE_SUCCESS = 'DONATION_LOAD_MORE_SUCCESS';
export const DONATION_LOAD_MORE_FAILURE = 'DONATION_LOAD_MORE_FAILURE';
export const DONATION_LOAD_MORE_DISMISS_ERROR = 'DONATION_LOAD_MORE_DISMISS_ERROR';
export const DONATION_LOAD_ONE_BEGIN = 'DONATION_LOAD_ONE_BEGIN';
export const DONATION_LOAD_ONE_SUCCESS = 'DONATION_LOAD_ONE_SUCCESS';
export const DONATION_LOAD_ONE_FAILURE = 'DONATION_LOAD_ONE_FAILURE';
export const DONATION_LOAD_ONE_DISMISS_ERROR = 'DONATION_LOAD_ONE_DISMISS_ERROR';
export const DONATION_SEND_ONE_BEGIN = 'DONATION_SEND_ONE_BEGIN';
export const DONATION_SEND_ONE_SUCCESS = 'DONATION_SEND_ONE_SUCCESS';
export const DONATION_SEND_ONE_FAILURE = 'DONATION_SEND_ONE_FAILURE';
export const DONATION_SEND_ONE_DISMISS_ERROR = 'DONATION_SEND_ONE_DISMISS_ERROR';
export const DONATION_ON_SELECT = 'DONATION_ON_SELECT';
export const DONATION_PRINT_ONE_BEGIN = 'DONATION_PRINT_ONE_BEGIN';
export const DONATION_PRINT_ONE_SUCCESS = 'DONATION_PRINT_ONE_SUCCESS';
export const DONATION_PRINT_ONE_FAILURE = 'DONATION_PRINT_ONE_FAILURE';
export const DONATION_PRINT_ONE_DISMISS_ERROR = 'DONATION_PRINT_ONE_DISMISS_ERROR';
export const DONATION_PROPAGATE = 'DONATION_PROPAGATE';
export const DONATION_SELECT_NONE = 'DONATION_SELECT_NONE';
export const DONATION_SELECT_ALL = 'DONATION_SELECT_ALL';
export const DONATION_SET_CURRENT = 'DONATION_SET_CURRENT';
export const DONATION_SET_PREVIOUS = 'DONATION_SET_PREVIOUS';
export const DONATION_SET_NEXT_BEGIN = 'DONATION_SET_NEXT_BEGIN';
export const DONATION_SET_NEXT_SUCCESS = 'DONATION_SET_NEXT_SUCCESS';
export const DONATION_SET_NEXT_FAILURE = 'DONATION_SET_NEXT_FAILURE';
export const DONATION_SET_NEXT_DISMISS_ERROR = 'DONATION_SET_NEXT_DISMISS_ERROR';
export const DONATION_SET_FILTERS = 'DONATION_SET_FILTERS';
export const DONATION_SET_SORT = 'DONATION_SET_SORT';
export const DONATION_UPDATE_ONE_BEGIN = 'DONATION_UPDATE_ONE_BEGIN';
export const DONATION_UPDATE_ONE_SUCCESS = 'DONATION_UPDATE_ONE_SUCCESS';
export const DONATION_UPDATE_ONE_FAILURE = 'DONATION_UPDATE_ONE_FAILURE';
export const DONATION_UPDATE_ONE_DISMISS_ERROR = 'DONATION_UPDATE_ONE_DISMISS_ERROR';
export const DONATION_UPDATE_QUICK_SEARCH = 'DONATION_UPDATE_QUICK_SEARCH';
export const DONATION_UPDATE_SORT = 'DONATION_UPDATE_SORT';
