export const GROUP_TYPE_CLEAR_ITEMS = 'GROUP_TYPE_CLEAR_ITEMS';
export const GROUP_TYPE_CREATE_ONE_BEGIN = 'GROUP_TYPE_CREATE_ONE_BEGIN';
export const GROUP_TYPE_CREATE_ONE_SUCCESS = 'GROUP_TYPE_CREATE_ONE_SUCCESS';
export const GROUP_TYPE_CREATE_ONE_FAILURE = 'GROUP_TYPE_CREATE_ONE_FAILURE';
export const GROUP_TYPE_CREATE_ONE_DISMISS_ERROR = 'GROUP_TYPE_CREATE_ONE_DISMISS_ERROR';
export const GROUP_TYPE_DEL_ONE_BEGIN = 'GROUP_TYPE_DEL_ONE_BEGIN';
export const GROUP_TYPE_DEL_ONE_SUCCESS = 'GROUP_TYPE_DEL_ONE_SUCCESS';
export const GROUP_TYPE_DEL_ONE_FAILURE = 'GROUP_TYPE_DEL_ONE_FAILURE';
export const GROUP_TYPE_DEL_ONE_DISMISS_ERROR = 'GROUP_TYPE_DEL_ONE_DISMISS_ERROR';
export const GROUP_TYPE_EXPORT_AS_TAB_BEGIN = 'GROUP_TYPE_EXPORT_AS_TAB_BEGIN';
export const GROUP_TYPE_EXPORT_AS_TAB_SUCCESS = 'GROUP_TYPE_EXPORT_AS_TAB_SUCCESS';
export const GROUP_TYPE_EXPORT_AS_TAB_FAILURE = 'GROUP_TYPE_EXPORT_AS_TAB_FAILURE';
export const GROUP_TYPE_EXPORT_AS_TAB_DISMISS_ERROR = 'GROUP_TYPE_EXPORT_AS_TAB_DISMISS_ERROR';
export const GROUP_TYPE_EXPORT_BEGIN = 'GROUP_TYPE_EXPORT_BEGIN';
export const GROUP_TYPE_EXPORT_SUCCESS = 'GROUP_TYPE_EXPORT_SUCCESS';
export const GROUP_TYPE_EXPORT_FAILURE = 'GROUP_TYPE_EXPORT_FAILURE';
export const GROUP_TYPE_EXPORT_DISMISS_ERROR = 'GROUP_TYPE_EXPORT_DISMISS_ERROR';
export const GROUP_TYPE_INIT_FILTERS = 'GROUP_TYPE_INIT_FILTERS';
export const GROUP_TYPE_INIT_SORT = 'GROUP_TYPE_INIT_SORT';
export const GROUP_TYPE_LOAD_MORE_INIT = 'GROUP_TYPE_LOAD_MORE_INIT';
export const GROUP_TYPE_LOAD_MORE_BEGIN = 'GROUP_TYPE_LOAD_MORE_BEGIN';
export const GROUP_TYPE_LOAD_MORE_SUCCESS = 'GROUP_TYPE_LOAD_MORE_SUCCESS';
export const GROUP_TYPE_LOAD_MORE_FAILURE = 'GROUP_TYPE_LOAD_MORE_FAILURE';
export const GROUP_TYPE_LOAD_MORE_DISMISS_ERROR = 'GROUP_TYPE_LOAD_MORE_DISMISS_ERROR';
export const GROUP_TYPE_LOAD_ONE_BEGIN = 'GROUP_TYPE_LOAD_ONE_BEGIN';
export const GROUP_TYPE_LOAD_ONE_SUCCESS = 'GROUP_TYPE_LOAD_ONE_SUCCESS';
export const GROUP_TYPE_LOAD_ONE_FAILURE = 'GROUP_TYPE_LOAD_ONE_FAILURE';
export const GROUP_TYPE_LOAD_ONE_DISMISS_ERROR = 'GROUP_TYPE_LOAD_ONE_DISMISS_ERROR';
export const GROUP_TYPE_ON_SELECT = 'GROUP_TYPE_ON_SELECT';
export const GROUP_TYPE_PRINT_ONE_BEGIN = 'GROUP_TYPE_PRINT_ONE_BEGIN';
export const GROUP_TYPE_PRINT_ONE_SUCCESS = 'GROUP_TYPE_PRINT_ONE_SUCCESS';
export const GROUP_TYPE_PRINT_ONE_FAILURE = 'GROUP_TYPE_PRINT_ONE_FAILURE';
export const GROUP_TYPE_PRINT_ONE_DISMISS_ERROR = 'GROUP_TYPE_PRINT_ONE_DISMISS_ERROR';
export const GROUP_TYPE_PROPAGATE = 'GROUP_TYPE_PROPAGATE';
export const GROUP_TYPE_SELECT_NONE = 'GROUP_TYPE_SELECT_NONE';
export const GROUP_TYPE_SELECT_ALL = 'GROUP_TYPE_SELECT_ALL';
export const GROUP_TYPE_SET_CURRENT = 'GROUP_TYPE_SET_CURRENT';
export const GROUP_TYPE_SET_PREVIOUS = 'GROUP_TYPE_SET_PREVIOUS';
export const GROUP_TYPE_SET_NEXT_BEGIN = 'GROUP_TYPE_SET_NEXT_BEGIN';
export const GROUP_TYPE_SET_NEXT_SUCCESS = 'GROUP_TYPE_SET_NEXT_SUCCESS';
export const GROUP_TYPE_SET_NEXT_FAILURE = 'GROUP_TYPE_SET_NEXT_FAILURE';
export const GROUP_TYPE_SET_NEXT_DISMISS_ERROR = 'GROUP_TYPE_SET_NEXT_DISMISS_ERROR';
export const GROUP_TYPE_SET_FILTERS = 'GROUP_TYPE_SET_FILTERS';
export const GROUP_TYPE_SET_SORT = 'GROUP_TYPE_SET_SORT';
export const GROUP_TYPE_UPDATE_ONE_BEGIN = 'GROUP_TYPE_UPDATE_ONE_BEGIN';
export const GROUP_TYPE_UPDATE_ONE_SUCCESS = 'GROUP_TYPE_UPDATE_ONE_SUCCESS';
export const GROUP_TYPE_UPDATE_ONE_FAILURE = 'GROUP_TYPE_UPDATE_ONE_FAILURE';
export const GROUP_TYPE_UPDATE_ONE_DISMISS_ERROR = 'GROUP_TYPE_UPDATE_ONE_DISMISS_ERROR';
export const GROUP_TYPE_UPDATE_QUICK_SEARCH = 'GROUP_TYPE_UPDATE_QUICK_SEARCH';
export const GROUP_TYPE_UPDATE_SORT = 'GROUP_TYPE_UPDATE_SORT';
