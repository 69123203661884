import React from 'react';
import { injectIntl } from 'react-intl';
import { getShortcuts } from './';
import useForm from '../ui/useForm';
import {
  ResponsiveModalOrForm,
  InputHidden,
  InputText,
  InputCheckbox,
  InputDatetime,
  InputHtml,
  Row,
  Col,
} from '../ui';
import { InputPicker as InputGroupPicker } from '../group';
import { InputPicker as InputUserPicker } from '../user';

/**
 * Initialisation
 *
 * @param {Object}   item
 * @param {Function} setItem
 */
const initItem = (item, setItem) => {};

/**
 * Un champ a été modifié
 *
 * @param {String}   field
 * @param {Object}   item
 * @param {Function} setitem
 */
const afterChange = (field, item, setItem) => {
  switch (field) {
    default:
      break;
  }
};

/**
 * Saisie des informations d'un modèle
 */
function Form(props) {
  let item = props.item;
  // On récupère tout ce qu'il faut pour travailler
  const {
    values,
    handleChange,
    handleSave,
    handleSubmit,
    handleCancel,
    handleNavTab,
    getErrorMessage,
    getCurrentTab,
  } = useForm(
    item,
    props.tab,
    props.onSubmit,
    props.onCancel,
    props.onNavTab,
    props.errors,
    props.intl,
    afterChange,
    initItem,
  );
  // Le formulaire
  return (
    <ResponsiveModalOrForm
      title={
        !props.modify && !values.inbox_filename
          ? props.intl.formatMessage({
              id: 'app.features.inbox.form.title',
              defaultMessage: 'New model',
            })
          : values.inbox_filename
      }
      className=""
      tab={getCurrentTab()}
      tabs={!props.modify ? getShortcuts(props.intl, 'new') : getShortcuts(props.intl, 'all')}
      size="md"
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onNavTab={handleNavTab}
      onSave={handleSave}
      onClose={handleCancel}
      onPrevious={props.onPrevious}
      onNext={props.onNext}
      modal={true}
      actionsButtons={props.actionsButtons}
      loader={props.loader ? props.loader : false}
    >
      <InputHidden name="id" id="id" value={values.id} />
      <Row>
        <Col size={{ xxs: 36, sm: 24 }}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.inbox.field.inbox_filename.label',
              defaultMessage: 'inbox_filename',
            })}
            id="inbox_filename"
            name="inbox_filename"
            value={values.inbox_filename}
            required={true}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('inbox_filename')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputDatetime
            label={props.intl.formatMessage({
              id: 'app.features.inbox.field.inbox_ts.label',
              defaultMessage: 'inbox_ts',
            })}
            id="inbox_ts"
            name="inbox_ts"
            value={values.inbox_ts}
            required={true}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('inbox_ts')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputUserPicker
            label={props.intl.formatMessage({
              id: 'app.features.inbox.field.user_id.label',
              defaultMessage: 'User',
            })}
            name="user"
            labelTop={true}
            required={true}
            item={values.user || null}
            onChange={handleChange}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputGroupPicker
            label={props.intl.formatMessage({
              id: 'app.features.inbox.field.grp_id.label',
              defaultMessage: 'Group',
            })}
            name="group"
            labelTop={true}
            required={true}
            item={values.group || null}
            onChange={handleChange}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 4 }}>
          <InputCheckbox
            label={props.intl.formatMessage({
              id: 'app.features.inbox.field.inbox_keep.label',
              defaultMessage: 'Bénévole',
            })}
            name="inbox_keep"
            labelTop={true}
            checked={values.inbox_keep === true}
            onChange={handleChange}
            error={getErrorMessage('inbox_keep')}
          />
        </Col>
        <Col size={{ xxs: 36 }}>
          <InputHtml
            label={props.intl.formatMessage({
              id: 'app.features.inbox.field.inbox_desc.label',
              defaultMessage: 'inbox_desc',
            })}
            id="inbox_desc"
            name="inbox_desc"
            value={values.inbox_desc}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('inbox_desc')}
          />
        </Col>
      </Row>
    </ResponsiveModalOrForm>
  );
}

/**
 * Injection de l'intl
 */
export default injectIntl(Form);
