import { jsonApiNormalizer, getJsonApi, normalizedObjectModeler } from 'jsonapi-front';
import {
  DONATION_SEND_ONE_BEGIN,
  DONATION_SEND_ONE_SUCCESS,
  DONATION_SEND_ONE_FAILURE,
  DONATION_SEND_ONE_DISMISS_ERROR,
} from './constants';
import { freeAssoApi, propagateModel } from '../../../common';

export function sendOne(id, obj = {}, propagate = true) {
  return dispatch => {
    dispatch({
      type: DONATION_SEND_ONE_BEGIN,
    });
    const promise = new Promise((resolve, reject) => {
      const japiObj = getJsonApi(obj, 'FreeAsso_Donation');
      const doRequest = freeAssoApi.post('/v1/asso/donation/send/' + id, japiObj);
      doRequest.then(
        result => {
          const object = jsonApiNormalizer(result.data);
          const item = normalizedObjectModeler(object, 'FreeAsso_Donation', id, { eager: true });
          if (propagate) {
            dispatch(propagateModel('FreeAsso_Donation', result));
          }
          dispatch({
            type: DONATION_SEND_ONE_SUCCESS,
            data: result,
            item: item,
          });
          resolve(item);
        },
        err => {
          dispatch({
            type: DONATION_SEND_ONE_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissSendOneError() {
  return {
    type: DONATION_SEND_ONE_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case DONATION_SEND_ONE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        sendOnePending: true,
        sendOneError: null,
      };

    case DONATION_SEND_ONE_SUCCESS:
      // The request is success
      return {
        ...state,
        sendOnePending: false,
        sendOneError: null,
      };

    case DONATION_SEND_ONE_FAILURE:
      // The request is failed
      let error = null;
      if (action.data.error && action.data.error.response) {
        error = jsonApiNormalizer(action.data.error.response);
      }
      return {
        ...state,
        sendOnePending: false,
        sendOneError: error,
      };

    case DONATION_SEND_ONE_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        sendOneError: null,
      };

    default:
      return state;
  }
}
