export const STAT_CLEAR_ITEMS = 'STAT_CLEAR_ITEMS';
export const STAT_CREATE_ONE_BEGIN = 'STAT_CREATE_ONE_BEGIN';
export const STAT_CREATE_ONE_SUCCESS = 'STAT_CREATE_ONE_SUCCESS';
export const STAT_CREATE_ONE_FAILURE = 'STAT_CREATE_ONE_FAILURE';
export const STAT_CREATE_ONE_DISMISS_ERROR = 'STAT_CREATE_ONE_DISMISS_ERROR';
export const STAT_DEL_ONE_BEGIN = 'STAT_DEL_ONE_BEGIN';
export const STAT_DEL_ONE_SUCCESS = 'STAT_DEL_ONE_SUCCESS';
export const STAT_DEL_ONE_FAILURE = 'STAT_DEL_ONE_FAILURE';
export const STAT_DEL_ONE_DISMISS_ERROR = 'STAT_DEL_ONE_DISMISS_ERROR';
export const STAT_EXPORT_AS_TAB_BEGIN = 'STAT_EXPORT_AS_TAB_BEGIN';
export const STAT_EXPORT_AS_TAB_SUCCESS = 'STAT_EXPORT_AS_TAB_SUCCESS';
export const STAT_EXPORT_AS_TAB_FAILURE = 'STAT_EXPORT_AS_TAB_FAILURE';
export const STAT_EXPORT_AS_TAB_DISMISS_ERROR = 'STAT_EXPORT_AS_TAB_DISMISS_ERROR';
export const STAT_EXPORT_BEGIN = 'STAT_EXPORT_BEGIN';
export const STAT_EXPORT_SUCCESS = 'STAT_EXPORT_SUCCESS';
export const STAT_EXPORT_FAILURE = 'STAT_EXPORT_FAILURE';
export const STAT_EXPORT_DISMISS_ERROR = 'STAT_EXPORT_DISMISS_ERROR';
export const STAT_INIT_FILTERS = 'STAT_INIT_FILTERS';
export const STAT_INIT_SORT = 'STAT_INIT_SORT';
export const STAT_LOAD_MORE_INIT = 'STAT_LOAD_MORE_INIT';
export const STAT_LOAD_MORE_BEGIN = 'STAT_LOAD_MORE_BEGIN';
export const STAT_LOAD_MORE_SUCCESS = 'STAT_LOAD_MORE_SUCCESS';
export const STAT_LOAD_MORE_FAILURE = 'STAT_LOAD_MORE_FAILURE';
export const STAT_LOAD_MORE_DISMISS_ERROR = 'STAT_LOAD_MORE_DISMISS_ERROR';
export const STAT_LOAD_ONE_BEGIN = 'STAT_LOAD_ONE_BEGIN';
export const STAT_LOAD_ONE_SUCCESS = 'STAT_LOAD_ONE_SUCCESS';
export const STAT_LOAD_ONE_FAILURE = 'STAT_LOAD_ONE_FAILURE';
export const STAT_LOAD_ONE_DISMISS_ERROR = 'STAT_LOAD_ONE_DISMISS_ERROR';
export const STAT_ON_SELECT = 'STAT_ON_SELECT';
export const STAT_PRINT_ONE_BEGIN = 'STAT_PRINT_ONE_BEGIN';
export const STAT_PRINT_ONE_SUCCESS = 'STAT_PRINT_ONE_SUCCESS';
export const STAT_PRINT_ONE_FAILURE = 'STAT_PRINT_ONE_FAILURE';
export const STAT_PRINT_ONE_DISMISS_ERROR = 'STAT_PRINT_ONE_DISMISS_ERROR';
export const STAT_PROPAGATE = 'STAT_PROPAGATE';
export const STAT_SELECT_NONE = 'STAT_SELECT_NONE';
export const STAT_SELECT_ALL = 'STAT_SELECT_ALL';
export const STAT_SET_CURRENT = 'STAT_SET_CURRENT';
export const STAT_SET_PREVIOUS = 'STAT_SET_PREVIOUS';
export const STAT_SET_NEXT_BEGIN = 'STAT_SET_NEXT_BEGIN';
export const STAT_SET_NEXT_SUCCESS = 'STAT_SET_NEXT_SUCCESS';
export const STAT_SET_NEXT_FAILURE = 'STAT_SET_NEXT_FAILURE';
export const STAT_SET_NEXT_DISMISS_ERROR = 'STAT_SET_NEXT_DISMISS_ERROR';
export const STAT_SET_FILTERS = 'STAT_SET_FILTERS';
export const STAT_SET_SORT = 'STAT_SET_SORT';
export const STAT_UPDATE_ONE_BEGIN = 'STAT_UPDATE_ONE_BEGIN';
export const STAT_UPDATE_ONE_SUCCESS = 'STAT_UPDATE_ONE_SUCCESS';
export const STAT_UPDATE_ONE_FAILURE = 'STAT_UPDATE_ONE_FAILURE';
export const STAT_UPDATE_ONE_DISMISS_ERROR = 'STAT_UPDATE_ONE_DISMISS_ERROR';
export const STAT_UPDATE_QUICK_SEARCH = 'STAT_UPDATE_QUICK_SEARCH';
export const STAT_UPDATE_SORT = 'STAT_UPDATE_SORT';
