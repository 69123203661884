import { freeAssoApi } from '../../../common';
import {
  Filter,
  FILTER_MODE_AND,
  FILTER_COND_EQUAL,
  FILTER_OPER_NOT_EQUAL,
} from 'react-bootstrap-front';
import { jsonApiNormalizer, objectToQueryString } from 'jsonapi-front';
import {
  JOBQUEUE_LOAD_PENDINGS_INIT,
  JOBQUEUE_LOAD_PENDINGS_BEGIN,
  JOBQUEUE_LOAD_PENDINGS_SUCCESS,
  JOBQUEUE_LOAD_PENDINGS_FAILURE,
  JOBQUEUE_LOAD_PENDINGS_DISMISS_ERROR,
} from './constants';

/**
 * Récupère la liste des agents
 */
export function loadPendings(reload = false) {
  return (dispatch, getState) => {
    const loaded = getState().jobqueue.loadPendingsFinish;
    if (!loaded || reload) {
      if (reload) {
        dispatch({
          type: JOBQUEUE_LOAD_PENDINGS_INIT,
        });
      } else {
        dispatch({
          type: JOBQUEUE_LOAD_PENDINGS_BEGIN,
        });
      }
      const promise = new Promise((resolve, reject) => {
        let pFilters = new Filter();
        pFilters.setMode(FILTER_MODE_AND, FILTER_COND_EQUAL);
        pFilters.addFilter('jobq_status', 'FINISHED', FILTER_OPER_NOT_EQUAL);
        const filters = pFilters.asJsonApiObject();
        let params = {
          page: {
            number: reload ? 1 : getState().jobqueue.page_pending,
            size: getState().jobqueue.page_size,
          },
          include: getState().jobqueue.includeMore,
          ...filters,
        };
        let sort = '';
        getState().jobqueue.sort.forEach(elt => {
          let add = elt.col;
          if (elt.way === 'down') {
            add = '-' + add;
          }
          if (sort === '') {
            sort = add;
          } else {
            sort = sort + ',' + add;
          }
        });
        if (sort !== '') {
          params.sort = sort;
        }
        const addUrl = objectToQueryString(params);
        const doRequest = freeAssoApi.get('/v1/core/jobqueue' + addUrl, {});
        doRequest.then(
          res => {
            dispatch({
              type: JOBQUEUE_LOAD_PENDINGS_SUCCESS,
              data: res,
              reload: reload,
            });
            resolve(res);
          },
          // Use rejectHandler as the second argument so that render errors won't be caught.
          err => {
            console.log('err more : ', err);
            dispatch({
              type: JOBQUEUE_LOAD_PENDINGS_FAILURE,
              data: { error: err },
            });
            reject(err);
          },
        );
      });
      return promise;
    }
  };
}

export function dismissLoadPendingsError() {
  return {
    type: JOBQUEUE_LOAD_PENDINGS_DISMISS_ERROR,
  };
}

/**
 * Reducer
 *
 * @param {Object} state  Etat courant de la mémoire (store)
 * @param {Object} action Action à réaliser sur cet état avec options
 */
export function reducer(state, action) {
  switch (action.type) {
    case JOBQUEUE_LOAD_PENDINGS_INIT:
      // Just after a request is sent
      return {
        ...state,
        loadPendingsPending: true,
        loadPendingsError: null,
        loadPendingsFinish: false,
        page_pending: 1,
      };

    case JOBQUEUE_LOAD_PENDINGS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        loadPendingsPending: true,
        loadPendingsError: null,
      };

    case JOBQUEUE_LOAD_PENDINGS_SUCCESS:
      // The request is success
      let list = {};
      let nbre = 0;
      let result = false;
      if (action.data && action.data.data) {
        result = action.data.data;
      }
      if (result.data) {
        nbre = result.data.length;
      }
      if (nbre > 0) {
        if (state.pendings && !action.reload) {
          list = jsonApiNormalizer(result, state.pendings);
        } else {
          list = jsonApiNormalizer(result);
        }
      } else {
        list = state.pendings;
      }
      return {
        ...state,
        loadPendingsPending: false,
        loadPendingsError: null,
        loadPendingsFinish: nbre < state.page_size,
        pendings: list,
        page_pending: state.page_pending + 1,
      };

    case JOBQUEUE_LOAD_PENDINGS_FAILURE:
      // The request is failed
      return {
        ...state,
        loadPendingsPending: false,
        loadPendingsError: action.data.error,
      };

    case JOBQUEUE_LOAD_PENDINGS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        loadPendingsError: null,
      };

    default:
      return state;
  }
}
