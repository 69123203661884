import React from 'react';
import { injectIntl } from 'react-intl';
import { deepClone } from 'jsonapi-front';
import { getShortcuts } from './';
import { getlangLabel } from '../lang';
import useForm from '../ui/useForm';
import {
  ResponsiveModalOrForm,
  InputHidden,
  InputText,
  InputSelect,
  InputHtml,
  Row,
  Col,
} from '../ui';

let id = 0;

/**
 * Add a new tab
 *
 * @param object item
 * @param object emptyVersion
 * @param array  langs
 */
const addTab = (item, emptyVersion, langs) => {
  if (!Array.isArray(item.versions)) {
    item.versions = [];
  }
  const newEmpty = deepClone(emptyVersion);
  id--;
  newEmpty.id = id;
  langs.every(lang => {
    const idx = item.versions.findIndex(elem => {
      return '' + elem.lang.id === '' + lang.value;
    });
    if (idx < 0) {
      newEmpty.lang.id = lang.value;
      item.versions.push(newEmpty);
      return false;
    }
    return true;
  });
};

/**
 * Remove tab
 *
 * @param object item
 * @param object tab
 * @param array  langs
 */
const delTab = (item, tab, langs) => {
  item.versions = item.versions.filter(elem => elem.id !== tab);
};

/**
 * Initialisation
 *
 * @param {Object}   item
 * @param {Function} setItem
 */
const initItem = (item, setItem) => {};

/**
 * Un champ a été modifié
 *
 * @param {String}   field
 * @param {Object}   item
 * @param {Function} setitem
 */
const afterChange = (field, item, setItem) => {
  switch (field) {
    default:
      break;
  }
};

/**
 * Saisie des informations d'un modèle
 */
function Form(props) {
  let item = props.item;
  // On récupère tout ce qu'il faut pour travailler
  const {
    values,
    handleChange,
    handleSave,
    handleSubmit,
    handleCancel,
    handleNavTab,
    getErrorMessage,
    getCurrentTab,
  } = useForm(
    item,
    props.tab,
    props.onSubmit,
    props.onCancel,
    props.onNavTab,
    props.errors,
    props.intl,
    afterChange,
    initItem,
  );
  if (!Array.isArray(values.versions)) {
    values.versions = [];
  }
  let versTabs = [];
  let indexV = 0;
  if (values.versions) {
    values.versions.forEach(oneVersion => {
      versTabs.push({
        id: oneVersion.id,
        key: 'version' + indexV,
        name: 'version' + indexV,
        label: getlangLabel(props.langs, oneVersion.lang.id),
        shortcut: 'E',
        icon: 'email',
        removeable: true,
      });
      indexV++;
    });
  }
  // Le formulaire
  return (
    <ResponsiveModalOrForm
      title={
        !props.modify && !values.tx_code
          ? props.intl.formatMessage({
              id: 'app.features.taxonomy.form.title',
              defaultMessage: 'New model',
            })
          : values.tx_code
      }
      className=""
      tab={getCurrentTab()}
      tabs={
        !props.modify
          ? getShortcuts(props.intl, 'new').concat(versTabs)
          : getShortcuts(props.intl, 'all').concat(versTabs)
      }
      size="md"
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onNavTab={handleNavTab}
      onAddTab={() => {
        addTab(values, props.emptyVersion, props.langs);
        handleNavTab(values.versions.length + 1);
      }}
      onDelTab={tab => {
        delTab(values, tab, props.langs);
        if (values.versions.length > 0) {
          handleNavTab(values.versions.length + 1);
        } else {
          handleNavTab(1);
        }
      }}
      onSave={handleSave}
      onClose={handleCancel}
      onPrevious={props.onPrevious}
      onNext={props.onNext}
      modal={true}
      actionsButtons={props.actionsButtons}
      loader={props.loader ? props.loader : false}
    >
      <InputHidden name="id" id="id" value={values.id} />
      <InputHidden name="tx_object_name" id="tx_object_name" value={values.tx_object_name} />
      <InputHidden name="tx_object_id" id="tx_object_id" value={values.tx_object_id} />
      <Row>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.taxonomy.field.tx_code.label',
              defaultMessage: 'tx_code',
            })}
            id="tx_code"
            name="tx_code"
            value={values.tx_code}
            required={true}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('tx_code')}
          />
        </Col>
        <Col size={{ xxs: 36 }}>
          <InputHtml
            label={props.intl.formatMessage({
              id: 'app.features.taxonomy.field.tx_desc.label',
              defaultMessage: 'tx_desc',
            })}
            id="tx_desc"
            name="tx_desc"
            value={values.tx_desc}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('tx_desc')}
          />
        </Col>
      </Row>
      {values.versions.map((elem, idx) => {
        if (values.__currentTab === 'version' + idx) {
          return (
            <Row>
              <Col size={{ xs: 36, sm: 10 }}>
                <InputSelect
                  label={props.intl.formatMessage({
                    id: 'app.features.taxonomy.field.lang.label',
                    defaultMessage: 'Lang',
                  })}
                  name={'versions.' + idx + '.lang.id'}
                  required={true}
                  value={elem.lang.id}
                  onChange={handleChange}
                  options={props.langs}
                  addempty={true}
                />
              </Col>
              <Col size={{ xxs: 36 }}>
                <InputHtml
                  label={props.intl.formatMessage({
                    id: 'app.features.taxonomy.field.txl_content.label',
                    defaultMessage: 'txl_content',
                  })}
                  name={'versions.' + idx + '.txl_content'}
                  id={'versions.' + idx + '.txl_content'}
                  value={elem.txl_content || null}
                  labelTop={true}
                  onChange={handleChange}
                  error={getErrorMessage('txl_content')}
                />
              </Col>
            </Row>
          );
        }
        return null;
      })}
    </ResponsiveModalOrForm>
  );
}

/**
 * Injection de l'intl
 */
export default injectIntl(Form);
