import React from 'react';
import { injectIntl } from 'react-intl';
import { getShortcuts } from './';
import useForm from '../ui/useForm';
import {
  ResponsiveModalOrForm,
  InputHidden,
  InputText,
  InputDatetime,
  Row,
  Col,
} from '../ui';

/**
 * Initialisation
 *
 * @param {Object}   item
 * @param {Function} setItem
 */
const initItem = (item, setItem) => {};

/**
 * Un champ a été modifié
 *
 * @param {String}   field
 * @param {Object}   item
 * @param {Function} setitem
 */
const afterChange = (field, item, setItem) => {
  switch (field) {
    default:
      break;
  }
};

/**
 * Saisie des informations d'un modèle
 */
function Form(props) {
  let item = props.item;
  // On récupère tout ce qu'il faut pour travailler
  const {
    values,
    handleChange,
    handleCancel,
    handleNavTab,
    getErrorMessage,
    getCurrentTab,
  } = useForm(
    item,
    props.tab,
    props.onSubmit,
    props.onCancel,
    props.onNavTab,
    props.errors,
    props.intl,
    afterChange,
    initItem,
  );
  // Le formulaire
  return (
    <ResponsiveModalOrForm
      title={
        !props.modify && !values.dono_comments
          ? props.intl.formatMessage({
              id: 'app.features.donation-origin.form.title',
              defaultMessage: 'New model',
            })
          : values.dono_month + '/' + values.dono_year
      }
      className=""
      tab={getCurrentTab()}
      tabs={!props.modify ? getShortcuts(props.intl, 'new') : getShortcuts(props.intl, 'all')}
      size="md"
      onCancel={handleCancel}
      onNavTab={handleNavTab}
      onClose={handleCancel}
      onPrevious={props.onPrevious}
      onNext={props.onNext}
      modal={true}
      actionsButtons={props.actionsButtons}
      loader={props.loader ? props.loader : false}
    >
      <InputHidden name="id" id="id" value={values.id} />
      {values.__currentTab === 'ident' && (
        <Row>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputDatetime
              label={props.intl.formatMessage({
                id: 'app.features.donation-origin.field.dono_ts.label',
                defaultMessage: 'dono_ts',
              })}
              id="dono_ts"
              name="dono_ts"
              value={values.dono_ts}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('dono_ts')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.donation-origin.field.dono_origin.label',
                defaultMessage: 'dono_origin',
              })}
              id="dono_origin"
              name="dono_origin"
              value={values.dono_origin}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('dono_origin')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.donation-origin.field.dono_year.label',
                defaultMessage: 'dono_year',
              })}
              id="dono_year"
              name="dono_year"
              value={values.dono_year}
              required={true}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('dono_year')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.donation-origin.field.dono_month.label',
                defaultMessage: 'dono_month',
              })}
              id="dono_month"
              name="dono_month"
              value={values.dono_month}
              required={true}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('dono_month')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.donation-origin.field.dono_day.label',
                defaultMessage: 'dono_day',
              })}
              id="dono_day"
              name="dono_day"
              value={values.dono_day}
              required={true}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('dono_day')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.donation-origin.field.dono_status.label',
                defaultMessage: 'dono_status',
              })}
              id="dono_status"
              name="dono_status"
              value={values.dono_status}
              required={true}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('dono_status')}
            />
          </Col>
        </Row>
      )}
      {values.__currentTab === 'comment' && (
        <Row>
          <Col size={{ xxs: 36, sm: 36 }}>
            <div dangerouslySetInnerHTML={{ __html: `${values.dono_comments}` }} />
          </Col>
        </Row>
      )}
    </ResponsiveModalOrForm>
  );
}

/**
 * Injection de l'intl
 */
export default injectIntl(Form);
