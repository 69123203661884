import React from 'react';
import { injectIntl } from 'react-intl';
import { getShortcuts, getStatus } from './';
import useForm from '../ui/useForm';
import { ResponsiveModalOrForm, InputHidden, InputText, InputSelect, Row, Col } from '../ui';
import { editionAsOptions } from '../edition';

/**
 * Initialisation
 *
 * @param {Object}   item
 * @param {Function} setItem
 */
const initItem = (item, setItem) => {};

/**
 * Un champ a été modifié
 *
 * @param {String}   field
 * @param {Object}   item
 * @param {Function} setitem
 */
const afterChange = (field, item, setItem) => {
  switch (field) {
    default:
      break;
  }
};

/**
 * Saisie des informations d'un modèle
 */
function Form(props) {
  let item = props.item;
  // On récupère tout ce qu'il faut pour travailler
  const {
    values,
    handleChange,
    handleSave,
    handleSubmit,
    handleCancel,
    handleNavTab,
    getErrorMessage,
    getCurrentTab,
  } = useForm(
    item,
    props.tab,
    props.onSubmit,
    props.onCancel,
    props.onNavTab,
    props.errors,
    props.intl,
    afterChange,
    initItem,
  );
  // Le formulaire
  return (
    <ResponsiveModalOrForm
      title={
        !props.modify && !values.cerg_name
          ? props.intl.formatMessage({
              id: 'app.features.certificate-generation.form.title',
              defaultMessage: 'New model',
            })
          : values.cerg_name
      }
      className=""
      tab={getCurrentTab()}
      tabs={!props.modify ? getShortcuts(props.intl, 'new') : getShortcuts(props.intl, 'all')}
      size="md"
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onNavTab={handleNavTab}
      onSave={handleSave}
      onClose={handleCancel}
      onPrevious={props.onPrevious}
      onNext={props.onNext}
      modal={true}
      actionsButtons={props.actionsButtons}
      loader={props.loader ? props.loader : false}
    >
      <InputHidden name="id" id="id" value={values.id} />
      <Row>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.certificate-generation.field.cerg_name.label',
              defaultMessage: 'cerg_name',
            })}
            id="cerg_name"
            name="cerg_name"
            value={values.cerg_name}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('cerg_name')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.certificate-generation.field.cerg_year.label',
              defaultMessage: 'cerg_year',
            })}
            id="cerg_year"
            name="cerg_year"
            value={values.cerg_year}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('cerg_year')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputSelect
            label={props.intl.formatMessage({
              id: 'app.features.certificate-generation.field.cerg_status.label',
              defaultMessage: 'cerg_status',
            })}
            id="cerg_status"
            name="cerg_status"
            value={values.cerg_status}
            required={true}
            labelTop={true}
            options={getStatus(props.intl)}
            onChange={handleChange}
            error={getErrorMessage('cerg_status')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputSelect
            label={props.intl.formatMessage({
              id: 'app.features.certificate-generation.field.edi_id.label',
              defaultMessage: 'edi_id',
            })}
            id="edition.id"
            name="edition.id"
            value={values.edition.id}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('edi_id')}
            options={editionAsOptions(props.editions, ['FreeAsso_Certificate'])}
          />
        </Col>
      </Row>
    </ResponsiveModalOrForm>
  );
}

/**
 * Injection de l'intl
 */
export default injectIntl(Form);
