import React from 'react';
import { injectIntl } from 'react-intl';
import { getShortcuts, getModeOptions, getSendMethodOptions } from './';
import useForm from '../ui/useForm';
import {
  ResponsiveModalOrForm,
  InputHidden,
  InputText,
  InputTextarea,
  InputEmail,
  InputDatetime,
  InputMonetary,
  InputSelect,
  InputSpin,
  Row,
  Col,
} from '../ui';
import { InputPicker as ClientInputPicker } from '../client';

/**
 * Initialisation
 *
 * @param {Object}   item
 * @param {Function} setItem
 */
const initItem = (item, setItem) => {};

/**
 * Un champ a été modifié
 *
 * @param {String}   field
 * @param {Object}   item
 * @param {Function} setitem
 */
const afterChange = (field, item, setItem) => {
  switch (field) {
    case 'client':
      const old_id = item.__client_id ? item.__client_id : null;
      const new_id = item.client && item.client.id ? item.client.id : null;
      if (old_id !== new_id) {
        if (item.client && item.client.id) {
          const fullname = item.client.cli_firstname + ' ' + item.client.cli_lastname;
          item.rec_fullname = fullname.trim();
          item.rec_address1 = item.client.cli_address1;
          item.rec_address2 = item.client.cli_address2;
          item.rec_address3 = item.client.cli_address3;
          item.rec_cp = item.client.cli_cp;
          item.rec_town = item.client.cli_town;
          item.country = item.client.country;
          item.lang = item.client.lang;
          item.rec_iren = item.client.cli_siren;
          item.rec_social_reason = item.client.cli_social_reason;
          item.rec_email = item.client.cli_email;
          item.rec_send_method = 'MANUAL';
          if (item.rec_email !== '') {
            item.rec_send_method = 'EMAIL';
          }
        }
      }
      item.__client_id = new_id;
      return item;
    default:
      break;
  }
};

/**
 * Saisie des informations d'un modèle
 */
function Form(props) {
  let item = props.item;
  // On récupère tout ce qu'il faut pour travailler
  const {
    values,
    handleChange,
    handleSave,
    handleSubmit,
    handleCancel,
    handleNavTab,
    getErrorMessage
  } = useForm(
    item,
    props.tab,
    props.onSubmit,
    props.onCancel,
    props.onNavTab,
    props.errors,
    props.intl,
    afterChange,
    initItem,
  );
  const readonlyForm = props.modify && item.rec_mode !== 'MANUAL';
  const cYear = new Date().getFullYear() - 2;
  const nYear = cYear + 4;

  // Le formulaire
  return (
    <ResponsiveModalOrForm
      title={
        !props.modify && !values.rec_fullname
          ? props.intl.formatMessage({
              id: 'app.features.receipt.form.title',
              defaultMessage: 'New model',
            })
          : values.rec_number
      }
      className=""
      tab={values.__currentTab}
      tabs={!props.modify ? getShortcuts(props.intl, 'new') : getShortcuts(props.intl, 'all')}
      size="md"
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onNavTab={handleNavTab}
      onSave={handleSave}
      onClose={handleCancel}
      onPrevious={props.onPrevious}
      onNext={props.onNext}
      modal={true}
      actionsButtons={props.actionsButtons}
      loader={props.loader ? props.loader : false}
    >
      <InputHidden name="id" id="id" value={values.id} />
      <Row>
        <Col size={{ xxs: 36, sm: 10 }}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.receipt.field.rec_number.label',
              defaultMessage: 'rec_number',
            })}
            id="rec_number"
            name="rec_number"
            value={values.rec_number}
            labelTop={true}
            disabled={readonlyForm}
            onChange={handleChange}
            error={getErrorMessage('rec_number')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 8 }}>
          <InputSpin
            label={props.intl.formatMessage({
              id: 'app.features.receipt.field.rec_year.label',
              defaultMessage: 'rec_year',
            })}
            id="rec_year"
            name="rec_year"
            value={values.rec_year}
            maxValue={nYear}
            minValue={cYear}
            labelTop={true}
            disabled={readonlyForm}
            onChange={handleChange}
            error={getErrorMessage('rec_year')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 6 }}>
          <InputSelect
            label={props.intl.formatMessage({
              id: 'app.features.receipt.field.rett_id.label',
              defaultMessage: 'rett_id',
            })}
            id="receipt_type.id"
            name="receipt_type.id"
            value={values.receipt_type ? values.receipt_type.id : null}
            labelTop={true}
            disabled={readonlyForm}
            options={props.receiptTypeOptions}
            onChange={handleChange}
            error={getErrorMessage('receipt_type.id')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 4 }}>
          <InputSelect
            label={props.intl.formatMessage({
              id: 'app.features.receipt.field.rec_mode.label',
              defaultMessage: 'rec_mode',
            })}
            id="rec_mode"
            name="rec_mode"
            value={values.rec_mode}
            required={true}
            labelTop={true}
            disabled={true}
            options={getModeOptions(props.intl)}
            onChange={handleChange}
            error={getErrorMessage('rec_mode')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 8 }}>
          <InputDatetime
            label={props.intl.formatMessage({
              id: 'app.features.receipt.field.rec_ts.label',
              defaultMessage: 'rec_ts',
            })}
            id="rec_ts"
            name="rec_ts"
            value={values.rec_ts}
            required={true}
            labelTop={true}
            disabled={readonlyForm}
            onChange={handleChange}
            error={getErrorMessage('rec_ts')}
          />
        </Col>
      </Row>
      {values.__currentTab === 'ident' && (
        <>
          <Row>
            <Col size={{ xxs: 36, sm: 18 }}>
              <ClientInputPicker
                label={props.intl.formatMessage({
                  id: 'app.features.receipt.field.cli_id.label',
                  defaultMessage: 'cli_id',
                })}
                id="client"
                name="client"
                item={values.client}
                labelTop={true}
                required={true}
                disabled={readonlyForm}
                onChange={handleChange}
                error={getErrorMessage('cli_id')}
              />
            </Col>
            <Col size={{ xxs: 36, sm: 6 }}>
              <InputSelect
                label={props.intl.formatMessage({
                  id: 'app.features.receipt.field.rec_send_method.label',
                  defaultMessage: 'rec_send_method',
                })}
                id="rec_send_method"
                name="rec_send_method"
                value={values.rec_send_method}
                required={true}
                labelTop={true}
                disabled={readonlyForm}
                options={getSendMethodOptions(props.intl)}
                onChange={handleChange}
                error={getErrorMessage('rec_send_method')}
              />
            </Col>
            <Col size={{ xxs: 36, sm: 12 }}>
              <InputEmail
                label={props.intl.formatMessage({
                  id: 'app.features.receipt.field.rec_email.label',
                  defaultMessage: 'rec_email',
                })}
                id="rec_email"
                name="rec_email"
                value={values.rec_email}
                labelTop={true}
                disabled={readonlyForm}
                onChange={handleChange}
                error={getErrorMessage('rec_email')}
              />
            </Col>
          </Row>
          <Row>
            <Col size={{ xxs: 36, sm: 20 }}>
              <InputText
                label={props.intl.formatMessage({
                  id: 'app.features.receipt.field.rec_title.label',
                  defaultMessage: 'rec_title',
                })}
                id="rec_title"
                name="rec_title"
                value={values.rec_title}
                labelTop={true}
                disabled={readonlyForm}
                onChange={handleChange}
                error={getErrorMessage('rec_title')}
              />
            </Col>
            <Col size={{ xxs: 36, sm: 8 }}>
              <InputSelect
                label={props.intl.formatMessage({
                  id: 'app.features.receipt.field.lang_id.label',
                  defaultMessage: 'lang_id',
                })}
                id="lang.id"
                name="lang.id"
                value={values.lang && values.lang.id}
                options={props.langOptions}
                labelTop={true}
                disabled={readonlyForm}
                onChange={handleChange}
                error={getErrorMessage('lang.id')}
              />
            </Col>
            <Col size={{ xxs: 36, sm: 8 }}>
              <InputDatetime
                label={props.intl.formatMessage({
                  id: 'app.features.receipt.field.rec_gen_ts.label',
                  defaultMessage: 'rec_gen_ts',
                })}
                id="rec_gen_ts"
                name="rec_gen_ts"
                value={values.rec_gen_ts}
                labelTop={true}
                disabled={readonlyForm}
                onChange={handleChange}
                error={getErrorMessage('rec_gen_ts')}
              />
            </Col>
          </Row>
          <Row>
            <Col size={{ xxs: 36, sm: 20 }}>
              <InputTextarea
                label={props.intl.formatMessage({
                  id: 'app.features.receipt.field.rec_detail.label',
                  defaultMessage: 'rec_detail',
                })}
                id="rec_detail"
                name="rec_detail"
                value={values.rec_detail}
                labelTop={true}
                disabled={false}
                onChange={handleChange}
                error={getErrorMessage('rec_detail')}
              />
            </Col>
            <Col size={{ xxs: 36, sm: 8 }}>
              <InputMonetary
                label={props.intl.formatMessage({
                  id: 'app.features.receipt.field.rec_mnt.label',
                  defaultMessage: 'Amount',
                })}
                name="rec_mnt"
                id="rec_mnt"
                required={true}
                labelTop={true}
                disabled={readonlyForm}
                inputMoney={values.rec_money}
                value={values.rec_mnt}
                onChange={handleChange}
              />
            </Col>
            <Col size={{ xxs: 36, sm: 8 }}>
              <InputDatetime
                label={props.intl.formatMessage({
                  id: 'app.features.receipt.field.rec_print_ts.label',
                  defaultMessage: 'rec_print_ts',
                })}
                id="rec_print_ts"
                name="rec_print_ts"
                value={values.rec_print_ts}
                labelTop={true}
                disabled={readonlyForm}
                onChange={handleChange}
                error={getErrorMessage('rec_print_ts')}
              />
            </Col>
          </Row>
          <Row>
            <Col size={{ xxs: 36 }}>
              <InputText
                label={props.intl.formatMessage({
                  id: 'app.features.receipt.field.rec_mnt_letter.label',
                  defaultMessage: 'rec_mnt_letter',
                })}
                id="rec_mnt_letter"
                name="rec_mnt_letter"
                value={values.rec_mnt_letter}
                labelTop={true}
                disabled={readonlyForm}
                onChange={handleChange}
                error={getErrorMessage('rec_mnt_letter')}
              />
            </Col>
          </Row>
        </>
      )}
      {values.__currentTab === 'client' && (
        <>
          <Row>
            <Col size={{ xxs: 36, sm: 20 }}>
              <InputText
                label={props.intl.formatMessage({
                  id: 'app.features.receipt.field.rec_fullname.label',
                  defaultMessage: 'rec_fullname',
                })}
                id="rec_fullname"
                name="rec_fullname"
                value={values.rec_fullname}
                labelTop={true}
                disabled={readonlyForm}
                onChange={handleChange}
                error={getErrorMessage('rec_fullname')}
              />
            </Col>
            <Col size={{ xxs: 36, sm: 8 }}>
              <InputText
                label={props.intl.formatMessage({
                  id: 'app.features.receipt.field.rec_siren.label',
                  defaultMessage: 'rec_siren',
                })}
                id="rec_siren"
                name="rec_siren"
                value={values.rec_siren}
                labelTop={true}
                disabled={false}
                onChange={handleChange}
                error={getErrorMessage('rec_siren')}
              />
            </Col>
            <Col size={{ xxs: 36, sm: 8 }}>
              <InputText
                label={props.intl.formatMessage({
                  id: 'app.features.receipt.field.rec_social_reason.label',
                  defaultMessage: 'rec_social_reason',
                })}
                id="rec_social_reason"
                name="rec_social_reason"
                value={values.rec_social_reason}
                labelTop={true}
                disabled={false}
                onChange={handleChange}
                error={getErrorMessage('rec_social_reason')}
              />
            </Col>
          </Row>
          <Row>
            <Col size={{ xxs: 36, sm: 20 }}>
              <InputText
                label={props.intl.formatMessage({
                  id: 'app.features.receipt.field.rec_address1.label',
                  defaultMessage: 'rec_address1',
                })}
                id="rec_address1"
                name="rec_address1"
                value={values.rec_address1}
                labelTop={true}
                disabled={false}
                onChange={handleChange}
                error={getErrorMessage('rec_address1')}
              />
            </Col>
            <Col size={{ xxs: 36, sm: 4 }}>
              <InputText
                label={props.intl.formatMessage({
                  id: 'app.features.receipt.field.rec_street_num.label',
                  defaultMessage: 'rec_street_num',
                })}
                id="rec_street_num"
                name="rec_street_num"
                value={values.rec_street_num}
                labelTop={true}
                disabled={false}
                onChange={handleChange}
                error={getErrorMessage('rec_street_num')}
              />
            </Col>
            <Col size={{ xxs: 36, sm: 12 }}>
              <InputText
                label={props.intl.formatMessage({
                  id: 'app.features.receipt.field.rec_street_name.label',
                  defaultMessage: 'rec_street_name',
                })}
                id="rec_street_name"
                name="rec_street_name"
                value={values.rec_street_name}
                labelTop={true}
                disabled={false}
                onChange={handleChange}
                error={getErrorMessage('rec_street_name')}
              />
            </Col>
          </Row>
          <Row>
            <Col size={{ xxs: 36, sm: 20 }}>
              <InputText
                id="rec_address2"
                name="rec_address2"
                value={values.rec_address2}
                labelTop={true}
                disabled={false}
                onChange={handleChange}
                error={getErrorMessage('rec_address2')}
              />
            </Col>
          </Row>
          <Row>
            <Col size={{ xxs: 36, sm: 20 }}>
              <InputText
                id="rec_address3"
                name="rec_address3"
                value={values.rec_address3}
                labelTop={true}
                disabled={false}
                onChange={handleChange}
                error={getErrorMessage('rec_address3')}
              />
            </Col>
          </Row>
          <Row>
            <Col size={{ xxs: 36, sm: 6 }}>
              <InputText
                label={props.intl.formatMessage({
                  id: 'app.features.receipt.field.rec_cp.label',
                  defaultMessage: 'rec_cp',
                })}
                id="rec_cp"
                name="rec_cp"
                value={values.rec_cp}
                labelTop={true}
                disabled={false}
                onChange={handleChange}
                error={getErrorMessage('rec_cp')}
              />
            </Col>
            <Col size={{ xxs: 36, sm: 14 }}>
              <InputText
                label={props.intl.formatMessage({
                  id: 'app.features.receipt.field.rec_town.label',
                  defaultMessage: 'rec_town',
                })}
                id="rec_town"
                name="rec_town"
                value={values.rec_town}
                labelTop={true}
                disabled={false}
                onChange={handleChange}
                error={getErrorMessage('rec_town')}
              />
            </Col>
            <Col size={{ xxs: 36, sm: 8 }}>
              <InputSelect
                label={props.intl.formatMessage({
                  id: 'app.features.receipt.field.cnty_id.label',
                  defaultMessage: 'cnty_id',
                })}
                id="country.id"
                name="country.id"
                value={values.country && values.country.id}
                labelTop={true}
                disabled={readonlyForm}
                options={props.countryOptions}
                onChange={handleChange}
                error={getErrorMessage('cnty_id')}
              />
            </Col>
          </Row>
        </>
      )}
    </ResponsiveModalOrForm>
  );
}

/**
 * Injection de l'intl
 */
export default injectIntl(Form);
