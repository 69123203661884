import {
  Home,
} from './';

export default {
  path: '/',
  name: 'Home',
  childRoutes: [
    { path: 'home', name: 'Home', component: Home, isIndex: true },
    { path: 'index.html', name: 'Home', component: Home, isIndex: true },
    { path: 'android_asset/www/index.html', name: 'Home', component: Home, isIndex: true },
  ],
};
