import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { jsonApiNormalizer, normalizedObjectModeler } from 'jsonapi-front';
import {
  NOTIFICATION_VALIDATE_ONE_BEGIN,
  NOTIFICATION_VALIDATE_ONE_SUCCESS,
  NOTIFICATION_VALIDATE_ONE_FAILURE,
  NOTIFICATION_VALIDATE_ONE_DISMISS_ERROR,
} from './constants';
import { freeAssoApi, propagateModel } from '../../../common';

export function validateOne(item = {}, propagate = true) {
  return (dispatch) => {
    dispatch({
      type: NOTIFICATION_VALIDATE_ONE_BEGIN,
    });
    const promise = new Promise((resolve, reject) => {
      const id = item.id;
      const doRequest = freeAssoApi.put('/v1/core/notification/validate/' + id);
      doRequest.then(
        result => {
          const object = jsonApiNormalizer(result.data);
          const item   = normalizedObjectModeler(object, 'FreeFW_Notification', id, { eager: true } );
          if (propagate) {
            dispatch(propagateModel('FreeFW_Notification', result));
          }
          dispatch({
            type: NOTIFICATION_VALIDATE_ONE_SUCCESS,
            data: result,
            item: item,
          });
          resolve(item);
        },
        (err) => {
          dispatch({
            type: NOTIFICATION_VALIDATE_ONE_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });
    return promise;
  };
}

export function dismissValidateOneError() {
  return {
    type: NOTIFICATION_VALIDATE_ONE_DISMISS_ERROR,
  };
}

export function useValidateOne() {
  const dispatch = useDispatch();

  const { validateOnePending, validateOneError } = useSelector(
    state => ({
      validateOnePending: state.notification.validateOnePending,
      validateOneError: state.notification.validateOneError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(validateOne(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissValidateOneError());
  }, [dispatch]);

  return {
    validateOne: boundAction,
    validateOnePending,
    validateOneError,
    dismissValidateOneError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case NOTIFICATION_VALIDATE_ONE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        validateOnePending: true,
        validateOneError: null,
      };

    case NOTIFICATION_VALIDATE_ONE_SUCCESS:
      // The request is success
      return {
        ...state,
        validateOnePending: false,
        validateOneError: null,
      };

    case NOTIFICATION_VALIDATE_ONE_FAILURE:
      // The request is failed
      return {
        ...state,
        validateOnePending: false,
        validateOneError: action.data.error,
      };

    case NOTIFICATION_VALIDATE_ONE_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        validateOneError: null,
      };

    default:
      return state;
  }
}
