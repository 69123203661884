import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  FILTER_OPER_LIKE,
  FILTER_OPER_EQUAL,
  FILTER_OPER_GREATER_OR_EQUAL,
  FILTER_OPER_LOWER_OR_EQUAL,
  FILTER_OPER_BETWEEN,
  FILTER_OPER_EMPTY,
  FILTER_OPER_NOT_EMPTY,
  ResponsiveContent,
  Container,
  Row,
  Col,
  randomString,
} from 'react-bootstrap-front';
import * as actions from './redux/actions';
import { InputCheckbox, InputMonetary, InputText, InputDate, messageError } from '../ui';
import { normalizedObjectModeler } from 'jsonapi-front';
import { Person as ClientIcon } from '../icons';
import { defaultFilters } from './redux/initFilters';

/**
 * Recherche liée au champ de saisie assistée du modèle (picker)
 */
export class SimpleSearch extends Component {
  static propTypes = {
  };
  static defaultProps = {
  };

  /**
   * Constructeur
   */
  constructor(props) {
    super(props);
    this.state = {
      cli_firstname: '',
      cli_lastname: '',
      cli_email: '',
      cli_address1: '',
      cli_cp: '',
      cli_town: '',
      cnty_name: '',
      clic_id: [],
      categories: [],
      ptyp_id: [],
      paymentTypes: [],
      ctyp_id: [],
      causeTypes: [],
      don_real_from: null,
      don_real_to: null,
      don_mnt_input_from: null,
      don_mnt_input_to: null,
      don_regular: true,
      don_once: true,
      spo_ts_from: null,
      spo_ts_to: null,
    };
    this.onChange = this.onChange.bind(this);
    this.init = this.init.bind(this);
    this.search = this.search.bind(this);
    this.allCategory = this.allCategory.bind(this);
    this.noCategory = this.noCategory.bind(this);
    this.allPaymentType = this.allPaymentType.bind(this);
    this.noPaymentType = this.noPaymentType.bind(this);
  }

  componentDidMount() {
    this.init();
  }

  noCategory() {
    const tab = this.state.clic_id;
    let newTab = [];
    tab.forEach((elem, idx) => {
      newTab[idx] = false;
    });
    this.setState({ clic_id: newTab });
  }

  allCategory() {
    const tab = this.state.clic_id;
    let newTab = [];
    tab.forEach((elem, idx) => {
      newTab[idx] = true;
    });
    this.setState({ clic_id: newTab });
  }

  noPaymentType() {
    const tab = this.state.ptyp_id;
    let newTab = [];
    tab.forEach((elem, idx) => {
      newTab[idx] = false;
    });
    this.setState({ ptyp_id: newTab });
  }

  allPaymentType() {
    const tab = this.state.ptyp_id;
    let newTab = [];
    tab.forEach((elem, idx) => {
      newTab[idx] = true;
    });
    this.setState({ ptyp_id: newTab });
  }

  init() {
    //
    const categories = normalizedObjectModeler(
      this.props.clientCategory.items,
      'FreeAsso_ClientCategory',
    );
    let clic_id = [];
    categories.forEach(elem => (clic_id[parseInt(elem.id, 10)] = true));
    //
    const paymentTypes = normalizedObjectModeler(
      this.props.paymentType.items,
      'FreeAsso_PaymentType',
    );
    let ptyp_id = [];
    paymentTypes.forEach(elem => (ptyp_id[parseInt(elem.id, 10)] = true));
    //
    const causeTypes = normalizedObjectModeler(
      this.props.causeType.items,
      'FreeAsso_CauseType',
    );
    let ctyp_id = [];
    causeTypes.forEach(elem => (ctyp_id[parseInt(elem.id, 10)] = true));
    //
    const myState = {
      cli_firstname: '',
      cli_lastname: '',
      cli_email: '',
      cli_address1: '',
      cli_cp: '',
      cli_town: '',
      cnty_name: '',
      clic_id: clic_id,
      categories: categories,
      ptyp_id: ptyp_id,
      paymentTypes: paymentTypes,
      ctyp_id: ctyp_id,
      causeTypes: causeTypes,
      don_real_from: null,
      don_real_to: null,
      don_mnt_input_from: null,
      don_mnt_input_to: null,
      don_regular: true,
      don_once: true,
      spo_ts_from: null,
      spo_ts_to: null,
    };
    this.setState({ ...myState });
  }

  search() {
    let errors = [];
    let newFilters = defaultFilters();
    // Données simple du donateur
    if (this.state.cli_firstname && this.state.cli_firstname !== '') {
      newFilters.addFilter('cli_firstname', this.state.cli_firstname, FILTER_OPER_LIKE);
    }
    if (this.state.cli_lastname && this.state.cli_lastname !== '') {
      newFilters.addFilter('cli_lastname', this.state.cli_lastname, FILTER_OPER_LIKE);
    }
    if (this.state.cli_address1 && this.state.cli_address1 !== '') {
      newFilters.addFilter('cli_address1', this.state.cli_address1, FILTER_OPER_LIKE);
    }
    if (this.state.cli_cp && this.state.cli_cp !== '') {
      newFilters.addFilter('cli_cp', this.state.cli_cp, FILTER_OPER_LIKE);
    }
    if (this.state.cli_town && this.state.cli_town !== '') {
      newFilters.addFilter('cli_town', this.state.cli_town, FILTER_OPER_LIKE);
    }
    if (this.state.cnty_name && this.state.cnty_name !== '') {
      newFilters.addFilter('country.cnty_name', this.state.cnty_name, FILTER_OPER_LIKE);
    }
    if (this.state.cli_email && this.state.cli_email !== '') {
      newFilters.addFilter('cli_email', this.state.cli_email, FILTER_OPER_LIKE);
    }
    // Catégories de donateur
    const tabT = this.state.clic_id.filter(elem => elem === true);
    if (tabT.length <= 0) {
      errors.push('Il faut au minimum sélectionner une catégorie');
    } else {
      if (tabT.length > 0 && tabT.length < this.state.categories.length) {
        let first = true;
        this.state.clic_id.forEach((elem, idx) => {
          if (elem === true) {
            if (first) {
              first = false;
              newFilters.addFilter('client_category.clic_id', idx, FILTER_OPER_EQUAL);
            } else {
              newFilters.updateFilter('client_category.clic_id', idx, randomString(8));
            }
          }
        });
      }
    }
    // Paiements
    if (this.state.don_real_from && this.state.don_real_to) {
      newFilters.addFilter('donations.don_real_ts', this.state.don_real_from, FILTER_OPER_BETWEEN);
      newFilters.updateFilter('donations.don_real_ts', this.state.don_real_to, 'between');
    } else {
      if (this.state.don_real_from) {
        newFilters.addFilter(
          'donations.don_real_ts',
          this.state.don_real_from,
          FILTER_OPER_GREATER_OR_EQUAL,
        );
      } else {
        if (this.state.don_real_to) {
          newFilters.addFilter(
            'donations.don_real_ts',
            this.state.don_real_to,
            FILTER_OPER_LOWER_OR_EQUAL,
          );
        }
      }
    }
    if (
      this.state.don_mnt_input_from !== null &&
      this.state.don_mnt_input_to !== null &&
      !isNaN(this.state.don_mnt_input_from) &&
      !isNaN(this.state.don_mnt_input_to)
    ) {
      newFilters.addFilter(
        'donations.don_mnt_input',
        this.state.don_mnt_input_from,
        FILTER_OPER_BETWEEN,
      );
      newFilters.updateFilter('donations.don_mnt_input', this.state.don_mnt_input_to, 'between');
    } else {
      if (this.state.don_mnt_input_from !== null && !isNaN(this.state.don_mnt_input_from)) {
        newFilters.addFilter(
          'donations.don_mnt_input',
          this.state.don_mnt_input_from,
          FILTER_OPER_GREATER_OR_EQUAL,
        );
      } else {
        if (this.state.don_mnt_input_to !== null && !isNaN(this.state.don_mnt_input_to)) {
          newFilters.addFilter(
            'donations.don_mnt_input',
            this.state.don_mnt_input_to,
            FILTER_OPER_LOWER_OR_EQUAL,
          );
        }
      }
    }
    // Amis
    if (
      this.state.spo_ts_from !== null &&
      this.state.spo_ts_to !== null
    ) {
      newFilters.addFilter(
        'sponsorships.spo_ts',
        this.state.spo_ts_from,
        FILTER_OPER_BETWEEN,
      );
      newFilters.updateFilter('sponsorships.spo_ts', this.state.spo_ts_to, 'between');
    } else {
      if (this.state.spo_ts_from !== null && !isNaN(this.state.spo_ts_from)) {
        newFilters.addFilter(
          'sponsorships.spo_ts',
          this.state.spo_ts_from,
          FILTER_OPER_GREATER_OR_EQUAL,
        );
      } else {
        if (this.state.spo_ts_to !== null && !isNaN(this.state.spo_ts_to)) {
          newFilters.addFilter(
            'sponsorships.spo_ts',
            this.state.spo_ts_to,
            FILTER_OPER_LOWER_OR_EQUAL,
          );
        }
      }
    }
    if (this.state.don_mnt_input && this.state.don_mnt_input !== '') {
      newFilters.addFilter('donations.don_mnt_input', this.state.don_mnt_input, FILTER_OPER_LIKE);
    }
    if (!this.state.don_regular && !this.state.don_once) {
      errors.push('Il faut au minimum sélectionner un paiement régulier ou ponctuel');
    } else {
      if (this.state.don_regular && !this.state.don_once) {
        newFilters.addFilter('donations.sponsorship.spo_id', 0, FILTER_OPER_NOT_EMPTY);
      } else {
        if (!this.state.don_regular && this.state.don_once) {
          newFilters.addFilter('donations.sponsorship.spo_id', 0, FILTER_OPER_EMPTY);
        }
      }
    }
    // Types de paiement
    const tabP = this.state.ptyp_id.filter(elem => elem === true);
    if (tabP.length <= 0) {
      errors.push('Il faut au minimum sélectionner un type de paiement');
    } else {
      if (tabP.length > 0 && tabP.length < this.state.paymentTypes.length) {
        let first = true;
        this.state.ptyp_id.forEach((elem, idx) => {
          if (elem === true) {
            if (first) {
              first = false;
              newFilters.addFilter('donations.payment_type.ptyp_id', idx, FILTER_OPER_EQUAL);
            } else {
              newFilters.updateFilter('donations.payment_type.ptyp_id', idx, randomString(8));
            }
          }
        });
      }
    }
    // Types de cause
    const tabC = this.state.ctyp_id.filter(elem => elem === true);
    if (tabC.length <= 0) {
      errors.push('Il faut au minimum sélectionner un programme');
    } else {
      if (tabC.length > 0 && tabC.length < this.state.causeTypes.length) {
        let first = true;
        this.state.ctyp_id.forEach((elem, idx) => {
          if (elem === true) {
            if (first) {
              first = false;
              newFilters.addFilter('donations.cause.cause_type.caut_id', idx, FILTER_OPER_EQUAL);
            } else {
              newFilters.updateFilter('donations.cause.cause_type.caut_id', idx, randomString(8));
            }
          }
        });
      }
    }
    // Finis
    if (errors.length <= 0) {
      this.props.actions.setFilters(newFilters);
      this.props.actions.clearItems();
      setTimeout(() => {
        const url = '/client';
        this.props.actions.push(url);
      }, 100);
    } else {
      errors.forEach(oneError => messageError(oneError));
    }
  }

  onChange(ev) {
    const { target } = ev;
    if (target) {
      switch (target.type) {
        case 'checkbox':
          const names = target.name.split('@@');
          switch (names[0]) {
            case 'don_regular':
              this.setState({ don_regular: target.checked || false });
              break;
            case 'don_once':
              this.setState({ don_once: target.checked || false });
              break;
            case 'ctyp_id':
              const oldsT = this.state.ctyp_id;
              oldsT[parseInt(names[1], 10)] = target.checked || false;
              this.setState({ ctyp_id: oldsT });
              break;
            case 'ptyp_id':
              const oldsP = this.state.ptyp_id;
              oldsP[parseInt(names[1], 10)] = target.checked || false;
              this.setState({ ptyp_id: oldsP });
              break;
            default:
              const oldsC = this.state.clic_id;
              oldsC[parseInt(names[1], 10)] = target.checked || false;
              this.setState({ clic_id: oldsC });
              break;
          }
          break;
        default:
          this.setState({ [target.name]: target.value });
          break;
      }
    }
  }

  /**
   * Render
   */
  render() {
    return (
      <ResponsiveContent
        header={true}
        icon={<ClientIcon />}
        headerContent={<span>Recherche de donateurs</span>}
      >
        <Container className="p-4 client-search">
          <Row>
            <Col size={{ xxs: 36, sm: 18, md: 9, lg: 7 }}>
              <h4 className="text-secondary">
                <b>Donateurs</b>
              </h4>
              <div className="p-2">
                <div className="mb-2">
                  <InputText
                    type="text"
                    className="form-control"
                    name="cli_lastname"
                    id="cli_lastname"
                    placeholder="Nom"
                    value={this.state.cli_lastname}
                    onChange={this.onChange}
                    label="Nom"
                  />
                </div>
                <div className="mb-2">
                  <InputText
                    type="text"
                    className="form-control"
                    name="cli_firstname"
                    id="cli_firstname"
                    placeholder="Prénom"
                    value={this.state.cli_firstname}
                    onChange={this.onChange}
                    label="Prénom"
                  />
                </div>
                <div className="mb-2">
                  <InputText
                    type="text"
                    className="form-control"
                    name="cli_email"
                    id="cli_email"
                    placeholder="Email"
                    value={this.state.cli_email}
                    onChange={this.onChange}
                    label="Email"
                  />
                </div>
                <div className="mb-2">
                  <InputText
                    type="text"
                    className="form-control"
                    name="cli_address1"
                    id="cli_address1"
                    placeholder="Adresse"
                    value={this.state.cli_address1}
                    onChange={this.onChange}
                    label="Adresse"
                  />
                </div>
                <div className="mb-2">
                  <InputText
                    type="text"
                    className="form-control"
                    name="cli_cp"
                    id="cli_cp"
                    placeholder="Code postal"
                    value={this.state.cli_cp}
                    onChange={this.onChange}
                    label="Code postal"
                  />
                </div>
                <div className="mb-2">
                  <InputText
                    type="text"
                    className="form-control"
                    name="cli_town"
                    id="cli_town"
                    placeholder="Ville"
                    value={this.state.cli_town}
                    onChange={this.onChange}
                    label="Ville"
                  />
                </div>
                <div className="mb-2">
                  <InputText
                    type="text"
                    className="form-control"
                    name="cnty_name"
                    id="cnty_name"
                    placeholder="Pays"
                    value={this.state.cnty_name}
                    onChange={this.onChange}
                    label="Pays"
                  />
                </div>
              </div>
            </Col>
            <Col size={{ xxs: 36, sm: 18, md: 9, lg: 7 }}>
              <h4 className="text-secondary">
                <b>Catégories</b>
              </h4>
              <div className="p-2">
                {this.state.categories &&
                  this.state.categories.map(elem => {
                    return (
                      <div className="mb-2" key={`clic-id-${elem.id}`}>
                        <InputCheckbox
                          type="checkbox"
                          name={'clic_id@@' + elem.id}
                          id={'clic_id@@' + elem.id}
                          checked={this.state.clic_id[parseInt(elem.id, 10)] === true}
                          onChange={this.onChange}
                          label={elem.clic_name}
                        />
                      </div>
                    );
                  })}
                <button className="btn btn-secondary mr-2" onClick={this.noCategory}>
                  Aucun
                </button>
                <button className="btn btn-secondary mr-2" onClick={this.allCategory}>
                  Tous
                </button>
              </div>
            </Col>
            <Col size={{ xxs: 36, sm: 18, md: 9, lg: 7 }}>
              <h4 className="text-secondary">
                <b>Paiements</b>
              </h4>
              <div className="p-2">
                <div className="mb-2">
                  <InputDate
                    className="form-control"
                    name="don_real_from"
                    id="don_real_from"
                    value={this.state.don_real_from}
                    onChange={this.onChange}
                    label="Effectué à partir du (inclus)"
                  />
                </div>
                <div className="mb-2">
                  <InputDate
                    className="form-control"
                    name="don_real_to"
                    id="don_real_to"
                    value={this.state.don_real_to}
                    onChange={this.onChange}
                    label="Avant le (inclus)"
                  />
                </div>
                <div className="mb-2">
                  <InputDate
                    className="form-control"
                    name="spo_ts_from"
                    id="spo_ts_from"
                    value={this.state.spo_ts_from}
                    onChange={this.onChange}
                    label="Ami enregistré de (inclus)"
                  />
                </div>
                <div className="mb-2">
                  <InputDate
                    className="form-control"
                    name="spo_ts_to"
                    id="spo_ts_to"
                    value={this.state.spo_ts_to}
                    onChange={this.onChange}
                    label="Ami jusqu'au (inclus)"
                  />
                </div>
                <div className="mb-2">
                  <InputMonetary
                    className="form-control"
                    name="don_mnt_input_from"
                    id="don_mnt_input_from"
                    value={this.state.don_mnt_input_from}
                    onChange={this.onChange}
                    label="Montant compris de (inclus)"
                    allowNull={true}
                  />
                </div>
                <div className="mb-2">
                  <InputMonetary
                    className="form-control"
                    name="don_mnt_input_to"
                    id="don_mnt_input_to"
                    value={this.state.don_mnt_input_to}
                    onChange={this.onChange}
                    label="Jusqu'à (inclus)"
                    allowNull={true}
                  />
                </div>
                <div className="mb-2">
                  <InputCheckbox
                    type="checkbox"
                    name={'don_regular'}
                    id={'don_regular'}
                    checked={this.state.don_regular === true}
                    onChange={this.onChange}
                    label="Paiement régulier"
                  />
                </div>
                <div className="mb-2">
                  <InputCheckbox
                    type="checkbox"
                    name={'don_once'}
                    id={'don_once'}
                    checked={this.state.don_once === true}
                    onChange={this.onChange}
                    label="Paiement ponctuel"
                  />
                </div>
              </div>
            </Col>
            <Col size={{ xxs: 36, sm: 18, md: 9, lg: 7 }}>
              <h4 className="text-secondary">
                <b>Types de paiement</b>
              </h4>
              <div className="p-2">
                {this.state.paymentTypes &&
                  this.state.paymentTypes.map(elem => {
                    return (
                      <div className="mb-2" key={`ptyp-id-${elem.id}`}>
                        <InputCheckbox
                          type="checkbox"
                          name={'ptyp_id@@' + elem.id}
                          id={'ptyp_id@@' + elem.id}
                          checked={this.state.ptyp_id[parseInt(elem.id, 10)] === true}
                          onChange={this.onChange}
                          label={elem.ptyp_name}
                        />
                      </div>
                    );
                  })}
                <button className="btn btn-secondary mr-2" onClick={this.noPaymentType}>
                  Aucun
                </button>
                <button className="btn btn-secondary mr-2" onClick={this.allPaymentType}>
                  Tous
                </button>
              </div>
            </Col>
            <Col size={{ xxs: 36, sm: 18, md: 9, lg: 7 }}>
            <h4 className="text-secondary">
                <b>Programmes</b>
              </h4>
              {this.state.causeTypes &&
                this.state.causeTypes.map(elem => {
                  return (
                    <div className="mb-2" key={`ctyp-id-${elem.id}`}>
                      <InputCheckbox
                        type="checkbox"
                        name={'ctyp_id@@' + elem.id}
                        id={'ctyp_id@@' + elem.id}
                        checked={this.state.ctyp_id[parseInt(elem.id, 10)] === true}
                        onChange={this.onChange}
                        label={elem.caut_name}
                      />
                    </div>
                  );
                })}
              <hr />
              <div className="pt-2">
                <button className="btn btn-block btn-warning mb-2" onClick={this.init}>
                  Initialiser la recherche
                </button>
                <button className="btn btn-block btn-primary mb-2" onClick={this.search}>
                  Lancer la recherche
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </ResponsiveContent>
    );
  }
}

function mapStateToProps(state) {
  return {
    clientCategory: state.clientCategory,
    clientType: state.clientType,
    paymentType: state.paymentType,
    causeType: state.causeType,

  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, push }, dispatch),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SimpleSearch));
