import React from 'react';
import { injectIntl } from 'react-intl';
import { InputHidden, InputText, InputSelect, Row, Col } from 'react-bootstrap-front';
import { deepClone, sanitize } from 'jsonapi-front';
import PropTypes from 'prop-types';
import { getEditionObjectNames, getEditionObjectTypes, getEditionObjectModes } from './';
import { useForm, ResponsiveModalOrForm, InputTextarea, InputFileContent, InputJson } from '../ui';
import { getlangLabel } from '../lang';

let id = 0;

/**
 * Add a new tab
 *
 * @param object item
 * @param object emptyVersion
 * @param array  langs
 */
const addTab = (item, emptyVersion, langs) => {
  if (!Array.isArray(item.versions)) {
    item.versions = [];
  }
  const newEmpty = deepClone(emptyVersion);
  id--;
  newEmpty.id = id;
  langs.every(lang => {
    const idx = item.versions.findIndex(elem => {
      return '' + elem.lang.id === '' + lang.value;
    });
    if (idx < 0) {
      newEmpty.lang.id = lang.value;
      item.versions.push(newEmpty);
      return false;
    }
    return true;
  });
};

/**
 * Remove tab
 *
 * @param object item
 * @param object tab
 * @param array  langs
 */
const delTab = (item, tab, langs) => {
  item.versions = item.versions.filter(elem => elem.id !== tab);
};

/**
 * Form
 *
 * @param object props
 */
function Form(props) {
  const tabs = [
    {
      id: 1,
      key: '1',
      name: 'edition',
      label: props.intl.formatMessage({
        id: 'app.features.edition.tab.main',
        defaultMessage: 'Edition',
      }),
      shortcut: 'E',
      icon: 'edition',
    },
    {
      id: 2,
      key: '2',
      name: 'mapping',
      label: props.intl.formatMessage({
        id: 'app.features.edition.tab.mapping',
        defaultMessage: 'Mapping',
      }),
      shortcut: 'M',
      icon: 'mapping',
    },
  ];
  const {
    values,
    handleChange,
    handleSubmit,
    handleCancel,
    handleNavTab,
    getErrorMessage,
  } = useForm(props.item, '1', props.onSubmit, props.onCancel, '', props.errors, props.intl);
  let versTabs = [];
  let indexV = 2;
  values.versions &&
    values.versions.forEach(oneVersion => {
      indexV++;
      versTabs.push({
        id: oneVersion.id,
        key: indexV,
        name: 'version' + indexV,
        label: getlangLabel(props.langs, oneVersion.lang.id),
        shortcut: 'E',
        icon: 'edition',
        removeable: true,
      });
    });
  let filename = values.edi_name ? sanitize(values.edi_name) : 'edition';
  if (values.lang && values.lang.id) {
    filename += '_' + values.lang.id;
  }
  if (values.edi_type) {
    switch (values.edi_type.toUpperCase()) {
      case 'IMPRESS':
        filename += '.odp';
        break;
      case 'WRITER':
        filename += '.odt';
        break;
      case 'PDF':
        filename += '.pdf';
        break;
      default:
        filename += '.txt';
        break;
    }
  }
  let mapping = {};
  if (values.edi_mapping) {
    mapping = JSON.parse(values.edi_mapping);
  }
  return (
    <ResponsiveModalOrForm
      title={props.intl.formatMessage({
        id: 'app.features.edition.form.title',
        defaultMessage: 'Edition',
      })}
      size="md"
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onNavTab={handleNavTab}
      onAddTab={() => {
        addTab(values, props.emptyVersion, props.langs);
        handleNavTab(values.versions.length + 2);
      }}
      onDelTab={tab => {
        delTab(values, tab, props.langs);
        if (values.versions.length > 0) {
          handleNavTab(values.versions.length + 2);
        } else {
          handleNavTab(1);
        }
      }}
      onClose={props.onClose}
      tab={values.__currentTab}
      tabs={tabs.concat(versTabs)}
      modal={true}
    >
      <InputHidden name="id" id="id" value={values.id} />
      <Row>
        <Col size={18}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.edition.form.name',
              defaultMessage: 'Name',
            })}
            name="edi_name"
            id="edi_name"
            required={true}
            value={values.edi_name}
            onChange={handleChange}
            error={getErrorMessage('edi_name')}
          />
        </Col>
        <Col size={9}>
          <InputSelect
            label={props.intl.formatMessage({
              id: 'app.features.edition.form.object_name',
              defaultMessage: 'Object',
            })}
            name="edi_object_name"
            id="edi_object_name"
            required={true}
            value={values.edi_object_name}
            onChange={handleChange}
            error={getErrorMessage('edi_object_name')}
            options={getEditionObjectNames(props.intl)}
          />
        </Col>
        <Col size={9}>
          <InputSelect
            label={props.intl.formatMessage({
              id: 'app.features.edition.form.lang',
              defaultMessage: 'Language',
            })}
            name="lang.id"
            id="lang.id"
            required={true}
            value={values.lang.id || null}
            onChange={handleChange}
            error={getErrorMessage('lang_id')}
            options={props.langs}
          />
        </Col>
      </Row>
      {values.__currentTab === '1' && (
        <>
          <Row>
            <Col size={18}>
              <InputSelect
                label={props.intl.formatMessage({
                  id: 'app.features.edition.form.type',
                  defaultMessage: 'Type',
                })}
                name="edi_type"
                id="edi_type"
                required={true}
                value={values.edi_type || 'HTML'}
                onChange={handleChange}
                error={getErrorMessage('edi_type')}
                options={getEditionObjectTypes(props.intl)}
              />
            </Col>
            <Col size={9}>
              <InputSelect
                label={props.intl.formatMessage({
                  id: 'app.features.edition.form.mode',
                  defaultMessage: 'Mode de génération',
                })}
                name="edi_mode"
                id="edi_mode"
                required={true}
                value={values.edi_mode || 'RENEW'}
                onChange={handleChange}
                error={getErrorMessage('edi_mode')}
                options={getEditionObjectModes(props.intl)}
              />
            </Col>
            <Col size={9}>
              <InputText
                label={props.intl.formatMessage({
                  id: 'app.features.edition.form.duration',
                  defaultMessage: 'Durée de conservation (j)',
                })}
                name="edi_duration"
                id="edi_duration"
                value={values.edi_duration}
                onChange={handleChange}
                error={getErrorMessage('edi_duration')}
              />
            </Col>
          </Row>
          <Row>
            <Col size={36}>
              <InputText
                label={props.intl.formatMessage({
                  id: 'app.features.edition.form.includes',
                  defaultMessage: 'Includes',
                })}
                name="edi_includes"
                id="edi_includes"
                value={values.edi_includes}
                onChange={handleChange}
                error={getErrorMessage('edi_includes')}
              />
            </Col>
          </Row>
          <Row>
            <Col size={36}>
              <InputTextarea
                label={props.intl.formatMessage({
                  id: 'app.features.edition.form.desc',
                  defaultMessage: 'Description',
                })}
                name="edi_desc"
                id="edi_desc"
                value={values.edi_desc}
                onChange={handleChange}
                error={getErrorMessage('edi_desc')}
              />
            </Col>
          </Row>
        </>
      )}
      {values.__currentTab === '2' && (
        <Row>
          <Col size={36}>
            <InputJson
              name="edi_mapping"
              value={mapping}
              labelTop
              mode="tree"
              onChange={ev => {
                let newEv = { ...ev };
                newEv.target.value = JSON.stringify(ev.target.value);
                handleChange(newEv);
              }}
            />
          </Col>
        </Row>
      )}
      {values.versions &&
        values.versions.map((version, idx) => {
          if (idx + 3 === parseInt(values.__currentTab, 10)) {
            return (
              <div>
                <Row>
                  <Col size={9}>
                    <InputSelect
                      label={props.intl.formatMessage({
                        id: 'app.features.edition.form.lang',
                        defaultMessage: 'Language',
                      })}
                      name={'versions.' + idx + '.lang.id'}
                      id={'versions.' + idx + '.lang.id'}
                      required={true}
                      value={version.lang.id || null}
                      onChange={handleChange}
                      error={getErrorMessage('lang_id')}
                      options={props.langs}
                    />
                  </Col>
                  <Col size={9}>
                    <InputText
                      label={props.intl.formatMessage({
                        id: 'app.features.edition.form.filename',
                        defaultMessage: 'Name',
                      })}
                      name={'versions.' + idx + '.edil_filename'}
                      id={'versions.' + idx + '.edil_filename'}
                      value={version.edil_filename || null}
                      onChange={handleChange}
                      error={getErrorMessage('edil_filename')}
                    />
                  </Col>
                  <Col size={12}>
                    <InputFileContent
                      label={props.intl.formatMessage({
                        id: 'app.features.edition.form.file',
                        defaultMessage: 'Fichier',
                      })}
                      name={'versions.' + idx + '.edil_data'}
                      id={'versions.' + idx + '.edil_data'}
                      required={true}
                      value={version.edil_data || null}
                      filename={filename}
                      onChange={handleChange}
                      error={getErrorMessage('edil_data')}
                    />
                  </Col>
                </Row>
              </div>
            );
          } else {
            return null;
          }
        })}
    </ResponsiveModalOrForm>
  );
}

Form.propTypes = {
  item: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
Form.defaultProps = {};

export default injectIntl(Form);
