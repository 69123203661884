import React from 'react';
// import PropTypes from 'prop-types';
import { ResponsiveQuickSearch as UIResponsiveQuickSearch } from 'react-bootstrap-front';
import { Search as SearchIcon } from '../icons';

export default function ResponsiveQuickSearch(props) {
  return (
    <UIResponsiveQuickSearch
      {...props}
      icon={<SearchIcon className="text-secondary" size={0.8} />}
    />
  );
}

ResponsiveQuickSearch.propTypes = {};
ResponsiveQuickSearch.defaultProps = {};
