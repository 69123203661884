import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { normalizedObjectModeler } from 'jsonapi-front';
import * as actions from './redux/actions';
import { getOneCauseAsModel } from '../cause';
import { getOneClientAsModel } from '../client';
import { getOneSponsorshipAsModel } from '../sponsorship';
import { PortalLoader, createSuccess, modifySuccess, showErrors } from '../ui';
import Form from './Form';

export class Input extends Component {
  static propTypes = {
    donation: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    loader: PropTypes.bool,
  };
  static defaultProps = {
    loader: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      id: props.donId || 0,
      donation: props.donation,
      item: false,
      saving: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    this.props.actions.loadOne(this.state.id).then(item => {
      item.__sessions = normalizedObjectModeler(this.props.session.items, 'FreeAsso_Session');
      if (this.state.id <= 0) {
        if (this.props.mode === 'client') {
          getOneClientAsModel(this.props.parentId).then(client => {
            item.client = client;
            this.setState({ item: item });
          });
        } else {
          if (this.props.mode === 'cause') {
            getOneCauseAsModel(this.props.parentId).then(cause => {
              item.cause = cause;
              this.setState({ item: item });
            });
          } else {
            if (this.props.mode === 'sponsorship') {
              getOneSponsorshipAsModel(this.props.parentId).then(sponsorship => {
                item.sponsorship = sponsorship
                item.cause = sponsorship.cause;
                item.client = sponsorship.client;
                item.don_mnt = sponsorship.spo_mnt;
                item.don_money = sponsorship.spo_money;
                item.don_mnt_input = sponsorship.spo_mnt_input;
                item.don_money_input = sponsorship.spo_money_input;
                item.payment_type = sponsorship.payment_type;
                this.setState({ item: item });
              });
            } else {
              this.setState({ item: item });
            }
          }
        }
      } else {
        this.setState({ item: item });
      }
    });
  }

  onCancel(event) {
    if (event) {
      event.preventDefault();
    }
    this.props.onClose();
  }

  onSubmit(datas = {}, close = true) {
    this.setState({ saving: true });
    if (this.state.id > 0) {
      this.props.actions
        .updateOne(this.state.id, datas)
        .then(item => {
          modifySuccess();
          if (this.props.onClose && close) {
            this.setState({ saving: false });
            this.props.onClose();
          } else {
            this.setState({ item: item, saving: false });
          }
        })
        .catch(errors => {
          this.setState({ saving: false });
          showErrors(this.props.intl, errors);
        });
    } else {
      this.props.actions
        .createOne(datas)
        .then(item => {
          createSuccess();
          if (this.props.onClose && close) {
            this.setState({ saving: false });
            this.props.onClose();
          } else {
            this.setState({ id: item.id, item: item, saving: false });
          }
        })
        .catch(errors => {
          this.setState({ saving: false });
          showErrors(this.props.intl, errors);
        });
    }
  }

  render() {
    const item = this.state.item;
    return (
      <div className="donation-modify global-card">
        {!item ? (
          <PortalLoader show={this.props.loader} />
        ) : (
          <div>
            {item && (
              <Form
                item={item}
                mode={this.props.mode}
                modal={true}
                realm_id={this.props.realm ? this.props.realm.id : 0}
                modify={this.state.id > 0}
                inputMoney={this.props.inputMoney || 'EUR'}
                dbMoney={'EUR'}
                donation={this.props.donation}
                paymentTypes={this.props.paymentType.items}
                sessions={this.props.session.items}
                rates={this.props.rate.models}
                errors={
                  this.state.id > 0
                    ? this.props.donation.updateOneError
                    : this.props.donation.createOneError
                }
                onSubmit={this.onSubmit}
                onCancel={this.onCancel}
                onClose={this.props.onClose}
                saving={this.state.saving}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    inputMoney: state.auth.inputMoney,
    displayMoney: state.auth.displayMoney,
    donation: state.donation,
    paymentType: state.paymentType,
    session: state.session,
    rate: state.rate,
    realm: state.auth.realm,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Input));
