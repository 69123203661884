import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  FILTER_OPER_LIKE,
  FILTER_OPER_GREATER_OR_EQUAL,
  FILTER_OPER_LOWER_OR_EQUAL,
  FILTER_OPER_BETWEEN,
  ResponsiveContent,
  Container,
  Row,
  Col,
} from 'react-bootstrap-front';
import * as actions from './redux/actions';
import { InputMonetary, InputText, messageError } from '../ui';
import { Person as ClientIcon } from '../icons';
import { defaultFilters } from './redux/initFilters';

/**
 * Recherche liée au champ de saisie assistée du modèle (picker)
 */
export class SimpleSearch extends Component {
  static propTypes = {
  };
  static defaultProps = {
  };

  /**
   * Constructeur
   */
  constructor(props) {
    super(props);
    this.state = {
      rec_number: '',
      rec_year: '',
      rec_fullname: '',
      rec_email: '',
      rec_address1: '',
      rec_cp: '',
      rec_town: '',
      cnty_name: '',
      rec_mnt_from: null,
      rec_mnt_to: null,
    };
    this.onChange = this.onChange.bind(this);
    this.init = this.init.bind(this);
    this.search = this.search.bind(this);
  }

  componentDidMount() {
    this.init();
  }

  init() {
    //
    const myState = {
      rec_number: '',
      rec_year: '',
      rec_fullname: '',
      rec_email: '',
      rec_address1: '',
      rec_cp: '',
      rec_town: '',
      cnty_name: '',
      rec_mnt_from: null,
      rec_mnt_to: null,
    };
    this.setState({ ...myState });
  }

  search() {
    let errors = [];
    let newFilters = defaultFilters();
    // Données simple du reçu
    if (this.state.rec_number && this.state.rec_number !== '') {
      newFilters.addFilter('rec_number', this.state.rec_number, FILTER_OPER_LIKE);
    }
    if (this.state.rec_year && this.state.rec_year !== '') {
      newFilters.addFilter('rec_year', this.state.rec_year, FILTER_OPER_LIKE);
    }
    // Données simple du donateur
    if (this.state.rec_fullname && this.state.rec_fullname !== '') {
      newFilters.addFilter('rec_fullname', this.state.rec_fullname, FILTER_OPER_LIKE);
    }
    if (this.state.rec_address1 && this.state.rec_address1 !== '') {
      newFilters.addFilter('rec_address1', this.state.rec_address1, FILTER_OPER_LIKE);
    }
    if (this.state.rec_cp && this.state.rec_cp !== '') {
      newFilters.addFilter('rec_cp', this.state.rec_cp, FILTER_OPER_LIKE);
    }
    if (this.state.rec_town && this.state.rec_town !== '') {
      newFilters.addFilter('rec_town', this.state.rec_town, FILTER_OPER_LIKE);
    }
    if (this.state.cnty_name && this.state.cnty_name !== '') {
      newFilters.addFilter('country.cnty_name', this.state.cnty_name, FILTER_OPER_LIKE);
    }
    if (this.state.rec_email && this.state.rec_email !== '') {
      newFilters.addFilter('rec_email', this.state.rec_email, FILTER_OPER_LIKE);
    }
    if (
      this.state.rec_mnt_from !== null &&
      this.state.rec_mnt_to !== null &&
      !isNaN(this.state.rec_mnt_from) &&
      !isNaN(this.state.rec_mnt_to)
    ) {
      newFilters.addFilter(
        'rec_mnt',
        this.state.rec_mnt_from,
        FILTER_OPER_BETWEEN,
      );
      newFilters.updateFilter('rec_mnt', this.state.rec_mnt_to, 'between');
    } else {
      if (this.state.rec_mnt_from !== null && !isNaN(this.state.rec_mnt_from)) {
        newFilters.addFilter(
          'rec_mnt',
          this.state.rec_mnt_from,
          FILTER_OPER_GREATER_OR_EQUAL,
        );
      } else {
        if (this.state.rec_mnt_to !== null && !isNaN(this.state.rec_mnt_to)) {
          newFilters.addFilter(
            'rec_mnt',
            this.state.rec_mnt_to,
            FILTER_OPER_LOWER_OR_EQUAL,
          );
        }
      }
    }
    // Finis
    if (errors.length <= 0) {
      this.props.actions.setFilters(newFilters);
      this.props.actions.clearItems();
      setTimeout(() => {
        const url = '/receipt';
        this.props.actions.push(url);
      }, 100);
    } else {
      errors.forEach(oneError => messageError(oneError));
    }
  }

  onChange(ev) {
    const { target } = ev;
    if (target) {
      switch (target.type) {
        default:
          this.setState({ [target.name]: target.value });
          break;
      }
    }
  }

  /**
   * Render
   */
  render() {
    return (
      <ResponsiveContent
        header={true}
        icon={<ClientIcon />}
        headerContent={<span>Recherche de reçus</span>}
      >
        <Container className="p-4 client-search">
          <Row>
            <Col size={{ xxs: 36, sm: 18, md: 9, lg: 7 }}>
              <h4 className="text-secondary">
                <b>Reçus</b>
              </h4>
              <div className="p-2">
                <div className="mb-2">
                  <InputText
                    type="text"
                    className="form-control"
                    name="rec_number"
                    id="rec_number"
                    placeholder="Numéro"
                    value={this.state.rec_number}
                    onChange={this.onChange}
                    label="Numéro"
                  />
                </div>
                <div className="mb-2">
                  <InputText
                    type="text"
                    className="form-control"
                    name="rec_year"
                    id="rec_year"
                    placeholder="Année"
                    value={this.state.rec_year}
                    onChange={this.onChange}
                    label="Année"
                  />
                </div>
                <div className="mb-2">
                  <InputMonetary
                    className="form-control"
                    name="rec_mnt_from"
                    id="rec_mnt_from"
                    value={this.state.rec_mnt_from}
                    onChange={this.onChange}
                    label="Montant compris de (inclus)"
                    allowNull={true}
                  />
                </div>
                <div className="mb-2">
                  <InputMonetary
                    className="form-control"
                    name="rec_mnt_to"
                    id="rec_mnt_to"
                    value={this.state.rec_mnt_to}
                    onChange={this.onChange}
                    label="Jusqu'à (inclus)"
                    allowNull={true}
                  />
                </div>
              </div>
            </Col>
            <Col size={{ xxs: 36, sm: 18, md: 9, lg: 7 }}>
              <h4 className="text-secondary">
                <b>Donateur</b>
              </h4>
              <div className="p-2">
                <div className="mb-2">
                  <InputText
                    type="text"
                    className="form-control"
                    name="rec_fullname"
                    id="rec_fullname"
                    placeholder="Nom, prénom"
                    value={this.state.rec_fullname}
                    onChange={this.onChange}
                    label="Nom, prénom"
                  />
                </div>
                <div className="mb-2">
                  <InputText
                    type="text"
                    className="form-control"
                    name="cli_email"
                    id="cli_email"
                    placeholder="Email"
                    value={this.state.cli_email}
                    onChange={this.onChange}
                    label="Email"
                  />
                </div>
                <div className="mb-2">
                  <InputText
                    type="text"
                    className="form-control"
                    name="rec_address1"
                    id="rec_address1"
                    placeholder="Adresse"
                    value={this.state.rec_address1}
                    onChange={this.onChange}
                    label="Adresse"
                  />
                </div>
                <div className="mb-2">
                  <InputText
                    type="text"
                    className="form-control"
                    name="rec_cp"
                    id="rec_cp"
                    placeholder="Code postal"
                    value={this.state.rec_cp}
                    onChange={this.onChange}
                    label="Code postal"
                  />
                </div>
                <div className="mb-2">
                  <InputText
                    type="text"
                    className="form-control"
                    name="rec_town"
                    id="rec_town"
                    placeholder="Ville"
                    value={this.state.rec_town}
                    onChange={this.onChange}
                    label="Ville"
                  />
                </div>
                <div className="mb-2">
                  <InputText
                    type="text"
                    className="form-control"
                    name="cnty_name"
                    id="cnty_name"
                    placeholder="Pays"
                    value={this.state.cnty_name}
                    onChange={this.onChange}
                    label="Pays"
                  />
                </div>
              </div>
            </Col>
            <Col size={{ xxs: 36, sm: 18, md: 9, lg: 7 }}>
              <div className="pt-5">
                <button className="btn btn-block btn-warning mb-2" onClick={this.init}>
                  Initialiser la recherche
                </button>
                <button className="btn btn-block btn-primary mb-2" onClick={this.search}>
                  Lancer la recherche
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </ResponsiveContent>
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, push }, dispatch),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SimpleSearch));
