import React from 'react';
// import PropTypes from 'prop-types';
import { InputSelect as UIInputSelect } from 'react-bootstrap-front';

export default function InputSelect(props) {
  return <UIInputSelect {...props} />;
}

InputSelect.propTypes = {};
InputSelect.defaultProps = {};
