import { getNewNormalizedObject } from 'jsonapi-front';
import { getInitFilters } from './initFilters';

/**
 * Initialisation du store pour l'objet
 */
const initialState = {
  objectName: 'FreeAsso_Receipt',
  items: getNewNormalizedObject('FreeAsso_Receipt'),
  currentId: 0,
  currentMode: 'none',
  currentIsFirst: true,
  currentIsLast: true,
  selected: [],
  page_number: 1,
  page_size: process.env.REACT_APP_PAGE_SIZE,
  tab: 'ident',
  filters: getInitFilters(),
  sort: [{ col: 'rec_year', way: 'down' }, { col: 'rec_fullname', way: 'up' }],
  include: 'client,receipt_type,country,lang',
  includeMore: 'client,receipt_type,country,lang',
  loadMorePending: false,
  loadMoreFinish: false,
  loadMoreError: null,
  loadOnePending: false,
  loadOneItem: null,
  loadOneError: null,
  createOnePending: false,
  createOneError: null,
  updateOnePending: false,
  updateOneError: null,
  generateOnePending: false,
  generateOneError: null,
  delOnePending: false,
  delOneError: null,
  printOnePending: false,
  printOneError: null,
  exportPending: false,
  exportError: null,
  setNextPending: false,
  setNextError: null,
  downloadOnePending: false,
  downloadOneError: null,
};

export default initialState;
