import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { normalizedObjectModeler } from 'jsonapi-front';
import { KalaLoader, ResponsiveInlineList } from '../ui';
import { getCols, getInlineActions } from './';

export class InlinePendings extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.actions.loadPendings(true);
  }

  render() {
    const cols = getCols(this);
    let items = [];
    let total = 0;
    if (this.props.history.pendings) {
      total = this.props.history.pendings.TOTAL;
      items = normalizedObjectModeler(this.props.history.pendings, 'FreeFW_History');
    }
    const inlineActions = getInlineActions(this);
    return (
      <div className="history-inline-list">
        <ResponsiveInlineList
          cols={cols}
          {...this.props}
          items={items}
          inlineActions={inlineActions}
          onMore={() => this.props.actions.loadPendings()}
          total={total}
          title={false}
          loading={false}
        />
        {this.props.history.loadPendingsPending && <KalaLoader />}
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    history: state.history,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(InlinePendings),
);
