import React from 'react';
import {
  AddOne as AddOneIcon,
  GetOne as GetOneIcon,
  DelOne as DelOneIcon,
  Print as PrintIcon,
} from '../icons';
import { displayColBool } from '../ui';
import { getClientFullname } from '../client';
import { groupAsOptions } from '../group';

/**
 * Shortcuts
 *
 * @param {Object} intl
 * @param {String} mode
 */
export const getShortcuts = (intl, mode = 'all') => {
  let shortcuts = [];
  shortcuts.push({
    name: 'ident',
    icon: '',
    size: 'maximized',
    display: 'block',
    position: 1,
    label: intl.formatMessage({
      id: 'app.features.sponsorship.form.tabs.ident',
      defaultMessage: 'Identification',
    }),
  });
  if (mode === 'all') {
    shortcuts.push({
      name: 'sponsors',
      icon: '',
      size: 'maximized',
      display: 'block',
      position: 1,
      label: intl.formatMessage({
        id: 'app.features.sponsorship.form.tabs.sponsors',
        defaultMessage: 'Invités',
      }),
    });
  }
  if (mode === 'all') {
    shortcuts.push({
      name: 'donations',
      icon: '',
      size: 'maximized',
      display: 'block',
      position: 1,
      label: intl.formatMessage({
        id: 'app.features.sponsorship.form.tabs.donations',
        defaultMessage: 'Paiements',
      }),
    });
    shortcuts.push({
      name: 'history',
      icon: '',
      size: 'maximized',
      display: 'block',
      position: 1,
      label: intl.formatMessage({
        id: 'app.features.sponsorship.form.tabs.history',
        defaultMessage: 'Paiements',
      }),
    });
  }
  return shortcuts;
};

/**
 * Gestion de l'affichage du champ en fonction du modèle
 */
export function getPickerDisplay(item) {
  let display = '';
  if (item && item.id > 0) {
    display = item.spo_from;
  }
  return display;
}

/**
 * Affichage des agents dans la liste des propositions de la recherhe
 * ou dans la liste proposées sous le champ en autocomplète
 */
export const displayItemPicker = item => {
  return (
    <p className="input-picker-line">
      <span className="input-picker-left">{item.spo_from}</span>
    </p>
  );
};

/**
 * Actions disponibles depuis l'en-tête de la liste des agents
 */
export const getGlobalActions = ({ props, onClearFilters, onCreate }) => {
  return [
    {
      name: 'create',
      label: props.intl.formatMessage({
        id: 'app.list.button.add',
        defaultMessage: 'Add',
      }),
      onClick: onCreate,
      theme: 'primary',
      icon: <AddOneIcon />,
      role: 'CREATE',
    },
  ];
};

/**
 * Actions disponibles sur la fiche (en plus de l'enregistrement et de l'annulation)
 */
export const getActionsButtons = ({ onPrint, state }) => {
  const { editions } = state;
  let myOptions = [];
  let printabled = false;
  if (editions && Array.isArray(editions) && editions.length > 0) {
    printabled = true;
    editions.forEach(edition => {
      myOptions.push({
        id: edition.id,
        label: edition.edi_name,
        onClick: () => onPrint(edition.id),
      });
    });
  }
  return [
    {
      theme: 'secondary',
      hidden: !printabled,
      options: myOptions,
      optionsAlign: 'top-left',
      function: () => onPrint(),
      icon: <PrintIcon title="Imprimer" />,
    },
  ];
};

/**
 * Actions disponibles depuis l'en-tête de la liste des agents pour agir sur les lignes de la liste
 *    Sélection lignes
 *    Dé-sélection
 *    ...
 *    Actions sur lignes sélectionnées
 *    ou sur toutes les lignes
 */
export const getSelectActions = ({ props, onSelectMenu }) => {
  let arrOne = [
    {
      name: 'selectAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.selectAll',
        defaultMessage: 'Select all',
      }),
      onClick: () => {
        onSelectMenu('selectAll');
      },
    },
  ];
  const arrAppend = [
    {
      name: 'selectNone',
      label: props.intl.formatMessage({
        id: 'app.list.options.deselectAll',
        defaultMessage: 'Deselect all',
      }),
      onClick: () => {
        onSelectMenu('selectNone');
      },
    },
    { name: 'divider' },
    {
      name: 'exportSelect',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportSelect',
        defaultMessage: 'Export selection',
      }),
      onClick: () => {
        onSelectMenu('exportSelection');
      },
    },
  ];
  if (props.sponsorship.selected.length > 0) {
    arrOne = arrOne.concat(arrAppend);
  }
  const arrStandard = [
    { name: 'divider' },
    {
      name: 'exportAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportAll',
        defaultMessage: 'Export all',
      }),
      onClick: () => {
        onSelectMenu('exportAll');
      },
    },
  ];
  arrOne = arrOne.concat(arrStandard);
  return arrOne;
};

/**
 * Actions disponibles sur une ligne de la liste des agents au survol de cette ligne
 */
export const getInlineActions = ({ props, onSelectList, onGetOne, onDelOne, onPrint, state }) => {
  let myEditions = [];
  if (state && state.editions) {
    const { editions } = state;
    editions.forEach(edition => {
      myEditions.push({ label: edition.edi_name, onClick: item => onPrint(edition.id, item) });
    });
  }
  return [
    {
      name: 'print',
      label: props.intl.formatMessage({
        id: 'app.list.button.print',
        defaultMessage: 'Print',
      }),
      onClick: onPrint,
      theme: 'secondary',
      icon: <PrintIcon />,
      role: 'PRINT',
      param: 'object',
      active: myEditions.length > 0,
      options: myEditions,
    },
    {
      name: 'modify',
      label: props.intl.formatMessage({
        id: 'app.list.button.modify',
        defaultMessage: 'Modify',
      }),
      onClick: onGetOne,
      theme: 'secondary',
      icon: <GetOneIcon />,
      role: 'MODIFY',
    },
    {
      name: 'delete',
      label: props.intl.formatMessage({
        id: 'app.list.button.delete',
        defaultMessage: 'Delete',
      }),
      onClick: onDelOne,
      theme: 'warning',
      icon: <DelOneIcon />,
      role: 'DELETE',
    },
  ];
};

const displayCause = item => {
  if (item && item.cause) {
    return item.cause.cau_name;
  }
  return null;
};

const displayClient = item => {
  if (item && item.client) {
    return getClientFullname(item.client);
  }
  return null;
};

/**
 * Descriptions de toutes les colonnes de la liste
 * Même les colonnes cachées
 * Ces colonnes peuvent ou non données des champs dans les filtres
 * (une info peut de pas être affichée dans la liste mais on peut peut-être mettre un filtre dessus)
 */
export const getCols = ({ props }) => {
  let cols = [];
  cols.push({
    name: 'spo_id',
    label: props.intl.formatMessage({
      id: 'app.features.sponsorship.field.spo_id.label',
      defaultMessage: 'spo_id',
    }),
    shortLabel: props.intl.formatMessage({
      id: 'app.features.sponsorship.field.spo_id.short',
      defaultMessage: 'spo_id',
    }),
    comment: props.intl.formatMessage({
      id: 'app.features.sponsorship.field.spo_id.comment',
      defaultMessage: 'spo_id',
    }),
    col: 'spo_id',
    size: { xxs: 36, sm: 12, md: 4 },
    title: true,
    sortable: true,
    hidden: true,
    card: { role: 'ID' },
    filterable: true,
  });
  if (props.mode !== 'cause') {
    cols.push({
      name: 'cau_id',
      label: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.cau_id.label',
        defaultMessage: 'cau_id',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.cau_id.short',
        defaultMessage: 'cau_id',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.cau_id.comment',
        defaultMessage: 'cau_id',
      }),
      col: 'cause',
      fDisplay: displayCause,
      size: { sm: 8, md: 7, lg: 6 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    });
  }
  if (props.mode !== 'client') {
    cols.push({
      name: 'cli_id',
      label: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.cli_id.label',
        defaultMessage: 'cli_id',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.cli_id.short',
        defaultMessage: 'cli_id',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.cli_id.comment',
        defaultMessage: 'cli_id',
      }),
      col: 'client',
      fDisplay: displayClient,
      size: { sm: 8, md: 7, lg: 6 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    });
  }
  cols.push(
    {
      name: 'spo_mnt_input',
      label: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_mnt_input.label',
        defaultMessage: 'spo_mnt_input',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_mnt_input.short',
        defaultMessage: 'spo_mnt_input',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_mnt_input.comment',
        defaultMessage: 'spo_mnt_input',
      }),
      col: 'spo_mnt_input',
      size: { xxs: 36, sm: 5, md: 4 },
      title: true,
      sortable: true,
      type: 'monetary',
      col_money: 'spo_money_input',
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'spo_from',
      label: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_from.label',
        defaultMessage: 'spo_from',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_from.short',
        defaultMessage: 'spo_from',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_from.comment',
        defaultMessage: 'spo_from',
      }),
      col: 'spo_from',
      size: { sm: 5, md: 4, xl: 3 },
      title: true,
      sortable: true,
      card: { role: 'TITLE' },
      filterable: { type: 'date' },
      type: 'date',
    },
    {
      name: 'spo_to',
      label: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_to.label',
        defaultMessage: 'spo_to',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_to.short',
        defaultMessage: 'spo_to',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_to.comment',
        defaultMessage: 'spo_to',
      }),
      col: 'spo_to',
      size: { sm: 4, md: 3, xl: 2 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: { type: 'date' },
      type: 'date',
    },
    {
      name: 'spo_ts',
      label: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_ts.label',
        defaultMessage: 'spo_ts',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_ts.short',
        defaultMessage: 'spo_ts',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_ts.comment',
        defaultMessage: 'spo_ts',
      }),
      col: 'spo_ts',
      size: { sm: 4, md: 3, xl: 2 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: { type: 'date' },
      type: 'date',
    },
    {
      name: 'ptyp_id',
      label: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.ptyp_id.label',
        defaultMessage: 'ptyp_id',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.ptyp_id.short',
        defaultMessage: 'ptyp_id',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.ptyp_id.comment',
        defaultMessage: 'ptyp_id',
      }),
      col: 'payment_type.ptyp_name',
      size: { sm: 6, md: 5, lg: 4, xl: 3 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'spo_mnt',
      label: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_mnt.label',
        defaultMessage: 'spo_mnt',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_mnt.short',
        defaultMessage: 'spo_mnt',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_mnt.comment',
        defaultMessage: 'spo_mnt',
      }),
      col: 'spo_mnt',
      hidden: true,
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'spo_money',
      label: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_money.label',
        defaultMessage: 'spo_money',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_money.short',
        defaultMessage: 'spo_money',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_money.comment',
        defaultMessage: 'spo_money',
      }),
      col: 'spo_money',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      hidden: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'spo_freq',
      label: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_freq.label',
        defaultMessage: 'spo_freq',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_freq.short',
        defaultMessage: 'spo_freq',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_freq.comment',
        defaultMessage: 'spo_freq',
      }),
      col: 'spo_freq',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'spo_freq_when',
      label: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_freq_when.label',
        defaultMessage: 'spo_freq_when',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_freq_when.short',
        defaultMessage: 'spo_freq_when',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_freq_when.comment',
        defaultMessage: 'spo_freq_when',
      }),
      col: 'spo_freq_when',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      hidden: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'spo_freq_detail',
      label: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_freq_detail.label',
        defaultMessage: 'spo_freq_detail',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_freq_detail.short',
        defaultMessage: 'spo_freq_detail',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_freq_detail.comment',
        defaultMessage: 'spo_freq_detail',
      }),
      col: 'spo_freq_detail',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      hidden: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'spo_sponsors',
      label: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_sponsors.label',
        defaultMessage: 'spo_sponsors',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_sponsors.short',
        defaultMessage: 'spo_sponsors',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_sponsors.comment',
        defaultMessage: 'spo_sponsors',
      }),
      col: 'spo_sponsors',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      hidden: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'spo_display_site',
      label: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_display_site.label',
        defaultMessage: 'spo_display_site',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_display_site.short',
        defaultMessage: 'spo_display_site',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_display_site.comment',
        defaultMessage: 'spo_display_site',
      }),
      col: 'spo_display_site',
      size: { xxs: 36, sm: 4, md: 3 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: { type: 'bool' },
      type: 'bool',
      values: displayColBool,
    },
    {
      name: 'spo_send_news',
      label: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_send_news.label',
        defaultMessage: 'spo_send_news',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_send_news.short',
        defaultMessage: 'spo_send_news',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_send_news.comment',
        defaultMessage: 'spo_send_news',
      }),
      col: 'spo_send_news',
      size: { xxs: 36, sm: 4, md: 3 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: { type: 'bool' },
      type: 'bool',
      values: displayColBool,
    },
    {
      name: 'spo_money_input',
      label: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_money_input.label',
        defaultMessage: 'spo_money_input',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_money_input.short',
        defaultMessage: 'spo_money_input',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_money_input.comment',
        defaultMessage: 'spo_money_input',
      }),
      col: 'spo_money_input',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'spo_freq_when_cpl',
      label: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_freq_when_cpl.label',
        defaultMessage: 'spo_freq_when_cpl',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_freq_when_cpl.short',
        defaultMessage: 'spo_freq_when_cpl',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_freq_when_cpl.comment',
        defaultMessage: 'spo_freq_when_cpl',
      }),
      col: 'spo_freq_when_cpl',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'spo_add_first',
      label: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_add_first.label',
        defaultMessage: 'spo_add_first',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_add_first.short',
        defaultMessage: 'spo_add_first',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.sponsorship.field.spo_add_first.comment',
        defaultMessage: 'spo_add_first',
      }),
      col: 'spo_add_first',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: { type: 'bool' },
      type: 'bool',
      hidden: true,
      values: displayColBool,
    },
    {
      name: 'grp_id',
      label: 'Ass.',
      col: 'group.id',
      size: { sm: 6, md: 3, lg: 2 },
      mob_size: '',
      title: true,
      sortable: true,
      type: 'switch',
      values: groupAsOptions(),
      filterable: {
        type: 'select',
        options: groupAsOptions(),
      },
    },
  );
  return cols;
};
