import React from 'react';
import {
  AddOne as AddOneIcon,
  GetOne as GetOneIcon,
  DelOne as DelOneIcon,
} from '../icons';
import { getEditionObjectNames } from './';

export const getGlobalActions = ({ props, onCreate }) => {
  return [
    {
      name: 'create',
      label: props.intl.formatMessage({ id: 'app.list.button.add', defaultMessage: 'Add' }),
      onClick: onCreate,
      theme: 'primary',
      icon: <AddOneIcon color="white" />,
      role: 'CREATE',
    },
  ];
};

export const getInlineActions = ({ props, onGetOne, onDelOne }) => {
  return [
    {
      name: 'modify',
      label: props.intl.formatMessage({ id: 'app.list.button.modify', defaultMessage: 'Modify' }),
      onClick: onGetOne,
      theme: 'secondary',
      icon: <GetOneIcon color="white" />,
      role: 'MODIFY',
    },
    {
      name: 'delete',
      label: props.intl.formatMessage({ id: 'app.list.button.delete', defaultMessage: 'Delete' }),
      onClick: onDelOne,
      theme: 'warning',
      icon: <DelOneIcon color="white" />,
      role: 'DELETE',
    },
  ];
};

export const getCols = ({ props }) => {
  return [
    {
      name: 'name',
      label: props.intl.formatMessage({
        id: 'app.features.edition.list.col.name',
        defaultMessage: 'Name',
      }),
      col: 'edi_name',
      size: '10',
      mob_size: '',
      title: true,
      sortable: true,
      first: true,
    },
    {
      name: 'object',
      label: props.intl.formatMessage({
        id: 'app.features.edition.list.col.object',
        defaultMessage: 'Object',
      }),
      col: 'edi_object_name',
      size: '10',
      mob_size: '',
      title: true,
      sortable: true,
      last: true,
      type: 'switch',
      values: getEditionObjectNames(props.intl),
    },
  ];
};
