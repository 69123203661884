export const COMMON_TOGGLE_SIDEBAR = 'COMMON_TOGGLE_SIDEBAR';
export const COMMON_SET_COORDS = 'COMMON_SET_COORDS';
export const COMMON_SET_LOCALE = 'COMMON_SET_LOCALE';
export const COMMON_SET_ONLINE = 'COMMON_SET_ONLINE';
export const COMMON_SET_OFFLINE = 'COMMON_SET_OFFLINE';
export const COMMON_SYNC_ON = 'COMMON_SYNC_ON';
export const COMMON_SYNC_OFF = 'COMMON_SYNC_OFF';
export const COMMON_ENABLE_SYNC = 'COMMON_ENABLE_SYNC';
export const COMMON_DISABLE_SYNC = 'COMMON_DISABLE_SYNC';
export const COMMON_CLOSE_SOCKET = 'COMMON_CLOSE_SOCKET';
export const COMMON_INIT_SOCKET = 'COMMON_INIT_SOCKET';
export const COMMON_SOCKET_ON = 'COMMON_SOCKET_ON';
export const COMMON_SOCKET_OFF = 'COMMON_SOCKET_OFF';
export const COMMON_TOGGLE_RIGHT_PANEL = 'COMMON_TOGGLE_RIGHT_PANEL';
export const COMMON_SET_FILTERS_COLS = 'COMMON_SET_FILTERS_COLS';
export const COMMON_SET_PANEL = 'COMMON_SET_PANEL';
export const COMMON_SYNC_READY = 'COMMON_SYNC_READY';
export const COMMON_SYNC_PENDING = 'COMMON_SYNC_PENDING';
export const COMMON_SET_PANEL_OBJ = 'COMMON_SET_PANEL_OBJ';
