import React from 'react';
import { normalizedObjectModeler } from 'jsonapi-front';
import {
  AddOne as AddOneIcon,
  GetOne as GetOneIcon,
  DelOne as DelOneIcon,
  EmailTest as EmailTestIcon,
} from '../icons';
import { getEmailObjectNames } from './';

const otherLangs = (item) => {
  let langs = [];
  if (item && item.versions) {
    if (Array.isArray(item.versions)) {
      item.versions.forEach(version => {
        if (version.lang) {
          langs.push(version.lang.lang_name);
        }
      });
    }
  }
  return langs.join(', ');
}

export const getGlobalActions = ({ props, onClearFilters, onCreate }) => {
  return [
    {
      name: 'create',
      label: props.intl.formatMessage({ id: 'app.list.button.add', defaultMessage: 'Add' }),
      onClick: onCreate,
      theme: 'primary',
      icon: <AddOneIcon color="white" />,
      role: 'CREATE',
    },
  ];
};

export const getInlineActions = ({ props, onGetOne, onDelOne, onSendOne }) => {
  let tests = [];
  const langs = normalizedObjectModeler(props.lang.items, 'FreeFW_Lang');
  langs.forEach(lang => {
    if (lang.lang_code) {
      tests.push({ label: lang.lang_name, onClick: elem => onSendOne(elem, lang.lang_code) });
    }
  });
  console.log(tests);
  //label: edition.edi_name + ' (' + lang + ')',
  //onClick: item => onPrintOne(edition.id, item, lang),
  return [
    {
      name: 'test',
      label: props.intl.formatMessage({ id: 'app.list.button.test', defaultMessage: 'Test' }),
      onClick: onSendOne,
      theme: 'secondary',
      icon: <EmailTestIcon />,
      role: 'ACTION',
      options: tests,
      param: 'object',
    },
    {
      name: 'modify',
      label: props.intl.formatMessage({ id: 'app.list.button.modify', defaultMessage: 'Modify' }),
      onClick: onGetOne,
      theme: 'secondary',
      icon: <GetOneIcon color="white" />,
      role: 'MODIFY',
    },
    {
      name: 'delete',
      label: props.intl.formatMessage({ id: 'app.list.button.delete', defaultMessage: 'Delete' }),
      onClick: onDelOne,
      theme: 'warning',
      icon: <DelOneIcon color="white" />,
      role: 'DELETE',
    },
  ];
};

export const getCols = ({ props }) => {
  return [
    {
      name: 'subject',
      label: props.intl.formatMessage({
        id: 'app.features.email.list.col.name',
        defaultMessage: 'Name',
      }),
      col: 'email_subject',
      size: {sm: 20, md: 14, xl: 10},
      mob_size: '',
      title: true,
      sortable: true,
      first: true,
      filterable: { type: 'text' },
      card: { role: 'TITLE' },
    },
    {
      name: 'code',
      label: props.intl.formatMessage({
        id: 'app.features.email.list.col.objetName',
        defaultMessage: 'For',
      }),
      col: 'email_object_name',
      size: {sm: 10, md: 6, xl: 4},
      mob_size: '',
      sortable: true,
      type: 'switch',
      values: getEmailObjectNames(props.intl),
      filterable: {
        type: 'select',
        options: getEmailObjectNames(props.intl),
      },
    },
    {
      name: 'lang',
      label: props.intl.formatMessage({
        id: 'app.features.email.list.col.lang',
        defaultMessage: 'Lang',
      }),
      col: 'lang.lang_name',
      size: {sm: 10, md: 6, xl: 4},
      mob_size: '',
      sortable: true,
      last: true,
      type: 'text',
      filterable: {
        type: 'text',
      },
    },
    {
      name: 'others',
      label: props.intl.formatMessage({
        id: 'app.features.email.list.col.langs',
        defaultMessage: 'Langs',
      }),
      col: 'lang.lang_name',
      size: {sm: 10, md: 8, xl: 6},
      mob_size: '',
      sortable: true,
      last: true,
      type: 'text',
      fDisplay: otherLangs,
      filterable: false,
    },
  ];
};
