import React from 'react';
import {
  GetOne as GetOneIcon,
  Print as PrintIcon,
  View as ViewIcon,
} from '../icons';
import { getLibObject } from '../../common';

export const getTypeOptions = intl => {
  const arr = [
    {
      value: 'EMAIL',
      label: intl.formatMessage({
        id: 'app.features.message.type.EMAIL',
        defaultMessage: 'EMAIL',
      }),
    },
  ];
  return arr;
};

export const getStatusOptions = intl => {
  const arr = [
    {
      value: 'ERROR',
      label: intl.formatMessage({
        id: 'app.features.message.status.ERROR',
        defaultMessage: 'ERROR',
      }),
    },
    {
      value: 'WAITING',
      label: intl.formatMessage({
        id: 'app.features.message.status.WAITING',
        defaultMessage: 'WAITING',
      }),
    },
    {
      value: 'PENDING',
      label: intl.formatMessage({
        id: 'app.features.message.status.PENDING',
        defaultMessage: 'PENDING',
      }),
    },
    {
      value: 'OK',
      label: intl.formatMessage({
        id: 'app.features.message.status.OK',
        defaultMessage: 'OK',
      }),
    },
  ];
  return arr;
};

/**
 * Get object label
 *
 * @param {Object} item
 *
 * @return {String}
 */
export const localObjectLabel = item => {
  return getLibObject(item.msg_object_name, item.msg_object_id);
};

/**
 * First dest of email
 *
 * @param {Object} item
 *
 * @return {String}
 */
export const localFirstDest = item => {
  try {
    const parsed = JSON.parse(item.msg_dest);
    if (Array.isArray(parsed)) {
      const first = parsed[0];
      if (first && first.address) {
        return first.address;
      }
    }
  } catch (ex) {}
  return item.msg_dest;
};

/**
 * Get className for current line
 *
 * @param {Object} item
 *
 * @return {String}
 */
export const lineClassName = item => {
  let color = '';
  if (item) {
    switch (item.msg_status) {
      case 'WAITING':
        color = 'row-line-secondary';
        break;
      case 'ERROR':
        color = 'row-line-danger';
        break;
      case 'PENDING':
        color = 'row-line-primary';
        break;
      default:
        break;
    }
  }
  return color;
};

/**
 * Get status label
 *
 * @param {Object} intl
 * @param {Object} item
 *
 * @return {String}
 */
export const statusLabel = (intl, item) => {
  if (intl && item) {
    return intl.formatMessage({
      id: 'app.features.message.status.' + item.msg_status,
      defaultMessage: item.msg_status,
    });
  }
  return item.jobq_status;
};

/**
 * Shortcuts
 *
 * @param {Object} intl
 * @param {String} mode
 */
export const getShortcuts = (intl, mode = 'all') => {
  let shortcuts = [];
  shortcuts.push({
    name: 'ident',
    icon: '',
    size: 'maximized',
    display: 'block',
    position: 1,
    label: intl.formatMessage({
      id: 'app.features.message.form.tabs.ident',
      defaultMessage: 'Identification',
    }),
  });
  if (mode === 'all') {
    shortcuts.push({
      name: 'content',
      icon: '',
      size: 'maximized',
      display: 'block',
      position: 2,
      label: intl.formatMessage({
        id: 'app.features.message.form.tabs.content',
        defaultMessage: 'Contenu',
      }),
    });
    shortcuts.push({
      name: 'pjs',
      icon: '',
      size: 'maximized',
      display: 'block',
      position: 2,
      label: intl.formatMessage({
        id: 'app.features.message.form.tabs.pjs',
        defaultMessage: 'Pièces jointes',
      }),
    });
  }
  return shortcuts;
};

/**
 * Gestion de l'affichage du champ en fonction du modèle
 */
export function getPickerDisplay(item) {
  let display = '';
  if (item && item.id > 0) {
    display = item.msg_body;
  }
  return display;
}

/**
 * Affichage des agents dans la liste des propositions de la recherhe
 * ou dans la liste proposées sous le champ en autocomplète
 */
export const displayItemPicker = item => {
  return (
    <p className="input-picker-line">
      <span className="input-picker-left">{item.msg_body}</span>
    </p>
  );
};

/**
 * Actions disponibles depuis l'en-tête de la liste des agents
 */
export const getGlobalActions = ({ props, onClearFilters, onCreate }) => {
  return [
    /*
    {
      name: 'create',
      label: props.intl.formatMessage({
        id: 'app.list.button.add',
        defaultMessage: 'Add',
      }),
      onClick: onCreate,
      theme: 'primary',
      icon: <AddOneIcon />,
      role: 'CREATE',
    },*/
  ];
};

/**
 * Actions disponibles sur la fiche (en plus de l'enregistrement et de l'annulation)
 */
export const getActionsButtons = ({ onPrint, state }) => {
  const { editions } = state;
  let myOptions = [];
  let printabled = false;
  if (editions && Array.isArray(editions) && editions.length > 0) {
    printabled = true;
    editions.forEach(edition => {
      myOptions.push({
        id: edition.id,
        label: edition.edi_name,
        onClick: () => onPrint(edition.id),
      });
    });
  }
  return [
    {
      theme: 'secondary',
      hidden: !printabled,
      options: myOptions,
      optionsAlign: 'top-left',
      function: () => onPrint(),
      icon: <PrintIcon title="Imprimer" />,
    },
  ];
};

/**
 * Actions disponibles depuis l'en-tête de la liste des agents pour agir sur les lignes de la liste
 *    Sélection lignes
 *    Dé-sélection
 *    ...
 *    Actions sur lignes sélectionnées
 *    ou sur toutes les lignes
 */
export const getSelectActions = ({ props, onSelectMenu }) => {
  let arrOne = [
    {
      name: 'selectAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.selectAll',
        defaultMessage: 'Select all',
      }),
      onClick: () => {
        onSelectMenu('selectAll');
      },
    },
  ];
  const arrAppend = [
    {
      name: 'selectNone',
      label: props.intl.formatMessage({
        id: 'app.list.options.deselectAll',
        defaultMessage: 'Deselect all',
      }),
      onClick: () => {
        onSelectMenu('selectNone');
      },
    },
    { name: 'divider' },
    {
      name: 'exportSelect',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportSelect',
        defaultMessage: 'Export selection',
      }),
      onClick: () => {
        onSelectMenu('exportSelection');
      },
    },
  ];
  if (props.message.selected.length > 0) {
    arrOne = arrOne.concat(arrAppend);
  }
  const arrStandard = [
    { name: 'divider' },
    {
      name: 'exportAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportAll',
        defaultMessage: 'Export all',
      }),
      onClick: () => {
        onSelectMenu('exportAll');
      },
    },
  ];
  arrOne = arrOne.concat(arrStandard);
  return arrOne;
};

/**
 * Actions disponibles sur une ligne de la liste des agents au survol de cette ligne
 */
export const getInlineActions = ({ props, onGetObject, onSelectList, onGetOne, onDelOne, onPrint, state }) => {
  return [
    {
      name: 'view',
      label: props.intl.formatMessage({
        id: 'app.list.button.view',
        defaultMessage: 'Visualiser',
      }),
      onClick: onGetObject,
      theme: 'secondary',
      icon: <ViewIcon />,
      param: "object",
      role: 'DETAILS',
    },
    {
      name: 'modify',
      label: props.intl.formatMessage({
        id: 'app.list.button.modify',
        defaultMessage: 'Modify',
      }),
      onClick: onGetOne,
      theme: 'secondary',
      icon: <GetOneIcon />,
      role: 'MODIFY',
    },
    /*
    {
      name: 'delete',
      label: props.intl.formatMessage({
        id: 'app.list.button.delete',
        defaultMessage: 'Delete',
      }),
      onClick: onDelOne,
      theme: 'warning',
      icon: <DelOneIcon />,
      role: 'DELETE',
    },*/
  ];
};

/**
 * Descriptions de toutes les colonnes de la liste
 * Même les colonnes cachées
 * Ces colonnes peuvent ou non données des champs dans les filtres
 * (une info peut de pas être affichée dans la liste mais on peut peut-être mettre un filtre dessus)
 */
export const getCols = ({ props }) => {
  return [
    {
      name: 'msg_id',
      label: props.intl.formatMessage({
        id: 'app.features.message.field.msg_id.label',
        defaultMessage: 'Id.',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.message.field.msg_id.short',
        defaultMessage: 'Id.',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.message.field.msg_id.comment',
        defaultMessage: 'Identifiant du message',
      }),
      col: 'msg_id',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'ID' },
      filterable: true,
    },
    {
      name: 'msg_object_name',
      label: props.intl.formatMessage({
        id: 'app.features.message.field.msg_object_name.label',
        defaultMessage: 'Objet',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.message.field.msg_object_name.short',
        defaultMessage: 'Objet',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.message.field.msg_object_name.comment',
        defaultMessage: "Nom de l'objet émétteur",
      }),
      col: 'msg_object_name',
      size: { xxs: 36, sm: 8, md: 6, lg: 5 },
      title: true,
      sortable: true,
      fDisplay: localObjectLabel,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'msg_object_id',
      label: props.intl.formatMessage({
        id: 'app.features.message.field.msg_object_id.label',
        defaultMessage: 'Id objet',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.message.field.msg_object_id.short',
        defaultMessage: 'Id objet',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.message.field.msg_object_id.comment',
        defaultMessage: "Identifiant de l'objet émétteur",
      }),
      col: 'msg_object_id',
      size: { xxs: 36, sm: 12, md: 7, lg: 6 },
      title: true,
      sortable: true,
      hidden: true,
      filterable: true,
    },
    {
      name: 'lang_id',
      label: props.intl.formatMessage({
        id: 'app.features.message.field.lang_id.label',
        defaultMessage: 'Langue',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.message.field.lang_id.short',
        defaultMessage: 'Langue',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.message.field.lang_id.comment',
        defaultMessage: 'Identifiant de la langue du message',
      }),
      col: 'lang_id',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      filterable: true,
    },
    {
      name: 'msg_subject',
      label: props.intl.formatMessage({
        id: 'app.features.message.field.msg_subject.label',
        defaultMessage: 'Sujet',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.message.field.msg_subject.short',
        defaultMessage: 'Sujet',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.message.field.msg_subject.comment',
        defaultMessage: 'Sujet du message, sans html',
      }),
      col: 'msg_subject',
      size: { xxs: 36, sm: 12, md: 8, lg: 7 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'msg_ts',
      label: props.intl.formatMessage({
        id: 'app.features.message.field.msg_ts.label',
        defaultMessage: 'Créé le',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.message.field.msg_ts.short',
        defaultMessage: 'Créé le',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.message.field.msg_ts.comment',
        defaultMessage: "Date heure d'expédition du message",
      }),
      col: 'msg_ts',
      size: { xxs: 36, sm: 12, md: 4, lg: 3 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: { type: 'datetime' },
      type: 'datetime',
    },
    {
      name: 'msg_status',
      label: props.intl.formatMessage({
        id: 'app.features.message.field.msg_status.label',
        defaultMessage: 'Status',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.message.field.msg_status.short',
        defaultMessage: 'Status',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.message.field.msg_status.comment',
        defaultMessage: 'Status du message',
      }),
      col: 'msg_status',
      size: { xxs: 36, sm: 4, md: 3, lg: 2 },
      title: true,
      sortable: true,
      fDisplay: item => statusLabel(props.intl, item),
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'msg_send_ts',
      label: props.intl.formatMessage({
        id: 'app.features.message.field.msg_send_ts.label',
        defaultMessage: 'Expédié le',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.message.field.msg_send_ts.short',
        defaultMessage: 'Expédié le',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.message.field.msg_send_ts.comment',
        defaultMessage: "Date heure d'expédition",
      }),
      col: 'msg_send_ts',
      size: { xxs: 36, sm: 12, md: 4, lg: 3 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: { type: 'datetime' },
      type: 'datetime',
    },
    {
      name: 'msg_send_error',
      label: props.intl.formatMessage({
        id: 'app.features.message.field.msg_send_error.label',
        defaultMessage: 'Erreur',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.message.field.msg_send_error.short',
        defaultMessage: 'Erreur',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.message.field.msg_send_error.comment',
        defaultMessage: "Message d'erreur retourné par le serveur",
      }),
      col: 'msg_send_error',
      size: { xxs: 36, sm: 12, md: 6 },
      title: true,
      sortable: true,
      hidden: true,
      filterable: true,
    },
    {
      name: 'msg_dest',
      label: props.intl.formatMessage({
        id: 'app.features.message.field.msg_dest.label',
        defaultMessage: 'Destinataire(s)',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.message.field.msg_dest.short',
        defaultMessage: 'Destinataire(s)',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.message.field.msg_dest.comment',
        defaultMessage: 'Destinataires du message au format json',
      }),
      col: 'msg_dest',
      size: { xxs: 36, sm: 12, md: 6 },
      title: true,
      sortable: true,
      fDisplay: localFirstDest,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'msg_cc',
      label: props.intl.formatMessage({
        id: 'app.features.message.field.msg_cc.label',
        defaultMessage: 'CC(s)',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.message.field.msg_cc.short',
        defaultMessage: 'CC(s)',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.message.field.msg_cc.comment',
        defaultMessage: 'CC du message au format json',
      }),
      col: 'msg_cc',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      filterable: true,
    },
    {
      name: 'msg_bcc',
      label: props.intl.formatMessage({
        id: 'app.features.message.field.msg_bcc.label',
        defaultMessage: 'BCC(s)',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.message.field.msg_bcc.short',
        defaultMessage: 'BCC(s)',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.message.field.msg_bcc.comment',
        defaultMessage: 'CC cachés du message au format json',
      }),
      col: 'msg_bcc',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      filterable: true,
    },

    {
      name: 'msg_body',
      label: props.intl.formatMessage({
        id: 'app.features.message.field.msg_body.label',
        defaultMessage: 'Contenu',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.message.field.msg_body.short',
        defaultMessage: 'Contenu',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.message.field.msg_body.comment',
        defaultMessage: 'Le corps du message, html recommandé',
      }),
      col: 'msg_body',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      filterable: true,
    },
    {
      name: 'msg_pj1',
      label: props.intl.formatMessage({
        id: 'app.features.message.field.msg_pj1.label',
        defaultMessage: 'PJ 1',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.message.field.msg_pj1.short',
        defaultMessage: 'PJ 1',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.message.field.msg_pj1.comment',
        defaultMessage: 'Fichier en pj 1',
      }),
      col: 'msg_pj1',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      filterable: true,
    },
    {
      name: 'msg_pj2',
      label: props.intl.formatMessage({
        id: 'app.features.message.field.msg_pj2.label',
        defaultMessage: 'PJ 2',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.message.field.msg_pj2.short',
        defaultMessage: 'PJ 2',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.message.field.msg_pj2.comment',
        defaultMessage: 'Fichier en pj 2',
      }),
      col: 'msg_pj2',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      filterable: true,
    },
    {
      name: 'msg_pj3',
      label: props.intl.formatMessage({
        id: 'app.features.message.field.msg_pj3.label',
        defaultMessage: 'PJ 3',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.message.field.msg_pj3.short',
        defaultMessage: 'PJ 3',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.message.field.msg_pj3.comment',
        defaultMessage: 'Fichier en pj 3',
      }),
      col: 'msg_pj3',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      filterable: true,
    },
    {
      name: 'msg_pj4',
      label: props.intl.formatMessage({
        id: 'app.features.message.field.msg_pj4.label',
        defaultMessage: 'PJ 4',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.message.field.msg_pj4.short',
        defaultMessage: 'PJ 4',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.message.field.msg_pj4.comment',
        defaultMessage: 'Fichier en pj 4',
      }),
      col: 'msg_pj4',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      filterable: true,
    },
    {
      name: 'msg_from',
      label: props.intl.formatMessage({
        id: 'app.features.message.field.msg_from.label',
        defaultMessage: 'From',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.message.field.msg_from.short',
        defaultMessage: 'From',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.message.field.msg_from.comment',
        defaultMessage: 'From du message',
      }),
      col: 'msg_from',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      filterable: true,
    },
    {
      name: 'msg_reply_to',
      label: props.intl.formatMessage({
        id: 'app.features.message.field.msg_reply_to.label',
        defaultMessage: 'From name',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.message.field.msg_reply_to.short',
        defaultMessage: 'From name',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.message.field.msg_reply_to.comment',
        defaultMessage: 'Informations de retour',
      }),
      col: 'msg_reply_to',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      filterable: true,
    },
    {
      name: 'msg_pj1_name',
      label: props.intl.formatMessage({
        id: 'app.features.message.field.msg_pj1_name.label',
        defaultMessage: 'Nom PJ 1',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.message.field.msg_pj1_name.short',
        defaultMessage: 'Nom PJ 1',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.message.field.msg_pj1_name.comment',
        defaultMessage: 'Nom de la pj 1',
      }),
      col: 'msg_pj1_name',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      filterable: true,
    },
    {
      name: 'msg_pj2_name',
      label: props.intl.formatMessage({
        id: 'app.features.message.field.msg_pj2_name.label',
        defaultMessage: 'Nom PJ 2',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.message.field.msg_pj2_name.short',
        defaultMessage: 'Nom PJ 2',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.message.field.msg_pj2_name.comment',
        defaultMessage: 'Nom de la pj 2',
      }),
      col: 'msg_pj2_name',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      filterable: true,
    },
    {
      name: 'msg_pj3_name',
      label: props.intl.formatMessage({
        id: 'app.features.message.field.msg_pj3_name.label',
        defaultMessage: 'Nom PJ 3',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.message.field.msg_pj3_name.short',
        defaultMessage: 'Nom PJ 3',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.message.field.msg_pj3_name.comment',
        defaultMessage: 'Nom de la pj 3',
      }),
      col: 'msg_pj3_name',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      filterable: true,
    },
    {
      name: 'msg_pj4_name',
      label: props.intl.formatMessage({
        id: 'app.features.message.field.msg_pj4_name.label',
        defaultMessage: 'Nom PJ 4',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.message.field.msg_pj4_name.short',
        defaultMessage: 'Nom PJ 4',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.message.field.msg_pj4_name.comment',
        defaultMessage: 'Nom de la pj 4',
      }),
      col: 'msg_pj4_name',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      filterable: true,
    },
  ];
};
