import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as actions from './redux/actions';
import { getOneCauseAsModel } from '../cause';
import { getOneClientAsModel } from '../client';
import Form from './Form';
import { getJsonApi } from 'jsonapi-front';
import { propagateModel } from '../../common';
import { PortalLoader, createSuccess, modifySuccess, showErrors } from '../ui';

export class Input extends Component {
  static propTypes = {
    sponsorship: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    loader: PropTypes.bool,
  };
  static defaultProps = {
    loader: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      id: props.id || props.spoId || 0,
      sponsorship: props.sponsorship,
      item: false,
      loading: true,
      saving: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.actions.loadOne(this.state.id).then(result => {
      const item = this.props.sponsorship.loadOneItem;
      if (this.state.id <= 0) {
        if (this.props.mode === 'client') {
          getOneClientAsModel(this.props.parentId).then(client => {
            item.client = client;
            this.setState({ item: item, loading: false });
          });
        } else {
          if (this.props.mode === 'cause') {
            getOneCauseAsModel(this.props.parentId).then(cause => {
              item.cause = cause;
              this.setState({ item: item, loading: false });
            });
          } else {
            this.setState({ item: item, loading: false });
          }
        }
      } else {
        this.setState({ item: item, loading: false });
      }
    }).catch((errors) => {
      showErrors(this.props.intl, errors);
      this.setState({ loading: false });
      this.props.onClose();
    });
  }

  onCancel(event) {
    if (event) {
      event.preventDefault();
    }
    this.props.onClose();
  }

  onSubmit(datas) {
    this.setState({ saving: true });
    let obj = getJsonApi(datas, 'FreeAsso_Sponsorship');
    if (this.state.id > 0) {
      this.props.actions
        .updateOne(obj)
        .then(result => {
          this.setState({ saving: false });
          modifySuccess();
          this.props.actions.propagateModel('FreeAsso_Sponsorship', result);
          this.props.onClose();
        })
        .catch(errors => {
          this.setState({ saving: false });
          showErrors(this.props.intl, errors);
        });
    } else {
      this.props.actions
        .createOne(obj)
        .then(result => {
          this.setState({ saving: false });
          createSuccess();
          this.props.actions.propagateModel('FreeAsso_Sponsoship', result);
          this.props.onClose();
        })
        .catch(errors => {
          this.setState({ saving: false });
          showErrors(this.props.intl, errors);
        });
    }
  }

  render() {
    const { item, loading } = this.state;
    return (
      <div className="sponsorship-modify global-card">
        {!item ? loading ? (
          <PortalLoader portal={true} />
        ) : (
          <></>
        ) : (
          <div>
            {item && (
              <Form
                item={item}
                mode={this.props.mode}
                modal={true}
                modify={this.state.id > 0}
                saving={this.state.saving}
                datas={this.props.data.items}
                configs={this.props.config.items}
                properties={this.props.sponsorship.properties}
                errors={
                  this.state.id > 0
                    ? this.props.sponsorship.updateOneError
                    : this.props.sponsorship.createOneError
                }
                paymentTypes={this.props.paymentType.items}
                inputMoney={this.props.inputMoney || 'EUR'}
                dbMoney={'EUR'}
                rates={this.props.rate.models}
                onSubmit={this.onSubmit}
                onCancel={this.onCancel}
                onClose={this.props.onClose}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    inputMoney: state.auth.inputMoney,
    displayMoney: state.auth.displayMoney,
    data: state.data,
    config: state.config,
    sponsorship: state.sponsorship,
    paymentType: state.paymentType,
    rate: state.rate,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, propagateModel }, dispatch),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Input));
