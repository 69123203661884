import React from 'react';
import { InputHidden, InputText, Container } from 'react-bootstrap-front';
import { injectIntl } from 'react-intl';
import { useForm, ResponsiveModalOrForm, Row, Col, InputKeywords } from '../ui';

function Form(props) {
  const { values, handleChange, handleSubmit, handleCancel, getErrorMessage } = useForm(
    props.item,
    '',
    props.onSubmit,
    props.onCancel,
    '',
    props.errors,
    props.intl,
  );
  return (
    <ResponsiveModalOrForm
      size="xs"
      modal={true}
      title={props.intl.formatMessage({
        id: 'app.features.clientCategory.form.title',
        defaultMessage: 'Person category',
      })}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onClose={props.onClose}
    >
      <InputHidden name="id" id="id" value={values.id} />
      <Container>
      <Row>
        <Col size={{ xxs: 36 }}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.clientCategory.form.code',
              defaultMessage: 'Code',
            })}
            name="clic_code"
            id="clic_code"
            required={true}
            value={values.clic_code}
            onChange={handleChange}
            error={getErrorMessage('clic_code')}
          />
        </Col>
        <Col size={{ xxs: 36 }}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.clientCategory.form.name',
              defaultMessage: 'Name',
            })}
            name="clic_name"
            id="clic_name"
            required={true}
            value={values.clic_name}
            onChange={handleChange}
            error={getErrorMessage('clic_name')}
          />
        </Col>
        <Col size={{ xxs: 36 }}>
          <InputKeywords
            label={props.intl.formatMessage({
              id: 'app.features.clientCategory.form.fields',
              defaultMessage: 'Champs',
            })}
            name="clic_fields"
            id="clic_fields"
            value={values.clic_fields}
            onChange={handleChange}
            error={getErrorMessage('clic_fields')}
          />
        </Col>
      </Row>
      </Container>
    </ResponsiveModalOrForm>
  );
}

export default injectIntl(Form);
