import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View as ViewIcon } from '../icons';

export class ObjectButton extends Component {
  static propTypes = {
    common: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    objectFieldId: PropTypes.string,
    objectFieldName: PropTypes.string,
    onClick: PropTypes.func,
  };
  static defaultProps = {
    objectFieldId: '',
    objectFieldName: '',
    onClick: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      opened: false,
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({ opened: true });
  }

  render() {
    const name = this.props.item[this.props.objectFieldName]
      ? this.props.item[this.props.objectFieldName]
      : '';
    const id = this.props.item[this.props.objectFieldId]
      ? this.props.item[this.props.objectFieldId]
      : null;
    return (
      <>
        <button className="btn btn-action btn-inline btn-secondary" onClick={this.onClick}>
          <ViewIcon />
        </button>
        {
          {
            '': <div onClick={() => { this.props.onClick(id)}} />,
          }[name]
        }
      </>
    );
  }
}

export default ObjectButton;
