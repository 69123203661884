import React from 'react';
import { injectIntl } from 'react-intl';
import { getShortcuts, getStatusOptions, getTypeOptions } from './';
import useForm from '../ui/useForm';
import {
  ResponsiveModalOrForm,
  InputHidden,
  InputText,
  InputTextarea,
  InputDatetime,
  InputSelect,
  InputJsonSimple,
  Row,
  Col,
} from '../ui';

/**
 * Initialisation
 *
 * @param {Object}   item
 * @param {Function} setItem
 */
const initItem = (item, setItem) => {};

/**
 * Un champ a été modifié
 *
 * @param {String}   field
 * @param {Object}   item
 * @param {Function} setitem
 */
const afterChange = (field, item, setItem) => {
  switch (field) {
    default:
      break;
  }
};

/**
 * Saisie des informations d'un modèle
 */
function Form(props) {
  let item = props.item;
  // On récupère tout ce qu'il faut pour travailler
  const {
    values,
    handleChange,
    handleSave,
    handleSubmit,
    handleCancel,
    handleNavTab,
    getErrorMessage,
    getCurrentTab,
  } = useForm(
    item,
    props.tab,
    props.onSubmit,
    props.onCancel,
    props.onNavTab,
    props.errors,
    props.intl,
    afterChange,
    initItem,
  );
  // Le formulaire
  return (
    <ResponsiveModalOrForm
      title={
        !props.modify && !values.jobq_name
          ? props.intl.formatMessage({
              id: 'app.features.jobqueue.form.title',
              defaultMessage: 'New model',
            })
          : values.jobq_name
      }
      className=""
      tab={getCurrentTab()}
      tabs={!props.modify ? getShortcuts(props.intl, 'new') : getShortcuts(props.intl, 'all')}
      size="md"
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onNavTab={handleNavTab}
      onSave={handleSave}
      onClose={handleCancel}
      onPrevious={props.onPrevious}
      onNext={props.onNext}
      modal={true}
      actionsButtons={props.actionsButtons}
      loader={props.loader ? props.loader : false}
    >
      <InputHidden name="id" id="id" value={values.id} />
      <Row>
        <Col size={{ xxs: 36, sm: 16 }}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.jobqueue.field.jobq_name.label',
              defaultMessage: 'jobq_name',
            })}
            id="jobq_name"
            name="jobq_name"
            value={values.jobq_name}
            required={true}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('jobq_name')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 10 }}>
          <InputSelect
            label={props.intl.formatMessage({
              id: 'app.features.jobqueue.field.jobq_type.label',
              defaultMessage: 'jobq_type',
            })}
            id="jobq_type"
            name="jobq_type"
            value={values.jobq_type}
            required={true}
            labelTop={true}
            onChange={handleChange}
            options={getTypeOptions(props.intl)}
            error={getErrorMessage('jobq_type')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 10 }}>
          <InputSelect
            label={props.intl.formatMessage({
              id: 'app.features.jobqueue.field.jobq_status.label',
              defaultMessage: 'jobq_status',
            })}
            id="jobq_status"
            name="jobq_status"
            value={values.jobq_status}
            required={true}
            labelTop={true}
            options={getStatusOptions(props.intl)}
            onChange={handleChange}
            error={getErrorMessage('jobq_status')}
          />
        </Col>
      </Row>
      {getCurrentTab() === 'ident' && (
        <Row>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputDatetime
              label={props.intl.formatMessage({
                id: 'app.features.jobqueue.field.jobq_next_retry.label',
                defaultMessage: 'jobq_next_retry',
              })}
              id="jobq_next_retry"
              name="jobq_next_retry"
              value={values.jobq_next_retry}
              labelTop={true}
              required={true}
              onChange={handleChange}
              error={getErrorMessage('jobq_next_retry')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 10 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.jobqueue.field.jobq_cron.label',
                defaultMessage: 'jobq_cron',
              })}
              id="jobq_cron"
              name="jobq_cron"
              value={values.jobq_cron}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('jobq_cron')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 10 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.jobqueue.field.jobq_next_minutes.label',
                defaultMessage: 'jobq_next_minutes',
              })}
              id="jobq_next_minutes"
              name="jobq_next_minutes"
              value={!values.jobq_cron && values.jobq_next_minutes}
              disabled={values.jobq_cron}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('jobq_next_minutes')}
            />
          </Col>
          <Col size={{ xxs: 36 }}>
            <InputTextarea
              label={props.intl.formatMessage({
                id: 'app.features.jobqueue.field.jobq_desc.label',
                defaultMessage: 'jobq_desc',
              })}
              id="jobq_desc"
              name="jobq_desc"
              value={values.jobq_desc}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('jobq_desc')}
            />
          </Col>
        </Row>
      )}
      {getCurrentTab() === 'desc' && (
        <Row>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputDatetime
              label={props.intl.formatMessage({
                id: 'app.features.jobqueue.field.jobq_ts.label',
                defaultMessage: 'jobq_ts',
              })}
              id="jobq_ts"
              name="jobq_ts"
              value={values.jobq_ts}
              required={true}
              labelTop={true}
              disabled={true}
              onChange={handleChange}
              error={getErrorMessage('jobq_ts')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.jobqueue.field.jobq_service.label',
                defaultMessage: 'jobq_service',
              })}
              id="jobq_service"
              name="jobq_service"
              value={values.jobq_service}
              labelTop={true}
              disabled={true}
              onChange={handleChange}
              error={getErrorMessage('jobq_service')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.jobqueue.field.jobq_method.label',
                defaultMessage: 'jobq_method',
              })}
              id="jobq_method"
              name="jobq_method"
              value={values.jobq_method}
              labelTop={true}
              disabled={true}
              onChange={handleChange}
              error={getErrorMessage('jobq_method')}
            />
          </Col>
          <Col size={{ xxs: 36 }}>
            <InputTextarea
              label={props.intl.formatMessage({
                id: 'app.features.jobqueue.field.jobq_last_report.label',
                defaultMessage: 'jobq_last_report',
              })}
              id="jobq_last_report"
              name="jobq_last_report"
              value={values.jobq_last_report}
              labelTop={true}
              disabled={true}
              onChange={handleChange}
              error={getErrorMessage('jobq_last_report')}
            />
          </Col>
          <Col size={{ xxs: 36 }}>
            <InputJsonSimple
              label={props.intl.formatMessage({
                id: 'app.features.jobqueue.field.jobq_params.label',
                defaultMessage: 'jobq_params',
              })}
              id="jobq_params"
              name="jobq_params"
              value={values.jobq_params}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('jobq_params')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputDatetime
              label={props.intl.formatMessage({
                id: 'app.features.jobqueue.field.jobq_last_ts.label',
                defaultMessage: 'jobq_last_ts',
              })}
              id="jobq_last_ts"
              name="jobq_last_ts"
              value={values.jobq_last_ts}
              labelTop={true}
              disabled={true}
              onChange={handleChange}
              error={getErrorMessage('jobq_last_ts')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.jobqueue.field.jobq_max_retry.label',
                defaultMessage: 'jobq_max_retry',
              })}
              id="jobq_max_retry"
              name="jobq_max_retry"
              value={values.jobq_max_retry}
              required={true}
              labelTop={true}
              disabled={true}
              onChange={handleChange}
              error={getErrorMessage('jobq_max_retry')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.jobqueue.field.jobq_nb_retry.label',
                defaultMessage: 'jobq_nb_retry',
              })}
              id="jobq_nb_retry"
              name="jobq_nb_retry"
              value={values.jobq_nb_retry}
              labelTop={true}
              disabled={true}
              onChange={handleChange}
              error={getErrorMessage('jobq_nb_retry')}
            />
          </Col>
        </Row>
      )}
    </ResponsiveModalOrForm>
  );
}

/**
 * Injection de l'intl
 */
export default injectIntl(Form);
