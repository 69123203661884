import {
  DONATION_INIT_SORT,
} from './constants';

export function initSort() {
  return {
    type: DONATION_INIT_SORT,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case DONATION_INIT_SORT:
      return {
        ...state,
        sort: [{ col: 'don_ts', way: 'down' }],
      };

    default:
      return state;
  }
}
