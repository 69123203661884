import React from 'react';
// import PropTypes from 'prop-types';

export default function OpenLines() {
  return (
    <div className="icons-open-lines">
      Component content: icons/OpenLines
    </div>
  );
};

OpenLines.propTypes = {};
OpenLines.defaultProps = {};
