import React from 'react';
import { injectIntl } from 'react-intl';
import {
  InputHidden,
  InputSelect,
  InputCheckbox,
  roundMonetary,
  displayMonetary,
  Row,
  Col,
} from 'react-bootstrap-front';
import { freqAsOptions, getShortcuts } from './';
import { InputDate, ResponsiveModalOrForm, InputSponsors, InputMonetary, InputText } from '../ui';
import useForm from '../ui/useForm';
import { paymentTypeAsOptions } from '../payment-type/functions.js';
import { InputPicker as ClientInputPicker, getFullName } from '../client';
import { InputPicker as CauseInputPicker } from '../cause';
import { InlineList as InlineDonations } from '../donation';
import { InlineList as InlineHistory } from '../history';

const afterChange = (name, item) => {
  switch (name) {
    case 'spo_from':
      const found = item.__locked.find(elem => elem.field === 'spo_freq_when');
      if (found && found.locked === true) {
        const myDate = new Date(item.spo_from);
        //item.spo_freq_when = myDate.getDate();
        // Ou le 10 en dur pour l'instant
        item.spo_freq_when = 10;
        if (item.spo_freq !== 'MONTH') {
          item.spo_freq_when_cpl = myDate.getMonth() + 1;
        }
      }
      break;
    case 'spo_mnt':
      item.spo_money_input = item.__inputMoney;
      item.spo_money = item.__dbMoney;
      if (item.__inputMoney !== item.__dbMoney) {
        const change1 = item.__getRate(item.__currentMoney, item.__inputMoney);
        const mnt1 = roundMonetary(
          parseFloat(item.spo_mnt || 0) * change1,
          'fr-FR',
          item.__inputMoney,
        );
        if (Math.abs(parseFloat(item.spo_mnt_input || 0) - mnt1) > 0.01) {
          item.spo_mnt_input = mnt1;
        }
      } else {
        item.spo_mnt_input = item.spo_mnt;
      }
      break;
    case 'spo_mnt_input':
      item.spo_money_input = item.__inputMoney;
      item.spo_money = item.__dbMoney;
      if (item.__inputMoney !== item.__dbMoney) {
        const change2 = item.__getRate(item.__currentMoney, item.__dbMoney);
        const mnt2 = roundMonetary(
          parseFloat(item.spo_mnt_input || 0) * change2,
          'fr-FR',
          item.__dbMoney,
        );
        if (Math.abs(parseFloat(item.spo_mnt || 0) - mnt2) > 0.01) {
          item.spo_mnt = mnt2;
        }
      } else {
        item.spo_mnt = item.spo_mnt_input;
      }
      break;
    default: {
      break;
    }
  }
  return item;
};

function Form(props) {
  const myDate = new Date(props.item.spo_from);
  const spo_freq_when = myDate.getDate();
  const {
    values,
    handleChange,
    handleSubmit,
    handleCancel,
    handleNavTab,
    getErrorMessage,
    isLocked,
    toggleLockOn,
    toggleLockOff,
    switchMoney,
    getCurrentMoney,
  } = useForm(
    props.item,
    'ident',
    props.onSubmit,
    props.onCancel,
    props.onNavTab,
    props.errors,
    props.intl,
    afterChange,
    null,
    [
      { field: 'spo_mnt', locked: props.modify || false },
      { field: 'spo_mnt_input', locked: props.modify || false },
      { field: 'spo_freq_when', locked: spo_freq_when === props.item.spo_freq_when },
    ],
    props.rates,
    props.inputMoney,
    props.dbMoney,
  );
  const { intl } = props;
  const readonlyForm = false;
  let myTitle = '';
  if (values.client && values.client.cli_lastname) {
    myTitle += getFullName(values.client);
  }
  if (values.cause && values.cause.cau_name) {
    if (myTitle !== '') {
      myTitle += ' : ';
    }
    myTitle += values.cause.cau_name;
  }
  return (
    <ResponsiveModalOrForm
      className=""
      title={myTitle}
      size="xmd"
      height="sm"
      tab={values.__currentTab}
      tabs={getShortcuts(props.intl, values.__modify ? 'all' : 'new')}
      onNavTab={handleNavTab}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onClose={props.onClose}
      modal={true}
      saving={props.saving || false}
    >
      <div className="card-body">
        <InputHidden name="id" id="id" value={values.id} />

        <div>
          <Row>
            <Col size={{ xs: 36, sm: 14 }}>
              <CauseInputPicker
                label={props.intl.formatMessage({
                  id: 'app.features.sponsorship.field.cau_id.label',
                  defaultMessage: 'Mission',
                })}
                key="cause"
                name="cause"
                disabled={props.mode === 'cause' || props.modify}
                labelTop={true}
                item={values.cause || null}
                onChange={handleChange}
                error={getErrorMessage('cause')}
              />
            </Col>
            <Col size={{ xs: 36, sm: 14 }}>
              <ClientInputPicker
                label={props.intl.formatMessage({
                  id: 'app.features.sponsorship.field.cli_id.label',
                  defaultMessage: 'Member',
                })}
                key="client"
                name="client"
                labelTop={true}
                disabled={props.mode === 'client' || props.modify}
                item={values.client || null}
                onChange={handleChange}
                error={getErrorMessage('client')}
              />
            </Col>
            <Col size={{ xs: 36, sm: 8 }}>
              {values.cause &&
                values.cause.cause_type &&
                values.cause.cause_type.caut_family === 'ANIMAL' && (
                  <InputCheckbox
                    label={props.intl.formatMessage({
                      id: 'app.features.sponsorship.field.spo_send_news.label',
                      defaultMessage: 'Send news',
                    })}
                    id="spo_send_news"
                    name="spo_send_news"
                    labelTop={true}
                    checked={values.spo_send_news}
                    onChange={handleChange}
                    error={getErrorMessage('spo_send_news')}
                  />
                )}
            </Col>
          </Row>
        </div>
        <hr />
        {values.__currentTab === 'ident' && (
          <div>
            <Row>
              <Col size={{ xs: 36, sm: 14, md: 8 }}>
                <InputDate
                  label={props.intl.formatMessage({
                    id: 'app.features.sponsorship.field.spo_from.label',
                    defaultMessage: 'From',
                  })}
                  name="spo_from"
                  labelTop={true}
                  value={values.spo_from}
                  onChange={handleChange}
                  error={getErrorMessage('spo_from')}
                />
              </Col>
              <Col size={{ xs: 0, md: 6 }}></Col>
              <Col size={{ xs: 36, sm: 14, md: 7 }}>
                <InputDate
                  label={props.intl.formatMessage({
                    id: 'app.features.sponsorship.field.spo_to.label',
                    defaultMessage: 'To',
                  })}
                  name="spo_to"
                  labelTop={true}
                  value={values.spo_to}
                  onChange={handleChange}
                  error={getErrorMessage('spo_to')}
                />
              </Col>
              <Col size={{ xs: 0, md: 7 }}></Col>
              <Col size={{ xs: 36, sm: 8 }}>
                {values.cause &&
                  values.cause.cause_type &&
                  values.cause.cause_type.caut_family === 'ANIMAL' && (
                    <InputCheckbox
                      label={props.intl.formatMessage({
                        id: 'app.features.sponsorship.field.spo_display_site.label',
                        defaultMessage: 'Display on site',
                      })}
                      id="spo_display_site"
                      name="spo_display_site"
                      labelTop={true}
                      checked={values.spo_display_site}
                      onChange={handleChange}
                      error={getErrorMessage('spo_display_site')}
                    />
                  )}
              </Col>
            </Row>
            <Row>
              <Col size={{ xs: 36, sm: 14, md: 8 }}>
                {getCurrentMoney() === props.dbMoney ? (
                  <InputMonetary
                    label={intl.formatMessage({
                      id: 'app.features.sponsorship.field.spo_mnt.label',
                      defaultMessage: 'Amount',
                    })}
                    labelTop={true}
                    name="spo_mnt"
                    id="spo_mnt"
                    inputMoney={getCurrentMoney()}
                    locked={isLocked('spo_mnt')}
                    required={true}
                    disabled={readonlyForm}
                    onLockOn={toggleLockOn}
                    onLockOff={toggleLockOff}
                    dbMoney={props.dbMoney}
                    value={values.spo_mnt}
                    rateValue={displayMonetary(values.spo_mnt_input, props.inputMoney)}
                    onChange={handleChange}
                    onMoneySwitch={props.inputMoney !== props.dbMoney ? switchMoney : null}
                    error={getErrorMessage('spo_mnt')}
                  />
                ) : (
                  <InputMonetary
                    label={intl.formatMessage({
                      id: 'app.features.sponsorship.field.spo_mnt_input.label',
                      defaultMessage: 'Amount',
                    })}
                    labelTop={true}
                    name="spo_mnt_input"
                    id="spo_mnt_input"
                    inputMoney={getCurrentMoney()}
                    locked={isLocked('spo_mnt_input')}
                    disabled={readonlyForm}
                    required={true}
                    onLockOn={toggleLockOn}
                    onLockOff={toggleLockOff}
                    dbMoney={props.dbMoney}
                    value={values.spo_mnt_input}
                    rateValue={displayMonetary(values.spo_mnt, props.dbMoney)}
                    onChange={handleChange}
                    onMoneySwitch={props.inputMoney !== props.dbMoney ? switchMoney : null}
                    error={getErrorMessage('spo_mnt_input')}
                  />
                )}
              </Col>
              <Col size={{ xs: 36, sm: 14, md: 6 }}>
                <InputSelect
                  label={props.intl.formatMessage({
                    id: 'app.features.sponsorship.field.ptyp_id.label',
                    defaultMessage: 'Type',
                  })}
                  name="payment_type.id"
                  labelTop={true}
                  value={values.payment_type.id}
                  onChange={handleChange}
                  options={paymentTypeAsOptions(props.paymentTypes, values.__modify)}
                  error={getErrorMessage('ptyp_id')}
                />
              </Col>
              <Col size={{ xs: 36, sm: 7 }}>
                <InputSelect
                  label={props.intl.formatMessage({
                    id: 'app.features.sponsorship.field.spo_freq.label',
                    defaultMessage: 'Fréquence',
                  })}
                  name="spo_freq"
                  labelTop={true}
                  value={values.spo_freq}
                  onChange={handleChange}
                  options={freqAsOptions()}
                  error={getErrorMessage('spo_freq')}
                />
              </Col>
              <Col size={{ xs: 36, sm: 4 }}>
                <InputText
                  label={props.intl.formatMessage({
                    id: 'app.features.sponsorship.field.spo_freq_when.label',
                    defaultMessage: 'Day',
                  })}
                  id="spo_freq_when"
                  name="spo_freq_when"
                  labelTop={true}
                  locked={isLocked('spo_freq_when')}
                  onLockOn={toggleLockOn}
                  onLockOff={toggleLockOff}
                  value={values.spo_freq_when}
                  onChange={handleChange}
                  error={getErrorMessage('spo_freq_when')}
                />
              </Col>
              {values.spo_freq === 'YEAR' ? (
                <Col size={{ xs: 36, sm: 3 }}>
                  <InputText
                    label={props.intl.formatMessage({
                      id: 'app.features.sponsorship.field.spo_freq_when_cpl.label',
                      defaultMessage: 'month',
                    })}
                    id="spo_freq_when_cpl"
                    name="spo_freq_when_cpl"
                    labelTop={true}
                    value={values.spo_freq_when_cpl}
                    onChange={handleChange}
                    error={getErrorMessage('spo_freq_when_cpl')}
                  />
                </Col>
              ) : (
                <Col size={{ xs: 0, sm: 3 }}></Col>
              )}
              <Col size={{ xs: 36, sm: 9, md: 7 }}>
                {' '}
                <InputCheckbox
                  label={props.intl.formatMessage({
                    id: 'app.features.sponsorship.field.spo_current_month.label',
                    defaultMessage: 'Add first',
                  })}
                  id="spo_current_month"
                  name="spo_current_month"
                  labelTop={true}
                  checked={values.spo_current_month}
                  onChange={handleChange}
                  disabled={values.__modify}
                  error={getErrorMessage('spo_current_month')}
                />
              </Col>
            </Row>
            <Row>
              <Col size={{ xs: 36 }}>
                <InputText
                  label={props.intl.formatMessage({
                    id: 'app.features.sponsorship.field.spo_freq_detail.label',
                    defaultMessage: 'Comments',
                  })}
                  id="spo_freq_detail"
                  name="spo_freq_detail"
                  labelTop={true}
                  value={values.spo_freq_detail}
                  onChange={handleChange}
                  error={getErrorMessage('spo_freq_detail')}
                />
              </Col>
            </Row>
          </div>
        )}
        {values.__currentTab === 'sponsors' && (
          <Row>
            <div className="col-sm-w36">
              <InputSponsors
                label="Invités"
                id="spo_sponsors"
                name="spo_sponsors"
                value={values.spo_sponsors}
                onChange={handleChange}
                error={getErrorMessage('spo_sponsors')}
              />
            </div>
          </Row>
        )}
        {values.__currentTab === 'donations' && (
          <div className="form-inline-list overflow-x-hidden">
            <InlineDonations mode="sponsorship" parentId={values.id} />
          </div>
        )}
        {values.__currentTab === 'history' && (
          <div className="form-inline-list overflow-x-hidden">
            <InlineHistory mode="sponsorship" parentId={values.id} />
          </div>
        )}
      </div>
    </ResponsiveModalOrForm>
  );
}

export default injectIntl(Form);
