import React from 'react';
import { Icon } from './';
import { mdiArrowRightThick as myIcon } from '@mdi/js';

export default function Next(props) {
  return <Icon path={myIcon} {...props} />;
}

Next.propTypes = {};
Next.defaultProps = {};
