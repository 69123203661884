import React from 'react';
import { isEmptyModel } from 'jsonapi-front';
import { isEmptyText, displayMonetary, displayDate } from 'react-bootstrap-front';
import {
  AddOne as AddOneIcon,
  GetOne as GetOneIcon,
  DelOne as DelOneIcon,
  PaymentOn as PaymentOnIcon,
  PaymentOff as PaymentOffIcon,
  Note as NoteIcon,
  Email as EmailIcon,
  Print as PrintIcon,
  MatchingCancel as MatchingCancelIcon,
  MatchingManual as MatchingManualIcon,
  MatchingNote as MatchingNoteIcon,
  Bank as BankIcon,
  Download as DownloadIcon,
} from '../icons';
import { getClientFullname } from '../client';
import { paymentTypeAsOptions } from '../payment-type/functions.js';
import { groupAsOptions } from '../group';
import { countryAsOptions } from '../country/functions.js';

export const getStatus = intl => {
  let statusValues = [
    {
      value: 'WAIT',
      label: intl.formatMessage({
        id: 'app.features.donation.status.wait',
        defaultMessage: 'Waiting',
      }),
    },
    {
      value: 'OK',
      label: intl.formatMessage({
        id: 'app.features.donation.status.paid',
        defaultMessage: 'Paid',
      }),
    },
    {
      value: 'NOK',
      label: intl.formatMessage({
        id: 'app.features.donation.status.unpaid',
        defaultMessage: 'Unpaid',
      }),
    },
    //{ value: 'NEXT', label: 'A venir' },
  ];
  return statusValues;
};

export const getFullStatus = (item, intl) => {
  const allStatus = getStatus(intl);
  const status = allStatus.find(elem => elem.value === item.don_status);
  return (
    <>
      {status && <span>{status.label}</span>}
      {
        {
          AUTO: (
            <MatchingNoteIcon
              size={0.8}
              value={item.don_verif_match}
              min={0}
              max={10}
              title={item.don_verif_comment}
            />
          ),
          HISTO: <BankIcon size={0.8} title={item.don_verif_comment} />,
          MANUAL: <MatchingManualIcon size={0.8} title={item.don_verif_comment} color="green" />,
        }[item.don_verif]
      }
    </>
  );
};

export const getGlobalActions = ({ props, onClearFilters, onCreate }) => {
  return [
    {
      name: 'create',
      label: props.intl.formatMessage({ id: 'app.list.button.add', defaultMessage: 'Add' }),
      onClick: onCreate,
      theme: 'primary',
      icon: <AddOneIcon color="white" />,
      role: 'CREATE',
    },
  ];
};

export const getInlineActions = ({
  props,
  onPrintOne,
  onGetOne,
  onDelOne,
  onPayOn,
  onPayOff,
  onSendOne,
  onUnmatched,
  onMatched,
  onCertificate,
  state,
}) => {
  let myEditions = [];
  if (state && state.editions) {
    const { editions } = state;
    editions.forEach(edition => {
      edition.langs.forEach(lang => {
        myEditions.push({
          label: edition.edi_name + ' (' + lang + ')',
          onClick: item => onPrintOne(edition.id, item, lang),
        });
      });
    });
  }
  let actions = [];
  const realm_id = props.realm ? props.realm.id : 0; 
  actions.push(
    {
      name: 'matching',
      label: props.intl.formatMessage({ id: 'app.list.button.unmatch', defaultMessage: 'Unmatch' }),
      onClick: onUnmatched,
      theme: 'warning',
      icon: <MatchingCancelIcon />,
      role: 'ACTION',
      param: 'object',
      fShow: item => {
        return `${item.grp_id}` === realm_id && (item.don_verif === 'AUTO' || item.don_verif === 'MANUAL');
      },
    },
    {
      name: 'match',
      label: props.intl.formatMessage({ id: 'app.list.button.match', defaultMessage: 'Match' }),
      onClick: onMatched,
      theme: 'primary',
      icon: <MatchingManualIcon />,
      role: 'ACTION',
      param: 'object',
      fShow: item => {
        return `${item.grp_id}` === realm_id && item.don_verif === 'NONE';
      },
    },
  );
  actions.push(
    {
      name: 'status',
      label: props.intl.formatMessage({ id: 'app.list.button.paid', defaultMessage: 'Paid' }),
      onClick: onPayOn,
      theme: 'primary',
      icon: <PaymentOnIcon />,
      role: 'ACTION',
      param: 'object',
      fShow: item => {
        if (item && `${item.grp_id}` === realm_id && (item.don_status === 'NOK' || item.don_status === 'WAIT')) {
          return true;
        } else {
          return false;
        }
      },
    },
    {
      name: 'status',
      label: props.intl.formatMessage({ id: 'app.list.button.unpaid', defaultMessage: 'Unpaid' }),
      onClick: onPayOff,
      theme: 'warning',
      icon: <PaymentOffIcon />,
      role: 'ACTION',
      param: 'object',
      fShow: item => {
        if (item && `${item.grp_id}` === realm_id && (item.don_status === 'OK' || item.don_status === 'WAIT')) {
          return true;
        } else {
          return false;
        }
      },
    },
  );
  if (onSendOne) {
    actions.push({
      name: 'email',
      label: props.intl.formatMessage({
        id: 'app.list.button.email',
        defaultMessage: 'Email',
      }),
      onClick: onSendOne,
      confirm: props.intl.formatMessage({
        id: 'app.list.button.email.confirm',
        defaultMessage: 'Confirm ?',
      }),
      fShow: item =>
        item && item.client && item.client.cli_email && item.client.cli_email !== '' ? true : false,
      theme: 'secondary',
      icon: <EmailIcon />,
      role: 'ACTION',
      param: 'object',
    });
  }
  if (onCertificate) {
    actions.push({
      name: 'certificate',
      label: props.intl.formatMessage({
        id: 'app.list.button.download',
        defaultMessage: 'Download',
      }),
      onClick: onCertificate,
      fShow: item => {
        return item && item.certificate && item.certificate.id ? true : false;
      },
      theme: 'secondary',
      icon: <DownloadIcon />,
      role: 'ACTION',
      param: 'object',
    });
  }
  actions.push(
    {
      name: 'print',
      label: 'Imprimer',
      onClick: onPrintOne,
      theme: 'secondary',
      icon: <PrintIcon />,
      role: 'PRINT',
      active: myEditions.length > 0,
      options: myEditions,
      param: 'object',
    },
    {
      name: 'modify',
      label: props.intl.formatMessage({ id: 'app.list.button.modify', defaultMessage: 'Modify' }),
      onClick: onGetOne,
      theme: 'secondary',
      icon: <GetOneIcon color="white" />,
      role: 'MODIFY',
      fShow: item => {
        return item && `${item.grp_id}` === realm_id;
      }
    },
    {
      name: 'delete',
      label: props.intl.formatMessage({ id: 'app.list.button.delete', defaultMessage: 'Delete' }),
      onClick: onDelOne,
      theme: 'warning',
      icon: <DelOneIcon color="white" />,
      role: 'DELETE',
      fShow: item => {
        return item && item.session && item.session.sess_status !== 'CLOSED';
      },
    },
  );
  return actions;
};

export const displayTitle = (item, mode) => {
  if (mode === 'cause') {
    return (
      <>
        <span>{displayDate(item.don_ask_ts)} - </span>
        <span>
          {item.client.cli_lastname} {item.client.cli_firstname}
        </span>
        <span> {displayMonetary(item.don_mnt, item.don_mnt_money)}</span>
      </>
    );
  } else {
    if (mode === 'client') {
      return (
        <>
          <span> {displayDate(item.don_ask_ts)} - </span>
          <span>{item.cause.cau_name}</span>
          <span> {displayMonetary(item.don_mnt, item.don_mnt_money)}</span>
        </>
      );
    }
  }
  return null;
};

export const getSelectActions = ({ props, onSelectMenu }) => {
  let arrOne = [
    {
      name: 'selectAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.selectAll',
        defaultMessage: 'Select all',
      }),
      onClick: () => {
        onSelectMenu('selectAll');
      },
    },
  ];
  const arrAppend = [
    {
      name: 'selectNone',
      label: props.intl.formatMessage({
        id: 'app.list.options.deselectAll',
        defaultMessage: 'Deselect all',
      }),
      onClick: () => {
        onSelectMenu('selectNone');
      },
    },
    { name: 'divider' },
    {
      name: 'exportSelect',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportSelect',
        defaultMessage: 'Export selection',
      }),
      onClick: () => {
        onSelectMenu('exportSelection');
      },
    },
  ];
  if (props.donation.selected.length > 0) {
    arrOne = arrOne.concat(arrAppend);
  }
  const arrStandard = [
    { name: 'divider' },
    {
      name: 'exportAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportAll',
        defaultMessage: 'Export all',
      }),
      onClick: () => {
        onSelectMenu('exportAll');
      },
    },
  ];
  arrOne = arrOne.concat(arrStandard);
  return arrOne;
};

const displayCause = item => {
  if (item && item.cause) {
    return item.cause.cau_name;
  }
  return null;
};

const displayClient = item => {
  if (item && item.client) {
    return getClientFullname(item.client);
  }
  return null;
};

const displayFrom = item => {
  if (
    item &&
    item.cause &&
    item.cause.cause_type &&
    item.cause.cause_type.caut_once_duration !== 'INFINITE' &&
    isEmptyModel(item.sponsorship)
  ) {
    return true;
  }
  return false;
};

const displayTo = item => {
  if (
    item &&
    item.cause &&
    item.cause.cause_type &&
    item.cause.cause_type.caut_once_duration !== 'INFINITE' &&
    isEmptyModel(item.sponsorship)
  ) {
    return true;
  }
  return false;
};

export const getCols = ({ props, mode }) => {
  let cols = [
    {
      name: 'id',
      label: props.intl.formatMessage({
        id: 'app.features.donation.list.col.id',
        defaultMessage: 'Id.',
      }),
      col: 'id',
      size: '3',
      mob_size: '',
      title: true,
      sortable: true,
      filterable: false,
      hidden: true,
      card: { role: 'ID' },
    },
  ];
  let first = true;
  if (mode !== 'cause' && mode !== 'sponsorship') {
    cols.push({
      name: 'name',
      label: props.intl.formatMessage({
        id: 'app.features.donation.list.col.cause',
        defaultMessage: 'Mission',
      }),
      col: 'cause.cau_name',
      size: { sm: 7, md: 5, lg: 4 },
      mob_size: '',
      fDisplay: displayCause(),
      sortable: true,
      first: first,
      selectable: first,
      filterable: { type: 'text' },
      card: { role: 'FIELD', position: 5 },
    });
    first = false;
  }
  if (mode !== 'client' && mode !== 'sponsorship') {
    cols.push(
      {
        name: 'lastname',
        label: props.intl.formatMessage({
          id: 'app.features.donation.list.col.client',
          defaultMessage: 'Member',
        }),
        col: 'client',
        size: { sm: 8, md: 6, lg: 5 },
        mob_size: '',
        sortable: false,
        fDisplay: displayClient,
        first: first,
        selectable: first,
        filterable: false,
        card: { role: 'FIELD', position: 4 },
      },
      {
        name: 'lastname',
        label: props.intl.formatMessage({
          id: 'app.features.client.list.col.lastname',
          defaultMessage: 'Member',
        }),
        col: 'client.cli_lastname',
        sortable: true,
        hidden: true,
        filterable: { type: 'text' },
      },
      {
        name: 'firstname',
        label: props.intl.formatMessage({
          id: 'app.features.client.list.col.firstname',
          defaultMessage: 'Member',
        }),
        col: 'client.cli_firstname',
        sortable: true,
        hidden: true,
        filterable: { type: 'text' },
      },
    );
  }
  cols.push(
    {
      name: 'country',
      label: props.intl.formatMessage({
        id: 'app.features.client.list.col.country',
        defaultMessage: 'Pays (cog)',
      }),
      col: 'client.country.cnty_cog',
      sortable: true,
      hidden: true,
      type: 'text',
      filterable: true,
    }
  );
  cols.push(
    {
      name: 'mnt',
      label: props.intl.formatMessage({
        id: 'app.features.donation.list.col.amount',
        defaultMessage: 'Amount',
      }),
      col: 'don_mnt_input',
      size: { xxs: 36, sm: 4, md: 3, xl: 2 },
      mob_size: '',
      sortable: true,
      type: 'monetary',
      col_money: 'don_money_input',
      filterable: { type: 'monetary' },
      card: { role: 'FIELD', position: 20 },
    },
    {
      name: 'date',
      label: props.intl.formatMessage({
        id: 'app.features.donation.list.col.realTs',
        defaultMessage: 'Done on',
      }),
      col: 'don_real_ts',
      size: { sm: 4, md: 4, lg: 3 },
      mob_size: '',
      title: true,
      sortable: true,
      type: 'date',
      filterable: { type: 'date' },
      card: { role: 'TITLE' },
    },
  );
  if (mode !== 'sponsorship') {
    cols.push(
      {
        name: 'from',
        label: props.intl.formatMessage({
          id: 'app.features.donation.list.col.askTs',
          defaultMessage: 'From',
        }),
        col: 'don_ask_ts',
        size: { sm: 4, md: 3, xl: 2 },
        mob_size: '',
        title: true,
        sortable: true,
        type: 'date',
        fShow: displayFrom,
        filterable: { type: 'date' },
        card: { role: 'FIELD', position: 30 },
      },
      {
        name: 'to',
        label: props.intl.formatMessage({
          id: 'app.features.donation.list.col.endTs',
          defaultMessage: 'To',
        }),
        col: 'don_end_ts',
        size: { sm: 4, md: 3, xl: 2 },
        mob_size: '',
        title: true,
        sortable: true,
        type: 'date',
        fShow: displayTo,
        filterable: { type: 'date' },
        card: { role: 'FIELD', position: 40 },
      },
    );
  }
  cols.push(
    {
      name: 'ts',
      label: props.intl.formatMessage({
        id: 'app.features.donation.list.col.donTs',
        defaultMessage: 'Création',
      }),
      col: 'don_ts',
      size: { sm: 6, md: 4, lg: 3 },
      mob_size: '',
      title: true,
      sortable: true,
      type: 'datetime',
      filterable: { type: 'date' },
    },
    {
      name: 'status',
      label: props.intl.formatMessage({
        id: 'app.features.donation.list.col.status',
        defaultMessage: 'Status',
      }),
      col: 'don_status',
      size: { sm: 4, md: 3, xl: 2 },
      mob_size: '',
      sortable: true,
      type: 'switch',
      values: getStatus(props.intl),
      fDisplay: item => getFullStatus(item, props.intl),
      filterable: { type: 'text' },
      card: { role: 'FIELD', position: 60 },
    },
    {
      name: 'type',
      label: props.intl.formatMessage({
        id: 'app.features.donation.list.col.type',
        defaultMessage: 'Type',
      }),
      col: 'payment_type.ptyp_name',
      size: { sm: 6, md: 4, lg: 4, xl: 3 },
      mob_size: '',
      sortable: true,
      type: 'text',
      filterable: false,
      card: { role: 'FIELD', position: 10 },
    },
    {
      name: 'ptyp_id',
      label: props.intl.formatMessage({
        id: 'app.features.donation.list.col.type',
        defaultMessage: 'Type',
      }),
      col: 'ptyp_id',
      size: { sm: 6, md: 4, lg: 4, xl: 3 },
      mob_size: '',
      hidden: true,
      filterable: {
        type: 'select',
        options: paymentTypeAsOptions(props.paymentType.items),
      },
    },
    {
      name: 'freq',
      label: props.intl.formatMessage({
        id: 'app.features.donation.list.col.frequency',
        defaultMessage: 'Frequency',
      }),
      col: 'don_comment',
      size: { sm: 4, md: 3, lg: 3 },
      mob_size: '',
      sortable: false,
      fDisplay: item => {
        if (item.sponsorship && item.sponsorship.id > 0) {
          return props.intl.formatMessage({
            id: 'app.features.donation.list.col.frequency.regular',
            defaultMessage: 'Regular',
          });
        } else {
          return props.intl.formatMessage({
            id: 'app.features.donation.list.col.frequency.once',
            defaultMessage: 'Once',
          });
        }
      },
      last: true,
      card: { role: 'FIELD', position: 50 },
    },
    {
      name: 'note',
      label: props.intl.formatMessage({
        id: 'app.features.donation.list.col.comments',
        defaultMessage: 'Comments',
      }),
      col: 'don_comment',
      size: '7',
      mob_size: '',
      hidden: true,
      sortable: true,
      fDisplay: item => {
        if (!isEmptyText(item.don_comment)) {
          return <NoteIcon className="col-icon text-secondary" />;
        } else {
          return '';
        }
      },
      last: true,
    },
    {
      name: 'sess_id',
      label: props.intl.formatMessage({
        id: 'app.features.donation.field.sess_id.label',
        defaultMessage: 'sess_id',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.donation.field.sess_id.short',
        defaultMessage: 'sess_id',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.donation.field.sess_id.comment',
        defaultMessage: 'sess_id',
      }),
      col: 'session.sess_name',
      size: { sm: 4, md: 3, xl: 2 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: { type: 'text' },
    },
    {
      name: 'dono_id',
      label: props.intl.formatMessage({
        id: 'app.features.donation.field.dono_id.label',
        defaultMessage: 'dono_id',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.donation.field.dono_id.short',
        defaultMessage: 'dono_id',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.donation.field.dono_id.comment',
        defaultMessage: 'dono_id',
      }),
      col: 'dono_id',
      size: { sm: 4, md: 3, xl: 2 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: { type: 'text' },
    },
    {
      name: 'spo_id',
      label: props.intl.formatMessage({
        id: 'app.features.donation.field.spo_id.label',
        defaultMessage: 'spo_id',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.donation.field.spo_id.short',
        defaultMessage: 'spo_id',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.donation.field.spo_id.comment',
        defaultMessage: 'spo_id',
      }),
      col: 'spo_id',
      size: { sm: 4, md: 3, xl: 2 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: { type: 'text' },
    },
    {
      name: 'spo_ts',
      label: props.intl.formatMessage({
        id: 'app.features.donation.field.spo_ts.label',
        defaultMessage: 'spo_ts',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.donation.field.spo_ts.short',
        defaultMessage: 'spo_ts',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.donation.field.spo_ts.comment',
        defaultMessage: 'spo_ts',
      }),
      col: 'sponsorship.spo_ts',
      size: { sm: 4, md: 3, lg: 2 },
      title: true,
      sortable: true,
      hidden: true,
      type: 'date',
      filterable: { type: 'date' },
    },
    {
      name: 'don_verif_match',
      label: props.intl.formatMessage({
        id: 'app.features.donation.field.don_verif_match.label',
        defaultMessage: 'don_verif_match',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.donation.field.don_verif_match.short',
        defaultMessage: 'don_verif_match',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.donation.field.don_verif_match.comment',
        defaultMessage: 'don_verif_match',
      }),
      col: 'don_verif_match',
      size: { sm: 4, md: 3, xl: 2 },
      title: true,
      sortable: true,
      hidden: true,
      filterable: { type: 'number' },
    },
    {
      name: 'grp_id',
      label: 'Ass.',
      col: 'group.id',
      size: { sm: 6, md: 3, lg: 2 },
      mob_size: '',
      title: true,
      sortable: true,
      type: 'switch',
      values: groupAsOptions(),
      filterable: {
        type: 'select',
        options: groupAsOptions(),
      },
    },
  );
  return cols;
};
