import {
  Filter,
  FILTER_MODE_AND,
  FILTER_OPER_GREATER_OR_EQUAL_OR_NULL,
  FILTER_OPER_EQUAL,
} from 'react-bootstrap-front';
import { CAUSE_INIT_FILTERS } from './constants';

export function defaultFilters(cautId) {
  let filters = new Filter();
  const now = new Date().toISOString();
  filters.setMode(FILTER_MODE_AND);
  filters.addFilter('cau_to', now, FILTER_OPER_GREATER_OR_EQUAL_OR_NULL, false, true);
  filters.addFilter('caut_id', cautId || 355, FILTER_OPER_EQUAL, true);
  return filters;
}

export function initFilters(cautId, def = false) {
  return {
    type: CAUSE_INIT_FILTERS,
    cautId: cautId,
    def: def,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CAUSE_INIT_FILTERS:
      let filters = defaultFilters(action.cautId || 355);
      if (action.def) {
        filters.disableDefaults();
      } else {
        filters.enableDefaults();
      }
      return {
        ...state,
        filters: filters,
      };

    default:
      return state;
  }
}
