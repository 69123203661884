import React from 'react';
import { InputHidden, InputText, InputSelect, Row, Col } from 'react-bootstrap-front';
import { deepClone } from 'jsonapi-front';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useForm, InputTextarea, ResponsiveModalOrForm, InputFileContent } from '../ui';
import { getlangLabel } from '../lang';
import { emailCodes, getEmailObjectNames } from './';
import { editionAsOptions } from '../edition';

let id = 0;

/**
 * Add a new tab
 *
 * @param object item
 * @param object emptyVersion
 * @param array  langs
 */
const addTab = (item, emptyVersion, langs) => {
  if (!Array.isArray(item.versions)) {
    item.versions = [];
  }
  const newEmpty = deepClone(emptyVersion);
  id--;
  newEmpty.id = id;
  langs.every(lang => {
    const idx = item.versions.findIndex(elem => {
      return '' + elem.lang.id === '' + lang.value;
    });
    if (idx < 0) {
      newEmpty.lang.id = lang.value;
      item.versions.push(newEmpty);
      return false;
    }
    return true;
  });
};

/**
 * Remove tab
 *
 * @param object item
 * @param object tab
 * @param array  langs
 */
const delTab = (item, tab, langs) => {
  item.versions = item.versions.filter(elem => elem.id !== tab);
};

function Form(props) {
  const {
    values,
    handleChange,
    handleSubmit,
    handleCancel,
    handleNavTab,
    getErrorMessage,
  } = useForm(
    props.item,
    props.tab || '1',
    props.onSubmit,
    props.onCancel,
    '',
    props.errors,
    props.intl,
  );
  if (!Array.isArray(values.versions)) {
    values.versions = [];
  }
  const tabs = [
    {
      key: '1',
      name: 'main',
      label: props.intl.formatMessage({
        id: 'app.features.email.tab.main',
        defaultMessage: 'Description',
      }),
      shortcut: 'I',
      icon: 'client',
    },
  ];
  let versTabs = [];
  let indexV = 1;
  if (values.versions) {
    values.versions.forEach(oneVersion => {
      indexV++;
      versTabs.push({
        id: oneVersion.id,
        key: indexV,
        name: 'version' + indexV,
        label: getlangLabel(props.langs, oneVersion.lang.id),
        shortcut: 'E',
        icon: 'email',
        removeable: true,
      });
    });
  }
  return (
    <ResponsiveModalOrForm
      title={props.intl.formatMessage({
        id: 'app.features.email.form.title',
        defaultMessage: 'Notification',
      })}
      size="lg"
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onNavTab={handleNavTab}
      onAddTab={() => {
        addTab(values, props.emptyVersion, props.langs);
        handleNavTab(values.versions.length + 1);
      }}
      onDelTab={tab => {
        delTab(values, tab, props.langs);
        if (values.versions.length > 0) {
          handleNavTab(values.versions.length + 1);
        } else {
          handleNavTab(1);
        }
      }}
      onClose={props.onClose}
      tab={values.__currentTab}
      tabs={tabs.concat(versTabs)}
      modal={true}
    >
      <InputHidden name="id" id="id" value={values.id} />
      <Row>
        <Col size={{ xs: 36, sm: 26 }}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.email.form.subject',
              defaultMessage: 'Subject',
            })}
            name="email_subject"
            id="email_subject"
            required={true}
            value={values.email_subject}
            onChange={handleChange}
            error={getErrorMessage('email_subject')}
          />
        </Col>
        <Col size={{ xs: 36, sm: 10 }}>
          <InputSelect
            label={props.intl.formatMessage({
              id: 'app.features.email.form.lang',
              defaultMessage: 'Lang',
            })}
            name="lang.id"
            required={true}
            value={values.lang.id}
            onChange={handleChange}
            options={props.langs}
            addempty={true}
          />
        </Col>
      </Row>
      <hr />
      {values.__currentTab === '1' && (
        <div>
          <Row>
            <Col size={{ xs: 36, sm: 18 }}>
              <InputText
                label={props.intl.formatMessage({
                  id: 'app.features.email.form.replyTo',
                  defaultMessage: 'Reply to email',
                })}
                name="email_reply_to"
                id="email_reply_to"
                value={values.email_reply_to}
                onChange={handleChange}
                error={getErrorMessage('email_reply_to')}
              />
            </Col>
            <Col size={{ xs: 36, sm: 18 }}>
              <InputText
                label={props.intl.formatMessage({
                  id: 'app.features.email.form.bcc',
                  defaultMessage: 'BCC',
                })}
                name="email_bcc"
                id="email_bcc"
                value={values.email_bcc}
                onChange={handleChange}
                error={getErrorMessage('email_bcc')}
              />
            </Col>
          </Row>
          <Row>
            <Col size={{ xs: 36, sm: 18 }}>
              <InputText
                label={props.intl.formatMessage({
                  id: 'app.features.email.form.fromEmail',
                  defaultMessage: 'From email',
                })}
                name="email_from"
                id="email_from"
                value={values.email_from}
                onChange={handleChange}
                error={getErrorMessage('email_from')}
              />
            </Col>
            <Col size={{ xs: 36, sm: 18 }}>
              <InputText
                label={props.intl.formatMessage({
                  id: 'app.features.email.form.fromName',
                  defaultMessage: 'From name',
                })}
                name="email_from_name"
                id="email_from_name"
                value={values.email_from_name}
                onChange={handleChange}
                error={getErrorMessage('email_from_name')}
              />
            </Col>
          </Row>
          <Row>
            <Col size={{ xs: 36, sm: 18 }}>
              <InputSelect
                label={props.intl.formatMessage({
                  id: 'app.features.email.form.objectName',
                  defaultMessage: 'Object',
                })}
                name="email_object_name"
                id="email_object_name"
                required={true}
                value={values.email_object_name}
                onChange={handleChange}
                addEmpty={true}
                error={getErrorMessage('email_object_name')}
                options={getEmailObjectNames(props.intl)}
              />
            </Col>
            <Col size={{ xs: 36, sm: 18 }}>
              <InputSelect
                label={props.intl.formatMessage({
                  id: 'app.features.email.form.code',
                  defaultMessage: 'For',
                })}
                name="email_code"
                id="email_code"
                required={true}
                value={values.email_code}
                options={emailCodes}
                onChange={handleChange}
                error={getErrorMessage('email_code')}
              />
            </Col>
          </Row>
          <Row>
            <Col size={{ xs: 36, sm: 12 }}>
              <InputSelect
                label={props.intl.formatMessage({
                  id: 'app.features.email.form.edition1',
                  defaultMessage: 'First edition',
                })}
                name="edition1.id"
                id="edition1.id"
                value={values.edition1 ? values.edition1.id : ''}
                onChange={handleChange}
                addEmpty={true}
                options={editionAsOptions(props.editions, [])}
              />
            </Col>
            <Col size={{ xs: 36, sm: 18 }}>
              <InputText
                label={props.intl.formatMessage({
                  id: 'app.features.email.form.edition1Object',
                  defaultMessage: 'Object',
                })}
                name="email_edi1_object"
                id="email_edi1_object"
                value={values.email_edi1_object}
                onChange={handleChange}
                error={getErrorMessage('email_edi1_object')}
              />
            </Col>
          </Row>
          <Row>
            <Col size={{ xs: 36, sm: 12 }}>
              <InputSelect
                label={props.intl.formatMessage({
                  id: 'app.features.email.form.edition2',
                  defaultMessage: 'Second edition',
                })}
                name="edition2.id"
                id="edition2.id"
                value={values.edition2.id}
                onChange={handleChange}
                addEmpty={true}
                options={editionAsOptions(props.editions, [])}
              />
            </Col>
            <Col size={{ xs: 36, sm: 18 }}>
              <InputText
                label={props.intl.formatMessage({
                  id: 'app.features.email.form.edition2Object',
                  defaultMessage: 'Object',
                })}
                name="email_edi2_object"
                id="email_edi2_object"
                value={values.email_edi2_object}
                onChange={handleChange}
                error={getErrorMessage('email_edi2_object')}
              />
            </Col>
          </Row>
        </div>
      )}
      {values.versions.map((email, idx) => {
        if (idx + 2 === parseInt(values.__currentTab, 10)) {
          return (
            <>
              <Row>
                <Col size={{ xs: 36, sm: 10 }}>
                  <InputSelect
                    label={props.intl.formatMessage({
                      id: 'app.features.email.form.lang2',
                      defaultMessage: 'Lang',
                    })}
                    name={'versions.' + idx + '.lang.id'}
                    required={true}
                    value={email.lang.id}
                    onChange={handleChange}
                    options={props.langs}
                    addempty={true}
                  />
                </Col>
                <Col size={{ xs: 36, sm: 26 }}>
                  <InputText
                    label={props.intl.formatMessage({
                      id: 'app.features.email.form.subject',
                      defaultMessage: 'Subject',
                    })}
                    name={'versions.' + idx + '.emaill_subject'}
                    id={'versions.' + idx + '.emaill_subject'}
                    required={true}
                    value={email.emaill_subject}
                    onChange={handleChange}
                    error={getErrorMessage('emaill_subject')}
                  />
                </Col>
              </Row>
              <Row>
                <Col size={{ xs: 36 }}>
                  <InputTextarea
                    label={props.intl.formatMessage({
                      id: 'app.features.email.form.body',
                      defaultMessage: 'Content',
                    })}
                    name={'versions.' + idx + '.emaill_body'}
                    id={'versions.' + idx + '.emaill_body'}
                    required={true}
                    value={email.emaill_body}
                    onChange={handleChange}
                    error={getErrorMessage('emaill_body')}
                  />
                </Col>
              </Row>
              <Row>
                <Col size={{ xss: 36, sm: 18 }}>
                  <InputFileContent
                    label={props.intl.formatMessage({
                      id: 'app.features.email.form.pj1',
                      defaultMessage: 'Pj1',
                    })}
                    name={'versions.' + idx + '.emaill_pj1'}
                    id={'versions.' + idx + '.emaill_pj1'}
                    required={false}
                    value={email.emaill_pj1 || null}
                    onChange={handleChange}
                    error={getErrorMessage('emaill_pj1')}
                  />
                </Col>
                <Col size={{ xss: 36, sm: 18 }}>
                  <InputText
                    label={props.intl.formatMessage({
                      id: 'app.features.email.form.pj1Name',
                      defaultMessage: 'Pj1',
                    })}
                    name={'versions.' + idx + '.emaill_pj1_name'}
                    id={'versions.' + idx + '.emaill_pj1_name'}
                    required={false}
                    value={email.emaill_pj1_name || null}
                    onChange={handleChange}
                    error={getErrorMessage('emaill_pj1_name')}
                  />
                </Col>
              </Row>
              <Row>
                <Col size={{ xss: 36, sm: 18 }}>
                  <InputFileContent
                    label={props.intl.formatMessage({
                      id: 'app.features.email.form.pj2',
                      defaultMessage: 'Pj2',
                    })}
                    name={'versions.' + idx + '.emaill_pj2'}
                    id={'versions.' + idx + '.emaill_pj2'}
                    required={false}
                    value={email.emaill_pj2 || null}
                    onChange={handleChange}
                    error={getErrorMessage('emaill_pj2')}
                  />
                </Col>
                <Col size={{ xss: 36, sm: 18 }}>
                  <InputText
                    label={props.intl.formatMessage({
                      id: 'app.features.email.form.pj2Name',
                      defaultMessage: 'Pj2',
                    })}
                    name={'versions.' + idx + '.emaill_pj2_name'}
                    id={'versions.' + idx + '.emaill_pj2_name'}
                    required={false}
                    value={email.emaill_pj2_name || null}
                    onChange={handleChange}
                    error={getErrorMessage('emaill_pj2_name')}
                  />
                </Col>
              </Row>
            </>
          );
        } else {
          return null;
        }
      })}
    </ResponsiveModalOrForm>
  );
}

Form.propTypes = {
  item: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
Form.defaultProps = {};

export default injectIntl(Form);
