import React from 'react';

const year = new Date().getFullYear();

export const Copyright = () => (
  <div className="common-copyright">
    <div className="text-center mt-2">
      <p>
        &copy;{' '}
        <a href="https://github.com/jeromeklam/freeasso" target="_blank" rel="noopener noreferrer">
          FreeAsso
        </a>{' '}
        2019-{year}
      </p>
    </div>
  </div>
);
