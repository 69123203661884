export const EMAIL_LOAD_MORE_INIT = 'EMAIL_LOAD_MORE_INIT';
export const EMAIL_LOAD_MORE_BEGIN = 'EMAIL_LOAD_MORE_BEGIN';
export const EMAIL_LOAD_MORE_SUCCESS = 'EMAIL_LOAD_MORE_SUCCESS';
export const EMAIL_LOAD_MORE_FAILURE = 'EMAIL_LOAD_MORE_FAILURE';
export const EMAIL_LOAD_MORE_DISMISS_ERROR = 'EMAIL_LOAD_MORE_DISMISS_ERROR';
export const EMAIL_CLEAR_ITEMS = 'EMAIL_CLEAR_ITEMS';
export const EMAIL_LOAD_ONE_BEGIN = 'EMAIL_LOAD_ONE_BEGIN';
export const EMAIL_LOAD_ONE_SUCCESS = 'EMAIL_LOAD_ONE_SUCCESS';
export const EMAIL_LOAD_ONE_FAILURE = 'EMAIL_LOAD_ONE_FAILURE';
export const EMAIL_LOAD_ONE_DISMISS_ERROR = 'EMAIL_LOAD_ONE_DISMISS_ERROR';
export const EMAIL_UPDATE_ONE_BEGIN = 'EMAIL_UPDATE_ONE_BEGIN';
export const EMAIL_UPDATE_ONE_SUCCESS = 'EMAIL_UPDATE_ONE_SUCCESS';
export const EMAIL_UPDATE_ONE_FAILURE = 'EMAIL_UPDATE_ONE_FAILURE';
export const EMAIL_UPDATE_ONE_DISMISS_ERROR = 'EMAIL_UPDATE_ONE_DISMISS_ERROR';
export const EMAIL_UPDATE_ONE_UPDATE = 'EMAIL_UPDATE_ONE_UPDATE'
export const EMAIL_CREATE_ONE_BEGIN = 'EMAIL_CREATE_ONE_BEGIN';
export const EMAIL_CREATE_ONE_SUCCESS = 'EMAIL_CREATE_ONE_SUCCESS';
export const EMAIL_CREATE_ONE_FAILURE = 'EMAIL_CREATE_ONE_FAILURE';
export const EMAIL_CREATE_ONE_DISMISS_ERROR = 'EMAIL_CREATE_ONE_DISMISS_ERROR';
export const EMAIL_DEL_ONE_BEGIN = 'EMAIL_DEL_ONE_BEGIN';
export const EMAIL_DEL_ONE_SUCCESS = 'EMAIL_DEL_ONE_SUCCESS';
export const EMAIL_DEL_ONE_FAILURE = 'EMAIL_DEL_ONE_FAILURE';
export const EMAIL_DEL_ONE_DISMISS_ERROR = 'EMAIL_DEL_ONE_DISMISS_ERROR';
export const EMAIL_SET_SORT = 'EMAIL_SET_SORT';
export const EMAIL_SET_FILTERS = 'EMAIL_SET_FILTERS';
export const EMAIL_UPDATE_QUICK_SEARCH = 'EMAIL_UPDATE_QUICK_SEARCH';
export const EMAIL_UPDATE_SORT = 'EMAIL_UPDATE_SORT';
export const EMAIL_INIT_SORT = 'EMAIL_INIT_SORT';
export const EMAIL_INIT_FILTERS = 'EMAIL_INIT_FILTERS';
export const EMAIL_LOAD_ONE_VERSION_BEGIN = 'EMAIL_LOAD_ONE_VERSION_BEGIN';
export const EMAIL_LOAD_ONE_VERSION_SUCCESS = 'EMAIL_LOAD_ONE_VERSION_SUCCESS';
export const EMAIL_LOAD_ONE_VERSION_FAILURE = 'EMAIL_LOAD_ONE_VERSION_FAILURE';
export const EMAIL_LOAD_ONE_VERSION_DISMISS_ERROR = 'EMAIL_LOAD_ONE_VERSION_DISMISS_ERROR';
export const EMAIL_SEND_ONE_BEGIN = 'EMAIL_SEND_ONE_BEGIN';
export const EMAIL_SEND_ONE_SUCCESS = 'EMAIL_SEND_ONE_SUCCESS';
export const EMAIL_SEND_ONE_FAILURE = 'EMAIL_SEND_ONE_FAILURE';
export const EMAIL_SEND_ONE_DISMISS_ERROR = 'EMAIL_SEND_ONE_DISMISS_ERROR';
export const EMAIL_SEND_ONE_UPDATE = 'EMAIL_SEND_ONE_UPDATE'