import { Filter, FILTER_MODE_AND, FILTER_OPER_LIKE, FILTER_OPER_EQUAL } from 'react-bootstrap-front';
import { DONATION_INIT_FILTERS } from './constants';

export function defaultFilters(grp_id) {
  let filters = new Filter();
  filters.init(FILTER_MODE_AND, FILTER_OPER_LIKE);
  filters.addFilter('group.id', grp_id, FILTER_OPER_EQUAL, false, true);
  return filters;
}

export function initFilters(grp_id) {
  return {
    type: DONATION_INIT_FILTERS,
    grp_id: grp_id
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case DONATION_INIT_FILTERS:
      return {
        ...state,
        filters: defaultFilters(action.grp_id),
      };

    default:
      return state;
  }
}
