import { jsonApiNormalizer, normalizedObjectModeler } from 'jsonapi-front';
import { freeAssoApi } from '../../common';

/**
 *
 */
export const getOneCause = id => {
  return freeAssoApi.get('/v1/asso/cause/' + id);
};

/**
 * 
 */
export const getOneCauseAsModel = id => {
  return new Promise((resolve, reject) => {
    getOneCause(id).then(
      res => {
        const object = jsonApiNormalizer(res.data);
        const item = normalizedObjectModeler(object, 'FreeAsso_Cause', id, { eager: true });
        resolve(item);
      },
      err => {
        reject(err);
      },
    );
  });
};

/**
 *
 */
export const downloadCauseMediaBlob = caum_id => {
  const promise = new Promise((resolve, reject) => {
    const doRequest = freeAssoApi.get('/v1/asso/cause_media_blob/download/' + caum_id, {
      responseType: 'arraybuffer',
    });
    doRequest.then(
      res => {
        resolve(res);
      },
      err => {
        reject(err);
      },
    );
  });
  return promise;
};
