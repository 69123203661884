import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as actions from './redux/actions';
import { getJsonApi } from 'jsonapi-front';
import { withRouter } from 'react-router-dom';
import { propagateModel } from '../../common';
import { PortalLoader, createSuccess, modifySuccess, showErrors } from '../ui';
import Form from './Form';

/**
 * Modification d'une donnée
 */
export class Input extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    loader: PropTypes.bool,
  };
  static defaultProps = {
    loader: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      id:
        this.props.dataId ||
        (this.props.match && this.props.match.params && this.props.match.params.id) ||
        0,
      item: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    this.props.actions.loadOne(this.state.id).then(result => {
      const item = this.props.data.loadOneItem;
      this.setState({ item: item });
    });
  }

  onCancel() {
    this.props.onClose();
  }

  onSubmit(datas = {}) {
    let obj = getJsonApi(datas, 'FreeAsso_Data');
    if (this.state.id > 0) {
      this.props.actions
        .updateOne(this.state.id, obj)
        .then(result => {
          modifySuccess();
          this.props.actions.propagateModel('FreeAsso_Data', result);
          this.props.onClose();
        })
        .catch(errors => {
          showErrors(this.props.intl, errors);
        });
    } else {
      this.props.actions
        .createOne(obj)
        .then(result => {
          createSuccess();
          this.props.actions.propagateModel('FreeAsso_Data', result);
          this.props.history.push('/data');
        })
        .catch(errors => {
          showErrors(this.props.intl, errors);
        });
    }
  }

  render() {
    const item = this.state.item;
    return (
      <div className="data-input global-card">
        {!item ? (
          <PortalLoader show={this.props.loader} />
        ) : (
          <div>
            {item && (
              <Form
                item={item}
                errors={
                  this.state.id > 0
                    ? this.props.data.updateOneError
                    : this.props.data.createOneError
                }
                onSubmit={this.onSubmit}
                onCancel={this.onCancel}
                onClose={this.props.onClose}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, propagateModel }, dispatch),
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(Input)));
