import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as actions from './redux/actions';
import { downloadOne as downloadCertificate }from '../certificate/redux/actions';
import { normalizedObjectModeler } from 'jsonapi-front';
import { ResponsiveQuickSearch } from 'react-bootstrap-front';
import { propagateModel } from '../../common';
import {
  Search as SearchIcon,
  SearchCancel as SearchCancelIcon,
  Donation as DonationIcon
} from '../icons';
import { showErrors, deleteSuccess, modifySuccess, List as UiList, messageSuccess, messageError } from '../ui';
import {
  getGlobalActions,
  getInlineActions,
  getCols,
  updateDonStatus,
  updateDonVerif,
  getSelectActions,
  Input,
} from './';
import { getEditions } from '../edition';

export class List extends Component {
  static propTypes = {
    donation: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      timer: null,
      donId: -1,
      editions: getEditions(props.edition.models, 'FreeAsso_Donation'),
    };
    this.onCreate = this.onCreate.bind(this);
    this.onReload = this.onReload.bind(this);
    this.onGetOne = this.onGetOne.bind(this);
    this.onDelOne = this.onDelOne.bind(this);
    this.onPayOn = this.onPayOn.bind(this);
    this.onPayOff = this.onPayOff.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onLoadMore = this.onLoadMore.bind(this);
    this.onQuickSearch = this.onQuickSearch.bind(this);
    this.onClearFilters = this.onClearFilters.bind(this);
    this.onUpdateSort = this.onUpdateSort.bind(this);
    this.onSetFiltersAndSort = this.onSetFiltersAndSort.bind(this);
    this.itemClassName = this.itemClassName.bind(this);
    this.onSendOne = this.onSendOne.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onSelectMenu = this.onSelectMenu.bind(this);
    this.onPrintOne = this.onPrintOne.bind(this);
    this.onMatched = this.onMatched.bind(this);
    this.onUnmatched = this.onUnmatched.bind(this);
    this.onCertificate = this.onCertificate.bind(this);
  }

  componentDidMount() {
    this.props.actions.loadMore(true);
  }

  onCreate(event) {
    this.setState({ donId: 0 });
  }

  onGetOne(id) {
    this.setState({ donId: id });
  }

  /**
   * Demande d'édition
   *
   * @param {Number} ediId Identifiant de l'édition
   * @param {Object} item  Objet à imprimer
   */
   onPrintOne(ediId, item, lang = '') {
    if (item) {
      this.props.actions
        .printOne(item.id, ediId, lang)
        .then(result => {
          messageSuccess(
            this.props.intl.formatMessage({ id: 'app.message.print.done', defaultMessage: 'Print' }),
          );
        })
        .catch(error => {
          messageError(
            this.props.intl.formatMessage({
              id: 'app.errors.default.print',
              defaultMessage: "Erreurs lors de l'impression",
            }),
          );
        });
      messageSuccess(
        this.props.intl.formatMessage({ id: 'app.message.print.ok', defaultMessage: 'Print' }),
      );
    }
  }

  onPayOn(item) {
    if (item && item.id) {
      updateDonStatus(item.id, 'OK')
        .then(result => {
          modifySuccess();
          this.props.actions.propagateModel('FreeAsso_Donation', result);
        })
        .catch(errors => {
          showErrors(this.props.intl, errors);
        });
    }
  }

  onPayOff(item) {
    if (item && item.id) {
      updateDonStatus(item.id, 'NOK')
        .then(result => {
          modifySuccess();
          this.props.actions.propagateModel('FreeAsso_Donation', result);
        })
        .catch(errors => {
          showErrors(this.props.intl, errors);
        });
    }
  }

  onMatched(item) {
    if (item && item.id) {
      updateDonVerif(item.id, 'MANUAL')
        .then(result => {
          modifySuccess();
          this.props.actions.propagateModel('FreeAsso_Donation', result);
        })
        .catch(errors => {
          showErrors(this.props.intl, errors);
        });
    }
  }

  onUnmatched(item) {
    if (item && item.id) {
      updateDonVerif(item.id, 'NONE')
        .then(result => {
          modifySuccess();
          this.props.actions.propagateModel('FreeAsso_Donation', result);
        })
        .catch(errors => {
          showErrors(this.props.intl, errors);
        });
    }
  }

  onClose() {
    this.setState({ donId: -1 });
  }

  onDelOne(item) {
    this.props.actions
      .delOne(item.id)
      .then(result => {
        deleteSuccess();
        this.props.actions.loadMore(true);
      })
      .catch(errors => {
        showErrors(this.props.intl, errors);
      });
  }

  onReload(event) {
    if (event) {
      event.preventDefault();
    }
    this.props.actions.loadMore(true);
  }

  onLoadMore(event) {
    if (!this.props.donation.loadMorePending) {
      this.props.actions.loadMore();
    }
  }

  onQuickSearch(quickSearch, mode) {
    this.props.actions.updateQuickSearch(quickSearch, mode);
    let timer = this.state.timer;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      this.props.actions.loadMore(true);
    }, 2000);
    this.setState({ timer: timer });
  }

  onUpdateSort(col, way, pos = 99) {
    this.props.actions.updateSort(col.col, way, pos);
    let timer = this.state.timer;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      this.props.actions.loadMore(true);
    }, 2000);
    this.setState({ timer: timer });
  }

  onSetFiltersAndSort(filters, sort) {
    this.props.actions.setFilters(filters);
    this.props.actions.setSort(sort);
    let timer = this.state.timer;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      this.props.actions.loadMore(true);
    }, 2000);
    this.setState({ timer: timer });
  }

  onClearFilters() {
    this.props.actions.initSort();
    let timer = this.state.timer;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      this.props.actions.loadMore(true);
    }, 2000);
    this.setState({ timer: timer });
  }

  itemClassName(item) {
    let cls = '';
    if (item) {
      if (cls === '') {
        if (item.don_status === 'NOK') {
          cls = 'row-line-warning';
        } else if (item.don_status === 'WAIT') {
          cls = 'row-line-info';
        } else {
          if (item.cause) {
            if (item.cause.cau_to) {
              cls = 'row-line-dark';
            }
          }
        }
      }
    }
    return cls;
  }

  onSendOne(item) {
    if (item && item.id) {
      this.props.actions
        .sendOne(item.id)
        .then(result => {
          // Message de confirmation
          messageSuccess(
            this.props.intl.formatMessage({
              id: 'app.message.send.ok',
              defaultMessage: 'Document sent',
            }),
          );
        })
        .catch(errors => {
          // Affichage des erreurs
          showErrors(this.props.intl, errors);
        });
    }
  }

  onSelect(id, key) {
    this.props.actions.onSelect(id, key);
  }

  /**
   * Download
   *
   * @param {Object} item  Objet à imprimer
   */
  onCertificate(item) {
    if (item && item.certificate) {
      this.props.actions.downloadCertificate(item.certificate.id);
    }
  }

  onSelectMenu(option) {
    switch (option) {
      case 'selectAll':
        this.props.actions.selectAll();
        break;
      case 'selectNone':
        this.props.actions.selectNone();
        break;
      case 'exportAll':
        this.props.actions.exportAsTab('all').then(res => {
          if (!res) {
            messageSuccess('Export demandé');
          }
        });
        break;
      case 'exportSelection':
        this.props.actions.exportAsTab('selection').then(res => {
          if (!res) {
            messageSuccess('Export demandé');
          }
        });
        break;
      default:
        break;
    }
  }

  render() {
    const { intl } = this.props;
    let items = [];
    if (this.props.donation.items.FreeAsso_Donation) {
      items = normalizedObjectModeler(this.props.donation.items, 'FreeAsso_Donation');
    }
    const globalActions = getGlobalActions(this);
    const inlineActions = getInlineActions(this);
    const cols = getCols(this);
    let search = '';
    const crit = this.props.donation.filters.findFirst('client.cli_lastname');
    if (crit) {
      search = crit.getFilterCrit();
    }
    const quickSearch = (
      <ResponsiveQuickSearch
        name="quickSearch"
        label={intl.formatMessage({
          id: 'app.features.donation.list.search',
          defaultMessage: 'Search by amount',
        })}
        quickSearch={search}
        onSubmit={this.onQuickSearch}
        icon={<SearchIcon />}
        reset={<SearchCancelIcon />}
      />
    );
    // Select actions
    const selectActions = getSelectActions(this);
    return (
      <div>
        <UiList
          title={intl.formatMessage({
            id: 'app.features.donation.list.title',
            defaultMessage: 'Donations',
          })}
          intl={intl}
          icon={<DonationIcon />}
          panelObject="donation"
          cols={cols}
          items={items}
          counter={
            this.props.donation.items && this.props.donation.items.TOTAL
          }
          quickSearch={quickSearch}
          mainCol="don_mnt"
          currentItem={this.state.item}
          inlineActions={inlineActions}
          globalActions={globalActions}
          sort={this.props.donation.sort}
          filters={this.props.donation.filters}
          onSearch={this.onQuickSearch}
          onClearFilters={this.onClearFilters}
          onSort={this.onUpdateSort}
          onSetFiltersAndSort={this.onSetFiltersAndSort}
          onLoadMore={this.onLoadMore}
          onClick={this.onSelectList}
          loadMorePending={this.props.donation.loadMorePending}
          loadMoreFinish={this.props.donation.loadMoreFinish}
          loadMoreError={this.props.donation.loadMoreError}
          fClassName={this.itemClassName}
          selected={this.props.donation.selected}
          selectMenu={selectActions}
          onSelect={this.onSelect}
        />
        {this.state.donId > 0 && (
          <Input modal={true} donId={this.state.donId} onClose={this.onClose} />
        )}
        {this.state.donId === 0 && <Input modal={true} onClose={this.onClose} />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    country: state.country,
    donation: state.donation,
    paymentType: state.paymentType,
    edition: state.edition,
    realm: state.auth.realm,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, propagateModel, downloadCertificate }, dispatch),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(List));
