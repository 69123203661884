import React from 'react';
import { injectIntl } from 'react-intl';
import { getShortcuts, getStatusOptions, getTypeOptions } from './';
import useForm from '../ui/useForm';
import {
  ResponsiveModalOrForm,
  InputHidden,
  InputText,
  InputTextarea,
  InputSelect,
  InputDatetime,
  InputJsonSimple,
  Row,
  Col,
} from '../ui';

/**
 * Initialisation
 *
 * @param {Object}   item
 * @param {Function} setItem
 */
const initItem = (item, setItem) => {};

/**
 * Un champ a été modifié
 *
 * @param {String}   field
 * @param {Object}   item
 * @param {Function} setitem
 */
const afterChange = (field, item, setItem) => {
  switch (field) {
    default:
      break;
  }
};

/**
 * Saisie des informations d'un modèle
 */
function Form(props) {
  let item = props.item;
  // On récupère tout ce qu'il faut pour travailler
  const {
    values,
    handleChange,
    handleSave,
    handleSubmit,
    handleCancel,
    handleNavTab,
    getErrorMessage,
    getCurrentTab,
  } = useForm(
    item,
    props.tab,
    props.onSubmit,
    props.onCancel,
    props.onNavTab,
    props.errors,
    props.intl,
    afterChange,
    initItem,
  );
  // Le formulaire
  const formDisabled = true;
  return (
    <ResponsiveModalOrForm
      title={
        !props.modify && !values.msg_body
          ? props.intl.formatMessage({
              id: 'app.features.message.form.title',
              defaultMessage: 'New model',
            })
          : values.msg_subject
      }
      className=""
      tab={getCurrentTab()}
      tabs={!props.modify ? getShortcuts(props.intl, 'new') : getShortcuts(props.intl, 'all')}
      size="md"
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onNavTab={handleNavTab}
      onSave={handleSave}
      onClose={handleCancel}
      onPrevious={props.onPrevious}
      onNext={props.onNext}
      modal={true}
      actionsButtons={props.actionsButtons}
      loader={props.loader ? props.loader : false}
    >
      <InputHidden name="id" id="id" value={values.id} />
      {values.__currentTab === 'ident' && (
        <Row>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.message.field.msg_object_name.label',
                defaultMessage: 'msg_object_name',
              })}
              id="msg_object_name"
              name="msg_object_name"
              value={values.msg_object_name}
              labelTop={true}
              disabled={formDisabled}
              onChange={handleChange}
              error={getErrorMessage('msg_object_name')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.message.field.msg_object_id.label',
                defaultMessage: 'msg_object_id',
              })}
              id="msg_object_id"
              name="msg_object_id"
              value={values.msg_object_id}
              labelTop={true}
              disabled={formDisabled}
              onChange={handleChange}
              error={getErrorMessage('msg_object_id')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputSelect
              label={props.intl.formatMessage({
                id: 'app.features.message.field.lang_id.label',
                defaultMessage: 'lang_id',
              })}
              id="lang.id"
              name="lang.id"
              value={values.lang.id}
              labelTop={true}
              disabled={formDisabled}
              onChange={handleChange}
              options={props.langOptions}
              error={getErrorMessage('lang.id')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputDatetime
              label={props.intl.formatMessage({
                id: 'app.features.message.field.msg_ts.label',
                defaultMessage: 'msg_ts',
              })}
              id="msg_ts"
              name="msg_ts"
              value={values.msg_ts}
              labelTop={true}
              disabled={formDisabled}
              onChange={handleChange}
              error={getErrorMessage('msg_ts')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputSelect
              label={props.intl.formatMessage({
                id: 'app.features.message.field.msg_status.label',
                defaultMessage: 'msg_status',
              })}
              id="msg_status"
              name="msg_status"
              value={values.msg_status}
              required={true}
              labelTop={true}
              disabled={formDisabled}
              options={getStatusOptions(props.intl)}
              onChange={handleChange}
              error={getErrorMessage('msg_status')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputSelect
              label={props.intl.formatMessage({
                id: 'app.features.message.field.msg_type.label',
                defaultMessage: 'msg_type',
              })}
              id="msg_type"
              name="msg_type"
              value={values.msg_type}
              required={true}
              labelTop={true}
              disabled={formDisabled}
              options={getTypeOptions(props.intl)}
              onChange={handleChange}
              error={getErrorMessage('msg_type')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputJsonSimple
              label={props.intl.formatMessage({
                id: 'app.features.message.field.msg_dest.label',
                defaultMessage: 'msg_dest',
              })}
              id="msg_dest"
              name="msg_dest"
              value={values.msg_dest}
              labelTop={true}
              disabled={formDisabled}
              onChange={handleChange}
              error={getErrorMessage('msg_dest')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputJsonSimple
              label={props.intl.formatMessage({
                id: 'app.features.message.field.msg_cc.label',
                defaultMessage: 'msg_cc',
              })}
              id="msg_cc"
              name="msg_cc"
              value={values.msg_cc}
              labelTop={true}
              disabled={formDisabled}
              onChange={handleChange}
              error={getErrorMessage('msg_cc')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputJsonSimple
              label={props.intl.formatMessage({
                id: 'app.features.message.field.msg_bcc.label',
                defaultMessage: 'msg_bcc',
              })}
              id="msg_bcc"
              name="msg_bcc"
              value={values.msg_bcc}
              labelTop={true}
              disabled={formDisabled}
              onChange={handleChange}
              error={getErrorMessage('msg_bcc')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputDatetime
              label={props.intl.formatMessage({
                id: 'app.features.message.field.msg_send_ts.label',
                defaultMessage: 'msg_send_ts',
              })}
              id="msg_send_ts"
              name="msg_send_ts"
              value={values.msg_send_ts}
              labelTop={true}
              disabled={formDisabled}
              onChange={handleChange}
              error={getErrorMessage('msg_send_ts')}
            />
          </Col>
          <Col size={{ xxs: 36 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.message.field.msg_send_error.label',
                defaultMessage: 'msg_send_error',
              })}
              id="msg_send_error"
              name="msg_send_error"
              value={values.msg_send_error}
              labelTop={true}
              disabled={formDisabled}
              onChange={handleChange}
              error={getErrorMessage('msg_send_error')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputJsonSimple
              label={props.intl.formatMessage({
                id: 'app.features.message.field.msg_from.label',
                defaultMessage: 'msg_from',
              })}
              id="msg_from"
              name="msg_from"
              value={values.msg_from}
              labelTop={true}
              disabled={formDisabled}
              onChange={handleChange}
              error={getErrorMessage('msg_from')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputJsonSimple
              label={props.intl.formatMessage({
                id: 'app.features.message.field.msg_reply_to.label',
                defaultMessage: 'msg_reply_to',
              })}
              id="msg_reply_to"
              name="msg_reply_to"
              value={values.msg_reply_to}
              labelTop={true}
              disabled={formDisabled}
              onChange={handleChange}
              error={getErrorMessage('msg_reply_to')}
            />
          </Col>
        </Row>
      )}
      {values.__currentTab === 'content' && (
        <Row>
          <Col size={{ xxs: 36 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.message.field.msg_subject.label',
                defaultMessage: 'msg_subject',
              })}
              id="msg_subject"
              name="msg_subject"
              value={values.msg_subject}
              labelTop={true}
              disabled={formDisabled}
              onChange={handleChange}
              error={getErrorMessage('msg_subject')}
            />
          </Col>
          <Col size={{ xxs: 36 }}>
            <InputTextarea
              label={props.intl.formatMessage({
                id: 'app.features.message.field.msg_body.label',
                defaultMessage: 'msg_body',
              })}
              id="msg_body"
              name="msg_body"
              disabled={formDisabled}
              value={values.msg_body}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('msg_body')}
            />
          </Col>
        </Row>
      )}
      {values.__currentTab === 'pjs' && (
        <Row>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.message.field.msg_pj1_name.label',
                defaultMessage: 'msg_pj1_name',
              })}
              id="msg_pj1_name"
              name="msg_pj1_name"
              value={values.msg_pj1_name}
              labelTop={true}
              disabled={true}
              onChange={handleChange}
              error={getErrorMessage('msg_pj1_name')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 24 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.message.field.msg_pj1.label',
                defaultMessage: 'msg_pj1',
              })}
              id="msg_pj1"
              name="msg_pj1"
              value={values.msg_pj1}
              labelTop={true}
              disabled={true}
              onChange={handleChange}
              error={getErrorMessage('msg_pj1')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.message.field.msg_pj2_name.label',
                defaultMessage: 'msg_pj2_name',
              })}
              id="msg_pj2_name"
              name="msg_pj2_name"
              value={values.msg_pj2_name}
              labelTop={true}
              disabled={true}
              onChange={handleChange}
              error={getErrorMessage('msg_pj2_name')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 24 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.message.field.msg_pj2.label',
                defaultMessage: 'msg_pj2',
              })}
              id="msg_pj2"
              name="msg_pj2"
              value={values.msg_pj2}
              labelTop={true}
              disabled={true}
              onChange={handleChange}
              error={getErrorMessage('msg_pj2')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.message.field.msg_pj3_name.label',
                defaultMessage: 'msg_pj3_name',
              })}
              id="msg_pj3_name"
              name="msg_pj3_name"
              value={values.msg_pj3_name}
              labelTop={true}
              disabled={true}
              onChange={handleChange}
              error={getErrorMessage('msg_pj3_name')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 24 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.message.field.msg_pj3.label',
                defaultMessage: 'msg_pj3',
              })}
              id="msg_pj3"
              name="msg_pj3"
              value={values.msg_pj3}
              labelTop={true}
              disabled={true}
              onChange={handleChange}
              error={getErrorMessage('msg_pj3')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.message.field.msg_pj4_name.label',
                defaultMessage: 'msg_pj4_name',
              })}
              id="msg_pj4_name"
              name="msg_pj4_name"
              value={values.msg_pj4_name}
              labelTop={true}
              disabled={true}
              onChange={handleChange}
              error={getErrorMessage('msg_pj4_name')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 24 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.message.field.msg_pj4.label',
                defaultMessage: 'msg_pj4',
              })}
              id="msg_pj4"
              name="msg_pj4"
              value={values.msg_pj4}
              labelTop={true}
              disabled={true}
              onChange={handleChange}
              error={getErrorMessage('msg_pj4')}
            />
          </Col>
        </Row>
      )}
    </ResponsiveModalOrForm>
  );
}

/**
 * Injection de l'intl
 */
export default injectIntl(Form);
