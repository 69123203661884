import React from 'react';
import { injectIntl } from 'react-intl';
import { getShortcuts } from './';
import useForm from '../ui/useForm';
import {
  ResponsiveModalOrForm,
  InputHidden,
  InputText,
  InputDatetime,
  Row,
  Col,
} from '../ui';

/**
 * Initialisation
 * 
 * @param {Object}   item
 * @param {Function} setItem
 */
const initItem = (item, setItem) => {
};

/**
 * Un champ a été modifié
 * 
 * @param {String}   field
 * @param {Object}   item
 * @param {Function} setitem
 */
const afterChange = (field, item, setItem) => {
  switch (field) {
    default:
      break;
  }
};

/**
 * Saisie des informations d'un modèle
 */
function Form(props) {
  let item = props.item;
  // On récupère tout ce qu'il faut pour travailler
  const {
    values,
    handleChange,
    handleSave,
    handleSubmit,
    handleCancel,
    handleNavTab,
    getErrorMessage,
    getCurrentTab,
  } = useForm(
    item,
    props.tab,
    props.onSubmit,
    props.onCancel,
    props.onNavTab,
    props.errors,
    props.intl,
    afterChange,
    initItem,
  );
  // Le formulaire
  return (
    <ResponsiveModalOrForm
      title={
        !props.modify && !values.accl_label
          ? props.intl.formatMessage({
              id: 'app.features.accounting-line.form.title',
              defaultMessage: 'New model',
            })
          : values.accl_label
      }
      className=""
      tab={getCurrentTab()}
      tabs={!props.modify ? getShortcuts(props.intl, 'new') : getShortcuts(props.intl, 'all')}
      size="md"
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onNavTab={handleNavTab}
      onSave={handleSave}
      onClose={handleCancel}
      onPrevious={props.onPrevious}
      onNext={props.onNext}
      modal={true}
      actionsButtons={props.actionsButtons}
      loader={props.loader ? props.loader : false}
    >
      <InputHidden name="id" id="id" value={values.id} />
            <Row>
      <Col size={{xxs: 36, sm: 12}}>
        <InputText
          label={props.intl.formatMessage({
            id: 'app.features.accounting-line.field.acch_id.label',
            defaultMessage: 'acch_id',
          })}
          id="acch_id"
          name="acch_id"
          value={values.acch_id}
          required={true}
          labelTop={true}
          onChange={handleChange}
          error={getErrorMessage('acch_id')}
        />
      </Col>
      <Col size={{xxs: 36, sm: 12}}>
        <InputDatetime
          label={props.intl.formatMessage({
            id: 'app.features.accounting-line.field.accl_ts.label',
            defaultMessage: 'accl_ts',
          })}
          id="accl_ts"
          name="accl_ts"
          value={values.accl_ts}
          labelTop={true}
          onChange={handleChange}
          error={getErrorMessage('accl_ts')}
        />
      </Col>
      <Col size={{xxs: 36, sm: 12}}>
        <InputText
          label={props.intl.formatMessage({
            id: 'app.features.accounting-line.field.accl_amount.label',
            defaultMessage: 'accl_amount',
          })}
          id="accl_amount"
          name="accl_amount"
          value={values.accl_amount}
          labelTop={true}
          onChange={handleChange}
          error={getErrorMessage('accl_amount')}
        />
      </Col>
      <Col size={{xxs: 36, sm: 12}}>
        <InputText
          label={props.intl.formatMessage({
            id: 'app.features.accounting-line.field.accl_label.label',
            defaultMessage: 'accl_label',
          })}
          id="accl_label"
          name="accl_label"
          value={values.accl_label}
          labelTop={true}
          onChange={handleChange}
          error={getErrorMessage('accl_label')}
        />
      </Col>
      <Col size={{xxs: 36, sm: 12}}>
        <InputText
          label={props.intl.formatMessage({
            id: 'app.features.accounting-line.field.accl_ptyp_name.label',
            defaultMessage: 'accl_ptyp_name',
          })}
          id="accl_ptyp_name"
          name="accl_ptyp_name"
          value={values.accl_ptyp_name}
          labelTop={true}
          onChange={handleChange}
          error={getErrorMessage('accl_ptyp_name')}
        />
      </Col>
      <Col size={{xxs: 36, sm: 12}}>
        <InputText
          label={props.intl.formatMessage({
            id: 'app.features.accounting-line.field.accl_complement.label',
            defaultMessage: 'accl_complement',
          })}
          id="accl_complement"
          name="accl_complement"
          value={values.accl_complement}
          labelTop={true}
          onChange={handleChange}
          error={getErrorMessage('accl_complement')}
        />
      </Col>
      </Row>

    </ResponsiveModalOrForm>
  );
}

/**
 * Injection de l'intl
 */
export default injectIntl(Form);
