import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { displayDatetime } from 'react-bootstrap-front';
import {
  InboxEmpty,
  Download,
  GetOne as GetOneIcon,
  Download as DownloadOneIcon,
  DelOne as DelOneIcon,
} from '../icons';
import { deleteSuccess, showErrors } from '../ui';
import { Input } from './';

export class InlineInbox extends Component {
  static propTypes = {
    inbox: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      id: 0,
    };
    this.onDownloadOne = this.onDownloadOne.bind(this);
    this.onDelOne = this.onDelOne.bind(this);
    this.onGetOne = this.onGetOne.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onDownloadOne(ev, elem) {
    this.props.actions.downloadOne(elem).then(result => {});
  }

  onDelOne(ev, elem) {
    this.props.actions
      .delOne(elem.id)
      .then(result => {
        // Message de confirmation
        deleteSuccess();
        this.props.actions.loadPendings(true).then();
      })
      .catch(errors => {
        // Affichage des erreurs
        showErrors(this.props.intl, errors);
      });
  }

  onGetOne(ev, elem) {
    this.setState({ id: elem.id });
  }

  onClose() {
    this.setState({ id: 0 });
  }

  render() {
    return (
      <div className="inbox-inline-inbox">
        <ul className="list-group">
          {Array.isArray(this.props.inbox.modelsPending) &&
            this.props.inbox.modelsPending.filter(elem => elem.inbox_keep === false).map(elem => {
              return (
                <li
                  className={classnames(
                    'card inbox-card ml-3 mr-3 mt-2',
                    elem.inbox_download_ts ? 'border-primary' : 'border-secondary',
                  )}
                  key={`inbox-${elem.id}`}
                >
                  <div className="card-header">
                    {elem.inbox_download_ts ? <InboxEmpty /> : <Download />}
                    <div className="header-toolbar btn-group">
                      <button
                        type="button"
                        className="btn btn-secondary btn-xs btn-inline"
                        onClick={ev => this.onDownloadOne(ev, elem)}
                      >
                        <DownloadOneIcon size={0.8} />
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary btn-xs btn-inline"
                        onClick={ev => this.onGetOne(ev, elem)}
                      >
                        <GetOneIcon size={0.8} />
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning btn-xs btn-inline"
                        onClick={ev => this.onDelOne(ev, elem)}
                      >
                        <DelOneIcon size={0.8} />
                      </button>
                    </div>
                    <div className="header-date">
                      <span>{displayDatetime(elem.inbox_ts)}</span>
                    </div>
                  </div>
                  <div className="card-body">
                    <span className="pl-2">{elem.inbox_filename}</span>
                  </div>
                </li>
              );
            })}
        </ul>
        {this.state.id > 0 && (
          <Input modal={true} id={this.state.id} onClose={this.onClose} loader={false} />
        )}
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    inbox: state.inbox,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(InlineInbox));
