import React from 'react';
import { injectIntl } from 'react-intl';
import { getShortcuts } from './';
import useForm from '../ui/useForm';
import {
  ResponsiveModalOrForm,
  InputHidden,
  InputText,
  InputSpin,
  InputSelect,
} from '../ui';
import { sessionStatus, sessionType } from './';

/**
 * Initialisation
 * 
 * @param {Object}   item
 * @param {Function} setItem
 */
const initItem = (item, setItem) => {
};

/**
 * Un champ a été modifié
 * 
 * @param {String}   field
 * @param {Object}   item
 * @param {Function} setitem
 */
const afterChange = (field, item, setItem) => {
  switch (field) {
    default:
      break;
  }
};

/**
 * Saisie des informations d'un modèle
 */
function Form(props) {
  let item = props.item;
  // On récupère tout ce qu'il faut pour travailler
  const {
    values,
    handleChange,
    handleSave,
    handleSubmit,
    handleCancel,
    handleNavTab,
    getErrorMessage,
    getCurrentTab,
  } = useForm(
    item,
    props.tab,
    props.onSubmit,
    props.onCancel,
    props.onNavTab,
    props.errors,
    props.intl,
    afterChange,
    initItem,
  );
  // Le formulaire
  const nYear = new Date().getFullYear() + 1;
  return (
    <ResponsiveModalOrForm
      title={
        !props.modify && !values.sess_name
          ? props.intl.formatMessage({
              id: 'app.features.session.form.title',
              defaultMessage: 'New model',
            })
          : values.sess_name
      }
      className=""
      tab={getCurrentTab()}
      tabs={!props.modify ? getShortcuts(props.intl, 'new') : getShortcuts(props.intl, 'all')}
      size="sm"
      height="sm"
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onNavTab={handleNavTab}
      onSave={handleSave}
      onClose={handleCancel}
      onPrevious={props.onPrevious}
      onNext={props.onNext}
      modal={true}
      actionsButtons={props.actionsButtons}
      loader={props.loader ? props.loader : false}
    >
     <InputHidden name="id" id="id" value={values.id} />
      <div className="row">
        <div className="col-sm-w12">
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.session.field.sess_name.label',
              defaultMessage: 'Name',
            })}
            name="sess_name"
            id="sess_name"
            required={true}
            value={values.sess_name}
            onChange={handleChange}
            error={getErrorMessage('sess_name')}
          />
        </div>
        <div className="col-md-w12">
          <InputSpin
            label={props.intl.formatMessage({
              id: 'app.features.session.field.sess_year.label',
              defaultMessage: 'Year',
            })}
            name="sess_year"
            id="sess_year"
            required={true}
            maxValue={nYear}
            minValue={1990}
            labelTop={true}
            value={values.sess_year}
            onChange={handleChange}
            error={getErrorMessage('sess_year')}
          />
        </div>
        <div className="col-md-w12">
          <InputSpin
            label={props.intl.formatMessage({
              id: 'app.features.session.field.sess_month.label',
              defaultMessage: 'Month',
            })}
            name="sess_month"
            id="sess_month"
            maxValue={99}
            minValue={0}
            labelTop={true}
            value={values.sess_month}
            onChange={handleChange}
            error={getErrorMessage('sess_month')}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-w18">
          <InputSelect
            label={props.intl.formatMessage({
              id: 'app.features.session.field.sess_status.label',
              defaultMessage: 'Status',
            })}
            name="sess_status"
            id="sess_status"
            required={true}
            disabled={false}
            value={values.sess_status}
            options={sessionStatus}
            onChange={handleChange}
            error={getErrorMessage('sess_status')}
          />
        </div>
        <div className="col-xs-w18">
          <InputSelect
            label={props.intl.formatMessage({
              id: 'app.features.session.field.sess_type.label',
              defaultMessage: 'Type',
            })}
            name="sess_type"
            id="sess_type"
            required={true}
            disabled={false}
            value={values.sess_type}
            options={sessionType}
            onChange={handleChange}
            error={getErrorMessage('sess_type')}
          />
        </div>
      </div>
    </ResponsiveModalOrForm>
  );
}

/**
 * Injection de l'intl
 */
export default injectIntl(Form);
