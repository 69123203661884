import React from 'react';
// import PropTypes from 'prop-types';
import { mdiAlertCircle as myIcon } from '@mdi/js';
import { Icon } from './';

export default function Warning(props) {
  return <Icon path={myIcon} {...props} />;
}

Warning.propTypes = {};
Warning.defaultProps = {};
