import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class ColLink extends Component {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    className: 'text-secondary',
    label: 'Plus',
    onClick: null,
  };

  render() {
    return (
      <div
        className={classnames(
          'col-xs-w36 text-center',
          this.props.onClick && 'col-link',
          this.props.className,
        )}
        onClick={this.props.onClick}
      >
        <span className="col-link-label">{this.props.label}</span>
      </div>
    );
  }
}
