export const HISTORY_CLEAR_ITEMS = 'HISTORY_CLEAR_ITEMS';
export const HISTORY_CREATE_ONE_BEGIN = 'HISTORY_CREATE_ONE_BEGIN';
export const HISTORY_CREATE_ONE_SUCCESS = 'HISTORY_CREATE_ONE_SUCCESS';
export const HISTORY_CREATE_ONE_FAILURE = 'HISTORY_CREATE_ONE_FAILURE';
export const HISTORY_CREATE_ONE_DISMISS_ERROR = 'HISTORY_CREATE_ONE_DISMISS_ERROR';
export const HISTORY_DEL_ONE_BEGIN = 'HISTORY_DEL_ONE_BEGIN';
export const HISTORY_DEL_ONE_SUCCESS = 'HISTORY_DEL_ONE_SUCCESS';
export const HISTORY_DEL_ONE_FAILURE = 'HISTORY_DEL_ONE_FAILURE';
export const HISTORY_DEL_ONE_DISMISS_ERROR = 'HISTORY_DEL_ONE_DISMISS_ERROR';
export const HISTORY_EXPORT_AS_TAB_BEGIN = 'HISTORY_EXPORT_AS_TAB_BEGIN';
export const HISTORY_EXPORT_AS_TAB_SUCCESS = 'HISTORY_EXPORT_AS_TAB_SUCCESS';
export const HISTORY_EXPORT_AS_TAB_FAILURE = 'HISTORY_EXPORT_AS_TAB_FAILURE';
export const HISTORY_EXPORT_AS_TAB_DISMISS_ERROR = 'HISTORY_EXPORT_AS_TAB_DISMISS_ERROR';
export const HISTORY_EXPORT_BEGIN = 'HISTORY_EXPORT_BEGIN';
export const HISTORY_EXPORT_SUCCESS = 'HISTORY_EXPORT_SUCCESS';
export const HISTORY_EXPORT_FAILURE = 'HISTORY_EXPORT_FAILURE';
export const HISTORY_EXPORT_DISMISS_ERROR = 'HISTORY_EXPORT_DISMISS_ERROR';
export const HISTORY_INIT_FILTERS = 'HISTORY_INIT_FILTERS';
export const HISTORY_INIT_SORT = 'HISTORY_INIT_SORT';
export const HISTORY_LOAD_MORE_INIT = 'HISTORY_LOAD_MORE_INIT';
export const HISTORY_LOAD_MORE_BEGIN = 'HISTORY_LOAD_MORE_BEGIN';
export const HISTORY_LOAD_MORE_SUCCESS = 'HISTORY_LOAD_MORE_SUCCESS';
export const HISTORY_LOAD_MORE_FAILURE = 'HISTORY_LOAD_MORE_FAILURE';
export const HISTORY_LOAD_MORE_DISMISS_ERROR = 'HISTORY_LOAD_MORE_DISMISS_ERROR';
export const HISTORY_LOAD_ONE_BEGIN = 'HISTORY_LOAD_ONE_BEGIN';
export const HISTORY_LOAD_ONE_SUCCESS = 'HISTORY_LOAD_ONE_SUCCESS';
export const HISTORY_LOAD_ONE_FAILURE = 'HISTORY_LOAD_ONE_FAILURE';
export const HISTORY_LOAD_ONE_DISMISS_ERROR = 'HISTORY_LOAD_ONE_DISMISS_ERROR';
export const HISTORY_ON_SELECT = 'HISTORY_ON_SELECT';
export const HISTORY_PRINT_ONE_BEGIN = 'HISTORY_PRINT_ONE_BEGIN';
export const HISTORY_PRINT_ONE_SUCCESS = 'HISTORY_PRINT_ONE_SUCCESS';
export const HISTORY_PRINT_ONE_FAILURE = 'HISTORY_PRINT_ONE_FAILURE';
export const HISTORY_PRINT_ONE_DISMISS_ERROR = 'HISTORY_PRINT_ONE_DISMISS_ERROR';
export const HISTORY_PROPAGATE = 'HISTORY_PROPAGATE';
export const HISTORY_SELECT_NONE = 'HISTORY_SELECT_NONE';
export const HISTORY_SELECT_ALL = 'HISTORY_SELECT_ALL';
export const HISTORY_SET_CURRENT = 'HISTORY_SET_CURRENT';
export const HISTORY_SET_PREVIOUS = 'HISTORY_SET_PREVIOUS';
export const HISTORY_SET_NEXT_BEGIN = 'HISTORY_SET_NEXT_BEGIN';
export const HISTORY_SET_NEXT_SUCCESS = 'HISTORY_SET_NEXT_SUCCESS';
export const HISTORY_SET_NEXT_FAILURE = 'HISTORY_SET_NEXT_FAILURE';
export const HISTORY_SET_NEXT_DISMISS_ERROR = 'HISTORY_SET_NEXT_DISMISS_ERROR';
export const HISTORY_SET_FILTERS = 'HISTORY_SET_FILTERS';
export const HISTORY_SET_SORT = 'HISTORY_SET_SORT';
export const HISTORY_UPDATE_ONE_BEGIN = 'HISTORY_UPDATE_ONE_BEGIN';
export const HISTORY_UPDATE_ONE_SUCCESS = 'HISTORY_UPDATE_ONE_SUCCESS';
export const HISTORY_UPDATE_ONE_FAILURE = 'HISTORY_UPDATE_ONE_FAILURE';
export const HISTORY_UPDATE_ONE_DISMISS_ERROR = 'HISTORY_UPDATE_ONE_DISMISS_ERROR';
export const HISTORY_UPDATE_QUICK_SEARCH = 'HISTORY_UPDATE_QUICK_SEARCH';
export const HISTORY_UPDATE_SORT = 'HISTORY_UPDATE_SORT';
export const HISTORY_LOAD_PENDINGS_INIT = 'HISTORY_LOAD_PENDINGS_INIT';
export const HISTORY_LOAD_PENDINGS_BEGIN = 'HISTORY_LOAD_PENDINGS_BEGIN';
export const HISTORY_LOAD_PENDINGS_SUCCESS = 'HISTORY_LOAD_PENDINGS_SUCCESS';
export const HISTORY_LOAD_PENDINGS_FAILURE = 'HISTORY_LOAD_PENDINGS_FAILURE';
export const HISTORY_LOAD_PENDINGS_DISMISS_ERROR = 'HISTORY_LOAD_PENDINGS_DISMISS_ERROR';
