import React from 'react';
// import PropTypes from 'prop-types';

export default function SimpleCheck() {
  return (
    <div className="icons-simple-check">
      Component content: icons/SimpleCheck
    </div>
  );
};

SimpleCheck.propTypes = {};
SimpleCheck.defaultProps = {};
