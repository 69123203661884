import React from 'react';
import { injectIntl } from 'react-intl';
import { Highlight, Row } from 'react-bootstrap-front';
import {
  GetOne as GetOneIcon,
  Save as SaveIcon,
  Reload as ReloadIcon,
  DashboardReset as ResetIcon,
  Stop as StopIcon,
} from '../icons';

export function DashboardToolbar(props) {
  const { intl } = props;
  return (
    <div className="dashboard-dashboard-toolbar">
      <Row>
        <div className="col-xs-w6 col-lg-w2 text-center" />
        <div className="col-xs-w10 col-lg-w14 text-center" />
        <div className="col-xs-w20 text-right">
          <div className="nav justify-content-end">
            {!props.editable && (
              <Highlight
                className="nav-item"
                position="bottom"
                theme="NAV"
                title={intl.formatMessage({
                  id: 'app.features.dashboard.toolbar.refresh.help',
                  defaultMessage: 'Refresh',
                })}
              >
                <button
                  className="btn btn-light text-secondary"
                  title={intl.formatMessage({
                    id: 'app.features.dashboard.toolbar.refresh',
                    defaultMessage: 'Refresh',
                  })}
                  onClick={props.onRefresh}
                >
                  <ReloadIcon />
                </button>
              </Highlight>
            )}
            {props.editable && (
              <Highlight
                className="nav-item"
                position="bottom"
                theme="NAV"
                title={intl.formatMessage({
                  id: 'app.features.dashboard.toolbar.default.help',
                  defaultMessage: 'Reload default',
                })}
              >
                <button
                  className="btn btn-warning text-light"
                  title={intl.formatMessage({
                    id: 'app.features.dashboard.toolbar.default',
                    defaultMessage: 'REload default',
                  })}
                  onClick={props.onReset}
                >
                  <ResetIcon />
                </button>
              </Highlight>
            )}
            {props.editable && (
              <Highlight
                className="nav-item"
                position="bottom"
                theme="NAV"
                title={intl.formatMessage({
                  id: 'app.features.dashboard.toolbar.save.help',
                  defaultMessage: 'Save',
                })}
              >
                <button
                  className="btn btn-primary text-light"
                  title={intl.formatMessage({
                    id: 'app.features.dashboard.toolbar.save',
                    defaultMessage: 'Save',
                  })}
                  onClick={props.onSave}
                >
                  <SaveIcon />
                </button>
              </Highlight>
            )}
            {!props.editable ? (
              <Highlight
                className="nav-item"
                position="bottom"
                theme="NAV"
                title={intl.formatMessage({
                  id: 'app.features.dashboard.toolbar.edit.help',
                  defaultMessage: 'Save',
                })}
              >
                <button
                  className="btn btn-light text-secondary"
                  title={intl.formatMessage({
                    id: 'app.features.dashboard.toolbar.edit',
                    defaultMessage: 'Save',
                  })}
                  onClick={props.onEditStart}
                >
                  <GetOneIcon />
                </button>
              </Highlight>
            ) : (
              <Highlight
                className="nav-item"
                position="bottom"
                theme="NAV"
                title={intl.formatMessage({
                  id: 'app.features.dashboard.toolbar.quit.help',
                  defaultMessage: 'Quit',
                })}
              >
                <button
                  className="btn btn-light text-secondary"
                  title={intl.formatMessage({
                    id: 'app.features.dashboard.toolbar.quit',
                    defaultMessage: 'Quit',
                  })}
                  onClick={props.onEditCancel}
                >
                  <StopIcon />
                </button>
              </Highlight>
            )}
          </div>
        </div>
      </Row>
    </div>
  );
}

export default injectIntl(DashboardToolbar);
