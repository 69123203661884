import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import { Container } from 'react-bootstrap-front';
import { WindowMaximize, WindowMinimize, WindowClose } from '../icons';

export default class DashboardCard extends Component {
  static propTypes = {
    title: PropTypes.string,
    titleMini: PropTypes.string,
    header: PropTypes.element,
    counter: PropTypes.string,
    url: PropTypes.string,
    icon: PropTypes.element,
    overlay: PropTypes.bool,
    maximized: PropTypes.bool,
    onMaximise: PropTypes.func,
    onMinimize: PropTypes.func,
  };
  static defaultProps = {
    title: '',
    titleMini: '',
    header: null,
    counter: '',
    url: '',
    icon: null,
    overlay: false,
    maximized: true,
    onMaximize: null,
    onMinimize: null,
  };

  render() {
    if (this.props.maximized) {
      return (
        <div className="dashboard-stat-card text-center">
          <div className="card card-maximized">
            <div className="dashboard-stat-card-header card-header card-header-warning card-header-icon">
              {this.props.url ? (
                <NavLink strict className="nav-link link" to={this.props.url}>
                  <div className="card-icon card-icon-btn text-secondary">{this.props.icon}</div>
                </NavLink>
              ) : (
                //<button type="button" className="btn btn-secondary card-icon">
                <div className="card-icon text-secondary">{this.props.icon}</div>
                //</button>
              )}
              <div className="btn-group float-right">
                {this.props.overlay && (
                  <>
                    <button
                      className="btn btn-sm btn-secondary-light text-secondary"
                      onClick={this.props.onMinimize}
                    >
                      <WindowMinimize />
                    </button>
                    <button
                      className="btn btn-sm btn-secondary-light text-secondary"
                      onClick={this.props.onClose}
                    >
                      <WindowClose />
                    </button>
                  </>
                )}
              </div>
              <div className="card-draggable-area">
                <p
                  className={classnames(
                    'card-category',
                    this.props.overlay && 'card-draggable-area grab',
                  )}
                >
                  {this.props.title}
                </p>
                <div className="card-title">
                  <Container>{this.props.header}</Container>
                </div>
              </div>
            </div>
            <div className="card-body dashboard-card-body custom-scrollbar text-left" style={{ height: '100%' }}>
              <div className="">
                {this.props.children && <Container>{this.props.children}</Container>}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="dashboard-stat-card text-center">
          <div className="card-minimized">
            <div className="dashboard-stat-card-header card-minimized-header card-header-warning card-header-icon">
              <div className="btn-group-vertical card-actions">
                {this.props.overlay ? (
                  <>
                    <button
                      className="btn btn-sm btn-secondary-light text-secondary"
                      onClick={this.props.onMaximize}
                    >
                      <WindowMaximize />
                    </button>
                    <button
                      className="btn btn-sm btn-secondary-light text-secondary"
                      onClick={this.props.onClose}
                    >
                      <WindowClose />
                    </button>
                  </>
                ) : (
                  <p className="card-total no-selector">{this.props.counter}</p>
                )}
              </div>
              <div className="card-draggable-area">
                <div className="card-body dashboard-card-body text-left">
                  {this.props.url && !this.props.overlay ? (
                    <NavLink strict className="nav-link link" to={this.props.url}>
                      <div className="card-icon card-icon-btn text-secondary">
                        {this.props.icon}
                      </div>
                    </NavLink>
                  ) : (
                    <div className="card-icon text-secondary">{this.props.icon}</div>
                  )}
                  <p className="card-title no-selector">{this.props.titleMini !== '' ? this.props.titleMini : this.props.title}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
