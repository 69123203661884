import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { getJsonApi } from 'jsonapi-front';
import { ResponsiveQuickSearch, verifyScope, Highlight } from 'react-bootstrap-front';
import { Responsive, WidthProvider } from 'react-grid-layout';
import * as actions from './redux/actions';
import {
  updateQuickSearch as updateQuickSearchCause,
  loadMore as loadMoreCause,
} from '../cause/redux/actions';
import {
  updateQuickSearch as updateQuickSearchClient,
  loadMore as loadMoreClient,
} from '../client/redux/actions';
import { loadMore as loadMoreData } from '../data/redux/loadMore';
import { updateConfig } from '../auth/redux/actions';
import {
  Search as SearchIcon,
  Person as ClientIcon,
  Tree as TreeIcon,
  Paw as PawIcon,
} from '../icons';
import { DashboardCardStat, DashboardToolbar } from './';
import { getFromLS, saveToLS, modifySuccess, showErrors } from '../ui';
import { Input as ModifyData, getData } from '../data';
import { Dashboard as DashboardJobqueues } from '../jobqueue';
import { Dashboard as DashboardNotification } from '../notification';
import { Dashboard as DashboardGroup } from '../group';
//import { PendingAlerts } from '../alert';

const getLayoutSize = (layouts, breakpoint, key) => {
  let size = 'sm';
  const layoutBr = layouts[breakpoint] || [];
  if (Array.isArray(layoutBr)) {
    const layout = layoutBr.find(elem => elem.i === key);
    if (layout) {
      if (layout.w < 9) {
        size = 'sm';
      } else {
        if (layout.w < 18) {
          size = 'md';
        } else {
          if (layout.w < 27) {
            size = 'lg';
          } else {
            size = 'xl';
          }
        }
      }
    }
  }
  return size;
};

const ResponsiveReactGridLayout = WidthProvider(Responsive, { measureBeforeMount: true });

export class DashboardGrid extends Component {
  static propTypes = {
    dashboard: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const originalLayouts = getFromLS('layouts') || {};
    this.state = {
      breakpoint: 'lg',
      layouts: JSON.parse(JSON.stringify(originalLayouts)),
      editable: false,
      savedLayouts: {},
      editHectares: false,
      editAnimals: false,
      timerCause: null,
      timerClient: null,
    };
    this.onLayoutChange = this.onLayoutChange.bind(this);
    this.onBreakpointChange = this.onBreakpointChange.bind(this);
    this.onResetLayout = this.onResetLayout.bind(this);
    this.onResizeStop = this.onResizeStop.bind(this);
    this.onEditStart = this.onEditStart.bind(this);
    this.onEditCancel = this.onEditCancel.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
    this.onSaveLayout = this.onSaveLayout.bind(this);
    this.onEditHectares = this.onEditHectares.bind(this);
    this.onEditAnimals = this.onEditAnimals.bind(this);
    this.onEditClose = this.onEditClose.bind(this);
    this.onQuickSearchCause = this.onQuickSearchCause.bind(this);
    this.onQuickSearchClient = this.onQuickSearchClient.bind(this);
  }

  onLayoutChange(layout, layouts) {
    this.setState({ layouts });
  }

  onRefresh(evt) {
    if (evt) {
      evt.preventDefault();
    }
    this.props.actions.loadMore();
  }

  onBreakpointChange(breakpoint) {
    this.setState({ breakpoint });
  }

  onResetLayout() {
    const originalLayouts = {};
    const layouts = JSON.parse(JSON.stringify(originalLayouts));
    this.setState({ layouts });
  }

  onResizeStop(param1, param2) {}

  onEditStart() {
    this.setState({ editable: true, savedLayouts: this.state.layouts });
  }

  onEditCancel() {
    this.setState({ editable: false, editAnimals: false, layouts: this.state.savedLayouts });
  }

  onSaveLayout(evt) {
    if (evt) {
      evt.preventDefault();
    }
    saveToLS('layouts', this.state.layouts);
    this.setState({ editable: false });
    const datas = {
      type: 'FreeSSO_ConfigRequest',
      config: JSON.stringify(this.state.layouts),
      config_type: 'cache',
    };
    let obj = getJsonApi(datas);
    this.props.actions
      .updateConfig(obj)
      .then(result => {
        modifySuccess();
      })
      .catch(errors => {
        showErrors(this.props.intl, errors, 'updateOneError');
      });
  }

  onEditHectares() {
    this.setState({ editHectares: true });
  }

  onEditAnimals() {
    this.setState({ editAnimals: true });
  }

  onEditClose() {
    this.setState({ editHectares: false, editAnimals: false });
    this.props.actions.loadMoreData({}, true);
  }

  onQuickSearchCause(quickSearch) {
    this.props.actions.updateQuickSearchCause(quickSearch);
    let timerCause = this.state.timerCause;
    if (timerCause) {
      clearTimeout(timerCause);
    }
    timerCause = setTimeout(() => {
      this.props.actions.loadMoreCause(true);
      this.props.actions.push('/cause/88');
    }, this.props.loadTimeOut);
    this.setState({ timerCause: timerCause });
  }

  onQuickSearchClient(quickSearch) {
    this.props.actions.updateQuickSearchClient(quickSearch);
    let timerClient = this.state.timerClient;
    if (timerClient) {
      clearTimeout(timerClient);
    }
    timerClient = setTimeout(() => {
      this.props.actions.loadMoreClient(true);
      this.props.actions.push('/client');
    }, this.props.loadTimeOut);
    this.setState({ timerClient: timerClient });
  }

  render() {
    const { intl } = this.props;
    const { layouts, breakpoint } = this.state;
    const authData = verifyScope(
      this.props.auth.user && this.props.auth.user.user_scope,
      'FreeAsso/Config/Data',
    );
    const quickSearchCause = (
      <ResponsiveQuickSearch
        name="quickSearch"
        label={intl.formatMessage({
          id: 'app.features.cause.list.search',
          defaultMessage: 'Search by name',
        })}
        onSubmit={this.onQuickSearchCause}
        icon={<SearchIcon className="text-secondary" />}
      />
    );
    const quickSearchClient = (
      <ResponsiveQuickSearch
        name="quickSearch"
        label={intl.formatMessage({
          id: 'app.features.client.list.search',
          defaultMessage: 'Search by first name, last name or email',
        })}
        onSubmit={this.onQuickSearchClient}
        icon={<SearchIcon className="text-secondary" />}
      />
    );
    if (this.props.auth.authenticated && this.props.dashboard.stats) {
      return (
        <div className="dashboard-dashboard-grid">
          <DashboardToolbar
            editable={this.state.editable}
            onRefresh={this.onRefresh}
            onReset={this.onResetLayout}
            onSave={this.onSaveLayout}
            onResetLayout={this.onResetLayout}
            onEditStart={this.onEditStart}
            onEditCancel={this.onEditCancel}
          />
          <div className="dashboard-dashboard-content custom-scrollbar">
            <ResponsiveReactGridLayout
              className="layout p-2"
              cols={{ lg: 36, md: 30, sm: 20, xs: 12, xxs: 6 }}
              rowHeight={30}
              verticalCompact={true}
              onResize={this.onResize}
              onLayoutChange={this.onLayoutChange}
              onResizeStop={this.onResizeStop}
              onBreakpointChange={this.onBreakpointChange}
              draggableHandle=".card-draggable-area"
              layouts={layouts}
              isDraggable={this.state.editable}
              isResizable={this.state.editable}
            >
              <div
                key="friends"
                data-grid={{ w: 6, h: 3, x: 1, y: 1, minW: 6, maxW: 6, minH: 3, maxH: 3 }}
              >
                <Highlight
                  className="nav-item"
                  position="bottom"
                  theme="NAV"
                  title={intl.formatMessage({
                    id: 'app.features.dashboard.card.friends.help',
                    defaultMessage: 'Refresh',
                  })}
                >
                  <DashboardCardStat
                    title={intl.formatMessage({
                      id: 'app.features.dashboard.friends',
                      defaultMessage: 'Friends',
                    })}
                    count={this.props.dashboard.stats.total_friends}
                    icon={<ClientIcon size={2} />}
                    url="/client"
                    footer={quickSearchClient}
                    overlay={this.state.editable}
                    size={getLayoutSize(layouts, breakpoint, 'friends')}
                  />
                </Highlight>
              </div>
              <div
                key="hectares"
                data-grid={{ w: 6, h: 3, x: 8, y: 1, minW: 6, maxW: 6, minH: 3, maxH: 3 }}
              >
                <Highlight
                  className="nav-item"
                  position="bottom"
                  theme="NAV"
                  title={intl.formatMessage({
                    id: 'app.features.dashboard.card.hectares.help',
                    defaultMessage: 'Refresh',
                  })}
                >
                  <DashboardCardStat
                    title={intl.formatMessage({
                      id: 'app.features.dashboard.hectares',
                      defaultMessage: 'Hectares',
                    })}
                    count={getData(this.props.data.models, 4)}
                    icon={<TreeIcon size={2} />}
                    overlay={this.state.editable}
                    onEdit={authData && this.onEditHectares}
                    size={getLayoutSize(layouts, breakpoint, 'hectares')}
                  />
                </Highlight>
              </div>
              <div
                key="animals"
                data-grid={{ w: 6, h: 3, x: 14, y: 1, minW: 6, maxW: 6, minH: 3, maxH: 3 }}
              >
                <Highlight
                  className="nav-item"
                  position="bottom"
                  theme="NAV"
                  title={intl.formatMessage({
                    id: 'app.features.dashboard.card.cause.help',
                    defaultMessage: 'Refresh',
                  })}
                >
                  <DashboardCardStat
                    title={intl.formatMessage({
                      id: 'app.features.dashboard.animals',
                      defaultMessage: 'Animaux',
                    })}
                    count={getData(this.props.data.models, 5)}
                    icon={<PawIcon size={2} />}
                    url="/cause/88"
                    footer={quickSearchCause}
                    overlay={this.state.editable}
                    onEdit={authData && this.onEditAnimals}
                    size={getLayoutSize(layouts, breakpoint, 'animals')}
                  />
                </Highlight>
              </div>
              <div
                key="notification"
                data-grid={{ w: 15, h: 10, x: 20, y: 7, minW: 9, maxW: 36, minH: 5, maxH: 18 }}
              >
                <DashboardNotification overlay={this.state.editable} />
              </div>
              <div
                key="jobqueues"
                data-grid={{ w: 18, h: 5, x: 1, y: 5, minW: 9, maxW: 36, minH: 5 }}
              >
                <DashboardJobqueues overlay={this.state.editable} />
              </div>
              <div
                key="group"
                data-grid={{ w: 6, h: 5, x: 18, y: 5, minW: 6, maxW: 36, minH: 5 }}
              >
                <DashboardGroup overlay={this.state.editable} />
              </div>
            </ResponsiveReactGridLayout>
          </div>
          {this.state.editHectares && (
            <ModifyData modal={true} dataId={4} onClose={this.onEditClose} />
          )}
          {this.state.editAnimals && (
            <ModifyData modal={true} dataId={5} onClose={this.onEditClose} />
          )}
        </div>
      );
    }
    return null;
  }
}

function mapStateToProps(state) {
  return {
    dashboard: state.dashboard,
    data: state.data,
    auth: state.auth,
    client: state.client,
    loadTimeOut: state.auth.loadTimeOut,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...actions,
        loadMoreData,
        updateConfig,
        loadMoreClient,
        updateQuickSearchClient,
        updateQuickSearchCause,
        loadMoreCause,
        push,
      },
      dispatch,
    ),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DashboardGrid));
