export { default as freeAssoApi } from './api';
export * from './socket';

/**/
export { propagateModel, propagateCreate, propagateUpdate, propagateDelete } from './update';

/**
 * 
 */
export const setModelValue = (model, key, value) => {
  const parts = key.split('.');
  switch (parts.length) {
    case 4: {
      model[parts[0]][parts[1]][parts[2]][parts[3]] = value;
      break;
    }
    case 3: {
      model[parts[0]][parts[1]][parts[2]] = value;
      break;
    }
    case 2: {
      model[parts[0]][parts[1]] = value;
      break;
    }
    default: {
      model[key] = value;
      break;
    }
  }
};

/**
 * 
 */
export const validateRegex = (value, regex) => {
  if (regex !== '') {
    try {
      const myRegex = new RegExp(regex, 'i');
      return myRegex.test(value);
    } catch (ex) {}
  }
  return true;
};

/**
 * 
 */
export function isInViewPort(el, threshold) {
  threshold = threshold || 0;

  var rect = el.getBoundingClientRect();
  var height = (rect.height || el.clientHeight || 0) + threshold;
  var width = (rect.width || el.clientWidth || 0) + threshold;

  return (
    rect.top >= -height &&
    rect.left >= -width &&
    rect.bottom <= height + window.innerHeight &&
    rect.right <= width + window.innerWidth
  );
}

/**
 * 
 */
export function getObjectmemberValue(obj, member) {
  const elems = member.split('.');
  let value = obj;
  while (elems.length > 0) {
    let mb = elems.shift();
    if (value.hasOwnProperty(mb)) {
      value = value[mb];
    } else {
      return null;
    }
  }
  return value;
}

/**
 * 
 */
export function modelsToSelect(models, value, label) {
  let arr = [];
  const ME = models.MAINELEM;
  const elems = models[ME];
  if (elems) {
    Object.keys(elems).forEach(oneKey => {
      const oneElem = elems[oneKey];
      arr.push({ value: oneElem.id, label: oneElem.attributes[label], id: oneElem.id, attributes: oneElem.attributes });
    });
    arr.sort(function(a, b) {
      if (a.label.toUpperCase() > b.label.toUpperCase()) {
        return 1;
      } else {
        if (a.label.toUpperCase() < b.label.toUpperCase()) {
          return -1;
        }
      }
      return 0;
    });
  }
  return arr;
}

/**
 * 
 */
export function getPreviousNext(items, id) {
  let ret = { prev: null, next: null };
  if (items.SORTEDELEMS) {
    const idx = items.SORTEDELEMS.findIndex(elt => elt === id);
    if (idx > 0) {
      ret.prev = items.SORTEDELEMS[idx - 1];
    }
    if (idx < items.SORTEDELEMS.length) {
      ret.next = items.SORTEDELEMS[idx + 1];
    }
  }
  return ret;
}

/**
 * 
 */
export function intlDateTime(date, dateOnly = false, timeOnly = false, seconds = false) {
  if (date) {
    try {
      const laDate = new Date(date);
      let options = {};
      if (!dateOnly) {
        if (!timeOnly) {
          options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
          };
        } else {
          options = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
          };
        }
        if (seconds) {
          options.seconds = 'numéric';
        }
      }
      return new Intl.DateTimeFormat('fr-FR', options).format(laDate);
    } catch (ex) {
      console.log(ex);
    }
  }
  return '';
}

/**
 *
 */
export const inTheFuture = date => {
  if (date === null) {
    return true;
  }
  try {
    const laDate1 = Date.now();
    const laDate2 = new Date(date);
    if (laDate2 > laDate1) {
      return true;
    }
  } catch (ex) {}
  return false;
};

/**
 *
 */
export const compareDate = (date1, date2) => {
  let result = 0;
  try {
    const laDate1 = new Date(date1);
    const laDate2 = new Date(date2);
    if (laDate1 < laDate2) {
      result = 1;
    } else if (laDate1 > laDate2) {
      result = -1;
    }
  } catch (ex) {
    console.log(ex);
  }
  return result;
};

/**
 * 
 */
export const getLibObject = (object, id) => {
  let libObj = object;
  switch (object) {
    case 'FreeFW_Alert':
      libObj = 'Alerte';
      break;
    case 'FreeSSO_Broker':
      libObj = 'Accès';
      break;
    case 'FreeAsso_Client':
      libObj = 'Donateur';
      break;
    case 'FreeAsso_Cause':
      libObj = 'Programme';
      break;
    case 'FreeAsso_CauseMedia':
      libObj = 'Média programme';
      break;
    case 'FreeAsso_Certificate':
      libObj = 'Certificat';
      break;
    case 'FreeAsso_Donation':
      libObj = 'Paiement';
      break;
    case 'FreeAsso_Sponsorship':
      libObj = 'Paiement régulier';
      break;
    case 'FreeAsso_Receipt':
      libObj = 'Reçu';
      break;
    case 'FreeSSO_Domain':
      libObj = 'Domaine';
      break;
    case 'FreeFW_Email':
      libObj = 'Mailing';
      break;
    case 'FreeFW_EmailLang':
      libObj = 'Version mailing';
      break;
    case 'FreeFW_Edition':
      libObj = 'Edition';
      break;
    case 'FreeFW_EditionLang':
      libObj = 'Version édition';
      break;
    case 'FreeFW_Follow':
      libObj = 'Suivi';
      break;
    case 'FreeSSO_Group':
      libObj = 'Entité';
      break;
    case 'FreeSSO_GroupType':
      libObj = 'Type d\'entité';
      break;
    case 'FreeSSO_GroupUser':
      libObj = 'Lien utilisateur-groupe';
      break;
    case 'FreeFW_Message':
      libObj = 'Message'; 
      break;
    case 'FreeFW_Text':
      libObj = 'Texte préféfini';
      break;
    case 'FreeSSO_User':
      libObj = 'Utilisateur';
      break;
    case 'FreeSSO_UserBroker':
      libObj = 'Accès utilisateur';
      break;  
    default:
      break;
  }
  if (id) {
    libObj += ' (' + id + ')';
  }
  return libObj;
}