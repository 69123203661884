export const MESSAGE_CLEAR_ITEMS = 'MESSAGE_CLEAR_ITEMS';
export const MESSAGE_CREATE_ONE_BEGIN = 'MESSAGE_CREATE_ONE_BEGIN';
export const MESSAGE_CREATE_ONE_SUCCESS = 'MESSAGE_CREATE_ONE_SUCCESS';
export const MESSAGE_CREATE_ONE_FAILURE = 'MESSAGE_CREATE_ONE_FAILURE';
export const MESSAGE_CREATE_ONE_DISMISS_ERROR = 'MESSAGE_CREATE_ONE_DISMISS_ERROR';
export const MESSAGE_DEL_ONE_BEGIN = 'MESSAGE_DEL_ONE_BEGIN';
export const MESSAGE_DEL_ONE_SUCCESS = 'MESSAGE_DEL_ONE_SUCCESS';
export const MESSAGE_DEL_ONE_FAILURE = 'MESSAGE_DEL_ONE_FAILURE';
export const MESSAGE_DEL_ONE_DISMISS_ERROR = 'MESSAGE_DEL_ONE_DISMISS_ERROR';
export const MESSAGE_EXPORT_AS_TAB_BEGIN = 'MESSAGE_EXPORT_AS_TAB_BEGIN';
export const MESSAGE_EXPORT_AS_TAB_SUCCESS = 'MESSAGE_EXPORT_AS_TAB_SUCCESS';
export const MESSAGE_EXPORT_AS_TAB_FAILURE = 'MESSAGE_EXPORT_AS_TAB_FAILURE';
export const MESSAGE_EXPORT_AS_TAB_DISMISS_ERROR = 'MESSAGE_EXPORT_AS_TAB_DISMISS_ERROR';
export const MESSAGE_EXPORT_BEGIN = 'MESSAGE_EXPORT_BEGIN';
export const MESSAGE_EXPORT_SUCCESS = 'MESSAGE_EXPORT_SUCCESS';
export const MESSAGE_EXPORT_FAILURE = 'MESSAGE_EXPORT_FAILURE';
export const MESSAGE_EXPORT_DISMISS_ERROR = 'MESSAGE_EXPORT_DISMISS_ERROR';
export const MESSAGE_INIT_FILTERS = 'MESSAGE_INIT_FILTERS';
export const MESSAGE_INIT_SORT = 'MESSAGE_INIT_SORT';
export const MESSAGE_LOAD_MORE_INIT = 'MESSAGE_LOAD_MORE_INIT';
export const MESSAGE_LOAD_MORE_BEGIN = 'MESSAGE_LOAD_MORE_BEGIN';
export const MESSAGE_LOAD_MORE_SUCCESS = 'MESSAGE_LOAD_MORE_SUCCESS';
export const MESSAGE_LOAD_MORE_FAILURE = 'MESSAGE_LOAD_MORE_FAILURE';
export const MESSAGE_LOAD_MORE_DISMISS_ERROR = 'MESSAGE_LOAD_MORE_DISMISS_ERROR';
export const MESSAGE_LOAD_ONE_BEGIN = 'MESSAGE_LOAD_ONE_BEGIN';
export const MESSAGE_LOAD_ONE_SUCCESS = 'MESSAGE_LOAD_ONE_SUCCESS';
export const MESSAGE_LOAD_ONE_FAILURE = 'MESSAGE_LOAD_ONE_FAILURE';
export const MESSAGE_LOAD_ONE_DISMISS_ERROR = 'MESSAGE_LOAD_ONE_DISMISS_ERROR';
export const MESSAGE_ON_SELECT = 'MESSAGE_ON_SELECT';
export const MESSAGE_PRINT_ONE_BEGIN = 'MESSAGE_PRINT_ONE_BEGIN';
export const MESSAGE_PRINT_ONE_SUCCESS = 'MESSAGE_PRINT_ONE_SUCCESS';
export const MESSAGE_PRINT_ONE_FAILURE = 'MESSAGE_PRINT_ONE_FAILURE';
export const MESSAGE_PRINT_ONE_DISMISS_ERROR = 'MESSAGE_PRINT_ONE_DISMISS_ERROR';
export const MESSAGE_PROPAGATE = 'MESSAGE_PROPAGATE';
export const MESSAGE_SELECT_NONE = 'MESSAGE_SELECT_NONE';
export const MESSAGE_SELECT_ALL = 'MESSAGE_SELECT_ALL';
export const MESSAGE_SET_CURRENT = 'MESSAGE_SET_CURRENT';
export const MESSAGE_SET_PREVIOUS = 'MESSAGE_SET_PREVIOUS';
export const MESSAGE_SET_NEXT_BEGIN = 'MESSAGE_SET_NEXT_BEGIN';
export const MESSAGE_SET_NEXT_SUCCESS = 'MESSAGE_SET_NEXT_SUCCESS';
export const MESSAGE_SET_NEXT_FAILURE = 'MESSAGE_SET_NEXT_FAILURE';
export const MESSAGE_SET_NEXT_DISMISS_ERROR = 'MESSAGE_SET_NEXT_DISMISS_ERROR';
export const MESSAGE_SET_FILTERS = 'MESSAGE_SET_FILTERS';
export const MESSAGE_SET_SORT = 'MESSAGE_SET_SORT';
export const MESSAGE_UPDATE_ONE_BEGIN = 'MESSAGE_UPDATE_ONE_BEGIN';
export const MESSAGE_UPDATE_ONE_SUCCESS = 'MESSAGE_UPDATE_ONE_SUCCESS';
export const MESSAGE_UPDATE_ONE_FAILURE = 'MESSAGE_UPDATE_ONE_FAILURE';
export const MESSAGE_UPDATE_ONE_DISMISS_ERROR = 'MESSAGE_UPDATE_ONE_DISMISS_ERROR';
export const MESSAGE_UPDATE_QUICK_SEARCH = 'MESSAGE_UPDATE_QUICK_SEARCH';
export const MESSAGE_UPDATE_SORT = 'MESSAGE_UPDATE_SORT';
