export const TAXONOMY_CLEAR_ITEMS = 'TAXONOMY_CLEAR_ITEMS';
export const TAXONOMY_CREATE_ONE_BEGIN = 'TAXONOMY_CREATE_ONE_BEGIN';
export const TAXONOMY_CREATE_ONE_SUCCESS = 'TAXONOMY_CREATE_ONE_SUCCESS';
export const TAXONOMY_CREATE_ONE_FAILURE = 'TAXONOMY_CREATE_ONE_FAILURE';
export const TAXONOMY_CREATE_ONE_DISMISS_ERROR = 'TAXONOMY_CREATE_ONE_DISMISS_ERROR';
export const TAXONOMY_DEL_ONE_BEGIN = 'TAXONOMY_DEL_ONE_BEGIN';
export const TAXONOMY_DEL_ONE_SUCCESS = 'TAXONOMY_DEL_ONE_SUCCESS';
export const TAXONOMY_DEL_ONE_FAILURE = 'TAXONOMY_DEL_ONE_FAILURE';
export const TAXONOMY_DEL_ONE_DISMISS_ERROR = 'TAXONOMY_DEL_ONE_DISMISS_ERROR';
export const TAXONOMY_EXPORT_AS_TAB_BEGIN = 'TAXONOMY_EXPORT_AS_TAB_BEGIN';
export const TAXONOMY_EXPORT_AS_TAB_SUCCESS = 'TAXONOMY_EXPORT_AS_TAB_SUCCESS';
export const TAXONOMY_EXPORT_AS_TAB_FAILURE = 'TAXONOMY_EXPORT_AS_TAB_FAILURE';
export const TAXONOMY_EXPORT_AS_TAB_DISMISS_ERROR = 'TAXONOMY_EXPORT_AS_TAB_DISMISS_ERROR';
export const TAXONOMY_EXPORT_BEGIN = 'TAXONOMY_EXPORT_BEGIN';
export const TAXONOMY_EXPORT_SUCCESS = 'TAXONOMY_EXPORT_SUCCESS';
export const TAXONOMY_EXPORT_FAILURE = 'TAXONOMY_EXPORT_FAILURE';
export const TAXONOMY_EXPORT_DISMISS_ERROR = 'TAXONOMY_EXPORT_DISMISS_ERROR';
export const TAXONOMY_INIT_FILTERS = 'TAXONOMY_INIT_FILTERS';
export const TAXONOMY_INIT_SORT = 'TAXONOMY_INIT_SORT';
export const TAXONOMY_LOAD_MORE_INIT = 'TAXONOMY_LOAD_MORE_INIT';
export const TAXONOMY_LOAD_MORE_BEGIN = 'TAXONOMY_LOAD_MORE_BEGIN';
export const TAXONOMY_LOAD_MORE_SUCCESS = 'TAXONOMY_LOAD_MORE_SUCCESS';
export const TAXONOMY_LOAD_MORE_FAILURE = 'TAXONOMY_LOAD_MORE_FAILURE';
export const TAXONOMY_LOAD_MORE_DISMISS_ERROR = 'TAXONOMY_LOAD_MORE_DISMISS_ERROR';
export const TAXONOMY_LOAD_ONE_BEGIN = 'TAXONOMY_LOAD_ONE_BEGIN';
export const TAXONOMY_LOAD_ONE_SUCCESS = 'TAXONOMY_LOAD_ONE_SUCCESS';
export const TAXONOMY_LOAD_ONE_FAILURE = 'TAXONOMY_LOAD_ONE_FAILURE';
export const TAXONOMY_LOAD_ONE_DISMISS_ERROR = 'TAXONOMY_LOAD_ONE_DISMISS_ERROR';
export const TAXONOMY_ON_SELECT = 'TAXONOMY_ON_SELECT';
export const TAXONOMY_PRINT_ONE_BEGIN = 'TAXONOMY_PRINT_ONE_BEGIN';
export const TAXONOMY_PRINT_ONE_SUCCESS = 'TAXONOMY_PRINT_ONE_SUCCESS';
export const TAXONOMY_PRINT_ONE_FAILURE = 'TAXONOMY_PRINT_ONE_FAILURE';
export const TAXONOMY_PRINT_ONE_DISMISS_ERROR = 'TAXONOMY_PRINT_ONE_DISMISS_ERROR';
export const TAXONOMY_PROPAGATE = 'TAXONOMY_PROPAGATE';
export const TAXONOMY_SELECT_NONE = 'TAXONOMY_SELECT_NONE';
export const TAXONOMY_SELECT_ALL = 'TAXONOMY_SELECT_ALL';
export const TAXONOMY_SET_CURRENT = 'TAXONOMY_SET_CURRENT';
export const TAXONOMY_SET_PREVIOUS = 'TAXONOMY_SET_PREVIOUS';
export const TAXONOMY_SET_NEXT_BEGIN = 'TAXONOMY_SET_NEXT_BEGIN';
export const TAXONOMY_SET_NEXT_SUCCESS = 'TAXONOMY_SET_NEXT_SUCCESS';
export const TAXONOMY_SET_NEXT_FAILURE = 'TAXONOMY_SET_NEXT_FAILURE';
export const TAXONOMY_SET_NEXT_DISMISS_ERROR = 'TAXONOMY_SET_NEXT_DISMISS_ERROR';
export const TAXONOMY_SET_FILTERS = 'TAXONOMY_SET_FILTERS';
export const TAXONOMY_SET_SORT = 'TAXONOMY_SET_SORT';
export const TAXONOMY_UPDATE_ONE_BEGIN = 'TAXONOMY_UPDATE_ONE_BEGIN';
export const TAXONOMY_UPDATE_ONE_SUCCESS = 'TAXONOMY_UPDATE_ONE_SUCCESS';
export const TAXONOMY_UPDATE_ONE_FAILURE = 'TAXONOMY_UPDATE_ONE_FAILURE';
export const TAXONOMY_UPDATE_ONE_DISMISS_ERROR = 'TAXONOMY_UPDATE_ONE_DISMISS_ERROR';
export const TAXONOMY_UPDATE_QUICK_SEARCH = 'TAXONOMY_UPDATE_QUICK_SEARCH';
export const TAXONOMY_UPDATE_SORT = 'TAXONOMY_UPDATE_SORT';
