import { Filter } from 'react-bootstrap-front';
import { getNewNormalizedObject } from 'jsonapi-front';

const initialState = {
  items: getNewNormalizedObject('FreeFW_Email'),
  emptyVersion: null,
  version: null,
  models: [],
  page_number: 1,
  page_size: 999,
  filters: new Filter(),
  sort: [{ col: 'email_subject', way: 'up' }],
  include: 'lang,versions,versions.lang',
  loadMorePending: false,
  loadMoreFinish: false,
  loadMoreError: null,
  loadOnePending: false,
  loadOneError: null,
  updateOnePending: false,
  updateOneError: null,
  createOnePending: false,
  createOneError: null,
  delOnePending: false,
  delOneError: null,
  loadOneVersionPending: false,
  loadOneVersionError: null,
};

export default initialState;
