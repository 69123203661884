import { normalizedObjectModeler } from 'jsonapi-front';

/**
 * Code des emails
 * 
 * @return {Array}
 */
export const emailCodes = [
  {label: 'Autre', value: 'OTHER'},
  {label: 'Mot de passe oublié', value: 'ASK_PASSWORD'},
  {label: 'Nouveau membre', value: 'NEW_CLIENT'},
  {label: 'Nouveau paiement ponctuel', value: 'NEW_DONATION'},
  {label: 'Nouveau paiement régulier', value: 'NEW_SPONSORSHIP'},
  {label: 'Fin bénéficaire', value: 'END_CAUSE'},
  {label: 'Fin parrainage', value: 'END_DONATION'},
  {label: 'Fin parrainage 1 mois', value: 'END_DONATION_1M'},
  {label: 'Fin parrainage 1 jour', value: 'END_DONATION_1D'},
];

/**
 * Get object names
 *
 * @return {Array}
 */
export const getEmailObjectNames = intl => {
  return [
    { value: 'FreeAsso_Certificate', label: 'Certificat' },
    { value: 'FreeAsso_Donation', label: 'Paiement' },
    { value: 'FreeAsso_Sponsorship', label: 'Paiement régulier' },
    { value: 'FreeAsso_Client', label: 'Donateur' },
    { value: 'FreeAsso_Cause', label: 'Bénéficiaire' },
    { value: 'FreeAsso_Receipt', label: 'Reçu' },
    { value: 'FreeFW_User', label: 'Utilisateur' },
  ];
};

/**
 * Export all emails as an array of value=>label
 * 
 * @param {object} object
 * 
 * @return {array}
 */
 export function emailAsOptions(object, restrict = []) {
  let arr = [];
  arr.push({value: '', label: ''});
  if (object) {
    let items = normalizedObjectModeler(
      object,
      'FreeFW_Email',
    );
    if (items) {
      items.forEach((item) => {
        if (restrict.length > 0) {
          if (item.email_object_name && restrict.includes(item.email_object_name)) {
            arr.push({value: item.id, label: item.email_subject});
          }
        } else {
          arr.push({value: item.id, label: item.email_subject});
        }
      });
      arr.sort(function (a, b) {
        if (a.label > b.label) {
          return 1;
        } else {
          if (a.label < b.label) {
            return -1;
          } 
        }
        return 0;
      });
    }
  }
  return arr;
}