import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  InputPicker as DefaultInputPicker,
  ButtonPicker as DefaultButtonPicker,
} from 'react-bootstrap-front';
import { Search, Input } from './';
import axios from 'axios';
import { freeAssoApi } from '../../common';
import { More, DelOne, Zoom } from '../icons';

export default class InputPicker extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    item: PropTypes.object,
    onChange: PropTypes.func.isRequired,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.item !== state.item) {
      let value = null;
      let display = '';
      let zoomBtn = false;
      if (props.item) {
        value = props.item.id || '';
        display = props.item.site_name;
        zoomBtn = props.item.id > 0 ? true : false;
      }
      return { item: props.item, value: value, display: display, zoomBtn };
    }
    return null;
  }

  constructor(props) {
    super(props);
    let value = '';
    let display = '';
    if (this.props.item) {
      value = this.props.item.id || '';
      display = this.props.item.site_name || '';
    }
    this.state = {
      search: false,
      item: this.props.item || null,
      list: [],
      value: value,
      display: display,
      autocomplete: false,
      source: false,
      zoom: false,
      zoomBtn: props.item && props.item.id > 0 ? true : false,
    };
    this.onMore = this.onMore.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onZoom = this.onZoom.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onCloseMore = this.onCloseMore.bind(this);
  }

  onChange(event) {
    if (this.state.source) {
      this.state.source.cancel();
    }
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      cancelToken: source.token,
    };
    const search = '' + event.target.value;
    this.setState({ display: search, loading: true, cancel: source });
    if (search.length >= 2) {
      freeAssoApi
        .get(process.env.REACT_APP_BO_URL + '/v1/asso/site/autocomplete/' + event.target.value, {
          headers: headers,
        })
        .then(result => {
          if (result && result.data && result.data.data) {
            const list = result.data.data;
            if (Array.isArray(list) && list.length === 1) {
              this.props.onChange({
                target: { name: this.props.name, value: list[0].site_id, type: 'FreeAsso_Site' },
              });
            } else {
              this.setState({ list: list, loading: false });
            }
          } else {
            this.setState({ list: [], loading: false });
          }
        })
        .catch(err => {
          this.setState({ list: [], loading: false });
        });
    }
  }

  onMore() {
    this.setState({ search: true, autocomplete: false });
  }

  onZoom() {
    this.setState({ zoom: true });
  }

  onClear() {
    this.setState({ autocomplete: false });
    this.props.onChange({
      target: { name: this.props.name, value: null, type: 'FreeAsso_Site' },
    });
  }

  onSelect(item) {
    this.setState({ search: false, autocomplete: false, list: [] });
    if (item) {
      this.props.onChange({
        target: { name: this.props.name, value: item.id, type: 'FreeAsso_Site' },
      });
    }
  }

  onCloseMore() {
    this.setState({ search: false, zoom: false });
  }

  render() {
    return (
      <div className="site-input-picker">
        {!this.state.zoomBtn ? (
          <>
            <DefaultInputPicker
              name={this.props.name}
              label={this.props.label}
              value={this.state.value}
              labelTop={this.props.labelTop || false}
              list={this.state.list}
              display={this.state.display}
              onChange={this.onChange}
              onClear={this.onClear}
              onMore={this.onMore}
              onZoom={this.onZoom}
              onSelect={this.onSelect}
              error={this.props.error}
              pickerId="site_id"
              pickerDisplay="site_name,site_code"
              required={this.props.required || false}
              size={this.props.size || 'md'}
              labelSize={this.props.labelSize || 6}
              inputSize={this.props.inputSize || 30}
              clearIcon={<DelOne className="text-warning" size={0.9} />}
              moreIcon={<More className="text-secondary" size={0.9} />}
              zoomIcon={<Zoom className="text-secondary" size={0.9} />}
            />
            <Search
              title={this.props.label}
              show={this.state.search}
              onClose={this.onCloseMore}
              onSelect={this.onSelect}
            />
          </>
        ) : (
          <>
            <DefaultButtonPicker
              label={this.props.label}
              value={this.state.value}
              display={this.state.display}
              disabled={this.props.disabled}
              required={this.props.required || false}
              clearIcon={<DelOne className="text-warning" size={0.8} />}
              onZoom={this.onZoom}
              onClear={this.onClear}
            />
          </>
        )}
        {this.state.zoom && (
          <Input
            loader={false}
            modal={true}
            siteId={this.state.item.id}
            onClose={this.onCloseMore}
          />
        )}
      </div>
    );
  }
}
