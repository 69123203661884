import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { ResponsiveInlineList } from '../ui';

export class InlineNamesake extends Component {
  static propTypes = {
    cols: PropTypes.array.isRequired,
    items: PropTypes.array,
    onClose: PropTypes.func,
  };
  static defaultProps = {
    items: [],
    onClose: () => {},
  };

  render() {
    if (this.props.items && Array.isArray(this.props.items) && this.props.items.length > 0) {
      return (
        <div className="form-right-panel">
          <div className="form-right-panel-header">
            <span className="form-right-panel-header-title">
              {this.props.intl.formatMessage({
                id: 'app.features.client.form.namesake',
                defaultMessage: 'Namesake ?',
              })}
            </span>
            <button
              type="button"
              className="btn btn-secondary-light form-right-panel-header-close"
              onClick={ev => {
                if (ev) {
                  ev.stopPropagation();
                }
                this.props.onClose(ev);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="form-right-panel-content custom-scrollbar">
            <ResponsiveInlineList
              {...this.props}
              cols={this.props.cols}
              items={this.props.items}
              total={this.props.items.length}
              loading={false}
            />
          </div>
        </div>
      );
    }
    return null;
  }
}

export default injectIntl(InlineNamesake);
