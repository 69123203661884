import React from 'react';
import { SocialIcon } from 'react-social-icons';
import { FormattedMessage } from 'react-intl';
import {
  Login as LoginIcon,
  Logout as LogoutIcon,
  Cause as CauseIcon,
  Person as PersonIcon,
  Donation as DonationIcon,
  System as SystemIcon,
  Dashboard as DashboardIcon,
  Certificate as CertificateIcon,
  Receipt as ReceiptIcon,
  Admin as AdminIcon,
  Changelog as ChangelogIcon,
  List as ListIcon,
  Search as SearchIcon,
  BookCog as BookCogIcon,
  InboxFull as InboxFullIcon,
} from '../icons';

const styleSocial = {
  height: '45px',
  width: '45px',
  marginLeft: '5px',
};

export const appMenu = types => {
  let causes = [];
  if (types) {
    types.forEach(elem => {
      causes.push({
        label: elem.label,
        url: '/cause/' + elem.value + '/',
        role: 'NAV',
        public: false,
      });
    });
  }
  return [
    {
      icon: <DashboardIcon />,
      label: <FormattedMessage id="app.features.home.app.menu.home" defaultMessage="Home" />,
      help: (
        <FormattedMessage
          id="app.features.home.app.menu.home.help"
          defaultMessage="Get back home"
        />
      ),
      url: '/',
      role: 'HOME',
      public: true,
    },
    {
      icon: <LoginIcon />,
      label: <FormattedMessage id="app.features.home.app.menu.signin" defaultMessage="Sign in" />,
      url: '/auth/signin',
      role: 'SIGNIN',
      public: true,
    },
    {
      icon: <LogoutIcon />,
      label: <FormattedMessage id="app.features.home.app.menu.signout" defaultMessage="Sign out" />,
      url: '/auth/signout',
      role: '-SIGNOUT-',
      public: false,
    },
    {
      icon: <SocialIcon url="https://facebook.com/KalaweitFrance/" style={styleSocial} />,
      label: 'Facebook',
      url: null,
      role: 'SOCIAL',
      position: 1,
      public: true,
    },
    {
      icon: <SocialIcon url="https://twitter.com/kalaweit" style={styleSocial} />,
      label: 'Twitter',
      url: null,
      role: 'SOCIAL',
      position: 2,
      public: true,
    },
    {
      icon: <SocialIcon url="https://www.instagram.com/chaneekalaweit/" style={styleSocial} />,
      label: 'Instagram',
      url: null,
      role: 'SOCIAL',
      position: 3,
      public: true,
    },
    {
      icon: <PersonIcon />,
      label: <FormattedMessage id="app.features.home.app.menu.person" defaultMessage="Members" />,
      role: 'MENU',
      position: 2,
      public: false,
      scope: 'FreeAsso/Asso/Client',
      help: (
        <FormattedMessage id="app.features.home.app.menu.person.help" defaultMessage="Members" />
      ),
      options: [
        {
          icon: <ListIcon />,
          label: (
            <FormattedMessage id="app.features.home.app.menu.person.list" defaultMessage="Liste" />
          ),
          url: '/client',
          role: 'NAV',
          position: 1,
          public: false,
          scope: 'FreeAsso/Asso/Client',
          help: (
            <FormattedMessage
              id="app.features.home.app.menu.person.list.help"
              defaultMessage="Members"
            />
          ),
        },
        {
          icon: <SearchIcon />,
          label: (
            <FormattedMessage
              id="app.features.home.app.menu.person.search"
              defaultMessage="Recherche"
            />
          ),
          url: '/client/search',
          role: 'NAV',
          position: 2,
          public: false,
          scope: 'FreeAsso/Asso/Client',
          help: (
            <FormattedMessage
              id="app.features.home.app.menu.person.search.help"
              defaultMessage="Members"
            />
          ),
        },
      ],
    },
    {
      icon: <CauseIcon />,
      label: <FormattedMessage id="app.features.home.app.menu.cause" defaultMessage="Missions" />,
      url: null,
      role: 'MENU',
      position: 3,
      options: causes,
      public: false,
      scope: 'FreeAsso/Asso/Cause',
      help: <FormattedMessage id="app.features.home.app.menu.cause.help" defaultMessage="Causes" />,
    },
    {
      icon: <DonationIcon />,
      label: (
        <FormattedMessage id="app.features.home.app.menu.donation" defaultMessage="Donations" />
      ),
      url: '/donation',
      role: 'MENU',
      position: 4,
      public: false,
      scope: 'FreeAsso/Asso/Donation',
      help: (
        <FormattedMessage
          id="app.features.home.app.menu.donation.help"
          defaultMessage="Donations"
        />
      ),
      options: [
        {
          icon: <ListIcon />,
          label: (
            <FormattedMessage
              id="app.features.home.app.menu.donation.list"
              defaultMessage="Donations"
            />
          ),
          url: '/donation',
          role: 'NAV',
          position: 1,
          public: false,
          scope: 'FreeAsso/Asso/Donation',
          help: (
            <FormattedMessage
              id="app.features.home.app.menu.donation.list.help"
              defaultMessage="Donations"
            />
          ),
        },
        {
          icon: <SearchIcon />,
          label: (
            <FormattedMessage
              id="app.features.home.app.menu.donation.search"
              defaultMessage="Donations"
            />
          ),
          url: '/donation/search',
          role: 'NAV',
          position: 2,
          public: false,
          scope: 'FreeAsso/Asso/Donation',
          help: (
            <FormattedMessage
              id="app.features.home.app.menu.donation.search.help"
              defaultMessage="Donations"
            />
          ),
        },
      ],
    },
    {
      icon: <CertificateIcon />,
      label: (
        <FormattedMessage
          id="app.features.home.app.menu.certificate"
          defaultMessage="Certificates"
        />
      ),
      role: 'MENU',
      position: 5,
      public: false,
      scope: 'FreeAsso/Asso/Certificate',
      help: (
        <FormattedMessage
          id="app.features.home.app.menu.certificate.help"
          defaultMessage="Donations"
        />
      ),
      options: [
        {
          icon: <ListIcon />,
          label: (
            <FormattedMessage
              id="app.features.home.app.menu.certificate.list"
              defaultMessage="Certificats"
            />
          ),
          url: '/certificate',
          role: 'NAV',
          position: 1,
          public: false,
          scope: 'FreeAsso/Asso/Certificate',
          help: (
            <FormattedMessage
              id="app.features.home.app.menu.certificate.list.help"
              defaultMessage="Certificats"
            />
          ),
        },
        {
          icon: <BookCogIcon />,
          label: (
            <FormattedMessage
              id="app.features.home.app.menu.certificate-generation.list"
              defaultMessage="Génération"
            />
          ),
          url: '/certificate-generation',
          role: 'NAV',
          position: 2,
          public: false,
          scope: 'FreeAsso/Asso/Certificate',
          help: (
            <FormattedMessage
              id="app.features.home.app.menu.certificate-generation.list.help"
              defaultMessage="Génération"
            />
          ),
        },
      ],
    },
    {
      icon: <ReceiptIcon />,
      label: <FormattedMessage id="app.features.home.app.menu.receipt" defaultMessage="Receipts" />,
      role: 'MENU',
      position: 6,
      public: false,
      scope: 'FreeAsso/Asso/Receipt',
      help: (
        <FormattedMessage id="app.features.home.app.menu.receipt.help" defaultMessage="Donations" />
      ),
      options: [
        {
          icon: <ListIcon />,
          label: (
            <FormattedMessage id="app.features.home.app.menu.receipt.list" defaultMessage="Reçus" />
          ),
          url: '/receipt',
          role: 'NAV',
          position: 1,
          public: false,
          scope: 'FreeAsso/Asso/Receipt',
          help: (
            <FormattedMessage
              id="app.features.home.app.menu.receipt.list.help"
              defaultMessage="Reçus"
            />
          ),
        },
        {
          icon: <BookCogIcon />,
          label: (
            <FormattedMessage
              id="app.features.home.app.menu.receipt-generation.list"
              defaultMessage="Reçus"
            />
          ),
          url: '/receipt-generation',
          role: 'NAV',
          position: 2,
          public: false,
          scope: 'FreeAsso/Asso/Receipt',
          help: (
            <FormattedMessage
              id="app.features.home.app.menu.receipt-generation.list.help"
              defaultMessage="Reçus"
            />
          ),
        },
        {
          icon: <SearchIcon />,
          label: (
            <FormattedMessage
              id="app.features.home.app.menu.receipt.search"
              defaultMessage="Reçus"
            />
          ),
          url: '/receipt/search',
          role: 'NAV',
          position: 3,
          public: false,
          scope: 'FreeAsso/Asso/Receipt',
          help: (
            <FormattedMessage
              id="app.features.home.app.menu.receipt.search.help"
              defaultMessage="Reçus"
            />
          ),
        },
      ],
    },
    {
      icon: <SystemIcon />,
      label: (
        <FormattedMessage id="app.features.home.app.menu.settings" defaultMessage="Settings" />
      ),
      url: null,
      role: 'MENU',
      position: 10,
      public: false,
      scope: 'FreeAsso/Config',
      help: (
        <FormattedMessage
          id="app.features.home.app.menu.settings.help"
          defaultMessage="Donations"
        />
      ),
      options: [
        {
          icon: null,
          label: (
            <FormattedMessage
              id="app.features.home.app.menu.paymentType"
              defaultMessage="Payment types"
            />
          ),
          url: '/payment-type',
          role: 'NAV',
          scope: 'FreeAsso/Config/PaymentType',
          position: 1,
        },
        {
          icon: null,
          label: (
            <FormattedMessage id="app.features.home.app.menu.session" defaultMessage="Sessions" />
          ),
          url: '/session',
          role: 'NAV',
          scope: 'FreeAsso/Config/Session',
          position: 2,
        },
        {
          icon: null,
          label: (
            <FormattedMessage
              id="app.features.home.app.menu.clientCategories"
              defaultMessage="Person categories"
            />
          ),
          url: '/client-category',
          role: 'NAV',
          scope: 'FreeAsso/Config/ClientCategory',
          position: 3,
        },
        {
          icon: null,
          label: (
            <FormattedMessage
              id="app.features.home.app.menu.clientType"
              defaultMessage="Person types"
            />
          ),
          url: '/client-type',
          role: 'NAV',
          scope: 'FreeAsso/Config/ClientType',
          position: 4,
        },
        {
          icon: null,
          label: (
            <FormattedMessage
              id="app.features.home.app.menu.subspecies"
              defaultMessage="Subspecies"
            />
          ),
          url: '/subspecies',
          role: 'NAV',
          scope: 'FreeAsso/Config/Subspecies',
          position: 5,
        },
        {
          icon: null,
          label: (
            <FormattedMessage id="app.features.home.app.menu.species" defaultMessage="Species" />
          ),
          url: '/species',
          role: 'NAV',
          scope: 'FreeAsso/Config/Species',
          position: 6,
        },
        {
          icon: null,
          label: (
            <FormattedMessage id="app.features.home.app.menu.edition" defaultMessage="Editions" />
          ),
          url: '/edition',
          role: 'NAV',
          scope: 'FreeFw/Config/Edition',
          position: 7,
        },
        {
          icon: null,
          label: (
            <FormattedMessage
              id="app.features.home.app.menu.emails"
              defaultMessage="Notifications"
            />
          ),
          url: '/email',
          role: 'NAV',
          scope: 'FreeFw/Config/Email',
          position: 8,
        },
        {
          icon: null,
          label: (
            <FormattedMessage id="app.features.home.app.menu.causeType" defaultMessage="Missions" />
          ),
          url: '/cause-type',
          role: 'NAV',
          scope: 'FreeAsso/Config/CauseType',
          position: 9,
        },
        {
          icon: null,
          label: (
            <FormattedMessage
              id="app.features.home.app.menu.causeMainType"
              defaultMessage="Programs"
            />
          ),
          url: '/cause-main-type',
          role: 'NAV',
          scope: 'FreeAsso/Config/CauseMainType',
          position: 10,
        },
        {
          icon: null,
          label: (
            <FormattedMessage
              id="app.features.home.app.menu.locations"
              defaultMessage="Locations"
            />
          ),
          url: '/site',
          role: 'NAV',
          scope: 'FreeAsso/Config/Site',
          position: 11,
        },
        {
          icon: null,
          label: (
            <FormattedMessage
              id="app.features.home.app.menu.receiptType"
              defaultMessage="Locations types"
            />
          ),
          url: '/receipt-type',
          scope: 'FreeAsso/Config/ReceiptType',
          role: 'NAV',
          position: 12,
        },
        {
          icon: null,
          label: <FormattedMessage id="app.features.home.app.menu.datas" defaultMessage="Datas" />,
          url: '/data',
          role: 'NAV',
          scope: 'FreeAsso/Config/Data',
          position: 13,
        },
        {
          icon: null,
          label: (
            <FormattedMessage
              id="app.features.home.app.menu.causeMainType"
              defaultMessage="Programs"
            />
          ),
          url: '/cause-main-type',
          role: 'NAV',
          scope: 'FreeAsso/Config/CauseMainType',
          position: 14,
        },
        {
          icon: null,
          label: (
            <FormattedMessage
              id="app.features.home.app.menu.translation"
              defaultMessage="Traductions"
            />
          ),
          url: '/translation',
          role: 'NAV',
          scope: 'FreeFW/Core',
          position: 15,
        },
      ],
    },
    {
      icon: <AdminIcon />,
      label: <FormattedMessage id="app.features.home.app.menu.admin" defaultMessage="Contrôle" />,
      url: null,
      role: 'MENU',
      position: 100,
      public: false,
      scope: 'FreeAsso/Config',
      help: (
        <FormattedMessage id="app.features.home.app.menu.admin.help" defaultMessage="Donations" />
      ),
      options: [
        {
          icon: null,
          label: (
            <FormattedMessage
              id="app.features.home.app.menu.accounting"
              defaultMessage="Accounting"
            />
          ),
          url: '/accounting-line',
          role: 'NAV',
          scope: 'FreeFW/Core',
          position: 1,
        },
        {
          icon: null,
          label: (
            <FormattedMessage
              id="app.features.home.app.menu.notifications"
              defaultMessage="Messages"
            />
          ),
          url: '/notification',
          role: 'NAV',
          scope: 'FreeFW/Core',
          position: 2,
        },
        {
          icon: null,
          label: (
            <FormattedMessage id="app.features.home.app.menu.history" defaultMessage="Historique" />
          ),
          url: '/history',
          role: 'NAV',
          scope: 'FreeFW/Core',
          position: 3,
        },
        {
          icon: null,
          label: (
            <FormattedMessage
              id="app.features.home.app.menu.donationOrigin"
              defaultMessage="Générations"
            />
          ),
          url: '/donation-origin',
          role: 'NAV',
          scope: 'FreeFW/Core',
          position: 4,
        },
        {
          icon: null,
          label: (
            <FormattedMessage id="app.features.home.app.menu.message" defaultMessage="Messages" />
          ),
          url: '/message',
          role: 'NAV',
          scope: 'FreeFW/Core',
          position: 5,
        },
        {
          icon: null,
          label: (
            <FormattedMessage
              id="app.features.home.app.menu.jobqueue"
              defaultMessage="Tâches planifiées"
            />
          ),
          url: '/jobqueue',
          role: 'NAV',
          scope: 'FreeFW/Core',
          position: 6,
        }
      ],
    },
    {
      icon: <InboxFullIcon />,
      label: (
        <FormattedMessage id="app.features.home.app.menu.inbox" defaultMessage="Files" />
      ),
      url: '/inbox',
      role: 'NAV',
      position: 180,
      public: false,
      scope: 'FreeFW',
      help: (
        <FormattedMessage id="app.features.home.app.menu.inbox.help" defaultMessage="Files" />
      ),
    },
    {
      icon: <ChangelogIcon />,
      label: (
        <FormattedMessage id="app.features.home.app.menu.version" defaultMessage="Fiche version" />
      ),
      url: '/version',
      role: 'NAV',
      position: 200,
      public: false,
      scope: 'FreeAsso',
      help: (
        <FormattedMessage id="app.features.home.app.menu.version.help" defaultMessage="Donations" />
      ),
    },
  ];
};
