import React from 'react';
import { injectIntl } from 'react-intl';
import { InputHidden, InputText, InputCheckbox } from 'react-bootstrap-front';
import { useForm, ResponsiveModalOrForm, InputDate, InputSelect, Row, Col } from '../ui';
import { getPaymentTypeRestrictions, getPaymentTypeTypes } from './';

function Form(props) {
  const { values, handleChange, handleSubmit, handleCancel, getErrorMessage } = useForm(
    props.item,
    '',
    props.onSubmit,
    props.onCancel,
    '',
    props.errors,
    props.intl,
  );
  const { intl } = props;
  return (
    <ResponsiveModalOrForm
      size="sm"
      modal={true}
      title={intl.formatMessage({
        id: 'app.features.paymentType.form.title',
        defaultMessage: 'Payment type',
      })}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onClose={props.onClose}
    >
      <InputHidden name="id" id="id" value={values.id} />
      <Row>
        <Col size={{ xxs: 36, sm: 14 }}>
          <InputText
            label={intl.formatMessage({
              id: 'app.features.paymentType.form.name',
              defaultMessage: 'Name',
            })}
            name="ptyp_name"
            required={true}
            value={values.ptyp_name}
            onChange={handleChange}
            error={getErrorMessage('ptyp_name')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 14 }}>
          <InputText
            label={intl.formatMessage({
              id: 'app.features.paymentType.form.name_en',
              defaultMessage: 'Name',
            })}
            name="ptyp_name_en"
            required={true}
            value={values.ptyp_name_en}
            onChange={handleChange}
            error={getErrorMessage('ptyp_name_en')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 8 }}>
          <InputCheckbox
            label={intl.formatMessage({
              id: 'app.features.paymentType.form.receipt',
              defaultMessage: 'Send receipt',
            })}
            name="ptyp_receipt"
            required={true}
            checked={values.ptyp_receipt}
            onChange={handleChange}
            error={getErrorMessage('ptyp_receipt')}
          />
        </Col>
      </Row>
      <Row>
        <div className="col-xs-w18">
          <InputDate
            label={intl.formatMessage({
              id: 'app.features.paymentType.form.from',
              defaultMessage: 'From',
            })}
            name="ptyp_from"
            required={true}
            value={values.ptyp_from}
            onChange={handleChange}
            error={getErrorMessage('ptyp_from')}
          />
        </div>
        <div className="col-xs-w18">
          <InputDate
            label={intl.formatMessage({
              id: 'app.features.paymentType.form.to',
              defaultMessage: 'To',
            })}
            name="ptyp_to"
            required={true}
            value={values.ptyp_to}
            onChange={handleChange}
            error={getErrorMessage('ptyp_to')}
          />
        </div>
      </Row>
      <Row>
        <div className="col-xs-w36">
          <InputText
            label={intl.formatMessage({
              id: 'app.features.paymentType.form.accounting',
              defaultMessage: 'Name',
            })}
            name="ptyp_accounting"
            required={true}
            value={values.ptyp_accounting}
            onChange={handleChange}
            error={getErrorMessage('ptyp_accounting')}
          />
        </div>
        <div className="col-xs-w36">
          <InputSelect
            label={intl.formatMessage({
              id: 'app.features.paymentType.form.restriction',
              defaultMessage: 'Name',
            })}
            name="ptyp_restriction"
            required={true}
            value={values.ptyp_restriction}
            onChange={handleChange}
            options={getPaymentTypeRestrictions()}
            error={getErrorMessage('ptyp_restriction')}
          />
        </div>
        <div className="col-xs-w36">
          <InputSelect
            label={intl.formatMessage({
              id: 'app.features.paymentType.form.type',
              defaultMessage: 'Type',
            })}
            name="ptyp_type"
            required={true}
            value={values.ptyp_type}
            onChange={handleChange}
            options={getPaymentTypeTypes()}
            error={getErrorMessage('ptyp_type')}
          />
        </div>
      </Row>
    </ResponsiveModalOrForm>
  );
}

export default injectIntl(Form);
