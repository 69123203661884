import React from 'react';
import {
  AddOne as AddOneIcon,
  BookMinus as BookMinusIcon,
  BookPlay as BookPlayIcon,
  GetOne as GetOneIcon,
  DelOne as DelOneIcon,
  Print as PrintIcon,
  Email as EmailIcon,
  System as SystemIcon,
} from '../icons';

export const getStatus = intl => {
  let statusValues = [
    {
      value: 'ERROR',
      label: intl.formatMessage({
        id: 'app.features.receipt-generation.status.error',
        defaultMessage: 'Error',
      }),
    },
    {
      value: 'DONE',
      label: intl.formatMessage({
        id: 'app.features.receipt-generation.status.done',
        defaultMessage: 'Done',
      }),
    },
    {
      value: 'WAITING',
      label: intl.formatMessage({
        id: 'app.features.receipt-generation.status.waiting',
        defaultMessage: 'Waiting',
      }),
    },
    {
      value: 'PENDING',
      label: intl.formatMessage({
        id: 'app.features.receipt-generation.status.pending',
        defaultMessage: 'Pending',
      }),
    },
    {
      value: 'NONE',
      label: intl.formatMessage({
        id: 'app.features.receipt-generation.status.none',
        defaultMessage: 'None',
      }),
    },
  ];
  return statusValues;
};

/**
 * Shortcuts
 *
 * @param {Object} intl
 * @param {String} mode
 */
export const getShortcuts = (intl, mode = 'all') => {
  let shortcuts = [];
  shortcuts.push({
    name: 'ident',
    icon: '',
    size: 'maximized',
    display: 'block',
    position: 1,
    label: intl.formatMessage({
      id: 'app.features.receipt-generation.form.tabs.ident',
      defaultMessage: 'Identification',
    }),
  });
  if (mode === 'all') {
  }
  return shortcuts;
};

/**
 * Gestion de l'affichage du champ en fonction du modèle
 */
export function getPickerDisplay(item) {
  let display = '';
  if (item && item.id > 0) {
    display = item.recg_name;
  }
  return display;
}

/**
 * Affichage des agents dans la liste des propositions de la recherhe
 * ou dans la liste proposées sous le champ en autocomplète
 */
export const displayItemPicker = item => {
  return (
    <p className="input-picker-line">
      <span className="input-picker-left">{item.recg_name}</span>
    </p>
  );
};

/**
 * Actions disponibles depuis l'en-tête de la liste des agents
 */
export const getGlobalActions = ({ props, onClearFilters, onCreate }) => {
  return [
    {
      name: 'create',
      label: props.intl.formatMessage({
        id: 'app.list.button.add',
        defaultMessage: 'Add',
      }),
      onClick: onCreate,
      theme: 'primary',
      icon: <AddOneIcon />,
      role: 'CREATE',
    },
  ];
};

/**
 * Actions disponibles sur la fiche (en plus de l'enregistrement et de l'annulation)
 */
export const getActionsButtons = ({ onPrint, state }) => {
  const { editions } = state;
  let myOptions = [];
  let printabled = false;
  if (editions && Array.isArray(editions) && editions.length > 0) {
    printabled = true;
    editions.forEach(edition => {
      myOptions.push({
        id: edition.id,
        label: edition.edi_name,
        onClick: () => onPrint(edition.id),
      });
    });
  }
  return [
    {
      theme: 'secondary',
      hidden: !printabled,
      options: myOptions,
      optionsAlign: 'top-left',
      function: () => onPrint(),
      icon: <PrintIcon title="Imprimer" />,
    },
  ];
};

/**
 * Actions disponibles depuis l'en-tête de la liste des agents pour agir sur les lignes de la liste
 *    Sélection lignes
 *    Dé-sélection
 *    ...
 *    Actions sur lignes sélectionnées
 *    ou sur toutes les lignes
 */
export const getSelectActions = ({ props, onSelectMenu }) => {
  let arrOne = [
    {
      name: 'selectAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.selectAll',
        defaultMessage: 'Select all',
      }),
      onClick: () => {
        onSelectMenu('selectAll');
      },
    },
  ];
  const arrAppend = [
    {
      name: 'selectNone',
      label: props.intl.formatMessage({
        id: 'app.list.options.deselectAll',
        defaultMessage: 'Deselect all',
      }),
      onClick: () => {
        onSelectMenu('selectNone');
      },
    },
    { name: 'divider' },
    {
      name: 'exportSelect',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportSelect',
        defaultMessage: 'Export selection',
      }),
      onClick: () => {
        onSelectMenu('exportSelection');
      },
    },
  ];
  if (props.receiptGeneration.selected.length > 0) {
    arrOne = arrOne.concat(arrAppend);
  }
  const arrStandard = [
    { name: 'divider' },
    {
      name: 'exportAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportAll',
        defaultMessage: 'Export all',
      }),
      onClick: () => {
        onSelectMenu('exportAll');
      },
    },
  ];
  arrOne = arrOne.concat(arrStandard);
  return arrOne;
};

/**
 * Actions disponibles sur une ligne de la liste des agents au survol de cette ligne
 */
export const getInlineActions = ({
  props,
  onSelectList,
  onGetOne,
  onDelOne,
  onPrint,
  onAction,
  state,
}) => {
  let myEditions = [];
  if (state && state.editions) {
    const { editions } = state;
    editions.forEach(edition => {
      myEditions.push({ label: edition.edi_name, onClick: item => onPrint(edition.id, item) });
    });
  }
  const myExports = [
    {label: 'Télécharger les reçus sans email, sans page blanche (PDF)', onClick: item => onAction(item, 'pdfYearNoEmail')},
    {label: 'Télécharger les reçus sans email, pour recto / verso (PDF)', onClick: item => onAction(item, 'pdfYearNoEmailPeer')},
    {label: 'Télécharger les reçus triés par ordre alphabétique (PDF)', onClick: item => onAction(item, 'pdfYearAllMember')},
    {label: 'Télécharger les reçus triés par numéro (PDF)', onClick: item => onAction(item, 'pdfYearAllNumber')},
    {label: 'Télécharger les reçus au format Excel', onClick: item => onAction(item, 'xlsAllYear')},
    {label: 'Regenérer les reçus (PDF uniquemnt)', onClick: item => onAction(item, 'regen')}
  ]
  let actions = [
    {
      name: 'print',
      label: props.intl.formatMessage({
        id: 'app.list.button.print',
        defaultMessage: 'Print',
      }),
      onClick: onPrint,
      theme: 'secondary',
      icon: <PrintIcon />,
      role: 'PRINT',
      param: 'object',
      active: myEditions.length > 0,
      options: myEditions,
    },
  ];
  actions.push({
    name: 'generate',
    label: props.intl.formatMessage({
      id: 'app.list.button.receipt.generate',
      defaultMessage: 'Generate',
    }),
    onClick: item => onAction(item, 'generate'),
    theme: 'secondary',
    fShow: item => item.recg_status === 'NONE',
    confirm: props.intl.formatMessage({
      id: 'app.list.button.receipt.generate.confirm',
      defaultMessage: 'Confirm ?',
    }),
    icon: <BookPlayIcon />,
    role: 'ACTION',
    param: 'object',
  });
  actions.push({
    name: 'remove',
    label: props.intl.formatMessage({
      id: 'app.list.button.receipt.degenerate',
      defaultMessage: 'Generate',
    }),
    onClick: item => onAction(item, 'remove'),
    theme: 'warning',
    fShow: item => item.recg_status === 'DONE' || item.recg_status === 'ERROR',
    confirm: props.intl.formatMessage({
      id: 'app.list.button.receipt.degenerate.confirm',
      defaultMessage: 'Confirm ?',
    }),
    icon: <BookMinusIcon />,
    role: 'ACTION',
    param: 'object',
  });
  actions.push({
    name: 'send',
    label: props.intl.formatMessage({
      id: 'app.list.button.receipt.send',
      defaultMessage: 'Send',
    }),
    onClick: item => onAction(item, 'send'),
    theme: 'secondary',
    fShow: item => item.recg_status === 'DONE' || item.recg_status === 'ERROR',
    confirm: props.intl.formatMessage({
      id: 'app.list.button.receipt.send.confirm',
      defaultMessage: 'Confirm ?',
    }),
    icon: <EmailIcon />,
    role: 'ACTION',
    param: 'object',
  });
  actions.push({
    name: 'download',
    label: props.intl.formatMessage({
      id: 'app.list.button.receipt.download',
      defaultMessage: 'Download',
    }),
    onClick: item => onAction(item, 'generate'),
    theme: 'secondary',
    fShow: item => item.recg_status === 'DONE',
    confirm: props.intl.formatMessage({
      id: 'app.list.button.receipt.download.confirm',
      defaultMessage: 'Confirm ?',
    }),
    icon: <SystemIcon />,
    role: 'ACTION',
    param: 'object',
    active: item => item.recg_status === 'DONE',
    options: myExports,
  });
  actions.push(
    {
      name: 'modify',
      label: props.intl.formatMessage({
        id: 'app.list.button.modify',
        defaultMessage: 'Modify',
      }),
      onClick: onGetOne,
      theme: 'secondary',
      icon: <GetOneIcon />,
      role: 'MODIFY',
    },
    {
      name: 'delete',
      label: props.intl.formatMessage({
        id: 'app.list.button.delete',
        defaultMessage: 'Delete',
      }),
      onClick: onDelOne,
      theme: 'warning',
      icon: <DelOneIcon />,
      role: 'DELETE',
    },
  );
  return actions;
};

/**
 * Descriptions de toutes les colonnes de la liste
 * Même les colonnes cachées
 * Ces colonnes peuvent ou non données des champs dans les filtres
 * (une info peut de pas être affichée dans la liste mais on peut peut-être mettre un filtre dessus)
 */
export const getCols = ({ props }) => {
  return [
    {
      name: 'recg_id',
      label: props.intl.formatMessage({
        id: 'app.features.receipt-generation.field.recg_id.label',
        defaultMessage: 'Id.',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt-generation.field.recg_id.short',
        defaultMessage: 'Id.',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt-generation.field.recg_id.comment',
        defaultMessage: 'Identifiant',
      }),
      col: 'recg_id',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'ID' },
      filterable: true,
    },
    {
      name: 'recg_name',
      label: props.intl.formatMessage({
        id: 'app.features.receipt-generation.field.recg_name.label',
        defaultMessage: 'Nom',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt-generation.field.recg_name.short',
        defaultMessage: 'Nom',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt-generation.field.recg_name.comment',
        defaultMessage: 'Nom',
      }),
      col: 'recg_name',
      size: { xxs: 36, sm: 12, md: 6 },
      title: true,
      sortable: true,
      card: { role: 'TITLE' },
      filterable: true,
    },
    {
      name: 'recg_year',
      label: props.intl.formatMessage({
        id: 'app.features.receipt-generation.field.recg_year.label',
        defaultMessage: 'Année',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt-generation.field.recg_year.short',
        defaultMessage: 'Année',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt-generation.field.recg_year.comment',
        defaultMessage: 'Année',
      }),
      col: 'recg_year',
      size: { xxs: 36, sm: 14, md: 8 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'recg_status',
      label: props.intl.formatMessage({
        id: 'app.features.receipt-generation.field.recg_status.label',
        defaultMessage: 'Status',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt-generation.field.recg_status.short',
        defaultMessage: 'Status',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt-generation.field.recg_status.comment',
        defaultMessage: 'Status',
      }),
      col: 'recg_status',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      type: 'switch',
      values: getStatus(props.intl),
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'recg_gen',
      label: props.intl.formatMessage({
        id: 'app.features.receipt-generation.field.recg_gen.label',
        defaultMessage: 'Généré',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt-generation.field.recg_gen.short',
        defaultMessage: 'Généré',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt-generation.field.recg_gen.comment',
        defaultMessage: 'Généré',
      }),
      col: 'recg_gen',
      type: 'date',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
    },
    {
      name: 'recg_email',
      label: props.intl.formatMessage({
        id: 'app.features.receipt-generation.field.recg_email.label',
        defaultMessage: 'Email',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt-generation.field.recg_email.short',
        defaultMessage: 'Email',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt-generation.field.recg_email.comment',
        defaultMessage: 'Email',
      }),
      col: 'recg_email',
      type: 'date',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
    },
    {
      name: 'recg_no_email',
      label: props.intl.formatMessage({
        id: 'app.features.receipt-generation.field.recg_no_email.label',
        defaultMessage: 'Courrier',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt-generation.field.recg_no_email.short',
        defaultMessage: 'Courrier',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt-generation.field.recg_no_email.comment',
        defaultMessage: 'Courrier',
      }),
      col: 'recg_no_email',
      type: 'date',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
    },
    {
      name: 'recg_save',
      label: props.intl.formatMessage({
        id: 'app.features.receipt-generation.field.recg_save.label',
        defaultMessage: 'Svg',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt-generation.field.recg_save.short',
        defaultMessage: 'Svg',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt-generation.field.recg_save.comment',
        defaultMessage: 'Sauvegarde',
      }),
      col: 'recg_save',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      hidden: true,
    },
    {
      name: 'grp_id',
      label: props.intl.formatMessage({
        id: 'app.features.receipt-generation.field.grp_id.label',
        defaultMessage: 'Groupe',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt-generation.field.grp_id.short',
        defaultMessage: 'Groupe',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt-generation.field.grp_id.comment',
        defaultMessage: 'Groupe',
      }),
      col: 'grp_id',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      hidden: true,
    },
  ];
};
