export const SUBSPECIES_LOAD_MORE_INIT = 'SUBSPECIES_LOAD_MORE_INIT';
export const SUBSPECIES_LOAD_MORE_BEGIN = 'SUBSPECIES_LOAD_MORE_BEGIN';
export const SUBSPECIES_LOAD_MORE_SUCCESS = 'SUBSPECIES_LOAD_MORE_SUCCESS';
export const SUBSPECIES_LOAD_MORE_FAILURE = 'SUBSPECIES_LOAD_MORE_FAILURE';
export const SUBSPECIES_LOAD_MORE_DISMISS_ERROR = 'SUBSPECIES_LOAD_MORE_DISMISS_ERROR';
export const SUBSPECIES_CLEAR_ITEMS = 'SUBSPECIES_CLEAR_ITEMS';
export const SUBSPECIES_CREATE_ONE_BEGIN = 'SUBSPECIES_CREATE_ONE_BEGIN';
export const SUBSPECIES_CREATE_ONE_SUCCESS = 'SUBSPECIES_CREATE_ONE_SUCCESS';
export const SUBSPECIES_CREATE_ONE_FAILURE = 'SUBSPECIES_CREATE_ONE_FAILURE';
export const SUBSPECIES_CREATE_ONE_DISMISS_ERROR = 'SUBSPECIES_CREATE_ONE_DISMISS_ERROR';
export const SUBSPECIES_DEL_ONE_BEGIN = 'SUBSPECIES_DEL_ONE_BEGIN';
export const SUBSPECIES_DEL_ONE_SUCCESS = 'SUBSPECIES_DEL_ONE_SUCCESS';
export const SUBSPECIES_DEL_ONE_FAILURE = 'SUBSPECIES_DEL_ONE_FAILURE';
export const SUBSPECIES_DEL_ONE_DISMISS_ERROR = 'SUBSPECIES_DEL_ONE_DISMISS_ERROR';
export const SUBSPECIES_LOAD_ONE_BEGIN = 'SUBSPECIES_LOAD_ONE_BEGIN';
export const SUBSPECIES_LOAD_ONE_SUCCESS = 'SUBSPECIES_LOAD_ONE_SUCCESS';
export const SUBSPECIES_LOAD_ONE_FAILURE = 'SUBSPECIES_LOAD_ONE_FAILURE';
export const SUBSPECIES_LOAD_ONE_DISMISS_ERROR = 'SUBSPECIES_LOAD_ONE_DISMISS_ERROR';
export const SUBSPECIES_UPDATE_ONE_BEGIN = 'SUBSPECIES_UPDATE_ONE_BEGIN';
export const SUBSPECIES_UPDATE_ONE_SUCCESS = 'SUBSPECIES_UPDATE_ONE_SUCCESS';
export const SUBSPECIES_UPDATE_ONE_FAILURE = 'SUBSPECIES_UPDATE_ONE_FAILURE';
export const SUBSPECIES_UPDATE_ONE_DISMISS_ERROR = 'SUBSPECIES_UPDATE_ONE_DISMISS_ERROR';
export const SUBSPECIES_UPDATE_ONE_UPDATE = 'SUBSPECIES_UPDATE_ONE_UPDATE';
export const SUBSPECIES_UPDATE_QUICK_SEARCH = 'SUBSPECIES_UPDATE_QUICK_SEARCH';
export const SUBSPECIES_SET_SORT = 'SUBSPECIES_SET_SORT';
export const SUBSPECIES_SET_FILTERS = 'SUBSPECIES_SET_FILTERS';
export const SUBSPECIES_UPDATE_SORT = 'SUBSPECIES_UPDATE_SORT';
export const SUBSPECIES_INIT_SORT = 'SUBSPECIES_INIT_SORT';
export const SUBSPECIES_INIT_FILTERS = 'SUBSPECIES_INIT_FILTERS';
