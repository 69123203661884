import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';
import { HighlightButton } from 'react-bootstrap-front';
import { injectIntl } from 'react-intl';
import * as actions from './redux/actions';
import { ResponsiveModalOrForm } from '../ui';

export class HelpPanel extends Component {
  static propTypes = {
    help: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      item: null,
    };
    this.onHelp = this.onHelp.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onHelp(item) {
    if (item) {
      this.setState({ item: item });
    } else {
      this.setState({ item: null });
    }
  }

  onClose() {
    this.setState({ item: null });
  }

  render() {
    return (
      <>
        <div className="list-group m-2">
          <HighlightButton theme="NAV">
            <button type="button" className="list-group-item list-group-item-action">
              Tour de la page en cours
            </button>
          </HighlightButton>
          {this.props.help.models.map(item => {
            return (
              <button
                key={`bthlp-${item.id}`}
                type="button"
                className="list-group-item list-group-item-action"
                onClick={ev => this.onHelp(item)}
              >
                {item.help_name}
              </button>
            );
          })}
        </div>
        {this.state.item && (
          <ResponsiveModalOrForm
            title={this.state.item.help_name}
            size="md"
            show={true}
            modal={true}
            onClose={this.onClose}
          >
            {
              {
                HTML: (
                  <div dangerouslySetInnerHTML={{ __html: `${this.state.item.help_content}` }} />
                ),
                MP4: (
                  <ReactPlayer
                    url={this.state.item.help_content}
                    className="ui-react-player"
                    controls={true}
                    width="100%"
                    height="100%"
                  />
                ),
              }[this.state.item.help_type]
            }
          </ResponsiveModalOrForm>
        )}
      </>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    help: state.help,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HelpPanel));
