import React from 'react';
import { displayMonetary } from 'react-bootstrap-front';
import {
  AddOne as AddOneIcon,
  GetOne as GetOneIcon,
  GetPhoto as GetPhotoIcon,
  DelOne as DelOneIcon,
  Sponsorship as SponsorshipIcon,
  Donation as CauseDonationIcon,
  News as NewsIcon,
  Sponsor as SponsorIcon,
  Male as MaleIcon,
  Female as FemaleIcon,
  Print as PrintIcon,
} from '../icons';
import { siteAsOptions } from '../site/functions';

export const sexSelect = [
  { label: 'Femelle', value: 'F', icon: <FemaleIcon className="col-icon text-secondary" /> },
  { label: 'Mâle', value: 'M', icon: <MaleIcon className="col-icon text-secondary" /> },
  { label: 'Indéfini', value: 'OTHER' },
];

/**
 *
 */
export const displayItemPicker = item => {
  if (!item || !item.id) {
    return null;
  }
  return (
    <p className="input-picker-line">
      <span className="input-picker-left">{item.cau_name}</span>
      {item.cause_type && item.cause_type.caut_mnt_type === 'ANNUAL' && (
        <span className="input-picker-right pl-1">
          {displayMonetary(item.cau_mnt, item.cau_money)} récoltés
        </span>
      )}
      <br />
      {item.cause_type && <span> ({item.cause_type.caut_name})</span>}
      {item.cau_to && <span> (mort ou relâché)</span>}
    </p>
  );
};

export const getGlobalActions = ({ props, onClearFilters, onCreate }) => {
  return [
    {
      name: 'create',
      label: props.intl.formatMessage({
        id: 'app.list.button.add',
        defaultMessage: 'Add',
      }),
      onClick: onCreate,
      theme: 'primary',
      icon: <AddOneIcon color="white" />,
      role: 'CREATE',
    },
  ];
};

export const getActionsButtons = ({ onPrint, state, props }) => {
  if (state.id > 0 && props.editions && props.editions.length > 0) {
    let ediId = state.item.cause_type.identity_edition
      ? state.item.cause_type.identity_edition.id
      : null;
    return [
      {
        theme: 'secondary',
        hidden: false,
        function: () => onPrint(ediId),
        icon: <PrintIcon title="Imprimer" />,
      },
    ];
  }
  return [];
};

export const getSelectActions = ({ props, onSelectMenu }) => {
  let arrOne = [
    {
      name: 'selectAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.selectAll',
        defaultMessage: 'Select all',
      }),
      onClick: () => {
        onSelectMenu('selectAll');
      },
    },
  ];
  const arrAppend = [
    {
      name: 'selectNone',
      label: props.intl.formatMessage({
        id: 'app.list.options.deselectAll',
        defaultMessage: 'Deselect all',
      }),
      onClick: () => {
        onSelectMenu('selectNone');
      },
    },
    { name: 'divider' },
    {
      name: 'exportSelect',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportSelect',
        defaultMessage: 'Export selection',
      }),
      onClick: () => {
        onSelectMenu('exportSelection');
      },
    },
  ];
  if (props.cause.selected.length > 0) {
    arrOne = arrOne.concat(arrAppend);
  }
  const arrStandard = [
    { name: 'divider' },
    {
      name: 'exportAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportAll',
        defaultMessage: 'Export all',
      }),
      onClick: () => {
        onSelectMenu('exportAll');
      },
    },
  ];
  arrOne = arrOne.concat(arrStandard);
  return arrOne;
};

export const getInlineActions = ({ props, onSelectList, onGetOne, onDelOne, onPrint, state }) => {
  let myEditions = [];
  if (state && state.editions) {
    const { editions } = state;
    editions.forEach(edition => {
      edition.langs.forEach(lang => {
        myEditions.push({ label: edition.edi_name + ' (' + lang + ')', onClick: item => onPrint(edition.id, item, lang) });
      })
    });
  }
  return [
    {
      name: 'sponsorship',
      label: props.intl.formatMessage({
        id: 'app.list.button.sponsorships',
        defaultMessage: 'Sponsorships',
      }),
      onClick: item => {
        onSelectList(item, 'sponsorship');
      },
      param: 'object',
      theme: 'secondary',
      icon: <SponsorshipIcon />,
      role: 'OTHER',
      active: state.sponsorships > 0,
    },
    {
      name: 'donation',
      label: props.intl.formatMessage({
        id: 'app.list.button.donations',
        defaultMessage: 'Donations',
      }),
      onClick: item => {
        onSelectList(item, 'donation');
      },
      param: 'object',
      theme: 'secondary',
      icon: <CauseDonationIcon />,
      role: 'OTHER',
      active: state.donations > 0,
    },
    {
      name: 'photo',
      label: props.intl.formatMessage({
        id: 'app.list.button.photos',
        defaultMessage: 'Pictures',
      }),
      onClick: item => {
        onSelectList(item, 'photo');
      },
      param: 'object',
      theme: 'secondary',
      icon: <GetPhotoIcon />,
      role: 'OTHER',
      active: state.photos > 0,
    },
    {
      name: 'news',
      label: props.intl.formatMessage({
        id: 'app.list.button.news',
        defaultMessage: 'News',
      }),
      onClick: item => {
        onSelectList(item, 'news');
      },
      param: 'object',
      theme: 'secondary',
      icon: <NewsIcon />,
      role: 'OTHER',
      active: state.news > 0,
    },
    {
      name: 'sponsor',
      label: props.intl.formatMessage({
        id: 'app.list.button.sponsors',
        defaultMessage: 'Sponsors',
      }),
      onClick: item => {
        onSelectList(item, 'sponsor');
      },
      param: 'object',
      theme: 'secondary',
      icon: <SponsorIcon />,
      role: 'OTHER',
      active: state.sponsors > 0,
    },
    {
      name: 'print',
      label: props.intl.formatMessage({
        id: 'app.list.button.print',
        defaultMessage: 'Print',
      }),
      onClick: onPrint,
      theme: 'secondary',
      icon: <PrintIcon />,
      role: 'PRINT',
      param: 'object',
      active: myEditions.length > 0,
      options: myEditions,
    },
    {
      name: 'modify',
      label: props.intl.formatMessage({
        id: 'app.list.button.modify',
        defaultMessage: 'Modify',
      }),
      onClick: onGetOne,
      theme: 'secondary',
      icon: <GetOneIcon />,
      role: 'MODIFY',
    },
    {
      name: 'delete',
      label: props.intl.formatMessage({
        id: 'app.list.button.delete',
        defaultMessage: 'Delete',
      }),
      onClick: onDelOne,
      theme: 'warning',
      icon: <DelOneIcon />,
      role: 'DELETE',
    },
  ];
};

export const getCols = ({ props }) => {
  return [
    {
      name: 'photo',
      label: '',
      col: 'default_blob.caum_short_blob',
      size: { sm: 5, md: 4, lg: 3 },
      mob_size: '',
      sortable: false,
      title: true,
      first: true,
      selectable: true,
      type: 'thumbnail',
    },
    {
      name: 'id',
      label: props.intl.formatMessage({
        id: 'app.features.cause.list.col.id',
        defaultMessage: 'Number',
      }),
      col: 'id',
      size: '4',
      mob_size: '',
      title: true,
      sortable: true,
      filterable: false,
      hidden: true,
      card: { role: 'ID' },
    },
    {
      name: 'name',
      label: props.intl.formatMessage({
        id: 'app.features.cause.list.col.name',
        defaultMessage: 'Name',
      }),
      col: 'cau_name',
      size: { sm: 5, md: 4, lg: 3 },
      mob_size: '',
      title: true,
      sortable: true,
      filterable: { type: 'text' },
      card: { role: 'TITLE' },
    },
    {
      name: 'subspecies',
      label: props.intl.formatMessage({
        id: 'app.features.cause.list.col.subspecies',
        defaultMessage: 'Subspecies',
      }),
      col: 'subspecies.sspe_name',
      size: { sm: 8, md: 7, lg: 6 },
      mob_size: '',
      title: true,
      sortable: true,
      card: { role: 'FIELD', position: 10 },
    },
    {
      name: 'sexe',
      label: props.intl.formatMessage({
        id: 'app.features.cause.list.col.sex',
        defaultMessage: 'Sex',
      }),
      col: 'cau_sex',
      size: { sm: 4, md: 3, lg: 2 },
      mob_size: '',
      type: 'switch',
      values: sexSelect,
      sortable: true,
      filterable: false,
      card: { role: 'FIELD', position: 90 },
    },
    {
      name: 'cau_year',
      label: props.intl.formatMessage({
        id: 'app.features.cause.list.col.cauYear',
        defaultMessage: 'Born in',
      }),
      col: 'cau_year',
      size: { sm: 5, md: 4, lg: 3 },
      mob_size: '',
      type: 'numeric',
      title: true,
      sortable: true,
      card: { role: 'FIELD', position: 20 },
    },
    {
      name: 'cau_mnt',
      label: props.intl.formatMessage({
        id: 'app.features.cause.list.col.mnt',
        defaultMessage: 'Raised',
      }),
      col: 'cau_mnt',
      size: { sm: 4, md: 4, lg: 3 },
      mob_size: '',
      type: 'monetary',
      title: true,
      filterable: { type: 'monetary' },
      sortable: true,
      card: { role: 'FIELD', position: 30 },
    },
    {
      name: 'cau_mnt_left',
      label: props.intl.formatMessage({
        id: 'app.features.cause.list.col.left',
        defaultMessage: 'Left',
      }),
      col: 'cau_mnt_left',
      size: { sm: 4, md: 4, lg: 3 },
      mob_size: '',
      type: 'monetary',
      title: true,
      fDisplay: (item, newContent) => {
        if (item.cau_to === '' || item.cau_to === null) {
          if (item.cause_type && item.cause_type.caut_max_mnt > 0) {
            return newContent;
          }
        } else {
          return '-';
        }
      },
      filterable: { type: 'monetary' },
      sortable: true,
      card: { role: 'FIELD', position: 40 },
    },
    {
      name: 'site',
      label: props.intl.formatMessage({
        id: 'app.features.cause.list.col.site',
        defaultMessage: 'Location',
      }),
      col: 'site.site_name',
      size: { xs: 0, sm: 0, md: 4, lg: 3 },
      mob_size: '',
      title: true,
      sortable: true,
    },
    {
      name: 'cau_to',
      label: props.intl.formatMessage({
        id: 'app.features.cause.list.col.end',
        defaultMessage: 'End',
      }),
      col: 'cau_to',
      size: '0',
      mob_size: '0',
      hidden: true,
      filterable: { type: 'date' },
    },
    {
      name: 'site',
      label: props.intl.formatMessage({
        id: 'app.features.cause.list.col.site',
        defaultMessage: 'Location',
      }),
      col: 'site.site_id',
      size: '0',
      mob_size: '0',
      hidden: true,
      filterable: {
        type: 'select',
        options: siteAsOptions(props.site.items),
      },
      last: true,
    },
    {
      name: 'cau_last_news',
      label: props.intl.formatMessage({
        id: 'app.features.cause.list.col.news',
        defaultMessage: 'News',
      }),
      col: 'cau_last_news',
      size: { xs: 0, sm: 0, md: 0, lg: 3 },
      mob_size: '0',
      type: 'date',
      sortable: true,
      filterable: { type: 'date' },
    },
    {
      name: 'cau_string_3',
      label: props.intl.formatMessage({
        id: 'app.features.cause.list.col.endWhy',
        defaultMessage: 'More',
      }),
      col: 'cau_string_3',
      size: { xs: 0, sm: 0, md: 0, lg: 3 },
      mob_size: '0',
      filterable: {
        type: 'text',
      },
      last: true,
    },
  ];
};

export const getColsSimple = ({ props }) => {
  return [
    {
      name: 'photo',
      label: '',
      col: 'default_blob.caum_short_blob',
      size: '3',
      mob_size: '',
      sortable: false,
      title: true,
      first: true,
      type: 'thumbnail',
    },
    {
      name: 'id',
      label: props.intl.formatMessage({
        id: 'app.features.cause.list.col.id',
        defaultMessage: 'Number',
      }),
      col: 'id',
      size: '4',
      mob_size: '',
      title: true,
      sortable: true,
      filterable: false,
      hidden: true,
      card: { role: 'ID' },
    },
    {
      name: 'name',
      label: props.intl.formatMessage({
        id: 'app.features.cause.list.col.name',
        defaultMessage: 'Name',
      }),
      col: 'cau_name',
      size: '3',
      mob_size: '',
      title: true,
      sortable: true,
      filterable: { type: 'text' },
      card: { role: 'TITLE' },
    },
    {
      name: 'cau_mnt',
      label: props.intl.formatMessage({
        id: 'app.features.cause.list.col.mnt',
        defaultMessage: 'Raised',
      }),
      col: 'cau_mnt',
      size: '4',
      mob_size: '',
      type: 'monetary',
      title: true,
      fDisplay: (item, newContent) => {
        if (item.cau_to === '' || item.cau_to === null) {
          return newContent;
        } else {
          return '';
        }
      },
      filterable: { type: 'monetary' },
      sortable: true,
      card: { role: 'FIELD' },
    },
    {
      name: 'cau_mnt_left',
      label: props.intl.formatMessage({
        id: 'app.features.cause.list.col.left',
        defaultMessage: 'Left',
      }),
      col: 'cau_mnt_left',
      size: '4',
      mob_size: '',
      type: 'monetary',
      title: true,
      fDisplay: (item, newContent) => {
        if (item.cau_to === '' || item.cau_to === null) {
          if (item.cause_type && item.cause_type.caut_max_mnt > 0) {
            return newContent;
          }
        } else {
          return '-';
        }
      },
      filterable: { type: 'monetary' },
      sortable: true,
      card: { role: 'FIELD' },
    },
    {
      name: 'site',
      label: props.intl.formatMessage({
        id: 'app.features.cause.list.col.site',
        defaultMessage: 'Location',
      }),
      col: 'site.site_name',
      size: '3',
      mob_size: '',
      title: true,
      sortable: true,
    },
    {
      name: 'cau_to',
      label: props.intl.formatMessage({
        id: 'app.features.cause.list.col.end',
        defaultMessage: 'End',
      }),
      col: 'cau_to',
      size: '0',
      mob_size: '0',
      hidden: true,
      filterable: { type: 'date' },
    },
    {
      name: 'site',
      label: props.intl.formatMessage({
        id: 'app.features.cause.list.col.site',
        defaultMessage: 'Location',
      }),
      col: 'site.site_id',
      size: '0',
      mob_size: '0',
      hidden: true,
      filterable: {
        type: 'select',
        options: siteAsOptions(props.site.items),
      },
      last: true,
    },
  ];
};
