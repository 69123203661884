import React from 'react';
import Icon from '@mdi/react';
import {
  mdiTimelineHelp as myIcon
} from '@mdi/js';

export default function Changelog(props) {
  return (
    <Icon path={myIcon} size={1} {...props} />
  );
};
