export const SPONSORSHIP_LOAD_SPONSORSHIPS_INIT = 'SPONSORSHIP_LOAD_SPONSORSHIPS_INIT';
export const SPONSORSHIP_LOAD_SPONSORSHIPS_BEGIN = 'SPONSORSHIP_LOAD_SPONSORSHIPS_BEGIN';
export const SPONSORSHIP_LOAD_SPONSORSHIPS_SUCCESS = 'SPONSORSHIP_LOAD_SPONSORSHIPS_SUCCESS';
export const SPONSORSHIP_LOAD_SPONSORSHIPS_FAILURE = 'SPONSORSHIP_LOAD_SPONSORSHIPS_FAILURE';
export const SPONSORSHIP_LOAD_SPONSORSHIPS_DISMISS_ERROR = 'SPONSORSHIP_LOAD_SPONSORSHIPS_DISMISS_ERROR';
export const SPONSORSHIP_LOAD_ONE_BEGIN = 'SPONSORSHIP_LOAD_ONE_BEGIN';
export const SPONSORSHIP_LOAD_ONE_SUCCESS = 'SPONSORSHIP_LOAD_ONE_SUCCESS';
export const SPONSORSHIP_LOAD_ONE_FAILURE = 'SPONSORSHIP_LOAD_ONE_FAILURE';
export const SPONSORSHIP_LOAD_ONE_DISMISS_ERROR = 'SPONSORSHIP_LOAD_ONE_DISMISS_ERROR';
export const SPONSORSHIP_CREATE_ONE_BEGIN = 'SPONSORSHIP_CREATE_ONE_BEGIN';
export const SPONSORSHIP_CREATE_ONE_SUCCESS = 'SPONSORSHIP_CREATE_ONE_SUCCESS';
export const SPONSORSHIP_CREATE_ONE_FAILURE = 'SPONSORSHIP_CREATE_ONE_FAILURE';
export const SPONSORSHIP_CREATE_ONE_DISMISS_ERROR = 'SPONSORSHIP_CREATE_ONE_DISMISS_ERROR';
export const SPONSORSHIP_DEL_ONE_BEGIN = 'SPONSORSHIP_DEL_ONE_BEGIN';
export const SPONSORSHIP_DEL_ONE_SUCCESS = 'SPONSORSHIP_DEL_ONE_SUCCESS';
export const SPONSORSHIP_DEL_ONE_FAILURE = 'SPONSORSHIP_DEL_ONE_FAILURE';
export const SPONSORSHIP_DEL_ONE_DISMISS_ERROR = 'SPONSORSHIP_DEL_ONE_DISMISS_ERROR';
export const SPONSORSHIP_UPDATE_ONE_BEGIN = 'SPONSORSHIP_UPDATE_ONE_BEGIN';
export const SPONSORSHIP_UPDATE_ONE_SUCCESS = 'SPONSORSHIP_UPDATE_ONE_SUCCESS';
export const SPONSORSHIP_UPDATE_ONE_FAILURE = 'SPONSORSHIP_UPDATE_ONE_FAILURE';
export const SPONSORSHIP_UPDATE_ONE_DISMISS_ERROR = 'SPONSORSHIP_UPDATE_ONE_DISMISS_ERROR';
export const SPONSORSHIP_UPDATE_ONE_UPDATE = 'SPONSORSHIP_UPDATE_ONE_UPDATE';
