import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { normalizedObjectModeler } from 'jsonapi-front';
import { ObjectViewer } from '../common';
import { KalaLoader, ResponsiveInlineList, messageSuccess } from '../ui';
import { getCols, getInlineActions } from './';

export class InlinePendings extends Component {
  static propTypes = {
    notification: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      objectName: '',
      objectId: null,
    };
    this.onValidate = this.onValidate.bind(this);
    this.onGetObject = this.onGetObject.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    this.props.actions.loadPendings(true);
  }

  onClose() {
    this.setState({ objectName: '', objectId: null });
  }

  /**
   * Set object to open
   *
   * @param {Object} item
   */
  onGetObject(item) {
    if (item) {
      this.setState({ objectName: item.notif_object_name, objectId: item.notif_object_id });
    }
  }

  onValidate(item) {
    if (item && item.id) {
      this.props.actions.validateOne(item).then(result => {
        messageSuccess(
          this.props.intl.formatMessage({
            id: 'app.features.notification.validated',
            defaultMessage: 'Validation effectuée',
          }),
        );
        this.props.actions.loadPendings(true);
      });
    }
  }

  render() {
    const cols = getCols({ ...this, colTheme: 'PENDINGS' });
    let items = [];
    let total = 0;
    if (this.props.notification.pendings) {
      total = this.props.notification.pendings.TOTAL;
      const allItems = normalizedObjectModeler(this.props.notification.pendings, 'FreeFW_Notification');
      if (Array.isArray(allItems)) {
        items = allItems.filter(elem => elem.notif_read !== true);
      }
    }
    const inlineActions = getInlineActions(this);
    return (
      <div className="notification-inline-list">
        <ResponsiveInlineList
          cols={cols}
          {...this.props}
          items={items}
          inlineActions={inlineActions}
          onMore={() => this.props.actions.loadPendings()}
          total={total}
          loading={false}
          title={false}
        />
        {this.props.notification.loadPendingsPending && <KalaLoader />}
        {this.state.objectName && this.state.objectId && (
          <ObjectViewer
            name={this.state.objectName}
            id={this.state.objectId}
            onClose={this.onClose}
          />
        )}
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    notification: state.notification,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(InlinePendings),
);
