import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { HoverObserver } from 'react-bootstrap-front';
import { ColLink } from './';

export default class InlineAddOne extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      flipped: false,
    };
    this.mouseLeave = this.mouseLeave.bind(this);
    this.mouseEnter = this.mouseEnter.bind(this);
  }

  mouseLeave() {
    this.setState({ flipped: false });
  }

  mouseEnter() {
    this.setState({ flipped: true });
  }

  render() {
    return (
      <HoverObserver onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
        <div
          className={classnames(
            'row row-line ui-inline-add-one',
            this.props.oddEven % 2 !== 1 ? 'row-odd' : 'row-even',
            this.state.flipped && 'row-hover',
          )}
        >
          <ColLink label={this.props.label} className="text-primary" {...this.props} />
        </div>
      </HoverObserver>
    );
  }
}
