import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  ResponsiveModal,
  FILTER_MODE_AND,
  FILTER_OPER_LIKE,
  FILTER_SEARCH_SIMPLE,
  Row,
  Col,
  FilterBuilder,
  FilterHeader,
  Filter,
  randomString,
} from 'react-bootstrap-front';
import {
  Calendar as CalendarIcon,
  AddOne as AddOneIcon,
  DelOne as DelOneIcon,
  DelOne as ClearIcon,
  Filter as FilterIcon,
  FilterEmpty as FilterEmptyIcon,
  FilterFull as FilterFullIcon,
  FilterClear as FilterClearIcon,
  FilterDefault as FilterDefaultIcon,
  FilterClearDefault as FilterClearDefaultIcon,
  SimpleCancel as CancelPanelIcon,
  Search as ValidPanelIcon,
  Cancel as CancelIcon
} from '../icons';

class SearchModal extends Component {
  static propTypes = {
    title: PropTypes.string,
    show: PropTypes.bool.isRequired,
    onClear: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    list: PropTypes.element,
    pickerDisplay: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    conditions: PropTypes.array,
  };
  static defaultProps = {
    list: null,
    pickerDisplay: '',
    title: '',
  };

  static getDerivedStateFromProps(props, state) {
    if (props.conditions !== state.conditions) {
      return { conditions: props.conditions };
    }
    return null;
  }

  constructor(props) {
    super(props);
    let filters = new Filter();
    filters.init(FILTER_MODE_AND, FILTER_OPER_LIKE);
    if (Array.isArray(props.conditions)) {
      props.conditions.forEach(elem => filters.addFilter(elem.field, elem.value, elem.oper));
    }
    this.state = {
      condition: FILTER_MODE_AND,
      filters: filters,
      conditions: props.conditions,
      local: false,
    };
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onFilterMode = this.onFilterMode.bind(this);
    this.onOperator = this.onOperator.bind(this);
    this.onFilterOperator = this.onFilterOperator.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.onFilterUpdate = this.onFilterUpdate.bind(this);
    this.onFilterAdd = this.onFilterAdd.bind(this);
    this.onFilterRemove = this.onFilterRemove.bind(this);
  }

  componentDidMount() {}

  onFilterChange(event, oper = false) {
    let { filters } = this.state;
    filters.addFilter(event.target.name, event.target.value, oper);
    filters.setSearch(FILTER_SEARCH_SIMPLE);
    this.setState({ filters, local: true });
  }

  onFilterUpdate(event) {
    let { filters } = this.state;
    const origName = event.target.name;
    const parts = origName.split('@@');
    const fieldName = parts[0];
    const fieldMore = parts[1] || 'between';
    filters.updateFilter(fieldName, event.target.value, fieldMore);
    this.setState({ filters, local: true });
  }

  onFilterAdd(event) {
    const newAddon = randomString(8);
    let { filters } = this.state;
    filters.updateFilter(event.target.name, event.target.value, newAddon);
    this.setState({ filters, local: true });
  }

  onFilterRemove(event) {
    let { filters } = this.state;
    const origName = event.target.name;
    const parts = origName.split('@@');
    const fieldName = parts[0];
    const fieldMore = parts[1] || 'between';
    filters.removeFilter(fieldName, fieldMore);
    this.setState({ filters, local: true });
  }

  onFilterMode(event) {
    let { filters } = this.state;
    filters.setMode(event.target.value);
    filters.setSearch(FILTER_SEARCH_SIMPLE);
    this.setState({ filters, local: true });
  }

  onOperator(event) {
    let { filters } = this.state;
    filters.setOperator(event.target.value);
    filters.setSearch(FILTER_SEARCH_SIMPLE);
    this.setState({ filters, local: true });
  }

  onFilterOperator(event) {
    let { filters } = this.state;
    const col = event.target.name;
    const oper = event.target.value;
    filters.updateFilterOperator(col.replace('oper-', ''), oper);
    this.setState({ filters, local: true });
  }

  onClear(event) {
    let filters = new Filter();
    filters.init(FILTER_MODE_AND, FILTER_OPER_LIKE);
    this.setState({ filters });
    this.props.onSearch(filters);
  }

  handleKeyUp(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.onSearch(event);
    }
  }

  onSearch(event) {
    this.props.onSearch(this.state.filters);
  }

  render() {
    const buttons = [
      {
        name: this.props.intl.formatMessage({
          id: 'app.features.ui.searchModal.filter',
          defaultMessage: 'Filter',
        }),
        function: this.onSearch,
        theme: 'primary',
        icon: <FilterIcon />,
      },
      {
        name: this.props.intl.formatMessage({
          id: 'app.features.ui.searchModal.delete',
          defaultMessage: 'Delete',
        }),
        function: this.onClear,
        theme: 'warning',
        icon: <ClearIcon />,
      },
      {
        name: this.props.intl.formatMessage({
          id: 'app.features.ui.searchModal.cancel',
          defaultMessage: 'Cancel',
        }),
        function: this.props.onClose,
        theme: 'secondary',
        icon: <CancelIcon />,
      },
    ];
    return (
      <ResponsiveModal
        size="md"
        title={this.props.title}
        show={this.props.show}
        onClose={this.props.onClose}
        buttons={buttons}
        scroll={false}
        modalClassName="bg-secondary-light text-secondary"
        closeClassName="text-light"
      >
        <Row className="ui-search-modal h-100 overflow-hidden no-gutters">
          <Col
            className="ui-search-modal-right border border-secondary-light p-0"
            size={{ xxs: 36, sm: 18 }}
          >
            <div className="h-100 custom-scrollbar">{this.props.list}</div>
          </Col>
          <Col className="ui-search-modal-left p-0" size={{ xxs: 36, sm: 18 }}>
            <div className="ui-search-modal-left-top p-2 border border-secondary-light">
              <FilterHeader
                filters={this.state.filters}
                onMode={this.onFilterMode}
                onOperator={this.onOperator}
                onFilterOperator={this.onFilterOperator}
              />
            </div>
            <div className="ui-search-modal-left-bottom custom-scrollbar border border-secondary-light p-2">
              <FilterBuilder
                {...this.props}
                addIcon={<AddOneIcon className="text-primary" />}
                delIcon={<DelOneIcon className="text-warning" />}
                calIcon={<CalendarIcon className="text-secondary" />}
                clearIcon={<ClearIcon className="text-warning" />}
                filterFullIcon={<FilterFullIcon color="white" />}
                filterEmptyIcon={<FilterEmptyIcon color="white" />}
                filterClearIcon={<FilterClearIcon color="white" />}
                filterDefaultIcon={<FilterDefaultIcon color="white" />}
                filterClearDefaultIcon={<FilterClearDefaultIcon color="white" />}
                validPanelIcon={<ValidPanelIcon />}
                cancelPanelIcon={<CancelPanelIcon />}
                filters={this.state.filters}
                onChange={this.onFilterChange}
                onUpdate={this.onFilterUpdate}
                onMode={this.onFilterMode}
                onOperator={this.onOperator}
                onAdd={this.onFilterAdd}
                onRemove={this.onFilterRemove}
                onFilterOperator={this.onFilterOperator}
                withHeader={false}
              />
            </div>
          </Col>
        </Row>
      </ResponsiveModal>
    );
  }
}

export default injectIntl(SearchModal);
