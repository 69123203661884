import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Avatar from 'react-avatar';
import * as actions from './redux/actions';
import * as authActions from '../auth/redux/actions';
import * as commonActions from '../common/redux/actions';
import { RightPanel } from '../common';
import { ResponsivePage } from 'react-bootstrap-front';
import {
  Menu as MenuIcon,
  MenuOpened as MenuOpenedIcon,
  MenuClosed as MenuClosedIcon,
  Warning as WarningIcon,
  AccountClose,
  SocketConnected,
  SocketDisconnected,
} from '../icons';
import { getFullName } from '../user';
import { SimpleForm, getRealms } from '../auth';
import { causeTypeAsOptions } from '../cause-type';
import { CenteredLoading9X9 } from '../ui';
import { appMenu } from './';
import logo from '../../images/favicon.png';

export class Page extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  static defaultProps = {
    children: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      inboxOpened: false,
      inboxRef: React.createRef(),
    };
    this.onNavigate = this.onNavigate.bind(this);
    this.onLocaleChange = this.onLocaleChange.bind(this);
    this.onChangeSettings = this.onChangeSettings.bind(this);
    this.onRealmSelect = this.onRealmSelect.bind(this);
    this.onInbox = this.onInbox.bind(this);
  }

  onChangeSettings(setting, value) {
    this.props.actions.changeSetting('layout', setting, value);
  }

  onLocaleChange(locale) {
    this.props.actions.setLocale(locale);
  }

  onRealmSelect(realm_id) {
    this.props.actions.switchGroup(realm_id).then(result => {
      // On recharge pour bien tout remettre à plat...
      // @todo : méthode de nettoyage de redux
      window.location.reload();
    });
  }

  onNavigate(url) {
    this.props.actions.push(url);
  }

  onInbox(ev) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    this.setState({ inboxOpened: !this.state.inboxOpened });
  }

  render() {
    const icons = [];
    const myMenu = appMenu(causeTypeAsOptions(this.props.causeTypes));
    let globalBadges = [];
    if (this.props.auth.authenticated) {
      globalBadges = [];
      if (this.props.home.socketOn) {
        if (this.props.home.socketConnected) {
          icons.push({
            name: 'socket',
            label: 'Synchronisation serveur activée',
            icon: <SocketConnected className="text-success" />,
          });
        } else {
          icons.push({
            name: 'socket',
            label: 'Erreur de synchronisation serveur',
            icon: <SocketDisconnected className="text-danger" />,
          });
        }
      }
    }
    const locale = this.props.home.locale || 'fr';
    const crtVers = window.currentVersion || '0.0.0';
    let cookVers = '0.0.0';
    if (cookie.load('APP_VERSION')) {
      cookVers = cookie.load('APP_VERSION');
    }
    if (crtVers !== '0.0.0' && process.env.REACT_APP_VERSION !== crtVers && cookVers !== crtVers) {
      // Must reload
      cookie.save('APP_VERSION', crtVers);
      setTimeout(
        () => (window.location = window.location.href + '?upd=' + new Date().toISOString()),
        200,
      );
    }
    let addOn = '';
    if (crtVers !== '0.0.0' && process.env.REACT_APP_VERSION !== crtVers) {
      addOn = (
        <WarningIcon color="red" title="Problème de version" />
      );
    }
    let pageTitle = process.env.REACT_APP_APP_NAME + ' ' + process.env.REACT_APP_VERSION;
    if (process.env.REACT_APP_WARNING !== '0') {
      pageTitle += ' (' + process.env.NODE_ENV + ')' + addOn;
    }
    return (
      <div className="home-page">
        <ResponsivePage
          headerBackgroundTheme={process.env.REACT_APP_WARNING !== '0' ? 'warning' : 'secondary'}
          menuIcon={<MenuIcon className="light" />}
          mobileTitle={<img src={logo} alt="" />}
          title={
            <span
              className="cursor-pointer"
              onClick={() =>
                (window.location = window.location.href + '?upd=' + new Date().toISOString())
              }
            >
              {pageTitle}
              {addOn}
            </span>
          }
          options={myMenu}
          scope={this.props.auth.user ? this.props.auth.user.user_scope : ''}
          badges={globalBadges}
          icons={icons}
          settings={{ ...this.props.auth.settings.layout }}
          authenticated={this.props.auth.authenticated}
          location={this.props.location}
          onNavigate={this.onNavigate}
          locales={[
            { code: 'fra', locale: 'fr', label: 'Français' },
            { code: 'gbr', locale: 'en', label: 'Royaume-Uni' },
          ]}
          currentLocale={locale}
          onLocale={this.onLocaleChange}
          currentRealm={this.props.auth.realm && this.props.auth.realm.id}
          realms={getRealms(this.props.auth.user)}
          onRealmSelect={this.onRealmSelect}
          onChangeSettings={this.onChangeSettings}
          userForm={<SimpleForm />}
          accountOpened={<AccountClose size="38" />}
          accountClosed={
            this.props.auth.user ? (
              <Avatar
                className="rounded-circle avatar-header"
                email={
                  (!this.props.auth.user.user_avatar || this.props.auth.user.user_avatar === '') &&
                  this.props.auth.user.user_email
                }
                name={getFullName(this.props.auth.user)}
                src={
                  this.props.auth.user.user_avatar && this.props.auth.user.user_avatar !== ''
                    ? `data:image/jpeg;base64,${this.props.auth.user.user_avatar}`
                    : null
                }
                size="38"
              />
            ) : null
          }
          menuOpened={<MenuOpenedIcon />}
          menuClosed={<MenuClosedIcon />}
          rightPanel={this.props.auth.authenticated ? <RightPanel /> : null}
          onRightPanelToggle={this.props.actions.toggleRightPanel}
          rightPanelOpened={this.props.common.rightPanelOpened}
          footer={!this.props.auth.authenticated}
          t={this.props.intl.formatMessage}
        >
          {this.props.auth.firstCheck &&
          (!this.props.auth.authenticated || this.props.home.loadAllFinish) ? (
            <div>{this.props.children}</div>
          ) : (
            <div className="text-center mt-5 text-secondary">
              <CenteredLoading9X9 />
            </div>
          )}
        </ResponsivePage>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    common: state.common,
    causeTypes: state.causeType.items || [],
    home: state.home,
    inbox: state.inbox,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, ...authActions, ...commonActions, push }, dispatch),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Page));
