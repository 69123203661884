import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import axios from 'axios';
import classnames from 'classnames';
import { ResponsiveModalOrForm, Row, Col, InputText, InputCheckbox } from '../ui';
import { KalaLoader } from '../ui';
import { freeAssoApi } from '../../common';

class Siret extends Component {
  static getDerivedStateFromProps(props, state) {
    if (props.nom !== state.orignom) {
      return {
        origsiren: props.siren ? props.siren : '',
        orignom: props.nom ? props.nom : '',
        origcp: props.cp ? props.cp : '',
        origville: props.ville ? props.ville : '',
        origcategorie: props.categorie ? props.categorie : '',
        nom: '',
        cp: '',
        ville: '',
        siren: '',
        siret: '',
        categorie: '',
      };
    } else {
      return {
        nom: state.nom ? state.nom : '',
        cp: state.cp ? state.cp : '',
        ville: state.ville ? state.ville : '',
        siren: state.siren ? state.siren : '',
        siret: state.siret ? state.siret : '',
        categorie: state.categorie ? state.categorie : '',
      };
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      origsiren: props.siren ? props.siren : '',
      orignom: props.nom ? props.nom : '',
      origcp: props.cp ? props.cp : '',
      origville: props.ville ? props.ville : '',
      origcategorie: props.categorie ? props.categorie : '',
      nom: '',
      cp: '',
      ville: '',
      siren: '',
      siret: '',
      categorie: '',
      loading: false,
      source: false,
      list: [],
      change_siren: true,
      change_nom: false,
      change_cp: false,
      change_ville: false,
      change_categorie: true,
      select: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.search = this.search.bind(this);
    this.reload = this.reload.bind(this);
    this.select = this.select.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    if (event.target.type === 'checkbox') {
      this.setState({ [event.target.name]: event.target.checked ? true : false });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  select(elem) {
    this.setState({ select: elem });
  }

  search(event) {
    if (this.state.source) {
      this.state.source.cancel();
    }
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      cancelToken: source.token,
    };
    this.setState({ loading: true, cancel: source });
    let query = '';
    if (this.state.siren && this.state.siren !== '') {
      if (query !== '') {
        query += '&';
      }
      query += 'siren=*' + this.state.siren + '*';
    }
    if (this.state.nom && this.state.nom !== '') {
      if (query !== '') {
        query += '&';
      }
      query += 'nom=*' + this.state.nom + '*';
    }
    if (this.state.cp && this.state.cp !== '') {
      if (query !== '') {
        query += '&';
      }
      query += 'cp=*' + this.state.cp + '*';
    }
    if (this.state.ville && this.state.ville !== '') {
      if (query !== '') {
        query += '&';
      }
      query += 'ville=*' + this.state.ville + '*';
    }
    freeAssoApi
      .get(process.env.REACT_APP_BO_URL + '/v1/asso/api/siret?' + query, {
        headers: headers,
      })
      .then(result => {
        if (result && result.data) {
          const list = result.data;
          this.setState({ list: list, loading: false });
        } else {
          this.setState({ list: [], loading: false });
        }
      })
      .catch(err => {
        this.setState({ list: [], loading: false });
      });
  }

  reload(field) {
    this.setState({ [field]: this.state['orig' + field] });
  }

  handleSubmit() {
    let ret = {};
    if (this.state.select) {
      if (this.state.change_siren) {
        ret.siren = this.state.select.siren;
      }
      if (this.state.change_nom) {
        ret.nom = this.state.select.denominationUniteLegale;
      }
      if (this.state.change_cp) {
        ret.cp = this.state.select.codePostalEtablissement;
      }
      if (this.state.change_ville) {
        ret.ville = this.state.select.libelleCommuneEtablissement;
      }
      if (this.state.change_categorie) {
        ret.categorie = this.state.select.categorie;
      }
    }
    this.props.onSubmit(ret);
  }

  render() {
    return (
      <ResponsiveModalOrForm
        title={this.props.intl.formatMessage({
          id: 'app.features.api.siret.title',
          defaultMessage: 'Recherche API SIRENE (INSEE)',
        })}
        className=""
        size="md"
        onSubmit={this.state.select && this.handleSubmit}
        onCancel={this.props.handleCancel}
        onClose={this.props.onClose}
        modal={true}
        help={this.props.intl.formatMessage({
          id: 'app.features.api.siret',
          defaultMessage: 'Aide',
        })}
      >
        <div>
          <div className="smodal-content">
            <div className="smodal-content-left">
              <div className="fixed-div">
                <Row>
                  <Col size={{ xxs: 36 }}>
                    <InputText
                      label={this.props.intl.formatMessage({
                        id: 'app.features.client.api.form.siren',
                        defaultMessage: 'Siren',
                      })}
                      name="siren"
                      id="siren"
                      labelTop={true}
                      value={this.state.siren}
                      onChange={this.handleChange}
                    />
                    <small
                      id="emailHelp"
                      class="form-text text-muted"
                      onClick={() => this.reload('siren')}
                    >
                      {this.state.origsiren}
                    </small>
                  </Col>
                </Row>
                <Row>
                  <Col size={{ xxs: 36 }}>
                    <InputText
                      label={this.props.intl.formatMessage({
                        id: 'app.features.client.api.form.name',
                        defaultMessage: 'Nom',
                      })}
                      name="nom"
                      id="nom"
                      labelTop={true}
                      value={this.state.nom}
                      onChange={this.handleChange}
                    />
                    <small
                      id="emailHelp"
                      class="form-text text-muted"
                      onClick={() => this.reload('nom')}
                    >
                      {this.state.orignom}
                    </small>
                  </Col>
                </Row>
                <Row>
                  <Col size={{ xxs: 36 }}>
                    <InputText
                      label={this.props.intl.formatMessage({
                        id: 'app.features.client.api.form.cp',
                        defaultMessage: 'Code Postal',
                      })}
                      name="cp"
                      id="cp"
                      labelTop={true}
                      value={this.state.cp}
                      onChange={this.handleChange}
                    />
                    <small
                      id="emailHelp"
                      class="form-text text-muted"
                      onClick={() => this.reload('cp')}
                    >
                      {this.state.origcp}
                    </small>
                  </Col>
                </Row>
                <Row>
                  <Col size={{ xxs: 36 }}>
                    <InputText
                      label={this.props.intl.formatMessage({
                        id: 'app.features.client.api.form.ville',
                        defaultMessage: 'Ville',
                      })}
                      name="ville"
                      id="ville"
                      labelTop={true}
                      value={this.state.ville}
                      onChange={this.handleChange}
                    />
                    <small
                      id="emailHelp"
                      class="form-text text-muted"
                      onClick={() => this.reload('ville')}
                    >
                      {this.state.origville}
                    </small>
                  </Col>
                </Row>
                <Row>
                  <Col size={{ xxs: 36 }}>
                    <InputText
                      label={this.props.intl.formatMessage({
                        id: 'app.features.client.api.form.categorie',
                        defaultMessage: 'Catégorie',
                      })}
                      name="categorie"
                      id="categorie"
                      labelTop={true}
                      value={this.state.categorie}
                      onChange={this.handleChange}
                    />
                    <small
                      id="emailHelp"
                      class="form-text text-muted"
                      onClick={() => this.reload('categorie')}
                    >
                      {this.state.origcategorie}
                    </small>
                  </Col>
                </Row>
                <Row>
                  <Col size={{ xxs: 36 }}>
                    <button className="btn btn-secondary" onClick={this.search}>
                      Rechercher
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="smodal-content-middle">
              <>
                {this.state.loading && <KalaLoader />}
                {this.state.list && (
                  <div className="quick-list">
                    <div class="list-group">
                      {this.state.list.map(elem => {
                        return (
                          <button
                            className={classnames(
                              'list-group-item list-group-item-action',
                              this.state.select && elem.siret === this.state.select.siret
                                ? 'active'
                                : '',
                            )}
                            onClick={() => this.select(elem)}
                          >
                            <div className="search-field">
                              <span className="search-label">siren</span>
                              <span className="search-value">{elem.siren}</span>
                            </div>
                            <div className="search-field">
                              <span className="search-label">siret</span>
                              <span className="search-value">{elem.siret}</span>
                            </div>
                            <div className="search-field">
                              <span className="search-label">nom</span>
                              <span className="search-value">{elem.denominationUniteLegale}</span>
                            </div>
                            <div className="search-field">
                              <span className="search-label">numéro</span>
                              <span className="search-value">{elem.numeroVoieEtablissement}</span>
                            </div>
                            <div className="search-field">
                              <span className="search-label">type</span>
                              <span className="search-value">{elem.typeVoieEtablissement}</span>
                            </div>
                            <div className="search-field">
                              <span className="search-label">voie</span>
                              <span className="search-value">{elem.libelleVoieEtablissement}</span>
                            </div>
                            <div className="search-field">
                              <span className="search-label">cp</span>
                              <span className="search-value">{elem.codePostalEtablissement}</span>
                            </div>
                            <div className="search-field">
                              <span className="search-label">ville</span>
                              <span className="search-value">
                                {elem.libelleCommuneEtablissement}
                              </span>
                            </div>
                            <div className="search-field">
                              <span className="search-label">catégorie</span>
                              <span className="search-value">{elem.categorie}</span>
                            </div>
                          </button>
                        );
                      })}
                    </div>
                  </div>
                )}
              </>
            </div>
            {this.state.select && (
              <div className="smodal-content-right-wrapper">
                <div className="smodal-content-right">
                  <Row>
                    <Col size={{ xxs: 36 }}>
                      <InputCheckbox
                        label={this.props.intl.formatMessage({
                          id: 'app.features.client.api.form.name',
                          defaultMessage: 'SIREN',
                        })}
                        name="change_siren"
                        id="change_siren"
                        labelTop={true}
                        checked={this.state.change_siren}
                        onChange={this.handleChange}
                      />
                    </Col>
                    <Col size={{ xxs: 36 }}>
                      <InputCheckbox
                        label={this.props.intl.formatMessage({
                          id: 'app.features.client.api.form.name',
                          defaultMessage: 'Nom',
                        })}
                        name="change_nom"
                        id="change_nom"
                        labelTop={true}
                        checked={this.state.change_nom}
                        onChange={this.handleChange}
                      />
                    </Col>
                    <Col size={{ xxs: 36 }}>
                      <InputCheckbox
                        label={this.props.intl.formatMessage({
                          id: 'app.features.client.api.form.name',
                          defaultMessage: 'Code postal',
                        })}
                        name="change_cp"
                        id="change_cp"
                        labelTop={true}
                        checked={this.state.change_cp}
                        onChange={this.handleChange}
                      />
                    </Col>
                    <Col size={{ xxs: 36 }}>
                      <InputCheckbox
                        label={this.props.intl.formatMessage({
                          id: 'app.features.client.api.form.name',
                          defaultMessage: 'Ville',
                        })}
                        name="change_ville"
                        id="change_ville"
                        labelTop={true}
                        checked={this.state.change_ville}
                        onChange={this.handleChange}
                      />
                    </Col>
                    <Col size={{ xxs: 36 }}>
                      <InputCheckbox
                        label={this.props.intl.formatMessage({
                          id: 'app.features.client.api.form.name',
                          defaultMessage: 'Catégorie',
                        })}
                        name="change_categorie"
                        id="change_categorie"
                        labelTop={true}
                        checked={this.state.change_categorie}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </div>
        </div>
      </ResponsiveModalOrForm>
    );
  }
}

export default injectIntl(Siret);
