import { jsonApiNormalizer, normalizedObjectModeler, objectToQueryString } from 'jsonapi-front';
import { FILTER_OPER_SOUND_LIKE } from 'react-bootstrap-front';
import { freeAssoApi } from '../../common';

/**
 * 
 * @param {Object} item 
 * @param {String} mode 
 */
export const checkClient = (item, mode="name") => {
  return new Promise((resolve, reject) => {
    let filters = {};
    if (mode === "email") {
      filters['cli_email'] = {};
      filters['cli_email'][FILTER_OPER_SOUND_LIKE] = item.cli_email
    } else {
      filters['cli_firstname'] = {};
      filters['cli_firstname'][FILTER_OPER_SOUND_LIKE] = item.cli_firstname
      filters['cli_lastname'] = {};
      filters['cli_lastname'][FILTER_OPER_SOUND_LIKE] = item.cli_lastname
    }
    let params = {
      page: { number: 1, size: 100 },
      sort: 'cli_lastname,cli_firstname',
      filter: {and: filters}
    };
    const addUrl = objectToQueryString(params);
    const doRequest = freeAssoApi.get('/v1/asso/client' + addUrl, {});
    doRequest.then(
      (result) => {
        let clients = null;
        if (result && result.data) {
          const list = jsonApiNormalizer(result.data);
          if (list) {
            clients = normalizedObjectModeler(list, 'FreeAsso_Client');
          }
        }
        resolve(clients);
      },
      (err) => {
        reject(err);
      },
    );
  });
}

/**
 * 
 */
 export const getOneClient = id => {
  return freeAssoApi.get('/v1/asso/client/' + id);
}

/**
 * 
 */
export const getOneClientAsModel = id => {
  return new Promise((resolve, reject) => {
    getOneClient(id).then(
      res => {
        const object = jsonApiNormalizer(res.data);
        const item = normalizedObjectModeler(object, 'FreeAsso_Client', id, { eager: true });
        resolve(item);
      },
      err => {
        reject(err);
      },
    );
  });
};

/**
 * 
 */
export const getFullName = (client) => {
  let fullname = '';
  if (client) {
    if (client.cli_firstname) {
      fullname += client.cli_firstname;
    }
    if (client.cli_lastname) {
      fullname += ' ' + client.cli_lastname;
    }
  }
  return fullname.trim();
};