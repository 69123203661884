import { ACCOUNTING_LINE_PROPAGATE } from '../features/accounting-line/redux/constants';
import { AUTH_PROPAGATE } from '../features/auth/redux/constants';
import { CAUSE_MAIN_TYPE_UPDATE_ONE_UPDATE } from '../features/cause-main-type/redux/constants';
import { CAUSE_TYPE_UPDATE_ONE_UPDATE } from '../features/cause-type/redux/constants';
import { CAUSE_PROPAGATE } from '../features/cause/redux/constants';
import { CERTIFICATE_PROPAGATE } from '../features/certificate/redux/constants';
import { CERTIFICATE_GENERATION_PROPAGATE } from '../features/certificate-generation/redux/constants';
import { CLIENT_CATEGORY_UPDATE_ONE_UPDATE } from '../features/client-category/redux/constants';
import { CLIENT_TYPE_UPDATE_ONE_UPDATE } from '../features/client-type/redux/constants';
import { CLIENT_PROPAGATE } from '../features/client/redux/constants';
import { DATA_UPDATE_ONE_UPDATE } from '../features/data/redux/constants';
import { DONATION_PROPAGATE } from '../features/donation/redux/constants';
import { EDITION_PROPAGATE } from '../features/edition/redux/constants';
import { JOBQUEUE_PROPAGATE } from '../features/jobqueue/redux/constants';
import { EMAIL_UPDATE_ONE_UPDATE } from '../features/email/redux/constants';
import { MESSAGE_PROPAGATE } from '../features/message/redux/constants';
import { NOTIFICATION_PROPAGATE } from '../features/notification/redux/constants';
import { PAYMENT_TYPE_UPDATE_ONE_UPDATE } from '../features/payment-type/redux/constants';
import { RECEIPT_PROPAGATE } from '../features/receipt/redux/constants';
import { RECEIPT_GENERATION_PROPAGATE } from '../features/receipt-generation/redux/constants';
import { RECEIPT_TYPE_PROPAGATE } from '../features/receipt-type/redux/constants';
import { SITE_UPDATE_ONE_UPDATE } from '../features/site/redux/constants';
import { SITE_TYPE_UPDATE_ONE_UPDATE } from '../features/site-type/redux/constants';
import { SESSION_PROPAGATE } from '../features/session/redux/constants';
import { SPECIES_UPDATE_ONE_UPDATE } from '../features/species/redux/constants';
import { SPONSORSHIP_UPDATE_ONE_UPDATE } from '../features/sponsorship/redux/constants';
import { SUBSPECIES_UPDATE_ONE_UPDATE } from '../features/subspecies/redux/constants';
import { INBOX_PROPAGATE } from '../features/inbox/redux/constants';

export function propagateCreate(type, id, datas) {
  return propagateModel(type, { data: datas });
}

export function propagateUpdate(type, id, datas) {
  return propagateModel(type, { data: datas });
}

export function propagateDelete(type, id, datas) {
  return dispatch => {};
}

export function propagateModel(type, model, remove = false) {
  return dispatch => {
    switch (type) {
      case 'FreeAsso_AccountingLine':
        dispatch({
          type: ACCOUNTING_LINE_PROPAGATE,
          data: model,
          remove: remove,
        });
        break;
      case 'FreeAsso_CauseMainType':
        dispatch({
          type: CAUSE_MAIN_TYPE_UPDATE_ONE_UPDATE,
          data: model,
          remove: remove,
        });
        break;
      case 'FreeAsso_CauseType':
        dispatch({
          type: CAUSE_TYPE_UPDATE_ONE_UPDATE,
          data: model,
          remove: remove,
        });
        break;
      case 'FreeAsso_Cause':
        dispatch({
          type: CAUSE_PROPAGATE,
          data: model,
          remove: remove,
        });
        break;
      case 'FreeAsso_Certificate':
        dispatch({
          type: CERTIFICATE_PROPAGATE,
          data: model,
          remove: remove,
        });
        break;
      case 'FreeAsso_CertificateGeneration':
        dispatch({
          type: CERTIFICATE_GENERATION_PROPAGATE,
          data: model,
          remove: remove,
        });
        break; 
      case 'FreeAsso_ClientCategory':
        dispatch({
          type: CLIENT_CATEGORY_UPDATE_ONE_UPDATE,
          data: model,
          remove: remove,
        });
        break;
      case 'FreeAsso_ClientType':
        dispatch({
          type: CLIENT_TYPE_UPDATE_ONE_UPDATE,
          data: model,
          remove: remove,
        });
        break;
      case 'FreeAsso_Client':
        dispatch({
          type: CLIENT_PROPAGATE,
          data: model,
          remove: remove,
        });
        dispatch({
          type: DONATION_PROPAGATE,
          data: model,
          remove: remove,
        });
        break;
      case 'FreeAsso_Data':
        dispatch({
          type: DATA_UPDATE_ONE_UPDATE,
          data: model,
          remove: remove,
        });
        break;
      case 'FreeAsso_Donation':
        dispatch({
          type: DONATION_PROPAGATE,
          data: model,
          remove: remove,
        });
        dispatch({
          type: CAUSE_PROPAGATE,
          data: model,
          remove: remove,
        });
        dispatch({
          type: CLIENT_PROPAGATE,
          data: model,
          remove: remove,
        });
        break;
      case 'FreeFW_Edition':
        dispatch({
          type: EDITION_PROPAGATE,
          data: model,
          remove: remove,
        });
        break;
      case 'FreeFW_Email':
        dispatch({
          type: EMAIL_UPDATE_ONE_UPDATE,
          data: model,
          remove: remove,
        });
        break;
      case 'FreeFW_Inbox':
        dispatch({
          type: INBOX_PROPAGATE,
          data: model,
          remove: remove,
        });
        break;
      case 'FreeFW_Message':
        dispatch({
          type: MESSAGE_PROPAGATE,
          data: model,
          remove: remove,
        });
        break;
      case 'FreeFW_Notification':
        dispatch({
          type: NOTIFICATION_PROPAGATE,
          data: model,
          remove: remove,
        });
        break;
      case 'FreeAsso_PaymentType':
        dispatch({
          type: PAYMENT_TYPE_UPDATE_ONE_UPDATE,
          data: model,
          remove: remove,
        });
        break;
      case 'FreeAsso_Receipt':
        dispatch({
          type: RECEIPT_PROPAGATE,
          data: model,
          remove: remove,
        });
        break;
      case 'FreeAsso_ReceiptType':
        dispatch({
          type: RECEIPT_TYPE_PROPAGATE,
          data: model,
          remove: remove,
        });
        break;
      case 'FreeAsso_ReceiptGeneration':
        dispatch({
          type: RECEIPT_GENERATION_PROPAGATE,
          data: model,
          remove: remove,
        });
        break;
      case 'FreeAsso_Session':
        dispatch({
          type: SESSION_PROPAGATE,
          data: model,
          remove: remove,
        });
        break;
      case 'FreeAsso_Site':
        dispatch({
          type: SITE_UPDATE_ONE_UPDATE,
          data: model,
          remove: remove,
        });
        break;
      case 'FreeAsso_SiteType':
        dispatch({
          type: SITE_TYPE_UPDATE_ONE_UPDATE,
          data: model,
          remove: remove,
        });
        break;
      case 'FreeAsso_Species':
        dispatch({
          type: SPECIES_UPDATE_ONE_UPDATE,
          data: model,
          remove: remove,
        });
        break;
      case 'FreeAsso_Sponsorship':
        dispatch({
          type: SPONSORSHIP_UPDATE_ONE_UPDATE,
          data: model,
          remove: remove,
        });
        break;
      case 'FreeAsso_Subspecies':
        dispatch({
          type: SUBSPECIES_UPDATE_ONE_UPDATE,
          data: model,
          remove: remove,
        });
        break;
      case 'FreeFW_Jobqueue':
        dispatch({
          type: JOBQUEUE_PROPAGATE,
          data: model,
          remove: remove,
        });
        break;
      case 'FreeSSO_User':
        dispatch({
          type: AUTH_PROPAGATE,
          data: model,
          remove: remove,
        });
        break;
      default:
        break;
    }
  };
}
