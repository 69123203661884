import { getNewNormalizedObject } from 'jsonapi-front';
import { defaultFilters } from './initFilters';

const initialState = {
  items: getNewNormalizedObject('FreeAsso_Cause'),
  news: [],
  photos: [],
  photosItem: null,
  sponsors: [],
  selected: [],
  page_number: 1,
  page_size: process.env.REACT_APP_PAGE_SIZE,
  tab: '1',
  filters: defaultFilters(),
  sort: [{ col: 'cau_name', way: 'up' }],
  loadMorePending: false,
  loadMoreFinish: false,
  loadMoreError: null,
  loadOnePending: false,
  loadOneItem: null,
  loadOneError: null,
  createOnePending: false,
  createOneError: null,
  updateOnePending: false,
  updateOneError: null,
  delOnePending: false,
  delOneError: null,
  loadPhotosPending: false,
  loadPhotosError: null,
  uploadPhotoPending: false,
  uploadPhotoError: null,
  delCauseMediaPending: false,
  delCauseMediaError: null,
  loadNewsPending: false,
  loadNewsError: null,
  loadOneMediaPending: false,
  loadOneMediaError: null,
  loadOneMediaItem: null,
  loadSponsorsPending: false,
  loadSponsorsError: null,
  updateOneMediaPending: false,
  updateOneMediaError: null,
  createOneMediaPending: false,
  createOneMediaError: null,
  delOneMediaPending: false,
  delOneMediaError: null,
  printOnePending: false,
  printOneError: null,
  checkOneMediaPending: false,
  checkOneMediaError: null,
  exportPending: false,
  exportError: null,
};

export default initialState;
