export default `
# TODO

* Attestation de don pour tous les membres qui ne sont pas du pays de l'association, cf mail Constance
    * Prévoir une liste de pays pour la génération des reçus
* Gestion du fond de dotation
* A la génération des reçus ajouter les certificats des dons régulier réserve

* Je crois me souvenir qu'on avait évoqué à plusieurs reprises le fait de mettre un remerciement et redirection vers le site internet une fois un achat ou don fait sur notre boutique en ligne. Sauf erreur on ne l'a toujours pas fait, il faudrait qu'on mette ça en place.


* Trouver un système, pour, par défaut, bloquer le dépassemment du montant total pouvant être parrainé
* Carte de membre
* Sur les paiements avec certificat, proposer d'export le certificat
* Gestion des modifications de dons / certificats : montant rectificatf, ... trouver une idée
* Export des Parrainages n'ayant pas eu de mise à jour de fiche depuis 1an
* Ajouter les colonnes de type email (paiements et parrains)
* Pouvoir copier tous les parrains (emails), en une seule fois dans le presse papier
* Paramétrage de tous les emails via la configuration
* Relancer l'envoi des emails qui sont en échec de connexion.
* Types de paiement par association
* Vérifier le destinataire email des certificat (champ présent sur certificat)
* Ajouter les information de reply sur les associations
* Pouvoir forcer la saisie du montant Euros si la monnaie est différente

* Technique - Dev
    * include sur toutes les features + updateOne
    * LoadAll avec items spécifiques

# Bugs

...

# Fiche Version

## 2.20.2 : 26/02/2024

* Possibilité de regénérer un reçu et le stocker suite à de petites modifications
* Les fichiers à conserver ne sont plus présents

## 2.20.1 : 23/02/2024

* Optimisations reçus

## 2.20.0 : 13/02/2024

* Adaptations codes cog par association
* Statistiques

## 2.19.3 : 27/01/2024

* Seconde signature disponible sur les associations

## 2.19.2 : 07/01/2024

* Compléments sur les pays
* Notification pour les certificats de fin d'année
* Possibilité de filtrer les générations de reçu par pays (code cog)
* Possibilité de bloquer les notifications pour des dons.
* Affichage des certificats sur les donateurs

## 2.18.2 : 17/12/2023

* Pour la relance des dons poctuels on ne tient pas compte d'un éventuel autre don sur le même parrainage. La relance est donc systématique.
* Fin d'un don régulier avec cdertificat
    * Correction d'un bug sur l'ajout de la pièce jointe au mail.
    * Augmentation du timeout à 120s pour la génération du PDF
* Intégration du montant en gras dans les certificats pour la forêt

## 2.18.1 : 28/11/2023

* Possibilité de changer l'affectation d'un don

## 2.18.0 : 28/05/2023

* Gestion des dates simples pour les dons et parrainages (pb de recherche)

## 2.17.6 : 14/05/2023

* Regénération des PDF des reçus

## 2.17.5 : 09/05/2023

* Correction de la suppression des PJs sur les notifications

## 2.17.4 : 24/04/2023

* Reçus triables par nom / prénom
$ Possibilité de ne pas envoyer la notification sur création de don

## 2.17.2 : 18/03/2023

* Ordre des reçus

## 2.17.1 : 01/03/2023

* Export des PDF des reçus

## 2.17.0 : 28/02/2023

* Génération de reçu possible par type de paiement

## 2.16.0 : 22/02/2023

* Correction fiche parrainage
* Blocage des modifications de dons si associartion différente
* Bugs mineurs

## 2.15.4 : 18/02/2023

* Mises à jour technique

## 2.15.3 : 29/01/2023

* Dons réguliers
    * Les anomalies de saisie sont de nouveau liées aux champs.
* Reçus
    * Saisie de reçus manuels
* Programmes
    * Affichage des dons réguliers / ponctuels pour toutes les associations

## 2.15.0 : 15/01/2023

* Reçus
    * Gestion des reçus de type Cerfa (en test)
* Types de reçu
    * Gestion d'une numérotation spécifique
    * Correction d'un bug à l'enregistrement
* Causes
    * Correction d'un bug de calcul de la somme restante, se base sur mois en cours et non plus les dates.
* Donateurs
    * Ajout de la recherche des codes SIREN via l'API sierene de l'INSEE
    * Ajout du numéro et du nom de rue pour les reçus de type cerfa
    * Ajout du numéro et nom de rue pour les reçus de type cerfa

## 2.14.5 : 27/12/2022

* Donateurs
    * Ajout des programmes en recherche
* Paiements
    * Ajout des programmes en recherche
* Reçus
    * Champs bloqués en modification

## 2.14.4 : 11/06/2022

* Général
    * Supression du nombre chargé dans les totaux des listes
    * Il manquait l'icône pour accéder au tri dans les listes
    * Utilisation des libellés "Postérieur" et "Antérieur" dans les filtres de recherche 
* Boîte de réception des fichiers
    * Correction de la suppression
    * Rechargement en cas de suppression
* Reçus
    * Il manquait le bouton de suppression dans le panneau de recherche
    * Ajout d'une fenêtre de recherche simplifiée
    * Correction du tri par défaut (année, donateur)
    * Option permettant l'export d'un fichier excel simplifié (Générations)
* Dons réguliers
    * Correction d'un bug de création du premier paiement si dans le futur

## 2.14.3 : 30/04/2022

* Paiements régulier
    * Correction d'un bug à l'enregistrement de la date de fin (pb injecté dans la version précédente)
* Donateurs
    * Recherche sur la date de création du don régulier

## 2.14.2 : 15/04/2022

* Général
    * Activation des nouvelles notifications
* Paiements
    * Remise en route de l'option pour ré-envoyer l'email de création
* Fin de parrainage
    * Au besoin on génère un certificat depuis le 1er janvier
* Impressions
    * Séparation de l'adresse sur une ligne par des "-"

## 2.14.1 : 24/02/2022

* Paiements
    * Correction de la recherche des paiements
* Reçus
    * Gestion des droits des PJs
* Emails
    * Ajout de la langue sans le template

## 2.14.0 : 20/02/2022

* Notifications
    * Le template repnd en compte les impressions et les mobiles
    * Gestion du quota de 100 emails / h
    * Gestion des reprises en cas d'erreur monté à 3
* Génération des reçus
    * Export + expéditions
    * Nouveaux champs de date
* Boîte de réception
    * Possibiliét de modifier et conserver les documents

## 2.13.4 : 19/02/2022

* Reçus
    * Génération avec options d'export
* Boîte de réception
    * Nouvelle gestion

## 2.13.3 : 17/02/2022

* Paiements
    * Correction de la recherche simple

## 2.13.2 : 13/02/2022

* Reçus
    * Adresse postale compressée sur 6 lignes maximum en majuscules sans accents
* Notifications
    * Ajout d'une colonne pour indiquer les traductions disponibles
    * Template disponible (en test)
* Paiements
    * Début de gestion des associations

## 2.13.1 : 07/02/2022

* Parrainages
    * Correction d'un bug d'affichage de la fiche

## 2.13.0 : 06/02/2022

* Donateurs
    * Ajout de la recherche par la langue
* Parrainages
    * Correction de la limite de la taille du texte sur la fiche parrainage
* Reçus
    * Correction de la recherche rapide
    * Génération des reçus finalisée pour tests
* Editions
    * Ajout de l'option pour récupérer le fichier d'une langue spécifique
    * Reçu France (FR / EN)
    * Reçu Suisse (FR)
* Divers
    * Pays traduits en anglais
    * Types de paiement traduits en anglais

## 2.12.2 : 31/01/2022

* Notifications
    * Test avec choix de la langue et utilisation de l'association en cours

## 2.12.0 : 30/01/2022

* Reçus
    * Gestion du paramétrage entre les différentes associations
    * Gestion du typage
    * Amélioration de la génération et de la suppression de la génération
    * Reçu Suisse
* Certificats
    * Début de gestion de la génération annuelle

## 2.11.1 : 27/01/2022

* Général
    * Nouvelles notifications en test

## 2.11.0 : 26/01/2022

* Général
    * Ajout de nouvelles fonctions sur la saisie de texte formaté
    * Optimisations
* Parrainage
    * Optimisation du montant restant en cas de conversion
* Gestion d'association
    * Suppression des onglets spécifiques "français"
    * Ajout d'un onglet de gestion des traductions
* Paiements
    * Jamais de notification pour la création d'un paiement pour un don régulier

## 2.10.1 : 16/01/2022

* Général
    * Meilleur affichage de la zone de recherche rapide
* Paiements régulier
    * Correction de la mise à jour des montants sur les causes
* Paiements 
    * Correction de la mise à jour des montants sur les causes
    * En création correction de la gestion de la date de fin

## 2.9.0 : 11/01/2022

* Programmes
    * Gestion du paramétrage des notifications
* Donateurs
    * Plus de blocage base pour les doublons, géré dans le formulaire uniquement
    * Ajout de l'association en affichage
    * Ajout du pays dans la recherche simplifiée
* Paiements réguliers
    * Gestion du blocage des boutons en cas d'enregistrement
    * Correction d'un bug d'enregistrement des paiements réguliers sur le mois en cours si jour > jour en cours
* Paiements
    * Téléchargement du certificat si présent
    * Onglet certificat caché pour les paiements liés à des paiements réguliers
    * Ajout du pays dans la recherche simplifiée
* Certificats
    * Gestion de l'affichage du montant sur le certificat sur option
* Reçus
    * Download du pdf du reçu
    * Gestion de la gébération et de l'édition en test
* Accueil
    * Ajout d'un bloc pour gestion de l'association et de son paramétrage
* Editions
    * On propose de choisir la langue d'édition 
    * Mise en place des champs de fusion de monnaie, ...

## 2.7.1 : 16/12/2021

* Donateur
    * Bug dans la recherche d'homonymes (plantage)
* Editions
    * Prise en compte de la langue

## 2.7.0 : 06/12/2021

* Donateurs
    * Liste et nouvelle recherche spécifique
* Paiements réguliers
    * Ajout et calcul de la date d'enregistrement et de l'indicateur de création du paiement le mois en cours
* Paiements
    * Système de validation (en cours)
* Libellés
    * Plus de résultat(s)

## 2.6.1 : 30/11/2021

* Paiements
    * Correction de la surcharge de l'invité pour un certificat
    * En création les surcharges d'un certificat sont sur le premier onglet
    * Séparateur d'année sur les listes des donateurs

## 2.6.0 : 29/11/2021

* Général
    * Amélioration des notifications pour ajouter plus d'informations
    * Suppression des notifications pour des donateurs sans email
    * Correction des valeurs par défaut des invités (par défaut affichés et nouvelles si email renseigné)
* Périodes
    * Ajout de la gestion de groupe pour avoir une distinction entre les associations
* Donateurs
    * Recherche rapide possible en contient ou ressemble et avec option de RAZ
* Programmes
    * Recherche rapide possible en contient ou ressemble et avec option de RAZ
* Paiements
    * Prise en compte de la date de début dans les calculs des parrainages
    * L'indicateur d'animal mort ou relaché ne tient plus compte de la date
    * Suppression des notification de fin pour des donateurs sans email
    * Recherche rapide en contient uniquement, montant en égalité et avec option de RAZ
* Paiements régulier
    * "Paiement le mois en cours" remplacé par "Inclure mois en cours"
    * Ajout d'un indicateur noir si l'animal est considéré mort ou relaché

## 2.5.0 : 24/11/2021

* Paiements
    * Gestion du "flag" "Nouvelles" pour les parrainages ponctuels
    * Onglet Invités disponible uniquement pour les parrainages ponctuels
* Email
    * Email de test

## 2.4.10 : 17/11/2021

* Paiements
    * Tri par date de don décroissante

## 2.4.9 : 14/11/2021

* Général :
    * Plus de tailles au niveau des modales
    * Meilleure gestion de l'opacité en cas d'empilement de modales
    * Ajout des messages dans le panneau de droite avec un compteur
* Accueil
    * Correction de l'icône du tableau de bord pour les tâches planifiées
* Paiements réguliers
    * Le jour est bloqué au jour du premier don, mais on peut le débloquer à l'aide du cadenas
    * Le premier onglet se nomme "paiement régulier"
    * Le titre de la fenêtre comprend le donateur et l'affectation du don pour plus de lisibilité
    * Ajout d'un onglet paiement pour ajouter des paiements "manuels" sur un paiement régulier
* Paiements
    * Gestion de la création à partir d'un paiement régulier en "manuel"
    * Le titre de la fenêtre comprend le donateur et l'affectation du don pour plus de lisibilité
* Changements de libellés
    * Pour les paiements réguliers, la date de "Début" est renommée en "Date premier don"
* Emails
    * Seuls les emails en cours sont marqués avec un trait vertical vert

## 2.4.8 : 12/11/2021

* Général :
    * Les informations à destination du site public sont converties en numérique pour les compteurs
    * Les boutons d'actions des formulaires (enregistrer / annuler / ...) sont désactivés le temps de l'enregistrement
* Contrôles
    * Les anomalies de don sont considérées comme des notifications de type "Warning"
* Messages
    * Les messages de type informations sont automatiquement marqués lu ou bout de 2j
    * Les messages sont supprimés au bout d'1 an. 
* Parrainages
    * Ajout d'une colonne pour le motif de Fin : Mort / Libéré
    * Correction d'un bug qui réinitialisait les filtres au chargement progressif
* Tâches planifiées
    * Accès au formulaire de modification depuis le tableau de bord
* Panneau droit
    * La liste des documents réceptionnés est "scrollable"
    * Les documents "downloadés" sont purgés après 45j
* Paiements
    * Obligation de saisir un motif en cas de modification d'un paiement sur une période non "ouverte"
    * Le filtre type de paiement est maintenant une "combo"
    * Correction d'un bug de reprise (inversion date de don / date de début)
* Changements de libellés
    * "Historique" a été renommé en "Traces" 

## 2.4.7 : 09/11/2021

* Programmes
    * Paramétrage des libellés du premier onglet en fonction du "type" de programme
* Parrainages
    * Lors de la création d'un parrainage dans le passé, génération d'un don par mois, mois en cours paramétrable
    * Outil de contrôle lancé chaque mois : génération d'un message en cas d'anomalie
* Tableau de bord
    * Format des "cards"
* Exports
    * Paramétrage des colonnes

## 2.4.6 : 03/11/2021

* Général
    * Rerchargement de l'application au clic sur Kalaweit dans la barre de titre
* Recherche
    * Les recherches rapides gèrent maintenant en plus un contient pour ne pas perdre de résultats
* Parrainages
    * Les parrainages finis sont affichés avec une couleur bleu foncée / noire
* Dons
    * Les dons ponctuels pour des parrainages finis sont affichés avec une couleur bleu foncée / noire
    * Les dons impayés sont affichés avec une couleur rouge.
* Périodes
    * La gestion des périodes se fait au mois.
    * La session du mois correspondant à la date du don est utilisée par défaut
* Changements de libellés
    * "Session" renommé en "Période" sur la fiche des paiements

## 2.4.5 : 01/11/2021

* Général
    * Ajout de l'aide : html, mp4, ... Ainsi que des informations sur le navigateur en cours
    * L'aide en format "tour" est maintenant accessible depuis le panneau droite d'aide
* Listes
    * Gestion des affichages en taille sm et md, les icônes de création, ... n'étaient plus visibles
    * Chargement automatique lors du scroll 
* Donateurs
    * Adaptation des colonnes de la liste pour rester sur une ligne entre sm et xl

## 2.4.4 : 28/10/2021

* Parrainages
    * Correction d'un bug en création d'un parrainage

## 2.4.3 : 28/10/2021

* Général
    * Gestion de l'aide ?
* Changements de libellés
    * "Parrains" renommé en "Invités" sur la fiche des paiements

## 2.4.2 : 25/10/2021

* Changements de libellés
    * L'onglet "Fiche Nouvelles" a été renommé en "Nouvelles"
    * Le champ "Date dernière fiche" a été renommé en "Date dernière nouvelle"
* Donateurs
    * Option permettant d'envoyer un email sur une sélection
* Paiements
    * Option pemettant de gérer des éditions

## 2.4.1 : 25/10/2021

* Paiements
    * Gestion des 4 dates :
        * Date du don pour la date réelle du don
        * Date d'enregistrement pour la date d'enregistrement système du don dans l'administration
        * Date de début et fin uniquement pour les parrainages ponctuels
* Donateurs
    * Ajout des emails dans la recherche rapide

## 2.4.0 : 24/10/2021

* Look & comportement général
    * Augmentation de la nuance entre les lignes paires et impaires dans les listes
    * L'affichage mode "mobile" sour forme de "cartes" a été adapté pour surligner les informations paires et impaires
    * Les recherches pour les sélecteurs, sont maintenant, par défaut, en contient
    * Correction de l'effacement des critères dans les recherches des sélecteurs
    * Adaptation de la présentation de la fenêtre de recherche dans les recherches des sélecteurs
    * Modification de l'image pour les combos
    * Adaptation de la barre de titre des listes pour organiser et simplifier les options
    * Recherche en mode "entre deux valeurs" pour les montants et dates
    * Nouvelle tentative pour forcer le reload de la page courante
* Changements de libellés
    * "Site" a été renommé en "Localisation"
    * "Type de site" a été renommé en "Type de localisation"
    * Onglet "Contrôles" renommé en "Historique"
    * "Imprimé le" pour les certificats, renommé en "Envoyé le"
    * Il restait les libellés 'Parrainages" et "Dons", renommés en "paiement régulier" et "paiement"
    * Le premier onglet sur les "programmes" correspond au nom du programme. On ne met plus "Identité"
    * L'onglet Nouvelles sur les parrainages avec nouvelles et été renommé en "Fiches nouvelles"
* Parrainages
    * La fenêtre de recherche se charge par défaut avec les 10 premiers
    * Le montant réuni est affiché même si l'animal est mort ou relâché, le montant restant est marqué avec ' - '
    * Dans la fenêtre de recherche il manque l'indicateur pour Mort ou Relâché
    * Le sélecteur ne propose plus, par défaut, les parrainages terminés
* Types de programmes
    * La fenêtre passe de lg à md
* Donateurs
    * Ajout de la colonne "Code Postal", disponible aussi en tri et recherche
    * Si l'email est incorrect (formattage), il sera affiché en rouge
    * La fenêtre de recherche se charge par défaut avec les 10 premiers
    * Gestion des homonymes (nom prénom / email)
    * Page d'aide
* Localisations 
    * La fenêtre de recherche se charge par défaut avec les 10 premiers
* Liste des paiements
    * On n'affiche plus de dates de fin pour un paiement issu d'un paiement régulier
* Paiements
    * La date réelle du paiement est de nouveau disponible en affichage et modification sur la fiche paiement
    * La date de fin d'un don ponctuel est disponible en modification même pour des dons assignés à des reçus.
    * Changement de la disposition des champs pour harmoniser le tout
* Notifications
    * Gestion de la copie cachée (BCC)

## 2.3.2 : 20/10/2021

* Site
    * Correction du nom pour le total des animaux pour affichage sur le site
* Certificats
    * Ajout de la date de création, utile si aucune autre date n'est renseignée
    * Recherche rapide sur nom, prénom ou email
    * Ajout d'un "flag" manuel
* Reçus
    * Ajout d'un "flag" manuel
* Programmes
    * Ajout de la monnaie du certificat pour les programmes avec certificat 
    * Il manquait la cause pour la date de fin : Mort / Relâché
* Paiements
    * Correction d'un bug sur l'affichage de la liste
    * Option "Afficher sur le site" que pour les animaux
* Paiements réguliers
    * Option "Afficher sur le site" que pour les animaux
* Emails envoyés
    * Ajout du panneau de recherche
    * Recherche rapide sur destinataire, sujet, contenu

## 2.3.0 : 18/10/2021

* Général
    * Uniformisation de l'animation d'attente
    * Remplacement de l'éditeur de texte : quill => prosemirror
    * Si la version n'est pas à jour, un icône apparaît en rouge dans la barre de titre
* Tableau de bord
    * Correction d'un bug de validation des messages en cours lorsqu'il n'en restait qu'un 
    * Finalisation du zoom sur les différents objets depuis les messages
    * Suppression de l'historique, disponible via le menu contrôles
    * Ajout d'une zone de recherche rapide vers les donateurs dans le bloc "donateur"
    * Ajout d'une zone de recherche rapide vers les parrainages dans le bloc "animaux"
* Cause
    * Ajout d'une date de dernière nouvelle
    * Modification du tri des nouvelels, présentation basculée en fin.
* Dons
    * Uniformisation des listes
    * On n'affiche plus de date de fin pour les dons de type "illimités"
* Paiements
    * Ajout d'un ciône dans les listes pour passer en impayé 

## 2.2.1 : 11/10/2021

* Général
    * Correction des sélecteurs (Pickers)
* Paiements
    * Ajout d'un indicateur de couleurs pour les impayés
* Site
    * Correction du formulaire de recherche et adaptation de la liste en mode mobile
* Paiements régulier
    * Correction du message en cas de suppression d'un don régulier avec dons

## 2.2.0 : 10/10/2021

* Général
    * Distinction et warning si vesion de test, ... (en-tête orange)
    * Rechargement automatique de la page s'il ne s'agit pas de la dernière version.
* Listes
    * En mode partagé (liste + infos) on bloque la largeur de la liste à 350px

## 2.1.2 : 07/10/2021

* Ajout de la fiche version
* Suppression du panneau historique du tableau de bord (accessible depuis la partie "Contrôles")
* A la création d'un bénéficiaire, on propose par défaut le programme en cours.
* Réduction de la taille des fenêtres de saisie des bénéficiares, donateurs et paiements (medium)
* Ajout du totaliseur sur la liste des paiements
* Ajout du numéro de version dans le titre
* Le "sélecteur" de bénéficiaire a été optimisé pour affiché la somme récoltée et le programme.
* Les types de paiement expirés ne sont pas proposés en création
* Uniformisation de l'affichage des listes

## 2.0.0 : 06/10/2021

* Nouvelle version de l'administration Kalaweit

# Updates

## Pb reprise 12/11/2021

Dons générés par la reprise avec inversion don_ts / don_real_ts

update asso_donation set don_real_ts = don_ts where don_id <= 1498026 and don_ts != don_real_ts;



`