import React from 'react';
import PropTypes from 'prop-types';
import { SmallKalaLoader } from './';

export default function KalaLoader(props) {
  if (props.show) {
    return <SmallKalaLoader />;
  } else {
    return null;
  }
}

KalaLoader.propTypes = {
  portal: PropTypes.bool,
  show: PropTypes.bool,
};
KalaLoader.defaultProps = {
  portal: false,
  show: true,
};
