import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InlineLine } from './';

export default class InlineList extends Component {
  static propTypes = {
    cols: PropTypes.array,
    items: PropTypes.array,
  };
  static defaultProps = {
    cols: [],
    items: [],
  };

  render() {
    if (this.props.children) {
      return <div className="inline-list">{this.props.children}</div>;
    } else {
      return (
        <div className="inline-list">
          {this.props.items &&
            this.props.items.map(item => {
              return <InlineLine {...this.props} item={item} key={`item-${item.id}`} />;
            })}
        </div>
      );
    }
  }
}
