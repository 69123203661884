import React from 'react';
import { injectIntl } from 'react-intl';
import { InputText, InputSelect, InputCheckbox } from 'react-bootstrap-front';
import {
  useForm,
  InputDate,
  InputDatetime,
  InputSpin,
  InputTextarea,
  ResponsiveModalOrForm,
  InputCheckList,
} from '../ui';
import { InputPicker as UserInputPicker, getFullName } from '../user';
import { alertPriority, alertRecurType, alertRemind, getAlertRecur } from './';

const objects = [
  { value: '', label: 'Aucun' },
  { value: 'POSSO_Group', label: 'Entité' },
  { value: 'POSSO_User', label: 'Utilisateur' },
  { value: 'POPM_Feature', label: 'Carte' }
];

const afterChange = (name, item) => {
  switch (name) {
    case 'object':
      if (!(item.alert_title && item.alert_title !== '')) {
        switch (item.alert_object_name) {
          case 'POSSO_User':
            item.alert_title = getFullName(item.object);
            break;
          default:
            break;
        }
      }
      break;
    case 'alert_from':
      const dateFrom1 = new Date(item.alert_from) || null;
      const dateTo1 = new Date(item.alert_to) || null;
      if (dateTo1 === null || dateTo1 < dateFrom1) {
        item.alert_to = item.alert_from;
      }
      break;
    case 'alert_to':
      const dateFrom2 = new Date(item.alert_from) || null;
      const dateTo2 = new Date(item.alert_to) || null;
      if (dateFrom2 === null || dateTo2 < dateFrom2) {
        item.alert_from = item.alert_to;
      }
      break;
    case 'alert_recur_type':
      if (item.alert_recur_type !== 'NONE') {
        item.alert_recur_number = Number(item.alert_recur_number);
        if (Number.isNaN(item.alert_recur_number) || item.alert_recur_number < 1) {
          item.alert_recur_number = 1;
        }
      }
      break;
    default:
      break;
  }
};

function Form(props) {
  if (props.cause) {
    props.item.cause = props.cause;
  }
  const {
    values,
    handleChange,
    handleSubmit,
    handleCancel,
    handleNavTab,
    getErrorMessage,
  } = useForm(
    props.item,
    props.tab,
    props.onSubmit,
    props.onCancel,
    props.onNavTab,
    props.errors,
    props.intl,
    afterChange,
  );
  const today = new Date();
  if (!values.alert_from) {
    values.alert_from = today;
  }
  if (!values.alert_to) {
    values.alert_to = today;
  }
  let libRecur = getAlertRecur(values);
  const recurDisabled = (values.parent && values.parent.id && parseInt(values.parent.id, 10) > 0) ? true : false;
  return (
    <ResponsiveModalOrForm
      title={values.alert_title}
      tab={values.currentTab}
      tabs={props.tabs}
      size="lg"
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onNavTab={handleNavTab}
      onClose={props.onClose}
      modal={props.modal || false}
    >
      <div className="row">
        <div className="col-xs-w9">
          <InputDatetime
            label="Du"
            name="alert_from"
            labelTop={true}
            required={true}
            value={values.alert_from}
            onChange={handleChange}
            error={getErrorMessage('alert_from')}
          />
        </div>
        <div className="col-xs-w9">
          <InputDatetime
            label="au"
            name="alert_to"
            labelTop={true}
            required={true}
            value={values.alert_to}
            onChange={handleChange}
            error={getErrorMessage('alert_to')}
          />
        </div>
        <div className="col-xs-w6">
          <InputSelect
            label="Priorité"
            id="alert_priority"
            name="alert_priority"
            value={values.alert_priority}
            defaultValue="NONE"
            onChange={handleChange}
            options={alertPriority}
            error={getErrorMessage('alert_priority')}
          />
        </div>
        <div className="col-xs-w3">
          <InputCheckbox
            label="Actif"
            name="alert_activ"
            labelTop={true}
            checked={values.alert_activ === true}
            onChange={handleChange}
          />
        </div>
        <div className="col-xs-w9">
          <InputDate
            label="Echéance"
            name="alert_deadline"
            labelTop={true}
            value={values.alert_deadline || ''}
            onChange={handleChange}
            error={getErrorMessage('alert_deadline')}
          />
        </div>
      </div>
      <hr />
      {values.currentTab === '1' && (
        <div>
          <div className="row">
            <div className="col-xs-w27">
              <InputText
                label="Libellé"
                name="alert_title"
                key="alert_title"
                labelTop={true}
                value={values.alert_title}
                required={true}
                onChange={handleChange}
                error={getErrorMessage('alert_title')}
              />
            </div>
            <div className="col-xs-w9">
              <InputDatetime
                label="Réalisée le"
                name="alert_done_ts"
                labelTop={true}
                value={values.alert_done_ts}
                onChange={handleChange}
                error={getErrorMessage('alert_done_ts')}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-w7">
              <InputSelect
                label="Elément lié"
                id="alert_object_name"
                name="alert_object_name"
                value={values.alert_object_name}
                defaultValue=""
                onChange={handleChange}
                options={objects}
                error={getErrorMessage('alert_object_name')}
              />
            </div>
            <div className="col-xs-w20">
              {
                {
                  POSSO_User: (
                    <UserInputPicker
                      label="Utilisateur"
                      key="object"
                      name="object"
                      item={values.object || null}
                      onChange={handleChange}
                      labelTop={true}
                      required={true}
                      error={getErrorMessage('object')}
                    />
                  ),
                }[values.alert_object_name]
              }
            </div>
            <div className="col-xs-w9">
              <UserInputPicker
                label="Utilisateur"
                key="user"
                name="user"
                pickerUp={true}
                addMail={false}
                item={values.user || null}
                onChange={handleChange}
                error={getErrorMessage('user')}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-w36">
              <InputTextarea
                label="Commentaires"
                name="alert_done_text"
                value={values.alert_done_text}
                onChange={handleChange}
                labelTop={true}
                error={getErrorMessage('alert_done_text')}
              />
            </div>
          </div>
        </div>
      )}
      {values.currentTab === '2' && (
        <div>
          <div className="row">
            <div className="col-xs-w26">
              <InputCheckList
                label=""
                name="alert_checklist"
                value={values.alert_checklist}
                onChange={handleChange}
                error={getErrorMessage('alert_checklist')}
              />
            </div>
          </div>
        </div>
      )}
      {values.currentTab === '3' && (
        <div>
          <div className="row">
            <div className="col-xs-w36">
              <InputTextarea
                label="Description"
                name="alert_text"
                value={values.alert_text}
                onChange={handleChange}
                labelTop={true}
                error={getErrorMessage('alert_text')}
              />
            </div>
          </div>
        </div>
      )}
      {values.currentTab === '4' && (
        <div>
          <div className="row">
            <div className="col-xs-w6">
              <InputSelect
                label="Récurrence"
                id="alert_recur_type"
                name="alert_recur_type"
                value={values.alert_recur_type}
                defaultValue="NONE"
                onChange={handleChange}
                options={alertRecurType}
                disabled={recurDisabled}
                error={getErrorMessage('alert_recur_type')}
              />
            </div>
            <div className="col-xs-w12">
              {libRecur !== '' && (
                <InputSpin
                  label={libRecur}
                  name="alert_recur_number"
                  id="alert_recur_number"
                  value={values.alert_recur_number}
                  maxValue={99}
                  minValue={0}
                  onChange={handleChange}
                  labelTop={true}
                  disabled={recurDisabled}
                  error={getErrorMessage('alert_recur_number')}
                />
              )}
            </div>
            <div className="col-xs-w18">
              <InputSelect
                label="Premier rappel avant échéance"
                name="alert_email_1"
                labelTop={true}
                options={alertRemind}
                value={values.alert_email_1}
                onChange={handleChange}
                error={getErrorMessage('alert_email_1')}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-w18" />
            <div className="col-xs-w18">
              <InputSelect
                label="Deuxième rappel avant échéance"
                name="alert_email_2"
                labelTop={true}
                options={alertRemind}
                value={values.alert_email_2}
                onChange={handleChange}
                error={getErrorMessage('alert_email_2')}
              />
            </div>
          </div>
        </div>
      )}
    </ResponsiveModalOrForm>
  );
}

export default injectIntl(Form);