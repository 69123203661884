export const TRANSLATION_CLEAR_ITEMS = 'TRANSLATION_CLEAR_ITEMS';
export const TRANSLATION_CREATE_ONE_BEGIN = 'TRANSLATION_CREATE_ONE_BEGIN';
export const TRANSLATION_CREATE_ONE_SUCCESS = 'TRANSLATION_CREATE_ONE_SUCCESS';
export const TRANSLATION_CREATE_ONE_FAILURE = 'TRANSLATION_CREATE_ONE_FAILURE';
export const TRANSLATION_CREATE_ONE_DISMISS_ERROR = 'TRANSLATION_CREATE_ONE_DISMISS_ERROR';
export const TRANSLATION_DEL_ONE_BEGIN = 'TRANSLATION_DEL_ONE_BEGIN';
export const TRANSLATION_DEL_ONE_SUCCESS = 'TRANSLATION_DEL_ONE_SUCCESS';
export const TRANSLATION_DEL_ONE_FAILURE = 'TRANSLATION_DEL_ONE_FAILURE';
export const TRANSLATION_DEL_ONE_DISMISS_ERROR = 'TRANSLATION_DEL_ONE_DISMISS_ERROR';
export const TRANSLATION_EXPORT_AS_TAB_BEGIN = 'TRANSLATION_EXPORT_AS_TAB_BEGIN';
export const TRANSLATION_EXPORT_AS_TAB_SUCCESS = 'TRANSLATION_EXPORT_AS_TAB_SUCCESS';
export const TRANSLATION_EXPORT_AS_TAB_FAILURE = 'TRANSLATION_EXPORT_AS_TAB_FAILURE';
export const TRANSLATION_EXPORT_AS_TAB_DISMISS_ERROR = 'TRANSLATION_EXPORT_AS_TAB_DISMISS_ERROR';
export const TRANSLATION_EXPORT_BEGIN = 'TRANSLATION_EXPORT_BEGIN';
export const TRANSLATION_EXPORT_SUCCESS = 'TRANSLATION_EXPORT_SUCCESS';
export const TRANSLATION_EXPORT_FAILURE = 'TRANSLATION_EXPORT_FAILURE';
export const TRANSLATION_EXPORT_DISMISS_ERROR = 'TRANSLATION_EXPORT_DISMISS_ERROR';
export const TRANSLATION_INIT_FILTERS = 'TRANSLATION_INIT_FILTERS';
export const TRANSLATION_INIT_SORT = 'TRANSLATION_INIT_SORT';
export const TRANSLATION_LOAD_MORE_INIT = 'TRANSLATION_LOAD_MORE_INIT';
export const TRANSLATION_LOAD_MORE_BEGIN = 'TRANSLATION_LOAD_MORE_BEGIN';
export const TRANSLATION_LOAD_MORE_SUCCESS = 'TRANSLATION_LOAD_MORE_SUCCESS';
export const TRANSLATION_LOAD_MORE_FAILURE = 'TRANSLATION_LOAD_MORE_FAILURE';
export const TRANSLATION_LOAD_MORE_DISMISS_ERROR = 'TRANSLATION_LOAD_MORE_DISMISS_ERROR';
export const TRANSLATION_LOAD_ONE_BEGIN = 'TRANSLATION_LOAD_ONE_BEGIN';
export const TRANSLATION_LOAD_ONE_SUCCESS = 'TRANSLATION_LOAD_ONE_SUCCESS';
export const TRANSLATION_LOAD_ONE_FAILURE = 'TRANSLATION_LOAD_ONE_FAILURE';
export const TRANSLATION_LOAD_ONE_DISMISS_ERROR = 'TRANSLATION_LOAD_ONE_DISMISS_ERROR';
export const TRANSLATION_ON_SELECT = 'TRANSLATION_ON_SELECT';
export const TRANSLATION_PRINT_ONE_BEGIN = 'TRANSLATION_PRINT_ONE_BEGIN';
export const TRANSLATION_PRINT_ONE_SUCCESS = 'TRANSLATION_PRINT_ONE_SUCCESS';
export const TRANSLATION_PRINT_ONE_FAILURE = 'TRANSLATION_PRINT_ONE_FAILURE';
export const TRANSLATION_PRINT_ONE_DISMISS_ERROR = 'TRANSLATION_PRINT_ONE_DISMISS_ERROR';
export const TRANSLATION_PROPAGATE = 'TRANSLATION_PROPAGATE';
export const TRANSLATION_SELECT_NONE = 'TRANSLATION_SELECT_NONE';
export const TRANSLATION_SELECT_ALL = 'TRANSLATION_SELECT_ALL';
export const TRANSLATION_SET_CURRENT = 'TRANSLATION_SET_CURRENT';
export const TRANSLATION_SET_PREVIOUS = 'TRANSLATION_SET_PREVIOUS';
export const TRANSLATION_SET_NEXT_BEGIN = 'TRANSLATION_SET_NEXT_BEGIN';
export const TRANSLATION_SET_NEXT_SUCCESS = 'TRANSLATION_SET_NEXT_SUCCESS';
export const TRANSLATION_SET_NEXT_FAILURE = 'TRANSLATION_SET_NEXT_FAILURE';
export const TRANSLATION_SET_NEXT_DISMISS_ERROR = 'TRANSLATION_SET_NEXT_DISMISS_ERROR';
export const TRANSLATION_SET_FILTERS = 'TRANSLATION_SET_FILTERS';
export const TRANSLATION_SET_SORT = 'TRANSLATION_SET_SORT';
export const TRANSLATION_UPDATE_ONE_BEGIN = 'TRANSLATION_UPDATE_ONE_BEGIN';
export const TRANSLATION_UPDATE_ONE_SUCCESS = 'TRANSLATION_UPDATE_ONE_SUCCESS';
export const TRANSLATION_UPDATE_ONE_FAILURE = 'TRANSLATION_UPDATE_ONE_FAILURE';
export const TRANSLATION_UPDATE_ONE_DISMISS_ERROR = 'TRANSLATION_UPDATE_ONE_DISMISS_ERROR';
export const TRANSLATION_UPDATE_QUICK_SEARCH = 'TRANSLATION_UPDATE_QUICK_SEARCH';
export const TRANSLATION_UPDATE_SORT = 'TRANSLATION_UPDATE_SORT';
