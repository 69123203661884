export const JOBQUEUE_CLEAR_ITEMS = 'JOBQUEUE_CLEAR_ITEMS';
export const JOBQUEUE_CREATE_ONE_BEGIN = 'JOBQUEUE_CREATE_ONE_BEGIN';
export const JOBQUEUE_CREATE_ONE_SUCCESS = 'JOBQUEUE_CREATE_ONE_SUCCESS';
export const JOBQUEUE_CREATE_ONE_FAILURE = 'JOBQUEUE_CREATE_ONE_FAILURE';
export const JOBQUEUE_CREATE_ONE_DISMISS_ERROR = 'JOBQUEUE_CREATE_ONE_DISMISS_ERROR';
export const JOBQUEUE_DEL_ONE_BEGIN = 'JOBQUEUE_DEL_ONE_BEGIN';
export const JOBQUEUE_DEL_ONE_SUCCESS = 'JOBQUEUE_DEL_ONE_SUCCESS';
export const JOBQUEUE_DEL_ONE_FAILURE = 'JOBQUEUE_DEL_ONE_FAILURE';
export const JOBQUEUE_DEL_ONE_DISMISS_ERROR = 'JOBQUEUE_DEL_ONE_DISMISS_ERROR';
export const JOBQUEUE_EXPORT_AS_TAB_BEGIN = 'JOBQUEUE_EXPORT_AS_TAB_BEGIN';
export const JOBQUEUE_EXPORT_AS_TAB_SUCCESS = 'JOBQUEUE_EXPORT_AS_TAB_SUCCESS';
export const JOBQUEUE_EXPORT_AS_TAB_FAILURE = 'JOBQUEUE_EXPORT_AS_TAB_FAILURE';
export const JOBQUEUE_EXPORT_AS_TAB_DISMISS_ERROR = 'JOBQUEUE_EXPORT_AS_TAB_DISMISS_ERROR';
export const JOBQUEUE_EXPORT_BEGIN = 'JOBQUEUE_EXPORT_BEGIN';
export const JOBQUEUE_EXPORT_SUCCESS = 'JOBQUEUE_EXPORT_SUCCESS';
export const JOBQUEUE_EXPORT_FAILURE = 'JOBQUEUE_EXPORT_FAILURE';
export const JOBQUEUE_EXPORT_DISMISS_ERROR = 'JOBQUEUE_EXPORT_DISMISS_ERROR';
export const JOBQUEUE_INIT_FILTERS = 'JOBQUEUE_INIT_FILTERS';
export const JOBQUEUE_INIT_SORT = 'JOBQUEUE_INIT_SORT';
export const JOBQUEUE_LOAD_MORE_INIT = 'JOBQUEUE_LOAD_MORE_INIT';
export const JOBQUEUE_LOAD_MORE_BEGIN = 'JOBQUEUE_LOAD_MORE_BEGIN';
export const JOBQUEUE_LOAD_MORE_SUCCESS = 'JOBQUEUE_LOAD_MORE_SUCCESS';
export const JOBQUEUE_LOAD_MORE_FAILURE = 'JOBQUEUE_LOAD_MORE_FAILURE';
export const JOBQUEUE_LOAD_MORE_DISMISS_ERROR = 'JOBQUEUE_LOAD_MORE_DISMISS_ERROR';
export const JOBQUEUE_LOAD_ONE_BEGIN = 'JOBQUEUE_LOAD_ONE_BEGIN';
export const JOBQUEUE_LOAD_ONE_SUCCESS = 'JOBQUEUE_LOAD_ONE_SUCCESS';
export const JOBQUEUE_LOAD_ONE_FAILURE = 'JOBQUEUE_LOAD_ONE_FAILURE';
export const JOBQUEUE_LOAD_ONE_DISMISS_ERROR = 'JOBQUEUE_LOAD_ONE_DISMISS_ERROR';
export const JOBQUEUE_ON_SELECT = 'JOBQUEUE_ON_SELECT';
export const JOBQUEUE_PRINT_ONE_BEGIN = 'JOBQUEUE_PRINT_ONE_BEGIN';
export const JOBQUEUE_PRINT_ONE_SUCCESS = 'JOBQUEUE_PRINT_ONE_SUCCESS';
export const JOBQUEUE_PRINT_ONE_FAILURE = 'JOBQUEUE_PRINT_ONE_FAILURE';
export const JOBQUEUE_PRINT_ONE_DISMISS_ERROR = 'JOBQUEUE_PRINT_ONE_DISMISS_ERROR';
export const JOBQUEUE_PROPAGATE = 'JOBQUEUE_PROPAGATE';
export const JOBQUEUE_SELECT_NONE = 'JOBQUEUE_SELECT_NONE';
export const JOBQUEUE_SELECT_ALL = 'JOBQUEUE_SELECT_ALL';
export const JOBQUEUE_SET_CURRENT = 'JOBQUEUE_SET_CURRENT';
export const JOBQUEUE_SET_PREVIOUS = 'JOBQUEUE_SET_PREVIOUS';
export const JOBQUEUE_SET_NEXT_BEGIN = 'JOBQUEUE_SET_NEXT_BEGIN';
export const JOBQUEUE_SET_NEXT_SUCCESS = 'JOBQUEUE_SET_NEXT_SUCCESS';
export const JOBQUEUE_SET_NEXT_FAILURE = 'JOBQUEUE_SET_NEXT_FAILURE';
export const JOBQUEUE_SET_NEXT_DISMISS_ERROR = 'JOBQUEUE_SET_NEXT_DISMISS_ERROR';
export const JOBQUEUE_SET_FILTERS = 'JOBQUEUE_SET_FILTERS';
export const JOBQUEUE_SET_SORT = 'JOBQUEUE_SET_SORT';
export const JOBQUEUE_UPDATE_ONE_BEGIN = 'JOBQUEUE_UPDATE_ONE_BEGIN';
export const JOBQUEUE_UPDATE_ONE_SUCCESS = 'JOBQUEUE_UPDATE_ONE_SUCCESS';
export const JOBQUEUE_UPDATE_ONE_FAILURE = 'JOBQUEUE_UPDATE_ONE_FAILURE';
export const JOBQUEUE_UPDATE_ONE_DISMISS_ERROR = 'JOBQUEUE_UPDATE_ONE_DISMISS_ERROR';
export const JOBQUEUE_UPDATE_QUICK_SEARCH = 'JOBQUEUE_UPDATE_QUICK_SEARCH';
export const JOBQUEUE_UPDATE_SORT = 'JOBQUEUE_UPDATE_SORT';
export const JOBQUEUE_LOAD_PENDINGS_INIT = 'JOBQUEUE_LOAD_PENDINGS_INIT';
export const JOBQUEUE_LOAD_PENDINGS_BEGIN = 'JOBQUEUE_LOAD_PENDINGS_BEGIN';
export const JOBQUEUE_LOAD_PENDINGS_SUCCESS = 'JOBQUEUE_LOAD_PENDINGS_SUCCESS';
export const JOBQUEUE_LOAD_PENDINGS_FAILURE = 'JOBQUEUE_LOAD_PENDINGS_FAILURE';
export const JOBQUEUE_LOAD_PENDINGS_DISMISS_ERROR = 'JOBQUEUE_LOAD_PENDINGS_DISMISS_ERROR';
