import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Row, Col, HoverObserver } from 'react-bootstrap-front';

export default class InlineLine extends Component {
  static propTypes = {
    className: PropTypes.string,
    cols: PropTypes.array.isRequired,
    fLineClassName: PropTypes.func,
    header: PropTypes.bool,
    item: PropTypes.object.isRequired,
    oddEven: PropTypes.number,
  };

  static defaultProps = {
    className: '',
    fLineClassName: null,
    header: false,
    oddEven: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      entered: false,
    };
    this.mouseLeave = this.mouseLeave.bind(this);
    this.mouseEnter = this.mouseEnter.bind(this);
  }

  mouseLeave() {
    this.setState({ entered: false });
  }

  mouseEnter() {
    this.setState({ entered: true });
  }

  render() {
    let className = this.props.fLineClassName ? this.props.fLineClassName(this.props.item) : '';
    if (this.props.header) {
      className += ' row-title';
    } else {
      if (this.props.oddEven % 2 !== 1) {
        className += ' row-odd';
      } else {
        className += ' row-even';
      }
    }
    return (
      <HoverObserver onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
        <Row className={classnames('ui-inline-line row-line', className)}>
          {this.props.cols.map((col, i) => {
            let content = '';
            if (col.fContent) {
              content = col.fContent(this.props);
            } else {
              if (col.content) {
                content = col.content;
              } else {
                if (col.field && this.props.item[col.field]) {
                  content = this.props.item[col.field];
                }
              }
            }
            return (
              <Col size={col.size} key={`inline-list-${col.name}`}>
                <span>{content}</span>
                {this.state.entered && this.props.actions && this.props.cols.length === i + 1 && (
                  <div className="btn-group btn-group-xs" role="group" aria-label="...">
                    {this.props.actions.map(action => (
                      <button
                        type="button"
                        className="btn btn-inline btn-secondary"
                        key={`inline-list-button-${action.name}`}
                        onClick={() => {
                          action.onClick(this.props.item);
                        }}
                      >
                        {action.icon}
                      </button>
                    ))}
                  </div>
                )}
              </Col>
            );
          })}
        </Row>
      </HoverObserver>
    );
  }
}
