import React from 'react';
import { injectIntl } from 'react-intl';
import { getShortcuts } from './';
import useForm from '../ui/useForm';
import {
  ResponsiveModalOrForm,
  InputHidden,
  InputText,
  InputCheckbox,
  InputDate,
  InputDatetime,
  InputPhone,
  Row,
  Col,
} from '../ui';
import { Home as StatIcon } from '../icons';

/**
 * Initialisation
 * 
 * @param {Object}   item
 * @param {Function} setItem
 */
const initItem = (item, setItem) => {
};

/**
 * Un champ a été modifié
 * 
 * @param {String}   field
 * @param {Object}   item
 * @param {Function} setitem
 */
const afterChange = (field, item, setItem) => {
  switch (field) {
    default:
      break;
  }
};

/**
 * Saisie des informations d'un modèle
 */
function Form(props) {
  let item = props.item;
  // On récupère tout ce qu'il faut pour travailler
  const {
    values,
    handleChange,
    handleSave,
    handleSubmit,
    handleCancel,
    handleNavTab,
    getErrorMessage,
    getCurrentTab,
  } = useForm(
    item,
    props.tab,
    props.onSubmit,
    props.onCancel,
    props.onNavTab,
    props.errors,
    props.intl,
    afterChange,
    initItem,
  );
  // Le formulaire
  return (
    <ResponsiveModalOrForm
      title={
        !props.modify && !values.don_year
          ? props.intl.formatMessage({
              id: 'app.features.stat.form.title',
              defaultMessage: 'New model',
            })
          : values.don_year
      }
      className=""
      tab={getCurrentTab()}
      tabs={!props.modify ? getShortcuts(props.intl, 'new') : getShortcuts(props.intl, 'all')}
      size="md"
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onNavTab={handleNavTab}
      onSave={handleSave}
      onClose={handleCancel}
      onPrevious={props.onPrevious}
      onNext={props.onNext}
      modal={true}
      actionsButtons={props.actionsButtons}
      loader={props.loader ? props.loader : false}
    >
      <InputHidden name="id" id="id" value={values.id} />
            <Row>
      <Col size={{xxs: 36, sm: 12}}>
        <InputText
          label={props.intl.formatMessage({
            id: 'app.features.stat.field.grp_id.label',
            defaultMessage: 'grp_id',
          })}
          id="grp_id"
          name="grp_id"
          value={values.grp_id}
          required={true}
          labelTop={true}
          onChange={handleChange}
          error={getErrorMessage('grp_id')}
        />
      </Col>
      <Col size={{xxs: 36, sm: 12}}>
        <InputText
          label={props.intl.formatMessage({
            id: 'app.features.stat.field.don_year.label',
            defaultMessage: 'don_year',
          })}
          id="don_year"
          name="don_year"
          value={values.don_year}
          labelTop={true}
          onChange={handleChange}
          error={getErrorMessage('don_year')}
        />
      </Col>
      <Col size={{xxs: 36, sm: 12}}>
        <InputText
          label={props.intl.formatMessage({
            id: 'app.features.stat.field.don_month.label',
            defaultMessage: 'don_month',
          })}
          id="don_month"
          name="don_month"
          value={values.don_month}
          labelTop={true}
          onChange={handleChange}
          error={getErrorMessage('don_month')}
        />
      </Col>
      <Col size={{xxs: 36, sm: 12}}>
        <InputText
          label={props.intl.formatMessage({
            id: 'app.features.stat.field.ptyp_name.label',
            defaultMessage: 'ptyp_name',
          })}
          id="ptyp_name"
          name="ptyp_name"
          value={values.ptyp_name}
          labelTop={true}
          onChange={handleChange}
          error={getErrorMessage('ptyp_name')}
        />
      </Col>
      <Col size={{xxs: 36, sm: 12}}>
        <InputText
          label={props.intl.formatMessage({
            id: 'app.features.stat.field.clic_name.label',
            defaultMessage: 'clic_name',
          })}
          id="clic_name"
          name="clic_name"
          value={values.clic_name}
          labelTop={true}
          onChange={handleChange}
          error={getErrorMessage('clic_name')}
        />
      </Col>
      <Col size={{xxs: 36, sm: 12}}>
        <InputText
          label={props.intl.formatMessage({
            id: 'app.features.stat.field.caut_name.label',
            defaultMessage: 'caut_name',
          })}
          id="caut_name"
          name="caut_name"
          value={values.caut_name}
          required={true}
          labelTop={true}
          onChange={handleChange}
          error={getErrorMessage('caut_name')}
        />
      </Col>
      <Col size={{xxs: 36, sm: 12}}>
        <InputText
          label={props.intl.formatMessage({
            id: 'app.features.stat.field.don_regular.label',
            defaultMessage: 'don_regular',
          })}
          id="don_regular"
          name="don_regular"
          value={values.don_regular}
          required={true}
          labelTop={true}
          onChange={handleChange}
          error={getErrorMessage('don_regular')}
        />
      </Col>
      <Col size={{xxs: 36, sm: 12}}>
        <InputText
          label={props.intl.formatMessage({
            id: 'app.features.stat.field.cnty_ass.label',
            defaultMessage: 'cnty_ass',
          })}
          id="cnty_ass"
          name="cnty_ass"
          value={values.cnty_ass}
          required={true}
          labelTop={true}
          onChange={handleChange}
          error={getErrorMessage('cnty_ass')}
        />
      </Col>
      <Col size={{xxs: 36, sm: 12}}>
        <InputText
          label={props.intl.formatMessage({
            id: 'app.features.stat.field.don_status.label',
            defaultMessage: 'don_status',
          })}
          id="don_status"
          name="don_status"
          value={values.don_status}
          required={true}
          labelTop={true}
          onChange={handleChange}
          error={getErrorMessage('don_status')}
        />
      </Col>
      <Col size={{xxs: 36, sm: 12}}>
        <InputText
          label={props.intl.formatMessage({
            id: 'app.features.stat.field.tot_mnt.label',
            defaultMessage: 'tot_mnt',
          })}
          id="tot_mnt"
          name="tot_mnt"
          value={values.tot_mnt}
          labelTop={true}
          onChange={handleChange}
          error={getErrorMessage('tot_mnt')}
        />
      </Col>
      </Row>

    </ResponsiveModalOrForm>
  );
}

/**
 * Injection de l'intl
 */
export default injectIntl(Form);
