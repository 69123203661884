import React from 'react';
import { injectIntl } from 'react-intl';
import {
  InputHidden,
  InputText,
  InputSelect,
  InputCheckbox,
  InputMonetary,
  Row,
  Col,
} from 'react-bootstrap-front';
import { useForm, ResponsiveModalOrForm } from '../ui';
import { causeTypeMntType, causeTypeFamily, causeTypeDuration } from './';
import { editionAsOptions } from '../edition';
import { emailAsOptions } from '../email';
import { Cause as CauseTypeIcon, System as SettingsIcon, Email as EmailIcon } from '../icons';

/**
 *
 */
const afterChange = (name, item) => {
  switch (name) {
    case 'cause_main_type.id':
      const idx = item.__causeMainTypes.findIndex(
        elem => elem.id && `${elem.id}` === `${item.cause_main_type.id}`,
      );
      if (idx >= 0) {
        item.caut_family = item.__causeMainTypes[idx].attributes.camt_family;
      }
      break;
    default:
      break;
  }
  return item;
};

/**
 *
 */
function Form(props) {
  const modify = props.modify || false;
  let myItem = props.item;
  myItem.__causeMainTypes = props.causeMainType;
  const {
    values,
    handleChange,
    handleSubmit,
    handleCancel,
    handleNavTab,
    getErrorMessage,
  } = useForm(
    myItem,
    '',
    props.onSubmit,
    props.onCancel,
    '',
    props.errors,
    props.intl,
    afterChange,
  );
  let minDate = true;
  let maxDate = true;
  let maxLabel = props.intl.formatMessage({
    id: 'app.features.causeType.form.mntMax',
    defaultMessage: 'Maximum amount',
  });
  if (values.caut_mnt_type === 'OTHER') {
    minDate = false;
    maxDate = false;
  }
  if (values.caut_mnt_type === 'ANNUAL') {
    minDate = false;
    maxLabel = props.intl.formatMessage({
      id: 'app.features.causeType.form.mntAnnual',
      defaultMessage: 'Annual amount',
    });
  }
  const tabs = [
    {
      key: '1',
      name: 'Programme',
      label: props.intl.formatMessage({
        id: 'app.features.causeType.form.tab.main',
        defaultMessage: 'Mission',
      }),
      shortcut: 'A',
      icon: <CauseTypeIcon />,
    },
    {
      key: '2',
      name: 'Settings',
      label: props.intl.formatMessage({
        id: 'app.features.causeType.form.tab.settings',
        defaultMessage: 'Settings',
      }),
      shortcut: 'S',
      icon: <SettingsIcon />,
    },
    {
      key: '3',
      name: 'Notifications',
      label: props.intl.formatMessage({
        id: 'app.features.causeType.form.tab.notifications',
        defaultMessage: 'Notifications',
      }),
      shortcut: 'N',
      icon: <EmailIcon />,
    },
  ];
  return (
    <ResponsiveModalOrForm
      size="md"
      modal={true}
      title={
        values.caut_name
          ? values.caut_name
          : props.intl.formatMessage({
              id: 'app.features.causeType.form.title',
              defaultMessage: 'Mission',
            })
      }
      tabs={tabs}
      tab={values.__currentTab}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onNavTab={handleNavTab}
      onClose={props.onClose}
    >
      <InputHidden name="id" id="id" value={values.id} />
      <Row>
        <Col size={{ xs: 36, sm: 18 }}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.causeType.form.name',
              defaultMessage: 'Name',
            })}
            name="caut_name"
            id="caut_name"
            required={true}
            value={values.caut_name}
            onChange={handleChange}
            error={getErrorMessage('caut_name')}
          />
        </Col>
        <Col size={{ xs: 36, sm: 18 }}>
          <InputSelect
            label={props.intl.formatMessage({
              id: 'app.features.causeType.form.mainType',
              defaultMessage: 'Program',
            })}
            name="cause_main_type.id"
            id="cause_main_type.id"
            value={values.cause_main_type.id}
            onChange={handleChange}
            options={props.causeMainType}
          />
        </Col>
      </Row>
      {values.__currentTab === '1' && (
        <Row>
          <Col size={{ xs: 36, sm: 12 }}>
            <InputSelect
              label={props.intl.formatMessage({
                id: 'app.features.causeType.form.mntType',
                defaultMessage: 'Totalization',
              })}
              name="caut_mnt_type"
              id="caut_mnt_type"
              disabled={modify}
              value={values.caut_mnt_type}
              onChange={handleChange}
              options={causeTypeMntType}
            />
          </Col>
          {values.caut_mnt_type === 'MAXIMUM' && (
            <Col size={{ xs: 36, sm: 12 }}>
              <InputMonetary
                label={props.intl.formatMessage({
                  id: 'app.features.causeType.form.minMnt',
                  defaultMessage: 'Minimum amount',
                })}
                labelTop={true}
                name="caut_min_mnt"
                id="caut_min_mnt"
                inputMoney="EUR"
                dbMoney="EUR"
                disabled={!minDate}
                value={minDate ? values.caut_min_mnt : ''}
                onChange={handleChange}
              />
            </Col>
          )}
          {values.caut_mnt_type !== 'OTHER' && (
            <Col size={{ xs: 36, sm: 12 }}>
              <InputMonetary
                label={maxLabel}
                labelTop={true}
                name="caut_max_mnt"
                id="caut_max_mnt"
                inputMoney="EUR"
                dbMoney="EUR"
                disabled={!maxDate}
                value={maxDate ? values.caut_max_mnt : ''}
                onChange={handleChange}
              />
            </Col>
          )}
          <Col size={{ xs: 36, sm: 12 }}>
            <InputSelect
              label={props.intl.formatMessage({
                id: 'app.features.causeType.form.family',
                defaultMessage: 'Operation',
              })}
              name="caut_family"
              id="caut_family"
              disabled={true}
              value={values.caut_family}
              onChange={handleChange}
              options={causeTypeFamily}
            />
          </Col>
          <Col size={{ xs: 36, sm: 12 }}>
            <InputSelect
              label={props.intl.formatMessage({
                id: 'app.features.causeType.form.onceDuration',
                defaultMessage: 'Durée',
              })}
              name="caut_once_duration"
              id="caut_once_duration"
              disabled={modify}
              value={values.caut_once_duration}
              onChange={handleChange}
              options={causeTypeDuration}
            />
          </Col>
          <Col size={{ xs: 36, sm: 12 }}>
            <InputSelect
              label={props.intl.formatMessage({
                id: 'app.features.causeType.form.regularDuration',
                defaultMessage: 'Durée',
              })}
              name="caut_regular_duration"
              id="caut_regular_duration"
              disabled={modify}
              value={values.caut_regular_duration}
              onChange={handleChange}
              options={causeTypeDuration}
            />
          </Col>
        </Row>
      )}
      {values.__currentTab === '2' && (
        <Row>
          <Col size={{ xs: 36, sm: 12 }}>
            <InputCheckbox
              label={props.intl.formatMessage({
                id: 'app.features.causeType.form.receipt',
                defaultMessage: 'Receipt',
              })}
              name="caut_receipt"
              id="caut_receipt"
              checked={values.caut_receipt}
              onChange={handleChange}
            />
          </Col>
          <Col size={{ xs: 36, sm: 12 }}>
            <InputCheckbox
              label={props.intl.formatMessage({
                id: 'app.features.causeType.form.certificate',
                defaultMessage: 'Certificate',
              })}
              name="caut_certificat"
              id="caut_certificat"
              checked={values.caut_certificat}
              onChange={handleChange}
            />
          </Col>
          <Col size={{ xs: 36, sm: 12 }}>
            <InputCheckbox
              label={props.intl.formatMessage({
                id: 'app.features.causeType.form.news',
                defaultMessage: 'Nouvelles',
              })}
              name="caut_news"
              id="caut_news"
              checked={values.caut_news}
              onChange={handleChange}
            />
          </Col>
          <Col size={{ xs: 36, sm: 12 }}>
            {values.caut_receipt && (
              <InputSelect
                label={props.intl.formatMessage({
                  id: 'app.features.causeType.form.receiptEdition',
                  defaultMessage: 'Receipt edition',
                })}
                name="receipt_edition.id"
                id="receipt_edition.id"
                value={values.receipt_edition.id}
                onChange={handleChange}
                addEmpty={true}
                options={editionAsOptions(props.editions, ['FreeAsso_Receipt'])}
              />
            )}
          </Col>
          <Col size={{ xs: 36, sm: 12 }}>
            {values.caut_certificat && (
              <InputSelect
                label={props.intl.formatMessage({
                  id: 'app.features.causeType.form.certificateEdition',
                  defaultMessage: 'Certificate edition',
                })}
                name="certificate_edition.id"
                id="certificate_edition.id"
                value={values.certificate_edition.id}
                onChange={handleChange}
                addEmpty={true}
                options={editionAsOptions(props.editions, ['FreeAsso_Certificate'])}
              />
            )}
          </Col>
          <Col size={{ xs: 36, sm: 12 }}>
            <InputSelect
              label={props.intl.formatMessage({
                id: 'app.features.causeType.form.identityEdition',
                defaultMessage: 'Identity edition',
              })}
              name="identity_edition.id"
              id="identity_edition.id"
              value={values.identity_edition.id}
              onChange={handleChange}
              addEmpty={true}
              options={editionAsOptions(props.editions, ['FreeAsso_Cause'])}
            />
          </Col>
        </Row>
      )}
      {values.__currentTab === '3' && (
        <>
          {' '}
          <Row>
            <Col size={{ xs: 36, sm: 12 }}>
              <InputSelect
                label={props.intl.formatMessage({
                  id: 'app.features.causeType.form.add_email',
                  defaultMessage: 'Add',
                })}
                name="add_email.id"
                id="add_email.id"
                value={values.add_email ? values.add_email.id : ''}
                onChange={handleChange}
                options={emailAsOptions(props.emails, 'FreeAsso_Cause')}
                error={getErrorMessage('add_email.id')}
              />
            </Col>
            <Col size={{ xs: 36, sm: 12 }}>
              <InputSelect
                label={props.intl.formatMessage({
                  id: 'app.features.causeType.form.update_email',
                  defaultMessage: 'Update',
                })}
                name="update_email.id"
                id="update_email.id"
                value={values.update_email ? values.update_email.id : ''}
                onChange={handleChange}
                options={emailAsOptions(props.emails, 'FreeAsso_Cause')}
                error={getErrorMessage('update_email.id')}
              />
            </Col>
            <Col size={{ xs: 36, sm: 12 }}>
              <InputSelect
                label={props.intl.formatMessage({
                  id: 'app.features.causeType.form.end_email',
                  defaultMessage: 'End',
                })}
                name="end_email.id"
                id="end_email.id"
                value={values.end_email ? values.end_email.id : ''}
                onChange={handleChange}
                options={emailAsOptions(props.emails, 'FreeAsso_Cause')}
                error={getErrorMessage('end_email.id')}
              />
            </Col>
            <Col size={{ xs: 36, sm: 12 }}>
              <InputSelect
                label={props.intl.formatMessage({
                  id: 'app.features.causeType.form.sponsorship_add_email',
                  defaultMessage: 'Add',
                })}
                name="sponsorship_add_email.id"
                id="sponsorship_add_email.id"
                value={values.sponsorship_add_email ? values.sponsorship_add_email.id : ''}
                onChange={handleChange}
                options={emailAsOptions(props.emails, 'FreeAsso_Sponsorship')}
                error={getErrorMessage('sponsorship_add_email.id')}
              />
            </Col>
            <Col size={{ xs: 36, sm: 12 }}>
              <InputSelect
                label={props.intl.formatMessage({
                  id: 'app.features.causeType.form.sponsorship_update_email',
                  defaultMessage: 'Update',
                })}
                name="sponsorship_update_email.id"
                id="sponsorship_update_email.id"
                value={values.sponsorship_update_email ? values.sponsorship_update_email.id : ''}
                onChange={handleChange}
                options={emailAsOptions(props.emails, 'FreeAsso_Sponsorship')}
                error={getErrorMessage('sponsorship_update_email.id')}
              />
            </Col>
            <Col size={{ xs: 36, sm: 12 }}>
              <InputSelect
                label={props.intl.formatMessage({
                  id: 'app.features.causeType.form.sponsorship_end_email',
                  defaultMessage: 'End',
                })}
                name="sponsorship_end_email.id"
                id="sponsorship_end_email.id"
                value={values.sponsorship_end_email ? values.sponsorship_end_email.id : ''}
                onChange={handleChange}
                options={emailAsOptions(props.emails, 'FreeAsso_Sponsorship')}
                error={getErrorMessage('sponsorship_end_email.id')}
              />
            </Col>
          </Row>
          <Row>
            <Col size={{ xs: 36, sm: 12 }}>
              <InputSelect
                label={props.intl.formatMessage({
                  id: 'app.features.causeType.form.donation_add_email',
                  defaultMessage: 'Add',
                })}
                name="donation_add_email.id"
                id="donation_add_email.id"
                value={values.donation_add_email ? values.donation_add_email.id : ''}
                onChange={handleChange}
                options={emailAsOptions(props.emails, 'FreeAsso_Donation')}
                error={getErrorMessage('donation_add_email.id')}
              />
            </Col>
            <Col size={{ xs: 36, sm: 12 }}>
              <InputSelect
                label={props.intl.formatMessage({
                  id: 'app.features.causeType.form.donation_update_email',
                  defaultMessage: 'Update',
                })}
                name="donation_update_email.id"
                id="donation_update_email.id"
                value={values.donation_update_email ? values.donation_update_email.id : ''}
                onChange={handleChange}
                options={emailAsOptions(props.emails, 'FreeAsso_Donation')}
                error={getErrorMessage('donation_update_email.id')}
              />
            </Col>
            <Col size={{ xs: 36, sm: 12 }}>
              <InputSelect
                label={props.intl.formatMessage({
                  id: 'app.features.causeType.form.donation_end_email',
                  defaultMessage: 'End',
                })}
                name="donation_end_email.id"
                id="donation_end_email.id"
                value={values.donation_end_email ? values.donation_end_email.id : ''}
                onChange={handleChange}
                options={emailAsOptions(props.emails, 'FreeAsso_Donation')}
                error={getErrorMessage('donation_end_email.id')}
              />
            </Col>
          </Row>
          <Row>
            <Col size={{ xs: 36, sm: 12 }}>
              <InputSelect
                label={props.intl.formatMessage({
                  id: 'app.features.causeType.form.donation_generate_email',
                  defaultMessage: 'Generate',
                })}
                name="donation_generate_email.id"
                id="donation_generate_email.id"
                value={values.donation_generate_email ? values.donation_generate_email.id : ''}
                onChange={handleChange}
                options={emailAsOptions(props.emails, 'FreeAsso_Donation')}
                error={getErrorMessage('donation_generate_email.id')}
              />
            </Col>
            <Col size={{ xs: 36, sm: 12 }}>
              <InputSelect
                label={props.intl.formatMessage({
                  id: 'app.features.causeType.form.donation_month_email',
                  defaultMessage: 'Generate',
                })}
                name="donation_month_email.id"
                id="donation_month_email.id"
                value={values.donation_month_email ? values.donation_month_email.id : ''}
                onChange={handleChange}
                options={emailAsOptions(props.emails, 'FreeAsso_Donation')}
                error={getErrorMessage('donation_month_email.id')}
              />
            </Col>
          </Row>
          <Row>
            <Col size={{ xs: 36, sm: 12 }}>
              <InputSelect
                label={props.intl.formatMessage({
                  id: 'app.features.causeType.form.certificate_year_email',
                  defaultMessage: 'Generate',
                })}
                name="certificate_year_email.id"
                id="certificate_year_email.id"
                value={values.certificate_year_email ? values.certificate_year_email.id : ''}
                onChange={handleChange}
                options={emailAsOptions(props.emails, 'FreeAsso_Certificate')}
                error={getErrorMessage('certificate_year_email.id')}
              />
            </Col>
          </Row>
        </>
      )}
    </ResponsiveModalOrForm>
  );
}

export default injectIntl(Form);
