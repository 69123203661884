import React from 'react';
// import PropTypes from 'prop-types';
import { InputKeywords as UIInputKeywords } from 'react-bootstrap-front';
import { Plus as PlusIcon, Minus as MinusIcon } from '../icons';

export default function InputKeywords(props) {
  return (
    <UIInputKeywords
      {...props}
      keywordPlusIcon={<PlusIcon className="text-success" size={0.8} title="Ajouter" />}
      keywordMinusIcon={<MinusIcon className="text-danger" size={0.8} title="Supprimer" />}
    />
  );
}

InputKeywords.propTypes = {};
InputKeywords.defaultProps = {};
