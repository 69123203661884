import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Row, HoverObserver } from 'react-bootstrap-front';

export default class Line extends Component {
  static propTypes = {
    className: PropTypes.string,
    header: PropTypes.bool,
    oddEven: PropTypes.number,
  };

  static defaultProps = {
    className: '',
    header: false,
    oddEven: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      entered: false,
    };
    this.mouseLeave = this.mouseLeave.bind(this);
    this.mouseEnter = this.mouseEnter.bind(this);
  }

  mouseLeave() {
    this.setState({ entered: false });
  }

  mouseEnter() {
    this.setState({ entered: true });
  }

  render() {
    let className = this.props.className || '';
    if (this.props.header) {
      className += ' row-title';
    } else {
      if (this.props.oddEven % 2 !== 1) {
        className += ' row-odd';
      } else {
        className += ' row-even';
      }
    }
    return (
      <HoverObserver onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
        <Row className={classnames('row-line', className)}>{this.props.children}</Row>
      </HoverObserver>
    );
  }
}
