import { getNewNormalizedObject } from 'jsonapi-front';
import { defaultFilters } from './initFilters';

const initialState = {
  items: getNewNormalizedObject('FreeAsso_Client'),
  selected: [],
  page_number: 1,
  page_size: process.env.REACT_APP_PAGE_SIZE,
  tab: "1",
  filters: defaultFilters(),
  sort: [{col:"cli_lastname",way:"up"}, {col:"cli_firstname",way:"up"}],
  loadMorePending: false,
  loadMoreError: null,
  loadMoreFinish: false,
  loadOnePending: false,
  loadOneError: null,
  loadOneItem: null,
  updateOnePending: false,
  updateOneError: null,
  delOnePending: false,
  delOneError: null,
  createOnePending: false,
  createOneError: null,
  printOnePending: false,
  printOneError: null,
};

export default initialState;
