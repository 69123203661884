import React from 'react';
// import PropTypes from 'prop-types';
import { mdiMessageTextOutline as myIcon } from '@mdi/js';
import { Icon } from './';

export default function Message(props) {
  return <Icon path={myIcon} {...props} />;
}

Message.propTypes = {};
Message.defaultProps = {};
