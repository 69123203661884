import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  InputPicker as DefaultInputPicker,
  ButtonPicker as DefaultButtonPicker,
} from 'react-bootstrap-front';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import axios from 'axios';
import { freeAssoApi } from '../../common';
import * as actions from './redux/actions';
import { Search, Input } from './';
import { More, DelOne, Zoom, AddOne } from '../icons';

const displayPicker = item => {
  let picker = null;
  if (item && item.id && parseInt(item.id, 10) > 0) {
    let display = item.cli_lastname || '';
    if (item.cli_firstname) {
      display = display + ' ' + item.cli_firstname;
      display.trim();
    }
    picker = (
      <p>
        <span>{display}</span>
        {item.cli_email && (
          <>
            <br />
            <span>{item.cli_email}</span>
          </>
        )}
      </p>
    );
  }
  return picker;
};

export class InputPicker extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    item: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  };
  static defaultProps = {
    disabled: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.item !== state.item) {
      let value = null;
      let display = '';
      let zoomBtn = false;
      if (props.item) {
        value = props.item.id || '';
        display = displayPicker(props.item);
        zoomBtn = props.item.id > 0 ? true : false;
      }
      return { item: props.item, value: value, display: display, zoomBtn };
    }
    return null;
  }

  constructor(props) {
    super(props);
    let value = '';
    let display = '';
    if (this.props.item) {
      value = this.props.item.id || '';
      display = displayPicker(props.item);
    }
    this.state = {
      search: false,
      item: this.props.item || null,
      list: [],
      value: value,
      display: display,
      autocomplete: false,
      source: false,
      zoom: false,
      add: false,
      zoomBtn: props.item && props.item.id > 0 ? true : false,
    };
    this.onMore = this.onMore.bind(this);
    this.onZoom = this.onZoom.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onCloseMore = this.onCloseMore.bind(this);
    this.onAdd = this.onAdd.bind(this);
  }

  onChange(event) {
    if (this.state.source) {
      this.state.source.cancel();
    }
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      cancelToken: source.token,
    };
    const search = '' + event.target.value;
    this.setState({ display: search, loading: true, cancel: source });
    if (search.length >= 2) {
      freeAssoApi
        .get(process.env.REACT_APP_BO_URL + '/v1/asso/client/autocomplete/' + event.target.value, {
          headers: headers,
        })
        .then(result => {
          if (result && result.data && result.data.data) {
            const list = result.data.data;
            if (Array.isArray(list) && list.length === 1) {
              this.props.onChange({
                target: { name: this.props.name, value: list[0].cli_id, type: 'FreeAsso_Client' },
              });
            } else {
              this.setState({ list: list, loading: false });
            }
          } else {
            this.setState({ list: [], loading: false });
          }
        })
        .catch(err => {
          this.setState({ list: [], loading: false });
        });
    }
  }

  onMore() {
    this.setState({ search: true, autocomplete: false });
  }

  onZoom() {
    this.setState({ zoom: true });
  }

  onClear() {
    this.setState({ autocomplete: false, value: null, display: null, list: [], loading: false });
    this.props.onChange({
      target: { name: this.props.name, value: null, type: 'FreeAsso_Client' },
    });
  }

  onSelect(item) {
    this.setState({ search: false, autocomplete: false, list: [] });
    if (item) {
      this.props.onChange({
        target: { name: this.props.name, value: item.id, type: 'FreeAsso_Client' },
      });
    }
  }

  onCloseMore(item) {
    this.setState({ search: false, zoom: false, add: false });
    if (item && item.id) {
      this.props.onChange({
        target: { name: this.props.name, value: item.id, type: 'FreeAsso_Client' },
      });
    }
  }

  onAdd() {
    this.setState({ add: true });
  }

  render() {
    return (
      <div className="client-input-picker">
        {!this.state.zoomBtn ? (
          <>
            <DefaultInputPicker
              name={this.props.name}
              label={this.props.label}
              value={this.state.value}
              labelTop={this.props.labelTop || true}
              size={this.props.size}
              labelSize={this.props.labelSize || 6}
              inputSize={this.props.inputSize || 30}
              list={this.state.list}
              disabled={this.props.disabled}
              display={this.state.display}
              onChange={this.onChange}
              onClear={this.onClear}
              onMore={this.onMore}
              onZoom={this.onZoom}
              onSelect={this.onSelect}
              onAdd={this.onAdd}
              required={this.props.required || false}
              error={this.props.error}
              pickerId="cli_id"
              pickerDisplay="cli_lastname,cli_firstname"
              clearIcon={
                <DelOne size={this.props.size === 'sm' ? 0.7 : 0.9} className="text-warning" />
              }
              moreIcon={
                <More size={this.props.size === 'sm' ? 0.7 : 0.9} className="text-secondary" />
              }
              zoomIcon={
                <Zoom size={this.props.size === 'sm' ? 0.7 : 0.9} className="text-secondary" />
              }
              addIcon={
                <AddOne size={this.props.size === 'sm' ? 0.7 : 0.9} className="text-primary" />
              }
            />
            <Search
              title={this.props.label}
              show={this.state.search}
              onClose={this.onCloseMore}
              onSelect={this.onSelect}
              types={this.props.clientType.items}
              categories={this.props.clientCategory.items}
            />
          </>
        ) : (
          <>
            <DefaultButtonPicker
              label={this.props.label}
              value={this.state.value}
              display={this.state.display}
              disabled={this.props.disabled}
              required={this.props.required || false}
              clearIcon={<DelOne className="text-warning" size={0.8} />}
              onZoom={this.onZoom}
              onClear={this.onClear}
            />
          </>
        )}
        {this.state.zoom && (
          <Input
            loader={false}
            modal={true}
            cliId={this.state.item.id}
            onClose={this.onCloseMore}
          />
        )}
        {this.state.add && (
          <Input loader={false} modal={true} onClose={this.onCloseMore} onCreate={this.onCreate} />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    client: state.client,
    clientType: state.clientType,
    clientCategory: state.clientCategory,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InputPicker);
