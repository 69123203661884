export const AUTH_CHECK_IS_AUTHENTICATED_BEGIN = 'AUTH_CHECK_IS_AUTHENTICATED_BEGIN';
export const AUTH_CHECK_IS_AUTHENTICATED_SUCCESS = 'AUTH_CHECK_IS_AUTHENTICATED_SUCCESS';
export const AUTH_CHECK_IS_AUTHENTICATED_FAILURE = 'AUTH_CHECK_IS_AUTHENTICATED_FAILURE';
export const AUTH_CHECK_IS_AUTHENTICATED_DISMISS_ERROR = 'AUTH_CHECK_IS_AUTHENTICATED_DISMISS_ERROR';
export const AUTH_SIGN_IN_BEGIN = 'AUTH_SIGN_IN_BEGIN';
export const AUTH_SIGN_IN_SUCCESS = 'AUTH_SIGN_IN_SUCCESS';
export const AUTH_SIGN_IN_FAILURE = 'AUTH_SIGN_IN_FAILURE';
export const AUTH_SIGN_IN_DISMISS_ERROR = 'AUTH_SIGN_IN_DISMISS_ERROR';
export const AUTH_SIGN_OUT_BEGIN = 'AUTH_SIGN_OUT_BEGIN';
export const AUTH_SIGN_OUT_SUCCESS = 'AUTH_SIGN_OUT_SUCCESS';
export const AUTH_SIGN_OUT_FAILURE = 'AUTH_SIGN_OUT_FAILURE';
export const AUTH_SIGN_OUT_DISMISS_ERROR = 'AUTH_SIGN_OUT_DISMISS_ERROR';
export const AUTH_ASK_PASSWORD_BEGIN = 'AUTH_ASK_PASSWORD_BEGIN';
export const AUTH_ASK_PASSWORD_SUCCESS = 'AUTH_ASK_PASSWORD_SUCCESS';
export const AUTH_ASK_PASSWORD_FAILURE = 'AUTH_ASK_PASSWORD_FAILURE';
export const AUTH_ASK_PASSWORD_DISMISS_ERROR = 'AUTH_ASK_PASSWORD_DISMISS_ERROR';
export const AUTH_CHANGE_PASSWORD_BEGIN = 'AUTH_CHANGE_PASSWORD_BEGIN';
export const AUTH_CHANGE_PASSWORD_SUCCESS = 'AUTH_CHANGE_PASSWORD_SUCCESS';
export const AUTH_CHANGE_PASSWORD_FAILURE = 'AUTH_CHANGE_PASSWORD_FAILURE';
export const AUTH_CHANGE_PASSWORD_DISMISS_ERROR = 'AUTH_CHANGE_PASSWORD_DISMISS_ERROR';
export const AUTH_UPDATE_ONE_BEGIN = 'AUTH_UPDATE_ONE_BEGIN';
export const AUTH_UPDATE_ONE_SUCCESS = 'AUTH_UPDATE_ONE_SUCCESS';
export const AUTH_UPDATE_ONE_FAILURE = 'AUTH_UPDATE_ONE_FAILURE';
export const AUTH_UPDATE_ONE_DISMISS_ERROR = 'AUTH_UPDATE_ONE_DISMISS_ERROR';
export const AUTH_UPDATE_PASSWORD_BEGIN = 'AUTH_UPDATE_PASSWORD_BEGIN';
export const AUTH_UPDATE_PASSWORD_SUCCESS = 'AUTH_UPDATE_PASSWORD_SUCCESS';
export const AUTH_UPDATE_PASSWORD_FAILURE = 'AUTH_UPDATE_PASSWORD_FAILURE';
export const AUTH_UPDATE_PASSWORD_DISMISS_ERROR = 'AUTH_UPDATE_PASSWORD_DISMISS_ERROR';
export const AUTH_UPDATE_CONFIG_BEGIN = 'AUTH_UPDATE_CONFIG_BEGIN';
export const AUTH_UPDATE_CONFIG_SUCCESS = 'AUTH_UPDATE_CONFIG_SUCCESS';
export const AUTH_UPDATE_CONFIG_FAILURE = 'AUTH_UPDATE_CONFIG_FAILURE';
export const AUTH_UPDATE_CONFIG_DISMISS_ERROR = 'AUTH_UPDATE_CONFIG_DISMISS_ERROR';
export const AUTH_CHANGE_SETTING_BEGIN = 'AUTH_CHANGE_SETTING_BEGIN';
export const AUTH_CHANGE_SETTING_SUCCESS = 'AUTH_CHANGE_SETTING_SUCCESS';
export const AUTH_CHANGE_SETTING_FAILURE = 'AUTH_CHANGE_SETTING_FAILURE';
export const AUTH_CHANGE_SETTING_DISMISS_ERROR = 'AUTH_CHANGE_SETTING_DISMISS_ERROR';
export const AUTH_PROPAGATE = 'AUTH_PROPAGATE';
export const AUTH_SET_REALM = 'AUTH_SET_REALM';
export const AUTH_SWITCH_GROUP_BEGIN = 'AUTH_SWITCH_GROUP_BEGIN';
export const AUTH_SWITCH_GROUP_SUCCESS = 'AUTH_SWITCH_GROUP_SUCCESS';
export const AUTH_SWITCH_GROUP_FAILURE = 'AUTH_SWITCH_GROUP_FAILURE';
export const AUTH_SWITCH_GROUP_DISMISS_ERROR = 'AUTH_SWITCH_GROUP_DISMISS_ERROR';
