export const SITE_LOAD_MORE_INIT = 'SITE_LOAD_MORE_INIT';
export const SITE_LOAD_MORE_BEGIN = 'SITE_LOAD_MORE_BEGIN';
export const SITE_LOAD_MORE_SUCCESS = 'SITE_LOAD_MORE_SUCCESS';
export const SITE_LOAD_MORE_FAILURE = 'SITE_LOAD_MORE_FAILURE';
export const SITE_LOAD_MORE_DISMISS_ERROR = 'SITE_LOAD_MORE_DISMISS_ERROR';
export const SITE_LOAD_ONE_BEGIN = 'SITE_LOAD_ONE_BEGIN';
export const SITE_LOAD_ONE_SUCCESS = 'SITE_LOAD_ONE_SUCCESS';
export const SITE_LOAD_ONE_FAILURE = 'SITE_LOAD_ONE_FAILURE';
export const SITE_LOAD_ONE_DISMISS_ERROR = 'SITE_LOAD_ONE_DISMISS_ERROR';
export const SITE_CREATE_ONE_BEGIN = 'SITE_CREATE_ONE_BEGIN';
export const SITE_CREATE_ONE_SUCCESS = 'SITE_CREATE_ONE_SUCCESS';
export const SITE_CREATE_ONE_FAILURE = 'SITE_CREATE_ONE_FAILURE';
export const SITE_CREATE_ONE_DISMISS_ERROR = 'SITE_CREATE_ONE_DISMISS_ERROR';
export const SITE_UPDATE_ONE_BEGIN = 'SITE_UPDATE_ONE_BEGIN';
export const SITE_UPDATE_ONE_SUCCESS = 'SITE_UPDATE_ONE_SUCCESS';
export const SITE_UPDATE_ONE_FAILURE = 'SITE_UPDATE_ONE_FAILURE';
export const SITE_UPDATE_ONE_DISMISS_ERROR = 'SITE_UPDATE_ONE_DISMISS_ERROR';
export const SITE_UPDATE_ONE_UPDATE = 'SITE_UPDATE_ONE_UPDATE';
export const SITE_FILTER_BEGIN = 'SITE_FILTER_BEGIN';
export const SITE_FILTER_SUCCESS = 'SITE_FILTER_SUCCESS';
export const SITE_FILTER_FAILURE = 'SITE_FILTER_FAILURE';
export const SITE_FILTER_DISMISS_ERROR = 'SITE_FILTER_DISMISS_ERROR';
export const SITE_DEL_ONE_BEGIN = 'SITE_DEL_ONE_BEGIN';
export const SITE_DEL_ONE_SUCCESS = 'SITE_DEL_ONE_SUCCESS';
export const SITE_DEL_ONE_FAILURE = 'SITE_DEL_ONE_FAILURE';
export const SITE_DEL_ONE_DISMISS_ERROR = 'SITE_DEL_ONE_DISMISS_ERROR';
export const SITE_CLEAR_ITEMS = 'SITE_CLEAR_ITEMS';
export const SITE_SET_SORT = 'SITE_SET_SORT';
export const SITE_UPDATE_QUICK_SEARCH = 'SITE_UPDATE_QUICK_SEARCH';
export const SITE_UPDATE_SORT = 'SITE_UPDATE_SORT';
export const SITE_INIT_SORT = 'SITE_INIT_SORT';
