import React from 'react';
// import PropTypes from 'prop-types';

export default function CloseLines() {
  return (
    <div className="icons-close-lines">
      Component content: icons/CloseLines
    </div>
  );
};

CloseLines.propTypes = {};
CloseLines.defaultProps = {};
