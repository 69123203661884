import React from 'react';
// import PropTypes from 'prop-types';

export default function AddLine() {
  return (
    <div className="icons-add-line">
      Component content: icons/AddLine
    </div>
  );
};

AddLine.propTypes = {};
AddLine.defaultProps = {};
