import React from 'react';
// import PropTypes from 'prop-types';
import { InputJson as UIInputJson } from 'react-bootstrap-front';
import { LockOn as LockIcon, LockOff as UnlockIcon } from '../icons';

export default function InputJsonSimple(props) {
  return (
    <UIInputJson
      {...props}
      lockOnIcon={<LockIcon className="text-secondary" size={0.8} title="Verrouillé" />}
      lockOffIcon={<UnlockIcon className="text-inactive" size={0.8} title="" />}
    />
  );
}

InputJsonSimple.propTypes = {};
InputJsonSimple.defaultProps = {};
