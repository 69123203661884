export const SESSION_CLEAR_ITEMS = 'SESSION_CLEAR_ITEMS';
export const SESSION_CREATE_ONE_BEGIN = 'SESSION_CREATE_ONE_BEGIN';
export const SESSION_CREATE_ONE_SUCCESS = 'SESSION_CREATE_ONE_SUCCESS';
export const SESSION_CREATE_ONE_FAILURE = 'SESSION_CREATE_ONE_FAILURE';
export const SESSION_CREATE_ONE_DISMISS_ERROR = 'SESSION_CREATE_ONE_DISMISS_ERROR';
export const SESSION_DEL_ONE_BEGIN = 'SESSION_DEL_ONE_BEGIN';
export const SESSION_DEL_ONE_SUCCESS = 'SESSION_DEL_ONE_SUCCESS';
export const SESSION_DEL_ONE_FAILURE = 'SESSION_DEL_ONE_FAILURE';
export const SESSION_DEL_ONE_DISMISS_ERROR = 'SESSION_DEL_ONE_DISMISS_ERROR';
export const SESSION_EXPORT_AS_TAB_BEGIN = 'SESSION_EXPORT_AS_TAB_BEGIN';
export const SESSION_EXPORT_AS_TAB_SUCCESS = 'SESSION_EXPORT_AS_TAB_SUCCESS';
export const SESSION_EXPORT_AS_TAB_FAILURE = 'SESSION_EXPORT_AS_TAB_FAILURE';
export const SESSION_EXPORT_AS_TAB_DISMISS_ERROR = 'SESSION_EXPORT_AS_TAB_DISMISS_ERROR';
export const SESSION_EXPORT_BEGIN = 'SESSION_EXPORT_BEGIN';
export const SESSION_EXPORT_SUCCESS = 'SESSION_EXPORT_SUCCESS';
export const SESSION_EXPORT_FAILURE = 'SESSION_EXPORT_FAILURE';
export const SESSION_EXPORT_DISMISS_ERROR = 'SESSION_EXPORT_DISMISS_ERROR';
export const SESSION_INIT_FILTERS = 'SESSION_INIT_FILTERS';
export const SESSION_INIT_SORT = 'SESSION_INIT_SORT';
export const SESSION_LOAD_MORE_INIT = 'SESSION_LOAD_MORE_INIT';
export const SESSION_LOAD_MORE_BEGIN = 'SESSION_LOAD_MORE_BEGIN';
export const SESSION_LOAD_MORE_SUCCESS = 'SESSION_LOAD_MORE_SUCCESS';
export const SESSION_LOAD_MORE_FAILURE = 'SESSION_LOAD_MORE_FAILURE';
export const SESSION_LOAD_MORE_DISMISS_ERROR = 'SESSION_LOAD_MORE_DISMISS_ERROR';
export const SESSION_LOAD_ONE_BEGIN = 'SESSION_LOAD_ONE_BEGIN';
export const SESSION_LOAD_ONE_SUCCESS = 'SESSION_LOAD_ONE_SUCCESS';
export const SESSION_LOAD_ONE_FAILURE = 'SESSION_LOAD_ONE_FAILURE';
export const SESSION_LOAD_ONE_DISMISS_ERROR = 'SESSION_LOAD_ONE_DISMISS_ERROR';
export const SESSION_ON_SELECT = 'SESSION_ON_SELECT';
export const SESSION_PRINT_ONE_BEGIN = 'SESSION_PRINT_ONE_BEGIN';
export const SESSION_PRINT_ONE_SUCCESS = 'SESSION_PRINT_ONE_SUCCESS';
export const SESSION_PRINT_ONE_FAILURE = 'SESSION_PRINT_ONE_FAILURE';
export const SESSION_PRINT_ONE_DISMISS_ERROR = 'SESSION_PRINT_ONE_DISMISS_ERROR';
export const SESSION_PROPAGATE = 'SESSION_PROPAGATE';
export const SESSION_SELECT_NONE = 'SESSION_SELECT_NONE';
export const SESSION_SELECT_ALL = 'SESSION_SELECT_ALL';
export const SESSION_SET_CURRENT = 'SESSION_SET_CURRENT';
export const SESSION_SET_PREVIOUS = 'SESSION_SET_PREVIOUS';
export const SESSION_SET_NEXT_BEGIN = 'SESSION_SET_NEXT_BEGIN';
export const SESSION_SET_NEXT_SUCCESS = 'SESSION_SET_NEXT_SUCCESS';
export const SESSION_SET_NEXT_FAILURE = 'SESSION_SET_NEXT_FAILURE';
export const SESSION_SET_NEXT_DISMISS_ERROR = 'SESSION_SET_NEXT_DISMISS_ERROR';
export const SESSION_SET_FILTERS = 'SESSION_SET_FILTERS';
export const SESSION_SET_SORT = 'SESSION_SET_SORT';
export const SESSION_UPDATE_ONE_BEGIN = 'SESSION_UPDATE_ONE_BEGIN';
export const SESSION_UPDATE_ONE_SUCCESS = 'SESSION_UPDATE_ONE_SUCCESS';
export const SESSION_UPDATE_ONE_FAILURE = 'SESSION_UPDATE_ONE_FAILURE';
export const SESSION_UPDATE_ONE_DISMISS_ERROR = 'SESSION_UPDATE_ONE_DISMISS_ERROR';
export const SESSION_UPDATE_QUICK_SEARCH = 'SESSION_UPDATE_QUICK_SEARCH';
export const SESSION_UPDATE_SORT = 'SESSION_UPDATE_SORT';
export const SESSION_VERIF_ONE_BEGIN = 'SESSION_VERIF_ONE_BEGIN';
export const SESSION_VERIF_ONE_SUCCESS = 'SESSION_VERIF_ONE_SUCCESS';
export const SESSION_VERIF_ONE_FAILURE = 'SESSION_VERIF_ONE_FAILURE';
export const SESSION_VERIF_ONE_DISMISS_ERROR = 'SESSION_VERIF_ONE_DISMISS_ERROR';
export const SESSION_CLEAN_ONE_BEGIN = 'SESSION_CLEAN_ONE_BEGIN';
export const SESSION_CLEAN_ONE_SUCCESS = 'SESSION_CLEAN_ONE_SUCCESS';
export const SESSION_CLEAN_ONE_FAILURE = 'SESSION_CLEAN_ONE_FAILURE';
export const SESSION_CLEAN_ONE_DISMISS_ERROR = 'SESSION_CLEAN_ONE_DISMISS_ERROR';