import React from 'react';
import { injectIntl } from 'react-intl';
import { getShortcuts } from './';
import useForm from '../ui/useForm';
import {
  ResponsiveModalOrForm,
  InputHidden,
  InputText,
  InputCheckbox,
  InputDatetime,
  Row,
  Col,
} from '../ui';

/**
 * Initialisation
 *
 * @param {Object}   item
 * @param {Function} setItem
 */
const initItem = (item, setItem) => {};

/**
 * Un champ a été modifié
 *
 * @param {String}   field
 * @param {Object}   item
 * @param {Function} setitem
 */
const afterChange = (field, item, setItem) => {
  switch (field) {
    default:
      break;
  }
};

/**
 * Saisie des informations d'un modèle
 */
function Form(props) {
  let item = props.item;
  // On récupère tout ce qu'il faut pour travailler
  const {
    values,
    handleChange,
    handleSave,
    handleSubmit,
    handleCancel,
    handleNavTab,
    getErrorMessage,
    getCurrentTab,
  } = useForm(
    item,
    props.tab,
    props.onSubmit,
    props.onCancel,
    props.onNavTab,
    props.errors,
    props.intl,
    afterChange,
    initItem,
  );
  // Le formulaire
  return (
    <ResponsiveModalOrForm
      title={
        !props.modify && !values.notif_ts
          ? props.intl.formatMessage({
              id: 'app.features.notification.form.title',
              defaultMessage: 'New model',
            })
          : values.notif_ts
      }
      className=""
      tab={getCurrentTab()}
      tabs={!props.modify ? getShortcuts(props.intl, 'new') : getShortcuts(props.intl, 'all')}
      size="md"
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onNavTab={handleNavTab}
      onSave={handleSave}
      onClose={handleCancel}
      onPrevious={props.onPrevious}
      onNext={props.onNext}
      modal={true}
      actionsButtons={props.actionsButtons}
      loader={props.loader ? props.loader : false}
    >
      <InputHidden name="id" id="id" value={values.id} />
      <Row>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.notification.field.brk_id.label',
              defaultMessage: 'brk_id',
            })}
            id="brk_id"
            name="brk_id"
            value={values.brk_id}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('brk_id')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.notification.field.notif_text.label',
              defaultMessage: 'notif_text',
            })}
            id="notif_text"
            name="notif_text"
            value={values.notif_text}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('notif_text')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.notification.field.notif_subject.label',
              defaultMessage: 'notif_subject',
            })}
            id="notif_subject"
            name="notif_subject"
            value={values.notif_subject}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('notif_subject')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.notification.field.notif_object_name.label',
              defaultMessage: 'notif_object_name',
            })}
            id="notif_object_name"
            name="notif_object_name"
            value={values.notif_object_name}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('notif_object_name')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.notification.field.notif_object_id.label',
              defaultMessage: 'notif_object_id',
            })}
            id="notif_object_id"
            name="notif_object_id"
            value={values.notif_object_id}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('notif_object_id')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.notification.field.notif_code.label',
              defaultMessage: 'notif_code',
            })}
            id="notif_code"
            name="notif_code"
            value={values.notif_code}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('notif_code')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputDatetime
            label={props.intl.formatMessage({
              id: 'app.features.notification.field.notif_ts.label',
              defaultMessage: 'notif_ts',
            })}
            id="notif_ts"
            name="notif_ts"
            value={values.notif_ts}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('notif_ts')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.notification.field.notif_type.label',
              defaultMessage: 'notif_type',
            })}
            id="notif_type"
            name="notif_type"
            value={values.notif_type}
            required={true}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('notif_type')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputCheckbox
            label={props.intl.formatMessage({
              id: 'app.features.notification.field.notif_read.label',
              defaultMessage: 'notif_read',
            })}
            id="notif_read"
            name="notif_read"
            checked={values.notif_read === true}
            required={true}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('notif_read')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputDatetime
            label={props.intl.formatMessage({
              id: 'app.features.notification.field.notif_read_ts.label',
              defaultMessage: 'notif_read_ts',
            })}
            id="notif_read_ts"
            name="notif_read_ts"
            value={values.notif_read_ts}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('notif_read_ts')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.notification.field.user_id.label',
              defaultMessage: 'user_id',
            })}
            id="user_id"
            name="user_id"
            value={values.user_id}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('user_id')}
          />
        </Col>
      </Row>
    </ResponsiveModalOrForm>
  );
}

/**
 * Injection de l'intl
 */
export default injectIntl(Form);
