import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as actions from './redux/actions';
import { loadSponsorships } from '../sponsorship/redux/actions';
import { loadDonations } from '../donation/redux/actions';
import { normalizedObjectModeler } from 'jsonapi-front';
import { ResponsiveQuickSearch } from 'react-bootstrap-front';
import {
  Search as SearchIcon,
  SearchPlus as SearchPlusIcon,
  SearchCancel as SearchCancelIcon,
  Person as ClientIcon,
} from '../icons';
import { showErrors, deleteSuccess, List as UiList, messageSuccess, messageError } from '../ui';
import { getGlobalActions, getInlineActions, getCols, getSelectActions } from './';
import { Input } from './';
import { InlineList as InlineSponsorships } from '../sponsorship';
import { InlineList as InlineCertificates } from '../certificate';
import { InlineList as InlineReceipts } from '../receipt';
import { InlineList as InlineDonations } from '../donation';
import { getEditions } from '../edition';

export class List extends Component {
  static propTypes = {
    client: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      timer: null,
      cliId: -1,
      mode: null,
      item: null,
      editions: getEditions(props.edition.models, 'FreeAsso_Client'),
    };
    this.onCreate = this.onCreate.bind(this);
    this.onGetOne = this.onGetOne.bind(this);
    this.onDelOne = this.onDelOne.bind(this);
    this.onPrintOne = this.onPrintOne.bind(this);
    this.onReload = this.onReload.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onLoadMore = this.onLoadMore.bind(this);
    this.onClearFilters = this.onClearFilters.bind(this);
    this.onQuickSearch = this.onQuickSearch.bind(this);
    this.onSetFiltersAndSort = this.onSetFiltersAndSort.bind(this);
    this.onUpdateSort = this.onUpdateSort.bind(this);
    this.onSelectList = this.onSelectList.bind(this);
    this.onSelectMenu = this.onSelectMenu.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  componentDidMount() {
    this.props.actions.loadMore();
  }

  onCreate(event) {
    this.setState({ cliId: 0 });
  }

  onGetOne(id) {
    this.setState({ cliId: id });
  }

  /**
   * Demande d'édition
   *
   * @param {Number} ediId Identifiant de l'édition
   * @param {Object} item  Objet à imprimer
   */
   onPrintOne(ediId, item, lang = '') {
    if (item) {
      this.props.actions
        .printOne(item.id, ediId, lang)
        .then(result => {
          messageSuccess(
            this.props.intl.formatMessage({ id: 'app.message.print.done', defaultMessage: 'Print' }),
          );
        })
        .catch(error => {
          messageError(
            this.props.intl.formatMessage({
              id: 'app.errors.default.print',
              defaultMessage: "Erreurs lors de l'impression",
            }),
          );
        });
      messageSuccess(
        this.props.intl.formatMessage({ id: 'app.message.print.ok', defaultMessage: 'Print' }),
      );
    }
  }

  onClose() {
    this.setState({ cliId: -1 });
  }

  onSelect(id) {
    this.props.actions.onSelect(id);
  }

  onDelOne(id) {
    this.props.actions
      .delOne(id)
      .then(result => {
        deleteSuccess();
        this.props.actions.loadMore(true);
      })
      .catch(errors => {
        showErrors(this.props.intl, errors);
      });
  }

  onReload(event) {
    if (event) {
      event.preventDefault();
    }
    this.props.actions.loadMore(true);
  }

  onLoadMore(event) {
    this.props.actions.loadMore();
  }

  onQuickSearch(quickSearch, mode) {
    this.props.actions.updateQuickSearch(quickSearch, mode);
    let timer = this.state.timer;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      this.props.actions.loadMore(true);
    }, this.props.loadTimeOut);
    this.setState({ timer: timer });
  }

  onUpdateSort(col, way, pos = 99) {
    this.props.actions.updateSort(col.col, way, pos);
    let timer = this.state.timer;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      this.props.actions.loadMore(true);
    }, this.props.loadTimeOut);
    this.setState({ timer: timer });
  }

  onSetFiltersAndSort(filters, sort) {
    this.props.actions.setFilters(filters);
    this.props.actions.setSort(sort);
    let timer = this.state.timer;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      this.props.actions.loadMore(true);
    }, this.props.loadTimeOut);
    this.setState({ timer: timer });
  }

  onClearFilters() {
    this.props.actions.initFilters();
    this.props.actions.initSort();
    let timer = this.state.timer;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      this.props.actions.loadMore(true);
    }, this.props.loadTimeOut);
    this.setState({ timer: timer });
  }

  onSelectList(obj, list) {
    if (obj) {
      if (list) {
        this.setState({ mode: list, item: obj });
      } else {
        this.setState({ item: obj });
      }
    } else {
      this.setState({ mode: false, item: null });
    }
  }

  onSelectMenu(option, email_id = null) {
    switch (option) {
      case 'selectAll':
        this.props.actions.selectAll();
        break;
      case 'selectNone':
        this.props.actions.selectNone();
        break;
      case 'exportAll':
        this.props.actions.exportAsTab('all').then(res => {
          if (!res) {
            messageSuccess('Export demandé');
          }
        });
        break;
      case 'exportSelection':
        this.props.actions.exportAsTab('selection').then(res => {
          if (!res) {
            messageSuccess('Export demandé');
          }
        });
        break;
      case 'emailAll':
        this.props.actions.exportAsTab('all', email_id).then(res => {
          if (!res) {
            messageSuccess("Envoi d'email demandé");
          }
        });
        break;
      case 'emailSelection':
        this.props.actions.exportAsTab('selection', email_id).then(res => {
          if (!res) {
            messageSuccess("Envoi d'email demandé");
          }
        });
        break;
      default:
        break;
    }
  }

  render() {
    const { intl } = this.props;
    // Les des items à afficher avec remplissage progressif
    let items = [];
    if (this.props.client.items.FreeAsso_Client) {
      items = normalizedObjectModeler(this.props.client.items, 'FreeAsso_Client');
    }
    const globalActions = getGlobalActions(this);
    const inlineActions = getInlineActions(this);
    const cols = getCols(this);
    // L'affichage, items, loading, loadMoreError
    let search = '';
    const crit = this.props.client.filters.findFirst('cli_firstname');
    if (crit) {
      search = crit.getFilterCrit();
    }
    const quickSearch = (
      <ResponsiveQuickSearch
        name="quickSearch"
        label={intl.formatMessage({
          id: 'app.features.client.list.search',
          defaultMessage: 'Search by first name, last name or email',
        })}
        quickSearch={search}
        onSubmit={this.onQuickSearch}
        icon={<SearchIcon />}
        plus={<SearchPlusIcon />}
        reset={<SearchCancelIcon />}
      />
    );
    // Select actions
    const selectActions = getSelectActions(this);
    // Inline
    let inlineComponent = null;
    switch (this.state.mode) {
      case 'donation':
        inlineComponent = <InlineDonations mode="client" parentId={this.state.item.id} />;
        break;
      case 'sponsorship':
        inlineComponent = <InlineSponsorships mode="client" parentId={this.state.item.id} />;
        break;
      case 'certificate':
        inlineComponent = <InlineCertificates mode="client" parentId={this.state.item.id} />;
        break;
      case 'receipt':
        inlineComponent = <InlineReceipts mode="client" parentId={this.state.item.id} />;
        break;
      default:
        inlineComponent = null;
        break;
    }
    return (
      <div>
        <UiList
          title={intl.formatMessage({
            id: 'app.features.client.list.title',
            defaultMessage: 'Members',
          })}
          intl={intl}
          cols={cols}
          icon={<ClientIcon />}
          items={items}
          counter={
            this.props.client.items && this.props.client.items.TOTAL
          }
          quickSearch={quickSearch}
          mainCol="cli_firstname"
          inlineActions={inlineActions}
          currentItem={this.state.item}
          currentInline={this.state.mode}
          inlineComponent={inlineComponent}
          globalActions={globalActions}
          sort={this.props.client.sort}
          filters={this.props.client.filters}
          panelObject="client"
          onSearch={this.onQuickSearch}
          onSort={this.onUpdateSort}
          onSetFiltersAndSort={this.onSetFiltersAndSort}
          onClearFilters={this.onClearFilters}
          onLoadMore={this.onLoadMore}
          onClick={this.onSelectList}
          loadMorePending={this.props.client.loadMorePending}
          loadMoreFinish={this.props.client.loadMoreFinish}
          loadMoreError={this.props.client.loadMoreError}
          selected={this.props.client.selected}
          selectMenu={selectActions}
          onSelect={this.onSelect}
        />
        {this.state.cliId >= 0 && (
          <Input
            modal={true}
            cliId={this.state.cliId}
            onClose={this.onClose}
            editions={this.state.editions}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loadTimeOut: state.auth.loadTimeOut,
    client: state.client,
    clientCategory: state.clientCategory,
    paymentType: state.paymentType,
    edition: state.edition,
    email: state.email,
    lang: state.lang,
    sponsorship: state.sponsorships,
    donation: state.donations,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, loadSponsorships, loadDonations }, dispatch),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(List));
