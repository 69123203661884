export const HOME_LOAD_ALL_BEGIN = 'HOME_LOAD_ALL_BEGIN';
export const HOME_LOAD_ALL_SUCCESS = 'HOME_LOAD_ALL_SUCCESS';
export const HOME_LOAD_ALL_FAILURE = 'HOME_LOAD_ALL_FAILURE';
export const HOME_LOAD_ALL_DISMISS_ERROR = 'HOME_LOAD_ALL_DISMISS_ERROR';
export const HOME_LOAD_TIMERS_BEGIN = 'HOME_LOAD_TIMERS_BEGIN';
export const HOME_LOAD_TIMERS_SUCCESS = 'HOME_LOAD_TIMERS_SUCCESS';
export const HOME_LOAD_TIMERS_FAILURE = 'HOME_LOAD_TIMERS_FAILURE';
export const HOME_LOAD_TIMERS_DISMISS_ERROR = 'HOME_LOAD_TIMERS_DISMISS_ERROR';
export const HOME_INIT_SOCKET = 'HOME_INIT_SOCKET';
export const HOME_CLOSE_SOCKET = 'HOME_CLOSE_SOCKET';
export const HOME_SET_LOCALE = 'HOME_SET_LOCALE';
