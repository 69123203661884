import React from 'react';
// import PropTypes from 'prop-types';

export default function Security() {
  return (
    <div className="icons-security">
      Component content: icons/Security
    </div>
  );
};

Security.propTypes = {};
Security.defaultProps = {};
