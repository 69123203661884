import React from 'react';
import { injectIntl } from 'react-intl';
import { getShortcuts, getStatus } from './';
import useForm from '../ui/useForm';
import { emailAsOptions } from '../email';
import {
  ResponsiveModalOrForm,
  InputCheckbox,
  InputHidden,
  InputText,
  InputSelect,
  InputDate,
  Row,
  Col,
} from '../ui';
import { editionAsOptions } from '../edition';
import { clientCategoryAsOptions } from '../client-category/functions.js';
import { paymentTypeAsOptions } from '../payment-type/functions.js';

/**
 * Initialisation
 *
 * @param {Object}   item
 * @param {Function} setItem
 */
const initItem = (item, setItem) => {};

/**
 * Un champ a été modifié
 *
 * @param {String}   field
 * @param {Object}   item
 * @param {Function} setitem
 */
const afterChange = (field, item, setItem) => {
  switch (field) {
    default:
      break;
  }
};

/**
 * Saisie des informations d'un modèle
 */
function Form(props) {
  let item = props.item;
  // On récupère tout ce qu'il faut pour travailler
  const {
    values,
    handleChange,
    handleSave,
    handleSubmit,
    handleCancel,
    handleNavTab,
    getErrorMessage,
    getCurrentTab,
  } = useForm(
    item,
    props.tab,
    props.onSubmit,
    props.onCancel,
    props.onNavTab,
    props.errors,
    props.intl,
    afterChange,
    initItem,
  );
  // Le formulaire
  return (
    <ResponsiveModalOrForm
      title={
        !props.modify && !values.recg_name
          ? props.intl.formatMessage({
              id: 'app.features.receipt-generation.form.title',
              defaultMessage: 'New model',
            })
          : values.recg_name
      }
      className=""
      tab={getCurrentTab()}
      tabs={
        !props.modify
          ? getShortcuts(props.intl, 'new')
          : getShortcuts(props.intl, 'all')
      }
      size="md"
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onNavTab={handleNavTab}
      onSave={handleSave}
      onClose={handleCancel}
      onPrevious={props.onPrevious}
      onNext={props.onNext}
      modal={true}
      actionsButtons={props.actionsButtons}
      loader={props.loader ? props.loader : false}
    >
      <InputHidden name="id" id="id" value={values.id} />
      <Row>
        <Col size={{ xxs: 36, sm: 24 }}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.receipt-generation.field.recg_name.label',
              defaultMessage: 'recg_name',
            })}
            id="recg_name"
            name="recg_name"
            value={values.recg_name}
            labelTop={true}
            required={true}
            onChange={handleChange}
            error={getErrorMessage('recg_name')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.receipt-generation.field.recg_year.label',
              defaultMessage: 'recg_year',
            })}
            id="recg_year"
            name="recg_year"
            value={values.recg_year}
            labelTop={true}
            required={true}
            onChange={handleChange}
            error={getErrorMessage('recg_year')}
          />
        </Col>
      </Row>
      <Row>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputSelect
            label={props.intl.formatMessage({
              id: 'app.features.receipt-generation.form.category',
              defaultMessage: 'Category',
            })}
            name="client_category.id"
            labelTop={true}
            addEmpty={true}
            value={values.client_category ? values.client_category.id : null}
            onChange={handleChange}
            options={clientCategoryAsOptions(props.clientCategory.items)}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputSelect
            label={props.intl.formatMessage({
              id: 'app.features.receipt-generation.form.payment',
              defaultMessage: 'Payment',
            })}
            name="payment_type.id"
            labelTop={true}
            addEmpty={true}
            value={values.payment_type ? values.payment_type.id : null}
            onChange={handleChange}
            options={paymentTypeAsOptions(props.paymentType.items)}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputSelect
            label={props.intl.formatMessage({
              id: 'app.features.receipt-generation.field.recg_status.label',
              defaultMessage: 'recg_status',
            })}
            id="recg_status"
            name="recg_status"
            value={values.recg_status}
            required={true}
            labelTop={true}
            options={getStatus(props.intl)}
            onChange={handleChange}
            error={getErrorMessage('recg_status')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.receipt-generation.form.cogs',
              defaultMessage: 'Pays (cog)',
            })}
            id="recg_cogs"
            name="recg_cogs"
            labelTop={true}
            value={values.recg_cogs}
            onChange={handleChange}
            error={getErrorMessage('recg_cogs')}
          />
        </Col>
      </Row>
      <Row>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.receipt-generation.form.prefix',
              defaultMessage: 'Préfixe',
            })}
            id="recg_prefix"
            name="recg_prefix"
            labelTop={true}
            value={values.recg_prefix}
            onChange={handleChange}
            error={getErrorMessage('recg_prefix')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputCheckbox
            label={props.intl.formatMessage({
              id: 'app.features.receipt-generation.form.receipt',
              defaultMessage: 'Reçus',
            })}
            name="recg_receipt"
            labelTop={true}
            checked={values.recg_receipt === true}
            onChange={handleChange}
            error={getErrorMessage('recg_receipt')}
          />
        </Col>
      </Row>
      <Row>
        <Col size={{ xs: 36, sm: 12 }}>
          <InputSelect
            label={props.intl.formatMessage({
              id: 'app.features.receipt-generation.field.edition.label',
              defaultMessage: 'Receipt edition',
            })}
            name="edition.id"
            id="edition.id"
            value={values.edition.id}
            onChange={handleChange}
            addEmpty={true}
            required={true}
            options={editionAsOptions(props.editions, ['FreeAsso_Receipt'])}
          />
        </Col>
        <Col size={{ xs: 36, sm: 24 }}>
          <InputSelect
            label={props.intl.formatMessage({
              id: 'app.features.receipt-generation.form.email',
              defaultMessage: 'Generate',
            })}
            name="email.id"
            id="email.id"
            value={values.email ? values.email.id : ''}
            onChange={handleChange}
            options={emailAsOptions(props.emails, 'FreeAsso_Client')}
            error={getErrorMessage('email.id')}
          />
        </Col>
      </Row>
      <Row>
        <Col size={{ xs: 36, sm: 12 }}>
          <InputDate
            label={props.intl.formatMessage({
              id: 'app.features.receipt-generation.form.gen',
              defaultMessage: 'Generate',
            })}
            name="recg_gen"
            id="recg_gen"
            value={values.recg_gen}
            onChange={handleChange}
            error={getErrorMessage('recg_gen')}
          />
        </Col>
        <Col size={{ xs: 36, sm: 12 }}>
          <InputDate
            label={props.intl.formatMessage({
              id: 'app.features.receipt-generation.form.withEmail',
              defaultMessage: 'Generate',
            })}
            name="recg_email"
            id="recg_email"
            value={values.recg_email}
            onChange={handleChange}
            error={getErrorMessage('recg_email')}
          />
        </Col>
        <Col size={{ xs: 36, sm: 12 }}>
          <InputDate
            label={props.intl.formatMessage({
              id: 'app.features.receipt-generation.form.withoutEmail',
              defaultMessage: 'Generate',
            })}
            name="recg_no_email"
            id="recg_no_email"
            value={values.recg_no_email}
            onChange={handleChange}
            error={getErrorMessage('recg_no_email')}
          />
        </Col>
      </Row>
    </ResponsiveModalOrForm>
  );
}

/**
 * Injection de l'intl
 */
export default injectIntl(Form);
