import { jsonApiNormalizer, normalizedObjectModeler } from 'jsonapi-front';
import { freeAssoApi } from '../../common';

/**
 * Export all species as an array of value=>label
 * 
 * @param {object} object
 * 
 * @return {array}
 */
export function freqAsOptions() {
  return [
    {value: 'MONTH', label: 'Mensuelle'},
    {value: 'YEAR', label: 'Annuelle'},
  ];
}


/**
 * 
 */
export const getOneSponsorship = id => {
  return freeAssoApi.get('/v1/asso/sponsorship/' + id + '?include=client,cause,cause.cause_type,payment_type');
}

/**
 * 
 */
export const getOneSponsorshipAsModel = id => {
  return new Promise((resolve, reject) => {
    getOneSponsorship(id).then(
      res => {
        const object = jsonApiNormalizer(res.data);
        const item = normalizedObjectModeler(object, 'FreeAsso_Sponsorship', id, { eager: true });
        resolve(item);
      },
      err => {
        reject(err);
      },
    );
  });
};
