import React, { Component } from 'react';
import Icon from '@mdi/react';
import { mdiMagnifyPlusOutline as mdiIcon } from '@mdi/js';

export default class SearchPlus extends Component {
  static propTypes = {};

  render() {
    return <Icon path={mdiIcon} {...this.props} />;
  }
}
