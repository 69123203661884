import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as actions from './redux/actions';
import { ResponsiveInlineHeader } from 'react-bootstrap-front';
import { InlinePendings, getCols } from './';
import { DashboardCard } from '../dashboard';
import { Jobqueue as JobqueueIcon } from '../icons';

export class Dashboard extends Component {
  static propTypes = {
    jobqueue: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    overlay: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.onValidateOne = this.onValidateOne.bind(this);
  }

  onValidateOne(item) {
    if (item && item.id) {
      this.props.actions.validOne(item.id).then(res => {
        this.props.actions.loadMore(true);
      });
    }
  }

  render() {
    return (
      <DashboardCard
        title="Tâches planifiées"
        icon={<JobqueueIcon />}
        overlay={this.props.overlay}
        size="lg"
        header={<ResponsiveInlineHeader cols={getCols(this)} />}
      >
        <InlinePendings mode="pending" />
      </DashboardCard>
    );
  }
}

function mapStateToProps(state) {
  return {
    jobqueue: state.jobqueue,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Dashboard),
);
