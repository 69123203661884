import React from 'react';
// import PropTypes from 'prop-types';
import { InputHidden as UIInputHidden } from 'react-bootstrap-front';

export default function InputHidden(props) {
  return <UIInputHidden {...props} />;
}

InputHidden.propTypes = {};
InputHidden.defaultProps = {};
