export const EDITION_LOAD_MORE_BEGIN = 'EDITION_LOAD_MORE_BEGIN';
export const EDITION_LOAD_MORE_SUCCESS = 'EDITION_LOAD_MORE_SUCCESS';
export const EDITION_LOAD_MORE_FAILURE = 'EDITION_LOAD_MORE_FAILURE';
export const EDITION_LOAD_MORE_DISMISS_ERROR = 'EDITION_LOAD_MORE_DISMISS_ERROR';
export const EDITION_CLEAR_ITEMS = 'EDITION_CLEAR_ITEMS';
export const EDITION_CREATE_ONE_BEGIN = 'EDITION_CREATE_ONE_BEGIN';
export const EDITION_CREATE_ONE_SUCCESS = 'EDITION_CREATE_ONE_SUCCESS';
export const EDITION_CREATE_ONE_FAILURE = 'EDITION_CREATE_ONE_FAILURE';
export const EDITION_CREATE_ONE_DISMISS_ERROR = 'EDITION_CREATE_ONE_DISMISS_ERROR';
export const EDITION_DEL_ONE_BEGIN = 'EDITION_DEL_ONE_BEGIN';
export const EDITION_DEL_ONE_SUCCESS = 'EDITION_DEL_ONE_SUCCESS';
export const EDITION_DEL_ONE_FAILURE = 'EDITION_DEL_ONE_FAILURE';
export const EDITION_DEL_ONE_DISMISS_ERROR = 'EDITION_DEL_ONE_DISMISS_ERROR';
export const EDITION_INIT_FILTERS = 'EDITION_INIT_FILTERS';
export const EDITION_INIT_SORT = 'EDITION_INIT_SORT';
export const EDITION_LOAD_ONE_BEGIN = 'EDITION_LOAD_ONE_BEGIN';
export const EDITION_LOAD_ONE_SUCCESS = 'EDITION_LOAD_ONE_SUCCESS';
export const EDITION_LOAD_ONE_FAILURE = 'EDITION_LOAD_ONE_FAILURE';
export const EDITION_LOAD_ONE_DISMISS_ERROR = 'EDITION_LOAD_ONE_DISMISS_ERROR';
export const EDITION_SET_FILTERS = 'EDITION_SET_FILTERS';
export const EDITION_SET_SORT = 'EDITION_SET_SORT';
export const EDITION_UPDATE_ONE_BEGIN = 'EDITION_UPDATE_ONE_BEGIN';
export const EDITION_UPDATE_ONE_SUCCESS = 'EDITION_UPDATE_ONE_SUCCESS';
export const EDITION_UPDATE_ONE_FAILURE = 'EDITION_UPDATE_ONE_FAILURE';
export const EDITION_UPDATE_ONE_DISMISS_ERROR = 'EDITION_UPDATE_ONE_DISMISS_ERROR';
export const EDITION_UPDATE_QUICK_SEARCH = 'EDITION_UPDATE_QUICK_SEARCH';
export const EDITION_UPDATE_SORT = 'EDITION_UPDATE_SORT';
export const EDITION_PROPAGATE = 'EDITION_PROPAGATE';
export const EDITION_LOAD_VERSIONS_INIT = 'EDITION_LOAD_VERSIONS_INIT';
export const EDITION_LOAD_VERSIONS_BEGIN = 'EDITION_LOAD_VERSIONS_BEGIN';
export const EDITION_LOAD_VERSIONS_SUCCESS = 'EDITION_LOAD_VERSIONS_SUCCESS';
export const EDITION_LOAD_VERSIONS_FAILURE = 'EDITION_LOAD_VERSIONS_FAILURE';
export const EDITION_LOAD_VERSIONS_DISMISS_ERROR = 'EDITION_LOAD_VERSIONS_DISMISS_ERROR';
export const EDITION_LOAD_ONE_VERSION_BEGIN = 'EDITION_LOAD_ONE_VERSION_BEGIN';
export const EDITION_LOAD_ONE_VERSION_SUCCESS = 'EDITION_LOAD_ONE_VERSION_SUCCESS';
export const EDITION_LOAD_ONE_VERSION_FAILURE = 'EDITION_LOAD_ONE_VERSION_FAILURE';
export const EDITION_LOAD_ONE_VERSION_DISMISS_ERROR = 'EDITION_LOAD_ONE_VERSION_DISMISS_ERROR';
