import React from 'react';
import {
  AddOne as AddOneIcon,
  GetOne as GetOneIcon,
  DelOne as DelOneIcon,
  Print as PrintIcon,
  Download as DownloadIcon,
  BookPlay as BookPlayIcon,
} from '../icons';

/**
 *
 */
export const getSendMethodLabel = (intl, item) => {
  if (intl && item) {
    return intl.formatMessage({
      id: 'app.features.receipt.mode.' + item.rec_send_method,
      defaultMessage: item.rec_send_method,
    });
  }
  return null;
};

/**
 * Get mode options
 *
 * @return {Array}
 */
export const getModeOptions = intl => {
  const arr = [
    {
      value: 'MANUAL',
      label: intl.formatMessage({
        id: 'app.features.receipt.mode.MANUAL',
        defaultMessage: 'MANUAL',
      }),
    },
    {
      value: 'AUTO',
      label: intl.formatMessage({
        id: 'app.features.receipt.mode.AUTO',
        defaultMessage: 'AUTO',
      }),
    },
  ];
  return arr;
};

/**
 * Get send method options
 *
 * @return {Array}
 */
export const getSendMethodOptions = intl => {
  const arr = [
    {
      value: 'MANUAL',
      label: intl.formatMessage({
        id: 'app.features.receipt.sendMethod.MANUAL',
        defaultMessage: 'MANUAL',
      }),
    },
    {
      value: 'EMAIL',
      label: intl.formatMessage({
        id: 'app.features.receipt.sendMethod.EMAIL',
        defaultMessage: 'EMAIL',
      }),
    },
    {
      value: 'UNKNOWN',
      label: intl.formatMessage({
        id: 'app.features.receipt.sendMethod.UNKNOWN',
        defaultMessage: 'UNKNOWN',
      }),
    },
  ];
  return arr;
};

/**
 * Shortcuts
 *
 * @param {Object} intl
 * @param {String} mode
 */
export const getShortcuts = (intl, mode = 'all') => {
  let shortcuts = [];
  shortcuts.push({
    name: 'ident',
    icon: '',
    size: 'maximized',
    display: 'block',
    position: 1,
    label: intl.formatMessage({
      id: 'app.features.receipt.form.tabs.ident',
      defaultMessage: 'Identification',
    }),
  });
  shortcuts.push({
    name: 'client',
    icon: '',
    size: 'maximized',
    display: 'block',
    position: 2,
    label: intl.formatMessage({
      id: 'app.features.receipt.form.tabs.client',
      defaultMessage: 'Donateur',
    }),
  });
  if (mode === 'all') {
  }
  return shortcuts;
};

/**
 * Gestion de l'affichage du champ en fonction du modèle
 */
export function getPickerDisplay(item) {
  let display = '';
  if (item && item.id > 0) {
    display = item.rec_fullname;
  }
  return display;
}

/**
 * Affichage des agents dans la liste des propositions de la recherhe
 * ou dans la liste proposées sous le champ en autocomplète
 */
export const displayItemPicker = item => {
  return (
    <p className="input-picker-line">
      <span className="input-picker-left">{item.rec_fullname}</span>
    </p>
  );
};

/**
 * Actions disponibles depuis l'en-tête de la liste des agents
 */
export const getGlobalActions = ({ props, onClearFilters, onCreate }) => {
  return [
    {
      name: 'create',
      label: props.intl.formatMessage({
        id: 'app.list.button.add',
        defaultMessage: 'Add',
      }),
      onClick: onCreate,
      theme: 'primary',
      icon: <AddOneIcon />,
      role: 'CREATE',
    },
  ];
};

/**
 * Actions disponibles sur la fiche (en plus de l'enregistrement et de l'annulation)
 */
export const getActionsButtons = ({ onPrint, state }) => {
  const { editions } = state;
  let myOptions = [];
  let printabled = false;
  if (editions && Array.isArray(editions) && editions.length > 0) {
    printabled = true;
    editions.forEach(edition => {
      myOptions.push({
        id: edition.id,
        label: edition.edi_name,
        onClick: () => onPrint(edition.id),
      });
    });
  }
  return [
    {
      theme: 'secondary',
      hidden: !printabled,
      options: myOptions,
      optionsAlign: 'top-left',
      function: () => onPrint(),
      icon: <PrintIcon title="Imprimer" />,
    },
  ];
};

/**
 * Actions disponibles depuis l'en-tête de la liste des agents pour agir sur les lignes de la liste
 *    Sélection lignes
 *    Dé-sélection
 *    ...
 *    Actions sur lignes sélectionnées
 *    ou sur toutes les lignes
 */
export const getSelectActions = ({ props, onSelectMenu }) => {
  let arrOne = [
    {
      name: 'selectAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.selectAll',
        defaultMessage: 'Select all',
      }),
      onClick: () => {
        onSelectMenu('selectAll');
      },
    },
  ];
  const arrAppend = [
    {
      name: 'selectNone',
      label: props.intl.formatMessage({
        id: 'app.list.options.deselectAll',
        defaultMessage: 'Deselect all',
      }),
      onClick: () => {
        onSelectMenu('selectNone');
      },
    },
    { name: 'divider' },
    {
      name: 'exportSelect',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportSelect',
        defaultMessage: 'Export selection',
      }),
      onClick: () => {
        onSelectMenu('exportSelection');
      },
    },
  ];
  if (props.receipt.selected.length > 0) {
    arrOne = arrOne.concat(arrAppend);
  }
  const arrStandard = [
    { name: 'divider' },
    {
      name: 'exportAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportAll',
        defaultMessage: 'Export all',
      }),
      onClick: () => {
        onSelectMenu('exportAll');
      },
    },
    {
      name: 'downloadAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.downloadAll',
        defaultMessage: 'Download all',
      }),
      onClick: () => {
        onSelectMenu('downloadAll');
      },
    },
  ];
  arrOne = arrOne.concat(arrStandard);
  return arrOne;
};

/**
 * Actions disponibles sur une ligne de la liste des agents au survol de cette ligne
 */
export const getInlineActions = ({
  props,
  onSelectList,
  onGetOne,
  onDelOne,
  onPrint,
  onDownload,
  onGenerate,
  state,
}) => {
  let myEditions = [];
  if (state && state.editions) {
    const { editions } = state;
    editions.forEach(edition => {
      edition.langs.forEach(lang => {
        myEditions.push({
          label: edition.edi_name + ' (' + lang + ')',
          onClick: item => onPrint(edition.id, item, lang),
        });
      });
    });
  }
  return [
    {
      name: 'download',
      label: props.intl.formatMessage({
        id: 'app.list.button.download',
        defaultMessage: 'Download',
      }),
      onClick: onDownload,
      theme: 'secondary',
      icon: <DownloadIcon />,
      role: 'ACTION',
      param: 'object',
    },
    {
      name: 'print',
      label: props.intl.formatMessage({
        id: 'app.list.button.print',
        defaultMessage: 'Download',
      }),
      onClick: onPrint,
      theme: 'secondary',
      icon: <PrintIcon />,
      role: 'PRINT',
      param: 'object',
      active: myEditions.length > 0,
      options: myEditions,
    },
    {
      name: 'generate',
      label: props.intl.formatMessage({
        id: 'app.list.button.generate',
        defaultMessage: 'Génération',
      }),
      onClick: onGenerate,
      theme: 'secondary',
      confirm: props.intl.formatMessage({
        id: 'app.list.button.receipt.generate.confirmone',
        defaultMessage: 'Confirm ?',
      }),
      icon: <BookPlayIcon />,
      role: 'ACTION',
      param: 'object',
    },
    {
      name: 'modify',
      label: props.intl.formatMessage({
        id: 'app.list.button.modify',
        defaultMessage: 'Modify',
      }),
      onClick: onGetOne,
      theme: 'secondary',
      icon: <GetOneIcon />,
      role: 'MODIFY',
    },
    {
      name: 'delete',
      label: props.intl.formatMessage({
        id: 'app.list.button.delete',
        defaultMessage: 'Delete',
      }),
      onClick: onDelOne,
      theme: 'warning',
      icon: <DelOneIcon />,
      role: 'DELETE',
    },
  ];
};

/**
 * Descriptions de toutes les colonnes de la liste
 * Même les colonnes cachées
 * Ces colonnes peuvent ou non données des champs dans les filtres
 * (une info peut de pas être affichée dans la liste mais on peut peut-être mettre un filtre dessus)
 */
export const getCols = ({ props, mode }) => {
  let cols = [
    {
      name: 'rec_id',
      label: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_id.label',
        defaultMessage: 'Id',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_id.short',
        defaultMessage: 'Id',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_id.comment',
        defaultMessage: 'Identifiany',
      }),
      col: 'rec_id',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'ID' },
      filterable: true,
    },
    {
      name: 'rec_number',
      label: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_number.label',
        defaultMessage: 'Numéro',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_number.short',
        defaultMessage: 'Numéro',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_number.comment',
        defaultMessage: 'Numéro',
      }),
      col: 'rec_number',
      size: { xxs: 36, sm: 4, md: 4 },
      title: true,
      first: true,
      sortable: true,
      selectable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'brk_id',
      label: props.intl.formatMessage({
        id: 'app.features.receipt.field.brk_id.label',
        defaultMessage: 'Broker',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt.field.brk_id.short',
        defaultMessage: 'Broker',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt.field.brk_id.comment',
        defaultMessage: 'Broker',
      }),
      col: 'brk_id',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
    },
    {
      name: 'client',
      label: props.intl.formatMessage({
        id: 'app.features.receipt.field.cli_id.label',
        defaultMessage: 'Donateur',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt.field.cli_id.short',
        defaultMessage: 'Donateur',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt.field.cli_id.comment',
        defaultMessage: 'Donateur',
      }),
      col: 'client',
      size: { xxs: 36, sm: 12, md: 5 },
      title: true,
      sortable: true,
      hidden: true,
      filterable: true,
    },
    {
      name: 'rett_id',
      label: props.intl.formatMessage({
        id: 'app.features.receipt.field.rett_id.label',
        defaultMessage: 'Type',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt.field.rett_id.short',
        defaultMessage: 'Type',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt.field.rett_id.comment',
        defaultMessage: 'Type de reçu',
      }),
      col: 'receipt_type.rett_name',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
  ];
  if (mode !== 'client') {
    cols.push(
      {
        name: 'rec_fullname',
        label: props.intl.formatMessage({
          id: 'app.features.receipt.field.rec_fullname.label',
          defaultMessage: 'Nom',
        }),
        shortLabel: props.intl.formatMessage({
          id: 'app.features.receipt.field.rec_fullname.short',
          defaultMessage: 'Nom',
        }),
        comment: props.intl.formatMessage({
          id: 'app.features.receipt.field.rec_fullname.comment',
          defaultMessage: 'Nom complet',
        }),
        col: 'rec_fullname',
        size: { xxs: 36, sm: 12, md: 6 },
        title: true,
        sortable: true,
        card: { role: 'TITLE' },
        filterable: true,
      },
      {
        name: 'cli_firstname',
        label: props.intl.formatMessage({
          id: 'app.features.receipt.field.cli_firstname.label',
          defaultMessage: 'Prénom',
        }),
        shortLabel: props.intl.formatMessage({
          id: 'app.features.receipt.field.cli_firstname.short',
          defaultMessage: 'Prénom',
        }),
        comment: props.intl.formatMessage({
          id: 'app.features.receipt.field.cli_firstname.comment',
          defaultMessage: 'Prénom',
        }),
        col: 'client.cli_firstname',
        size: { xxs: 36, sm: 12, md: 6 },
        hidden: true,
        title: false,
        sortable: true,
        filterable: true,
      },
      {
        name: 'cli_lastname',
        label: props.intl.formatMessage({
          id: 'app.features.receipt.field.cli_lastname.label',
          defaultMessage: 'Nom',
        }),
        shortLabel: props.intl.formatMessage({
          id: 'app.features.receipt.field.cli_lastname.short',
          defaultMessage: 'Nom',
        }),
        comment: props.intl.formatMessage({
          id: 'app.features.receipt.field.cli_lastname.comment',
          defaultMessage: 'Nom',
        }),
        col: 'client.cli_lastname',
        size: { xxs: 36, sm: 12, md: 6 },
        hidden: true,
        title: false,
        sortable: true,
        filterable: true,
      },
      {
        name: 'rec_address1',
        label: props.intl.formatMessage({
          id: 'app.features.receipt.field.rec_address1.label',
          defaultMessage: 'Adresse 1',
        }),
        shortLabel: props.intl.formatMessage({
          id: 'app.features.receipt.field.rec_address1.short',
          defaultMessage: 'Adresse 1',
        }),
        comment: props.intl.formatMessage({
          id: 'app.features.receipt.field.rec_address1.comment',
          defaultMessage: 'Adresse 1',
        }),
        col: 'rec_address1',
        size: { xxs: 36, sm: 12, md: 4 },
        title: true,
        sortable: true,
        hidden: true,
        card: { role: 'FIELD' },
        filterable: true,
      },
      {
        name: 'rec_address2',
        label: props.intl.formatMessage({
          id: 'app.features.receipt.field.rec_address2.label',
          defaultMessage: 'Adresse 2',
        }),
        shortLabel: props.intl.formatMessage({
          id: 'app.features.receipt.field.rec_address2.short',
          defaultMessage: 'Adresse 2',
        }),
        comment: props.intl.formatMessage({
          id: 'app.features.receipt.field.rec_address2.comment',
          defaultMessage: 'Adresse 2',
        }),
        col: 'rec_address2',
        size: { xxs: 36, sm: 12, md: 4 },
        title: true,
        sortable: true,
        hidden: true,
        card: { role: 'FIELD' },
        filterable: true,
      },
      {
        name: 'rec_address3',
        label: props.intl.formatMessage({
          id: 'app.features.receipt.field.rec_address3.label',
          defaultMessage: 'Adresse 3',
        }),
        shortLabel: props.intl.formatMessage({
          id: 'app.features.receipt.field.rec_address3.short',
          defaultMessage: 'Adresse 3',
        }),
        comment: props.intl.formatMessage({
          id: 'app.features.receipt.field.rec_address3.comment',
          defaultMessage: 'Adresse 3',
        }),
        col: 'rec_address3',
        size: { xxs: 36, sm: 12, md: 4 },
        title: true,
        sortable: true,
        hidden: true,
        card: { role: 'FIELD' },
        filterable: true,
      },
      {
        name: 'rec_cp',
        label: props.intl.formatMessage({
          id: 'app.features.receipt.field.rec_cp.label',
          defaultMessage: 'Code postal',
        }),
        shortLabel: props.intl.formatMessage({
          id: 'app.features.receipt.field.rec_cp.short',
          defaultMessage: 'Code postal',
        }),
        comment: props.intl.formatMessage({
          id: 'app.features.receipt.field.rec_cp.comment',
          defaultMessage: 'Code postal',
        }),
        col: 'rec_cp',
        size: { xxs: 36, sm: 12, md: 4 },
        title: true,
        sortable: true,
        hidden: true,
        card: { role: 'FIELD' },
        filterable: true,
      },
      {
        name: 'rec_town',
        label: props.intl.formatMessage({
          id: 'app.features.receipt.field.rec_town.label',
          defaultMessage: 'Ville',
        }),
        shortLabel: props.intl.formatMessage({
          id: 'app.features.receipt.field.rec_town.short',
          defaultMessage: 'Ville',
        }),
        comment: props.intl.formatMessage({
          id: 'app.features.receipt.field.rec_town.comment',
          defaultMessage: 'Ville',
        }),
        col: 'rec_town',
        size: { xxs: 36, sm: 12, md: 4 },
        title: true,
        sortable: true,
        hidden: true,
        card: { role: 'FIELD' },
        filterable: true,
      },
      {
        name: 'country',
        label: props.intl.formatMessage({
          id: 'app.features.receipt.field.cnty_id.label',
          defaultMessage: 'Pays',
        }),
        shortLabel: props.intl.formatMessage({
          id: 'app.features.receipt.field.cnty_id.short',
          defaultMessage: 'Pays',
        }),
        comment: props.intl.formatMessage({
          id: 'app.features.receipt.field.cnty_id.comment',
          defaultMessage: 'Pays',
        }),
        col: 'country',
        size: { xxs: 36, sm: 12, md: 4 },
        title: true,
        sortable: true,
        hidden: true,
        card: { role: 'FIELD' },
        filterable: true,
      },
      {
        name: 'lang',
        label: props.intl.formatMessage({
          id: 'app.features.receipt.field.lang_id.label',
          defaultMessage: 'Langue',
        }),
        shortLabel: props.intl.formatMessage({
          id: 'app.features.receipt.field.lang_id.short',
          defaultMessage: 'Langue',
        }),
        comment: props.intl.formatMessage({
          id: 'app.features.receipt.field.lang_id.comment',
          defaultMessage: 'Langue',
        }),
        col: 'lang',
        size: { xxs: 36, sm: 12, md: 4 },
        title: true,
        hidden: true,
        sortable: true,
        card: { role: 'FIELD' },
        filterable: true,
      },
      {
        name: 'rec_email',
        label: props.intl.formatMessage({
          id: 'app.features.receipt.field.rec_email.label',
          defaultMessage: 'Email',
        }),
        shortLabel: props.intl.formatMessage({
          id: 'app.features.receipt.field.rec_email.short',
          defaultMessage: 'Email',
        }),
        comment: props.intl.formatMessage({
          id: 'app.features.receipt.field.rec_email.comment',
          defaultMessage: 'Email',
        }),
        col: 'rec_email',
        size: { xxs: 36, sm: 12, md: 6 },
        title: true,
        type: 'mail',
        sortable: true,
        card: { role: 'FIELD' },
        filterable: true,
      },
    );
  }
  cols.push(
    {
      name: 'rec_mnt',
      label: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_mnt.label',
        defaultMessage: 'Montant',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_mnt.short',
        defaultMessage: 'Montant',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_mnt.comment',
        defaultMessage: 'Montant',
      }),
      col: 'rec_mnt',
      size: { xxs: 36, sm: 5, md: 3, xl: 3 },
      title: true,
      sortable: true,
      type: 'monetary',
      col_money: 'rec_money',
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'rec_money',
      label: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_money.label',
        defaultMessage: 'Monnaie',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_money.short',
        defaultMessage: 'Monnaie',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_money.comment',
        defaultMessage: 'Monnaie',
      }),
      col: 'rec_money',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      filterable: true,
    },
    {
      name: 'rec_year_order',
      label: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_year_order.label',
        defaultMessage: 'Ordre',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_year_order.short',
        defaultMessage: 'Ordre',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_year_order.comment',
        defaultMessage: 'Ordre',
      }),
      col: 'rec_year_order',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      filterable: true,
    },
    {
      name: 'rec_mode',
      label: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_mode.label',
        defaultMessage: 'Mode',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_mode.short',
        defaultMessage: 'Mode',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_mode.comment',
        defaultMessage: 'Mode de génération',
      }),
      col: 'rec_mode',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'rec_ts',
      label: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_ts.label',
        defaultMessage: 'Créé le',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_ts.short',
        defaultMessage: 'Créé le',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_ts.comment',
        defaultMessage: 'Date de création',
      }),
      col: 'rec_ts',
      size: { xxs: 36, sm: 4, md: 3 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: { type: 'date' },
      type: 'date',
    },
    {
      name: 'rec_gen_ts',
      label: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_gen_ts.label',
        defaultMessage: 'Généré le',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_gen_ts.short',
        defaultMessage: 'Généré le',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_gen_ts.comment',
        defaultMessage: 'Date de génération',
      }),
      col: 'rec_gen_ts',
      size: { xxs: 36, sm: 4, md: 3 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: { type: 'date' },
      type: 'date',
    },
    {
      name: 'rec_print_ts',
      label: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_print_ts.label',
        defaultMessage: 'Imprimé le',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_print_ts.short',
        defaultMessage: 'Imprimé le',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_print_ts.comment',
        defaultMessage: "Date d'impression",
      }),
      col: 'rec_print_ts',
      size: { xxs: 36, sm: 4, md: 3 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: { type: 'date' },
      type: 'date',
    },
    {
      name: 'rec_year',
      label: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_year.label',
        defaultMessage: 'Année',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_year.short',
        defaultMessage: 'Année',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_year.comment',
        defaultMessage: 'Année',
      }),
      col: 'rec_year',
      size: { xxs: 36, sm: 3, md: 3 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    }
  );
  cols.push(
    {
      name: 'rec_send_method',
      label: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_send_method.label',
        defaultMessage: 'Expédition',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_send_method.short',
        defaultMessage: 'Expédition',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_send_method.comment',
        defaultMessage: "Mode d'expédition",
      }),
      col: 'rec_send_method',
      size: { xxs: 36, sm: 4, md: 3 },
      title: true,
      sortable: true,
      fDisplay: item => getSendMethodLabel(props.intl, item),
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'rec_mnt_letter',
      label: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_mnt_letter.label',
        defaultMessage: 'Toute lettre',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_mnt_letter.short',
        defaultMessage: 'Toute lettre',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_mnt_letter.comment',
        defaultMessage: 'Montant toute lettre',
      }),
      col: 'rec_mnt_letter',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'file',
      label: props.intl.formatMessage({
        id: 'app.features.receipt.field.file_id.label',
        defaultMessage: 'Fichier',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt.field.file_id.short',
        defaultMessage: 'Fichier',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt.field.file_id.comment',
        defaultMessage: 'Fichier',
      }),
      col: 'file',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'group',
      label: props.intl.formatMessage({
        id: 'app.features.receipt.field.grp_id.label',
        defaultMessage: 'Groupe',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt.field.grp_id.short',
        defaultMessage: 'Groupe',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt.field.grp_id.comment',
        defaultMessage: 'Groupe',
      }),
      col: 'group',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'rec_receipt',
      label: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_receipt.label',
        defaultMessage: 'Reçu',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_receipt.short',
        defaultMessage: 'Reçu',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt.field.rec_receipt.comment',
        defaultMessage: 'Reçu',
      }),
      col: 'rec_receipt',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: { type: 'bool' },
      type: 'bool'
    },
  );
  return cols;
};
