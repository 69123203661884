import { List, Input, SimpleSearch } from './';

export default {
  path: '',
  name: '',
  isIndex: true,
  childRoutes: [
    { path: 'client', name: 'List', component: List, auth: 'PRIVATE' },
    { path: 'client/search', name: 'SimpleSearch', component: SimpleSearch, auth: 'PRIVATE' },
    { path: 'client/create', name: 'Create', component: Input, auth: 'PRIVATE' },
    { path: 'client/modify/:clientId', name: 'Modify', component: Input, auth: 'PRIVATE' },
  ],
};
