import React from 'react';

export default function SmallKalaLoader() {
  return (
    <div className="inline-loader">
      <div className="dot-flashing"></div>
    </div>
  );
}

SmallKalaLoader.propTypes = {};
SmallKalaLoader.defaultProps = {};
