import { RECEIPT_INIT_SORT } from './constants';

/**
 * Initialisation du tri de la liste
 */
export function initSort() {
  return {
    type: RECEIPT_INIT_SORT,
  };
}

/**
 * Reducer
 * 
 * @param {Object} state  Etat courant de la mémoire (store)
 * @param {Object} action Action à réaliser sur cet état avec options
 */
export function reducer(state, action) {
  switch (action.type) {
    case RECEIPT_INIT_SORT:
      return {
        ...state,
        sort: [{ col: 'rec_year', way: 'down' }, { col: 'rec_fullname', way: 'up' }],
      };

    default:
      return state;
  }
}
