import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { jsonApiNormalizer, normalizedObjectUpdate } from 'jsonapi-front';
import {
  DONATION_PROPAGATE,
} from './constants';

export function propagate(data, ignoreAdd = false) {
  return {
    type: DONATION_PROPAGATE,
    data: data,
    ignoreAdd: ignoreAdd,
  };
}

export function usePropagate() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => dispatch(propagate(...params)), [dispatch]);
  return { propagate: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case DONATION_PROPAGATE:
      const object = jsonApiNormalizer(action.data.data);
      const myItems = state.items;
      const news = normalizedObjectUpdate(myItems, 'FreeAsso_Donation', object, action.ignoreAdd || false);
      return {
        ...state,
        items: news,
      };

    default:
      return state;
  }
}
