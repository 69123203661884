export const ACCOUNTING_LINE_CLEAR_ITEMS = 'ACCOUNTING_LINE_CLEAR_ITEMS';
export const ACCOUNTING_LINE_CREATE_ONE_BEGIN = 'ACCOUNTING_LINE_CREATE_ONE_BEGIN';
export const ACCOUNTING_LINE_CREATE_ONE_SUCCESS = 'ACCOUNTING_LINE_CREATE_ONE_SUCCESS';
export const ACCOUNTING_LINE_CREATE_ONE_FAILURE = 'ACCOUNTING_LINE_CREATE_ONE_FAILURE';
export const ACCOUNTING_LINE_CREATE_ONE_DISMISS_ERROR = 'ACCOUNTING_LINE_CREATE_ONE_DISMISS_ERROR';
export const ACCOUNTING_LINE_DEL_ONE_BEGIN = 'ACCOUNTING_LINE_DEL_ONE_BEGIN';
export const ACCOUNTING_LINE_DEL_ONE_SUCCESS = 'ACCOUNTING_LINE_DEL_ONE_SUCCESS';
export const ACCOUNTING_LINE_DEL_ONE_FAILURE = 'ACCOUNTING_LINE_DEL_ONE_FAILURE';
export const ACCOUNTING_LINE_DEL_ONE_DISMISS_ERROR = 'ACCOUNTING_LINE_DEL_ONE_DISMISS_ERROR';
export const ACCOUNTING_LINE_EXPORT_AS_TAB_BEGIN = 'ACCOUNTING_LINE_EXPORT_AS_TAB_BEGIN';
export const ACCOUNTING_LINE_EXPORT_AS_TAB_SUCCESS = 'ACCOUNTING_LINE_EXPORT_AS_TAB_SUCCESS';
export const ACCOUNTING_LINE_EXPORT_AS_TAB_FAILURE = 'ACCOUNTING_LINE_EXPORT_AS_TAB_FAILURE';
export const ACCOUNTING_LINE_EXPORT_AS_TAB_DISMISS_ERROR = 'ACCOUNTING_LINE_EXPORT_AS_TAB_DISMISS_ERROR';
export const ACCOUNTING_LINE_EXPORT_BEGIN = 'ACCOUNTING_LINE_EXPORT_BEGIN';
export const ACCOUNTING_LINE_EXPORT_SUCCESS = 'ACCOUNTING_LINE_EXPORT_SUCCESS';
export const ACCOUNTING_LINE_EXPORT_FAILURE = 'ACCOUNTING_LINE_EXPORT_FAILURE';
export const ACCOUNTING_LINE_EXPORT_DISMISS_ERROR = 'ACCOUNTING_LINE_EXPORT_DISMISS_ERROR';
export const ACCOUNTING_LINE_INIT_FILTERS = 'ACCOUNTING_LINE_INIT_FILTERS';
export const ACCOUNTING_LINE_INIT_SORT = 'ACCOUNTING_LINE_INIT_SORT';
export const ACCOUNTING_LINE_LOAD_MORE_INIT = 'ACCOUNTING_LINE_LOAD_MORE_INIT';
export const ACCOUNTING_LINE_LOAD_MORE_BEGIN = 'ACCOUNTING_LINE_LOAD_MORE_BEGIN';
export const ACCOUNTING_LINE_LOAD_MORE_SUCCESS = 'ACCOUNTING_LINE_LOAD_MORE_SUCCESS';
export const ACCOUNTING_LINE_LOAD_MORE_FAILURE = 'ACCOUNTING_LINE_LOAD_MORE_FAILURE';
export const ACCOUNTING_LINE_LOAD_MORE_DISMISS_ERROR = 'ACCOUNTING_LINE_LOAD_MORE_DISMISS_ERROR';
export const ACCOUNTING_LINE_LOAD_ONE_BEGIN = 'ACCOUNTING_LINE_LOAD_ONE_BEGIN';
export const ACCOUNTING_LINE_LOAD_ONE_SUCCESS = 'ACCOUNTING_LINE_LOAD_ONE_SUCCESS';
export const ACCOUNTING_LINE_LOAD_ONE_FAILURE = 'ACCOUNTING_LINE_LOAD_ONE_FAILURE';
export const ACCOUNTING_LINE_LOAD_ONE_DISMISS_ERROR = 'ACCOUNTING_LINE_LOAD_ONE_DISMISS_ERROR';
export const ACCOUNTING_LINE_ON_SELECT = 'ACCOUNTING_LINE_ON_SELECT';
export const ACCOUNTING_LINE_PRINT_ONE_BEGIN = 'ACCOUNTING_LINE_PRINT_ONE_BEGIN';
export const ACCOUNTING_LINE_PRINT_ONE_SUCCESS = 'ACCOUNTING_LINE_PRINT_ONE_SUCCESS';
export const ACCOUNTING_LINE_PRINT_ONE_FAILURE = 'ACCOUNTING_LINE_PRINT_ONE_FAILURE';
export const ACCOUNTING_LINE_PRINT_ONE_DISMISS_ERROR = 'ACCOUNTING_LINE_PRINT_ONE_DISMISS_ERROR';
export const ACCOUNTING_LINE_PROPAGATE = 'ACCOUNTING_LINE_PROPAGATE';
export const ACCOUNTING_LINE_SELECT_NONE = 'ACCOUNTING_LINE_SELECT_NONE';
export const ACCOUNTING_LINE_SELECT_ALL = 'ACCOUNTING_LINE_SELECT_ALL';
export const ACCOUNTING_LINE_SET_CURRENT = 'ACCOUNTING_LINE_SET_CURRENT';
export const ACCOUNTING_LINE_SET_PREVIOUS = 'ACCOUNTING_LINE_SET_PREVIOUS';
export const ACCOUNTING_LINE_SET_NEXT_BEGIN = 'ACCOUNTING_LINE_SET_NEXT_BEGIN';
export const ACCOUNTING_LINE_SET_NEXT_SUCCESS = 'ACCOUNTING_LINE_SET_NEXT_SUCCESS';
export const ACCOUNTING_LINE_SET_NEXT_FAILURE = 'ACCOUNTING_LINE_SET_NEXT_FAILURE';
export const ACCOUNTING_LINE_SET_NEXT_DISMISS_ERROR = 'ACCOUNTING_LINE_SET_NEXT_DISMISS_ERROR';
export const ACCOUNTING_LINE_SET_FILTERS = 'ACCOUNTING_LINE_SET_FILTERS';
export const ACCOUNTING_LINE_SET_SORT = 'ACCOUNTING_LINE_SET_SORT';
export const ACCOUNTING_LINE_UPDATE_ONE_BEGIN = 'ACCOUNTING_LINE_UPDATE_ONE_BEGIN';
export const ACCOUNTING_LINE_UPDATE_ONE_SUCCESS = 'ACCOUNTING_LINE_UPDATE_ONE_SUCCESS';
export const ACCOUNTING_LINE_UPDATE_ONE_FAILURE = 'ACCOUNTING_LINE_UPDATE_ONE_FAILURE';
export const ACCOUNTING_LINE_UPDATE_ONE_DISMISS_ERROR = 'ACCOUNTING_LINE_UPDATE_ONE_DISMISS_ERROR';
export const ACCOUNTING_LINE_UPDATE_QUICK_SEARCH = 'ACCOUNTING_LINE_UPDATE_QUICK_SEARCH';
export const ACCOUNTING_LINE_UPDATE_SORT = 'ACCOUNTING_LINE_UPDATE_SORT';
