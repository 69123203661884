import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { normalizedObjectModeler } from 'jsonapi-front';
import { KalaLoader, ResponsiveInlineList } from '../ui';
import { getEditions } from '../edition';
import { getCols, getInlineActions, lineClassName, Input } from './';

export class InlinePendings extends Component {
  static propTypes = {
    jobqueue: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      id: null,
      editions: getEditions(props.edition.models, 'FreeFW_Jobqueue')
    }
    this.onValidate = this.onValidate.bind(this);
    this.onGetOne = this.onGetOne.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    this.props.actions.loadPendings(true);
  }

  onValidate(item) {
    if (item && item.id) {
      this.props.actions.validateOne(item).then(result => this.props.actions.loadPendings(true));
    }
  }

  onGetOne(id) {
    this.setState({id: id});
  }

  onClose() {
    this.setState({id: null});
  }

  render() {
    const cols = getCols(this);
    let items = [];
    let total = 0;
    if (this.props.jobqueue.pendings) {
      total = this.props.jobqueue.pendings.TOTAL;
      items = normalizedObjectModeler(this.props.jobqueue.pendings, 'FreeFW_Jobqueue');
    }
    const inlineActions = getInlineActions(this);
    return (
      <div className="jobqueue-inline-list">
        <ResponsiveInlineList
          cols={cols}
          {...this.props}
          fClassName={lineClassName}
          items={items}
          inlineActions={inlineActions}
          onMore={() => this.props.actions.loadPendings()}
          total={total}
          loading={false}
          title={false}
        />
        {this.props.jobqueue.loadPendingsPending && <KalaLoader />}
        {this.state.id && this.state.id >= 0 &&
          <Input
            modal={true}
            id={this.state.id}
            onClose={this.onClose}
            loader={false}
            editions={this.state.editions}
          />
        }
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    edition: state.edition,
    jobqueue: state.jobqueue,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(InlinePendings),
);
