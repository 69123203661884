import { getNewNormalizedObject } from 'jsonapi-front';
import { defaultFilters } from './initFilters';

const initialState = {
  items: getNewNormalizedObject('FreeAsso_Donation'),
  models: [],
  donations: [],
  donationsModels: [],
  selected: [],
  cli_id: null,
  cau_id: null,
  emptyItem: null,
  page_number: 1,
  page_size: process.env.REACT_APP_PAGE_SIZE,
  filters: defaultFilters(0),
  sort: [{ col: 'don_real_ts', way: 'down' }],
  loadMorePending: false,
  loadMoreFinish: false,
  loadMoreError: null,
  createOnePending: false,
  createOneError: null,
  delOnePending: false,
  delOneError: null,
  loadOnePending: false,
  loadOneError: null,
  updateOnePending: false,
  updateOneError: null,
  loadDonationsPending: false,
  loadDonationsError: null,
  sendOnePending: false,
  sendOneError: null,
};

export default initialState;
