import React from 'react';
// import PropTypes from 'prop-types';
import { InputText as UIInputText } from 'react-bootstrap-front';
import { LockOn as LockIcon, LockOff as UnlockIcon } from '../icons';

export default function InputText(props) {
  return (
    <UIInputText
      {...props}
      lockOnIcon={<LockIcon className="text-secondary" size={0.8} title="Verrouillé" />}
      lockOffIcon={<UnlockIcon className="text-inactive" size={0.8} title="" />}
    />
  );
}

InputText.propTypes = {};
InputText.defaultProps = {};
