import {
  CLIENT_UPDATE_QUICK_SEARCH,
} from './constants';
import {
  FILTER_MODE_AND,
  FILTER_MODE_OR,
  FILTER_OPER_SOUND_LIKE,
  FILTER_SEARCH_QUICK,
  FILTER_OPER_LIKE,
} from 'react-bootstrap-front';

export function updateQuickSearch(value, mode) {
  return {
    type: CLIENT_UPDATE_QUICK_SEARCH,
    value: value,
    mode: mode,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CLIENT_UPDATE_QUICK_SEARCH:
      let filters = state.filters;
      let mode = action.mode || 'simple';
      let oper = FILTER_OPER_LIKE;
      if (mode === 'plus') {
        oper = FILTER_OPER_SOUND_LIKE;
      }
      if (mode !== 'reset') {
        filters.init(FILTER_MODE_OR, oper);
        filters.setSearch(FILTER_SEARCH_QUICK);
        filters.addFilter('cli_firstname', action.value, oper);
        filters.addFilter('cli_lastname', action.value, oper);
        filters.addFilter('cli_email', action.value, oper);
        filters.addFilter('cli_email_2', action.value, oper);
      } else {
        filters.init(FILTER_MODE_AND, oper);
        filters.setSearch(FILTER_SEARCH_QUICK);
      }
      return {
        ...state,
        filters: filters
      };

    default:
      return state;
  }
}
