import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getNewJsonApi } from 'jsonapi-front';
import mime from 'mime-types';
import {
  CERTIFICATE_DOWNLOAD_ONE_BEGIN,
  CERTIFICATE_DOWNLOAD_ONE_SUCCESS,
  CERTIFICATE_DOWNLOAD_ONE_FAILURE,
  CERTIFICATE_DOWNLOAD_ONE_DISMISS_ERROR,
} from './constants';
import { downloadBlob } from '../../ui';
import { default as freeAssoApi } from '../../../common/api';

/**
 * Impression d'un modèle
 */
export function downloadOne(id, ediId) {
  return (dispatch) => {
    dispatch({
      type: CERTIFICATE_DOWNLOAD_ONE_BEGIN,
    });
    const promise = new Promise((resolve, reject) => {
      const options = getNewJsonApi('FreeFW_PrintOptions', id, {prt_name : 'certificate', prt_type : 'EDITION', edi_id: ediId});
      const doRequest = freeAssoApi.post('/v1/asso/certificate/download/' + id, options, {responseType: 'arraybuffer'});
      doRequest.then(
        (res) => {
          const type = res.headers['content-type'] || 'application/octet-stream';
          const extension = mime.extension(type);
          let filename = 'certificat_' + id + '.' + extension;
          /*
          const disposition = res.headers['content-disposition'] || res.headers['Content-Disposition'];
          if (disposition) {
            if (disposition && disposition.indexOf('attachment') !== -1) {
              const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              const matches = filenameRegex.exec(disposition);
              if (matches != null && matches[1]) { 
                filename = matches[1].replace(/['"]/g, '');
              }
            }
          }*/
          downloadBlob(res.data, type, filename);
          dispatch({
            type: CERTIFICATE_DOWNLOAD_ONE_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        (err) => {
          dispatch({
            type: CERTIFICATE_DOWNLOAD_ONE_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });
    return promise;
  };
}

export function dismissDownloadOneError() {
  return {
    type: CERTIFICATE_DOWNLOAD_ONE_DISMISS_ERROR,
  };
}

export function useDownloadOne() {
  const dispatch = useDispatch();

  const { downloadOnePending, downloadOneError } = useSelector(
    state => ({
      downloadOnePending: state.certificate.downloadOnePending,
      downloadOneError: state.certificate.downloadOneError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(downloadOne(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissDownloadOneError());
  }, [dispatch]);

  return {
    downloadOne: boundAction,
    downloadOnePending,
    downloadOneError,
    dismissDownloadOneError: boundDismissError,
  };
}

/**
 * Reducer
 * 
 * @param {Object} state  Etat courant de la mémoire (store)
 * @param {Object} action Action à réaliser sur cet état avec options
 */
export function reducer(state, action) {
  switch (action.type) {
    case CERTIFICATE_DOWNLOAD_ONE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        downloadOnePending: true,
        downloadOneError: null,
      };

    case CERTIFICATE_DOWNLOAD_ONE_SUCCESS:
      // The request is success
      return {
        ...state,
        downloadOnePending: false,
        downloadOneError: null,
      };

    case CERTIFICATE_DOWNLOAD_ONE_FAILURE:
      // The request is failed
      return {
        ...state,
        downloadOnePending: false,
        downloadOneError: action.data.error,
      };

    case CERTIFICATE_DOWNLOAD_ONE_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        downloadOneError: null,
      };

    default:
      return state;
  }
}
