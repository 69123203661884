export const DONATION_ORIGIN_CLEAR_ITEMS = 'DONATION_ORIGIN_CLEAR_ITEMS';
export const DONATION_ORIGIN_CREATE_ONE_BEGIN = 'DONATION_ORIGIN_CREATE_ONE_BEGIN';
export const DONATION_ORIGIN_CREATE_ONE_SUCCESS = 'DONATION_ORIGIN_CREATE_ONE_SUCCESS';
export const DONATION_ORIGIN_CREATE_ONE_FAILURE = 'DONATION_ORIGIN_CREATE_ONE_FAILURE';
export const DONATION_ORIGIN_CREATE_ONE_DISMISS_ERROR = 'DONATION_ORIGIN_CREATE_ONE_DISMISS_ERROR';
export const DONATION_ORIGIN_DEL_ONE_BEGIN = 'DONATION_ORIGIN_DEL_ONE_BEGIN';
export const DONATION_ORIGIN_DEL_ONE_SUCCESS = 'DONATION_ORIGIN_DEL_ONE_SUCCESS';
export const DONATION_ORIGIN_DEL_ONE_FAILURE = 'DONATION_ORIGIN_DEL_ONE_FAILURE';
export const DONATION_ORIGIN_DEL_ONE_DISMISS_ERROR = 'DONATION_ORIGIN_DEL_ONE_DISMISS_ERROR';
export const DONATION_ORIGIN_EXPORT_AS_TAB_BEGIN = 'DONATION_ORIGIN_EXPORT_AS_TAB_BEGIN';
export const DONATION_ORIGIN_EXPORT_AS_TAB_SUCCESS = 'DONATION_ORIGIN_EXPORT_AS_TAB_SUCCESS';
export const DONATION_ORIGIN_EXPORT_AS_TAB_FAILURE = 'DONATION_ORIGIN_EXPORT_AS_TAB_FAILURE';
export const DONATION_ORIGIN_EXPORT_AS_TAB_DISMISS_ERROR = 'DONATION_ORIGIN_EXPORT_AS_TAB_DISMISS_ERROR';
export const DONATION_ORIGIN_EXPORT_BEGIN = 'DONATION_ORIGIN_EXPORT_BEGIN';
export const DONATION_ORIGIN_EXPORT_SUCCESS = 'DONATION_ORIGIN_EXPORT_SUCCESS';
export const DONATION_ORIGIN_EXPORT_FAILURE = 'DONATION_ORIGIN_EXPORT_FAILURE';
export const DONATION_ORIGIN_EXPORT_DISMISS_ERROR = 'DONATION_ORIGIN_EXPORT_DISMISS_ERROR';
export const DONATION_ORIGIN_INIT_FILTERS = 'DONATION_ORIGIN_INIT_FILTERS';
export const DONATION_ORIGIN_INIT_SORT = 'DONATION_ORIGIN_INIT_SORT';
export const DONATION_ORIGIN_LOAD_MORE_INIT = 'DONATION_ORIGIN_LOAD_MORE_INIT';
export const DONATION_ORIGIN_LOAD_MORE_BEGIN = 'DONATION_ORIGIN_LOAD_MORE_BEGIN';
export const DONATION_ORIGIN_LOAD_MORE_SUCCESS = 'DONATION_ORIGIN_LOAD_MORE_SUCCESS';
export const DONATION_ORIGIN_LOAD_MORE_FAILURE = 'DONATION_ORIGIN_LOAD_MORE_FAILURE';
export const DONATION_ORIGIN_LOAD_MORE_DISMISS_ERROR = 'DONATION_ORIGIN_LOAD_MORE_DISMISS_ERROR';
export const DONATION_ORIGIN_LOAD_ONE_BEGIN = 'DONATION_ORIGIN_LOAD_ONE_BEGIN';
export const DONATION_ORIGIN_LOAD_ONE_SUCCESS = 'DONATION_ORIGIN_LOAD_ONE_SUCCESS';
export const DONATION_ORIGIN_LOAD_ONE_FAILURE = 'DONATION_ORIGIN_LOAD_ONE_FAILURE';
export const DONATION_ORIGIN_LOAD_ONE_DISMISS_ERROR = 'DONATION_ORIGIN_LOAD_ONE_DISMISS_ERROR';
export const DONATION_ORIGIN_ON_SELECT = 'DONATION_ORIGIN_ON_SELECT';
export const DONATION_ORIGIN_PRINT_ONE_BEGIN = 'DONATION_ORIGIN_PRINT_ONE_BEGIN';
export const DONATION_ORIGIN_PRINT_ONE_SUCCESS = 'DONATION_ORIGIN_PRINT_ONE_SUCCESS';
export const DONATION_ORIGIN_PRINT_ONE_FAILURE = 'DONATION_ORIGIN_PRINT_ONE_FAILURE';
export const DONATION_ORIGIN_PRINT_ONE_DISMISS_ERROR = 'DONATION_ORIGIN_PRINT_ONE_DISMISS_ERROR';
export const DONATION_ORIGIN_PROPAGATE = 'DONATION_ORIGIN_PROPAGATE';
export const DONATION_ORIGIN_SELECT_NONE = 'DONATION_ORIGIN_SELECT_NONE';
export const DONATION_ORIGIN_SELECT_ALL = 'DONATION_ORIGIN_SELECT_ALL';
export const DONATION_ORIGIN_SET_CURRENT = 'DONATION_ORIGIN_SET_CURRENT';
export const DONATION_ORIGIN_SET_PREVIOUS = 'DONATION_ORIGIN_SET_PREVIOUS';
export const DONATION_ORIGIN_SET_NEXT_BEGIN = 'DONATION_ORIGIN_SET_NEXT_BEGIN';
export const DONATION_ORIGIN_SET_NEXT_SUCCESS = 'DONATION_ORIGIN_SET_NEXT_SUCCESS';
export const DONATION_ORIGIN_SET_NEXT_FAILURE = 'DONATION_ORIGIN_SET_NEXT_FAILURE';
export const DONATION_ORIGIN_SET_NEXT_DISMISS_ERROR = 'DONATION_ORIGIN_SET_NEXT_DISMISS_ERROR';
export const DONATION_ORIGIN_SET_FILTERS = 'DONATION_ORIGIN_SET_FILTERS';
export const DONATION_ORIGIN_SET_SORT = 'DONATION_ORIGIN_SET_SORT';
export const DONATION_ORIGIN_UPDATE_ONE_BEGIN = 'DONATION_ORIGIN_UPDATE_ONE_BEGIN';
export const DONATION_ORIGIN_UPDATE_ONE_SUCCESS = 'DONATION_ORIGIN_UPDATE_ONE_SUCCESS';
export const DONATION_ORIGIN_UPDATE_ONE_FAILURE = 'DONATION_ORIGIN_UPDATE_ONE_FAILURE';
export const DONATION_ORIGIN_UPDATE_ONE_DISMISS_ERROR = 'DONATION_ORIGIN_UPDATE_ONE_DISMISS_ERROR';
export const DONATION_ORIGIN_UPDATE_QUICK_SEARCH = 'DONATION_ORIGIN_UPDATE_QUICK_SEARCH';
export const DONATION_ORIGIN_UPDATE_SORT = 'DONATION_ORIGIN_UPDATE_SORT';
