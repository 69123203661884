import React from 'react';
import {
  AddOne as AddOneIcon,
  GetOne as GetOneIcon,
  DelOne as DelOneIcon,
  Print as PrintIcon,
} from '../icons';

/**
 * Shortcuts
 *
 * @param {Object} intl
 * @param {String} mode
 */
export const getShortcuts = (intl, mode = 'all') => {
  let shortcuts = [];
  shortcuts.push({
    name: 'ident',
    icon: '',
    size: 'maximized',
    display: 'block',
    position: 1,
    label: intl.formatMessage({
      id: 'app.features.group.form.tabs.ident',
      defaultMessage: 'Identification',
    }),
  });
  shortcuts.push({
    name: 'ident2',
    icon: '',
    size: 'maximized',
    display: 'block',
    position: 2,
    label: intl.formatMessage({
      id: 'app.features.group.form.tabs.ident2',
      defaultMessage: 'Identification',
    }),
  });
  shortcuts.push({
    name: 'sign',
    icon: '',
    size: 'maximized',
    display: 'block',
    position: 3,
    label: intl.formatMessage({
      id: 'app.features.group.form.tabs.sign',
      defaultMessage: 'Signature',
    }),
  });
  shortcuts.push({
    name: 'sign2',
    icon: '',
    size: 'maximized',
    display: 'block',
    position: 3,
    label: intl.formatMessage({
      id: 'app.features.group.form.tabs.sign2',
      defaultMessage: 'Signature 2',
    }),
  });
  shortcuts.push({
    name: 'taxonomy',
    icon: '',
    size: 'maximized',
    display: 'block',
    position: 3,
    label: intl.formatMessage({
      id: 'app.features.group.form.tabs.taxonomy',
      defaultMessage: 'Config',
    }),
  });
  shortcuts.push({
    name: 'conf',
    icon: '',
    size: 'maximized',
    display: 'block',
    position: 3,
    label: intl.formatMessage({
      id: 'app.features.group.form.tabs.conf',
      defaultMessage: 'Config',
    }),
  });
  if (mode === 'all') {
  }
  return shortcuts;
};

/**
 * Gestion de l'affichage du champ en fonction du modèle
 */
export function getPickerDisplay(item) {
  let display = '';
  if (item && item.id > 0) {
    display = item.grp_name;
  }
  return display;
}

/**
 * Affichage des agents dans la liste des propositions de la recherhe
 * ou dans la liste proposées sous le champ en autocomplète
 */
export const displayItemPicker = item => {
  return (
    <p className="input-picker-line">
      <span className="input-picker-left">{item.grp_name}</span>
    </p>
  );
};

/**
 * Actions disponibles depuis l'en-tête de la liste des agents
 */
export const getGlobalActions = ({ props, onClearFilters, onCreate }) => {
  return [
    {
      name: 'create',
      label: props.intl.formatMessage({
        id: 'app.list.button.add',
        defaultMessage: 'Add',
      }),
      onClick: onCreate,
      theme: 'primary',
      icon: <AddOneIcon />,
      role: 'CREATE',
    },
  ];
};

/**
 * Actions disponibles sur la fiche (en plus de l'enregistrement et de l'annulation)
 */
export const getActionsButtons = ({ onPrint, state }) => {
  const { editions } = state;
  let myOptions = [];
  let printabled = false;
  if (editions && Array.isArray(editions) && editions.length > 0) {
    printabled = true;
    editions.forEach(edition => {
      myOptions.push({
        id: edition.id,
        label: edition.edi_name,
        onClick: () => onPrint(edition.id),
      });
    });
  }
  return [
    {
      theme: 'secondary',
      hidden: !printabled,
      options: myOptions,
      optionsAlign: 'top-left',
      function: () => onPrint(),
      icon: <PrintIcon title="Imprimer" />,
    },
  ];
};

/**
 * Actions disponibles depuis l'en-tête de la liste des agents pour agir sur les lignes de la liste
 *    Sélection lignes
 *    Dé-sélection
 *    ...
 *    Actions sur lignes sélectionnées
 *    ou sur toutes les lignes
 */
export const getSelectActions = ({ props, onSelectMenu }) => {
  let arrOne = [
    {
      name: 'selectAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.selectAll',
        defaultMessage: 'Select all',
      }),
      onClick: () => {
        onSelectMenu('selectAll');
      },
    },
  ];
  const arrAppend = [
    {
      name: 'selectNone',
      label: props.intl.formatMessage({
        id: 'app.list.options.deselectAll',
        defaultMessage: 'Deselect all',
      }),
      onClick: () => {
        onSelectMenu('selectNone');
      },
    },
    { name: 'divider' },
    {
      name: 'exportSelect',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportSelect',
        defaultMessage: 'Export selection',
      }),
      onClick: () => {
        onSelectMenu('exportSelection');
      },
    },
  ];
  if (props.group.selected.length > 0) {
    arrOne = arrOne.concat(arrAppend);
  }
  const arrStandard = [
    { name: 'divider' },
    {
      name: 'exportAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportAll',
        defaultMessage: 'Export all',
      }),
      onClick: () => {
        onSelectMenu('exportAll');
      },
    },
  ];
  arrOne = arrOne.concat(arrStandard);
  return arrOne;
};

/**
 * Actions disponibles sur une ligne de la liste des agents au survol de cette ligne
 */
export const getInlineActions = ({ props, onSelectList, onGetOne, onDelOne, onPrint, state }) => {
  let myEditions = [];
  if (state && state.editions) {
    const { editions } = state;
    editions.forEach(edition => {
      myEditions.push({ label: edition.edi_name, onClick: item => onPrint(edition.id, item) });
    });
  }
  return [
    {
      name: 'print',
      label: props.intl.formatMessage({
        id: 'app.list.button.print',
        defaultMessage: 'Print',
      }),
      onClick: onPrint,
      theme: 'secondary',
      icon: <PrintIcon />,
      role: 'PRINT',
      param: 'object',
      active: myEditions.length > 0,
      options: myEditions,
    },
    {
      name: 'modify',
      label: props.intl.formatMessage({
        id: 'app.list.button.modify',
        defaultMessage: 'Modify',
      }),
      onClick: onGetOne,
      theme: 'secondary',
      icon: <GetOneIcon />,
      role: 'MODIFY',
    },
    {
      name: 'delete',
      label: props.intl.formatMessage({
        id: 'app.list.button.delete',
        defaultMessage: 'Delete',
      }),
      onClick: onDelOne,
      theme: 'warning',
      icon: <DelOneIcon />,
      role: 'DELETE',
    },
  ];
};

/**
 * Descriptions de toutes les colonnes de la liste
 * Même les colonnes cachées
 * Ces colonnes peuvent ou non données des champs dans les filtres
 * (une info peut de pas être affichée dans la liste mais on peut peut-être mettre un filtre dessus)
 */
export const getCols = ({ props }) => {
  return [
    {
      name: 'grp_id',
      label: props.intl.formatMessage({
        id: 'app.features.group.field.grp_id.label',
        defaultMessage: 'grp_id',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.group.field.grp_id.short',
        defaultMessage: 'grp_id',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.group.field.grp_id.comment',
        defaultMessage: 'Identifiant du groupe',
      }),
      col: 'grp_id',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'ID' },
      filterable: true,
    },
    {
      name: 'grpt_id',
      label: props.intl.formatMessage({
        id: 'app.features.group.field.grpt_id.label',
        defaultMessage: 'grpt_id',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.group.field.grpt_id.short',
        defaultMessage: 'grpt_id',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.group.field.grpt_id.comment',
        defaultMessage: 'Identifiant du type de groupe',
      }),
      col: 'grpt_id',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      first: true,
      filterable: true,
    },
    {
      name: 'grp_code',
      label: props.intl.formatMessage({
        id: 'app.features.group.field.grp_code.label',
        defaultMessage: 'grp_code',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.group.field.grp_code.short',
        defaultMessage: 'grp_code',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.group.field.grp_code.comment',
        defaultMessage: 'Le code court du groupe',
      }),
      col: 'grp_code',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'grp_name',
      label: props.intl.formatMessage({
        id: 'app.features.group.field.grp_name.label',
        defaultMessage: 'grp_name',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.group.field.grp_name.short',
        defaultMessage: 'grp_name',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.group.field.grp_name.comment',
        defaultMessage: 'Le nom du groupe',
      }),
      col: 'grp_name',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      card: { role: 'TITLE' },
      filterable: true,
    },
    {
      name: 'grp_address1',
      label: props.intl.formatMessage({
        id: 'app.features.group.field.grp_address1.label',
        defaultMessage: 'grp_address1',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.group.field.grp_address1.short',
        defaultMessage: 'grp_address1',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.group.field.grp_address1.comment',
        defaultMessage: "La première ligne d'adresse",
      }),
      col: 'grp_address1',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'grp_address2',
      label: props.intl.formatMessage({
        id: 'app.features.group.field.grp_address2.label',
        defaultMessage: 'grp_address2',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.group.field.grp_address2.short',
        defaultMessage: 'grp_address2',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.group.field.grp_address2.comment',
        defaultMessage: "La seconde ligne d'adresse",
      }),
      col: 'grp_address2',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'grp_address3',
      label: props.intl.formatMessage({
        id: 'app.features.group.field.grp_address3.label',
        defaultMessage: 'grp_address3',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.group.field.grp_address3.short',
        defaultMessage: 'grp_address3',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.group.field.grp_address3.comment',
        defaultMessage: "La troisième ligne d'adresse",
      }),
      col: 'grp_address3',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'grp_cp',
      label: props.intl.formatMessage({
        id: 'app.features.group.field.grp_cp.label',
        defaultMessage: 'grp_cp',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.group.field.grp_cp.short',
        defaultMessage: 'grp_cp',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.group.field.grp_cp.comment',
        defaultMessage: 'Le code postal',
      }),
      col: 'grp_cp',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'grp_town',
      label: props.intl.formatMessage({
        id: 'app.features.group.field.grp_town.label',
        defaultMessage: 'grp_town',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.group.field.grp_town.short',
        defaultMessage: 'grp_town',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.group.field.grp_town.comment',
        defaultMessage: 'La ville',
      }),
      col: 'grp_town',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'cnty_id',
      label: props.intl.formatMessage({
        id: 'app.features.group.field.cnty_id.label',
        defaultMessage: 'cnty_id',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.group.field.cnty_id.short',
        defaultMessage: 'cnty_id',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.group.field.cnty_id.comment',
        defaultMessage: "L'identifiant du pays",
      }),
      col: 'cnty_id',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'lang_id',
      label: props.intl.formatMessage({
        id: 'app.features.group.field.lang_id.label',
        defaultMessage: 'lang_id',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.group.field.lang_id.short',
        defaultMessage: 'lang_id',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.group.field.lang_id.comment',
        defaultMessage: "L'identifiant de la langue par défaut pour ce groupe",
      }),
      col: 'lang_id',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'grp_from',
      label: props.intl.formatMessage({
        id: 'app.features.group.field.grp_from.label',
        defaultMessage: 'grp_from',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.group.field.grp_from.short',
        defaultMessage: 'grp_from',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.group.field.grp_from.comment',
        defaultMessage: 'Début de validité',
      }),
      col: 'grp_from',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: { type: 'datetime' },
      type: 'datetime',
    },
    {
      name: 'grp_to',
      label: props.intl.formatMessage({
        id: 'app.features.group.field.grp_to.label',
        defaultMessage: 'grp_to',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.group.field.grp_to.short',
        defaultMessage: 'grp_to',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.group.field.grp_to.comment',
        defaultMessage: 'Fin de validité',
      }),
      col: 'grp_to',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: { type: 'datetime' },
      type: 'datetime',
    },
    {
      name: 'grp_parent_id',
      label: props.intl.formatMessage({
        id: 'app.features.group.field.grp_parent_id.label',
        defaultMessage: 'grp_parent_id',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.group.field.grp_parent_id.short',
        defaultMessage: 'grp_parent_id',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.group.field.grp_parent_id.comment',
        defaultMessage: "L'identifiant du groupe parent",
      }),
      col: 'grp_parent_id',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      filterable: true,
    },
    {
      name: 'grp_money_code',
      label: props.intl.formatMessage({
        id: 'app.features.group.field.grp_money_code.label',
        defaultMessage: 'grp_money_code',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.group.field.grp_money_code.short',
        defaultMessage: 'grp_money_code',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.group.field.grp_money_code.comment',
        defaultMessage: 'Le code de la monnaie de stockage',
      }),
      col: 'grp_money_code',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      filterable: true,
    },
    {
      name: 'grp_money_input',
      label: props.intl.formatMessage({
        id: 'app.features.group.field.grp_money_input.label',
        defaultMessage: 'grp_money_input',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.group.field.grp_money_input.short',
        defaultMessage: 'grp_money_input',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.group.field.grp_money_input.comment',
        defaultMessage: 'Le code de la monnaie de saisie',
      }),
      col: 'grp_money_input',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      filterable: true,
    },
    {
      name: 'grp_logo',
      label: props.intl.formatMessage({
        id: 'app.features.group.field.grp_logo.label',
        defaultMessage: 'grp_logo',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.group.field.grp_logo.short',
        defaultMessage: 'grp_logo',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.group.field.grp_logo.comment',
        defaultMessage: 'Le logo',
      }),
      col: 'grp_logo',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      filterable: true,
    },
    {
      name: 'grp_email_header',
      label: props.intl.formatMessage({
        id: 'app.features.group.field.grp_email_header.label',
        defaultMessage: 'grp_email_header',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.group.field.grp_email_header.short',
        defaultMessage: 'grp_email_header',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.group.field.grp_email_header.comment',
        defaultMessage: "L'en-tête de page de correspondance",
      }),
      col: 'grp_email_header',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      filterable: true,
    },
    {
      name: 'grp_email_footer',
      label: props.intl.formatMessage({
        id: 'app.features.group.field.grp_email_footer.label',
        defaultMessage: 'grp_email_footer',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.group.field.grp_email_footer.short',
        defaultMessage: 'grp_email_footer',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.group.field.grp_email_footer.comment',
        defaultMessage: 'Le pied de page de correspondance',
      }),
      col: 'grp_email_footer',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      filterable: true,
    },
    {
      name: 'grp_sign',
      label: props.intl.formatMessage({
        id: 'app.features.group.field.grp_sign.label',
        defaultMessage: 'grp_sign',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.group.field.grp_sign.short',
        defaultMessage: 'grp_sign',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.group.field.grp_sign.comment',
        defaultMessage: 'La signature',
      }),
      col: 'grp_sign',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      filterable: true,
    },
    {
      name: 'grp_realm_id',
      label: props.intl.formatMessage({
        id: 'app.features.group.field.grp_realm_id.label',
        defaultMessage: 'grp_realm_id',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.group.field.grp_realm_id.short',
        defaultMessage: 'grp_realm_id',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.group.field.grp_realm_id.comment',
        defaultMessage: "L'identifiant du domaine principal, par défaut soi-même",
      }),
      col: 'grp_realm_id',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      filterable: true,
    },
    {
      name: 'grp_config',
      label: props.intl.formatMessage({
        id: 'app.features.group.field.grp_config.label',
        defaultMessage: 'grp_config',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.group.field.grp_config.short',
        defaultMessage: 'grp_config',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.group.field.grp_config.comment',
        defaultMessage: 'La configuration au format json',
      }),
      col: 'grp_config',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      filterable: true,
    },
    {
      name: 'grp_phone',
      label: props.intl.formatMessage({
        id: 'app.features.group.field.grp_phone.label',
        defaultMessage: 'grp_phone',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.group.field.grp_phone.short',
        defaultMessage: 'grp_phone',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.group.field.grp_phone.comment',
        defaultMessage: 'Le numéro de téléphone',
      }),
      col: 'grp_phone',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'grp_email',
      label: props.intl.formatMessage({
        id: 'app.features.group.field.grp_email.label',
        defaultMessage: 'grp_email',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.group.field.grp_email.short',
        defaultMessage: 'grp_email',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.group.field.grp_email.comment',
        defaultMessage: "L'email",
      }),
      col: 'grp_email',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'grp_site_url',
      label: props.intl.formatMessage({
        id: 'app.features.group.field.grp_site_url.label',
        defaultMessage: 'grp_site_url',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.group.field.grp_site_url.short',
        defaultMessage: 'grp_site_url',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.group.field.grp_site_url.comment',
        defaultMessage: "L'url du site public",
      }),
      col: 'grp_site_url',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      filterable: true,
    },
    {
      name: 'grp_email_payment',
      label: props.intl.formatMessage({
        id: 'app.features.group.field.grp_email_payment.label',
        defaultMessage: 'grp_email_payment',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.group.field.grp_email_payment.short',
        defaultMessage: 'grp_email_payment',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.group.field.grp_email_payment.comment',
        defaultMessage: 'Conditions de paiement',
      }),
      col: 'grp_email_payment',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      filterable: true,
    },
  ];
};
