import React from 'react';
import PropTypes from 'prop-types';
import { Input as InputCause } from '../cause';
import { Input as InputClient } from '../client';
import { Input as InputDonation } from '../donation';
import { Input as InputSponsorship } from '../sponsorship';

export default function ObjectViewer(props) {
  let component = null;
  switch (props.name) {
    case 'FreeAsso_Cause':
      component = <InputCause modal={true} cauId={props.id} onClose={props.onClose} />;
      break;
    case 'FreeAsso_Client':
      component = <InputClient modal={true} cliId={props.id} onClose={props.onClose} />;
      break;
    case 'FreeAsso_Donation':
      component = <InputDonation modal={true} donId={props.id} onClose={props.onClose} />;
      break;
    case 'FreeAsso_Sponsorship':
      component = <InputSponsorship modal={true} spoId={props.id} onClose={props.onClose} />;
      break;
    default:
      break;
  }
  return component;
}

ObjectViewer.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func,
};
ObjectViewer.defaultProps = {
  name: '',
  onClose: () => {},
};
