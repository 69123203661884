import React from 'react';
import { InputHidden, InputText, InputSelect } from 'react-bootstrap-front';
import { injectIntl } from 'react-intl';
import { useForm, ResponsiveModalOrForm } from '../ui';
import { emailAsOptions } from '../email';

function Form(props) {
  const { values, handleChange, handleSubmit, handleCancel, getErrorMessage } = useForm(
    props.item,
    '',
    props.onSubmit,
    props.onCancel,
    '',
    props.errors,
    props.intl
  );
  return (
    <ResponsiveModalOrForm
      size="xs"
      modal={true}
      title={props.intl.formatMessage({
        id: 'app.features.clientType.form.title',
        defaultMessage: 'Person type',
      })}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onClose={props.onClose}
    >
      <InputHidden name="id" id="id" value={values.id} />
      <div className="row">
        <div className="col-xs-w36">
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.clientType.form.name',
              defaultMessage: 'Name',
            })}
            name="clit_name"
            id="clit_name"
            required={true}
            value={values.clit_name}
            onChange={handleChange}
            error={getErrorMessage('clit_name')}
          />
        </div>
        <div className="col-xs-w36">
          <InputSelect
            label={props.intl.formatMessage({
              id: 'app.features.clientType.form.add_email',
              defaultMessage: 'Add',
            })}
            name="add_email.id"
            id="add_email.id"
            value={values.add_email ? values.add_email.id : ''}
            onChange={handleChange}
            options={emailAsOptions(props.emails, 'FreeAsso_Client')}
            error={getErrorMessage('add_email.id')}
          />
        </div>
        <div className="col-xs-w36">
          <InputSelect
            label={props.intl.formatMessage({
              id: 'app.features.clientType.form.update_email',
              defaultMessage: 'Update',
            })}
            name="update_email.id"
            id="update_email.id"
            value={values.update_email ? values.update_email.id : ''}
            onChange={handleChange}
            options={emailAsOptions(props.emails, 'FreeAsso_Client')}
            error={getErrorMessage('update_email.id')}
          />
        </div>
        <div className="col-xs-w36">
          <InputSelect
            label={props.intl.formatMessage({
              id: 'app.features.clientType.form.end_email',
              defaultMessage: 'End',
            })}
            name="end_email.id"
            id="end_email.id"
            value={values.end_email ? values.end_email.id : ''}
            onChange={handleChange}
            options={emailAsOptions(props.emails, 'FreeAsso_Client')}
            error={getErrorMessage('end_email.id')}
          />
        </div>
      </div>
    </ResponsiveModalOrForm>
  );
}

export default injectIntl(Form);
