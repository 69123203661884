import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Filter, FILTER_OPER_EQUAL, FILTER_MODE_AND } from 'react-bootstrap-front';
import {
  objectToQueryString,
  jsonApiNormalizer,
  normalizedObjectModeler,
  getNewNormalizedObject,
} from 'jsonapi-front';
import * as actions from './redux/actions';
import { downloadOne as downloadCertificate } from '../certificate/redux/actions';
import { freeAssoApi, propagateModel } from '../../common';
import {
  KalaLoader,
  sortAsJsonApiObject,
  ResponsiveInlineList,
  modifySuccess,
  showErrors,
  messageSuccess,
} from '../ui';
import { getCols, getInlineActions, Input, updateDonStatus, updateDonVerif } from './';
import { getEditions } from '../edition';

/**
 * Liste interne responsive, pour la recherche ou l'affichage
 *
 * C'est à l'appelant de gérer la recherche et les actions.
 */
export class InlineList extends Component {
  static propTypes = {
    items: PropTypes.array,
    loading: PropTypes.bool,
  };
  static defaultProps = {
    items: [],
    loading: false,
  };

  /**
   * Update state, ...
   *
   * @param {Object} props
   * @param {Object} state
   */
  static getDerivedStateFromProps(props, state) {
    if (props.parentId !== state.parentId || props.mode !== state.mode) {
      let filters = state.filters;
      if (props.mode === 'cause') {
        filters.addFilter('cau_id', props.parentId, FILTER_OPER_EQUAL, true);
      }
      if (props.mode === 'client') {
        filters.addFilter('cli_id', props.parentId, FILTER_OPER_EQUAL, true);
      }
      if (props.mode === 'sponsorship') {
        filters.addFilter('spo_id', props.parentId, FILTER_OPER_EQUAL, true);
      }
      return {
        parentId: props.parentId,
        mode: props.mode,
        normalized: getNewNormalizedObject('FreeAsso_Donation'),
        editions: getEditions(props.edition.models, 'FreeAsso_Donation'),
        certEditions: getEditions(props.edition.models, 'FreeAsso_Certificate'),
        items: [],
        filters: filters,
        currentPage: 1,
        currentCount: 0,
      };
    }
    return null;
  }

  /**
   * Constructor
   *
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    const parentId = props.parentId || null;
    const filters = props.filters || new Filter();
    filters.init(FILTER_MODE_AND, FILTER_OPER_EQUAL);
    const sort = props.sort || [{ col: 'don_real_ts', way: 'down' }];
    if (props.mode === 'cause') {
      filters.addFilter('cau_id', parentId, FILTER_OPER_EQUAL, true);
    }
    if (props.mode === 'client') {
      filters.addFilter('cli_id', parentId, FILTER_OPER_EQUAL, true);
    }
    if (props.mode === 'sponsorship') {
      filters.addFilter('spo_id', parentId, FILTER_OPER_EQUAL, true);
    }
    // State
    this.state = {
      id: -1,
      parentId: parentId,
      filters: filters,
      mode: props.mode,
      sort: sort,
      normalized: getNewNormalizedObject('FreeAsso_Donation'),
      editions: getEditions(props.edition.models, 'FreeAsso_Donation'),
      certEditions: getEditions(props.edition.models, 'FreeAsso_Certificate'),
      items: [],
      currentPage: 1,
      currentCount: 0,
      loadingItems: true,
      confirm: false,
    };
    // Binds
    this.localLoad = this.localLoad.bind(this);
    this.onAddOne = this.onAddOne.bind(this);
    this.onGetOne = this.onGetOne.bind(this);
    this.onDelOne = this.onDelOne.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onConfirmOpen = this.onConfirmOpen.bind(this);
    this.onConfirmClose = this.onConfirmClose.bind(this);
    this.getClassName = this.getClassName.bind(this);
    this.onPayOn = this.onPayOn.bind(this);
    this.onPayOff = this.onPayOff.bind(this);
    this.onSendOne = this.onSendOne.bind(this);
    this.breakpoint = this.breakpoint.bind(this);
    this.onPrintOne = this.onPrintOne.bind(this);
    this.onMatched = this.onMatched.bind(this);
    this.onUnmatched = this.onUnmatched.bind(this);
    this.onCertificate = this.onCertificate.bind(this);
  }

  /**
   * On mount
   */
  componentDidMount() {
    this.localLoad(true);
  }

  /**
   * Update
   *
   * @param {Object} prevProps
   * @param {Object} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.state.parentId !== prevState.parentId || this.state.mode !== prevState.mode) {
      this.localLoad(true);
    }
  }

  /**
   * Load items
   *
   * @param {Bool} reset
   */
  localLoad(reset = true) {
    let update = { loadingItems: true };
    let currentPage = this.state.currentPage;
    if (reset) {
      update = {
        ...update,
        normalized: getNewNormalizedObject('FreeAsso_Donation'),
        items: [],
        currentPage: 1,
        currentCount: 0,
      };
    } else {
      currentPage++;
      update = {
        ...update,
        currentPage: currentPage,
      };
    }
    this.setState({ ...update });
    let filters = this.state.filters;
    let sort = sortAsJsonApiObject(this.state.sort);
    let params = {
      ...filters.asJsonApiObject(),
      ...sort,
      page: { number: currentPage, size: 25 },
      option: { nogroup: true },
    };
    const addUrl = objectToQueryString(params);
    const doRequest = freeAssoApi.get('/v1/asso/donation' + addUrl, {});
    doRequest.then(result => {
      if (result && result.data) {
        let normalized = [];
        if (!reset) {
          normalized = jsonApiNormalizer(result.data, this.state.normalized);
        } else {
          normalized = jsonApiNormalizer(result.data);
        }
        this.setState({
          normalized: normalized,
          loadingItems: false,
          currentCount: normalized.TOTAL || 0,
          items: normalizedObjectModeler(normalized, 'FreeAsso_Donation'),
        });
      } else {
        this.setState({
          normalized: getNewNormalizedObject('FreeAsso_Donation'),
          loadingItems: false,
        });
      }
    });
  }

  onAddOne() {
    this.setState({ id: 0 });
  }

  onGetOne(id) {
    this.setState({ id: parseInt(id, 10) });
  }

  onDelOne(id) {
    this.props.actions
      .delOne(id)
      .then(result => {
        this.localLoad();
      })
      .catch(errors => {
        showErrors(this.props.intl, errors);
      });
  }

  onClose() {
    this.setState({ id: -1 });
    this.localLoad(true);
  }

  onConfirmOpen(id) {
    this.setState({ confirm: true, id: parseInt(id, 10) });
  }

  onConfirmClose() {
    this.setState({ confirm: false, id: -1 });
  }

  onPayOn(item) {
    if (item && item.id) {
      updateDonStatus(item.id, 'OK')
        .then(result => {
          modifySuccess();
          this.props.actions.propagateModel('FreeAsso_Donation', result);
          this.localLoad();
        })
        .catch(errors => {
          showErrors(this.props.intl, errors);
        });
    }
  }

  onPayOff(item) {
    if (item && item.id) {
      updateDonStatus(item.id, 'NOK')
        .then(result => {
          modifySuccess();
          this.props.actions.propagateModel('FreeAsso_Donation', result);
          this.localLoad();
        })
        .catch(errors => {
          showErrors(this.props.intl, errors);
        });
    }
  }

  /**
   * Download
   *
   * @param {Object} item  Objet à imprimer
   */
  onCertificate(item) {
    if (item && item.certificate) {
      this.props.actions.downloadCertificate(item.certificate.id);
    }
  }

  getClassName(item) {
    let cls = '';
    if (item) {
      if (cls === '') {
        if (item.don_status === 'NOK') {
          cls = 'row-line-warning';
        } else if (item.don_status === 'WAIT') {
          cls = 'row-line-info';
        } else {
          if (item.cause) {
            if (item.cause.cau_to) {
              cls = 'row-line-dark';
            }
          }
        }
      }
    }
    return cls;
  }

  onSendOne(item) {
    if (item && item.id) {
      this.props.actions
        .sendOne(item.id)
        .then(result => {
          // Message de confirmation
          messageSuccess(
            this.props.intl.formatMessage({
              id: 'app.message.send.ok',
              defaultMessage: 'Document sent',
            }),
          );
        })
        .catch(errors => {
          // Affichage des erreurs
          showErrors(this.props.intl, errors);
        });
    }
  }

  onPrintOne(ediId, cliId) {
    if (ediId && cliId) {
      this.props.actions.printOne(cliId, ediId);
      messageSuccess(
        this.props.intl.formatMessage({ id: 'app.message.print.ok', defaultMessage: 'Print' }),
      );
    }
  }

  breakpoint(before, current) {
    if (before && current) {
      const d1 = new Date(before.don_real_ts);
      const d2 = new Date(current.don_real_ts);
      if (d1.getFullYear() !== d2.getFullYear()) {
        return (
          <span className="text-secondary">
            <b>{d2.getFullYear()}</b>
          </span>
        );
      }
    }
    return false;
  }

  onMatched(item) {
    if (item && item.id) {
      updateDonVerif(item.id, 'MANUAL')
        .then(result => {
          modifySuccess();
          this.props.actions.propagateModel('FreeAsso_Donation', result);
          this.localLoad();
        })
        .catch(errors => {
          showErrors(this.props.intl, errors);
        });
    }
  }

  onUnmatched(item) {
    if (item && item.id) {
      updateDonVerif(item.id, 'NONE')
        .then(result => {
          modifySuccess();
          this.props.actions.propagateModel('FreeAsso_Donation', result);
          this.localLoad();
        })
        .catch(errors => {
          showErrors(this.props.intl, errors);
        });
    }
  }

  render() {
    const cols = getCols({ ...this, mode: this.state.mode });
    const inlineActions = getInlineActions(this);
    return (
      <div className="donation-inline-list">
        <ResponsiveInlineList
          cols={cols}
          {...this.props}
          items={this.state.items}
          onAddOne={this.onAddOne}
          onGetOne={this.onGetOne}
          onDelOne={this.onDelOne}
          inlineActions={inlineActions}
          onMore={() => this.localLoad(false)}
          total={this.state.currentCount}
          loading={this.state.loadingItems}
          onConfirm={id => this.onConfirmOpen(id)}
          fClassName={this.getClassName}
          breakpoint={this.breakpoint}
        />
        {this.state.loadingItems && <KalaLoader />}
        <div>
          {!this.state.confirm && this.state.id === 0 && (
            <Input
              onClose={this.onClose}
              mode={this.props.mode}
              parentId={this.state.parentId}
              objParent={this.props.objParent}
            />
          )}
          {!this.state.confirm && this.state.id > 0 && (
            <Input onClose={this.onClose} donId={this.state.id} />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    edition: state.edition,
    paymentType: state.paymentType,
    realm: state.auth.realm,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, propagateModel, downloadCertificate }, dispatch),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(InlineList));
