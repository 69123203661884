import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getNewJsonApi } from 'jsonapi-front';
import mime from 'mime-types';
import {
  CERTIFICATE_PRINT_ONE_BEGIN,
  CERTIFICATE_PRINT_ONE_SUCCESS,
  CERTIFICATE_PRINT_ONE_FAILURE,
  CERTIFICATE_PRINT_ONE_DISMISS_ERROR,
} from './constants';
import { downloadBlob } from '../../ui';
import { default as freeAssoApi } from '../../../common/api';

/**
 * Impression d'un modèle
 */
export function printOne(id, ediId, lang = '') {
  return dispatch => {
    dispatch({
      type: CERTIFICATE_PRINT_ONE_BEGIN,
    });
    const promise = new Promise((resolve, reject) => {
      const options = getNewJsonApi('FreeFW_PrintOptions', id, {
        prt_name: 'Certificat',
        prt_lang: lang,
        prt_type: 'EDITION',
        edi_id: ediId,
      });
      const doRequest = freeAssoApi.post('/v1/asso/certificate/print/' + id, options, {
        responseType: 'arraybuffer',
      });
      doRequest.then(
        res => {
          const type = res.headers['content-type'] || 'application/octet-stream';
          const extension = mime.extension(type);
          let filename = 'certificat_' + id + '.' + extension;
          /*
          const disposition = res.headers['content-disposition'] || res.headers['Content-Disposition'];
          if (disposition) {
            if (disposition && disposition.indexOf('attachment') !== -1) {
              const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              const matches = filenameRegex.exec(disposition);
              if (matches != null && matches[1]) { 
                filename = matches[1].replace(/['"]/g, '');
              }
            }
          }*/
          downloadBlob(res.data, type, filename);
          dispatch({
            type: CERTIFICATE_PRINT_ONE_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        err => {
          dispatch({
            type: CERTIFICATE_PRINT_ONE_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });
    return promise;
  };
}

export function dismissPrintOneError() {
  return {
    type: CERTIFICATE_PRINT_ONE_DISMISS_ERROR,
  };
}

export function usePrintOne() {
  const dispatch = useDispatch();

  const { printOnePending, printOneError } = useSelector(
    state => ({
      printOnePending: state.certificate.printOnePending,
      printOneError: state.certificate.printOneError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(printOne(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissPrintOneError());
  }, [dispatch]);

  return {
    printOne: boundAction,
    printOnePending,
    printOneError,
    dismissPrintOneError: boundDismissError,
  };
}

/**
 * Reducer
 *
 * @param {Object} state  Etat courant de la mémoire (store)
 * @param {Object} action Action à réaliser sur cet état avec options
 */
export function reducer(state, action) {
  switch (action.type) {
    case CERTIFICATE_PRINT_ONE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        printOnePending: true,
        printOneError: null,
      };

    case CERTIFICATE_PRINT_ONE_SUCCESS:
      // The request is success
      return {
        ...state,
        printOnePending: false,
        printOneError: null,
      };

    case CERTIFICATE_PRINT_ONE_FAILURE:
      // The request is failed
      return {
        ...state,
        printOnePending: false,
        printOneError: action.data.error,
      };

    case CERTIFICATE_PRINT_ONE_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        printOneError: null,
      };

    default:
      return state;
  }
}
