import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as actions from './redux/actions';
import { propagateModel } from '../../common';
import { PortalLoader, createSuccess, modifySuccess, showErrors, messageSuccess } from '../ui';
import { getEditions } from '../edition';
import { Siret } from '../api';
import Form from './Form';
import { getActionsButtons } from './';
import { clientCategoryAsOptions, clientCategoryFields } from '../client-category/functions.js';
import { clientTypeAsOptions } from '../client-type/functions.js';
import { countryAsOptions } from '../country/functions.js';
import { langAsOptions } from '../lang/functions.js';

export class Input extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    editions: PropTypes.array,
    loader: PropTypes.bool,
  };

  static defaultProps = {
    editions: [],
    loader: true,
  };

  constructor(props) {
    super(props);
    const editions = getEditions(props.edition.models, 'FreeAsso_Client');
    this.state = {
      editions: editions,
      id: this.props.cliId || 0,
      item: false,
      saving: false,
      siret: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onPrint = this.onPrint.bind(this);
    this.onLoadNamesake = this.onLoadNamesake.bind(this);
    this.onCloseSiret = this.onCloseSiret.bind(this);
    this.onOpenSiret = this.onOpenSiret.bind(this);
    this.onSelectSiret = this.onSelectSiret.bind(this);
  }

  componentDidMount() {
    this.props.actions.loadOne(this.state.id).then(item => {
      this.setState({ item: item });
    });
  }

  onCancel(event) {
    if (event) {
      event.preventDefault();
    }
    this.props.onClose();
  }

  onSubmit(datas = {}, close = true) {
    this.setState({ saving: true });
    if (this.state.id > 0) {
      this.props.actions
        .updateOne(this.state.id, datas)
        .then(item => {
          modifySuccess();
          if (this.props.onClose && close) {
            this.setState({ saving: false });
            this.props.onClose(item);
          } else {
            this.setState({ item: item, saving: false });
          }
        })
        .catch(errors => {
          this.setState({ saving: false });
          showErrors(this.props.intl, errors);
        });
    } else {
      this.props.actions
        .createOne(datas)
        .then(item => {
          createSuccess();
          if (this.props.onClose && close) {
            this.setState({ saving: false });
            this.props.onClose(item);
          } else {
            this.setState({ id: item.id, item: item, saving: false });
          }
        })
        .catch(errors => {
          this.setState({ saving: false });
          showErrors(this.props.intl, errors);
        });
    }
  }

  onPrint(id) {
    console.log(id);
    if (id) {
      this.props.actions.printOne(this.state.id, id);
      messageSuccess(
        this.props.intl.formatMessage({ id: 'app.message.print.ok', defaultMessage: 'Print' }),
      );
    }
  }

  onLoadNamesake(namesake) {
    if (namesake) {
      this.props.actions.loadOne(namesake.id).then(item => {
        item.__checkClient = null;
        this.setState({ item: item, id: namesake.id });
      });
    }
  }

  onOpenSiret() {
    this.setState({ siret: true });
  }

  onCloseSiret() {
    this.setState({ siret: false });
  }

  onSelectSiret(elem) {
    if (elem) {
      let item = this.state.item;
      item.__forced_items = [];
      if (elem.nom) {
        item.cli_lastname = elem.nom;
        item.__forced = true;
        item.__forced_items.push('cli_lastname');
      }
      if (elem.siren) {
        item.cli_siren = elem.siren;
        item.__forced = true;
        item.__forced_items.push('cli_siren');
      }
      if (elem.cp) {
        item.cli_cp = elem.cp;
        item.__forced = true;
        item.__forced_items.push('cli_cp');
      }
      if (elem.ville) {
        item.cli_town = elem.ville;
        item.__forced = true;
        item.__forced_items.push('cli_town');
      }
      if (elem.categorie) {
        item.cli_social_reason = elem.categorie;
        item.__forced = true;
        item.__forced_items.push('cli_social_reason');
      }
      this.setState({ item: { ...item } });
    }
    this.setState({ siret: false });
  }

  render() {
    const item = this.state.item;
    return (
      <div className="client-input global-card">
        {!item ? (
          <PortalLoader show={this.props.loader} />
        ) : (
          <div>
            {item && (
              <Form
                tab={this.props.client.tab}
                item={item}
                scope={this.props.auth.user ? this.props.auth.user.user_scope : ''}
                modify={this.state.id > 0}
                clientTypeOptions={clientTypeAsOptions(this.props.clientType.items)}
                clientCategoryOptions={clientCategoryAsOptions(this.props.clientCategory.items)}
                paymentType={this.props.paymentType}
                countryOptions={countryAsOptions(this.props.country.items)}
                langOptions={langAsOptions(this.props.lang.items)}
                fields={clientCategoryFields(this.props.clientCategory.items)}
                lang={this.props.lang}
                clientCategory={this.props.clientCategory}
                clientType={this.props.clientType}
                country={this.props.country}
                errors={
                  this.state.id > 0
                    ? this.props.client.updateOneError
                    : this.props.client.createOneError
                }
                actionsButtons={getActionsButtons(this)}
                onSubmit={this.onSubmit}
                onCancel={this.onCancel}
                onLoadNamesake={this.onLoadNamesake}
                onClose={this.props.onClose}
                saving={this.state.saving}
              />
            )}
            {this.state.siret && (
              <Siret
                nom={item.cli_lastname}
                cp={item.cli_cp}
                ville={item.cli_town}
                siren={item.cli_siren}
                categorie={item.cli_social_reason}
                onClose={this.onCloseSiret}
                onSubmit={this.onSelectSiret}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    client: state.client,
    clientType: state.clientType,
    clientCategory: state.clientCategory,
    paymentType: state.paymentType,
    country: state.country,
    edition: state.edition,
    lang: state.lang,
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, propagateModel }, dispatch),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Input));
