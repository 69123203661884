import React from 'react';
import {
  AddOne as AddOneIcon,
  GetOne as GetOneIcon,
  DelOne as DelOneIcon,
  Print as PrintIcon,
  Reload as ReloadIcon,
  Download as DownloadIcon,
} from '../icons';

/**
 * Shortcuts
 *
 * @param {Object} intl
 * @param {String} mode
 */
export const getShortcuts = (intl, mode = 'all') => {
  let shortcuts = [];
  shortcuts.push({
    name: 'ident',
    icon: '',
    size: 'maximized',
    display: 'block',
    position: 1,
    label: intl.formatMessage({
      id: 'app.features.certificate.form.tabs.ident',
      defaultMessage: 'Identification',
    }),
  });
  shortcuts.push({
    name: 'tech',
    icon: '',
    size: 'maximized',
    display: 'block',
    position: 1,
    label: intl.formatMessage({
      id: 'app.features.certificate.form.tabs.tech',
      defaultMessage: 'Technique',
    }),
  });
  if (mode === 'all') {
  }
  return shortcuts;
};

/**
 * Gestion de l'affichage du champ en fonction du modèle
 */
export function getPickerDisplay(item) {
  let display = '';
  if (item && item.id > 0) {
    display = item.cert_fullname;
  }
  return display;
}

/**
 * Affichage des agents dans la liste des propositions de la recherhe
 * ou dans la liste proposées sous le champ en autocomplète
 */
export const displayItemPicker = item => {
  return (
    <p className="input-picker-line">
      <span className="input-picker-left">{item.cert_fullname}</span>
    </p>
  );
};

/**
 * Actions disponibles depuis l'en-tête de la liste des agents
 */
export const getGlobalActions = ({ props, onClearFilters, onCreate }) => {
  return [
    {
      name: 'create',
      label: props.intl.formatMessage({
        id: 'app.list.button.add',
        defaultMessage: 'Add',
      }),
      onClick: onCreate,
      theme: 'primary',
      icon: <AddOneIcon />,
      role: 'CREATE',
    },
  ];
};

/**
 * Actions disponibles sur la fiche (en plus de l'enregistrement et de l'annulation)
 */
export const getActionsButtons = ({ onPrint, state }) => {
  const { editions } = state;
  let myOptions = [];
  let printabled = false;
  if (editions && Array.isArray(editions) && editions.length > 0) {
    printabled = true;
    editions.forEach(edition => {
      myOptions.push({
        id: edition.id,
        label: edition.edi_name,
        onClick: () => onPrint(edition.id),
      });
    });
  }
  return [
    {
      theme: 'secondary',
      hidden: !printabled,
      options: myOptions,
      optionsAlign: 'top-left',
      function: () => onPrint(),
      icon: <PrintIcon title="Imprimer" />,
    },
  ];
};

/**
 * Actions disponibles depuis l'en-tête de la liste des agents pour agir sur les lignes de la liste
 *    Sélection lignes
 *    Dé-sélection
 *    ...
 *    Actions sur lignes sélectionnées
 *    ou sur toutes les lignes
 */
export const getSelectActions = ({ props, onSelectMenu }) => {
  let arrOne = [
    {
      name: 'selectAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.selectAll',
        defaultMessage: 'Select all',
      }),
      onClick: () => {
        onSelectMenu('selectAll');
      },
    },
  ];
  const arrAppend = [
    {
      name: 'selectNone',
      label: props.intl.formatMessage({
        id: 'app.list.options.deselectAll',
        defaultMessage: 'Deselect all',
      }),
      onClick: () => {
        onSelectMenu('selectNone');
      },
    },
    { name: 'divider' },
    {
      name: 'exportSelect',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportSelect',
        defaultMessage: 'Export selection',
      }),
      onClick: () => {
        onSelectMenu('exportSelection');
      },
    },
  ];
  if (props.certificate.selected.length > 0) {
    arrOne = arrOne.concat(arrAppend);
  }
  const arrStandard = [
    { name: 'divider' },
    {
      name: 'exportAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportAll',
        defaultMessage: 'Export all',
      }),
      onClick: () => {
        onSelectMenu('exportAll');
      },
    },
  ];
  arrOne = arrOne.concat(arrStandard);
  return arrOne;
};

/**
 * Actions disponibles sur une ligne de la liste des agents au survol de cette ligne
 */
export const getInlineActions = ({
  props,
  onSelectList,
  onGetOne,
  onDelOne,
  onPrint,
  onDownload,
  onGenerateOne,
  state,
}) => {
  let myEditions = [];
  if (state && state.editions) {
    const { editions } = state;
    editions.forEach(edition => {
      edition.langs.forEach(lang => {
        myEditions.push({
          label: edition.edi_name + ' (' + lang + ')',
          onClick: item => onPrint(edition.id, item, lang),
        });
      });
    });
  }
  let actions = [];
  if (onGenerateOne) {
    actions.push({
      name: 'generate',
      label: props.intl.formatMessage({
        id: 'app.list.button.generate',
        defaultMessage: 'Génération',
      }),
      onClick: onGenerateOne,
      confirm: props.intl.formatMessage({
        id: 'app.list.button.generate.confirm',
        defaultMessage: 'Confirm ?',
      }),
      theme: 'secondary',
      icon: <ReloadIcon />,
      role: 'ACTION',
      param: 'object',
    });
  }
  if (onDownload) {
    actions.push({
      name: 'download',
      label: props.intl.formatMessage({
        id: 'app.list.button.download',
        defaultMessage: 'Download',
      }),
      onClick: onDownload,
      theme: 'secondary',
      icon: <DownloadIcon />,
      role: 'ACTION',
      param: 'object',
      fShow: item => {
        return item.file && parseInt(item.file.id, 10) > 0 ? true : false;
      },
    });
  }
  if (onPrint) {
    actions.push({
      name: 'print',
      label: props.intl.formatMessage({
        id: 'app.list.button.print',
        defaultMessage: 'Print',
      }),
      onClick: onPrint,
      theme: 'secondary',
      icon: <PrintIcon />,
      role: 'PRINT',
      param: 'object',
      active: myEditions.length > 0,
      options: myEditions,
    });
  }
  actions.push(
    {
      name: 'modify',
      label: props.intl.formatMessage({
        id: 'app.list.button.modify',
        defaultMessage: 'Modify',
      }),
      onClick: onGetOne,
      theme: 'secondary',
      icon: <GetOneIcon />,
      role: 'MODIFY',
    },
    {
      name: 'delete',
      label: props.intl.formatMessage({
        id: 'app.list.button.delete',
        defaultMessage: 'Delete',
      }),
      onClick: onDelOne,
      theme: 'warning',
      icon: <DelOneIcon />,
      role: 'DELETE',
    },
  );
  return actions;
};

/**
 * Descriptions de toutes les colonnes de la liste
 * Même les colonnes cachées
 * Ces colonnes peuvent ou non données des champs dans les filtres
 * (une info peut de pas être affichée dans la liste mais on peut peut-être mettre un filtre dessus)
 */
export const getCols = ({ props }) => {
  return [
    {
      name: 'cert_id',
      label: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_id.label',
        defaultMessage: 'Id.',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_id.short',
        defaultMessage: 'Id.',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_id.comment',
        defaultMessage: 'Identifiant',
      }),
      col: 'cert_id',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'ID' },
      filterable: true,
    },
    {
      name: 'client',
      label: props.intl.formatMessage({
        id: 'app.features.certificate.field.cli_id.label',
        defaultMessage: 'Donateur',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.certificate.field.cli_id.short',
        defaultMessage: 'Donateur',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.certificate.field.cli_id.comment',
        defaultMessage: 'Donateur',
      }),
      col: 'client',
      size: { xxs: 36, sm: 12, md: 6 },
      hidden: true,
    },
    {
      name: 'cert_fullname',
      label: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_fullname.label',
        defaultMessage: 'Nom complet',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_fullname.short',
        defaultMessage: 'Nom complet',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_fullname.comment',
        defaultMessage: 'Nom complet du donateur',
      }),
      col: 'cert_fullname',
      size: { xxs: 36, sm: 8, md: 6 },
      title: true,
      sortable: true,
      card: { role: 'TITLE' },
      filterable: true,
    },
    {
      name: 'cert_ts',
      label: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_ts.label',
        defaultMessage: 'Création',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_ts.short',
        defaultMessage: 'Création',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_ts.comment',
        defaultMessage: 'Date de création',
      }),
      col: 'cert_ts',
      size: { xxs: 36, sm: 5, md: 3 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: { type: 'date' },
      type: 'date',
    },
    {
      name: 'cert_gen_ts',
      label: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_gen_ts.label',
        defaultMessage: 'Généré le',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_gen_ts.short',
        defaultMessage: 'Généré le',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_gen_ts.comment',
        defaultMessage: 'Date de génération',
      }),
      col: 'cert_gen_ts',
      size: { xxs: 36, sm: 5, md: 3 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: { type: 'date' },
      type: 'date',
    },
    {
      name: 'cert_print_ts',
      label: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_print_ts.label',
        defaultMessage: 'Imprimé le',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_print_ts.short',
        defaultMessage: 'Imprimé le',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_print_ts.comment',
        defaultMessage: "Date d'impression",
      }),
      col: 'cert_print_ts',
      size: { xxs: 36, sm: 5, md: 3 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: { type: 'date' },
      type: 'date',
    },
    {
      name: 'cert_address1',
      label: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_address1.label',
        defaultMessage: 'Adresse 1',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_address1.short',
        defaultMessage: 'Adresse 1',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_address1.comment',
        defaultMessage: 'Adresse 1',
      }),
      col: 'cert_address1',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'cert_address2',
      label: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_address2.label',
        defaultMessage: 'Adresse 2',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_address2.short',
        defaultMessage: 'Adresse 2',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_address2.comment',
        defaultMessage: 'Adresse 2',
      }),
      col: 'cert_address2',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'cert_address3',
      label: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_address3.label',
        defaultMessage: 'Adresse 3',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_address3.short',
        defaultMessage: 'Adresse 3',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_address3.comment',
        defaultMessage: 'Adresse 3',
      }),
      col: 'cert_address3',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'cert_cp',
      label: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_cp.label',
        defaultMessage: 'Code postal',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_cp.short',
        defaultMessage: 'Code postal',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_cp.comment',
        defaultMessage: 'Code postal',
      }),
      col: 'cert_cp',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      hidden: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'cert_town',
      label: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_town.label',
        defaultMessage: 'Ville',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_town.short',
        defaultMessage: 'Ville',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_town.comment',
        defaultMessage: 'Ville',
      }),
      col: 'cert_town',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'lang_id',
      label: props.intl.formatMessage({
        id: 'app.features.certificate.field.lang_id.label',
        defaultMessage: 'Langue',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.certificate.field.lang_id.short',
        defaultMessage: 'Langue',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.certificate.field.lang_id.comment',
        defaultMessage: 'Langue',
      }),
      col: 'lang_id',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'cnty_id',
      label: props.intl.formatMessage({
        id: 'app.features.certificate.field.cnty_id.label',
        defaultMessage: 'Pays',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.certificate.field.cnty_id.short',
        defaultMessage: 'Pays',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.certificate.field.cnty_id.comment',
        defaultMessage: 'Pays',
      }),
      col: 'cnty_id',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'cert_email',
      label: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_email.label',
        defaultMessage: 'Email',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_email.short',
        defaultMessage: 'Email',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_email.comment',
        defaultMessage: 'Email',
      }),
      col: 'cert_email',
      size: { xxs: 36, sm: 10, md: 6 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'cert_input_mnt',
      label: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_input_mnt.label',
        defaultMessage: 'Montant',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_input_mnt.short',
        defaultMessage: 'Montant',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_input_mnt.comment',
        defaultMessage: 'Montant',
      }),
      col: 'cert_input_mnt',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      type: 'monetary',
      col_money: 'cert_input_money',
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'cert_input_money',
      label: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_input_money.label',
        defaultMessage: 'Monnaie',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_input_money.short',
        defaultMessage: 'Monnaie',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_input_money.comment',
        defaultMessage: 'Monnaie',
      }),
      col: 'cert_input_money',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      filterable: true,
    },
    {
      name: 'cert_output_mnt',
      label: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_output_mnt.label',
        defaultMessage: 'Montant final',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_output_mnt.short',
        defaultMessage: 'Montant final',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_output_mnt.comment',
        defaultMessage: 'Montant final',
      }),
      col: 'cert_output_mnt',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      type: 'monetary',
      col_money: 'cert_output_money',
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'cert_output_money',
      label: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_output_money.label',
        defaultMessage: 'Monnaie finale',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_output_money.short',
        defaultMessage: 'Monnaie finale',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_output_money.comment',
        defaultMessage: 'Monnaie finale',
      }),
      col: 'cert_output_money',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      filterable: true,
    },
    {
      name: 'cert_comment',
      label: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_comment.label',
        defaultMessage: 'Commentaires',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_comment.short',
        defaultMessage: 'Commentaires',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_comment.comment',
        defaultMessage: 'Commentaires',
      }),
      col: 'cert_comment',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'cert_data1',
      label: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_data1.label',
        defaultMessage: 'Donnée 1',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_data1.short',
        defaultMessage: 'Donnée 1',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_data1.comment',
        defaultMessage: 'Donnée 1',
      }),
      col: 'cert_data1',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'cert_data2',
      label: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_data2.label',
        defaultMessage: 'Donnée 2',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_data2.short',
        defaultMessage: 'Donnée 2',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_data2.comment',
        defaultMessage: 'Donnée 2',
      }),
      col: 'cert_data2',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'file_id',
      label: props.intl.formatMessage({
        id: 'app.features.certificate.field.file_id.label',
        defaultMessage: 'Fichier',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.certificate.field.file_id.short',
        defaultMessage: 'Fichier',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.certificate.field.file_id.comment',
        defaultMessage: 'Fichier',
      }),
      col: 'file_id',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'cert_unit_unit',
      label: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_unit_unit.label',
        defaultMessage: 'Unité',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_unit_unit.short',
        defaultMessage: 'Unité',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_unit_unit.comment',
        defaultMessage: 'Unité',
      }),
      col: 'cert_unit_unit',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'cert_unit_mnt',
      label: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_unit_mnt.label',
        defaultMessage: 'Montant unité',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_unit_mnt.short',
        defaultMessage: 'Montant unité',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_unit_mnt.comment',
        defaultMessage: 'Montant unité',
      }),
      col: 'cert_unit_mnt',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'cert_unit_base',
      label: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_unit_base.label',
        defaultMessage: 'Base unité',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_unit_base.short',
        defaultMessage: 'Base unité',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.certificate.field.cert_unit_base.comment',
        defaultMessage: 'Base unité',
      }),
      col: 'cert_unit_base',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'grp_id',
      label: props.intl.formatMessage({
        id: 'app.features.certificate.field.grp_id.label',
        defaultMessage: 'Groupe',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.certificate.field.grp_id.short',
        defaultMessage: 'Groupe',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.certificate.field.grp_id.comment',
        defaultMessage: 'Groupe',
      }),
      col: 'grp_id',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      hidden: true,
      sortable: true,
      filterable: true,
    },
    {
      name: 'cau_id',
      label: props.intl.formatMessage({
        id: 'app.features.certificate.field.cau_id.label',
        defaultMessage: 'Cause',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.certificate.field.cau_id.short',
        defaultMessage: 'Cause',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.certificate.field.cau_id.comment',
        defaultMessage: 'Cause',
      }),
      col: 'cau_id',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
  ];
};
