import { jsonApiNormalizer, normalizedObjectModeler, objectToQueryString } from 'jsonapi-front';
import { axios } from 'axios';
import { freeAssoApi } from '../../common';

let receiptTypeCancelToken = null;

/**
 * Export all receipt types as an array of value=>label
 * 
 * @param {object} object
 * 
 * @return {array}
 */
export function receiptTypeAsOptions(object) {
  let arr = [];
  let items = normalizedObjectModeler(
    object,
    'FreeAsso_ReceiptType',
  );
  items.forEach((item) => {
    arr.push({value: item.id, label: item.rett_name});
  });
  arr.sort(function (a, b) {
    if (a.label > b.label) {
      return 1;
    } else {
      if (a.label < b.label) {
        return -1;
      } 
    }
    return 0;
  });
  return arr;
}

export const searchReceiptType = search => {
  const promise = new Promise((resolve, reject) => {
    if (receiptTypeCancelToken) {
      receiptTypeCancelToken.cancel();
    }
    receiptTypeCancelToken = axios.CancelToken.source();
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    const doRequest = freeAssoApi.get(
      process.env.REACT_APP_BO_URL + '/v1/asso/receipt_type/autocomplete/' + search,
      {
        headers: headers,
        cancelToken: receiptTypeCancelToken.token,
      },
    );
    doRequest.then(
      res => {
        let list = [];
        if (res.data && res.data.length > 0) {
          res.data.map(item =>
            list.push({ item: item }),
          );
        }
        resolve(list);
      },
      err => {
        reject(err);
      },
    );
  });
  return promise;
}

/**
 * Appel du webservice de la récupération d'un modèle en fonction de son identifiant
 */
export const getOneModel = (id, params = {}) => {
  const addUrl = objectToQueryString(params);
  return freeAssoApi.get('/v1/asso/receipt_type/' + id + addUrl);
};

/**
 * Récupération d'un modèle en fonction de son identifiant en mode asynchorne
 * Renvoie un résultat qu'au retour de la promesse
 */
export const getOneModelAsModel = (id, params = {}) => {
  return new Promise((resolve, reject) => {
    getOneModel(id, params).then(
      res => {
        const object = jsonApiNormalizer(res.data);
        const item = normalizedObjectModeler(object, 'FreeAsso_ReceiptType', id, { eager: true });
        resolve(item);
      },
      err => {
        reject(err);
      },
    );
  });
};

/**
 * Récupération de modèle(s) en fonction de critères
 * Renvoie un résultat qu'au retour de la promesse
 */
export const getAllReceiptTypeAsModel = (p_filters = {}, sort= [], page = 1, size = 99999) => {
  return new Promise((resolve, reject) => {
    let filters = p_filters.asJsonApiObject()
    let params = {
      page: page,
      size: size,
      ...filters
    };
    let sort = '';
    sort.forEach(elt => {
      let add = elt.col;
      if (elt.way === 'down') {
        add = '-' + add;
      }
      if (sort === '') {
        sort = add;
      } else {
        sort = sort + ',' + add;
      }
    });
    if (sort !== '') {
      params.sort = sort;
    }
    const addUrl = objectToQueryString(params);
    const doRequest = freeAssoApi.get('/v1/asso/receipt_type' + addUrl, {});
    doRequest.then(
      (res) => {
        let result = false;
        if (res && res.data && res.data.data) {
          result = res.data.data;
        }
        if (result) {
          resolve(jsonApiNormalizer(result));
        } else {
          resolve(null);
        }
      },
      // Use rejectHandler as the second argument so that render errors won't be caught.
      (err) => {
        reject(err);
      },
    );
  });
};