export const CAUSE_LOAD_MORE_INIT = 'CAUSE_LOAD_MORE_INIT';
export const CAUSE_LOAD_MORE_BEGIN = 'CAUSE_LOAD_MORE_BEGIN';
export const CAUSE_LOAD_MORE_SUCCESS = 'CAUSE_LOAD_MORE_SUCCESS';
export const CAUSE_LOAD_MORE_FAILURE = 'CAUSE_LOAD_MORE_FAILURE';
export const CAUSE_LOAD_MORE_DISMISS_ERROR = 'CAUSE_LOAD_MORE_DISMISS_ERROR';
export const CAUSE_LOAD_ONE_BEGIN = 'CAUSE_LOAD_ONE_BEGIN';
export const CAUSE_LOAD_ONE_SUCCESS = 'CAUSE_LOAD_ONE_SUCCESS';
export const CAUSE_LOAD_ONE_FAILURE = 'CAUSE_LOAD_ONE_FAILURE';
export const CAUSE_LOAD_ONE_DISMISS_ERROR = 'CAUSE_LOAD_ONE_DISMISS_ERROR';
export const CAUSE_CREATE_ONE_BEGIN = 'CAUSE_CREATE_ONE_BEGIN';
export const CAUSE_CREATE_ONE_SUCCESS = 'CAUSE_CREATE_ONE_SUCCESS';
export const CAUSE_CREATE_ONE_FAILURE = 'CAUSE_CREATE_ONE_FAILURE';
export const CAUSE_CREATE_ONE_DISMISS_ERROR = 'CAUSE_CREATE_ONE_DISMISS_ERROR';
export const CAUSE_UPDATE_ONE_BEGIN = 'CAUSE_UPDATE_ONE_BEGIN';
export const CAUSE_UPDATE_ONE_SUCCESS = 'CAUSE_UPDATE_ONE_SUCCESS';
export const CAUSE_UPDATE_ONE_FAILURE = 'CAUSE_UPDATE_ONE_FAILURE';
export const CAUSE_UPDATE_ONE_DISMISS_ERROR = 'CAUSE_UPDATE_ONE_DISMISS_ERROR';
export const CAUSE_DEL_ONE_BEGIN = 'CAUSE_DEL_ONE_BEGIN';
export const CAUSE_DEL_ONE_SUCCESS = 'CAUSE_DEL_ONE_SUCCESS';
export const CAUSE_DEL_ONE_FAILURE = 'CAUSE_DEL_ONE_FAILURE';
export const CAUSE_DEL_ONE_DISMISS_ERROR = 'CAUSE_DEL_ONE_DISMISS_ERROR';
export const CAUSE_CLEAR_ITEMS = 'CAUSE_CLEAR_ITEMS';
export const CAUSE_SET_SORT = 'CAUSE_SET_SORT';
export const CAUSE_SET_FILTERS = 'CAUSE_SET_FILTERS';
export const CAUSE_UPDATE_QUICK_SEARCH = 'CAUSE_UPDATE_QUICK_SEARCH';
export const CAUSE_UPDATE_SORT = 'CAUSE_UPDATE_SORT';
export const CAUSE_INIT_FILTERS = 'CAUSE_INIT_FILTERS';
export const CAUSE_INIT_SORT = 'CAUSE_INIT_SORT';
export const CAUSE_LOAD_PHOTOS_BEGIN = 'CAUSE_LOAD_PHOTOS_BEGIN';
export const CAUSE_LOAD_PHOTOS_SUCCESS = 'CAUSE_LOAD_PHOTOS_SUCCESS';
export const CAUSE_LOAD_PHOTOS_FAILURE = 'CAUSE_LOAD_PHOTOS_FAILURE';
export const CAUSE_LOAD_PHOTOS_DISMISS_ERROR = 'CAUSE_LOAD_PHOTOS_DISMISS_ERROR';
export const CAUSE_LOAD_PHOTOS_INIT = 'CAUSE_LOAD_PHOTOS_INIT';
export const CAUSE_UPLOAD_PHOTO_BEGIN = 'CAUSE_UPLOAD_PHOTO_BEGIN';
export const CAUSE_UPLOAD_PHOTO_SUCCESS = 'CAUSE_UPLOAD_PHOTO_SUCCESS';
export const CAUSE_UPLOAD_PHOTO_FAILURE = 'CAUSE_UPLOAD_PHOTO_FAILURE';
export const CAUSE_UPLOAD_PHOTO_DISMISS_ERROR = 'CAUSE_UPLOAD_PHOTO_DISMISS_ERROR';
export const CAUSE_DEL_CAUSE_MEDIA_BEGIN = 'CAUSE_DEL_CAUSE_MEDIA_BEGIN';
export const CAUSE_DEL_CAUSE_MEDIA_SUCCESS = 'CAUSE_DEL_CAUSE_MEDIA_SUCCESS';
export const CAUSE_DEL_CAUSE_MEDIA_FAILURE = 'CAUSE_DEL_CAUSE_MEDIA_FAILURE';
export const CAUSE_DEL_CAUSE_MEDIA_DISMISS_ERROR = 'CAUSE_DEL_CAUSE_MEDIA_DISMISS_ERROR';
export const CAUSE_LOAD_NEWS_INIT = 'CAUSE_LOAD_NEWS_INIT';
export const CAUSE_LOAD_NEWS_BEGIN = 'CAUSE_LOAD_NEWS_BEGIN';
export const CAUSE_LOAD_NEWS_SUCCESS = 'CAUSE_LOAD_NEWS_SUCCESS';
export const CAUSE_LOAD_NEWS_FAILURE = 'CAUSE_LOAD_NEWS_FAILURE';
export const CAUSE_LOAD_NEWS_DISMISS_ERROR = 'CAUSE_LOAD_NEWS_DISMISS_ERROR';
export const CAUSE_LOAD_ONE_MEDIA_BEGIN = 'CAUSE_LOAD_ONE_MEDIA_BEGIN';
export const CAUSE_LOAD_ONE_MEDIA_SUCCESS = 'CAUSE_LOAD_ONE_MEDIA_SUCCESS';
export const CAUSE_LOAD_ONE_MEDIA_FAILURE = 'CAUSE_LOAD_ONE_MEDIA_FAILURE';
export const CAUSE_LOAD_ONE_MEDIA_DISMISS_ERROR = 'CAUSE_LOAD_ONE_MEDIA_DISMISS_ERROR';
export const CAUSE_LOAD_SPONSORS_INIT = 'CAUSE_LOAD_SPONSORS_INIT';
export const CAUSE_LOAD_SPONSORS_BEGIN = 'CAUSE_LOAD_SPONSORS_BEGIN';
export const CAUSE_LOAD_SPONSORS_SUCCESS = 'CAUSE_LOAD_SPONSORS_SUCCESS';
export const CAUSE_LOAD_SPONSORS_FAILURE = 'CAUSE_LOAD_SPONSORS_FAILURE';
export const CAUSE_LOAD_SPONSORS_DISMISS_ERROR = 'CAUSE_LOAD_SPONSORS_DISMISS_ERROR';
export const CAUSE_UPDATE_ONE_MEDIA_BEGIN = 'CAUSE_UPDATE_ONE_MEDIA_BEGIN';
export const CAUSE_UPDATE_ONE_MEDIA_SUCCESS = 'CAUSE_UPDATE_ONE_MEDIA_SUCCESS';
export const CAUSE_UPDATE_ONE_MEDIA_FAILURE = 'CAUSE_UPDATE_ONE_MEDIA_FAILURE';
export const CAUSE_UPDATE_ONE_MEDIA_DISMISS_ERROR = 'CAUSE_UPDATE_ONE_MEDIA_DISMISS_ERROR';
export const CAUSE_CREATE_ONE_MEDIA_BEGIN = 'CAUSE_CREATE_ONE_MEDIA_BEGIN';
export const CAUSE_CREATE_ONE_MEDIA_SUCCESS = 'CAUSE_CREATE_ONE_MEDIA_SUCCESS';
export const CAUSE_CREATE_ONE_MEDIA_FAILURE = 'CAUSE_CREATE_ONE_MEDIA_FAILURE';
export const CAUSE_CREATE_ONE_MEDIA_DISMISS_ERROR = 'CAUSE_CREATE_ONE_MEDIA_DISMISS_ERROR';
export const CAUSE_DEL_ONE_MEDIA_BEGIN = 'CAUSE_DEL_ONE_MEDIA_BEGIN';
export const CAUSE_DEL_ONE_MEDIA_SUCCESS = 'CAUSE_DEL_ONE_MEDIA_SUCCESS';
export const CAUSE_DEL_ONE_MEDIA_FAILURE = 'CAUSE_DEL_ONE_MEDIA_FAILURE';
export const CAUSE_DEL_ONE_MEDIA_DISMISS_ERROR = 'CAUSE_DEL_ONE_MEDIA_DISMISS_ERROR';
export const CAUSE_PRINT_ONE_BEGIN = 'CAUSE_PRINT_ONE_BEGIN';
export const CAUSE_PRINT_ONE_SUCCESS = 'CAUSE_PRINT_ONE_SUCCESS';
export const CAUSE_PRINT_ONE_FAILURE = 'CAUSE_PRINT_ONE_FAILURE';
export const CAUSE_PRINT_ONE_DISMISS_ERROR = 'CAUSE_PRINT_ONE_DISMISS_ERROR';
export const CAUSE_PROPAGATE = 'CAUSE_PROPAGATE';
export const CAUSE_CHECK_ONE_MEDIA_BEGIN = 'CAUSE_CHECK_ONE_MEDIA_BEGIN';
export const CAUSE_CHECK_ONE_MEDIA_SUCCESS = 'CAUSE_CHECK_ONE_MEDIA_SUCCESS';
export const CAUSE_CHECK_ONE_MEDIA_FAILURE = 'CAUSE_CHECK_ONE_MEDIA_FAILURE';
export const CAUSE_CHECK_ONE_MEDIA_DISMISS_ERROR = 'CAUSE_CHECK_ONE_MEDIA_DISMISS_ERROR';
export const CAUSE_ON_SELECT = 'CAUSE_ON_SELECT';
export const CAUSE_SELECT_NONE = 'CAUSE_SELECT_NONE';
export const CAUSE_SELECT_ALL = 'CAUSE_SELECT_ALL';
export const CAUSE_EXPORT_AS_TAB_BEGIN = 'CAUSE_EXPORT_AS_TAB_BEGIN';
export const CAUSE_EXPORT_AS_TAB_SUCCESS = 'CAUSE_EXPORT_AS_TAB_SUCCESS';
export const CAUSE_EXPORT_AS_TAB_FAILURE = 'CAUSE_EXPORT_AS_TAB_FAILURE';
export const CAUSE_EXPORT_AS_TAB_DISMISS_ERROR = 'CAUSE_EXPORT_AS_TAB_DISMISS_ERROR';
