export const CERTIFICATE_GENERATION_CLEAR_ITEMS = 'CERTIFICATE_GENERATION_CLEAR_ITEMS';
export const CERTIFICATE_GENERATION_CREATE_ONE_BEGIN = 'CERTIFICATE_GENERATION_CREATE_ONE_BEGIN';
export const CERTIFICATE_GENERATION_CREATE_ONE_SUCCESS = 'CERTIFICATE_GENERATION_CREATE_ONE_SUCCESS';
export const CERTIFICATE_GENERATION_CREATE_ONE_FAILURE = 'CERTIFICATE_GENERATION_CREATE_ONE_FAILURE';
export const CERTIFICATE_GENERATION_CREATE_ONE_DISMISS_ERROR = 'CERTIFICATE_GENERATION_CREATE_ONE_DISMISS_ERROR';
export const CERTIFICATE_GENERATION_DEL_ONE_BEGIN = 'CERTIFICATE_GENERATION_DEL_ONE_BEGIN';
export const CERTIFICATE_GENERATION_DEL_ONE_SUCCESS = 'CERTIFICATE_GENERATION_DEL_ONE_SUCCESS';
export const CERTIFICATE_GENERATION_DEL_ONE_FAILURE = 'CERTIFICATE_GENERATION_DEL_ONE_FAILURE';
export const CERTIFICATE_GENERATION_DEL_ONE_DISMISS_ERROR = 'CERTIFICATE_GENERATION_DEL_ONE_DISMISS_ERROR';
export const CERTIFICATE_GENERATION_EXPORT_AS_TAB_BEGIN = 'CERTIFICATE_GENERATION_EXPORT_AS_TAB_BEGIN';
export const CERTIFICATE_GENERATION_EXPORT_AS_TAB_SUCCESS = 'CERTIFICATE_GENERATION_EXPORT_AS_TAB_SUCCESS';
export const CERTIFICATE_GENERATION_EXPORT_AS_TAB_FAILURE = 'CERTIFICATE_GENERATION_EXPORT_AS_TAB_FAILURE';
export const CERTIFICATE_GENERATION_EXPORT_AS_TAB_DISMISS_ERROR = 'CERTIFICATE_GENERATION_EXPORT_AS_TAB_DISMISS_ERROR';
export const CERTIFICATE_GENERATION_EXPORT_BEGIN = 'CERTIFICATE_GENERATION_EXPORT_BEGIN';
export const CERTIFICATE_GENERATION_EXPORT_SUCCESS = 'CERTIFICATE_GENERATION_EXPORT_SUCCESS';
export const CERTIFICATE_GENERATION_EXPORT_FAILURE = 'CERTIFICATE_GENERATION_EXPORT_FAILURE';
export const CERTIFICATE_GENERATION_EXPORT_DISMISS_ERROR = 'CERTIFICATE_GENERATION_EXPORT_DISMISS_ERROR';
export const CERTIFICATE_GENERATION_INIT_FILTERS = 'CERTIFICATE_GENERATION_INIT_FILTERS';
export const CERTIFICATE_GENERATION_INIT_SORT = 'CERTIFICATE_GENERATION_INIT_SORT';
export const CERTIFICATE_GENERATION_LOAD_MORE_INIT = 'CERTIFICATE_GENERATION_LOAD_MORE_INIT';
export const CERTIFICATE_GENERATION_LOAD_MORE_BEGIN = 'CERTIFICATE_GENERATION_LOAD_MORE_BEGIN';
export const CERTIFICATE_GENERATION_LOAD_MORE_SUCCESS = 'CERTIFICATE_GENERATION_LOAD_MORE_SUCCESS';
export const CERTIFICATE_GENERATION_LOAD_MORE_FAILURE = 'CERTIFICATE_GENERATION_LOAD_MORE_FAILURE';
export const CERTIFICATE_GENERATION_LOAD_MORE_DISMISS_ERROR = 'CERTIFICATE_GENERATION_LOAD_MORE_DISMISS_ERROR';
export const CERTIFICATE_GENERATION_LOAD_ONE_BEGIN = 'CERTIFICATE_GENERATION_LOAD_ONE_BEGIN';
export const CERTIFICATE_GENERATION_LOAD_ONE_SUCCESS = 'CERTIFICATE_GENERATION_LOAD_ONE_SUCCESS';
export const CERTIFICATE_GENERATION_LOAD_ONE_FAILURE = 'CERTIFICATE_GENERATION_LOAD_ONE_FAILURE';
export const CERTIFICATE_GENERATION_LOAD_ONE_DISMISS_ERROR = 'CERTIFICATE_GENERATION_LOAD_ONE_DISMISS_ERROR';
export const CERTIFICATE_GENERATION_ON_SELECT = 'CERTIFICATE_GENERATION_ON_SELECT';
export const CERTIFICATE_GENERATION_PRINT_ONE_BEGIN = 'CERTIFICATE_GENERATION_PRINT_ONE_BEGIN';
export const CERTIFICATE_GENERATION_PRINT_ONE_SUCCESS = 'CERTIFICATE_GENERATION_PRINT_ONE_SUCCESS';
export const CERTIFICATE_GENERATION_PRINT_ONE_FAILURE = 'CERTIFICATE_GENERATION_PRINT_ONE_FAILURE';
export const CERTIFICATE_GENERATION_PRINT_ONE_DISMISS_ERROR = 'CERTIFICATE_GENERATION_PRINT_ONE_DISMISS_ERROR';
export const CERTIFICATE_GENERATION_PROPAGATE = 'CERTIFICATE_GENERATION_PROPAGATE';
export const CERTIFICATE_GENERATION_SELECT_NONE = 'CERTIFICATE_GENERATION_SELECT_NONE';
export const CERTIFICATE_GENERATION_SELECT_ALL = 'CERTIFICATE_GENERATION_SELECT_ALL';
export const CERTIFICATE_GENERATION_SET_CURRENT = 'CERTIFICATE_GENERATION_SET_CURRENT';
export const CERTIFICATE_GENERATION_SET_PREVIOUS = 'CERTIFICATE_GENERATION_SET_PREVIOUS';
export const CERTIFICATE_GENERATION_SET_NEXT_BEGIN = 'CERTIFICATE_GENERATION_SET_NEXT_BEGIN';
export const CERTIFICATE_GENERATION_SET_NEXT_SUCCESS = 'CERTIFICATE_GENERATION_SET_NEXT_SUCCESS';
export const CERTIFICATE_GENERATION_SET_NEXT_FAILURE = 'CERTIFICATE_GENERATION_SET_NEXT_FAILURE';
export const CERTIFICATE_GENERATION_SET_NEXT_DISMISS_ERROR = 'CERTIFICATE_GENERATION_SET_NEXT_DISMISS_ERROR';
export const CERTIFICATE_GENERATION_SET_FILTERS = 'CERTIFICATE_GENERATION_SET_FILTERS';
export const CERTIFICATE_GENERATION_SET_SORT = 'CERTIFICATE_GENERATION_SET_SORT';
export const CERTIFICATE_GENERATION_UPDATE_ONE_BEGIN = 'CERTIFICATE_GENERATION_UPDATE_ONE_BEGIN';
export const CERTIFICATE_GENERATION_UPDATE_ONE_SUCCESS = 'CERTIFICATE_GENERATION_UPDATE_ONE_SUCCESS';
export const CERTIFICATE_GENERATION_UPDATE_ONE_FAILURE = 'CERTIFICATE_GENERATION_UPDATE_ONE_FAILURE';
export const CERTIFICATE_GENERATION_UPDATE_ONE_DISMISS_ERROR = 'CERTIFICATE_GENERATION_UPDATE_ONE_DISMISS_ERROR';
export const CERTIFICATE_GENERATION_UPDATE_QUICK_SEARCH = 'CERTIFICATE_GENERATION_UPDATE_QUICK_SEARCH';
export const CERTIFICATE_GENERATION_UPDATE_SORT = 'CERTIFICATE_GENERATION_UPDATE_SORT';
