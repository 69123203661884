import React from 'react';
import { injectIntl } from 'react-intl';
import { verifyScope } from 'react-bootstrap-front';
import {
  InputTextarea,
  ResponsiveModalOrForm,
  InputHidden,
  InputSelect,
  InputCheckbox,
  InputEmail,
  InputPhone,
  Row,
  Col,
  InputText,
} from '../ui';
import {
  Person as ClientIcon,
  Donation as DonationIcon,
  DonationHeart as DonationHeartIcon,
  Admin as AdminIcon,
  Email as EmailIcon,
  Certificate as CertificateIcon,
} from '../icons';
import useForm from '../ui/useForm';
import { InlineList as InlineSponsorships } from '../sponsorship';
import { InlineList as InlineDonations } from '../donation';
import { InlineList as InlineHistory } from '../history';
import { InlineList as InlineMessage } from '../message';
import { InlineList as InlineReceipt } from '../receipt';
import { InlineList as InlineCertificate } from '../certificate';
import { InputPicker as InputGroupPicker } from '../group';
import { checkClient, getCols, InlineNamesake } from './';

const updateState = (state, props, setValues) => {
  // Hook !!
  if (props.__forced) {
    props.__forced = false;
    Array.isArray(props.__forced_items) &&
      props.__forced_items.map(field => {
        state[field] = props[field];
        return state;
      });
  }
  return false;
};

const afterChange = (name, item, setItem, action) => {
  if (action === 'blur') {
    switch (name) {
      case 'cli_firstname':
        if (
          item.cli_firstname &&
          item.cli_lastname &&
          item.cli_firstname !== '' &&
          item.cli_lastname !== ''
        ) {
          checkClient(item).then(result => {
            item.__checkClient = result;
            setItem(item);
          });
        }
        break;
      case 'cli_lastname':
        if (
          item.cli_firstname &&
          item.cli_lastname &&
          item.cli_firstname !== '' &&
          item.cli_lastname !== ''
        ) {
          checkClient(item).then(result => {
            item.__checkClient = result;
            setItem(item);
          });
        }
        break;
      case 'cli_email':
        if (item.cli_email && item.cli_email !== '') {
          checkClient(item, 'email').then(result => {
            item.__checkClient = result;
            setItem(item);
          });
        }
        item.__checkClient = true;
        break;
      default:
        break;
    }
  }
};

function Form(props) {
  const {
    values,
    handleChange,
    handleSave,
    handleSubmit,
    handleCancel,
    handleNavTab,
    getErrorMessage,
    handleBlur,
    localSetValues,
  } = useForm(
    props.item,
    props.tab || '1',
    props.onSubmit,
    props.onCancel,
    props.onNavTab,
    props.errors,
    props.intl,
    afterChange,
    updateState,
  );
  const tabs = [
    {
      key: '1',
      name: 'identity',
      label: props.intl.formatMessage({
        id: 'app.features.client.tab.main',
        defaultMessage: 'Identity',
      }),
      shortcut: 'I',
      icon: <ClientIcon />,
    },
  ];
  const modifTabs = [
    {
      key: '3',
      name: 'sponsorship',
      label: props.intl.formatMessage({
        id: 'app.features.client.tab.sponsorships',
        defaultMessage: 'Sponsorships',
      }),
      shortcut: 'I',
      icon: <DonationHeartIcon />,
    },
    {
      key: '4',
      name: 'donation',
      label: props.intl.formatMessage({
        id: 'app.features.client.tab.donations',
        defaultMessage: 'Donations',
      }),
      shortcut: 'C',
      icon: <DonationIcon />,
    },
    {
      key: '5',
      name: 'receipts',
      label: props.intl.formatMessage({
        id: 'app.features.client.tab.receipts',
        defaultMessage: 'Receipts',
      }),
      shortcut: 'R',
      icon: <AdminIcon />,
    },
    {
      key: '6',
      name: 'certificates',
      label: props.intl.formatMessage({
        id: 'app.features.client.tab.certificates',
        defaultMessage: 'Certificats',
      }),
      shortcut: 'C',
      icon: <CertificateIcon />,
    },
    {
      key: '7',
      name: 'others',
      label: props.intl.formatMessage({
        id: 'app.features.client.tab.control',
        defaultMessage: 'Controls',
      }),
      shortcut: 'C',
      icon: <AdminIcon />,
    },
    {
      key: '8',
      name: 'emails',
      label: props.intl.formatMessage({
        id: 'app.features.client.tab.email',
        defaultMessage: 'Emails',
      }),
      shortcut: 'E',
      icon: <EmailIcon />,
    },
  ];
  const title =
    (values.cli_firstname ? values.cli_firstname : '') +
    '  ' +
    (values.cli_lastname ? values.cli_lastname : '');
  const allowedDonation = verifyScope(props.scope, 'FreeAsso/Asso/Donation');
  let rightPan = null;
  if (Array.isArray(values.__checkClient)) {
    rightPan = (
      <InlineNamesake
        {...props}
        cols={getCols({ props: props })}
        items={values.__checkClient}
        loading={false}
        onClose={() => {
          values.__checkClient = null;
          localSetValues(values);
        }}
        onSelect={item => {
          props.onLoadNamesake(item);
        }}
      />
    );
  }
  return (
    <ResponsiveModalOrForm
      title={title}
      className=""
      tab={values.__currentTab}
      tabs={values.__create || !allowedDonation ? tabs : tabs.concat(modifTabs)}
      size="lg"
      onNavTab={handleNavTab}
      onSave={handleSave}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onClose={props.onClose}
      modal={true}
      saving={props.saving || false}
      rightPan={rightPan}
      actionsButtons={props.actionsButtons}
      help={props.intl.formatMessage({
        id: 'app.features.client.help',
        defaultMessage: 'Aide',
      })}
    >
      <div>
        <InputHidden name="id" id="id" value={values.id} />
        {values.__currentTab === '1' && (
          <div>
            <Row>
              <Col size={{ xxs: 36, sm: 9 }}>
                <InputText
                  label={props.intl.formatMessage({
                    id: 'app.features.client.form.lastname',
                    defaultMessage: 'Lastname',
                  })}
                  name="cli_lastname"
                  id="cli_lastname"
                  required={true}
                  labelTop={true}
                  value={values.cli_lastname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={getErrorMessage('cli_lastname')}
                />
              </Col>
              <Col size={{ xxs: 36, sm: 9 }}>
                <InputText
                  label={props.intl.formatMessage({
                    id: 'app.features.client.form.firstname',
                    defaultMessage: 'Firstname',
                  })}
                  name="cli_firstname"
                  id="cli_firstname"
                  labelTop={true}
                  value={values.cli_firstname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={getErrorMessage('cli_firstname')}
                />
              </Col>
              <Col size={{ xxs: 36, sm: 7 }}>
                <InputSelect
                  label={props.intl.formatMessage({
                    id: 'app.features.client.form.category',
                    defaultMessage: 'Category',
                  })}
                  name="client_category.id"
                  labelTop={true}
                  required={true}
                  value={values.client_category ? values.client_category.id : null}
                  onChange={handleChange}
                  options={props.clientCategoryOptions}
                />
              </Col>
              <Col size={{ xxs: 36, sm: 7 }}>
                <InputGroupPicker
                  label={props.intl.formatMessage({
                    id: 'app.features.client.form.group',
                    defaultMessage: 'Group',
                  })}
                  name="group"
                  labelTop={true}
                  required={true}
                  disabled={true}
                  item={values.group || null}
                  onChange={handleChange}
                />
              </Col>
              <Col size={{ xxs: 36, sm: 4 }}>
                <InputCheckbox
                  label={props.intl.formatMessage({
                    id: 'app.features.client.form.active',
                    defaultMessage: 'Active',
                  })}
                  name="cli_active"
                  labelTop={true}
                  checked={values.cli_active === true}
                  onChange={handleChange}
                  error={getErrorMessage('cli_active')}
                />
              </Col>
            </Row>
            <Row>
              <Col size={{ xxs: 36, sm: 12 }}>
                <InputText
                  label={props.intl.formatMessage({
                    id: 'app.features.client.form.address',
                    defaultMessage: 'Address',
                  })}
                  name="cli_address1"
                  labelTop={true}
                  value={values.cli_address1}
                  onChange={handleChange}
                  error={getErrorMessage('cli_address1')}
                />
              </Col>
              {props.fields[values.client_category.id] &&
              props.fields[values.client_category.id].indexOf('cli_street_num') >= 0 ? (
                <Col size={{ xxs: 36, sm: 3 }}>
                  <InputText
                    label={props.intl.formatMessage({
                      id: 'app.features.client.form.num',
                      defaultMessage: 'N*',
                    })}
                    name="cli_street_num"
                    labelTop={true}
                    value={values.cli_street_num}
                    onChange={handleChange}
                    error={getErrorMessage('cli_street_num')}
                  />
                </Col>
              ) : (
                <Col size={{ xxs: 0, sm: 3 }}></Col>
              )}
              {props.fields[values.client_category.id] &&
              props.fields[values.client_category.id].indexOf('cli_street_name') >= 0 ? (
                <Col size={{ xxs: 36, sm: 10 }}>
                  <InputText
                    label={props.intl.formatMessage({
                      id: 'app.features.client.form.name',
                      defaultMessage: 'Rue',
                    })}
                    name="cli_street_name"
                    labelTop={true}
                    value={values.cli_street_name}
                    onChange={handleChange}
                    error={getErrorMessage('cli_street_name')}
                  />
                </Col>
              ) : (
                <Col size={{ xxs: 0, sm: 10 }}></Col>
              )}
              <Col size={{ xxs: 36, sm: 4 }}>
                <InputCheckbox
                  label={props.intl.formatMessage({
                    id: 'app.features.client.form.volunteer',
                    defaultMessage: 'Bénévole',
                  })}
                  name="cli_volunteer"
                  labelTop={true}
                  checked={values.cli_volunteer === true}
                  onChange={handleChange}
                  error={getErrorMessage('cli_volunteer')}
                />
              </Col>
              <Col size={{ xxs: 36, sm: 7 }}>
                <InputCheckbox
                  label={props.intl.formatMessage({
                    id: 'app.features.client.form.receipt',
                    defaultMessage: 'Reçus fiscaux',
                  })}
                  name="cli_receipt"
                  labelTop={true}
                  checked={values.cli_receipt === true}
                  onChange={handleChange}
                  error={getErrorMessage('cli_receipt')}
                />
              </Col>
            </Row>
            <Row>
              <Col size={{ xxs: 36, sm: 12 }}>
                <InputText
                  label=""
                  name="cli_address2"
                  labelTop={true}
                  value={values.cli_address2}
                  onChange={handleChange}
                  error={getErrorMessage('cli_address2')}
                />
              </Col>
            </Row>
            <Row>
              <Col size={{ xxs: 36, sm: 12 }}>
                <InputText
                  label=""
                  name="cli_address3"
                  labelTop={true}
                  value={values.cli_address3}
                  onChange={handleChange}
                  error={getErrorMessage('cli_address3')}
                />
              </Col>
            </Row>
            <Row>
              <Col size={{ xxs: 36, sm: 5 }}>
                <InputText
                  label={props.intl.formatMessage({
                    id: 'app.features.client.form.cp',
                    defaultMessage: 'Postcode',
                  })}
                  name="cli_cp"
                  labelTop={true}
                  value={values.cli_cp}
                  onChange={handleChange}
                  error={getErrorMessage('cli_cp')}
                />
              </Col>
              <Col size={{ xxs: 36, sm: 11 }}>
                <InputText
                  label={props.intl.formatMessage({
                    id: 'app.features.client.form.town',
                    defaultMessage: 'Town',
                  })}
                  name="cli_town"
                  labelTop={true}
                  value={values.cli_town}
                  onChange={handleChange}
                  error={getErrorMessage('cli_town')}
                />
              </Col>
              <Col size={{ xxs: 36, sm: 9 }}>
                <InputSelect
                  label={props.intl.formatMessage({
                    id: 'app.features.client.form.country',
                    defaultMessage: 'Country',
                  })}
                  name="country.id"
                  labelTop={true}
                  value={values.country ? values.country.id : null}
                  onChange={handleChange}
                  options={props.countryOptions}
                />
              </Col>
              <Col size={{ xxs: 36, sm: 7 }}>
                <InputSelect
                  label={props.intl.formatMessage({
                    id: 'app.features.client.form.type',
                    defaultMessage: 'Type',
                  })}
                  name="client_type.id"
                  labelTop={true}
                  required={true}
                  value={values.client_type ? values.client_type.id : null}
                  onChange={handleChange}
                  options={props.clientTypeOptions}
                />
              </Col>
            </Row>
            <Row>
              {props.fields[values.client_category.id] &&
                props.fields[values.client_category.id].indexOf('cli_siren') >= 0 && (
                  <Col size={{ xxs: 36, sm: 5 }}>
                    <InputText
                      label={props.intl.formatMessage({
                        id: 'app.features.client.form.siren',
                        defaultMessage: 'SIREN',
                      })}
                      name="cli_siren"
                      id="cli_siren"
                      labelTop={true}
                      value={values.cli_siren}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={getErrorMessage('cli_siren')}
                    />
                  </Col>
                )}
              {props.fields[values.client_category.id] &&
                props.fields[values.client_category.id].indexOf('cli_social_reason') >= 0 && (
                  <Col size={{ xxs: 36, sm: 20 }}>
                    <InputText
                      label={props.intl.formatMessage({
                        id: 'app.features.client.form.social',
                        defaultMessage: 'Forme juridique',
                      })}
                      name="cli_social_reason"
                      id="cli_social_reason"
                      labelTop={true}
                      value={values.cli_social_reason}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={getErrorMessage('cli_social_reason')}
                    />
                  </Col>
                )}
            </Row>
            <Row>
              <Col size={{ xxs: 36, sm: 16 }}>
                <InputEmail
                  label={props.intl.formatMessage({
                    id: 'app.features.client.form.email',
                    defaultMessage: 'Email',
                  })}
                  name="cli_email"
                  labelTop={true}
                  value={values.cli_email}
                  className={values.cli_email === values.cli_email_refused ? 'text-warning' : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={getErrorMessage('cli_email')}
                />
              </Col>
              <Col size={{ xxs: 36, sm: 9 }}>
                <InputPhone
                  label={props.intl.formatMessage({
                    id: 'app.features.client.form.phoneHomr',
                    defaultMessage: 'Phone',
                  })}
                  name="cli_phone_home"
                  labelTop={true}
                  value={values.cli_phone_home}
                  onChange={handleChange}
                  error={getErrorMessage('cli_phone_home')}
                />
              </Col>
              <Col size={{ xxs: 36, sm: 6 }}>
                <InputSelect
                  label={props.intl.formatMessage({
                    id: 'app.features.client.form.lang',
                    defaultMessage: 'Lang',
                  })}
                  name="lang.id"
                  labelTop={true}
                  value={values.lang ? values.lang.id : null}
                  onChange={handleChange}
                  options={props.langOptions}
                />
              </Col>
            </Row>
            <Row>
              <Col size={{ xxs: 36, sm: 16 }}>
                <InputEmail
                  label={props.intl.formatMessage({
                    id: 'app.features.client.form.email2',
                    defaultMessage: 'Second email',
                  })}
                  name="cli_email2"
                  labelTop={true}
                  value={values.cli_email2}
                  className={values.cli_email2 === values.cli_email_refused ? 'text-warning' : ''}
                  onChange={handleChange}
                  error={getErrorMessage('cli_email2')}
                />
              </Col>
              <Col size={{ xxs: 36, sm: 9 }}>
                <InputPhone
                  label={props.intl.formatMessage({
                    id: 'app.features.client.form.phoneGsm',
                    defaultMessage: 'Second phone',
                  })}
                  name="cli_phone_gsm"
                  labelTop={true}
                  value={values.cli_phone_gsm}
                  onChange={handleChange}
                  error={getErrorMessage('cli_phone_gsm')}
                />
              </Col>
              <Col size={{ xxs: 36, sm: 11 }}>
                <InputText
                  label={props.intl.formatMessage({
                    id: 'app.features.client.form.accounting',
                    defaultMessage: 'Compta',
                  })}
                  name="cli_accounting"
                  labelTop={true}
                  value={values.cli_accounting}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col size={{ xxs: 36, sm: 36 }}>
                <InputTextarea
                  label={props.intl.formatMessage({
                    id: 'app.features.client.form.desc',
                    defaultMessage: 'Comments',
                  })}
                  labelTop={true}
                  name="cli_desc"
                  id="cli_desc"
                  value={values.cli_desc}
                  onChange={handleChange}
                  error={getErrorMessage('cli_desc')}
                />
              </Col>
            </Row>
          </div>
        )}
        {values.__currentTab === '3' && (
          <div className="form-inline-list overflow-x-hidden">
            <InlineSponsorships mode="client" parentId={values.id} />
          </div>
        )}
        {values.__currentTab === '4' && (
          <div className="form-inline-list overflow-x-hidden">
            <InlineDonations mode="client" parentId={values.id} />
          </div>
        )}
        {values.__currentTab === '5' && (
          <div className="form-inline-list overflow-x-hidden">
            <InlineReceipt mode="client" parentId={values.id} />
          </div>
        )}
        {values.__currentTab === '6' && (
          <div className="form-inline-list overflow-x-hidden">
            <InlineCertificate mode="client" parentId={values.id} />
          </div>
        )}
        {values.__currentTab === '7' && (
          <div className="form-inline-list overflow-x-hidden">
            <InlineHistory mode="client" parentId={values.id} />
          </div>
        )}
        {values.__currentTab === '8' && (
          <div className="form-inline-list overflow-x-hidden">
            <InlineMessage mode="dest" parentId={values.id} />
          </div>
        )}
      </div>
    </ResponsiveModalOrForm>
  );
}

export default injectIntl(Form);
