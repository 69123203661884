import React from 'react';
// import PropTypes from 'prop-types';
import { mdiCamera as myIcon } from '@mdi/js';
import { Icon } from './';

export default function Photo(props) {
  return <Icon path={myIcon} {...props} />;
}

Photo.propTypes = {};
Photo.defaultProps = {};
