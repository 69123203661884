import { freeAssoApi } from '../../../common';
import { jsonApiNormalizer, objectToQueryString, getNewNormalizedObject } from 'jsonapi-front';
import {
  HISTORY_LOAD_PENDINGS_INIT,
  HISTORY_LOAD_PENDINGS_BEGIN,
  HISTORY_LOAD_PENDINGS_SUCCESS,
  HISTORY_LOAD_PENDINGS_FAILURE,
  HISTORY_LOAD_PENDINGS_DISMISS_ERROR,
} from './constants';

/**
 * Récupère la liste des agents
 */
export function loadPendings(reload = false) {
  return (dispatch, getState) => {
    const loaded = getState().history.loadPendingsFinish;
    if (!loaded || reload) {
      if (reload) {
        dispatch({
          type: HISTORY_LOAD_PENDINGS_INIT,
        });
      } else {
        dispatch({
          type: HISTORY_LOAD_PENDINGS_BEGIN,
        });
      }
      const promise = new Promise((resolve, reject) => {
        let params = {
          page: {
            number: getState().history.page_pending,
            size: getState().history.page_size,
          },
          include: getState().history.includeMore,
        };
        let sort = '';
        getState().history.sort.forEach(elt => {
          let add = elt.col;
          if (elt.way === 'down') {
            add = '-' + add;
          }
          if (sort === '') {
            sort = add;
          } else {
            sort = sort + ',' + add;
          }
        });
        if (sort !== '') {
          params.sort = sort;
        }
        const addUrl = objectToQueryString(params);
        const doRequest = freeAssoApi.get('/v1/core/history' + addUrl, {});
        doRequest.then(
          res => {
            dispatch({
              type: HISTORY_LOAD_PENDINGS_SUCCESS,
              data: res,
            });
            resolve(res);
          },
          // Use rejectHandler as the second argument so that render errors won't be caught.
          err => {
            dispatch({
              type: HISTORY_LOAD_PENDINGS_FAILURE,
              data: { error: err },
            });
            reject(err);
          },
        );
      });
      return promise;
    }
  };
}

export function dismissLoadPendingsError() {
  return {
    type: HISTORY_LOAD_PENDINGS_DISMISS_ERROR,
  };
}

/**
 * Reducer
 *
 * @param {Object} state  Etat courant de la mémoire (store)
 * @param {Object} action Action à réaliser sur cet état avec options
 */
export function reducer(state, action) {
  switch (action.type) {
    case HISTORY_LOAD_PENDINGS_INIT:
      // Just after a request is sent
      return {
        ...state,
        loadPendingsPending: true,
        loadPendingsError: null,
        loadPendingsFinish: false,
        selected: [],
        pendings: getNewNormalizedObject('FreeFW_history'),
        page_pending: 1,
      };

    case HISTORY_LOAD_PENDINGS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        loadPendingsPending: true,
        loadPendingsError: null,
      };

    case HISTORY_LOAD_PENDINGS_SUCCESS:
      // The request is success
      let list = {};
      let nbre = 0;
      let result = false;
      if (action.data && action.data.data) {
        result = action.data.data;
      }
      if (result.data) {
        nbre = result.data.length;
      }
      if (nbre > 0) {
        if (state.pendings) {
          list = jsonApiNormalizer(result, state.pendings);
        } else {
          list = jsonApiNormalizer(result);
        }
      } else {
        list = state.pendings;
      }
      return {
        ...state,
        loadPendingsPending: false,
        loadPendingsError: null,
        loadPendingsFinish: nbre < state.page_size,
        pendings: list,
        page_pending: state.page_pending + 1,
      };

    case HISTORY_LOAD_PENDINGS_FAILURE:
      // The request is failed
      return {
        ...state,
        loadPendingsPending: false,
        loadPendingsError: action.data.error,
      };

    case HISTORY_LOAD_PENDINGS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        loadPendingsError: null,
      };

    default:
      return state;
  }
}
