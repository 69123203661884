import React from 'react';
import {
  AddOne as AddOneIcon,
  GetOne as GetOneIcon,
  DelOne as DelOneIcon,
  Print as PrintIcon,
  Accounting as VerifOneIcon,
} from '../icons';

export const sessionStatus = [
  { value: 'OPEN', label: 'Ouverte' },
  { value: 'CLOSED', label: 'Fermée' },
  { value: 'VALIDATION', label: 'En validation' },
];

export const sessionType = [
  { value: 'STANDARD', label: 'Standard' },
  { value: 'CORRECTION', label: 'Corrective' },
];

export const sessionVerif = [
  { value: 'NONE', label: 'Aucun' },
  { value: 'DONE', label: 'Terminé' },
  { value: 'CLEANING', label: 'Purge demandée' },
  { value: 'PENDING', label: 'Contrôle demandé' },
  { value: 'ERROR', label: 'En erreur' },
];

/**
 * Shortcuts
 *
 * @param {Object} intl
 * @param {String} mode
 */
export const getShortcuts = (intl, mode = 'all') => {
  let shortcuts = [];
  shortcuts.push({
    name: 'ident',
    icon: '',
    size: 'maximized',
    display: 'block',
    position: 1,
    label: intl.formatMessage({
      id: 'app.features.session.form.tabs.ident',
      defaultMessage: 'Identification',
    }),
  });
  if (mode === 'all') {
  }
  return shortcuts;
};

/**
 * Gestion de l'affichage du champ en fonction du modèle
 */
export function getPickerDisplay(item) {
  let display = '';
  if (item && item.id > 0) {
    const status = sessionStatus.find(elem => elem.value === item.sess_status);
    display = (
      <p>
        {item.sess_name}
        <br />
        {status.label}
      </p>
    );
  }
  return display;
}

/**
 * Affichage des agents dans la liste des propositions de la recherhe
 * ou dans la liste proposées sous le champ en autocomplète
 */
export const displayItemPicker = item => {
  return (
    <p className="input-picker-line">
      <span className="input-picker-left">{item.sess_name}</span>
    </p>
  );
};

/**
 * Actions disponibles depuis l'en-tête de la liste des agents
 */
export const getGlobalActions = ({ props, onClearFilters, onCreate }) => {
  return [
    {
      name: 'create',
      label: props.intl.formatMessage({
        id: 'app.list.button.add',
        defaultMessage: 'Add',
      }),
      onClick: onCreate,
      theme: 'primary',
      icon: <AddOneIcon />,
      role: 'CREATE',
    },
  ];
};

/**
 * Actions disponibles sur la fiche (en plus de l'enregistrement et de l'annulation)
 */
export const getActionsButtons = ({ onPrint, state }) => {
  const { editions } = state;
  let myOptions = [];
  let printabled = false;
  if (editions && Array.isArray(editions) && editions.length > 0) {
    printabled = true;
    editions.forEach(edition => {
      myOptions.push({
        id: edition.id,
        label: edition.edi_name,
        onClick: () => onPrint(edition.id),
      });
    });
  }
  return [
    {
      theme: 'secondary',
      hidden: !printabled,
      options: myOptions,
      optionsAlign: 'top-left',
      function: () => onPrint(),
      icon: <PrintIcon title="Imprimer" />,
    },
  ];
};

/**
 * Actions disponibles depuis l'en-tête de la liste des agents pour agir sur les lignes de la liste
 *    Sélection lignes
 *    Dé-sélection
 *    ...
 *    Actions sur lignes sélectionnées
 *    ou sur toutes les lignes
 */
export const getSelectActions = ({ props, onSelectMenu }) => {
  let arrOne = [
    {
      name: 'selectAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.selectAll',
        defaultMessage: 'Select all',
      }),
      onClick: () => {
        onSelectMenu('selectAll');
      },
    },
  ];
  const arrAppend = [
    {
      name: 'selectNone',
      label: props.intl.formatMessage({
        id: 'app.list.options.deselectAll',
        defaultMessage: 'Deselect all',
      }),
      onClick: () => {
        onSelectMenu('selectNone');
      },
    },
    { name: 'divider' },
    {
      name: 'exportSelect',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportSelect',
        defaultMessage: 'Export selection',
      }),
      onClick: () => {
        onSelectMenu('exportSelection');
      },
    },
  ];
  if (props.session.selected.length > 0) {
    arrOne = arrOne.concat(arrAppend);
  }
  const arrStandard = [
    { name: 'divider' },
    {
      name: 'exportAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportAll',
        defaultMessage: 'Export all',
      }),
      onClick: () => {
        onSelectMenu('exportAll');
      },
    },
  ];
  arrOne = arrOne.concat(arrStandard);
  return arrOne;
};

/**
 * Actions disponibles sur une ligne de la liste des agents au survol de cette ligne
 */
export const getInlineActions = ({
  props,
  onSelectList,
  onGetOne,
  onDelOne,
  onPrint,
  state,
  onCleanOne,
  onVerifOne,
}) => {
  let myEditions = [];
  if (state && state.editions) {
    const { editions } = state;
    editions.forEach(edition => {
      myEditions.push({ label: edition.edi_name, onClick: item => onPrint(edition.id, item) });
    });
  }
  return [
    {
      name: 'print',
      label: props.intl.formatMessage({
        id: 'app.list.button.print',
        defaultMessage: 'Print',
      }),
      onClick: onPrint,
      theme: 'secondary',
      icon: <PrintIcon />,
      role: 'PRINT',
      param: 'object',
      active: myEditions.length > 0,
      options: myEditions,
    },
    {
      name: 'verify',
      label: props.intl.formatMessage({
        id: 'app.list.button.verify',
        defaultMessage: 'Verify',
      }),
      onClick: onVerifOne,
      theme: 'secondary',
      icon: <VerifOneIcon />,
      role: 'ACTION',
      fShow: item => {
        return item.sess_verif === 'NONE';
      },
    },
    {
      name: 'clean',
      label: props.intl.formatMessage({
        id: 'app.list.button.clean',
        defaultMessage: 'Clean',
      }),
      onClick: onCleanOne,
      theme: 'warning',
      icon: <VerifOneIcon />,
      role: 'ACTION',
      fShow: item => {
        return item.sess_verif === 'DONE' || item.sess_verif === 'ERROR';
      },
    },
    {
      name: 'modify',
      label: props.intl.formatMessage({
        id: 'app.list.button.modify',
        defaultMessage: 'Modify',
      }),
      onClick: onGetOne,
      theme: 'secondary',
      icon: <GetOneIcon />,
      role: 'MODIFY',
    },
    {
      name: 'delete',
      label: props.intl.formatMessage({
        id: 'app.list.button.delete',
        defaultMessage: 'Delete',
      }),
      onClick: onDelOne,
      theme: 'warning',
      icon: <DelOneIcon />,
      role: 'DELETE',
    },
  ];
};

/**
 * Descriptions de toutes les colonnes de la liste
 * Même les colonnes cachées
 * Ces colonnes peuvent ou non données des champs dans les filtres
 * (une info peut de pas être affichée dans la liste mais on peut peut-être mettre un filtre dessus)
 */
export const getCols = ({ props }) => {
  return [
    {
      name: 'sess_id',
      label: props.intl.formatMessage({
        id: 'app.features.session.field.sess_id.label',
        defaultMessage: 'sess_id',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.session.field.sess_id.short',
        defaultMessage: 'sess_id',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.session.field.sess_id.comment',
        defaultMessage: 'sess_id',
      }),
      col: 'sess_id',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'ID' },
      filterable: true,
    },
    {
      name: 'sess_name',
      label: props.intl.formatMessage({
        id: 'app.features.session.field.sess_name.label',
        defaultMessage: 'sess_name',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.session.field.sess_name.short',
        defaultMessage: 'sess_name',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.session.field.sess_name.comment',
        defaultMessage: 'sess_name',
      }),
      col: 'sess_name',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      card: { role: 'TITLE' },
      filterable: true,
    },
    {
      name: 'sess_status',
      label: props.intl.formatMessage({
        id: 'app.features.session.field.sess_status.label',
        defaultMessage: 'sess_status',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.session.field.sess_status.short',
        defaultMessage: 'sess_status',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.session.field.sess_status.comment',
        defaultMessage: 'sess_status',
      }),
      col: 'sess_status',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      type: 'switch',
      values: sessionStatus,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'sess_type',
      label: props.intl.formatMessage({
        id: 'app.features.session.field.sess_type.label',
        defaultMessage: 'sess_type',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.session.field.sess_type.short',
        defaultMessage: 'sess_type',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.session.field.sess_type.comment',
        defaultMessage: 'sess_type',
      }),
      col: 'sess_type',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      type: 'switch',
      values: sessionType,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'sess_year',
      label: props.intl.formatMessage({
        id: 'app.features.session.field.sess_year.label',
        defaultMessage: 'sess_year',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.session.field.sess_year.short',
        defaultMessage: 'sess_year',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.session.field.sess_year.comment',
        defaultMessage: 'sess_year',
      }),
      col: 'sess_year',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'sess_month',
      label: props.intl.formatMessage({
        id: 'app.features.session.field.sess_month.label',
        defaultMessage: 'sess_month',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.session.field.sess_month.short',
        defaultMessage: 'sess_month',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.session.field.sess_month.comment',
        defaultMessage: 'sess_month',
      }),
      col: 'sess_month',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'sess_verif',
      label: props.intl.formatMessage({
        id: 'app.features.session.field.sess_verif.label',
        defaultMessage: 'sess_verif',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.session.field.sess_verif.short',
        defaultMessage: 'sess_verif',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.session.field.sess_verif.comment',
        defaultMessage: 'sess_verif',
      }),
      col: 'sess_verif',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      type: 'switch',
      values: sessionVerif,
      card: { role: 'FIELD' },
      filterable: true,
    },
  ];
};
