export const NOTIFICATION_CLEAR_ITEMS = 'NOTIFICATION_CLEAR_ITEMS';
export const NOTIFICATION_CREATE_ONE_BEGIN = 'NOTIFICATION_CREATE_ONE_BEGIN';
export const NOTIFICATION_CREATE_ONE_SUCCESS = 'NOTIFICATION_CREATE_ONE_SUCCESS';
export const NOTIFICATION_CREATE_ONE_FAILURE = 'NOTIFICATION_CREATE_ONE_FAILURE';
export const NOTIFICATION_CREATE_ONE_DISMISS_ERROR = 'NOTIFICATION_CREATE_ONE_DISMISS_ERROR';
export const NOTIFICATION_DEL_ONE_BEGIN = 'NOTIFICATION_DEL_ONE_BEGIN';
export const NOTIFICATION_DEL_ONE_SUCCESS = 'NOTIFICATION_DEL_ONE_SUCCESS';
export const NOTIFICATION_DEL_ONE_FAILURE = 'NOTIFICATION_DEL_ONE_FAILURE';
export const NOTIFICATION_DEL_ONE_DISMISS_ERROR = 'NOTIFICATION_DEL_ONE_DISMISS_ERROR';
export const NOTIFICATION_EXPORT_AS_TAB_BEGIN = 'NOTIFICATION_EXPORT_AS_TAB_BEGIN';
export const NOTIFICATION_EXPORT_AS_TAB_SUCCESS = 'NOTIFICATION_EXPORT_AS_TAB_SUCCESS';
export const NOTIFICATION_EXPORT_AS_TAB_FAILURE = 'NOTIFICATION_EXPORT_AS_TAB_FAILURE';
export const NOTIFICATION_EXPORT_AS_TAB_DISMISS_ERROR = 'NOTIFICATION_EXPORT_AS_TAB_DISMISS_ERROR';
export const NOTIFICATION_EXPORT_BEGIN = 'NOTIFICATION_EXPORT_BEGIN';
export const NOTIFICATION_EXPORT_SUCCESS = 'NOTIFICATION_EXPORT_SUCCESS';
export const NOTIFICATION_EXPORT_FAILURE = 'NOTIFICATION_EXPORT_FAILURE';
export const NOTIFICATION_EXPORT_DISMISS_ERROR = 'NOTIFICATION_EXPORT_DISMISS_ERROR';
export const NOTIFICATION_INIT_FILTERS = 'NOTIFICATION_INIT_FILTERS';
export const NOTIFICATION_INIT_SORT = 'NOTIFICATION_INIT_SORT';
export const NOTIFICATION_LOAD_MORE_INIT = 'NOTIFICATION_LOAD_MORE_INIT';
export const NOTIFICATION_LOAD_MORE_BEGIN = 'NOTIFICATION_LOAD_MORE_BEGIN';
export const NOTIFICATION_LOAD_MORE_SUCCESS = 'NOTIFICATION_LOAD_MORE_SUCCESS';
export const NOTIFICATION_LOAD_MORE_FAILURE = 'NOTIFICATION_LOAD_MORE_FAILURE';
export const NOTIFICATION_LOAD_MORE_DISMISS_ERROR = 'NOTIFICATION_LOAD_MORE_DISMISS_ERROR';
export const NOTIFICATION_LOAD_ONE_BEGIN = 'NOTIFICATION_LOAD_ONE_BEGIN';
export const NOTIFICATION_LOAD_ONE_SUCCESS = 'NOTIFICATION_LOAD_ONE_SUCCESS';
export const NOTIFICATION_LOAD_ONE_FAILURE = 'NOTIFICATION_LOAD_ONE_FAILURE';
export const NOTIFICATION_LOAD_ONE_DISMISS_ERROR = 'NOTIFICATION_LOAD_ONE_DISMISS_ERROR';
export const NOTIFICATION_ON_SELECT = 'NOTIFICATION_ON_SELECT';
export const NOTIFICATION_PRINT_ONE_BEGIN = 'NOTIFICATION_PRINT_ONE_BEGIN';
export const NOTIFICATION_PRINT_ONE_SUCCESS = 'NOTIFICATION_PRINT_ONE_SUCCESS';
export const NOTIFICATION_PRINT_ONE_FAILURE = 'NOTIFICATION_PRINT_ONE_FAILURE';
export const NOTIFICATION_PRINT_ONE_DISMISS_ERROR = 'NOTIFICATION_PRINT_ONE_DISMISS_ERROR';
export const NOTIFICATION_PROPAGATE = 'NOTIFICATION_PROPAGATE';
export const NOTIFICATION_SELECT_NONE = 'NOTIFICATION_SELECT_NONE';
export const NOTIFICATION_SELECT_ALL = 'NOTIFICATION_SELECT_ALL';
export const NOTIFICATION_SET_CURRENT = 'NOTIFICATION_SET_CURRENT';
export const NOTIFICATION_SET_PREVIOUS = 'NOTIFICATION_SET_PREVIOUS';
export const NOTIFICATION_SET_NEXT_BEGIN = 'NOTIFICATION_SET_NEXT_BEGIN';
export const NOTIFICATION_SET_NEXT_SUCCESS = 'NOTIFICATION_SET_NEXT_SUCCESS';
export const NOTIFICATION_SET_NEXT_FAILURE = 'NOTIFICATION_SET_NEXT_FAILURE';
export const NOTIFICATION_SET_NEXT_DISMISS_ERROR = 'NOTIFICATION_SET_NEXT_DISMISS_ERROR';
export const NOTIFICATION_SET_FILTERS = 'NOTIFICATION_SET_FILTERS';
export const NOTIFICATION_SET_SORT = 'NOTIFICATION_SET_SORT';
export const NOTIFICATION_UPDATE_ONE_BEGIN = 'NOTIFICATION_UPDATE_ONE_BEGIN';
export const NOTIFICATION_UPDATE_ONE_SUCCESS = 'NOTIFICATION_UPDATE_ONE_SUCCESS';
export const NOTIFICATION_UPDATE_ONE_FAILURE = 'NOTIFICATION_UPDATE_ONE_FAILURE';
export const NOTIFICATION_UPDATE_ONE_DISMISS_ERROR = 'NOTIFICATION_UPDATE_ONE_DISMISS_ERROR';
export const NOTIFICATION_UPDATE_QUICK_SEARCH = 'NOTIFICATION_UPDATE_QUICK_SEARCH';
export const NOTIFICATION_UPDATE_SORT = 'NOTIFICATION_UPDATE_SORT';
export const NOTIFICATION_LOAD_PENDINGS_INIT = 'NOTIFICATION_LOAD_PENDINGS_INIT';
export const NOTIFICATION_LOAD_PENDINGS_BEGIN = 'NOTIFICATION_LOAD_PENDINGS_BEGIN';
export const NOTIFICATION_LOAD_PENDINGS_SUCCESS = 'NOTIFICATION_LOAD_PENDINGS_SUCCESS';
export const NOTIFICATION_LOAD_PENDINGS_FAILURE = 'NOTIFICATION_LOAD_PENDINGS_FAILURE';
export const NOTIFICATION_LOAD_PENDINGS_DISMISS_ERROR = 'NOTIFICATION_LOAD_PENDINGS_DISMISS_ERROR';
export const NOTIFICATION_VALIDATE_ONE_BEGIN = 'NOTIFICATION_VALIDATE_ONE_BEGIN';
export const NOTIFICATION_VALIDATE_ONE_SUCCESS = 'NOTIFICATION_VALIDATE_ONE_SUCCESS';
export const NOTIFICATION_VALIDATE_ONE_FAILURE = 'NOTIFICATION_VALIDATE_ONE_FAILURE';
export const NOTIFICATION_VALIDATE_ONE_DISMISS_ERROR = 'NOTIFICATION_VALIDATE_ONE_DISMISS_ERROR';
