import { DONATION_ORIGIN_INIT_SORT } from './constants';

/**
 * Initialisation du tri de la liste
 */
export function initSort() {
  return {
    type: DONATION_ORIGIN_INIT_SORT,
  };
}

/**
 * Reducer
 *
 * @param {Object} state  Etat courant de la mémoire (store)
 * @param {Object} action Action à réaliser sur cet état avec options
 */
export function reducer(state, action) {
  switch (action.type) {
    case DONATION_ORIGIN_INIT_SORT:
      return {
        ...state,
        sort: [
          { col: 'dono_year', way: 'down' },
          { col: 'dono_month', way: 'down' },
          { col: 'dono_day', way: 'down' },
        ],
      };

    default:
      return state;
  }
}
