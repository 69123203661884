import React from 'react';
import { Icon } from './';
import { mdiArrowLeftThick as myIcon } from '@mdi/js';

export default function Previous(props) {
  return <Icon path={myIcon} {...props} />;
}

Previous.propTypes = {};
Previous.defaultProps = {};
