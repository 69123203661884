import React, { Component } from 'react';
import Icon from '@mdi/react';
import {
  mdiNumeric0CircleOutline,
  mdiNumeric1CircleOutline,
  mdiNumeric2CircleOutline,
  mdiNumeric3CircleOutline,
  mdiNumeric4CircleOutline,
  mdiNumeric5CircleOutline,
  mdiNumeric6CircleOutline,
  mdiNumeric7CircleOutline,
  mdiNumeric8CircleOutline,
  mdiNumeric9CircleOutline,
  mdiNumeric10CircleOutline,
} from '@mdi/js';

const getIcon = (props, num) => {
  switch (num) {
    case 1:
      return <Icon path={mdiNumeric1CircleOutline} style={{color: "red"}} {...props} />
    case 2:
      return <Icon path={mdiNumeric2CircleOutline} style={{color: "red"}} {...props} />
    case 3:
      return <Icon path={mdiNumeric3CircleOutline} style={{color: "red"}} {...props} />
    case 4:
      return <Icon path={mdiNumeric4CircleOutline} style={{color: "orange"}} {...props} />
    case 5:
      return <Icon path={mdiNumeric5CircleOutline} style={{color: "orange"}} {...props} />
    case 6:
      return <Icon path={mdiNumeric6CircleOutline} style={{color: "orange"}} {...props} />
    case 7:
      return <Icon path={mdiNumeric7CircleOutline} style={{color: "orange"}} {...props} />
    case 8:
      return <Icon path={mdiNumeric8CircleOutline} style={{color: "orange"}} {...props} />
    case 9:
      return <Icon path={mdiNumeric9CircleOutline} style={{color: "orange"}} {...props} />
    case 10:
      return <Icon path={mdiNumeric10CircleOutline} style={{color: "green"}} {...props} />
    default: 
      return <Icon path={mdiNumeric0CircleOutline} {...props} />
  }
}

export default class MatchingNote extends Component {
  static propTypes = { };

  render() {
    const min = this.props.min || 0;
    const max = this.props.max || 10;
    let myIcon = null;
    if (this.props.value === min) {
      myIcon = getIcon(this.props, 0);
    } else {
      if (this.props.value === max) {
        myIcon = getIcon(this.props, 10);
      } else {
        const moy = parseInt((max - min) / 10, 10);
        const val = parseInt(parseInt(this.props.value, 10) / moy);
        myIcon = getIcon(this.props, val);
      }
    }
    return myIcon;
  }
}