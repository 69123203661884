import { freeAssoApi } from '../../../common';
import { jsonApiNormalizer, normalizedObjectModeler, objectToQueryString } from 'jsonapi-front';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  INBOX_LOAD_PENDINGS_BEGIN,
  INBOX_LOAD_PENDINGS_SUCCESS,
  INBOX_LOAD_PENDINGS_FAILURE,
  INBOX_LOAD_PENDINGS_DISMISS_ERROR,
} from './constants';

export function loadPendings(reload = false) {
  return dispatch => {
    dispatch({
      type: INBOX_LOAD_PENDINGS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const params = {
        fields: '-inbox_params,-inbox_content',
        sort: '-inbox_ts'
      }
      const addUrl = objectToQueryString(params);
      const doRequest = freeAssoApi.get('/v1/core/inbox'+ addUrl, {});
      doRequest.then(
        res => {
          dispatch({
            type: INBOX_LOAD_PENDINGS_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        err => {
          dispatch({
            type: INBOX_LOAD_PENDINGS_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissLoadPendingsError() {
  return {
    type: INBOX_LOAD_PENDINGS_DISMISS_ERROR,
  };
}

export function useLoadPendings() {
  const dispatch = useDispatch();

  const { loadPendingsPending, loadPendingsError } = useSelector(
    state => ({
      loadPendingsPending: state.inbox.loadPendingsPending,
      loadPendingsError: state.inbox.loadPendingsError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(loadPendings(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissLoadPendingsError());
  }, [dispatch]);

  return {
    loadPendings: boundAction,
    loadPendingsPending,
    loadPendingsError,
    dismissLoadPendingsError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case INBOX_LOAD_PENDINGS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        loadPendingsPending: true,
        loadPendingsError: null,
      };

    case INBOX_LOAD_PENDINGS_SUCCESS:
      // The request is success
      let list = {};
      let nbre = 0;
      let result = false;
      if (action.data && action.data.data) {
        result = action.data.data;
      }
      if (result.data) {
        nbre = result.data.length;
      }
      if (nbre > 0) {
        if (state.page_number > 1) {
          list = jsonApiNormalizer(result, state.items);
        } else {
          list = jsonApiNormalizer(result);
        }
      } else {
        list = state.items;
      }
      const inboxes = normalizedObjectModeler(list, 'FreeFW_Inbox');
      let not_downloaded = 0
      inboxes && inboxes.forEach(elem => {
        if (!elem.inbox_download_ts) {
          not_downloaded++;
        }
      });
      return {
        ...state,
        loadPendingsPending: false,
        loadPendingsError: null,
        itemsPending: list,
        modelsPending: inboxes,
        countPending: nbre,
        not_downloaded: not_downloaded,
      };

    case INBOX_LOAD_PENDINGS_FAILURE:
      // The request is failed
      return {
        ...state,
        loadPendingsPending: false,
        loadPendingsError: action.data.error,
      };

    case INBOX_LOAD_PENDINGS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        loadPendingsError: null,
      };

    default:
      return state;
  }
}
