import React from 'react';
import { injectIntl } from 'react-intl';
import { normalizedObjectModeler } from 'jsonapi-front';
import { getShortcuts } from './';
import useForm from '../ui/useForm';
import { ResponsiveModalOrForm, InputHidden, InputText, Row, Col, InputCheckbox } from '../ui';

/**
 * Initialisation
 *
 * @param {Object}   item
 * @param {Function} setItem
 */
const initItem = (item, setItem) => {};

/**
 * Un champ a été modifié
 *
 * @param {String}   field
 * @param {Object}   item
 * @param {Function} setitem
 */
const afterChange = (field, item, setItem) => {
  switch (field) {
    default:
      break;
  }
};

/**
 * Saisie des informations d'un modèle
 */
function Form(props) {
  let item = props.item;
  // On récupère tout ce qu'il faut pour travailler
  const {
    values,
    handleChange,
    handleSave,
    handleSubmit,
    handleCancel,
    handleNavTab,
    getErrorMessage,
    getCurrentTab,
  } = useForm(
    item,
    props.tab,
    props.onSubmit,
    props.onCancel,
    props.onNavTab,
    props.errors,
    props.intl,
    afterChange,
    initItem,
  );
  //
  const causeTypes = normalizedObjectModeler(props.causeTypes, 'FreeAsso_CauseType');
  // Le formulaire
  return (
    <ResponsiveModalOrForm
      title={
        !props.modify && !values.rett_name
          ? props.intl.formatMessage({
              id: 'app.features.receipt-type.form.title',
              defaultMessage: 'New model',
            })
          : values.rett_name
      }
      className=""
      tab={getCurrentTab()}
      tabs={!props.modify ? getShortcuts(props.intl, 'new') : getShortcuts(props.intl, 'all')}
      size="md"
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onNavTab={handleNavTab}
      onSave={handleSave}
      onClose={handleCancel}
      onPrevious={props.onPrevious}
      onNext={props.onNext}
      modal={true}
      actionsButtons={props.actionsButtons}
      help={props.intl.formatMessage({
        id: 'app.features.receipt-type.help',
        defaultMessage: 'Aide',
      })}
    >
      <InputHidden name="id" id="id" value={values.id} />
      <Row>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.receipt-type.field.rett_name.label',
              defaultMessage: 'rett_name',
            })}
            id="rett_name"
            name="rett_name"
            value={values.rett_name}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('rett_name')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 6 }}>
          <InputCheckbox
            label={props.intl.formatMessage({
              id: 'app.features.receipt-type.field.rett_start_one.label',
              defaultMessage: 'rett_start_one',
            })}
            id="rett_start_one"
            name="rett_start_one"
            checked={values.rett_start_one}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('rett_start_one')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 6 }}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.receipt-type.field.rett_last_number.label',
              defaultMessage: 'rett_last_number',
            })}
            id="rett_last_number"
            name="rett_last_number"
            value={values.rett_last_number}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('rett_last_number')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.receipt-type.field.rett_regex.label',
              defaultMessage: 'rett_regex',
            })}
            id="rett_regex"
            name="rett_regex"
            value={values.rett_regex}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('rett_regex')}
          />
        </Col>
      </Row>
      <hr />
      <table>
        <thead>
          <th>
            <strong>
              <span className="text-secondary">
                {props.intl.formatMessage({
                  id: 'app.features.receipt-type.field.col.title',
                  defaultMessage: 'Cause type',
                })}
              </span>
            </strong>
          </th>
          <th size={{ xxs: 20 }}>
            <strong>
              <span className="text-secondary">
                {props.intl.formatMessage({
                  id: 'app.features.receipt-type.field.col.once',
                  defaultMessage: 'Once',
                })}
              </span>
            </strong>
          </th>
          <th size={{ xxs: 20 }}>
            <strong>
              <span className="text-secondary">
                {props.intl.formatMessage({
                  id: 'app.features.receipt-type.field.col.regular',
                  defaultMessage: 'Regular',
                })}
              </span>
            </strong>
          </th>
        </thead>
        <tbody>
          {values.settings.map((oneSetting, idx) => {
            const causeType = causeTypes.find(
              elem => parseInt(elem.id, 10) === parseInt(oneSetting.cause_type.id, 10),
            );
            return (
              <tr key={`tr-${oneSetting.id}`}>
                <td className="pr-5">
                  <span>{causeType ? causeType.caut_name : '?'}</span>
                </td>
                <td className="pr-5">
                  <InputCheckbox
                    id={'settings.' + idx + '.rtct_once'}
                    name={'settings.' + idx + '.rtct_once'}
                    checked={oneSetting.rtct_once === true}
                    onChange={handleChange}
                    error={getErrorMessage('cli_active')}
                  />
                </td>
                <td>
                  <InputCheckbox
                    id={'settings.' + idx + '.rtct_regular'}
                    name={'settings.' + idx + '.rtct_regular'}
                    checked={oneSetting.rtct_regular === true}
                    onChange={handleChange}
                    error={getErrorMessage('cli_active')}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </ResponsiveModalOrForm>
  );
}

/**
 * Injection de l'intl
 */
export default injectIntl(Form);
