import { getNewJsonApi, normalizedObjectModeler } from 'jsonapi-front';
import mime from 'mime-types';
import { downloadBlob, showErrors } from '../ui';
import { freeAssoApi } from '../../common';

/**
 * Get object types
 *
 * @return Array
 */
export const getEditionObjectTypes = intl => {
  return [
    { value: 'IMPRESS', label: 'Office Impress' },
    { value: 'WRITER', label: 'Office Writer' },
    { value: 'CALC', label: 'Office Calc' },
    { value: 'HTML', label: 'Html' },
    { value: 'PDF', label: 'PDF' },
  ];
};

/**
 * Get object modes
 *
 * @return Array
 */
export const getEditionObjectModes = intl => {
  return [
    { value: 'RENEW', label: 'Ré édition' },
    { value: 'KEEP', label: 'Conservation' },
    { value: 'OTHER', label: 'Autre' },
  ];
};

/**
 * Get object names
 *
 * @return Array
 */
export const getEditionObjectNames = intl => {
  return [
    { value: 'FreeAsso_Certificate', label: 'Certificat' },
    { value: 'FreeAsso_Donation', label: 'Paiement' },
    { value: 'FreeAsso_Sponsorship', label: 'Paiement régulier' },
    { value: 'FreeAsso_Client', label: 'Donateur' },
    { value: 'FreeAsso_Cause', label: 'Programme' },
    { value: 'FreeAsso_Receipt', label: 'Reçu' },
  ];
};

/**
 * Retourne la liste des éditions d'un type d'object
 *
 * @param [POFW_Edition] p_editions
 * @param string         p_object_name
 *
 * @return [POFW_Edition]
 */
export const getEditions = (p_editions, p_object_name) => {
  return p_editions.filter(
    elem => elem.edi_object_name.toLowerCase() === p_object_name.toLowerCase(),
  );
};

/**
 * Lancement d'une édition
 *
 * @param object intl
 * @param string p_ediId
 * @param string p_object
 * @param mixed  p_ids
 */
export const printEdition = (intl, p_ediId, p_object, $p_ids) => {
  let obj = {};
  if (Array.isArray($p_ids)) {
    obj = getNewJsonApi(p_object);
  } else {
    obj = getNewJsonApi(p_object, $p_ids);
  }
  const doRequest = freeAssoApi.post('/v1/core/edition/print/' + p_ediId, obj, {
    responseType: 'arraybuffer',
  });
  doRequest.then(
    result => {
      const type = result.headers['content-type'] || 'application/octet-stream';
      const extension = mime.extension(type);
      downloadBlob(result.data, type, p_object + '_' + $p_ids + '.' + extension);
    },
    err => {
      showErrors(intl, err);
    },
  );
};

/**
 * Export all editions as an array of value=>label
 * 
 * @param {object} object
 * 
 * @return {array}
 */
export function editionAsOptions(object, restrict = []) {
  let arr = [];
  if (object) {
    let items = normalizedObjectModeler(
      object,
      'FreeFW_Edition',
    );
    if (items) {
      items.forEach((item) => {
        if (restrict.length > 0) {
          if (item.edi_object_name && restrict.includes(item.edi_object_name)) {
            arr.push({value: item.id, label: item.edi_name});
          }
        } else {
          arr.push({value: item.id, label: item.edi_name});
        }
      });
      arr.sort(function (a, b) {
        if (a.label > b.label) {
          return 1;
        } else {
          if (a.label < b.label) {
            return -1;
          } 
        }
        return 0;
      });
    }
  }
  return arr;
}
