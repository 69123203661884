import React from 'react';
import { displayDatetime } from 'react-bootstrap-front';
import {
  Print as PrintIcon,
  Checked as CheckedIcon,
  View as ViewIcon,
} from '../icons';
import { displayColBool, Avatar } from '../ui';
import { getLibObject } from '../../common';

/**
 * Avatar
 *
 * @param {Object} item
 *
 * @return {Component}
 */
export const userAvatar = item => {
  if (item && item.user && item.user.id) {
    return <Avatar user={item.user} className="rounded-circle" size="22" />;
  }
  return null;
};

/**
 *
 */
export const localNotificationObjectInformation = item => {
  if (item && item.notif_object_info !== null && item.notif_object_info !== '') {
    return item.notif_object_info;
  }
  return getLibObject(item.notif_object_name, item.notif_object_id);
};

/**
 *
 */
export const localNotificationObjectLabel = item => {
  return getLibObject(item.notif_object_name, item.notif_object_id);
};

/**
 *
 */
export const localNotificationDatetime = item => {
  return displayDatetime(item.notif_ts);
};

/**
 *
 */
export const localNotificationCode = item => {
  let label = '';
  switch (item.notif_code) {
    case '':
      label = '';
      break;
    default:
      label = '';
  }
  return label;
};

/**
 *
 */
export const localNotificationType = item => {
  let label = '';
  switch (item.notif_type) {
    case 'ERROR':
      label = 'Erreur';
      break;
    case 'WARNING':
      label = 'Avertissement';
      break;
    case 'INFORMATION':
      label = 'Information';
      break;
    default:
      label = '';
  }
  return label;
};

/**
 * Shortcuts
 *
 * @param {Object} intl
 * @param {String} mode
 */
export const getShortcuts = (intl, mode = 'all') => {
  let shortcuts = [];
  shortcuts.push({
    name: 'ident',
    icon: '',
    size: 'maximized',
    display: 'block',
    position: 1,
    label: intl.formatMessage({
      id: 'app.features.notification.form.tabs.ident',
      defaultMessage: 'Identification',
    }),
  });
  if (mode === 'all') {
  }
  return shortcuts;
};

/**
 * Gestion de l'affichage du champ en fonction du modèle
 */
export function getPickerDisplay(item) {
  let display = '';
  if (item && item.id > 0) {
    display = item.notif_ts;
  }
  return display;
}

/**
 * Affichage des agents dans la liste des propositions de la recherhe
 * ou dans la liste proposées sous le champ en autocomplète
 */
export const displayItemPicker = item => {
  return (
    <p className="input-picker-line">
      <span className="input-picker-left">{item.notif_ts}</span>
    </p>
  );
};

/**
 * Actions disponibles depuis l'en-tête de la liste des agents
 */
export const getGlobalActions = ({ props, onClearFilters, onCreate }) => {
  return [];
};

/**
 * Actions disponibles sur la fiche (en plus de l'enregistrement et de l'annulation)
 */
export const getActionsButtons = ({ onPrint, state }) => {
  const { editions } = state;
  let myOptions = [];
  let printabled = false;
  if (editions && Array.isArray(editions) && editions.length > 0) {
    printabled = true;
    editions.forEach(edition => {
      myOptions.push({
        id: edition.id,
        label: edition.edi_name,
        onClick: () => onPrint(edition.id),
      });
    });
  }
  return [
    {
      theme: 'secondary',
      hidden: !printabled,
      options: myOptions,
      optionsAlign: 'top-left',
      function: () => onPrint(),
      icon: <PrintIcon title="Imprimer" />,
    },
  ];
};

/**
 * Actions disponibles depuis l'en-tête de la liste des agents pour agir sur les lignes de la liste
 *    Sélection lignes
 *    Dé-sélection
 *    ...
 *    Actions sur lignes sélectionnées
 *    ou sur toutes les lignes
 */
export const getSelectActions = ({ props, onSelectMenu }) => {
  let arrOne = [
    {
      name: 'selectAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.selectAll',
        defaultMessage: 'Select all',
      }),
      onClick: () => {
        onSelectMenu('selectAll');
      },
    },
  ];
  const arrAppend = [
    {
      name: 'selectNone',
      label: props.intl.formatMessage({
        id: 'app.list.options.deselectAll',
        defaultMessage: 'Deselect all',
      }),
      onClick: () => {
        onSelectMenu('selectNone');
      },
    },
    { name: 'divider' },
    {
      name: 'exportSelect',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportSelect',
        defaultMessage: 'Export selection',
      }),
      onClick: () => {
        onSelectMenu('exportSelection');
      },
    },
  ];
  if (props.notification.selected.length > 0) {
    arrOne = arrOne.concat(arrAppend);
  }
  const arrStandard = [
    { name: 'divider' },
    {
      name: 'exportAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportAll',
        defaultMessage: 'Export all',
      }),
      onClick: () => {
        onSelectMenu('exportAll');
      },
    },
  ];
  arrOne = arrOne.concat(arrStandard);
  return arrOne;
};

/**
 * Actions disponibles sur une ligne de la liste des agents au survol de cette ligne
 */
export const getInlineActions = ({
  props,
  onSelectList = null,
  onGetObject = null,
  onGetOne = null,
  onDelOne = null,
  onPrint = null,
  onValidate = null,
  state = null,
}) => {
  let actions = [];
  if (state && state.editions) {
    const { editions } = state;
    let myEditions = [];
    editions.forEach(edition => {
      myEditions.push({ label: edition.edi_name, onClick: item => onPrint(edition.id, item) });
    });
    actions.push({
      name: 'print',
      label: props.intl.formatMessage({
        id: 'app.list.button.print',
        defaultMessage: 'Print',
      }),
      onClick: onPrint,
      theme: 'secondary',
      icon: <PrintIcon />,
      role: 'PRINT',
      param: 'object',
      active: myEditions.length > 0,
      options: myEditions,
    });
  }
  if (onValidate) {
    actions.push({
      name: 'validate',
      label: props.intl.formatMessage({
        id: 'app.list.button.validate',
        defaultMessage: 'Valider',
      }),
      onClick: onValidate,
      param: 'object',
      theme: 'primary',
      icon: <CheckedIcon />,
      role: 'ACTION',
    });
  }
  if (onGetObject) {
    actions.push({
      name: 'view',
      label: props.intl.formatMessage({
        id: 'app.list.button.view',
        defaultMessage: 'Visualiser',
      }),
      onClick: onGetObject,
      fShow: item => {
        return item && item.notif_object_id;
      },
      theme: 'secondary',
      icon: <ViewIcon />,
      param: 'object',
      role: 'DETAILS',
    });
  }
  /*
  if (onDelOne) {
    actions.push({
      name: 'delete',
      label: props.intl.formatMessage({
        id: 'app.list.button.delete',
        defaultMessage: 'Delete',
      }),
      onClick: onDelOne,
      theme: 'warning',
      icon: <DelOneIcon />,
      role: 'DELETE',
    });
  }*/
  return actions;
};

/**
 * Descriptions de toutes les colonnes de la liste
 * Même les colonnes cachées
 * Ces colonnes peuvent ou non données des champs dans les filtres
 * (une info peut de pas être affichée dans la liste mais on peut peut-être mettre un filtre dessus)
 */
export const getCols = ({ props, colTheme='ALL' }) => {
  let cols = [
    {
      name: 'notif_id',
      label: props.intl.formatMessage({
        id: 'app.features.notification.field.notif_id.label',
        defaultMessage: 'Id.',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.notification.field.notif_id.short',
        defaultMessage: 'Id.',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.notification.field.notif_id.comment',
        defaultMessage: 'Identifiant de la notification',
      }),
      col: 'notif_id',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'ID' },
      filterable: true,
    },
    {
      name: 'brk_id',
      label: props.intl.formatMessage({
        id: 'app.features.notification.field.brk_id.label',
        defaultMessage: 'Broker',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.notification.field.brk_id.short',
        defaultMessage: 'Broker',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.notification.field.brk_id.comment',
        defaultMessage: 'Identifiant du broker, pour restriction',
      }),
      col: 'brk_id',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      first: true,
      filterable: true,
    },
    {
      name: 'notif_text',
      label: props.intl.formatMessage({
        id: 'app.features.notification.field.notif_text.label',
        defaultMessage: 'Notification',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.notification.field.notif_text.short',
        defaultMessage: 'Notification',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.notification.field.notif_text.comment',
        defaultMessage: 'Le texte de la notification, html de préférence',
      }),
      col: 'notif_text',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      hidden: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'notif_subject',
      label: props.intl.formatMessage({
        id: 'app.features.notification.field.notif_subject.label',
        defaultMessage: 'Sujet',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.notification.field.notif_subject.short',
        defaultMessage: 'Sujet',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.notification.field.notif_subject.comment',
        defaultMessage: "Le sujet de la notification, ne pas mettre d'html",
      }),
      col: 'notif_subject',
      size: { xxs: 36, sm: 12, md: 16 },
      title: true,
      sortable: true,
      card: { role: 'TITLE' },
      filterable: true,
    },
    {
      name: 'notif_object_name',
      label: props.intl.formatMessage({
        id: 'app.features.notification.field.notif_object_name.label',
        defaultMessage: 'Objet',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.notification.field.notif_object_name.short',
        defaultMessage: 'Objet',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.notification.field.notif_object_name.comment',
        defaultMessage: "Le nom de l'objet concerné",
      }),
      col: 'notif_object_name',
      size: { xxs: 36, sm: 12, md: 5 },
      title: true,
      fDisplay: localNotificationObjectLabel,
      sortable: true,
      hidden: true,
      filterable: true,
    },
    {
      name: 'notif_object_info',
      label: props.intl.formatMessage({
        id: 'app.features.notification.field.notif_object_info.label',
        defaultMessage: 'Objet',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.notification.field.notif_object_info.short',
        defaultMessage: 'Objet',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.notification.field.notif_object_info.comment',
        defaultMessage: "Le nom de l'objet concerné",
      }),
      col: 'notif_object_info',
      size: { xxs: 36, sm: 12, md: 5 },
      title: true,
      sortable: true,
      fDisplay: localNotificationObjectInformation,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'notif_object_id',
      label: props.intl.formatMessage({
        id: 'app.features.notification.field.notif_object_id.label',
        defaultMessage: 'Id. objet',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.notification.field.notif_object_id.short',
        defaultMessage: 'Id. objet',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.notification.field.notif_object_id.comment',
        defaultMessage: "L'indentifiant de l'objet concerné",
      }),
      col: 'notif_object_id',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      hidden: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'notif_code',
      label: props.intl.formatMessage({
        id: 'app.features.notification.field.notif_code.label',
        defaultMessage: 'Code',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.notification.field.notif_code.short',
        defaultMessage: 'Code',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.notification.field.notif_code.comment',
        defaultMessage: 'Code interne de la notification',
      }),
      col: 'notif_code',
      size: { xxs: 36, sm: 12, md: 6 },
      title: true,
      sortable: true,
      hidden: true,
      fDisplay: item => localNotificationCode(item, props.intl),
      card: { role: 'FIELD', position: 4 },
      filterable: true,
    },
    {
      name: 'notif_ts',
      label: props.intl.formatMessage({
        id: 'app.features.notification.field.notif_ts.label',
        defaultMessage: 'Quand',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.notification.field.notif_ts.short',
        defaultMessage: 'Quand',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.notification.field.notif_ts.comment',
        defaultMessage: 'La date de création de la notification',
      }),
      col: 'notif_ts',
      size: { xxs: 36, sm: 12, md: 5 },
      title: true,
      sortable: true,
      card: { role: 'FIELD', position: 1 },
      filterable: { type: 'datetime' },
      type: 'datetime',
    },
    {
      name: 'notif_type',
      label: props.intl.formatMessage({
        id: 'app.features.notification.field.notif_type.label',
        defaultMessage: 'Type',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.notification.field.notif_type.short',
        defaultMessage: 'Type',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.notification.field.notif_type.comment',
        defaultMessage: 'Le type de la notification',
      }),
      col: 'notif_type',
      size: { xxs: 36, sm: 12, md: 8 },
      title: true,
      sortable: true,
      fDisplay: item => localNotificationType(item, props.intl),
      card: { role: 'FIELD' },
      filterable: true,
    },
  ];
  if (colTheme === 'ALL') {
    cols.push(
      {
        name: 'notif_read',
        label: props.intl.formatMessage({
          id: 'app.features.notification.field.notif_read.label',
          defaultMessage: 'Lue ?',
        }),
        shortLabel: props.intl.formatMessage({
          id: 'app.features.notification.field.notif_read.short',
          defaultMessage: 'Lue ?',
        }),
        comment: props.intl.formatMessage({
          id: 'app.features.notification.field.notif_read.comment',
          defaultMessage: 'La notification a-t-elle été lue ?',
        }),
        col: 'notif_read',
        size: { xxs: 36, sm: 12, md: 4 },
        title: true,
        sortable: true,
        card: { role: 'FIELD', hideEmpty: true, position: 10 },
        filterable: { type: 'bool' },
        type: 'bool',
        values: displayColBool,
      },
      {
        name: 'notif_read_ts',
        label: props.intl.formatMessage({
          id: 'app.features.notification.field.notif_read_ts.label',
          defaultMessage: 'Lue le',
        }),
        shortLabel: props.intl.formatMessage({
          id: 'app.features.notification.field.notif_read_ts.short',
          defaultMessage: 'Lue le',
        }),
        comment: props.intl.formatMessage({
          id: 'app.features.notification.field.notif_read_ts.comment',
          defaultMessage: 'La date de lecture',
        }),
        col: 'notif_read_ts',
        size: { xxs: 36, sm: 12, md: 4 },
        title: true,
        sortable: true,
        card: { role: 'FIELD', hideEmpty: true, position: 11 },
        filterable: { type: 'datetime' },
        type: 'datetime',
      },
      {
        name: 'user',
        label: props.intl.formatMessage({
          id: 'app.features.notification.field.user_id.label',
          defaultMessage: 'Utilisateur',
        }),
        shortLabel: props.intl.formatMessage({
          id: 'app.features.notification.field.user_id.short',
          defaultMessage: 'Utilisateur',
        }),
        comment: props.intl.formatMessage({
          id: 'app.features.notification.field.user_id.comment',
          defaultMessage: "L'utilisateur ayant lu la notification",
        }),
        col: 'user',
        fDisplay: userAvatar,
        size: { xxs: 36, sm: 12, md: 4 },
        title: true,
        sortable: true,
        card: { role: 'FIELD', hideEmpty: true, position: 13 },
        filterable: true,
      },
    );
  }
  return cols;
};
