import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as actions from './redux/actions';
import { Group as GroupIcon, GetOne as GetOneIcon } from '../icons';
import { Input as InputGroup } from '../group';

export class Dashboard extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    overlay: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.onClose = this.onClose.bind(this);
    this.onOpen = this.onOpen.bind(this);
  }

  onClose() {
    this.setState({ open: false });
  }

  onOpen() {
    this.setState({ open: true });
  }

  render() {
    const group = this.props.auth.realm;
    if (group) {
      return (
        <div className="dashboard-dashboard-card-stat select-none">
          <div className="row card-draggable-area">
            <div className="col-xxs-w14 text-center">
              <button className="nav-link btn btn-secondary-light" onClick={this.onOpen}>
                <div className="text-secondary">
                  <GroupIcon size={2} />
                </div>
              </button>
            </div>
            <div className="col-xxs-w22">
              <div className="row">
                <div className="col-xxs-w36 text-center">
                  <h4 className="card-title">{group.grp_name}</h4>
                  {this.props.onEdit && !this.props.overlay && (
                    <button className="btn-edit btn btn-xs btn-secondary-light text-secondary pl-2">
                      <GetOneIcon size={0.6} onClick={this.props.onEdit} />
                    </button>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-xxs-w36 text-center">
                  <p className="card-category text-secondary"></p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxs-w36 text-center pl-3, pr-3">
              <span>{group.grp_address1}</span>
              <br />
              <span>
                {group.grp_cp} {group.grp_town}
              </span>
              <br />
              <br />
              <span>Tel : {group.grp_phone}</span>
              <br />
              <span>{group.grp_email}</span>
            </div>
          </div>
          {this.state.open && <InputGroup id={this.props.auth.realm.id} edition={[]} onClose={this.onClose} />}
        </div>
      );
    }
    return null;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
