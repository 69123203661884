export const USER_LOAD_MORE_INIT = 'USER_LOAD_MORE_INIT';
export const USER_LOAD_MORE_BEGIN = 'USER_LOAD_MORE_BEGIN';
export const USER_LOAD_MORE_SUCCESS = 'USER_LOAD_MORE_SUCCESS';
export const USER_LOAD_MORE_FAILURE = 'USER_LOAD_MORE_FAILURE';
export const USER_LOAD_MORE_DISMISS_ERROR = 'USER_LOAD_MORE_DISMISS_ERROR';
export const USER_LOAD_ONE_BEGIN = 'USER_LOAD_ONE_BEGIN';
export const USER_LOAD_ONE_SUCCESS = 'USER_LOAD_ONE_SUCCESS';
export const USER_LOAD_ONE_FAILURE = 'USER_LOAD_ONE_FAILURE';
export const USER_LOAD_ONE_DISMISS_ERROR = 'USER_LOAD_ONE_DISMISS_ERROR';
export const USER_UPDATE_ONE_BEGIN = 'USER_UPDATE_ONE_BEGIN';
export const USER_UPDATE_ONE_SUCCESS = 'USER_UPDATE_ONE_SUCCESS';
export const USER_UPDATE_ONE_FAILURE = 'USER_UPDATE_ONE_FAILURE';
export const USER_UPDATE_ONE_DISMISS_ERROR = 'USER_UPDATE_ONE_DISMISS_ERROR';
export const USER_DEL_ONE_BEGIN = 'USER_DEL_ONE_BEGIN';
export const USER_DEL_ONE_SUCCESS = 'USER_DEL_ONE_SUCCESS';
export const USER_DEL_ONE_FAILURE = 'USER_DEL_ONE_FAILURE';
export const USER_DEL_ONE_DISMISS_ERROR = 'USER_DEL_ONE_DISMISS_ERROR';
export const USER_INIT_SORT = 'USER_INIT_SORT';
export const USER_SET_SORT = 'USER_SET_SORT';
export const USER_UPDATE_SORT = 'USER_UPDATE_SORT';
export const USER_INIT_FILTERS = 'USER_INIT_FILTERS';
export const USER_SET_FILTERS = 'USER_SET_FILTERS';
export const USER_CREATE_ONE_BEGIN = 'USER_CREATE_ONE_BEGIN';
export const USER_CREATE_ONE_SUCCESS = 'USER_CREATE_ONE_SUCCESS';
export const USER_CREATE_ONE_FAILURE = 'USER_CREATE_ONE_FAILURE';
export const USER_CREATE_ONE_DISMISS_ERROR = 'USER_CREATE_ONE_DISMISS_ERROR';
export const USER_CLEAR_ITEMS = 'USER_CLEAR_ITEMS';
export const USER_UPDATE_QUICK_SEARCH = 'USER_UPDATE_QUICK_SEARCH';
export const USER_UPLOAD_AVATAR_BEGIN = 'USER_UPLOAD_AVATAR_BEGIN';
export const USER_UPLOAD_AVATAR_SUCCESS = 'USER_UPLOAD_AVATAR_SUCCESS';
export const USER_UPLOAD_AVATAR_FAILURE = 'USER_UPLOAD_AVATAR_FAILURE';
export const USER_UPLOAD_AVATAR_DISMISS_ERROR = 'USER_UPLOAD_AVATAR_DISMISS_ERROR';
export const USER_LOAD_TOKENS_BEGIN = 'USER_LOAD_TOKENS_BEGIN';
export const USER_LOAD_TOKENS_SUCCESS = 'USER_LOAD_TOKENS_SUCCESS';
export const USER_LOAD_TOKENS_FAILURE = 'USER_LOAD_TOKENS_FAILURE';
export const USER_LOAD_TOKENS_DISMISS_ERROR = 'USER_LOAD_TOKENS_DISMISS_ERROR';
export const USER_PROPAGATE = 'USER_PROPAGATE';
