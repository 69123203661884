import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { ResponsiveForm, ResponsiveModal } from 'react-bootstrap-front';
import {
  AddOne as AddOneIcon,
  DelOne as DelOneIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  Valid as ValidIcon,
} from '../icons';

class ResponsiveModalOrForm extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    onSubmit: PropTypes.func,
  };
  static defaultProps = {
    onClose: null,
    onSave: null,
    onSubmit: null,
  };

  render() {
    if (this.props.modal) {
      let buttons = [];
      if (this.props.onSave) {
        buttons.push({
          function: this.props.onSave,
          theme: 'primary',
          icon: <SaveIcon title="Sauvegarder" />,
        });
      }
      if (this.props.actionsButtons) {
        this.props.actionsButtons.forEach(btnAction => {
          if (!btnAction.hidden) {
            buttons.push(btnAction);
          }
        });
      }
      if (this.props.onSave || this.props.actionsButtons) {
        buttons.push({
          name: '',
          icon: '',
        });
      }
      if (this.props.onSubmit) {
        buttons.push({
          name: this.props.intl.formatMessage({
            id: 'app.features.ui.responsiveModalOrForm.save',
            defaultMessage: 'Save',
          }),
          function: this.props.onSubmit,
          theme: 'primary',
          icon: <ValidIcon />,
        });
      }
      if (this.props.onClose) {
        buttons.push({
          name: this.props.intl.formatMessage({
            id: 'app.features.ui.responsiveModalOrForm.cancel',
          }),
          defaultMessage: 'Cancel',
          function: this.props.onClose,
          theme: 'secondary',
          icon: <CancelIcon />,
        });
      }
      return (
        <ResponsiveModal
          {...this.props}
          size={this.props.size || 'fullscreen'}
          show={true}
          addIcon={<AddOneIcon className="text-light" size={0.9} />}
          delIcon={<DelOneIcon className="text-warning" size={0.9} />}
          buttons={buttons}
        />
      );
    }
    return <ResponsiveForm {...this.props} />;
  }
}

export default injectIntl(ResponsiveModalOrForm);
