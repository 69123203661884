import React from 'react';
import Icon from '@mdi/react';
import {
  mdiSortDescending as myIcon
} from '@mdi/js';

export default function SortDown(props) {
  return (
    <Icon path={myIcon} size={1} {...props} />
  );
};
