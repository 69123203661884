import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Row, Col, Highlight, Container } from 'react-bootstrap-front';
import * as actions from './redux/actions';
import { Copyright } from '../ui';
import { DashboardGrid } from '../dashboard';
import logo from '../../images/logo-timbre.png';

export class Home extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  render() {
    const { intl } = this.props;
    return (
      <Container>
        {!this.props.auth.authenticated ? (
          <div className="text-center">
            <br />
            <h1 className="text-secondary">
              <FormattedMessage
                id="app.features.about.about.application"
                defaultMessage="Kalaweit application"
              />
            </h1>
            <br />
            <br />
            <Row className="mb-2">
              <div className="col-xxs-w36 col-md-w18">
                <img src={logo} alt="" />
              </div>
              <div className="col-xxs-w36 col-md-w18">
                <div className="card flex-md-row mb-4 box-shadow h-md-250">
                  <div className="card-body d-flex flex-column align-items-start">
                    <h3 className="mb-0">
                      <span className="text-dark">Kalaweit</span>
                    </h3>
                    <p className="card-text mb-auto">
                      <FormattedMessage
                        id="app.contact.address1"
                        defaultMessage="69 rue Mouffetard"
                      />
                    </p>
                    <p className="card-text mb-auto">
                      <FormattedMessage id="app.contact.address2" defaultMessage="75005 Paris" />
                    </p>
                    <p className="card-text mb-auto">
                      <FormattedMessage id="app.contact.tel" defaultMessage="(+33) 7 86 01 18 87" />
                    </p>
                    <p className="card-text mb-auto">
                      <a
                        href={
                          'mailto:' +
                          intl.formatMessage({
                            id: 'app.contact.email',
                            defaultMessage: 'kalaweit.france@yahoo.fr',
                          })
                        }
                      >
                        <FormattedMessage
                          id="app.contact.email"
                          defaultMessage="kalaweit.france@yahoo.fr"
                        />
                      </a>
                    </p>
                    <a
                      href={intl.formatMessage({
                        id: 'app.contact.site',
                        defaultMessage: 'https://kalaweit.org',
                      })}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessage
                        id="app.contact.site"
                        defaultMessage="https://kalaweit.org"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </Row>
            <Row>
              <Col size={{xxs:0, sm: 12}} />
              <Col size={{xxs:36, sm: 12}}>
                {' '}
                <Highlight
                  title={intl.formatMessage({
                    id: 'app.features.home.home.connexion.help',
                    defaultMessage: 'Go to login',
                  })}
                  theme="NAV"
                  position="bottom"
                >
                  <button
                    className="btn btn-lg btn-primary btn-block"
                    onClick={() => this.props.actions.push('/auth/signin')}
                  >
                    <FormattedMessage
                      id="app.features.home.home.connexion"
                      defaultMessage="Connexion page"
                    />
                  </button>
                </Highlight>
              </Col>
            </Row>
            <br />
            <Copyright />
          </div>
        ) : (
          <DashboardGrid />
        )}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, push }, dispatch),
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Home),
);
