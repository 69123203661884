import { CLIENT_INIT_FILTERS } from './constants';
import { Filter, FILTER_MODE_AND, FILTER_OPER_LIKE } from 'react-bootstrap-front';

export function defaultFilters() {
  let filters = new Filter();
  filters.init(FILTER_MODE_AND, FILTER_OPER_LIKE);
  return filters;
}

export function initFilters() {
  return {
    type: CLIENT_INIT_FILTERS,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CLIENT_INIT_FILTERS:
      return {
        ...state,
        filters: defaultFilters(),
      };

    default:
      return state;
  }
}
