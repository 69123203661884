import React from 'react';
import {
  Print as PrintIcon,
} from '../icons';
import { Avatar } from '../ui';
import { getLibObject } from '../../common';
import { getLibMethod } from './';

export const localHistoryObjectLabel = item => {
  return getLibObject(item.hist_object_name, item.hist_object_id);
};

export const localHistoryMethod = item => {
  return getLibMethod(item.hist_method);
};

export const userAvatar = item => {
  if (item && item.user && item.user.id) {
    return <Avatar user={item.user} className="rounded-circle" size="22" />;
  }
  return null;
};

/**
 * Shortcuts
 *
 * @param {Object} intl
 * @param {String} mode
 */
export const getShortcuts = (intl, mode = 'all') => {
  let shortcuts = [];
  shortcuts.push({
    name: 'ident',
    icon: '',
    size: 'maximized',
    display: 'block',
    position: 1,
    label: intl.formatMessage({
      id: 'app.features.history.form.tabs.ident',
      defaultMessage: 'Identification',
    }),
  });
  if (mode === 'all') {
  }
  return shortcuts;
};

/**
 * Gestion de l'affichage du champ en fonction du modèle
 */
export function getPickerDisplay(item) {
  let display = '';
  if (item && item.id > 0) {
    display = item.jobq_name;
  }
  return display;
}

/**
 * Affichage des agents dans la liste des propositions de la recherhe
 * ou dans la liste proposées sous le champ en autocomplète
 */
export const displayItemPicker = item => {
  return (
    <p className="input-picker-line">
      <span className="input-picker-left">{item.jobq_name}</span>
    </p>
  );
};

/**
 * Actions disponibles depuis l'en-tête de la liste des agents
 */
export const getGlobalActions = ({ props, onClearFilters, onCreate }) => {
  return [];
};

/**
 * Actions disponibles sur la fiche (en plus de l'enregistrement et de l'annulation)
 */
export const getActionsButtons = ({ onPrint, state }) => {
  const { editions } = state;
  let myOptions = [];
  let printabled = false;
  if (editions && Array.isArray(editions) && editions.length > 0) {
    printabled = true;
    editions.forEach(edition => {
      myOptions.push({
        id: edition.id,
        label: edition.edi_name,
        onClick: () => onPrint(edition.id),
      });
    });
  }
  return [
    {
      theme: 'secondary',
      hidden: !printabled,
      options: myOptions,
      optionsAlign: 'top-left',
      function: () => onPrint(),
      icon: <PrintIcon title="Imprimer" />,
    },
  ];
};

/**
 * Actions disponibles depuis l'en-tête de la liste des agents pour agir sur les lignes de la liste
 *    Sélection lignes
 *    Dé-sélection
 *    ...
 *    Actions sur lignes sélectionnées
 *    ou sur toutes les lignes
 */
export const getSelectActions = ({ props, onSelectMenu }) => {
  let arrOne = [
    {
      name: 'selectAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.selectAll',
        defaultMessage: 'Select all',
      }),
      onClick: () => {
        onSelectMenu('selectAll');
      },
    },
  ];
  const arrAppend = [
    {
      name: 'selectNone',
      label: props.intl.formatMessage({
        id: 'app.list.options.deselectAll',
        defaultMessage: 'Deselect all',
      }),
      onClick: () => {
        onSelectMenu('selectNone');
      },
    },
    { name: 'divider' },
    {
      name: 'exportSelect',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportSelect',
        defaultMessage: 'Export selection',
      }),
      onClick: () => {
        onSelectMenu('exportSelection');
      },
    },
  ];
  if (props.history.selected.length > 0) {
    arrOne = arrOne.concat(arrAppend);
  }
  const arrStandard = [
    { name: 'divider' },
    {
      name: 'exportAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportAll',
        defaultMessage: 'Export all',
      }),
      onClick: () => {
        onSelectMenu('exportAll');
      },
    },
  ];
  arrOne = arrOne.concat(arrStandard);
  return arrOne;
};

/**
 * Actions disponibles sur une ligne de la liste des agents au survol de cette ligne
 */
export const getInlineActions = () => {
  return [];
};

/**
 * Descriptions de toutes les colonnes de la liste
 * Même les colonnes cachées
 * Ces colonnes peuvent ou non données des champs dans les filtres
 * (une info peut de pas être affichée dans la liste mais on peut peut-être mettre un filtre dessus)
 */
export const getCols = ({ props }) => {
  return [
    {
      name: 'hist_id',
      label: props.intl.formatMessage({
        id: 'app.features.history.field.hist_id.label',
        defaultMessage: 'Id.',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.history.field.hist_id.short',
        defaultMessage: 'Id.',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.history.field.hist_id.comment',
        defaultMessage: 'Identifiant',
      }),
      col: 'hist_id',
      size: { xxs: 36, sm: 10, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'ID' },
      filterable: true,
    },
    {
      name: 'brk_id',
      label: props.intl.formatMessage({
        id: 'app.features.history.field.brk_id.label',
        defaultMessage: 'Broker',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.history.field.brk_id.short',
        defaultMessage: 'Broker',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.history.field.brk_id.comment',
        defaultMessage: 'Identifiant du broker',
      }),
      col: 'brk_id',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      first: true,
      filterable: true,
    },
    {
      name: 'user',
      label: props.intl.formatMessage({
        id: 'app.features.history.field.user_id.label',
        defaultMessage: 'Utilisateur',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.history.field.user_id.short',
        defaultMessage: 'Utilisateur',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.history.field.user_id.comment',
        defaultMessage: 'Utilisateur',
      }),
      col: 'user',
      size: { xxs: 36, sm: 10, md: 4 },
      title: true,
      sortable: true,
      fDisplay: userAvatar,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'hist_ts',
      label: props.intl.formatMessage({
        id: 'app.features.history.field.hist_ts.label',
        defaultMessage: 'Date heure',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.history.field.hist_ts.short',
        defaultMessage: 'Date heure',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.history.field.hist_ts.comment',
        defaultMessage: "Date heure de l'historique",
      }),
      col: 'hist_ts',
      size: { xxs: 36, sm: 6, md: 5 },
      title: true,
      sortable: true,
      card: { role: 'FIELD', position: 1 },
      filterable: { type: 'datetime' },
      type: 'datetime',
    },
    {
      name: 'hist_method',
      label: props.intl.formatMessage({
        id: 'app.features.history.field.hist_method.label',
        defaultMessage: 'Méthode',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.history.field.hist_method.short',
        defaultMessage: 'Méthode',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.history.field.hist_method.comment',
        defaultMessage: 'Méthode',
      }),
      col: 'hist_method',
      size: { xxs: 36, sm: 6, md: 5 },
      title: true,
      sortable: true,
      fDisplay: localHistoryMethod,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'hist_object_name',
      label: props.intl.formatMessage({
        id: 'app.features.history.field.hist_object_name.label',
        defaultMessage: 'Objet',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.history.field.hist_object_name.short',
        defaultMessage: 'Objet',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.history.field.hist_object_name.comment',
        defaultMessage: "Nom de l'objet",
      }),
      col: 'hist_object_name',
      size: { xxs: 36, sm: 10, md: 8 },
      title: true,
      sortable: true,
      fDisplay: localHistoryObjectLabel,
      card: { role: 'TITLE' },
      filterable: true,
    },
    {
      name: 'hist_object_id',
      label: props.intl.formatMessage({
        id: 'app.features.history.field.hist_object_id.label',
        defaultMessage: 'Id. objet',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.history.field.hist_object_id.short',
        defaultMessage: 'Id. objet',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.history.field.hist_object_id.comment',
        defaultMessage: "Identifiant de l'objet",
      }),
      col: 'hist_object_id',
      size: { xxs: 36, sm: 6, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'hist_object',
      label: props.intl.formatMessage({
        id: 'app.features.history.field.hist_object.label',
        defaultMessage: 'Contenu',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.history.field.hist_object.short',
        defaultMessage: 'Contenu',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.history.field.hist_object.comment',
        defaultMessage: 'Contenu',
      }),
      col: 'hist_object',
      size: { xxs: 36, sm: 8, md: 6 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
  ];
};
