import React, { Component } from 'react';
import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import * as actions from './redux/actions';
import { PanelBadge as InboxBadge } from '../inbox';
import { PanelBadge as NotificationBadge } from '../notification';
import { Help as HelpIcon } from '../icons';

export class RightPanelBadges extends Component {
  static propTypes = {
    common: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      animated: false,
    };
    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
    this.openPanel = this.openPanel.bind(this);
  }

  openPanel(panel) {
    cookie.save('APP_HELP', 'READ');
    this.props.actions.setPanel(panel);
  }

  handleOnAction(event) {
    this.setState({ animated: false });
  }

  handleOnActive(event) {
    this.setState({ animated: false });
  }

  handleOnIdle(event) {
    if (!cookie.load('APP_HELP')) {
      this.setState({ animated: true });
    }
  }

  render() {
    return (
      <div className="common-right-panel-badges">
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          timeout={1000 * 15}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
        <ul className="common-right-panel-badges-list navbar-nav">
          <li className="common-right-panel-badges-item nav-item">
            <InboxBadge onClick={ev => this.openPanel('inbox')} />
          </li>
          <li className="common-right-panel-badges-item nav-item">
            <NotificationBadge onClick={ev => this.openPanel('notification')} />
          </li>
        </ul>
        <div
          title="Aide"
          style={{ position: 'absolute', bottom: '4px' }}
          className={classnames(this.state.animated && 'bouncing-help')}
        >
          <button
            className={classnames(
              'btn btn-light text-secondary bouncing-help-icon btn--shockwave',
              this.state.animated && 'is-active',
            )}
            onClick={ev => this.openPanel('help')}
          >
            <HelpIcon />
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    common: state.common,
    notification: state.notification,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RightPanelBadges);
