import React from 'react';
import { injectIntl } from 'react-intl';
import { getShortcuts } from './';
import useForm from '../ui/useForm';
import {
  ResponsiveModalOrForm,
  InputHidden,
  InputText,
  InputCheckbox,
  Row,
  Col,
  InputHtml,
} from '../ui';

/**
 * Initialisation
 *
 * @param {Object}   item
 * @param {Function} setItem
 */
const initItem = (item, setItem) => {};

/**
 * Un champ a été modifié
 *
 * @param {String}   field
 * @param {Object}   item
 * @param {Function} setitem
 */
const afterChange = (field, item, setItem) => {
  switch (field) {
    default:
      break;
  }
};

/**
 * Saisie des informations d'un modèle
 */
function Form(props) {
  let item = props.item;
  // On récupère tout ce qu'il faut pour travailler
  const {
    values,
    handleChange,
    handleSave,
    handleSubmit,
    handleCancel,
    handleNavTab,
    getErrorMessage,
    getCurrentTab,
  } = useForm(
    item,
    props.tab,
    props.onSubmit,
    props.onCancel,
    props.onNavTab,
    props.errors,
    props.intl,
    afterChange,
    initItem,
  );
  // Le formulaire
  return (
    <ResponsiveModalOrForm
      title={
        !props.modify && !values.tr_key
          ? props.intl.formatMessage({
              id: 'app.features.translation.form.title',
              defaultMessage: 'New model',
            })
          : values.tr_key
      }
      className=""
      tab={getCurrentTab()}
      tabs={!props.modify ? getShortcuts(props.intl, 'new') : getShortcuts(props.intl, 'all')}
      size="md"
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onNavTab={handleNavTab}
      onSave={handleSave}
      onClose={handleCancel}
      onPrevious={props.onPrevious}
      onNext={props.onNext}
      modal={true}
      actionsButtons={props.actionsButtons}
      loader={props.loader ? props.loader : false}
    >
      <InputHidden name="id" id="id" value={values.id} />
      <Row>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.translation.field.tr_key.label',
              defaultMessage: 'tr_key',
            })}
            id="tr_key"
            name="tr_key"
            value={values.tr_key}
            required={true}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('tr_key')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputText
            label={props.intl.formatMessage({
              id: 'app.features.translation.field.tr_type.label',
              defaultMessage: 'tr_type',
            })}
            id="tr_type"
            name="tr_type"
            value={values.tr_type}
            required={true}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('tr_type')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputCheckbox
            label={props.intl.formatMessage({
              id: 'app.features.translation.field.tr_html.label',
              defaultMessage: 'tr_html',
            })}
            id="tr_html"
            name="tr_html"
            checked={values.tr_html === true}
            required={true}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('tr_html')}
          />
        </Col>
        <Col size={{ xxs: 36 }}>
          <InputHtml
            label={props.intl.formatMessage({
              id: 'app.features.translation.field.tr_desc.label',
              defaultMessage: 'tr_desc',
            })}
            id="tr_desc"
            name="tr_desc"
            value={values.tr_desc}
            labelTop={true}
            disabled={values.__modify && values.tr_html}
            onChange={handleChange}
            error={getErrorMessage('tr_desc')}
          />
        </Col>
      </Row>
      {!values.tr_html ? (
        <Row>
          <Col size={{ xxs: 36 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.translation.field.tr_lang_fr.label',
                defaultMessage: 'tr_lang_fr',
              })}
              id="tr_lang_fr"
              name="tr_lang_fr"
              value={values.tr_lang_fr}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('tr_lang_fr')}
            />
          </Col>
          <Col size={{ xxs: 36 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.translation.field.tr_lang_en.label',
                defaultMessage: 'tr_lang_en',
              })}
              id="tr_lang_en"
              name="tr_lang_en"
              value={values.tr_lang_en}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('tr_lang_en')}
            />
          </Col>
          <Col size={{ xxs: 36 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.translation.field.tr_lang_ch.label',
                defaultMessage: 'tr_lang_ch',
              })}
              id="tr_lang_ch"
              name="tr_lang_ch"
              value={values.tr_lang_ch}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('tr_lang_ch')}
            />
          </Col>
          <Col size={{ xxs: 36 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.translation.field.tr_lang_de.label',
                defaultMessage: 'tr_lang_de',
              })}
              id="tr_lang_de"
              name="tr_lang_de"
              value={values.tr_lang_de}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('tr_lang_de')}
            />
          </Col>
          <Col size={{ xxs: 36 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.translation.field.tr_lang_es.label',
                defaultMessage: 'tr_lang_es',
              })}
              id="tr_lang_es"
              name="tr_lang_es"
              value={values.tr_lang_es}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('tr_lang_es')}
            />
          </Col>
          <Col size={{ xxs: 36 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.translation.field.tr_lang_id.label',
                defaultMessage: 'tr_lang_id',
              })}
              id="tr_lang_id"
              name="tr_lang_id"
              value={values.tr_lang_id}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('tr_lang_id')}
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col size={{ xxs: 36 }}>
            <InputHtml
              label={props.intl.formatMessage({
                id: 'app.features.translation.field.tr_lang_fr.label',
                defaultMessage: 'tr_lang_fr',
              })}
              id="tr_lang_fr"
              name="tr_lang_fr"
              value={values.tr_lang_fr}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('tr_lang_fr')}
            />
          </Col>
          <Col size={{ xxs: 36 }}>
            <InputHtml
              label={props.intl.formatMessage({
                id: 'app.features.translation.field.tr_lang_en.label',
                defaultMessage: 'tr_lang_en',
              })}
              id="tr_lang_en"
              name="tr_lang_en"
              value={values.tr_lang_en}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('tr_lang_en')}
            />
          </Col>
          <Col size={{ xxs: 36 }}>
            <InputHtml
              label={props.intl.formatMessage({
                id: 'app.features.translation.field.tr_lang_ch.label',
                defaultMessage: 'tr_lang_ch',
              })}
              id="tr_lang_ch"
              name="tr_lang_ch"
              value={values.tr_lang_ch}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('tr_lang_ch')}
            />
          </Col>
          <Col size={{ xxs: 36 }}>
            <InputHtml
              label={props.intl.formatMessage({
                id: 'app.features.translation.field.tr_lang_de.label',
                defaultMessage: 'tr_lang_de',
              })}
              id="tr_lang_de"
              name="tr_lang_de"
              value={values.tr_lang_de}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('tr_lang_de')}
            />
          </Col>
          <Col size={{ xxs: 36 }}>
            <InputHtml
              label={props.intl.formatMessage({
                id: 'app.features.translation.field.tr_lang_es.label',
                defaultMessage: 'tr_lang_es',
              })}
              id="tr_lang_es"
              name="tr_lang_es"
              value={values.tr_lang_es}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('tr_lang_es')}
            />
          </Col>
          <Col size={{ xxs: 36 }}>
            <InputHtml
              label={props.intl.formatMessage({
                id: 'app.features.translation.field.tr_lang_id.label',
                defaultMessage: 'tr_lang_id',
              })}
              id="tr_lang_id"
              name="tr_lang_id"
              value={values.tr_lang_id}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('tr_lang_id')}
            />
          </Col>
        </Row>
      )}
    </ResponsiveModalOrForm>
  );
}

/**
 * Injection de l'intl
 */
export default injectIntl(Form);
