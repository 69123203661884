export const CERTIFICATE_CLEAR_ITEMS = 'CERTIFICATE_CLEAR_ITEMS';
export const CERTIFICATE_CREATE_ONE_BEGIN = 'CERTIFICATE_CREATE_ONE_BEGIN';
export const CERTIFICATE_CREATE_ONE_SUCCESS = 'CERTIFICATE_CREATE_ONE_SUCCESS';
export const CERTIFICATE_CREATE_ONE_FAILURE = 'CERTIFICATE_CREATE_ONE_FAILURE';
export const CERTIFICATE_CREATE_ONE_DISMISS_ERROR = 'CERTIFICATE_CREATE_ONE_DISMISS_ERROR';
export const CERTIFICATE_DEL_ONE_BEGIN = 'CERTIFICATE_DEL_ONE_BEGIN';
export const CERTIFICATE_DEL_ONE_SUCCESS = 'CERTIFICATE_DEL_ONE_SUCCESS';
export const CERTIFICATE_DEL_ONE_FAILURE = 'CERTIFICATE_DEL_ONE_FAILURE';
export const CERTIFICATE_DEL_ONE_DISMISS_ERROR = 'CERTIFICATE_DEL_ONE_DISMISS_ERROR';
export const CERTIFICATE_EXPORT_AS_TAB_BEGIN = 'CERTIFICATE_EXPORT_AS_TAB_BEGIN';
export const CERTIFICATE_EXPORT_AS_TAB_SUCCESS = 'CERTIFICATE_EXPORT_AS_TAB_SUCCESS';
export const CERTIFICATE_EXPORT_AS_TAB_FAILURE = 'CERTIFICATE_EXPORT_AS_TAB_FAILURE';
export const CERTIFICATE_EXPORT_AS_TAB_DISMISS_ERROR = 'CERTIFICATE_EXPORT_AS_TAB_DISMISS_ERROR';
export const CERTIFICATE_EXPORT_BEGIN = 'CERTIFICATE_EXPORT_BEGIN';
export const CERTIFICATE_EXPORT_SUCCESS = 'CERTIFICATE_EXPORT_SUCCESS';
export const CERTIFICATE_EXPORT_FAILURE = 'CERTIFICATE_EXPORT_FAILURE';
export const CERTIFICATE_EXPORT_DISMISS_ERROR = 'CERTIFICATE_EXPORT_DISMISS_ERROR';
export const CERTIFICATE_INIT_FILTERS = 'CERTIFICATE_INIT_FILTERS';
export const CERTIFICATE_INIT_SORT = 'CERTIFICATE_INIT_SORT';
export const CERTIFICATE_LOAD_MORE_INIT = 'CERTIFICATE_LOAD_MORE_INIT';
export const CERTIFICATE_LOAD_MORE_BEGIN = 'CERTIFICATE_LOAD_MORE_BEGIN';
export const CERTIFICATE_LOAD_MORE_SUCCESS = 'CERTIFICATE_LOAD_MORE_SUCCESS';
export const CERTIFICATE_LOAD_MORE_FAILURE = 'CERTIFICATE_LOAD_MORE_FAILURE';
export const CERTIFICATE_LOAD_MORE_DISMISS_ERROR = 'CERTIFICATE_LOAD_MORE_DISMISS_ERROR';
export const CERTIFICATE_LOAD_ONE_BEGIN = 'CERTIFICATE_LOAD_ONE_BEGIN';
export const CERTIFICATE_LOAD_ONE_SUCCESS = 'CERTIFICATE_LOAD_ONE_SUCCESS';
export const CERTIFICATE_LOAD_ONE_FAILURE = 'CERTIFICATE_LOAD_ONE_FAILURE';
export const CERTIFICATE_LOAD_ONE_DISMISS_ERROR = 'CERTIFICATE_LOAD_ONE_DISMISS_ERROR';
export const CERTIFICATE_ON_SELECT = 'CERTIFICATE_ON_SELECT';
export const CERTIFICATE_PRINT_ONE_BEGIN = 'CERTIFICATE_PRINT_ONE_BEGIN';
export const CERTIFICATE_PRINT_ONE_SUCCESS = 'CERTIFICATE_PRINT_ONE_SUCCESS';
export const CERTIFICATE_PRINT_ONE_FAILURE = 'CERTIFICATE_PRINT_ONE_FAILURE';
export const CERTIFICATE_PRINT_ONE_DISMISS_ERROR = 'CERTIFICATE_PRINT_ONE_DISMISS_ERROR';
export const CERTIFICATE_PROPAGATE = 'CERTIFICATE_PROPAGATE';
export const CERTIFICATE_SELECT_NONE = 'CERTIFICATE_SELECT_NONE';
export const CERTIFICATE_SELECT_ALL = 'CERTIFICATE_SELECT_ALL';
export const CERTIFICATE_SET_CURRENT = 'CERTIFICATE_SET_CURRENT';
export const CERTIFICATE_SET_PREVIOUS = 'CERTIFICATE_SET_PREVIOUS';
export const CERTIFICATE_SET_NEXT_BEGIN = 'CERTIFICATE_SET_NEXT_BEGIN';
export const CERTIFICATE_SET_NEXT_SUCCESS = 'CERTIFICATE_SET_NEXT_SUCCESS';
export const CERTIFICATE_SET_NEXT_FAILURE = 'CERTIFICATE_SET_NEXT_FAILURE';
export const CERTIFICATE_SET_NEXT_DISMISS_ERROR = 'CERTIFICATE_SET_NEXT_DISMISS_ERROR';
export const CERTIFICATE_SET_FILTERS = 'CERTIFICATE_SET_FILTERS';
export const CERTIFICATE_SET_SORT = 'CERTIFICATE_SET_SORT';
export const CERTIFICATE_UPDATE_ONE_BEGIN = 'CERTIFICATE_UPDATE_ONE_BEGIN';
export const CERTIFICATE_UPDATE_ONE_SUCCESS = 'CERTIFICATE_UPDATE_ONE_SUCCESS';
export const CERTIFICATE_UPDATE_ONE_FAILURE = 'CERTIFICATE_UPDATE_ONE_FAILURE';
export const CERTIFICATE_UPDATE_ONE_DISMISS_ERROR = 'CERTIFICATE_UPDATE_ONE_DISMISS_ERROR';
export const CERTIFICATE_UPDATE_QUICK_SEARCH = 'CERTIFICATE_UPDATE_QUICK_SEARCH';
export const CERTIFICATE_UPDATE_SORT = 'CERTIFICATE_UPDATE_SORT';
export const CERTIFICATE_GENERATE_ONE_BEGIN = 'CERTIFICATE_GENERATE_ONE_BEGIN';
export const CERTIFICATE_GENERATE_ONE_SUCCESS = 'CERTIFICATE_GENERATE_ONE_SUCCESS';
export const CERTIFICATE_GENERATE_ONE_FAILURE = 'CERTIFICATE_GENERATE_ONE_FAILURE';
export const CERTIFICATE_GENERATE_ONE_DISMISS_ERROR = 'CERTIFICATE_GENERATE_ONE_DISMISS_ERROR';
export const CERTIFICATE_DOWNLOAD_ONE_BEGIN = 'CERTIFICATE_DOWNLOAD_ONE_BEGIN';
export const CERTIFICATE_DOWNLOAD_ONE_SUCCESS = 'CERTIFICATE_DOWNLOAD_ONE_SUCCESS';
export const CERTIFICATE_DOWNLOAD_ONE_FAILURE = 'CERTIFICATE_DOWNLOAD_ONE_FAILURE';
export const CERTIFICATE_DOWNLOAD_ONE_DISMISS_ERROR = 'CERTIFICATE_DOWNLOAD_ONE_DISMISS_ERROR';