import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { Badge } from 'react-bootstrap-front';
import { Message as MessageIcon } from '../icons';

export class PanelBadge extends Component {
  static propTypes = {
    notification: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    className: PropTypes.string,
  };
  static defaultProps = {
    className: '',
  }

  render() {
    const num = this.props.notification.pendings ? this.props.notification.pendings.TOTAL : 0;
    const className = num > 0 ? 'text-warning' : '';
    return (
      <Badge
        name="notification"
        count={num > 0 ? num : ''}
        icon={<MessageIcon className={className}/>}
        onClick={this.props.onClick}
        className={classnames("btn btn-light", this.props.className)}
      />
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    notification: state.notification,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelBadge);
