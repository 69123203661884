export const GROUP_CLEAR_ITEMS = 'GROUP_CLEAR_ITEMS';
export const GROUP_CREATE_ONE_BEGIN = 'GROUP_CREATE_ONE_BEGIN';
export const GROUP_CREATE_ONE_SUCCESS = 'GROUP_CREATE_ONE_SUCCESS';
export const GROUP_CREATE_ONE_FAILURE = 'GROUP_CREATE_ONE_FAILURE';
export const GROUP_CREATE_ONE_DISMISS_ERROR = 'GROUP_CREATE_ONE_DISMISS_ERROR';
export const GROUP_DEL_ONE_BEGIN = 'GROUP_DEL_ONE_BEGIN';
export const GROUP_DEL_ONE_SUCCESS = 'GROUP_DEL_ONE_SUCCESS';
export const GROUP_DEL_ONE_FAILURE = 'GROUP_DEL_ONE_FAILURE';
export const GROUP_DEL_ONE_DISMISS_ERROR = 'GROUP_DEL_ONE_DISMISS_ERROR';
export const GROUP_EXPORT_AS_TAB_BEGIN = 'GROUP_EXPORT_AS_TAB_BEGIN';
export const GROUP_EXPORT_AS_TAB_SUCCESS = 'GROUP_EXPORT_AS_TAB_SUCCESS';
export const GROUP_EXPORT_AS_TAB_FAILURE = 'GROUP_EXPORT_AS_TAB_FAILURE';
export const GROUP_EXPORT_AS_TAB_DISMISS_ERROR = 'GROUP_EXPORT_AS_TAB_DISMISS_ERROR';
export const GROUP_EXPORT_BEGIN = 'GROUP_EXPORT_BEGIN';
export const GROUP_EXPORT_SUCCESS = 'GROUP_EXPORT_SUCCESS';
export const GROUP_EXPORT_FAILURE = 'GROUP_EXPORT_FAILURE';
export const GROUP_EXPORT_DISMISS_ERROR = 'GROUP_EXPORT_DISMISS_ERROR';
export const GROUP_INIT_FILTERS = 'GROUP_INIT_FILTERS';
export const GROUP_INIT_SORT = 'GROUP_INIT_SORT';
export const GROUP_LOAD_MORE_INIT = 'GROUP_LOAD_MORE_INIT';
export const GROUP_LOAD_MORE_BEGIN = 'GROUP_LOAD_MORE_BEGIN';
export const GROUP_LOAD_MORE_SUCCESS = 'GROUP_LOAD_MORE_SUCCESS';
export const GROUP_LOAD_MORE_FAILURE = 'GROUP_LOAD_MORE_FAILURE';
export const GROUP_LOAD_MORE_DISMISS_ERROR = 'GROUP_LOAD_MORE_DISMISS_ERROR';
export const GROUP_LOAD_ONE_BEGIN = 'GROUP_LOAD_ONE_BEGIN';
export const GROUP_LOAD_ONE_SUCCESS = 'GROUP_LOAD_ONE_SUCCESS';
export const GROUP_LOAD_ONE_FAILURE = 'GROUP_LOAD_ONE_FAILURE';
export const GROUP_LOAD_ONE_DISMISS_ERROR = 'GROUP_LOAD_ONE_DISMISS_ERROR';
export const GROUP_ON_SELECT = 'GROUP_ON_SELECT';
export const GROUP_PRINT_ONE_BEGIN = 'GROUP_PRINT_ONE_BEGIN';
export const GROUP_PRINT_ONE_SUCCESS = 'GROUP_PRINT_ONE_SUCCESS';
export const GROUP_PRINT_ONE_FAILURE = 'GROUP_PRINT_ONE_FAILURE';
export const GROUP_PRINT_ONE_DISMISS_ERROR = 'GROUP_PRINT_ONE_DISMISS_ERROR';
export const GROUP_PROPAGATE = 'GROUP_PROPAGATE';
export const GROUP_SELECT_NONE = 'GROUP_SELECT_NONE';
export const GROUP_SELECT_ALL = 'GROUP_SELECT_ALL';
export const GROUP_SET_CURRENT = 'GROUP_SET_CURRENT';
export const GROUP_SET_PREVIOUS = 'GROUP_SET_PREVIOUS';
export const GROUP_SET_NEXT_BEGIN = 'GROUP_SET_NEXT_BEGIN';
export const GROUP_SET_NEXT_SUCCESS = 'GROUP_SET_NEXT_SUCCESS';
export const GROUP_SET_NEXT_FAILURE = 'GROUP_SET_NEXT_FAILURE';
export const GROUP_SET_NEXT_DISMISS_ERROR = 'GROUP_SET_NEXT_DISMISS_ERROR';
export const GROUP_SET_FILTERS = 'GROUP_SET_FILTERS';
export const GROUP_SET_SORT = 'GROUP_SET_SORT';
export const GROUP_UPDATE_ONE_BEGIN = 'GROUP_UPDATE_ONE_BEGIN';
export const GROUP_UPDATE_ONE_SUCCESS = 'GROUP_UPDATE_ONE_SUCCESS';
export const GROUP_UPDATE_ONE_FAILURE = 'GROUP_UPDATE_ONE_FAILURE';
export const GROUP_UPDATE_ONE_DISMISS_ERROR = 'GROUP_UPDATE_ONE_DISMISS_ERROR';
export const GROUP_UPDATE_QUICK_SEARCH = 'GROUP_UPDATE_QUICK_SEARCH';
export const GROUP_UPDATE_SORT = 'GROUP_UPDATE_SORT';
