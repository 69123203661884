import React from 'react';
import { normalizedObjectModeler } from 'jsonapi-front';
import {
  AddOne as AddOneIcon,
  GetOne as GetOneIcon,
  DelOne as DelOneIcon,
  Sponsorship as SponsorshipIcon,
  Donation as DonationIcon,
  Print as PrintIcon,
  Certificate as CertificateIcon,
  Receipt as ReceiptIcon,
  Siret as SiretIcon,
} from '../icons';
import { clientCategoryAsOptions } from '../client-category';
import { paymentTypeAsOptions } from '../payment-type';
import { langAsOptions } from '../lang';

export const getClientFullname = client => {
  if (!client) {
    return '';
  }
  return (
    (client.cli_lastname ? client.cli_lastname : '') +
    ' ' +
    (client.cli_firstname ? client.cli_firstname : '')
  );
};

export const getSelectActions = ({ props, onSelectMenu }) => {
  const emails = props.email ? props.email.items : null;
  let emailList = [];
  if (emails) {
    emailList = normalizedObjectModeler(emails, 'FreeFW_Email');
  }
  let arrOne = [
    {
      name: 'selectAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.selectAll',
        defaultMessage: 'Select all',
      }),
      onClick: () => {
        onSelectMenu('selectAll');
      },
    },
  ];
  const arrAppend = [
    {
      name: 'selectNone',
      label: props.intl.formatMessage({
        id: 'app.list.options.deselectAll',
        defaultMessage: 'Deselect all',
      }),
      onClick: () => {
        onSelectMenu('selectNone');
      },
    },
    { name: 'divider' },
    {
      name: 'exportSelect',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportSelect',
        defaultMessage: 'Export selection',
      }),
      onClick: () => {
        onSelectMenu('exportSelection');
      },
    },
  ];
  if (props.client.selected.length > 0) {
    arrOne = arrOne.concat(arrAppend);
    if (Array.isArray(emailList)) {
      emailList.forEach(elem => {
        if (elem.email_object_name === 'FreeAsso_Client') {
          arrOne.push({
            name: 'export' + elem.id,
            label: 'Email sélection (' + elem.email_subject + ')',
            onClick: () => {
              onSelectMenu('emailSelection', elem.id);
            },
          });
        }
      });
    }
  }
  const arrStandard = [
    { name: 'divider' },
    {
      name: 'exportAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportAll',
        defaultMessage: 'Export all',
      }),
      onClick: () => {
        onSelectMenu('exportAll');
      },
    },
  ];
  arrOne = arrOne.concat(arrStandard);
  if (Array.isArray(emailList)) {
    emailList.forEach(elem => {
      if (elem.email_object_name === 'FreeAsso_Client') {
        arrOne.push({
          name: 'export' + elem.id,
          label: 'Email tout (' + elem.email_subject + ')',
          onClick: () => {
            onSelectMenu('emailAll', elem.id);
          },
        });
      }
    });
  }
  return arrOne;
};

export const getGlobalActions = ({ props, onClearFilters, onCreate }) => {
  return [
    {
      name: 'create',
      label: props.intl.formatMessage({ id: 'app.list.button.add', defaultMessage: 'Add' }),
      onClick: onCreate,
      theme: 'primary',
      icon: <AddOneIcon color="white" />,
      role: 'CREATE',
    },
  ];
};

export const getActionsButtons = ({ onPrint, onOpenSiret, state }) => {
  let actions = [];
  actions.push({
    theme: 'secondary',
    hidden: false,
    function: onOpenSiret,
    icon: <SiretIcon title="Recherche SIRET" />,
  });
  if (state.id > 0) {
    const editions = state.editions || [];
    if (editions && editions.length > 0) {
      let myOptions = [];
      editions.forEach(elem => {
        myOptions.push({ value: elem.id, label: elem.edi_name });
      });
      actions.push({
        theme: 'secondary',
        hidden: false,
        function: onPrint,
        options: myOptions,
        icon: <PrintIcon title="Imprimer" />,
      });
    }
  }
  return actions;
};

export const getInlineActions = ({
  props,
  onSelectList,
  onGetOne,
  onDelOne,
  onPrintOne,
  state,
}) => {
  let myEditions = [];
  if (state && state.editions) {
    const { editions } = state;
    editions.forEach(edition => {
      edition.langs.forEach(lang => {
        myEditions.push({
          label: edition.edi_name + ' (' + lang + ')',
          onClick: item => onPrintOne(edition.id, item, lang),
        });
      });
    });
  }
  return [
    {
      name: 'sponsorship',
      label: props.intl.formatMessage({
        id: 'app.list.button.sponsorships',
        defaultMessage: 'Sponsorships',
      }),
      param: 'object',
      onClick: obj => {
        onSelectList(obj, 'sponsorship');
      },
      theme: 'secondary',
      icon: <SponsorshipIcon />,
      role: 'OTHER',
      active: state.sponsorships > 0,
    },
    {
      name: 'donation',
      label: props.intl.formatMessage({
        id: 'app.list.button.donations',
        defaultMessage: 'Donations',
      }),
      param: 'object',
      onClick: obj => {
        onSelectList(obj, 'donation');
      },
      theme: 'secondary',
      icon: <DonationIcon />,
      role: 'OTHER',
      active: state.donations > 0,
    },
    {
      name: 'certificate',
      label: props.intl.formatMessage({
        id: 'app.list.button.certificates',
        defaultMessage: 'Certificates',
      }),
      param: 'object',
      onClick: obj => {
        onSelectList(obj, 'certificate');
      },
      theme: 'secondary',
      icon: <CertificateIcon />,
      role: 'OTHER',
      active: state.certificates > 0,
    },
    {
      name: 'receipt',
      label: props.intl.formatMessage({
        id: 'app.list.button.receipts',
        defaultMessage: 'Receipts',
      }),
      param: 'object',
      onClick: obj => {
        onSelectList(obj, 'receipt');
      },
      theme: 'secondary',
      icon: <ReceiptIcon />,
      role: 'OTHER',
      active: state.receipts > 0,
    },
    {
      name: 'print',
      label: props.intl.formatMessage({
        id: 'app.list.button.print',
        defaultMessage: 'Print',
      }),
      onClick: onPrintOne,
      theme: 'secondary',
      icon: <PrintIcon />,
      role: 'PRINT',
      param: 'object',
      active: myEditions.length > 0,
      options: myEditions,
    },
    {
      name: 'modify',
      label: props.intl.formatMessage({ id: 'app.list.button.modify', defaultMessage: 'Modify' }),
      onClick: onGetOne,
      theme: 'secondary',
      icon: <GetOneIcon />,
      role: 'MODIFY',
    },
    {
      name: 'delete',
      label: props.intl.formatMessage({ id: 'app.list.button.delete', defaultMessage: 'Delete' }),
      onClick: onDelOne,
      theme: 'warning',
      icon: <DelOneIcon />,
      role: 'DELETE',
    },
  ];
};

/**
 * Affichage dans la liste des propositions de la recherhe
 * ou dans la liste proposées sous le champ en autocomplète
 */
export const displayItemPicker = item => {
  let fullname = item.cli_firstname || '';
  return (
    <p className="input-picker-line">
      <span className="input-picker-left">{fullname}</span>
    </p>
  );
};

export const getCols = ({ props }) => {
  return [
    {
      name: 'id',
      label: props.intl.formatMessage({
        id: 'app.features.client.list.col.id',
        defaultMessage: 'Number',
      }),
      col: 'id',
      size: { xl: 3, lg: 4 },
      sortable: true,
      filterable: false,
      title: true,
      hidden: true,
      card: { role: 'ID' },
    },
    {
      name: 'lastname',
      label: props.intl.formatMessage({
        id: 'app.features.client.list.col.lastname',
        defaultMessage: 'Lastname',
      }),
      col: 'cli_lastname',
      size: { xl: 4, lg: 5 },
      sortable: true,
      filterable: { type: 'text' },
      selectable: true,
      title: true,
      card: { role: 'FIELD', position: 6 },
    },
    {
      name: 'firstname',
      label: props.intl.formatMessage({
        id: 'app.features.client.list.col.firstname',
        defaultMessage: 'Firstname',
      }),
      col: 'cli_firstname',
      size: { xl: 4, lg: 4 },
      sortable: true,
      filterable: { type: 'text' },
      title: true,
      card: { role: 'FIELD', position: 7 },
    },
    {
      name: 'fullname',
      label: props.intl.formatMessage({
        id: 'app.features.client.list.col.fullname',
        defaultMessage: 'Fullname',
      }),
      col: 'cli_fullname',
      fDisplay: item => {
        return (
          (item.cli_lastname ? item.cli_lastname : '') +
          ' ' +
          (item.cli_firstname ? item.cli_firstname : '')
        );
      },
      hidden: true,
      sortable: false,
      filterable: false,
      title: false,
      card: { role: 'TITLE' },
    },
    {
      name: 'category',
      label: props.intl.formatMessage({
        id: 'app.features.client.list.col.category',
        defaultMessage: 'Category',
      }),
      col: 'client_category.clic_name',
      size: { sm: 7, md: 6, lg: 5, xl: 4 },
      title: true,
      sortable: true,
      card: { role: 'FIELD', position: 10 },
    },
    {
      name: 'cp',
      label: props.intl.formatMessage({
        id: 'app.features.client.list.col.cp',
        defaultMessage: 'Postcode',
      }),
      col: 'cli_cp',
      size: { sm: 4, md: 3, lg: 2, xl: 2 },
      mob_size: '36',
      sortable: true,
      filterable: { type: 'text' },
      title: true,
      card: { role: 'FIELD', position: 20 },
    },
    {
      name: 'town',
      label: props.intl.formatMessage({
        id: 'app.features.client.list.col.town',
        defaultMessage: 'Town',
      }),
      col: 'cli_town',
      size: { sm: 7, md: 6, lg: 5, xl: 5 },
      mob_size: '36',
      sortable: true,
      filterable: { type: 'text' },
      title: true,
      card: { role: 'FIELD', position: 20 },
    },
    {
      name: 'country',
      label: props.intl.formatMessage({
        id: 'app.features.client.list.col.country',
        defaultMessage: 'Country',
      }),
      col: 'country.cnty_name',
      size: { md: 4, lg: 3, xl: 3 },
      mob_size: '36',
      sortable: true,
      filterable: { type: 'text' },
      title: true,
      card: { role: 'FIELD', position: 30 },
    },
    {
      name: 'email',
      label: props.intl.formatMessage({
        id: 'app.features.client.list.col.email',
        defaultMessage: 'Email',
      }),
      col: 'cli_email',
      size: { sm: 0, md: 8, lg: 7, xl: 7 },
      mob_size: '36',
      sortable: true,
      filterable: { type: 'test' },
      title: true,
      type: 'mail',
      card: { role: 'FIELD', position: 40 },
    },
    {
      name: 'lang',
      label: props.intl.formatMessage({
        id: 'app.features.client.list.col.lang',
        defaultMessage: 'lang',
      }),
      col: 'donations.lang.lang_id',
      size: '0',
      mob_size: '0',
      sortable: false,
      filterable: {
        type: 'select',
        options: langAsOptions(props.lang.items),
      },
      title: false,
      hidden: true,
    },
    {
      name: 'last_donation',
      label: props.intl.formatMessage({
        id: 'app.features.client.list.col.lastDonation',
        defaultMessage: 'Last donation',
      }),
      col: 'last_donation.don_real_ts',
      size: { sm: 0, lg: 5, xl: 4 },
      mob_size: '36',
      sortable: true,
      filterable: { type: 'date' },
      title: true,
      type: 'date',
      last: true,
    },
    {
      name: 'donation',
      label: props.intl.formatMessage({
        id: 'app.features.client.list.col.donation',
        defaultMessage: 'Paiement',
      }),
      col: 'donations.don_real_ts',
      size: { sm: 0, lg: 5, xl: 4 },
      mob_size: '36',
      sortable: true,
      filterable: { type: 'date' },
      hidden: true,
      type: 'date',
      last: true,
    },
    {
      name: 'donation_mnt',
      label: props.intl.formatMessage({
        id: 'app.features.client.list.col.donationMnt',
        defaultMessage: 'Montant',
      }),
      col: 'donations.don_mnt_input',
      size: { sm: 0, lg: 5, xl: 4 },
      mob_size: '36',
      sortable: true,
      filterable: { type: 'monetary' },
      hidden: true,
      type: 'date',
      last: true,
    },
    {
      name: 'receipt',
      label: props.intl.formatMessage({
        id: 'app.features.client.list.col.cliReceipt',
        defaultMessage: 'Send receipt',
      }),
      col: 'cli_receipt',
      hidden: true,
      sortable: false,
      filterable: { type: 'bool' },
      type: 'bool',
    },
    {
      name: 'cli_email_refused',
      label: props.intl.formatMessage({
        id: 'app.features.client.list.col.cliEmailRefused',
        defaultMessage: 'Email refused',
      }),
      col: 'cli_email_refused',
      hidden: true,
      sortable: false,
      filterable: { type: 'text' },
      type: 'mail',
    },
    {
      name: 'category',
      label: props.intl.formatMessage({
        id: 'app.features.client.list.col.category',
        defaultMessage: 'Category',
      }),
      col: 'client_category.clic_id',
      size: '0',
      mob_size: '0',
      sortable: false,
      filterable: {
        type: 'select',
        options: clientCategoryAsOptions(props.clientCategory.items),
      },
      title: true,
      hidden: true,
      last: true,
    },
    {
      name: 'payment_types',
      label: props.intl.formatMessage({
        id: 'app.features.client.list.col.paymentType',
        defaultMessage: 'Category',
      }),
      col: 'donations.payment_type.ptyp_id',
      size: '0',
      mob_size: '0',
      sortable: false,
      filterable: {
        type: 'select',
        options: paymentTypeAsOptions(props.paymentType.items),
      },
      title: true,
      hidden: true,
      last: true,
    },
    {
      name: 'sponsorship',
      label: props.intl.formatMessage({
        id: 'app.features.client.list.col.sponsorship',
        defaultMessage: 'Category',
      }),
      col: 'donations.sponsorship.spo_id',
      size: '0',
      mob_size: '0',
      sortable: false,
      filterable: {
        type: 'select',
        options: paymentTypeAsOptions(props.paymentType.items),
      },
      title: true,
      hidden: true,
      last: true,
    },
    {
      name: 'spo_ts',
      label: props.intl.formatMessage({
        id: 'app.features.client.field.spo_ts.label',
        defaultMessage: 'spo_ts',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.client.field.spo_ts.short',
        defaultMessage: 'spo_ts',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.client.field.spo_ts.comment',
        defaultMessage: 'spo_ts',
      }),
      col: 'sponsorships.spo_ts',
      size: { sm: 4, md: 3, xl: 2 },
      title: true,
      sortable: true,
      hidden: true,
      type: 'date',
      filterable: { type: 'date' },
    },
  ];
};
