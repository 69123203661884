import React from 'react';
import {
  AddOne as AddOneIcon,
  GetOne as GetOneIcon,
  DelOne as DelOneIcon,
  Print as PrintIcon,
  ColCheck as ColCheckIcon,
  Minus as MinusIcon,
} from '../icons';

/**
 * Shortcuts
 *
 * @param {Object} intl
 * @param {String} mode
 */
export const getShortcuts = (intl, mode = 'all') => {
  let shortcuts = [];
  shortcuts.push({
    name: 'ident',
    icon: '',
    size: 'maximized',
    display: 'block',
    position: 1,
    label: intl.formatMessage({
      id: 'app.features.receipt-type.form.tabs.ident',
      defaultMessage: 'Identification',
    }),
  });
  if (mode === 'all') {
  }
  return shortcuts;
};

/**
 * Gestion de l'affichage du champ en fonction du modèle
 */
export function getPickerDisplay(item) {
  let display = '';
  if (item && item.id > 0) {
    display = item.rett_name;
  }
  return display;
}

/**
 * Affichage des agents dans la liste des propositions de la recherhe
 * ou dans la liste proposées sous le champ en autocomplète
 */
export const displayItemPicker = item => {
  return (
    <p className="input-picker-line">
      <span className="input-picker-left">{item.rett_name}</span>
    </p>
  );
};

/**
 * Actions disponibles depuis l'en-tête de la liste des agents
 */
export const getGlobalActions = ({ props, onClearFilters, onCreate }) => {
  return [
    {
      name: 'create',
      label: props.intl.formatMessage({
        id: 'app.list.button.add',
        defaultMessage: 'Add',
      }),
      onClick: onCreate,
      theme: 'primary',
      icon: <AddOneIcon />,
      role: 'CREATE',
    },
  ];
};

export const validSelect = [
  { label: 'Actif', value: true, icon: <ColCheckIcon className="col-icon text-secondary" /> },
  { label: 'Inactif', value: false, icon: <MinusIcon className="col-icon text-secondary" /> },
];

/**
 * Actions disponibles sur la fiche (en plus de l'enregistrement et de l'annulation)
 */
export const getActionsButtons = ({ onPrint, state }) => {
  const { editions } = state;
  let myOptions = [];
  let printabled = false;
  if (editions && Array.isArray(editions) && editions.length > 0) {
    printabled = true;
    editions.forEach(edition => {
      myOptions.push({
        id: edition.id,
        label: edition.edi_name,
        onClick: () => onPrint(edition.id),
      });
    });
  }
  return [
    {
      theme: 'secondary',
      hidden: !printabled,
      options: myOptions,
      optionsAlign: 'top-left',
      function: () => onPrint(),
      icon: <PrintIcon title="Imprimer" />,
    },
  ];
};

/**
 * Actions disponibles depuis l'en-tête de la liste des agents pour agir sur les lignes de la liste
 *    Sélection lignes
 *    Dé-sélection
 *    ...
 *    Actions sur lignes sélectionnées
 *    ou sur toutes les lignes
 */
export const getSelectActions = ({ props, onSelectMenu }) => {
  let arrOne = [
    {
      name: 'selectAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.selectAll',
        defaultMessage: 'Select all',
      }),
      onClick: () => {
        onSelectMenu('selectAll');
      },
    },
  ];
  const arrAppend = [
    {
      name: 'selectNone',
      label: props.intl.formatMessage({
        id: 'app.list.options.deselectAll',
        defaultMessage: 'Deselect all',
      }),
      onClick: () => {
        onSelectMenu('selectNone');
      },
    },
    { name: 'divider' },
    {
      name: 'exportSelect',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportSelect',
        defaultMessage: 'Export selection',
      }),
      onClick: () => {
        onSelectMenu('exportSelection');
      },
    },
  ];
  if (props.receiptType.selected.length > 0) {
    arrOne = arrOne.concat(arrAppend);
  }
  const arrStandard = [
    { name: 'divider' },
    {
      name: 'exportAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportAll',
        defaultMessage: 'Export all',
      }),
      onClick: () => {
        onSelectMenu('exportAll');
      },
    },
  ];
  arrOne = arrOne.concat(arrStandard);
  return arrOne;
};

/**
 * Actions disponibles sur une ligne de la liste des agents au survol de cette ligne
 */
export const getInlineActions = ({ props, onSelectList, onGetOne, onDelOne, onPrint, state }) => {
  const { editions } = state;
  let myEditions = [];
  editions.forEach(edition => {
    myEditions.push({ label: edition.edi_name, onClick: item => onPrint(edition.id, item) });
  });
  return [
    {
      name: 'print',
      label: props.intl.formatMessage({
        id: 'app.list.button.print',
        defaultMessage: 'Print',
      }),
      onClick: onPrint,
      theme: 'secondary',
      icon: <PrintIcon />,
      role: 'PRINT',
      param: 'object',
      active: myEditions.length > 0,
      options: myEditions,
    },
    {
      name: 'modify',
      label: props.intl.formatMessage({
        id: 'app.list.button.modify',
        defaultMessage: 'Modify',
      }),
      onClick: onGetOne,
      theme: 'secondary',
      icon: <GetOneIcon />,
      role: 'MODIFY',
    },
    {
      name: 'delete',
      label: props.intl.formatMessage({
        id: 'app.list.button.delete',
        defaultMessage: 'Delete',
      }),
      onClick: onDelOne,
      theme: 'warning',
      icon: <DelOneIcon />,
      role: 'DELETE',
    },
  ];
};

/**
 * Descriptions de toutes les colonnes de la liste
 * Même les colonnes cachées
 * Ces colonnes peuvent ou non données des champs dans les filtres
 * (une info peut de pas être affichée dans la liste mais on peut peut-être mettre un filtre dessus)
 */
export const getCols = ({ props }) => {
  return [
    {
      name: 'rett_id',
      label: props.intl.formatMessage({
        id: 'app.features.receipt-type.field.rett_id.label',
        defaultMessage: 'Id.',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt-type.field.rett_id.short',
        defaultMessage: 'Id.',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt-type.field.rett_id.comment',
        defaultMessage: 'Identifiant',
      }),
      col: 'rett_id',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'ID' },
    },
    {
      name: 'brk_id',
      label: props.intl.formatMessage({
        id: 'app.features.receipt-type.field.brk_id.label',
        defaultMessage: 'Broker',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt-type.field.brk_id.short',
        defaultMessage: 'Broker',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt-type.field.brk_id.comment',
        defaultMessage: 'Broker',
      }),
      col: 'brk_id',
      size: { xxs: 36, sm: 12, md: 4 },
      hidden: true,
    },
    {
      name: 'rett_name',
      label: props.intl.formatMessage({
        id: 'app.features.receipt-type.field.rett_name.label',
        defaultMessage: 'Nom',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt-type.field.rett_name.short',
        defaultMessage: 'Nom',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt-type.field.rett_name.comment',
        defaultMessage: 'Nom',
      }),
      col: 'rett_name',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      card: { role: 'TITLE' },
      filterable: true,
    },
    {
      name: 'rett_start_one',
      label: props.intl.formatMessage({
        id: 'app.features.receipt-type.field.rett_start_one.label',
        defaultMessage: 'Start at 1',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt-type.field.rett_start_one.short',
        defaultMessage: 'Start at 1',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt-type.field.rett_start_one.comment',
        defaultMessage: 'Start at 1',
      }),
      col: 'rett_start_one',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      type: 'bool',
      values: validSelect,
      card: { role: 'FIELD' },
      filterable: { type: 'bool' },
    },
    {
      name: 'rett_last_number',
      label: props.intl.formatMessage({
        id: 'app.features.receipt-type.field.rett_last_number.label',
        defaultMessage: 'Dernier',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt-type.field.rett_last_number.short',
        defaultMessage: 'Dernier',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt-type.field.rett_last_number.comment',
        defaultMessage: 'Dernier numéro',
      }),
      col: 'rett_last_number',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'rett_regex',
      label: props.intl.formatMessage({
        id: 'app.features.receipt-type.field.rett_regex.label',
        defaultMessage: 'Expression',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt-type.field.rett_regex.short',
        defaultMessage: 'Expression',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt-type.field.rett_regex.comment',
        defaultMessage: 'Expression régulière',
      }),
      col: 'rett_regex',
      size: { xxs: 36, sm: 12, md: 6 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'grp_id',
      label: props.intl.formatMessage({
        id: 'app.features.receipt-type.field.grp_id.label',
        defaultMessage: 'Groupe',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.receipt-type.field.grp_id.short',
        defaultMessage: 'Groupe',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.receipt-type.field.grp_id.comment',
        defaultMessage: 'Groupe',
      }),
      col: 'grp_id',
      size: { xxs: 36, sm: 12, md: 4 },
      hidden: true,
    },
  ];
};
