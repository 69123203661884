export const DATA_LOAD_MORE_INIT = 'DATA_LOAD_MORE_INIT';
export const DATA_LOAD_MORE_BEGIN = 'DATA_LOAD_MORE_BEGIN';
export const DATA_LOAD_MORE_SUCCESS = 'DATA_LOAD_MORE_SUCCESS';
export const DATA_LOAD_MORE_FAILURE = 'DATA_LOAD_MORE_FAILURE';
export const DATA_LOAD_MORE_DISMISS_ERROR = 'DATA_LOAD_MORE_DISMISS_ERROR';
export const DATA_LOAD_ONE_BEGIN = 'DATA_LOAD_ONE_BEGIN';
export const DATA_LOAD_ONE_SUCCESS = 'DATA_LOAD_ONE_SUCCESS';
export const DATA_LOAD_ONE_FAILURE = 'DATA_LOAD_ONE_FAILURE';
export const DATA_LOAD_ONE_DISMISS_ERROR = 'DATA_LOAD_ONE_DISMISS_ERROR';
export const DATA_UPDATE_ONE_BEGIN = 'DATA_UPDATE_ONE_BEGIN';
export const DATA_UPDATE_ONE_SUCCESS = 'DATA_UPDATE_ONE_SUCCESS';
export const DATA_UPDATE_ONE_FAILURE = 'DATA_UPDATE_ONE_FAILURE';
export const DATA_UPDATE_ONE_DISMISS_ERROR = 'DATA_UPDATE_ONE_DISMISS_ERROR';
export const DATA_UPDATE_ONE_UPDATE = 'DATA_UPDATE_ONE_UPDATE';
export const DATA_CREATE_ONE_BEGIN = 'DATA_CREATE_ONE_BEGIN';
export const DATA_CREATE_ONE_SUCCESS = 'DATA_CREATE_ONE_SUCCESS';
export const DATA_CREATE_ONE_FAILURE = 'DATA_CREATE_ONE_FAILURE';
export const DATA_CREATE_ONE_DISMISS_ERROR = 'DATA_CREATE_ONE_DISMISS_ERROR';
export const DATA_CLEAR_ITEMS = 'DATA_CLEAR_ITEMS';
export const DATA_UPDATE_SORT = 'DATA_UPDATE_SORT';
export const DATA_SET_SORT = 'DATA_SET_SORT';
export const DATA_SET_FILTERS = 'DATA_SET_FILTERS';
export const DATA_UPDATE_QUICK_SEARCH = 'DATA_UPDATE_QUICK_SEARCH';
export const DATA_INIT_SORT = 'DATA_INIT_SORT';
export const DATA_INIT_FILTERS = 'DATA_INIT_FILTERS';