import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { ResponsiveInlineHeader } from 'react-bootstrap-front';
import { DashboardCard } from '../dashboard';
import { History as HistoryIcon } from '../icons';
import { getCols, InlinePendings } from './';

export class Dashboard extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    overlay: PropTypes.bool,
  };

  render() {
    return (
      <DashboardCard
        title="Historique"
        icon={<HistoryIcon />}
        overlay={this.props.overlay}
        size="lg"
        header={<ResponsiveInlineHeader cols={getCols(this)} />}
      >
        <InlinePendings mode="pending" />
      </DashboardCard>
    );
  }
}

function mapStateToProps(state) {
  return {
    history: state.history,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Dashboard),
);
