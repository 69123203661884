import React from 'react';
import {
  AddOne as AddOneIcon,
  GetOne as GetOneIcon,
  DelOne as DelOneIcon,
  Print as PrintIcon,
} from '../icons';
import { displayColBool } from '../ui';

/**
 * Shortcuts
 *
 * @param {Object} intl
 * @param {String} mode
 */
export const getShortcuts = (intl, mode = 'all') => {
  let shortcuts = [];
  shortcuts.push({
    name: 'ident',
    icon: '',
    size: 'maximized',
    display: 'block',
    position: 1,
    label: intl.formatMessage({
      id: 'app.features.translation.form.tabs.ident',
      defaultMessage: 'Identification',
    }),
  });
  if (mode === 'all') {
  }
  return shortcuts;
};

/**
 * Gestion de l'affichage du champ en fonction du modèle
 */
export function getPickerDisplay(item) {
  let display = '';
  if (item && item.id > 0) {
    display = item.tr_key;
  }
  return display;
}

/**
 * Affichage des agents dans la liste des propositions de la recherhe
 * ou dans la liste proposées sous le champ en autocomplète
 */
export const displayItemPicker = item => {
  return (
    <p className="input-picker-line">
      <span className="input-picker-left">{item.tr_key}</span>
    </p>
  );
};

/**
 * Actions disponibles depuis l'en-tête de la liste des agents
 */
export const getGlobalActions = ({ props, onClearFilters, onCreate }) => {
  return [
    {
      name: 'create',
      label: props.intl.formatMessage({
        id: 'app.list.button.add',
        defaultMessage: 'Add',
      }),
      onClick: onCreate,
      theme: 'primary',
      icon: <AddOneIcon />,
      role: 'CREATE',
    },
  ];
};

/**
 * Actions disponibles sur la fiche (en plus de l'enregistrement et de l'annulation)
 */
export const getActionsButtons = ({ onPrint, state }) => {
  const { editions } = state;
  let myOptions = [];
  let printabled = false;
  if (editions && Array.isArray(editions) && editions.length > 0) {
    printabled = true;
    editions.forEach(edition => {
      myOptions.push({
        id: edition.id,
        label: edition.edi_name,
        onClick: () => onPrint(edition.id),
      });
    });
  }
  return [
    {
      theme: 'secondary',
      hidden: !printabled,
      options: myOptions,
      optionsAlign: 'top-left',
      function: () => onPrint(),
      icon: <PrintIcon title="Imprimer" />,
    },
  ];
};

/**
 * Actions disponibles depuis l'en-tête de la liste des agents pour agir sur les lignes de la liste
 *    Sélection lignes
 *    Dé-sélection
 *    ...
 *    Actions sur lignes sélectionnées
 *    ou sur toutes les lignes
 */
export const getSelectActions = ({ props, onSelectMenu }) => {
  let arrOne = [
    {
      name: 'selectAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.selectAll',
        defaultMessage: 'Select all',
      }),
      onClick: () => {
        onSelectMenu('selectAll');
      },
    },
  ];
  const arrAppend = [
    {
      name: 'selectNone',
      label: props.intl.formatMessage({
        id: 'app.list.options.deselectAll',
        defaultMessage: 'Deselect all',
      }),
      onClick: () => {
        onSelectMenu('selectNone');
      },
    },
    { name: 'divider' },
    {
      name: 'exportSelect',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportSelect',
        defaultMessage: 'Export selection',
      }),
      onClick: () => {
        onSelectMenu('exportSelection');
      },
    },
  ];
  if (props.translation.selected.length > 0) {
    arrOne = arrOne.concat(arrAppend);
  }
  const arrStandard = [
    { name: 'divider' },
    {
      name: 'exportAll',
      label: props.intl.formatMessage({
        id: 'app.list.options.exportAll',
        defaultMessage: 'Export all',
      }),
      onClick: () => {
        onSelectMenu('exportAll');
      },
    },
  ];
  arrOne = arrOne.concat(arrStandard);
  return arrOne;
};

/**
 * Actions disponibles sur une ligne de la liste des agents au survol de cette ligne
 */
export const getInlineActions = ({ props, onSelectList, onGetOne, onDelOne, onPrint, state }) => {
  let myEditions = [];
  if (state && state.editions) {
    const { editions } = state;
    editions.forEach(edition => {
      myEditions.push({ label: edition.edi_name, onClick: item => onPrint(edition.id, item) });
    });
  }
  return [
    {
      name: 'print',
      label: props.intl.formatMessage({
        id: 'app.list.button.print',
        defaultMessage: 'Print',
      }),
      onClick: onPrint,
      theme: 'secondary',
      icon: <PrintIcon />,
      role: 'PRINT',
      param: 'object',
      active: myEditions.length > 0,
      options: myEditions,
    },
    {
      name: 'modify',
      label: props.intl.formatMessage({
        id: 'app.list.button.modify',
        defaultMessage: 'Modify',
      }),
      onClick: onGetOne,
      theme: 'secondary',
      icon: <GetOneIcon />,
      role: 'MODIFY',
    },
    {
      name: 'delete',
      label: props.intl.formatMessage({
        id: 'app.list.button.delete',
        defaultMessage: 'Delete',
      }),
      onClick: onDelOne,
      theme: 'warning',
      icon: <DelOneIcon />,
      role: 'DELETE',
    },
  ];
};

/**
 * Descriptions de toutes les colonnes de la liste
 * Même les colonnes cachées
 * Ces colonnes peuvent ou non données des champs dans les filtres
 * (une info peut de pas être affichée dans la liste mais on peut peut-être mettre un filtre dessus)
 */
export const getCols = ({ props }) => {
  return [
    {
      name: 'tr_id',
      label: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_id.label',
        defaultMessage: 'Id',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_id.short',
        defaultMessage: 'Id',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_id.comment',
        defaultMessage: 'Identifiant',
      }),
      col: 'tr_id',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      card: { role: 'ID' },
      filterable: true,
    },
    {
      name: 'tr_key',
      label: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_key.label',
        defaultMessage: 'Clef',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_key.short',
        defaultMessage: 'Clef',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_key.comment',
        defaultMessage: 'Clef de la traduction',
      }),
      col: 'tr_key',
      size: { xxs: 36, sm: 12, md: 10, lg: 8 },
      title: true,
      sortable: true,
      card: { role: 'TITLE' },
      first: true,
      filterable: true,
    },
    {
      name: 'tr_desc',
      label: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_desc.label',
        defaultMessage: 'Description',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_desc.short',
        defaultMessage: 'Description',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_desc.comment',
        defaultMessage: 'Description',
      }),
      col: 'tr_desc',
      hidden: true,
      title: true,
      sortable: true,
      filterable: true,
    },
    {
      name: 'tr_html',
      label: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_html.label',
        defaultMessage: 'Html',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_html.short',
        defaultMessage: 'Html',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_html.comment',
        defaultMessage: 'Html',
      }),
      col: 'tr_html',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      hidden: true,
      filterable: { type: 'bool' },
      type: 'bool',
      values: displayColBool,
    },
    {
      name: 'tr_type',
      label: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_type.label',
        defaultMessage: 'Type',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_type.short',
        defaultMessage: 'Type',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_type.comment',
        defaultMessage: 'Type',
      }),
      col: 'tr_type',
      size: { xxs: 36, sm: 12, md: 4 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'tr_lang_fr',
      label: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_lang_fr.label',
        defaultMessage: 'FR',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_lang_fr.short',
        defaultMessage: 'FR',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_lang_fr.comment',
        defaultMessage: 'Français',
      }),
      col: 'tr_lang_fr',
      size: { xxs: 36, sm: 12, md: 10, lg: 8 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'tr_lang_en',
      label: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_lang_en.label',
        defaultMessage: 'EN',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_lang_en.short',
        defaultMessage: 'EN',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_lang_en.comment',
        defaultMessage: 'Anglais',
      }),
      col: 'tr_lang_en',
      size: { xxs: 36, sm: 12, md: 10, lg: 8 },
      title: true,
      sortable: true,
      card: { role: 'FIELD' },
      filterable: true,
    },
    {
      name: 'tr_lang_ch',
      label: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_lang_ch.label',
        defaultMessage: 'CH',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_lang_ch.short',
        defaultMessage: 'CH',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_lang_ch.comment',
        defaultMessage: 'Suisse',
      }),
      col: 'tr_lang_ch',
      size: { xxs: 36, sm: 12, md: 10, lg: 8 },
      title: true,
      sortable: true,
      hidden: true,
      filterable: true,
    },
    {
      name: 'tr_lang_de',
      label: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_lang_de.label',
        defaultMessage: 'DE',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_lang_de.short',
        defaultMessage: 'DE',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_lang_de.comment',
        defaultMessage: 'Allemand',
      }),
      col: 'tr_lang_de',
      size: { xxs: 36, sm: 12, md: 10, lg: 8 },
      title: true,
      sortable: true,
      hidden: true,
      filterable: true,
    },
    {
      name: 'tr_lang_es',
      label: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_lang_es.label',
        defaultMessage: 'ES',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_lang_es.short',
        defaultMessage: 'ES',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_lang_es.comment',
        defaultMessage: 'Espagnol',
      }),
      col: 'tr_lang_es',
      size: { xxs: 36, sm: 12, md: 10, lg: 8 },
      title: true,
      sortable: true,
      hidden: true,
      filterable: true,
    },
    {
      name: 'tr_lang_id',
      label: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_lang_id.label',
        defaultMessage: 'ID',
      }),
      shortLabel: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_lang_id.short',
        defaultMessage: 'ID',
      }),
      comment: props.intl.formatMessage({
        id: 'app.features.translation.field.tr_lang_id.comment',
        defaultMessage: 'Indonésien',
      }),
      col: 'tr_lang_id',
      size: { xxs: 36, sm: 12, md: 10, lg: 8 },
      title: true,
      sortable: true,
      hidden: true,
      filterable: true,
    },
  ];
};
