import React from 'react';
import { injectIntl } from 'react-intl';
import { getShortcuts } from './';
import useForm from '../ui/useForm';
import {
  ResponsiveModalOrForm,
  InputHidden,
  InputText,
  InputHtml,
  InputDate,
  InputEmail,
  InputJson,
  InputPhone,
  InputSelect,
  InputImage,
  Row,
  Col,
} from '../ui';
import { countryAsOptions } from '../country';
import { langAsOptions } from '../lang';
import { groupTypeAsOptions } from '../group-type';
import { InputPicker as InputGroupPicker } from '../group';
import { InlineList as InlineTaxonomy } from '../taxonomy';

/**
 * Initialisation
 *
 * @param {Object}   item
 * @param {Function} setItem
 */
const initItem = (item, setItem) => {};

/**
 * Un champ a été modifié
 *
 * @param {String}   field
 * @param {Object}   item
 * @param {Function} setitem
 */
const afterChange = (field, item, setItem) => {
  switch (field) {
    default:
      break;
  }
};

/**
 * Saisie des informations d'un modèle
 */
function Form(props) {
  let item = props.item;
  // On récupère tout ce qu'il faut pour travailler
  const {
    values,
    handleChange,
    handleSave,
    handleSubmit,
    handleCancel,
    handleNavTab,
    getErrorMessage,
    getCurrentTab,
  } = useForm(
    item,
    props.tab,
    props.onSubmit,
    props.onCancel,
    props.onNavTab,
    props.errors,
    props.intl,
    afterChange,
    initItem,
  );
  // Le formulaire
  return (
    <ResponsiveModalOrForm
      title={
        !props.modify && !values.grp_name
          ? props.intl.formatMessage({
              id: 'app.features.group.form.title',
              defaultMessage: 'New model',
            })
          : values.grp_name
      }
      className=""
      tab={getCurrentTab()}
      tabs={
        !props.modify
          ? getShortcuts(props.intl, 'new')
          : getShortcuts(props.intl, 'all')
      }
      size="md"
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onNavTab={handleNavTab}
      onSave={handleSave}
      onClose={handleCancel}
      onPrevious={props.onPrevious}
      onNext={props.onNext}
      modal={true}
      actionsButtons={props.actionsButtons}
      loader={props.loader ? props.loader : false}
    >
      <InputHidden name="id" id="id" value={values.id} />
      {getCurrentTab() === 'ident' && (
        <>
          <Row>
            <Col size={{ xxs: 36, sm: 9 }}>
              <InputText
                label={props.intl.formatMessage({
                  id: 'app.features.group.field.grp_name.label',
                  defaultMessage: 'grp_name',
                })}
                id="grp_name"
                name="grp_name"
                value={values.grp_name}
                required={true}
                labelTop={true}
                onChange={handleChange}
                error={getErrorMessage('grp_name')}
              />
            </Col>
            <Col size={{ xxs: 36, sm: 9 }}>
              <InputText
                label={props.intl.formatMessage({
                  id: 'app.features.group.field.grp_code.label',
                  defaultMessage: 'grp_code',
                })}
                id="grp_code"
                name="grp_code"
                value={values.grp_code}
                required={true}
                labelTop={true}
                onChange={handleChange}
                error={getErrorMessage('grp_code')}
              />
            </Col>
            <Col size={{ xxs: 36, sm: 9 }}>
              <InputSelect
                label={props.intl.formatMessage({
                  id: 'app.features.group.field.grpt_id.label',
                  defaultMessage: 'grpt_id',
                })}
                name="group_type.id"
                labelTop={true}
                value={values.group_type ? values.group_type.id : null}
                onChange={handleChange}
                options={groupTypeAsOptions(props.groupTypes.items)}
              />
            </Col>
            <Col size={{ xxs: 36, sm: 9 }}>
              <InputGroupPicker
                label={props.intl.formatMessage({
                  id: 'app.features.group.field.realm.label',
                  defaultMessage: 'Realm',
                })}
                name="realm"
                labelTop={true}
                required={true}
                item={values.realm || null}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row>
            <Col size={{ xxs: 36, sm: 36 }}>
              <InputText
                label={props.intl.formatMessage({
                  id: 'app.features.group.field.grp_address1.label',
                  defaultMessage: 'grp_address1',
                })}
                id="grp_address1"
                name="grp_address1"
                value={values.grp_address1}
                labelTop={true}
                onChange={handleChange}
                error={getErrorMessage('grp_address1')}
              />
            </Col>
            <Col size={{ xxs: 36, sm: 36 }}>
              <InputText
                id="grp_address2"
                name="grp_address2"
                value={values.grp_address2}
                labelTop={true}
                onChange={handleChange}
                error={getErrorMessage('grp_address2')}
              />
            </Col>
            <Col size={{ xxs: 36, sm: 36 }}>
              <InputText
                id="grp_address3"
                name="grp_address3"
                value={values.grp_address3}
                labelTop={true}
                onChange={handleChange}
                error={getErrorMessage('grp_address3')}
              />
            </Col>
            <Col size={{ xxs: 36, sm: 12 }}>
              <InputText
                label={props.intl.formatMessage({
                  id: 'app.features.group.field.grp_cp.label',
                  defaultMessage: 'grp_cp',
                })}
                id="grp_cp"
                name="grp_cp"
                value={values.grp_cp}
                labelTop={true}
                onChange={handleChange}
                error={getErrorMessage('grp_cp')}
              />
            </Col>
            <Col size={{ xxs: 36, sm: 12 }}>
              <InputText
                label={props.intl.formatMessage({
                  id: 'app.features.group.field.grp_town.label',
                  defaultMessage: 'grp_town',
                })}
                id="grp_town"
                name="grp_town"
                value={values.grp_town}
                labelTop={true}
                onChange={handleChange}
                error={getErrorMessage('grp_town')}
              />
            </Col>
            <Col size={{ xxs: 36, sm: 12 }}>
              <InputSelect
                label={props.intl.formatMessage({
                  id: 'app.features.group.field.cnty_id.label',
                  defaultMessage: 'cnty_id',
                })}
                name="country.id"
                labelTop={true}
                value={values.country ? values.country.id : null}
                onChange={handleChange}
                options={countryAsOptions(props.country.items)}
              />
            </Col>
            <Col size={{ xxs: 36, sm: 12 }}>
              <InputSelect
                label={props.intl.formatMessage({
                  id: 'app.features.group.field.lang_id.label',
                  defaultMessage: 'lang_id',
                })}
                name="lang.id"
                labelTop={true}
                value={values.lang ? values.lang.id : null}
                onChange={handleChange}
                options={langAsOptions(props.lang.items)}
              />
            </Col>
            <Col size={{ xxs: 36, sm: 12 }}>
              <InputDate
                label={props.intl.formatMessage({
                  id: 'app.features.group.field.grp_from.label',
                  defaultMessage: 'grp_from',
                })}
                id="grp_from"
                name="grp_from"
                value={values.grp_from}
                labelTop={true}
                onChange={handleChange}
                error={getErrorMessage('grp_from')}
              />
            </Col>
            <Col size={{ xxs: 36, sm: 12 }}>
              <InputDate
                label={props.intl.formatMessage({
                  id: 'app.features.group.field.grp_to.label',
                  defaultMessage: 'grp_to',
                })}
                id="grp_to"
                name="grp_to"
                value={values.grp_to}
                labelTop={true}
                onChange={handleChange}
                error={getErrorMessage('grp_to')}
              />
            </Col>
            <Col size={{ xxs: 36, sm: 12 }}>
              <InputText
                label={props.intl.formatMessage({
                  id: 'app.features.group.field.grp_money_code.label',
                  defaultMessage: 'grp_money_code',
                })}
                id="grp_money_code"
                name="grp_money_code"
                value={values.grp_money_code}
                required={true}
                labelTop={true}
                onChange={handleChange}
                error={getErrorMessage('grp_money_code')}
              />
            </Col>
            <Col size={{ xxs: 36, sm: 12 }}>
              <InputText
                label={props.intl.formatMessage({
                  id: 'app.features.group.field.grp_money_input.label',
                  defaultMessage: 'grp_money_input',
                })}
                id="grp_money_input"
                name="grp_money_input"
                value={values.grp_money_input}
                required={true}
                labelTop={true}
                onChange={handleChange}
                error={getErrorMessage('grp_money_input')}
              />
            </Col>
            <Col size={{ xxs: 36, sm: 12 }}>
              <InputText
                label={props.intl.formatMessage({
                  id: 'app.features.group.field.grp_cog.label',
                  defaultMessage: 'Code COG',
                })}
                id="grp_cog"
                name="grp_cog"
                value={values.grp_cog}
                labelTop={true}
                onChange={handleChange}
                error={getErrorMessage('grp_cog')}
              />
            </Col>
          </Row>
        </>
      )}
      {getCurrentTab() === 'ident2' && (
        <Row>
          <Col size={{ xxs: 36 }}>
            <Row>
              <Col size={{ xxs: 36, sm: 18 }}>
                <InputImage
                  label={props.intl.formatMessage({
                    id: 'app.features.group.field.grp_logo.label',
                    defaultMessage: 'grp_logo',
                  })}
                  id="grp_logo"
                  name="grp_logo"
                  value={values.grp_logo}
                  labelTop={true}
                  onChange={handleChange}
                  error={getErrorMessage('grp_logo')}
                />
              </Col>
              <Col size={{ xxs: 36, sm: 18 }}>
                <Col size={{ xxs: 36 }}>
                  <InputPhone
                    label={props.intl.formatMessage({
                      id: 'app.features.group.field.grp_phone.label',
                      defaultMessage: 'grp_phone',
                    })}
                    id="grp_phone"
                    name="grp_phone"
                    value={values.grp_phone}
                    labelTop={true}
                    onChange={handleChange}
                    error={getErrorMessage('grp_phone')}
                  />
                </Col>
                <Col size={{ xxs: 36 }}>
                  <InputEmail
                    label={props.intl.formatMessage({
                      id: 'app.features.group.field.grp_email.label',
                      defaultMessage: 'grp_email',
                    })}
                    id="grp_email"
                    name="grp_email"
                    value={values.grp_email}
                    labelTop={true}
                    onChange={handleChange}
                    error={getErrorMessage('grp_email')}
                  />
                </Col>
                <Col size={{ xxs: 36 }}>
                  <InputText
                    label={props.intl.formatMessage({
                      id: 'app.features.group.field.grp_site_url.label',
                      defaultMessage: 'grp_site_url',
                    })}
                    id="grp_site_url"
                    name="grp_site_url"
                    value={values.grp_site_url}
                    labelTop={true}
                    onChange={handleChange}
                    error={getErrorMessage('grp_site_url')}
                  />
                </Col>
                <Col size={{ xxs: 36 }}>
                  <InputText
                    label={props.intl.formatMessage({
                      id: 'app.features.group.field.grp_siret.label',
                      defaultMessage: 'grp_siret',
                    })}
                    id="grp_siret"
                    name="grp_siret"
                    value={values.grp_siret}
                    labelTop={true}
                    onChange={handleChange}
                    error={getErrorMessage('grp_siret')}
                  />
                </Col>
                <Col size={{ xxs: 36 }}>
                  <InputText
                    label={props.intl.formatMessage({
                      id: 'app.features.group.field.grp_siret_cpl.label',
                      defaultMessage: 'grp_siret_cpl',
                    })}
                    id="grp_siret_cpl"
                    name="grp_siret_cpl"
                    value={values.grp_siret_cpl}
                    labelTop={true}
                    onChange={handleChange}
                    error={getErrorMessage('grp_siret_cpl')}
                  />
                </Col>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {getCurrentTab() === 'sign' && (
        <Row>
          <Col size={{ xxs: 36, sm: 18 }}>
            <InputHtml
              label={props.intl.formatMessage({
                id: 'app.features.group.field.grp_sign.label',
                defaultMessage: 'grp_sign',
              })}
              id="grp_sign"
              name="grp_sign"
              value={values.grp_sign}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('grp_sign')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 18 }}>
            <InputImage
              label={props.intl.formatMessage({
                id: 'app.features.group.field.grp_dig_sign.label',
                defaultMessage: 'grp_dig_sign',
              })}
              id="grp_dig_sign"
              name="grp_dig_sign"
              value={values.grp_dig_sign}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('grp_dig_sign')}
            />
          </Col>
        </Row>
      )}
      {getCurrentTab() === 'sign2' && (
        <Row>
          <Col size={{ xxs: 36, sm: 18 }}>
            <InputHtml
              label={props.intl.formatMessage({
                id: 'app.features.group.field.grp_sign2.label',
                defaultMessage: 'grp_sign2',
              })}
              id="grp_sign2"
              name="grp_sign2"
              value={values.grp_sign2}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('grp_sign2')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 18 }}>
            <InputImage
              label={props.intl.formatMessage({
                id: 'app.features.group.field.grp_dig_sign2.label',
                defaultMessage: 'grp_dig_sign2',
              })}
              id="grp_dig_sign2"
              name="grp_dig_sign2"
              value={values.grp_dig_sign2}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('grp_dig_sign2')}
            />
          </Col>
        </Row>
      )}
      {getCurrentTab() === 'email' && (
        <Row>
          <Col size={{ xxs: 36 }}>
            <InputHtml
              label={props.intl.formatMessage({
                id: 'app.features.group.field.grp_email_header.label',
                defaultMessage: 'grp_email_header',
              })}
              id="grp_email_header"
              name="grp_email_header"
              value={values.grp_email_header}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('grp_email_header')}
            />
          </Col>
          <Col size={{ xxs: 36 }}>
            <InputHtml
              label={props.intl.formatMessage({
                id: 'app.features.group.field.grp_email_footer.label',
                defaultMessage: 'grp_email_footer',
              })}
              id="grp_email_footer"
              name="grp_email_footer"
              value={values.grp_email_footer}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('grp_email_footer')}
            />
          </Col>
        </Row>
      )}
      {getCurrentTab() === 'text' && (
        <Row>
          <Col size={{ xxs: 36 }}>
            <InputHtml
              label={props.intl.formatMessage({
                id: 'app.features.group.field.grp_receipt.label',
                defaultMessage: 'grp_receipt',
              })}
              id="grp_receipt"
              name="grp_receipt"
              value={values.grp_receipt}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('grp_receipt')}
            />
          </Col>
          <Col size={{ xxs: 36 }}>
            <InputHtml
              label={props.intl.formatMessage({
                id: 'app.features.group.field.grp_email_payment.label',
                defaultMessage: 'grp_email_payment',
              })}
              id="grp_email_payment"
              name="grp_email_payment"
              value={values.grp_email_payment}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('grp_email_payment')}
            />
          </Col>
        </Row>
      )}
      {getCurrentTab() === 'conf' && (
        <Row>
          <Col size={{ xxs: 36 }}>
            <InputJson
              label={props.intl.formatMessage({
                id: 'app.features.group.field.grp_config.label',
                defaultMessage: 'grp_config',
              })}
              id="grp_config"
              name="grp_config"
              value={values.grp_config}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('grp_config')}
            />
          </Col>
        </Row>
      )}
      {values.__currentTab === 'taxonomy' && (
        <div className="form-inline-list overflow-x-hidden">
          <InlineTaxonomy objectName="FreeFW_Group" objectId={values.id} />
        </div>
      )}
    </ResponsiveModalOrForm>
  );
}

/**
 * Injection de l'intl
 */
export default injectIntl(Form);
