export const INBOX_CLEAR_ITEMS = 'INBOX_CLEAR_ITEMS';
export const INBOX_CREATE_ONE_BEGIN = 'INBOX_CREATE_ONE_BEGIN';
export const INBOX_CREATE_ONE_SUCCESS = 'INBOX_CREATE_ONE_SUCCESS';
export const INBOX_CREATE_ONE_FAILURE = 'INBOX_CREATE_ONE_FAILURE';
export const INBOX_CREATE_ONE_DISMISS_ERROR = 'INBOX_CREATE_ONE_DISMISS_ERROR';
export const INBOX_DOWNLOAD_ONE_BEGIN = 'INBOX_DOWNLOAD_ONE_BEGIN';
export const INBOX_DOWNLOAD_ONE_SUCCESS = 'INBOX_DOWNLOAD_ONE_SUCCESS';
export const INBOX_DOWNLOAD_ONE_FAILURE = 'INBOX_DOWNLOAD_ONE_FAILURE';
export const INBOX_DOWNLOAD_ONE_DISMISS_ERROR = 'INBOX_DOWNLOAD_ONE_DISMISS_ERROR';
export const INBOX_DEL_ONE_BEGIN = 'INBOX_DEL_ONE_BEGIN';
export const INBOX_DEL_ONE_SUCCESS = 'INBOX_DEL_ONE_SUCCESS';
export const INBOX_DEL_ONE_FAILURE = 'INBOX_DEL_ONE_FAILURE';
export const INBOX_DEL_ONE_DISMISS_ERROR = 'INBOX_DEL_ONE_DISMISS_ERROR';
export const INBOX_EXPORT_AS_TAB_BEGIN = 'INBOX_EXPORT_AS_TAB_BEGIN';
export const INBOX_EXPORT_AS_TAB_SUCCESS = 'INBOX_EXPORT_AS_TAB_SUCCESS';
export const INBOX_EXPORT_AS_TAB_FAILURE = 'INBOX_EXPORT_AS_TAB_FAILURE';
export const INBOX_EXPORT_AS_TAB_DISMISS_ERROR = 'INBOX_EXPORT_AS_TAB_DISMISS_ERROR';
export const INBOX_EXPORT_BEGIN = 'INBOX_EXPORT_BEGIN';
export const INBOX_EXPORT_SUCCESS = 'INBOX_EXPORT_SUCCESS';
export const INBOX_EXPORT_FAILURE = 'INBOX_EXPORT_FAILURE';
export const INBOX_EXPORT_DISMISS_ERROR = 'INBOX_EXPORT_DISMISS_ERROR';
export const INBOX_INIT_FILTERS = 'INBOX_INIT_FILTERS';
export const INBOX_INIT_SORT = 'INBOX_INIT_SORT';
export const INBOX_LOAD_MORE_INIT = 'INBOX_LOAD_MORE_INIT';
export const INBOX_LOAD_MORE_BEGIN = 'INBOX_LOAD_MORE_BEGIN';
export const INBOX_LOAD_MORE_SUCCESS = 'INBOX_LOAD_MORE_SUCCESS';
export const INBOX_LOAD_MORE_FAILURE = 'INBOX_LOAD_MORE_FAILURE';
export const INBOX_LOAD_MORE_DISMISS_ERROR = 'INBOX_LOAD_MORE_DISMISS_ERROR';
export const INBOX_LOAD_PENDINGS_INIT = 'INBOX_LOAD_PENDINGS_INIT';
export const INBOX_LOAD_PENDINGS_BEGIN = 'INBOX_LOAD_PENDINGS_BEGIN';
export const INBOX_LOAD_PENDINGS_SUCCESS = 'INBOX_LOAD_PENDINGS_SUCCESS';
export const INBOX_LOAD_PENDINGS_FAILURE = 'INBOX_LOAD_PENDINGS_FAILURE';
export const INBOX_LOAD_PENDINGS_DISMISS_ERROR = 'INBOX_LOAD_PENDINGS_DISMISS_ERROR';
export const INBOX_LOAD_ONE_BEGIN = 'INBOX_LOAD_ONE_BEGIN';
export const INBOX_LOAD_ONE_SUCCESS = 'INBOX_LOAD_ONE_SUCCESS';
export const INBOX_LOAD_ONE_FAILURE = 'INBOX_LOAD_ONE_FAILURE';
export const INBOX_LOAD_ONE_DISMISS_ERROR = 'INBOX_LOAD_ONE_DISMISS_ERROR';
export const INBOX_ON_SELECT = 'INBOX_ON_SELECT';
export const INBOX_PRINT_ONE_BEGIN = 'INBOX_PRINT_ONE_BEGIN';
export const INBOX_PRINT_ONE_SUCCESS = 'INBOX_PRINT_ONE_SUCCESS';
export const INBOX_PRINT_ONE_FAILURE = 'INBOX_PRINT_ONE_FAILURE';
export const INBOX_PRINT_ONE_DISMISS_ERROR = 'INBOX_PRINT_ONE_DISMISS_ERROR';
export const INBOX_PROPAGATE = 'INBOX_PROPAGATE';
export const INBOX_SELECT_NONE = 'INBOX_SELECT_NONE';
export const INBOX_SELECT_ALL = 'INBOX_SELECT_ALL';
export const INBOX_SET_CURRENT = 'INBOX_SET_CURRENT';
export const INBOX_SET_PREVIOUS = 'INBOX_SET_PREVIOUS';
export const INBOX_SET_NEXT_BEGIN = 'INBOX_SET_NEXT_BEGIN';
export const INBOX_SET_NEXT_SUCCESS = 'INBOX_SET_NEXT_SUCCESS';
export const INBOX_SET_NEXT_FAILURE = 'INBOX_SET_NEXT_FAILURE';
export const INBOX_SET_NEXT_DISMISS_ERROR = 'INBOX_SET_NEXT_DISMISS_ERROR';
export const INBOX_SET_FILTERS = 'INBOX_SET_FILTERS';
export const INBOX_SET_SORT = 'INBOX_SET_SORT';
export const INBOX_UPDATE_ONE_BEGIN = 'INBOX_UPDATE_ONE_BEGIN';
export const INBOX_UPDATE_ONE_SUCCESS = 'INBOX_UPDATE_ONE_SUCCESS';
export const INBOX_UPDATE_ONE_FAILURE = 'INBOX_UPDATE_ONE_FAILURE';
export const INBOX_UPDATE_ONE_DISMISS_ERROR = 'INBOX_UPDATE_ONE_DISMISS_ERROR';
export const INBOX_UPDATE_QUICK_SEARCH = 'INBOX_UPDATE_QUICK_SEARCH';
export const INBOX_UPDATE_SORT = 'INBOX_UPDATE_SORT';
