import { freeAssoApi } from '../../common';

/**
 * 
 * @param {Object} item
 *  
 * @returns 
 */
export const calculateDonationEndTs = item => {
  let endTs = item.don_end_ts;
  if (parseInt(item.cause.id, 10) > 0 && item.cause.cause_type) {
    let cautDuration = item.cause.cause_type.caut_once_duration;
    try {
      let myDate = new Date(item.don_ask_ts);
      switch (cautDuration) {
        case '1Y': {
          myDate.setFullYear(myDate.getFullYear() + 1);
          endTs = myDate.toISOString();
          break;
        }
        case '1M': {
          myDate.setMonth(myDate.getMonth() + 1);
          endTs = myDate.toISOString();
          break;
        }
        default: {
          myDate.setFullYear(myDate.getFullYear() + 1);
          endTs = myDate.toISOString();
          break;
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }
  return endTs;
};

/**
 *
 */
export const updateDonStatus = (donId, newStatus) => {
  const promise = new Promise((resolve, reject) => {
    let doRequestPut = null;
    if (newStatus === 'OK') {
      doRequestPut = freeAssoApi.put('/v1/asso/donation/paid/' + donId);
    } else {
      doRequestPut = freeAssoApi.put('/v1/asso/donation/unpaid/' + donId);
    }
    doRequestPut.then(
      res => {
        resolve(res);
      },
      err => {
        reject(err);
      },
    );
  });
  return promise;
};

/**
 *
 */
 export const updateDonVerif = (donId, newVerif) => {
  const promise = new Promise((resolve, reject) => {
    let doRequestPut = null;
    if (newVerif === 'MANUAL') {
      doRequestPut = freeAssoApi.put('/v1/asso/donation/matched/' + donId);
    } else {
      doRequestPut = freeAssoApi.put('/v1/asso/donation/unmatched/' + donId);
    }
    doRequestPut.then(
      res => {
        resolve(res);
      },
      err => {
        reject(err);
      },
    );
  });
  return promise;
};
