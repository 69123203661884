import React from 'react';
import { injectIntl } from 'react-intl';
import { getShortcuts } from './';
import useForm from '../ui/useForm';
import {
  ResponsiveModalOrForm,
  InputCheckbox,
  InputHidden,
  InputText,
  InputTextarea,
  InputEmail,
  InputSelect,
  InputDatetime,
  InputMonetary,
  Row,
  Col,
} from '../ui';
import { InputPicker as ClientInputPicker, getFullName } from '../client';
import { InputPicker as CauseInputPicker } from '../cause';

/**
 * Initialisation
 *
 * @param {Object}   item
 * @param {Function} setItem
 */
const initItem = (item, setItem) => {};

/**
 * Un champ a été modifié
 *
 * @param {String}   field
 * @param {Object}   item
 * @param {Function} setitem
 */
const afterChange = (field, item, setItem) => {
  switch (field) {
    case 'client':
      item.cert_address1 = item.client.cli_address1;
      item.cert_address2 = item.client.cli_address2;
      item.cert_address3 = item.client.cli_address3;
      item.cert_cp = item.client.cli_cp;
      item.cert_town = item.client.cli_town;
      item.cert_fullname = getFullName(item.client);
      item.cnty_id = item.client.cnty_id;
      item.cert_email = item.client.cli_email;
      break;
    default:
      break;
  }
};

/**
 * Saisie des informations d'un modèle
 */
function Form(props) {
  let item = props.item;
  // On récupère tout ce qu'il faut pour travailler
  const {
    values,
    handleChange,
    handleSave,
    handleSubmit,
    handleCancel,
    handleNavTab,
    getErrorMessage,
    getCurrentTab,
  } = useForm(
    item,
    props.tab,
    props.onSubmit,
    props.onCancel,
    props.onNavTab,
    props.errors,
    props.intl,
    afterChange,
    initItem,
  );
  const readonlyForm = values.__modify;
  if (values.__create) {
    values.cert_manual = true;
  }
  // Le formulaire
  return (
    <ResponsiveModalOrForm
      title={
        !props.modify && !values.cert_fullname
          ? props.intl.formatMessage({
              id: 'app.features.certificate.form.title',
              defaultMessage: 'New model',
            })
          : values.cert_fullname
      }
      className=""
      tab={getCurrentTab()}
      tabs={!props.modify ? getShortcuts(props.intl, 'new') : getShortcuts(props.intl, 'all')}
      size="md"
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onNavTab={handleNavTab}
      onSave={handleSave}
      onClose={handleCancel}
      onPrevious={props.onPrevious}
      onNext={props.onNext}
      modal={true}
      actionsButtons={props.actionsButtons}
      loader={props.loader ? props.loader : false}
    >
      <InputHidden name="id" id="id" value={values.id} />
      <Row>
        <Col size={{ xxs: 36, sm: 12 }}>
          <ClientInputPicker
            label={props.intl.formatMessage({
              id: 'app.features.certificate.field.cli_id.label',
              defaultMessage: 'Member',
            })}
            labelTop={true}
            key="client"
            name="client"
            required={true}
            item={values.client || null}
            disabled={readonlyForm}
            onChange={handleChange}
            error={getErrorMessage('client')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <CauseInputPicker
            label={props.intl.formatMessage({
              id: 'app.features.certificate.field.cau_id.label',
              defaultMessage: 'cau_id',
            })}
            id="cause"
            name="cause"
            item={values.cause || null}
            required={true}
            labelTop={true}
            disabled={readonlyForm}
            onChange={handleChange}
            error={getErrorMessage('cause')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 8 }}>
          <InputSelect
            label={props.intl.formatMessage({
              id: 'app.features.certificate.field.lang_id.label',
              defaultMessage: 'lang.id',
            })}
            id="lang.id"
            name="lang.id"
            value={values.lang && values.lang.id}
            labelTop={true}
            disabled={readonlyForm}
            options={props.langOptions}
            onChange={handleChange}
            error={getErrorMessage('lang')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 4 }}>
          <InputCheckbox
            label={props.intl.formatMessage({
              id: 'app.features.certificate.field.cert_manual.label',
              defaultMessage: 'Manual',
            })}
            id="cert_manual"
            name="cert_manual"
            checked={values.cert_manual}
            labelTop={true}
            disabled={readonlyForm}
            onChange={handleChange}
            error={getErrorMessage('cert_manual')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputDatetime
            label={props.intl.formatMessage({
              id: 'app.features.certificate.field.cert_ts.label',
              defaultMessage: 'cert_ts',
            })}
            id="cert_ts"
            name="cert_ts"
            value={values.cert_ts}
            disabled={readonlyForm}
            labelTop={true}
            onChange={handleChange}
            error={getErrorMessage('cert_ts')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputDatetime
            label={props.intl.formatMessage({
              id: 'app.features.certificate.field.cert_gen_ts.label',
              defaultMessage: 'cert_gen_ts',
            })}
            id="cert_gen_ts"
            name="cert_gen_ts"
            value={values.cert_gen_ts}
            labelTop={true}
            disabled={true}
            onChange={handleChange}
            error={getErrorMessage('cert_gen_ts')}
          />
        </Col>
        <Col size={{ xxs: 36, sm: 12 }}>
          <InputDatetime
            label={props.intl.formatMessage({
              id: 'app.features.certificate.field.cert_print_ts.label',
              defaultMessage: 'cert_print_ts',
            })}
            id="cert_print_ts"
            name="cert_print_ts"
            value={values.cert_print_ts}
            labelTop={true}
            disabled={true}
            onChange={handleChange}
            error={getErrorMessage('cert_print_ts')}
          />
        </Col>
      </Row>
      <hr />
      {values.__currentTab === 'ident' && (
        <Row>
          <Col size={{ xxs: 36, sm: 10 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.certificate.field.cert_fullname.label',
                defaultMessage: 'cert_fullname',
              })}
              id="cert_fullname"
              name="cert_fullname"
              value={values.cert_fullname}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('cert_fullname')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 10 }}>
            <InputEmail
              label={props.intl.formatMessage({
                id: 'app.features.certificate.field.cert_email.label',
                defaultMessage: 'cert_email',
              })}
              id="cert_email"
              name="cert_email"
              value={values.cert_email}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('cert_email')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 10 }}>
            <InputMonetary
              label={props.intl.formatMessage({
                id: 'app.features.certificate.field.cert_input_mnt.label',
                defaultMessage: 'cert_input_mnt',
              })}
              id="cert_input_mnt"
              name="cert_input_mnt"
              value={values.cert_input_mnt}
              inputMoney={values.cert_input_money}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('cert_input_mnt')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 6 }}>
            <InputCheckbox
              label={props.intl.formatMessage({
                id: 'app.features.certificate.field.cert_display_mnt.label',
                defaultMessage: 'cert_input_mnt',
              })}
              id="cert_display_mnt"
              name="cert_display_mnt"
              checked={values.cert_display_mnt || false}
              inputMoney={values.cert_display_mnt}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('cert_display_mnt')}
            />
          </Col>
          <Col size={{ xxs: 36 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.certificate.field.cert_address1.label',
                defaultMessage: 'cert_address1',
              })}
              id="cert_address1"
              name="cert_address1"
              value={values.cert_address1}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('cert_address1')}
            />
          </Col>
          <Col size={{ xxs: 36 }}>
            <InputText
              id="cert_address2"
              name="cert_address2"
              value={values.cert_address2}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('cert_address2')}
            />
          </Col>
          <Col size={{ xxs: 36 }}>
            <InputText
              id="cert_address3"
              name="cert_address3"
              value={values.cert_address3}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('cert_address3')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 6 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.certificate.field.cert_cp.label',
                defaultMessage: 'cert_cp',
              })}
              id="cert_cp"
              name="cert_cp"
              value={values.cert_cp}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('cert_cp')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.certificate.field.cert_town.label',
                defaultMessage: 'cert_town',
              })}
              id="cert_town"
              name="cert_town"
              value={values.cert_town}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('cert_town')}
            />
          </Col>

          <Col size={{ xxs: 36, sm: 12 }}>
            <InputSelect
              label={props.intl.formatMessage({
                id: 'app.features.certificate.field.cnty_id.label',
                defaultMessage: 'cnty_id',
              })}
              id="country.id"
              name="country.id"
              value={values.country && values.country.id}
              options={props.countryOptions}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('country.id')}
            />
          </Col>
          <Col size={{ xxs: 36 }}>
            <InputTextarea
              label={props.intl.formatMessage({
                id: 'app.features.certificate.field.cert_comment.label',
                defaultMessage: 'cert_comment',
              })}
              id="cert_comment"
              name="cert_comment"
              value={values.cert_comment}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('cert_comment')}
            />
          </Col>
        </Row>
      )}
      {values.__currentTab === 'tech' && (
        <Row>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.certificate.field.cert_output_mnt.label',
                defaultMessage: 'cert_output_mnt',
              })}
              id="cert_output_mnt"
              name="cert_output_mnt"
              value={values.cert_output_mnt}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('cert_output_mnt')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.certificate.field.cert_output_money.label',
                defaultMessage: 'cert_output_money',
              })}
              id="cert_output_money"
              name="cert_output_money"
              value={values.cert_output_money}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('cert_output_money')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.certificate.field.cert_data1.label',
                defaultMessage: 'cert_data1',
              })}
              id="cert_data1"
              name="cert_data1"
              value={values.cert_data1}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('cert_data1')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.certificate.field.cert_data2.label',
                defaultMessage: 'cert_data2',
              })}
              id="cert_data2"
              name="cert_data2"
              value={values.cert_data2}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('cert_data2')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.certificate.field.file_id.label',
                defaultMessage: 'file_id',
              })}
              id="file_id"
              name="file_id"
              value={values.file_id}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('file_id')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.certificate.field.cert_unit_unit.label',
                defaultMessage: 'cert_unit_unit',
              })}
              id="cert_unit_unit"
              name="cert_unit_unit"
              value={values.cert_unit_unit}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('cert_unit_unit')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.certificate.field.cert_unit_mnt.label',
                defaultMessage: 'cert_unit_mnt',
              })}
              id="cert_unit_mnt"
              name="cert_unit_mnt"
              value={values.cert_unit_mnt}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('cert_unit_mnt')}
            />
          </Col>
          <Col size={{ xxs: 36, sm: 12 }}>
            <InputText
              label={props.intl.formatMessage({
                id: 'app.features.certificate.field.cert_unit_base.label',
                defaultMessage: 'cert_unit_base',
              })}
              id="cert_unit_base"
              name="cert_unit_base"
              value={values.cert_unit_base}
              labelTop={true}
              onChange={handleChange}
              error={getErrorMessage('cert_unit_base')}
            />
          </Col>
        </Row>
      )}
    </ResponsiveModalOrForm>
  );
}

/**
 * Injection de l'intl
 */
export default injectIntl(Form);
