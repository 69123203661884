import { jsonApiNormalizer, normalizedObjectModeler } from 'jsonapi-front';
import {
  STAT_LOAD_ONE_BEGIN,
  STAT_LOAD_ONE_SUCCESS,
  STAT_LOAD_ONE_FAILURE,
  STAT_LOAD_ONE_DISMISS_ERROR,
} from './constants';
import { getOneModel } from '../';

/**
 * Récupère un modèle en fonction de son identifiant
 */
export function loadOne(id = 0) {
  return (dispatch, getState) => {
    dispatch({
      type: STAT_LOAD_ONE_BEGIN,
    });
    const promise = new Promise((resolve, reject) => {
      const params = {
        include: getState().stat.include
      }
      const doRequest = getOneModel(id, params);
      doRequest.then(
        result => {
          const object = jsonApiNormalizer(result.data);
          const item   = normalizedObjectModeler(object, 'FreeAsso_Stat', id, { eager: true } );
          dispatch({
            type: STAT_LOAD_ONE_SUCCESS,
            data: result,
            item: item,
            id: id,
          });
          resolve(item);
        },
        err => {
          dispatch({
            type: STAT_LOAD_ONE_FAILURE,
            data: { error: err },
            id: id,
          });
          reject(err);
        },
      );
    });
    return promise;
  };
}

export function dismissLoadOneError() {
  return {
    type: STAT_LOAD_ONE_DISMISS_ERROR,
  };
}

/**
 * Reducer
 * 
 * @param {Object} state  Etat courant de la mémoire (store)
 * @param {Object} action Action à réaliser sur cet état avec options
 */
export function reducer(state, action) {
  switch (action.type) {
    case STAT_LOAD_ONE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        loadOnePending: true,
        loadOneError: null,
        createOneError: null,
        updateOneError: null,
      };

    case STAT_LOAD_ONE_SUCCESS:
      // The request is success
      return {
        ...state,
        loadOnePending: false,
        loadOneItem: action.item,
        loadOneError: null,
      };

    case STAT_LOAD_ONE_FAILURE:
      // The request is failed
      return {
        ...state,
        loadOnePending: false,
        loadOneError: action.data.error,
      };

    case STAT_LOAD_ONE_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        loadOneError: null,
      };

    default:
      return state;
  }
}
