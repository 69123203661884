import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { GetOne as GetOneIcon } from '../icons';

export default function DashboardCardStat(props) {
  let counter = props.count;
  return (
    <div className="dashboard-dashboard-card-stat select-none">
      <div className="row card-draggable-area">
        <div className="col-xxs-w14 text-center">
          {props.url && !props.overlay ? (
            <NavLink strict className="nav-link link" to={props.url}>
              <div className="text-secondary">{props.icon}</div>
            </NavLink>
          ) : (
            <div className="nav-link link">
              <div className="text-secondary pt-2">{props.icon}</div>
            </div>
          )}
        </div>
        <div className="col-xxs-w22">
          <div className="row">
            <div className="col-xxs-w36 text-center">
              <h4 className="card-title">{counter}</h4>
              {props.onEdit && !props.overlay && (
                <button className="btn-edit btn btn-xs btn-secondary-light text-secondary pl-2">
                  <GetOneIcon size={0.6} onClick={props.onEdit} />
                </button>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-xxs-w36 text-center">
              <p className="card-category text-secondary">{props.title}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xxs-w36 text-center pl-3, pr-3">
          <div className="pl-4 pr-4">{props.footer}</div>
        </div>
      </div>
    </div>
  );
}

DashboardCardStat.propTypes = {
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.element,
  footer: PropTypes.element,
  onEdit: PropTypes.func,
  overlay: PropTypes.bool,
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
};
DashboardCardStat.defaultProps = {
  icon: null,
  footer: null,
  onEdit: null,
  overlay: false,
  url: '',
};
